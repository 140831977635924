export function convertToCamelCase(strr = "") {
  // Remove leading and trailing whitespace
if(strr == null ){
  strr = " "
}
  let str = strr.trim();

  // Split the string into words using space or other delimiters if necessary
  let words = str.split(/\s+/);

  // If there's only one word, return it in all lowercase
  if (words.length === 1) {
    return words[0].toLowerCase();
  }

  // Convert the first word to lowercase
  let camelCaseStr = words[0].toLowerCase();

  // Convert the rest of the words to capitalize first letter and lower case the rest
  for (let i = 1; i < words.length; i++) {
    let word = words[i].toLowerCase();
    camelCaseStr += word.charAt(0).toUpperCase() + word.slice(1);
  }

  return camelCaseStr;
}
