import axios from "../axios/axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSystemLanguage } from "../Redux/StudBook/StudBookSlice";
import { setMarketType } from "../Redux/HorseMarket/HorseMarketSlice";
import TranslationText, { useTranslate } from "./TranslationText";
import { emptyPageHorseMarket } from "../Redux/HorseMarket/HorseMarketSlice";
import BigLoader from "./BigLoader";
import { constant } from "lodash";
import { useLocation } from "react-router-dom";
import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { serviceRequest } from "../firebase/collections";
import { NightyNineMax } from "../helper/NightyNineMax";
import { FetchGetAllServicesRequestsOwnerToApprovethunk } from "../Redux/ServicesRequests/ServicesRequestsAction";
import { getFirstAndLastWords } from "../helper/showFirstSixWords";
import axiosInstance from "../axios/axios";

const baseURL = process.env.REACT_APP_BASE_URL;
const logoutUAEPASS = process.env.REACT_APP_UAE_PASS_LOGOUT_LINK;
const RedirectUAEPASS = process.env.REACT_APP_UAE_PASS_REDIRECT_LINK;

export default function Header() {
  const [isNavVisible, setNavVisible] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const { translate } = useTranslate();
  // Handle menu button click
  const handleMenuClick = () => {
    setNavVisible(!isNavVisible);
    setOverlayVisible(!isOverlayVisible);
  };

  // Handle overlay click
  const handleOverlayClick = () => {
    setNavVisible(!isNavVisible);
    setOverlayVisible(!isOverlayVisible);
  };

  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessTokenforEAHS")
  );

  const [currentTab, setCurrentTabState] = useState("home");

  function setCurrentTab(currentTab) {
    setCurrentTabState(currentTab);
  }

  // current tab
  const location = useLocation();

  const { servicesRequestsLoading, getAllServicesRequestsOwnerToApproveData } =
    useSelector((state) => state.servicesRequestsSlice);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setCurrentTab("home");
        break;
      case "/horseMarket":
        setCurrentTab("horse-market");
        break;
      case "/horseSupplies":
        setCurrentTab("horse-market");
        break;
      case "/HorseTransportCompanies":
        setCurrentTab("horse-market");
        break;
      case "/EquestrianCentersClubs":
        setCurrentTab("horse-market");
        break;
      case "/horsemarketprofile":
        setCurrentTab("horse-market");
        break;
      case "/SingleSupplier":
        setCurrentTab("horse-market");
        break;
      case "/SingleTransportCompany":
        setCurrentTab("horse-market");
        break;
      case "/Educational-Courses":
        setCurrentTab("Educational-Courses");
        break;
      case "/SingleEquestrianCentersClubs":
        setCurrentTab("horse-market");
        break;
      case "/HorseHospitals":
        setCurrentTab("horse-market");
        break;
      case "/studbook":
        setCurrentTab("studbook");
        break;
      case "/horsedetails":
        setCurrentTab("studbook");
        break;
      case "/horseDetails":
        setCurrentTab("studbook");
        break;
      case "/services":
        setCurrentTab("services");
        break;
      case "/singleservice":
        setCurrentTab("services");
        break;
      case "/auctions":
        setCurrentTab("events");
        break;
      case "/singleauction":
        setCurrentTab("events");
        break;
      case "/horse-auction":
        setCurrentTab("events");
        break;
      case "/book-table":
        setCurrentTab("events");
        break;
      case "/eahs-award":
        setCurrentTab("events");
        break;
      case "/bidding":
        setCurrentTab("horse-market");
        break;
      case "/shows":
        setCurrentTab("events");
        break;
      case "/showdetails":
        setCurrentTab("events");
        break;
      case "/calendar":
        setCurrentTab("events");
        break;
      case "/shows-categories":
        setCurrentTab("events");
        break;
      // case "/wahoconference":
      //   setCurrentTab("events");
      //   break;
      // case "/waho-trophy":
      //   setCurrentTab("events");
      //   break;
      case "/news":
        setCurrentTab("Media-Center");
        break;
      case "/gallery":
        setCurrentTab("Media-Center");
        break;
      case "/singlePost":
        setCurrentTab("Media-Center");
        break;
      case "/about-us":
        setCurrentTab("about-us");
        break;
      case "/orgChart":
        setCurrentTab("orgChart");
        break;
      case "/contactus":
        setCurrentTab("contact-us");
        break;

      default:
        setCurrentTab("");
        break;
    }
  }, [location]);

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const [userProfile, setUserProfile] = useState("");
  const [showAccountList, setShowAccountList] = useState(false);
  const [showBigLoader, setShowBigLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLanguageChange = (lang) => {
    setShowBigLoader(true);
    dispatch(setSystemLanguage(lang));
    setTimeout(() => {
      setShowBigLoader(false);
      window.location.reload();
    }, 1000);
  };

  const handleMarketTypeChange = (e, marketType) => {
    e.preventDefault();
    dispatch(setMarketType(marketType));
    dispatch(emptyPageHorseMarket());
    navigate("horseMarket");
  };

  const { hash } = useLocation();

  const {
    setCurrentSubAccountId
  } = useSelector((state) => state.ownerProfileSlice);
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     try {
  //       if (hash) {
  //         const element = document.querySelector(hash);
  //         if (element) {
  //           const topOffset =
  //             element.getBoundingClientRect().top + window.scrollY - 200;
  //           window.scrollTo({ top: topOffset, behavior: "smooth" });
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, 1000); // 1000 milliseconds = 1 second

  //   // Cleanup the timer if the component is unmounted
  //   return () => clearTimeout(timer);
  // }, [hash]);

  useEffect(() => {
    // Check if access token is stored in localStorage
    const accessToken = localStorage.getItem("accessTokenforEAHS");

    if (accessToken) {
      setAccessToken(accessToken);
      // console.log("This is token in cookies", accessToken);
    }

    if (accessToken) {
      async function getUserProfile(accessToken) {
        const userProfile = await axios.get("/account/profile");

        if (userProfile.data.status.detailedResponseCode !== "Success") {
          handlelogout(userProfile);
          return <Navigate to="/login" />;
        } else {
          localStorage.setItem("EAHSUserID", userProfile.data.result.id);

          localStorage.setItem(
            "EAHSUserFullName",
            userProfile.data.result.fullName
          );
          const jsonString = JSON.stringify(userProfile.data.result);
          localStorage.setItem("eahsUserProfile", jsonString);
          //localStorage.setItem("currentSubAccountId",userProfile.data.result.id);
          localStorage.setItem("EAHSUserID", userProfile.data.result.id);
        }
        setUserProfile(userProfile.data.result);
      }
      getUserProfile(accessToken);
      // console.log("userProfile is loaded", userProfile);
    }
  }, [accessToken, navigate]);

  async function lougoutUaePass() {
    try {
      window.location.href = `${logoutUAEPASS}?redirect_uri=${RedirectUAEPASS}`;

      localStorage.setItem("EAHSUserIsUAEPASSUser", false);
    } catch (error) {
      console.log("error in logout in UAE PASS", error);
    }
  }

  async function handlelogout(userProfile) {
    localStorage.clear();
    await setUserProfile("");
    await lougoutUaePass();
    if (userProfile !== "") {
      navigate("/login", { state: "loggedout" });
    } else {
      navigate("/login", { state: "gotologin" });
    }
  }

  async function visitUserProfile() {
    navigate("/profile");
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      // Handle click outside the element (e.g., close a menu, etc.)
      setShowAccountList(false);
    }
  };

  const ref = useRef(null);

  function showHideuserMenu() {
    setShowAccountList((prev) => !prev); // Toggle the state
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  const [firestoreData, setFireStoreData] = useState([]);

  const filterCollection = async () => {
    const q = query(serviceRequest,
      where("Flag", "==", false),
      where("NewOwnerId", "==", setCurrentSubAccountId),
      orderBy('TimeStamp', 'desc'));

    return q;
  };

  //console.log(getAllServicesRequestsOwnerToApproveData)
  // useEffect(() => {
  //   if (!!userProfile) {
  //     let params = {
  //       language: currentLanguage,
  //       userId: localStorage.getItem("currentSubAccountId"),
  //     };
  //     dispatch(FetchGetAllServicesRequestsOwnerToApprovethunk(params));
  //   }
  // }, [userProfile]);

  const [showNotificationPanel, setShowNotificationPanel] = useState(false);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const q = await filterCollection();
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const newData = [];

          querySnapshot.forEach((doc) => {
            newData.push(doc.data());
          });

          // if (getAllServicesRequestsOwnerToApproveData.length > 0) {
          //   let filtered = getAllServicesRequestsOwnerToApproveData.filter(
          //     (serviceY) =>
          //       !newData.some(
          //         (serviceX) => serviceX.ServiceId === serviceY.serviceId
          //       )
          //   );

          //   let result = [
          //     ...newData.filter(
          //       (item) =>
          //         item.NewOwnerId ===
          //         localStorage.getItem("currentSubAccountId") && !item.flag
          //     ),
          //     ...filtered,
          //   ];

          //   let resultLowercaseKeys = result.map((obj) => {
          //     return Object.keys(obj).reduce((acc, key) => {
          //       acc[key.toLowerCase()] = obj[key];
          //       return acc;
          //     }, {});
          //   });

          //   // resultLowercaseKeys.sort((a, b) => {
          //   //   // Extract the numeric part of the referencenumber string (ignoring OT and the year)
          //   //   const referencenumberA = parseInt(a.referencenumber.split('-')[1], 10);
          //   //   const referencenumberB = parseInt(b.referencenumber.split('-')[1], 10);

          //   //   return referencenumberB - referencenumberA; // Sort in ascending order
          //   // });

          //   setFireStoreData(resultLowercaseKeys);

          //   console.log("newData is", resultLowercaseKeys);
          // } else {
          let resultLowercaseKeys = newData
            .map((obj) => {
              return Object.keys(obj).reduce((acc, key) => {
                acc[key.toLowerCase()] = obj[key];
                return acc;
              }, {});
            });

          // resultLowercaseKeys.sort((a, b) => {
          //   // Extract the numeric part of the referencenumber string (ignoring OT and the year)
          //   const referencenumberA = parseInt(a.referencenumber.split('-')[1], 10);
          //   const referencenumberB = parseInt(b.referencenumber.split('-')[1], 10);

          //   return referencenumberB - referencenumberA; // Sort in ascending order
          // });

          setFireStoreData(resultLowercaseKeys);

          console.log("newData is", resultLowercaseKeys);
          // }
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching data in Header:", error);
      }
    };
    if (!!accessToken) {
      if (false)
        fetchData();
    }
  }, [accessToken, setCurrentSubAccountId]);


  // live Show Check
  const [liveShowId, setLiveShowID] = useState(null);
  const [liveEventType, setLiveEventType] = useState('showdetails?id');
  async function CheckRunningShows() {
    try {
      const response = await axiosInstance.get('shows/checkliveevent');
      debugger;
      if (response.data.responseCode == "200") {
        if (response.data.result.eventType.toLowerCase() == 'auction') {
          setLiveEventType('hybridBidding?auctionID');
        }
        if (response.data.result.eventType.toLowerCase() == 'show') {
          debugger;
          setLiveEventType('showdetails?id');
        }
        if (response.data.result && !!response.data.result.liveLink) {
          setLiveShowID(response.data.result.id)
        }
      }

    } catch (error) {
      console.error('error in getting live Shows', error);
    }
  }
  useEffect(() => {
    CheckRunningShows();

  }, [])


  if (showBigLoader) {
    return <BigLoader />;
  }

  const youtubeURL = translate("web.liveStreamingUrl", "false");

  //show notification panel
  const togglePanel = () => {
    // var panel = document.getElementById("notificationPanel");
    // panel.classList.toggle("show");
    setShowNotificationPanel(!showNotificationPanel);
  };

  const handleNotificationClick = (link) => {
    togglePanel();

    navigate(link);
  };

  return (
    <div className="header bold">
      <a href="#" className="logo_img_container">
        <img src={`${baseURL}/assets/img/logotransparent.png`} alt="logo" />
      </a>
      <div className="nav_bar">
        <div className={`lang_and_login ${isOverlayVisible ? "secshow" : ""}`}>
          {userProfile !== "" && userProfile !== null ? (
            <>
              <span
                className="display_block userNameContainer"
                style={{ position: "relative" }}
              >
                <TranslationText
                  defaultText={"Welcome"}
                  textkey={"web.welcome"}
                />{" "}
                {getFirstAndLastWords(userProfile.fullName)}
                {/* {!!firestoreData && (
                  <>
                    <div className="dropdown belldevContaner desktopOnly">
                      <button
                        className="dropdown-toggle bgtransparent-outline-0-border-0 bellDiv"
                        type="button"
                        // data-toggle="dropdown"
                        // data-bs-toggle="dropdown"
                        onClick={togglePanel}
                      >
                        <i className="fa-regular fa-bell fa-lg"></i>
                        <span className="badgebell">
                          {NightyNineMax(firestoreData?.length ?? 0)}
                        </span>
                      </button>
                    </div>
                  </>
                )} */}
              </span>
            </>
          ) : (
            ""
          )}
          {youtubeURL != "false" && youtubeURL != "" && (
            <div className="desktopOnly mx-2">
              <Link
                to={"/live-event"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TranslationText
                  defaultText={"Live"}
                  textkey={"web.liveBrodcast"}
                />
                <div className="redpointLive" />
              </Link>
            </div>
          )}
          {!!liveShowId &&
            <div className="desktopOnly mx-2">
              <Link
                to={`/${liveEventType}=${liveShowId}`}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TranslationText
                  defaultText={"Live"}
                  textkey={"web.liveBrodcast"}
                />
                <div className="redpointLive" />
              </Link>
            </div>
          }
          <div className="lang">
            {currentLanguage == "en" ? (
              <div className="bold">
                <button
                  className=" bgtransparent-outline-0-border-0"
                  type="button"
                  data-toggle="dropdown"
                  id="langbtn"
                  onClick={() => handleLanguageChange("ar")}
                >
                  <a href="#" className="lang_item">
                    {/* <img
                      className="width-17"
                      src={`${baseURL}/assets/img/ar.png`}
                      alt=""
                    /> */}
                    <span style={{ fontWeight: 700 }}>
                      <TranslationText defaultText={"AR"} textkey={"web.ar"} />
                    </span>
                  </a>
                </button>
              </div>
            ) : (
              <div className="bold">
                <button
                  className="bgtransparent-outline-0-border-0"
                  type="button"
                  data-toggle="dropdown"
                  id="langbtn"
                  onClick={() => handleLanguageChange("en")}
                >
                  <a href="#" className="lang_item">
                    {/* <img
                      className="width-17"
                      src={`${baseURL}/assets/img/EN.png`}
                      alt=""
                    /> */}
                    <span style={{ fontWeight: 700 }}>
                      <TranslationText defaultText={"EN"} textkey={"web.en"} />
                    </span>
                  </a>
                </button>
              </div>
            )}
          </div>
          {/* <div className="menueSpacer" />
          <div className="get_app_link">
            <a href="#appstores">
              <i className="fa-solid fa-mobile-screen"></i>
              <span>
                <TranslationText
                  defaultText={"Get The App"}
                  textkey={"web.getTheApp"}
                />
              </span>
            </a>
          </div> */}

          <div className="menueSpacer" />
          <div className="get_app_link">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Link
                to={"https://eahgc.ae/"}
                className="rotateArabic rtl"
                target="_blank"
              >
                <img
                  style={{ width: 30, borderRadius: 10, margin: "auto 5px" }}
                  src={baseURL + "/assets/img/ecup.png"}
                />
                <TranslationText
                  defaultText={"Emirates Cup"}
                  textkey={"web.emiratesCup"}
                />
              </Link>
              <Link to={"/wahoconference"} className="rotateArabic rtl">
                <img
                  style={{
                    width: 30,
                    // height: 20,
                    borderRadius: 10,
                    margin: "auto 5px",
                  }}
                  src={baseURL + "/assets/img/wahoLogo.png"}
                />

                <TranslationText
                  defaultText={"Waho Conference"}
                  textkey={"web.wahoConference"}
                />
              </Link>
            </div>
          </div>
          <div className="menueSpacer" />
          <div className="login_link" ref={ref}>
            {/* <Link to={userProfile !== "" ? "/profile" : "/login"}> */}
            <span
              onClick={() =>
                userProfile !== "" && userProfile !== null
                  ? null
                  : navigate("/login")
              }
            >
              {userProfile !== "" && userProfile !== null ? (
                <React.Fragment>
                  <div
                    className="accountSettingMenu"
                    onClick={() => showHideuserMenu()}
                  >
                    <span>{userProfile?.fullName?.charAt(0)}</span>
                  </div>
                  {showAccountList && (
                    <div className="showAndHideUsermenu">
                      <span
                        className="display_block"
                        onClick={() => visitUserProfile()}
                      >
                        <TranslationText
                          defaultText={"Profile"}
                          textkey={"web.profile"}
                        />
                      </span>
                      <span className="display_block" onClick={handlelogout}>
                        <TranslationText
                          defaultText={"Logout"}
                          textkey={"web.logout"}
                        />
                      </span>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <i className="fa-regular fa-user"></i>
                  <TranslationText
                    defaultText={"Login"}
                    textkey={"web.login"}
                  />
                </React.Fragment>
              )}{" "}
            </span>
            {/* </Link> */}
          </div>
        </div>
        <div className={`nav_items ${isNavVisible ? "showCustom" : ""}`}>
          {youtubeURL != "false" && youtubeURL != "" && (
            <>
              <Link to={"/live-event"} className="livedivMobileOnly">
                <TranslationText
                  defaultText={"Live"}
                  textkey={"web.liveBrodcast"}
                />
                <div className="redpointLive" />
              </Link>
              <div className="menueSpacer livedivMobileOnly" />
            </>
          )}
          {!!liveShowId &&
            <>
              <Link to={`/${liveEventType}=${liveShowId}`} className="livedivMobileOnly">
                <TranslationText
                  defaultText={"Live"}
                  textkey={"web.liveBrodcast"}
                />
                <div className="redpointLive" />
              </Link>
              <div className="menueSpacer livedivMobileOnly" />
            </>
          }
          <div className="mobileMenuSpacer"></div>
          <Link to={"/"}>
            <TranslationText defaultText={"Home"} textkey={"web.home"} />
            <div
              className="underline"
              style={{
                opacity: currentTab === "home" ? 1 : 0,
                visibility: currentTab === "home" ? "visible" : "hidden",
              }}
            ></div>
          </Link>
          <div className="menueSpacer" />
          {/* services */}
          <Link to={"/services"}>
            <TranslationText
              defaultText={"Services"}
              textkey={"web.services"}
            />
            <div
              className="underline"
              style={{
                opacity: currentTab === "services" ? 1 : 0,
                visibility: currentTab === "services" ? "visible" : "hidden",
              }}
            ></div>
          </Link>
          <div className="menueSpacer" />
          {/* StudBook */}
          <Link to={"/studbook"}>
            <TranslationText
              defaultText={"Studbook"}
              textkey={"web.studBook"}
            />
            <div
              className="underline"
              style={{
                opacity: currentTab === "studbook" ? 1 : 0,
                visibility: currentTab === "studbook" ? "visible" : "hidden",
              }}
            ></div>
          </Link>
          <div className="menueSpacer" />
          {/* horse Market */}
          <a href="#">
            <div className="dropdown">
              <button
                className="dropdown-toggle bgtransparent-outline-0-border-0"
                type="button"
                data-toggle="dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TranslationText
                  defaultText={"Owner Services"}
                  textkey={"web.ownerServices"}
                />
                <span className="caret"></span>
              </button>
              <div
                className="underline"
                style={{
                  opacity: currentTab === "horse-market" ? 1 : 0,
                  visibility:
                    currentTab === "horse-market" ? "visible" : "hidden",
                }}
              ></div>
              <ul className="dropdown-menu">
                {/* <li>
                  <Link
                    onClick={(e) => handleMarketTypeChange(e, "HorseMarket")}
                  >
                    <span>
                      <TranslationText
                        defaultText={"Horse Market"}
                        textkey={"web.horseMarket"}
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(e) => handleMarketTypeChange(e, "HorseCharity")}
                  >
                    <span>
                      <TranslationText
                        defaultText={"Horse Charity"}
                        textkey={"web.horseCharity"}
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={(e) => handleMarketTypeChange(e, "SemenMarket")}
                  >
                    <span>
                      <TranslationText
                        defaultText={"Semen Market"}
                        textkey={"web.semenMarket"}
                      />
                    </span>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to={"/horseSupplies"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <span>
                      <TranslationText
                        defaultText={"Horse Supplies"}
                        textkey={"web.horseSupplies"}
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/HorseTransportCompanies"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <span>
                      <TranslationText
                        defaultText={"Horse Transport Companies"}
                        textkey={"web.horseTransportCompanies"}
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/EquestrianCentersClubs"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <span>
                      <TranslationText
                        defaultText={"Equestrian Centers Clubs"}
                        textkey={"web.equestrianCentersClubs"}
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/HorseHospitals"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <span>
                      <TranslationText
                        defaultText={"Horse Hospitals"}
                        textkey={"web.horseHospitals"}
                      />
                    </span>
                  </Link>
                </li>
                {/* <li>
                  <Link to={"https://vzap.org/zb-online/"} target="_blank" className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}>
                    <TranslationText
                      defaultText={"VZAP studbook (German Studbook)"}
                      textkey={"web.vzapStudbook"}
                    />
                  </Link>
                </li> */}
              </ul>
            </div>
          </a>
          <div className="menueSpacer" />
          {/* Events and Auctions Center */}
          <a href="#">
            <div className="dropdown">
              <button
                className="dropdown-toggle bgtransparent-outline-0-border-0"
                type="button"
                data-toggle="dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TranslationText
                  defaultText={"Events And Activities"}
                  textkey={"web.eventsAndActivities"}
                />
                <span className="caret"></span>
              </button>
              <div
                className="underline"
                style={{
                  opacity: currentTab === "events" ? 1 : 0,
                  visibility: currentTab === "events" ? "visible" : "hidden",
                }}
              ></div>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to={"/calendar"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Calender"}
                      textkey={"web.calender"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/shows"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Shows"}
                      textkey={"web.shows"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/auctions"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Auctions"}
                      textkey={"web.horsesAuctions"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/eahs-award"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"EAHS Award"}
                      textkey={"web.eahsAward"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/opend-events"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Book Table"}
                      textkey={"web.bookTable"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/opend-ads"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Advertis Package"}
                      textkey={"web.advertisPackage"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/courses"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Courses"}
                      textkey={"web.courses"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/Educational-Courses"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Educational"}
                      textkey={"web.educational"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/waho-trophy"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Waho Trophy"}
                      textkey={"web.wahoTrophy"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/shows-categories"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Shows Categories"}
                      textkey={"web.showsCategories"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"https://www.ecaho.org/shows/blue-book"}
                    target="_blank"
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"ECAHO Shows Rules"}
                      textkey={"web.ecahoShowsRules"}
                    />
                  </Link>
                </li>
                {/* <li>
                  <Link to={"/wahoconference"}>
                    <TranslationText
                      defaultText={"Waho Conference"}
                      textkey={"web.wahoConference"}
                    />
                  </Link>
                </li> 
                */}
              </ul>
            </div>
          </a>
          <div className="menueSpacer" />
          {/* <a href="#">
            <div className="dropdown">
              <button
                className="dropdown-toggle bgtransparent-outline-0-border-0"
                type="button"
                data-toggle="dropdown"
              >
                <a href="#">
                  <TranslationText defaultText={"Waho"} textkey={"web.waho"} />
                  <span className="caret"></span>
                  <div
                    className="underline"
                    style={{
                      opacity: currentTab === "wahoConference" ? 1 : 0,
                      visibility:
                        currentTab === "wahoConference" ? "visible" : "hidden",
                    }}
                  ></div>
                </a>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link to={"/wahoconference"}>
                    <TranslationText
                      defaultText={"Waho Conference"}
                      textkey={"web.wahoConference"}
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/waho-trophy"} >
                    <TranslationText
                      defaultText={"Waho Trophy"}
                      textkey={"web.wahoTrophy"}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </a> */}

          {/* <Link to={"/wahoconference"}>
            <TranslationText
              defaultText={"Waho Conference"}
              textkey={"web.wahoConference"}
            />
            <div className="underline"></div>
          </Link> */}
          {/* Rules Center */}
          {/* <a href="#">
            <div className="dropdown">
              <button
                className="dropdown-toggle bgtransparent-outline-0-border-0"
                type="button"
                data-toggle="dropdown"
              >
                <a href="#">
                  <TranslationText
                    defaultText={"Rules"}
                    textkey={"web.rules"}
                  />
                  <span className="caret"></span>
                  <div className="underline"></div>
                </a>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link to={"http://www.waho.org/"} target="_blank">
                    <TranslationText
                      defaultText={"WAHO"}
                      textkey={"web.waho"}
                    />
                  </Link>
                </li>
                
              </ul>
            </div>
          </a> */}
          {/* <div className="menueSpacer" /> */}
          {/* <Link to={"/Educational-Courses"}>
            <TranslationText
              defaultText={"Educational Courses"}
              textkey={"web.educationalCourses"}
            />
            <div
              className="underline"
              style={{
                opacity: currentTab === "Educational-Courses" ? 1 : 0,
                visibility: currentTab === "Educational-Courses" ? "visible" : "hidden",
              }}
            ></div>
          </Link> */}
          {/* Media Center */}
          <a href="#">
            <div className="dropdown">
              <button
                className="dropdown-toggle bgtransparent-outline-0-border-0"
                type="button"
                data-toggle="dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TranslationText
                  defaultText={"Media Center"}
                  textkey={"web.mediaCenter"}
                />
                <span className="caret"></span>
              </button>
              <div
                className="underline"
                style={{
                  opacity: currentTab === "Media-Center" ? 1 : 0,
                  visibility:
                    currentTab === "Media-Center" ? "visible" : "hidden",
                }}
              ></div>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to={"/news"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"News"}
                      textkey={"web.news"}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/gallery"}
                    className="dropdown-item"
                    style={{ fontWeight: 400, color: "rgb(38, 38, 38)" }}
                  >
                    <TranslationText
                      defaultText={"Gallery"}
                      textkey={"web.gallery"}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </a>
          <div className="menueSpacer" />
          {/* End Of Media Center */}

          {/* About Us */}
          <a href="#">
            {/* <div className="dropdown"> */}
            <div>
              <button
                className=" bgtransparent-outline-0-border-0"
                type="button"
                data-toggle="dropdown"
                // data-bs-toggle="dropdown" aria-expanded="false"
                style={{
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TranslationText
                  defaultText={"About Us"}
                  textkey={"web.aboutUs"}
                />
                {/* <span className="caret"></span> */}
              </button>
              <div
                className="underline"
                style={{
                  opacity: currentTab === "about-us" ? 1 : 0,
                  visibility: currentTab === "about-us" ? "visible" : "hidden",
                }}
              ></div>
              {/* <Link to={"/about-us"}> */}
              {/* <ul className="dropdown-menu">
                <li>
                  <Link >
                    <TranslationText
                      defaultText={"About EAHS"}
                      textkey={"web.aboutEAHS"}
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/sponsorsPage"}>
                    <TranslationText
                      defaultText={"Sponsors"}
                      textkey={"web.sponsors"}
                    />
                  </Link>
                </li>
                <li>
                  <Link to={"/orgChart"}>
                    <TranslationText
                      defaultText={"Org Chart"}
                      textkey={"web.orgChart"}
                    />
                  </Link>
                </li>
              </ul> */}
            </div>
          </a>

          <div className="menueSpacer" />
          <Link to={"/contactus"}>
            <TranslationText
              defaultText={"Contact Us"}
              textkey={"web.contactUs"}
            />
            <div
              className="underline"
              style={{
                opacity: currentTab === "contact-us" ? 1 : 0,
                visibility: currentTab === "contact-us" ? "visible" : "hidden",
              }}
            ></div>
          </Link>
          {userProfile !== "" && userProfile !== null && (
            <>
              <Link
                // onClick={() => visitUserProfile()}
                to={"/profile"}
                className="showonMobileJust"
              >
                <TranslationText
                  defaultText={"Profile"}
                  textkey={"web.profile"}
                />
              </Link>
              <Link onClick={handlelogout} className="showonMobileJust">
                <TranslationText
                  defaultText={"Log Out"}
                  textkey={"web.logout"}
                />
              </Link>
            </>
          )}
        </div>
      </div>
      <div id="menu_btn" onClick={handleMenuClick}>
        <i className="fa-solid fa-bars font-50"></i>
      </div>
      {/* {!!firestoreData && (
        <>
          <div
            className="dropdown belldevContaner viewMobileOnly"
            onClick={() => togglePanel()}
          >
            <button
              className="dropdown-toggle bgtransparent-outline-0-border-0 bellDiv"
              type="button"
              // data-toggle="dropdown"
              // data-bs-toggle="dropdown"
              onClick={() => togglePanel()}
            >
              <i className="fa-regular fa-bell fa-lg"></i>
              <span className="badgebell">
                {NightyNineMax(firestoreData?.length ?? 0)}
              </span>
            </button>
          </div>
          {
            //notification panel
          }
          <div
            className={`panel ${showNotificationPanel ? "show" : ""} `}
            style={{ paddingTop: 25 }}
          >
            <div className="bold closeButn" onClick={togglePanel}>
              X
            </div>
            <div className="notifications rtl">
              <h2>
                {" "}
                <TranslationText
                  defaultText={"Notifications"}
                  textkey={"web.notifications"}
                />
              </h2>
              {!!firestoreData &&
                firestoreData.map((item, i) => (
                  <div className="notification-item">
                    {
                      //Ownership transfer
                    }
                    {
                      item.servicedefinitionid === "53ab6bf3-596b-47ec-8d5d-65bf44d2e5c5" && (item.statusen === "Waiting Buyer Approval" || item.statusen === "Needs More Details") && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestHorseOwnershipTransferSeller?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                            );
                          }}
                        // to={
                        //   `/requestHorseOwnershipTransferSeller?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                        // }
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            //item.ownername + " - " + item.servicename
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "53ab6bf3-596b-47ec-8d5d-65bf44d2e5c5" && item.statusen === "Buyer Rejected" && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestHorseOwnershipTransferSeller?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=edit`
                            );
                          }}
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            //item.ownername + " - " + item.servicename
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "53ab6bf3-596b-47ec-8d5d-65bf44d2e5c5" && (item.statusen !== "Waiting Buyer Approval" && item.statusen !== "Needs More Details" && item.statusen !== "Buyer Rejected") && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestHorseOwnershipTransferSeller?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                            );
                          }}
                        // to={
                        //   `/requestHorseOwnershipTransferSeller?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                        // }
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }

                    {
                      //covering certificate
                    }
                    {
                      item.servicedefinitionid === "25341e00-bc23-49d7-99bd-61c9f976e73a" && (item.statusen === "Wating Stallion Owner Approval" || item.statusen === "Needs More Details") && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestCoveringCertificate?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                            );
                          }}
                        // to={
                        //   `/requestCoveringCertificate?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                        // }
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "25341e00-bc23-49d7-99bd-61c9f976e73a" && item.statusen === "Stallion Owner Rejected" && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestCoveringCertificate?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=edit`
                            );
                          }}
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "25341e00-bc23-49d7-99bd-61c9f976e73a" && (item.statusen !== "Wating Stallion Owner Approval" && item.statusen !== "Needs More Details" && item.statusen !== "Stallion Owner Rejected") && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestCoveringCertificate?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                            );
                          }}
                        // to={
                        //   `/requestCoveringCertificate?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                        // }
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }

                    {
                      //embryo Oocyte Transfer Permit
                    }
                    {
                      item.servicedefinitionid === "9fe50fc8-618a-4ca1-a2d4-4afc69cf5ace" && (item.statusen === "Needs More Details" || (item.paymentstatus === "Pendding" && item.requeststype === "BackOffice")) && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestEmbryoOocyteTransferPermit?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                            );
                          }}
                        // to={
                        //   `/requestEmbryoOocyteTransferPermit?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                        // }
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "9fe50fc8-618a-4ca1-a2d4-4afc69cf5ace" && item.statusen !== "Needs More Details" && item.statusen !== "Waiting Mare Owner Approval" && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestEmbryoOocyteTransferPermit?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                            );
                          }}
                        // to={
                        //   `/requestEmbryoOocyteTransferPermit?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                        // }
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }

                    {
                      //Embryo Transfer Right Purchace Agreement
                    }
                    {
                      item.servicedefinitionid === "80d4c794-37da-4b16-938f-18bb8b1c7326" && (item.statusen === "Waiting Buyer Approval" || item.statusen === "Needs More Details") && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestEmbryoTransferRightPurchaceAgreement?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                            );
                          }}
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "80d4c794-37da-4b16-938f-18bb8b1c7326" && item.statusen === "Buyer Rejected" && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestEmbryoTransferRightPurchaceAgreement?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=edit`
                            );
                          }}
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "80d4c794-37da-4b16-938f-18bb8b1c7326" && (item.statusen !== "Waiting Buyer Approval" && item.statusen !== "Needs More Details" && item.statusen !== "Buyer Rejected") && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestEmbryoTransferRightPurchaceAgreement?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                            );
                          }}
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }

                    {
                      //Registering New Arabian Horse Foal
                    }
                    {
                      item.servicedefinitionid === "e15193c7-36cd-4280-bd02-d8799a7512df" && (item.statusen === "Needs More Details" || (item.paymentstatus === "Pendding" && item.requeststype === "BackOffice")) && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestRegisteringNewArabianHorseFoal?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=approve`
                            );
                          }}
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                    {
                      item.servicedefinitionid === "e15193c7-36cd-4280-bd02-d8799a7512df" && item.statusen !== "Needs More Details" && (
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNotificationClick(
                              `/requestRegisteringNewArabianHorseFoal?serviceId=${item.servicedefinitionid}&id=${item.serviceid}&action=view`
                            );
                          }}
                        >
                          <i className="fa-regular fa-envelope"></i>
                          {
                            item.referencenumber
                          }
                          <p>
                            {currentLanguage === "en"
                              ? item.statusen
                              : item.statusar}
                          </p>
                        </Link>
                      )
                    }
                  </div>
                ))}
            </div>
          </div>
        </>
      )} */}
      {isOverlayVisible && (
        <div
          className="overlayMenu"
          style={{ display: "block" }}
          onClick={handleOverlayClick}
        ></div>
      )}
    </div>
  );
}
