import React, { useEffect, useState } from 'react';
import { useTranslate } from '../components/TranslationText';
import { useSelector } from 'react-redux';
import axios from "../axios/axiosUmbraco";
import Loader from '../components/Loader';
import ActionButton from '../components/ActionButton';
import FilterButton from '../components/FilterButton';
import Lightbox from 'react-18-image-lightbox';
import EducationalFilter from '../components/EducationalFilter';
import ComingSoon from '../components/ComingSoon';
import { showFirst12Words, showFirst22Words } from '../helper/showFirstSixWords';
import { translateMonthToArabic } from '../helper/translateDayToArabic';

const BaseURL = process.env.REACT_APP_BASE_URL;
const fetch = process.env.REACT_APP_UMBRACO_EDUCATIONAL_PAGE_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function EducationalPage() {
    const { translate } = useTranslate();
    const { currentLanguage } = useSelector((state) => state.studBookSlice);

    const itemsPerPage = 12;
    const [visibleItems, setVisibleItems] = useState(itemsPerPage);
    const [latestNews, setLatestNews] = useState([]);
    const [allNews, setAllNews] = useState([]);
    const [filteredNews, setFilteredNews] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showmoreEnabled, setShowmoreEnabled] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [index, setIndex] = useState(-1);
    const [images, setImages] = useState([]);
    const [categoriesList, setCategoriesList] = useState(['all']);

    const currentImage = images[index];

    function getCurrentLanguageUmbraco() {
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang === "en") return 'en-US';
        else return 'ar-AE';
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function formatDateArray(incomingdate) {
        const dateTime = new Date(incomingdate);
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const date = new Date(dateTime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = months[date.getMonth()];
        const year = String(date.getFullYear());
        return [day, month, year];
    }

    async function getdata() {
        setIsLoading(true);
        const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=children:${fetch}&skip=0&take=2000`, {
            headers: {
                "accept-language": getCurrentLanguageUmbraco(),
            },
        });

        const formattedData = umbraco.data?.items.map((item) => ({
            id: item.id,
            Item_CoverImage: item.properties?.image ? item.properties?.image[0]?.url : '',
            Item_Date: item.properties.date ? formatDateArray(item?.properties?.date) : '',
            Item_ShortDescription: item?.properties?.subTitle ?? '',
            Item_Category: item?.properties?.category ?? 'all',
            Item_Title: item?.properties?.title ?? '',
            Item_Date_Normal: item.properties.date ? new Date(item?.properties?.date) : '',
            Item_Video_Link: MediaBaseURL + item?.properties?.videoFile ?? ''
        }));

        setCategoriesList(Array.from(new Set(formattedData.filter((y) => y.Item_Category != 'all').map((x) => x.Item_Category))));

        const sortedData = formattedData.sort((b, a) => a.Item_Date_Normal - b.Item_Date_Normal);
        const videos = formattedData.map((video, index) => ({
            src: video.Item_Video_Link,
            width: 320,
            isvideo: true,
            height: 174,
            caption: 'video',
        }));
        setImages(videos)
        setLatestNews(sortedData);
        setAllNews(sortedData);
        setFilteredNews(sortedData);
        setTotal(umbraco.data?.total);
        setIsLoading(false);
    }

    useEffect(() => {
        getdata();
    }, [currentLanguage]);

    const loadMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
    };

    const handleFilter = (filters) => {
        setShowmoreEnabled(false);
        const filteredData = latestNews.filter(item => {
            const { coursTitle, coursecategory, DateFrom, DateTo } = filters;

            const matchTitle = item.Item_Title.toLowerCase().includes(coursTitle.toLowerCase());
            const matchCategory = coursecategory === 'all' || item.Item_Category === coursecategory;
            const matchDate = (!DateFrom || new Date(item.Item_Date_Normal) >= new Date(DateFrom)) &&
                (!DateTo || new Date(item.Item_Date_Normal) <= new Date(DateTo));

            return matchTitle && matchCategory && matchDate;
        });

        setFilteredNews(filteredData);
        setVisibleItems(itemsPerPage);  // Reset pagination
    };

    const handleClose = () => setIndex(-1);
    function getVideoFrame(index) {
        return (<video style={{ position: 'relative', zIndex: 100, }} width="100%" height="80%" controls autoPlay><source src={images[index].src} type="video/mp4" /></video>);
    }

    return (
        <>
            {isLoading && <Loader />}
            {total === 0 && !isLoading && <ComingSoon />}
            {total > 0 && (
                <div className="container-fluid px75">
                    <div className="row m0 ">
                        <h2 className="col-sm-12 p0 bold rtl capatalise">
                            {translate('web.educational', 'Educational')}
                        </h2>
                    </div>
                    <div className="row m0 rtl">
                        {windowWidth <= 970 && (
                            <div className="col-md-2 col-sm-3 text-center">
                                <FilterButton onClick={() => setShowFilter(!showFilter)} />
                            </div>
                        )}
                        <div className="col-lg-3 col-md-3 col-sm-12 NewsFilterContainer" style={{ display: windowWidth > 970 || showFilter ? 'block' : 'none' }}>
                            <EducationalFilter onSubmit={handleFilter} categoriesList={categoriesList} />
                        </div>

                        <div className="col-lg-9 col-md-9 col-sm-12">
                            <div className="row m0 rtl">
                                <div className="row m0 rtl">
                                    {filteredNews.length > 0 ? (
                                        filteredNews.slice(0, visibleItems).map((item, index) => (
                                            <div className="col-lg-4 col-md-6 px-5px col-sm-12" key={index} style={{ position: "relative" }}>
                                                <div onClick={() => setIndex(index)} style={{ cursor: 'pointer' }}>
                                                    <div style={{ borderRadius: 5, minHeight: 475, margin: '5px 0', overflow: 'hidden' }}>
                                                        <div style={{position:'relative'}}>
                                                        <div style={{width:'100%' , maxHeight:200  , overflow:'hidden'}}>
                                                            <img src={`${MediaBaseURL}${item.Item_CoverImage}`} className="card-img-top newsImage " alt="..." />
                                                        </div>
                                                            <div className='playButtonContainer'><i className="fa-regular fa-circle-play"></i></div>
                                                        </div>
                                                        <div className="card-body px-0">
                                                            <h5 className="card-title px-0 newsPageNewsTitle">{showFirst12Words(item.Item_Title)}</h5>
                                                            <p className="card-text px-0 newsPageNewsDescription">{showFirst22Words(item.Item_ShortDescription)}</p>
                                                            <div className="newsPageNewsDateContainer">
                                                                <div className="newsPageNewsDay">{item.Item_Date[0]}</div>
                                                                <div className="newsPageDateSpacer"></div>
                                                                <div className="newsPageMonthAndYear">
                                                                    <span>{translateMonthToArabic(item.Item_Date[1], getCurrentLanguageUmbraco())}</span>
                                                                    <span>{item.Item_Date[2]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="text-center bold color-eahsred">
                                            {translate('web.nodataFound', 'No News Found with Your Filter Parameters')}
                                        </div>
                                    )}
                                </div>
                                {visibleItems < filteredNews.length && (
                                    <div className="col-lg-12 col-md-12 text-center my-5">
                                        <ActionButton label={translate('web.showMore', 'Show More')} type={'loadmore'} onClick={loadMore} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={images[index].src}
                    onCloseRequest={handleClose}
                    wrapperClassName='galleryContainer'
                    enableZoom={false}
                    imageLoadErrorMessage={getVideoFrame(index)}
                />
            )}
        </>
    );
}
