import React, { useEffect } from "react";
import { useTranslate } from "../components/TranslationText";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchShowEntrybyIdthunk } from "../Redux/ShowEntry/ShowEntryAction";

export default function ShowEntrySubmissionSuccessful() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { translate } = useTranslate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const showId = searchParams.has("showId") ? searchParams.get("showId") : null;

  const isRegister = searchParams.has("isRegister")
    ? searchParams.get("isRegister")
    : null;

  const entryFees = searchParams.has("entryFees")
    ? searchParams.get("entryFees")
    : null;

  const waitingHorsesList = searchParams.has("waitingHorsesList")
    ? searchParams.get("waitingHorsesList")
    : null;

  const dispatch = useDispatch();

  const { showEntrybyIdData } = useSelector((state) => state.showEntrySlice);

  useEffect(() => {
    async function getShowEntrybyId() {
      let paramsShowEntrybyId = {
        id: showId,
        langauge: currentLanguage,
      };
      dispatch(FetchShowEntrybyIdthunk(paramsShowEntrybyId));
    }

    getShowEntrybyId();
  }, [dispatch, showId, currentLanguage]);

  return (
    <div className="container-fluid container-fluid-padding row">
      {
        // title
      }
      <div className="col-md-12 col-sm-12  mt-15 mb-25">
        <h1 className="fontWeight">{showEntrybyIdData.showName}</h1>
      </div>
      {
        //text
      }
      <div className="col-md-12 col-sm-12 text-center">
        <h1 className="color-green">
          {isRegister != null && isRegister === "false"
            ? translate("web.submissionSuccessful", "Submitted Successful!")
            : translate(
                "web.paymentCompletedSuccessful",
                "Payment Completed Successful!"
              )}
        </h1>
        <span className="horseCardlabelContent">
          {isRegister != null &&
            isRegister === "false" &&
            entryFees == null &&
            translate(
              "web.pendingreq",
              "International horses requests will be under the approval process."
            )}
        </span>
        <span className="horseCardlabelContent">
          {waitingHorsesList === "true" &&
            translate(
              "web.waitingHorsesList",
              "There are horses in waiting horses list."
            )}
        </span>
        {
          // <h4 className="graycolor titlepleaseRegisterHorsesMax">
          //   {translate(
          //     "web.pleaseRegisterHorsesMax",
          //     `Please note you register for up to ${maxHorses} horses max`
          //   )}
          // </h4>
        }
        <Link
          type="button"
          className="btn buttonformshowentrycard height46 linkbtn mt-50"
          to={`/profile`}
        >
          <span className="ml-10 margin-left-unset">
            <i class="fa-solid fa-arrow-left fa-lg marginrightleft-10"></i>
            {translate("web.back", "Back")}
          </span>
        </Link>
      </div>
    </div>
  );
}
