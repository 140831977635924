import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
import ActionButton from "./ActionButton";
import NetaqInput from "./NetaqInput";
import { getOrdinal } from "../helper/getOrdinal";
import { convertToCamelCase } from "../helper/convertToCamelCase";
import Popup from "reactjs-popup";
import UploadFile from "./SimpleFileUpload";
import { extractChars } from "../helper/extractChart";
import { Link } from "react-router-dom";

export default function ParticipateHistory({ toast }) {
  const { translate } = useTranslate();
  const [ParticipateHistory, setParticipateHistory] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const itemsPerPage = 20;
  const [take, settake] = useState(itemsPerPage);
  const [searchByHorseName, setSearchByHorseName] = useState(null);
  const [searchByShowName, setSearchByShowName] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ParticipateLoaded, setParticipatesLoaded] = useState(false);
  const [noParticipatesFound, setNoParticipatesFound] = useState(false);

  const lang = localStorage.getItem('eahsLang') ?? 'en';

  async function getParticipateHistory() {
    try {
      const response = await axiosInstance.post(
        "shows/FilterShowParticipantHistory", {
        skip: skip,
        take: take,
        showName: searchByShowName,
        horseName: searchByHorseName,
        startDate: fromDate?.toDate(),
        endDate: toDate?.toDate()
      }
      );
      if (response.data.responseCode === "200") {
        const Participates = response.data.result;
        setTotalCount(response.data.totalCount);
        setParticipateHistory(Participates);
        setNoParticipatesFound(Participates.length === 0);
        setParticipatesLoaded(true);
      } else {
        console.log("error in loading Participates");
        setParticipatesLoaded(true);
      }
    } catch (error) {
      console.log("error in loading Participates", error);
      setParticipatesLoaded(true);
    }
  }

  useEffect(() => {
    getParticipateHistory();
  }, [skip]); // Fetch data on `skip` change for pagination

  const handleReset = () => {
    setSearchByHorseName('');
    setSearchByShowName('');
    setFromDate(null);
    setToDate(null);
    setSkip(0);
    setCurrentPage(1);

    // Fetch data without filters after resetting
    axiosInstance.post("shows/FilterShowParticipantHistory", {
      skip: 0,
      take: itemsPerPage,
      showName: null,
      horseName: null,
      startDate: null,
      endDate: null
    })
      .then(response => {
        if (response.data.responseCode === "200") {
          const Participates = response.data.result;
          setTotalCount(response.data.totalCount);
          setParticipateHistory(Participates);
          setNoParticipatesFound(Participates.length === 0);
        } else {
          console.log("error in loading Participates");
        }
      })
      .catch(error => {
        console.log("error in loading Participates", error);
      })
      .finally(() => {
        setParticipatesLoaded(true);
      });
  };

  const handleSubmit = () => {
    setSkip(0); // reset to first page on submit
    setCurrentPage(1);
    getParticipateHistory();
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      setSkip((newPage - 1) * itemsPerPage);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(totalCount / itemsPerPage);
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      setSkip(newPage * itemsPerPage - itemsPerPage);
    }
  };

  // withdrawRequest
  const [withdrawReason, setWithDrawReason] = useState('');
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [midicalReportFile, setMidicalReportFile] = useState([]);
  const [ismidicalReason, setIsMidicalReason] = useState(true);
  const handleUploadFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      let checkFileSize = true;
      const fileListHook = [];

      for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
        if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
          checkFileSize = false;
          toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
        } else {
          fileListHook.push({
            documentFile: URL.createObjectURL(e.target.files[fileIndex]),
            name: e.target.files[fileIndex].name,
            file: e.target.files[fileIndex],
          });
        }
      }

      setMidicalReportFile(fileListHook);
    }
  };
  async function addWithdrawRequestWhileRegisterIsOpen(id, close) {
    try {
      setIsSendingRequest(true);
      const formData = new FormData();
      formData.append('id', id);
      formData.append('withdrawReason', withdrawReason);
      const response = await axiosInstance.post('showentry/createwithdrawrequest', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        }
      });
      if (response.data.responseCode == '200') {
        toast.success(translate('web.yourRequestIsSentSuccessfully', 'Your Request Is Sent Successfully'));
        setIsSendingRequest(false);
        handleReset();
        setMidicalReportFile([]);
        setWithDrawReason('');
        close();
      } else {
        toast.error(translate('web.unexpectedError', 'Unexpected Error Please Contact the Admin'));
        setIsSendingRequest(false);
        handleReset();
        setMidicalReportFile([]);
        setWithDrawReason('');
      }
    } catch (error) {
      setIsSendingRequest(false);

    }
  }
  async function addWithdrawRequestWhileRegisterIsclosed(id, close) {
    try {
      if (ismidicalReason == true) {
        if (midicalReportFile.length == 0) {
          toast.error(translate('web.medicalReportIsRequired', 'Medical Report Is Required'))
          return;
        }
      }
      setIsSendingRequest(true);
      const formData = new FormData();
      formData.append('id', id);
      formData.append('withdrawReason', withdrawReason);
      if (!!midicalReportFile) {
        formData.append('withdrawAttachmentFile', midicalReportFile[0]?.file);
      }
      formData.append('isMedicalWithdraw', ismidicalReason);
      const response = await axiosInstance.post('showentry/createwithdrawrequest', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        }
      })

      if (response.data.responseCode == '200') {
        toast.success(translate('web.yourRequestIsSentSuccessfully', 'Your Request Is Sent Successfully'));
        setIsSendingRequest(false);
        handleReset();
        setMidicalReportFile([]);
        setWithDrawReason('');
        close();
      } else {
        toast.error(translate('web.unexpectedError', 'Unexpected Error Please Contact the Admin'));
        setIsSendingRequest(false);
        handleReset();
        setMidicalReportFile([]);
        setWithDrawReason('');
      }
    } catch (error) {
      setIsSendingRequest(false);
    }
  }

  return (
    <>
      {ParticipateLoaded ? (
        <div className="col-lg-12 col-md-12 col-sm-12 p0">
          <h3 className="col-lg-12 col-md-12 col-sm-12 bold rtl p0">
            {translate("web.participateHistory", "Participate History")}
          </h3>
          <div className="filter-controls rtl mb-3">
            <div
              className="row p0"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                <NetaqInput
                  type={"text"}
                  label={translate("web.horseName", "Horse Name")}
                  placeholder={translate("web.horseName", "Horse Name")}
                  onChange={(e) => setSearchByHorseName(e.target.value)}
                  value={searchByHorseName}
                  name={translate("web.horseName", "Horse Name")}
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                <NetaqInput
                  type={"text"}
                  label={translate("web.showName", "Show Name")}
                  placeholder={translate("web.showName", "Show Name")}
                  onChange={(e) => setSearchByShowName(e.target.value)}
                  value={searchByShowName}
                  name={translate("web.showName", "Show Name")}
                />
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 mt-2" style={{ minWidth: 160 }}>
                <NetaqInput
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e)}
                  label={translate("web.from", "From")}
                  name={translate("web.from", "From")}
                />
              </div>
              <div className="col-lg-1 col-md-2 col-sm-12 mt-2" style={{ minWidth: 160 }}>
                <NetaqInput
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e)}
                  label={translate("web.to", "To")}
                  name={translate("web.to", "To")}
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 mt-3" style={{ display: "flex" }}>
                <ActionButton
                  type={"resetandfetch"}
                  margin={"5px auto 0 auto"}
                  label={translate("web.reset", "Reset")}
                  text={translate("web.reset", "Reset")}
                  onClick={handleReset}
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-12 mt-3" style={{ display: "flex" }}>
                <ActionButton
                  type={"submit"}
                  margin={"5px auto 0 auto"}
                  label={translate("web.search", "Search")}
                  text={translate("web.search", "Search")}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </div>
          <hr />

          {ParticipateHistory.length > 0 ? (
            <div className="scrolableTable">
              <table className="table table-striped table-bordered rtl horseResultTable">
                <thead className="theadHorseSelect">
                  <tr>
                    <th className="text-center">
                      {translate("web.horseName", "Horse Name")}
                    </th>
                    <th className="text-center">
                      {translate("web.showName", "Show Name")}
                    </th>
                    <th className="text-center">
                      {translate("web.showrank", "Show Rank")}
                    </th>
                    <th className="text-center">
                      {translate("web.showDate", "Show Date")}
                    </th>
                    <th className="text-center">
                      {translate("web.categories", "Categories")} / {translate("web.classes", "Classes")}
                    </th>
                    <th className="text-center">
                      {translate("web.rank", "Rank")}
                    </th>
                    <th className="text-center">
                      {translate("web.medal", "Medal")}
                    </th>
                    <th className="text-center">
                      {translate("web.withdrawStatus", "Withdraw Status")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {ParticipateHistory.map((item, index) => (
                    <tr key={index}>
                      <td className="rtl">{item.horseName}</td>
                      <td className="rtl" >{item.showName}</td>
                      <td className="rtl">{item.showRank}</td>
                      <td className="rtl">{item.showStartDate}</td>
                      <td className="rtl">{item.categoryClassName}</td>
                      <td className="rtl" dangerouslySetInnerHTML={{ __html: !!item.rank ? getOrdinal(item.rank, lang) : '' }}></td>
                      <td className="rtl">{!!item.medal ? translate(`web.${convertToCamelCase(item.medal)}`, item.medal) : ''}</td>
                      <td className="rtl">
                        {(item.withdrawStatus == 'None' && item.withdrawStatus != null) ? <>{translate(`web.${convertToCamelCase(item.withdrawStatus)}`, item.withdrawStatus)}</>
                          :
                          <>
                            {item.isAllowedToRequest ?
                              <Popup modal
                                lockScroll
                                closeOnDocumentClick={false}
                                closeOnEscape
                                style={{ background: "red" }}
                                contentStyle={{
                                  width: "100%",
                                  maxWidth: 1000,
                                  // height: 400,
                                  zIndex: 5000000,
                                  padding: 30
                                }}
                                trigger={lang == 'en' ? <ActionButton type={'normalbtn'} className="tooltip" text={`<i class="fa-solid fa-right-from-bracket"></i><span class="tooltiptext" style="left:-100px !important;">Withdraw Request</span>`} /> : <ActionButton type={'normalbtn'} className="tooltip" text={`<i class="fa-solid fa-right-from-bracket"></i><span class="tooltiptext" style="right:-100px !important;">طلب انسحاب</span>`} />}>
                                {(close) => (
                                  <>
                                    {item.isShowRegisterOpend ?
                                      <form className="row text-start rtl mx-0" onSubmit={(e) => { e.preventDefault(); addWithdrawRequestWhileRegisterIsOpen(item.id, close) }}>
                                        <h5 className="bold px-0 mb-3">{translate('web.addWithdrawRequest', 'Add Withdraw Request')}</h5>
                                        <div className="row rtl mx-0 py-2" style={{ border: '1px solid #C89C66', background: 'rgb(252, 250, 247)', borderRadius: 10 }}>
                                          <NetaqInput textareaRows={3} textAreaHeight="50px" label={translate('web.withdrawReason', 'withdrawReason')} type={'textarea'} onChange={(e) => setWithDrawReason(e.target.value)} required={true} name={'withdrawReason'} />
                                        </div>
                                        <div className="col-md-6 col-sm-12 text-center my-1">
                                          <ActionButton type={'btn'} text={translate('web.close', 'Close')} onClick={() => { setWithDrawReason(''); close(); }} />
                                        </div>
                                        <div className="col-md-6 col-sm-12 text-center my-1">
                                          {isSendingRequest ? <Loader /> :
                                            <ActionButton type={'submit'} text={translate('web.submit', 'Submit')} onClick={() => (console.log('as'))} />
                                          }
                                        </div>
                                      </form>
                                      :
                                      <form className="row text-start rtl mx-0" onSubmit={(e) => { e.preventDefault(); addWithdrawRequestWhileRegisterIsclosed(item.id, close) }}>
                                        <h5 className="bold px-0 mb-3">{translate('web.addWithdrawRequest', 'Add Withdraw Request')}</h5>
                                        <div className="row rtl mx-0 pb-2" style={{ border: '1px solid #C89C66', background: 'rgb(252, 250, 247)', borderRadius: 10 }}>
                                          <div className="d-flex my-2">
                                            <div>
                                              <NetaqInput label={translate('web.isMidicalReason', 'Is Midical Reason')} type={'radio'} onChange={(e) => setIsMidicalReason(true)} required={true} checked={ismidicalReason} name={'withdrawReason'} />
                                            </div>
                                            <div className="ms-5">
                                              <NetaqInput label={translate('web.other', 'Other')} type={'radio'} onChange={(e) => setIsMidicalReason(false)} required={true} checked={!ismidicalReason} name={'withdrawReason'} />
                                            </div>
                                          </div>
                                          <div className="col-md-6 col-sm-12 mt-2">
                                            <NetaqInput textareaRows={3} textAreaHeight="40.4px" label={translate('web.withdrawReason', 'withdrawReason')} type={'textarea'} onChange={(e) => setWithDrawReason(e.target.value)} required={!ismidicalReason} name={'withdrawReason'} />
                                          </div>
                                          <div className="col-md-6 col-sm-12 mt-2">
                                            <label>{translate('web.midicalReport', 'Medical Report')} {ismidicalReason ? <span style={{ color: 'red' }}>*</span> : ''} </label>
                                            <UploadFile
                                              key={'anykey' + index}
                                              initialFiles={midicalReportFile}
                                              text={translate('web.uploadMedicalReport', 'Upload Medical Report')}
                                              ismultiFile={false}
                                              isServer={false}
                                              name={`medicalreport`}
                                              className="text-center "
                                              onChange={(e) => handleUploadFile(e)}
                                            />

                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 text-center mt-3">
                                          <ActionButton type={'btn'} text={translate('web.close', 'Close')} onClick={() => { setWithDrawReason(''); setMidicalReportFile([]); close(); }} />
                                        </div>
                                        <div className="col-md-6 col-sm-12 text-center mt-3">
                                          {isSendingRequest ? <Loader /> :
                                            <ActionButton type={'submit'} text={translate('web.submit', 'Submit')} onClick={() => (console.log('as'))} />
                                          }
                                        </div>
                                      </form>
                                    }
                                  </>
                                )}
                              </Popup>
                              : <>{translate(`web.${convertToCamelCase(item.withdrawStatus)}`, item.withdrawStatus)}</>
                            }
                          </>}

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="pagination-buttons rtl" style={{ display: "flex", alignItems: "center" }}>
                <ActionButton
                  type={"normalbtn"}
                  className="btn"
                  text={translate("web.prev", "Prev")}
                  margin={"auto 10px"}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                />
                <span>{`${currentPage} / ${Math.ceil(totalCount / itemsPerPage)}`}</span>
                <ActionButton
                  type={"normalbtn"}
                  className="btn"
                  text={translate("web.next", "Next")}
                  margin={"auto 10px"}
                  onClick={handleNextPage}
                  disabled={currentPage === Math.ceil(totalCount / itemsPerPage)}
                />
              </div>
            </div>
          ) : (
            <div className="text-center text-danger">
              {translate("web.nodataFound", "No Result Found")}
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
