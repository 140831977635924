export function getDayString(dayString, lang) {
    // Extract the number from the dayString
    const dayNumber = parseInt(dayString.match(/\d+/)[0], 10);

    // Define English and Arabic translations for days 1 to 20
    const daysEn = [
        "Day 1", "Day 2", "Day 3", "Day 4", "Day 5",
        "Day 6", "Day 7", "Day 8", "Day 9", "Day 10",
        "Day 11", "Day 12", "Day 13", "Day 14", "Day 15",
        "Day 16", "Day 17", "Day 18", "Day 19", "Day 20"
    ];

    const daysAr = [
        "اليوم الأول", "اليوم الثاني", "اليوم الثالث", "اليوم الرابع", "اليوم الخامس",
        "اليوم السادس", "اليوم السابع", "اليوم الثامن", "اليوم التاسع", "اليوم العاشر",
        "اليوم الحادي عشر", "اليوم الثاني عشر", "اليوم الثالث عشر", "اليوم الرابع عشر", "اليوم الخامس عشر",
        "اليوم السادس عشر", "اليوم السابع عشر", "اليوم الثامن عشر", "اليوم التاسع عشر", "اليوم العشرون"
    ];

    // Check if the number is within the range
    if (dayNumber >= 1 && dayNumber <= 20) {
        return lang === 'ar' ? daysAr[dayNumber - 1] : daysEn[dayNumber - 1];
    } else {
        return "Day out of range"; // Handle days outside 1-20 range
    }
}