import React from "react";

export const ColorScheme = {
  PrimaryGold: "#C89C66",
  PrimaryRed: "#E44D52",
};

export default function SiteMap({
  siteTree,
  bgColor = ColorScheme.PrimaryGold,
}) {
  return (
    <div
    className="right-to-left siteMapStyle"
      style={{
        background: bgColor,
      }}
    >
      {siteTree.map((item, index) => (
        <React.Fragment key={index}>
          <span className="margin_auto_zero capatalize_text">
            {item}
          </span>
          {index < siteTree.length - 1 && (
            <span className="margin_auto_20">{">"}</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
