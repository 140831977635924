import React, { useEffect, useState } from 'react';
import ActionButton from './ActionButton';
import { useTranslate } from './TranslationText';
import axiosInstance from '../axios/axios';
import { formatDate } from '../helper/formatDate';

export default function OrginizingRequests() {
    const { translate } = useTranslate();

    // Static data
    // const myRequests = [
    //     { showName: 'testing name', submitDate: '29/01/2024', status: 'Approved', action: null },
    //     { showName: 'testing name', submitDate: '29/01/2024', status: 'Draft', action: 'edit' },
    //     { showName: 'testing name', submitDate: '29/01/2024', status: 'Returned', action: 'edit' },
    //     { showName: 'testing name', submitDate: '29/01/2024', status: 'Rejected', action: null },
    // ];

    const [myRequests,setMyRequests] = useState([]);

    async function getMyRequests() {
        try {
            const request = await axiosInstance.get('shows/getmyshoworganizerequest')
            if(request.data.responseCode == "200"){
                const formattedRequests = request.data.result.map((res)=>({
                    showName:res.showName,
                    submitDate: formatDate (res.createdDate),
                    status:res.status,
                    action:res.status == "Draft" || res.status == "Returned" ? 'edit':res.status == "Approved" ? 'view':null,
                    Id:res.id,
                    showId:res.showId
                }))
                setMyRequests(formattedRequests);
            }
        } catch (error) {
            console.error('error in getting requests')
        }
        
    } 
    useEffect(()=>{
        getMyRequests();
    },[])

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calculate the start and end indexes for the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = myRequests.slice(indexOfFirstItem, indexOfLastItem);

    // Logic for Next and Previous buttons
    const totalPages = Math.ceil(myRequests.length / itemsPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    return (
        <>
            <div className='col-lg-12 col-md-12 col-sm-12 p0'>
                <h3 className='col-lg-12 col-md-12 col-sm-12 bold rtl p0'>
                    {translate('web.orginizingRequests', 'Show Orginizing Requests')}
                </h3>
                <div className='my-2'>
                <ActionButton type={'internalLink'} to={'/add-organizer-request'} text={translate('web.addNewRequest' ,'Add New Request')} />
                </div>
                
                {myRequests.length > 0 ? (
                    <div className='scrolableTable'>
                        <table className="table table-striped table-bordered rtl horseResultTable">
                            <thead className="theadHorseSelect">
                                <tr>
                                    <th className="text-center">
                                        {translate('web.showName', 'Show Name')}
                                    </th>
                                    <th className="text-center">
                                        {translate('web.dateOfSubmit', 'Date of Submit')}
                                    </th>
                                    <th className="text-center">
                                        {translate('web.status', 'Status')}
                                    </th>
                                    <th className="text-center">
                                        {translate('web.action', 'Action')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                                {currentItems.map((item, index) => (
                                    <tr key={index}>
                                        <td className='rtl'>{item.showName}</td>
                                        <td className='rtl'>{item.submitDate}</td>
                                        <td className={`rtl text-center ${item.status.toLowerCase()}`}>
                                            {translate(`web.${item.status.toLowerCase()}`, item.status)}
                                        </td>
                                        <td className='rtl'>
                                            {item.action === 'edit' ? (
                                               <ActionButton type={'internalLink'} to={`/add-organizer-request?isEdit=true&requestId=${item.Id}`} text={'<i class="fas fa-edit"></i>'} />
                                            ) :
                                            item.action === 'view' ?
                                            (
                                                <ActionButton type={'internalLink'} to={`/showentrydetails?showId=${item.showId}`} text={'<i class="fa fa-eye" aria-hidden="true"></i>'} />

                                            )
                                            :(
                                                '-'
                                            )
                                        }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        
                        {/* Pagination buttons */}
                        <div className="pagination-buttons rtl" style={{ display: 'flex', alignItems: 'center' }}>
                            <ActionButton
                                type={'normalbtn'}
                                onClick={prevPage}
                                disabled={currentPage === 1}
                                className="btn"
                                text={translate('web.prev', 'Prev')}
                                margin={'auto 10px'}
                            />
                            <span>{`${indexOfFirstItem + 1} - ${Math.min(indexOfLastItem, myRequests.length)} ${translate('web.of', 'of')} ${myRequests.length}`}</span>
                            <ActionButton
                                type={'normalbtn'}
                                onClick={nextPage}
                                margin={'auto 10px'}
                                disabled={currentPage === totalPages}
                                className="btn"
                                text={translate('web.next', 'Next')}
                            />
                        </div>
                    </div>
                ) : (
                    <div className="text-center text-danger">
                        {translate('web.nodataFound', 'No Result Found')}
                    </div>
                )}
            </div>
        </>
    );
}
