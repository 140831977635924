import React, { useState, useEffect } from "react";
import LoginForm from "../components/LoginForm";
import { useLocation } from "react-router";
import SuccessMessage from "../components/SuccessMessage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function LoginPage() {
  const [isWideScreen, setIsWideScreen] = useState(true);
  
  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 600);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const currentlocation = useLocation();
  const logoutUAEPASS = process.env.REACT_APP_UAE_PASS_LOGOUT_LINK;
  const RedirectUAEPASS = process.env.REACT_APP_UAE_PASS_REDIRECT_LINK;
  const langauge = localStorage.getItem('eahsLang') ?? 'en';


  function showToastmessage(message){
    toast.error(message);

  }

  useEffect(() => {

    if (currentlocation?.state !== null) {
      if (currentlocation.state.message !== undefined || currentlocation.state.message !== null) {
       
        setMessage(currentlocation.state.message);
        setShowMessage(true);
        toast.error(currentlocation.state.message);

        // window.location.href = `${logoutUAEPASS}?redirect_uri=${RedirectUAEPASS}`
        if(currentlocation.state !== 'Error in Login with UAE PASS'){
 // Wait for 5 seconds
 setTimeout(() => {
  localStorage.setItem("EAHSUserIsUAEPASSUser", false);

  window.location.href = `${logoutUAEPASS}?redirect_uri=${RedirectUAEPASS}`;

}, 10000);
        }

       
      }
    }
  }, [])

  const dismissAll = () => toast.dismiss();

  return (
    <>
      <ToastContainer position="top-right" onClick={dismissAll} theme="colored" autoClose={10000} />

      <div className="LoginPage">
        {isWideScreen && <div style={{ flex: 1 }}></div>}
        {/* <div className="LoginPageContent" >
          <img
          className="LoginPageBG"
            src={`${BaseURL}/assets/img/Longinnew.png`}
            alt="loginbg"
            style={{opacity:1 , top:0 , right:'10%' }}
          />
        </div> */}
        <div className="LoginFormContainer">
          <LoginForm showToastmessage={showToastmessage} />
        </div>
      </div>
    </>
  );
}
 