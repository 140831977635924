import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
import ActionButton from "./ActionButton";
import NetaqInput from "./NetaqInput";
import { formatDate } from "../helper/formatDate";
import { convertToCamelCase } from "../helper/convertToCamelCase";

export default function PaymentHistory() {
  const { translate } = useTranslate();
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [paymentLoaded, setPaymentsLoaded] = useState(false);
  const [noPaymentsFound, setNoPaymentsFound] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const paymentsPerPage = 20;

  // Filter states
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [paidStatus, setPaidStatus] = useState("paid"); // Added state for paid status

  async function getPaymentHistory() {
    try {
      const response = await axiosInstance.get(
        "payment/getpaymenttransactionbyuserid"
      );
      if (response.data.responseCode == "200") {
        const payments = response.data.result;
        const SortedArray = payments.sort(
          (b, a) => new Date(a.createdDate) - new Date(b.createdDate)
        );

        setPaymentHistory(SortedArray);
        if (payments.length === 0) {
          setNoPaymentsFound(true);
        }
        setPaymentsLoaded(true);
      } else {
        setPaymentsLoaded(true);
        console.log("error in loading payments");
      }
    } catch (error) {
      console.log("error in loading payments", error);
      setPaymentsLoaded(true);
    }
  }

  useEffect(() => {
    getPaymentHistory();
  }, []);

  // Filter the paymentHistory based on the filter states
  const filteredPayments = paymentHistory.filter((item) => {
    const itemDate = new Date(item.createdDate);
    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;

    // Filter by date range
    const isWithinDateRange =
      (!from || itemDate >= from) && (!to || itemDate <= to);

    // Filter by reference number
    const matchesReferenceNumber =
      referenceNumber.toLowerCase() === "" ||
      item.refranceNumber.toLowerCase().includes(referenceNumber.toLowerCase());

    // Filter by paid status
    const matchesPaidStatus =
      paidStatus === "" ||
      (paidStatus === "paid" && item.isPaid) ||
      (paidStatus === "notPaid" && !item.isPaid);

    return isWithinDateRange && matchesReferenceNumber && matchesPaidStatus;
  });

  // Calculate the start and end indexes for the current page
  const indexOfLastPayment = currentPage * paymentsPerPage;
  const indexOfFirstPayment = indexOfLastPayment - paymentsPerPage;
  const currentPayments = filteredPayments.slice(
    indexOfFirstPayment,
    indexOfLastPayment
  );

  // Logic for Next and Previous buttons
  const totalPages = Math.ceil(filteredPayments.length / paymentsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleFilterChange = () => {
    setCurrentPage(1); // Reset to first page on filter change
  };

  const [fromDateEvent, setFromDateEvent] = useState(null);
  const [toDateEvent, setToDateEvent] = useState(null);
  const handleReset = () => {
    setFromDateEvent(null);
    setToDateEvent(null);
    setCurrentPage(1);
    setReferenceNumber("");
    setToDate("");
    setFromDate("");
    setPaidStatus("paid"); // Reset paid status filter
  };

  return (
    <>
      {paymentLoaded ? (
        <div className="col-lg-12 col-md-12 col-sm-12  p0">
          <h3 className="col-lg-12 col-md-12 col-sm-12 bold rtl p0">
            {translate("web.paymentHistory", "Payment History")}
          </h3>
          {/* Filter controls */}
          <div className="filter-controls rtl mb-3">
            <div
              className="row p0"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                <NetaqInput
                  type={"text"}
                  value={referenceNumber}
                  onChange={(e) => {
                    setReferenceNumber(e.target.value);
                    handleFilterChange();
                  }}
                  label={translate("web.refranceNumber", "Refrance Number")}
                  placeholder={translate(
                    "web.refranceNumber",
                    "Refrance Number"
                  )}
                  name={translate("web.refranceNumber", "Refrance Number")}
                />
              </div>
              <div
                className="col-lg-1 col-md-2 col-sm-12 mt-2"
                style={{ minWidth: 160 }}
              >
                <NetaqInput
                  type="date"
                  value={fromDateEvent}
                  onChange={(e) => {
                    setFromDateEvent(e);
                    setFromDate(e.toDate());
                    handleFilterChange();
                  }}
                  label={translate("web.from", "From ")}
                  name={translate("web.from", "From ")}
                />
              </div>
              <div
                className="col-lg-1 col-md-2 col-sm-12 mt-2"
                style={{ minWidth: 160 }}
              >
                <NetaqInput
                  type="date"
                  minDate={fromDateEvent}
                  value={toDateEvent}
                  onChange={(e) => {
                    setToDateEvent(e);
                    setToDate(e.toDate());
                    handleFilterChange();
                  }}
                  label={translate("web.to", "To ")}
                  name={translate("web.to", "To ")}
                />
              </div>
              {/* <div className="col-lg-1 col-md-1 col-sm-1 text-left">
                            </div> */}
              <div
                className="col-lg-2 col-md-2 col-sm-12 mt-2"
                style={{ marginTop: -10 }}
              >
                <label
                  className="capatalize_text"
                  style={{ margin: "auto 5px" }}
                >
                  {translate("web.status", "Status")}
                </label>
                <select
                  className="form-control"
                  style={{ margin: "auto" }}
                  value={paidStatus}
                  onChange={(e) => {
                    setPaidStatus(e.target.value);
                    handleFilterChange();
                  }}
                >
                  <option value="">{translate("web.all", "All")}</option>
                  <option value="paid">
                    {translate("web.completed", "Completed")}
                  </option>
                  <option value="notPaid">
                    {translate("web.notCompleted", "Not Completed")}
                  </option>
                </select>
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-12 mt-2"
                style={{ display: "flex" }}
              >
                <ActionButton
                  type={"normalbtn"}
                  margin={"5px auto 0 auto"}
                  label={translate("web.reset", "Reset")}
                  onClick={handleReset}
                  text={translate("web.reset", "Reset")}
                />
              </div>
            </div>
          </div>
          {filteredPayments.length > 0 ? (
            <div className="scrolableTable">
              <table className="table table-striped table-bordered rtl horseResultTable">
                <thead className="theadHorseSelect">
                  <tr>
                    <th className="text-center">
                      {translate("web.refranceNumber", "Refrance Number")}
                    </th>
                    <th className="text-center">
                      {translate("web.date", "Date")}
                    </th>
                    <th className="text-center">
                      {translate("web.amount", "Amount")}
                    </th>
                    <th className="text-center">
                      {translate("web.paymentReason", "Payment Reason")}
                    </th>
                    <th className="text-center">
                      {translate("web.status", "Status")}
                    </th>
                    <th className="text-center">
                      {translate("web.bankDecision", "Bank Decision")}
                    </th>
                    <th className="text-center">
                      {translate("web.paymentType", "Payment Type")}
                    </th>
                    <th className="text-center">
                      {translate(
                        "web.receiptPerformaInvoice",
                        "Receipt - Performa Invoice"
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {currentPayments.map((item, index) => (
                    <tr key={index}>
                      <td className="rtl">{item.refranceNumber}</td>
                      <td className="rtl">
                        {formatDate(new Date(item.createdDate))}
                      </td>
                      <td className="rtl">
                        {item?.amount?.toLocaleString("en-US") +
                          " " +
                          item.currency}
                      </td>
                      <td className="rtl">
                        {translate(
                          `web.Payment${convertToCamelCase(item.paymentType)}`,
                          convertToCamelCase(item.paymentType)
                        )}
                      </td>
                      <td
                        className={`rtl text-center ${
                          item.isPaid ? "text-success" : "text-danger"
                        }`}
                      >
                        {item.isPaid
                          ? translate("web.completed", "Completed")
                          : translate("web.notCompleted", "Not Completed")}
                      </td>
                      <td className="rtl" style={{ maxWidth: 75 }}>
                        {item.decisionText}{" "}
                        {item?.decisionText?.toLowerCase() !== "accept" && (
                          <>{item?.messageText}</>
                        )}
                      </td>
                      <td className="rtl" style={{ maxWidth: 75 }}>
                        {item.paymentMethod == null ||
                        item.paymentMethod == "Online"
                          ? translate("web.onlinePayment", "Online Payment")
                          : translate("web.creditPayment", "Credit Payment")}
                      </td>
                      <td className="rtl">
                        {!!item?.receiptUrl && (
                          <ActionButton
                            type={"openLink"}
                            text={'<i class="fa-solid fa-download"></i>'}
                            to={item.receiptUrl}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination buttons */}
              <div
                className="pagination-buttons rtl"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ActionButton
                  type={"normalbtn"}
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className="btn"
                  text={translate("web.prev", "Prev")}
                  margin={"auto 10px"}
                />
                <span>{`${
                  currentPayments.length < paymentsPerPage
                    ? paymentHistory.length
                    : currentPage * currentPayments.length
                } / ${paymentHistory.length}`}</span>
                <ActionButton
                  type={"normalbtn"}
                  onClick={nextPage}
                  margin={"auto 10px"}
                  disabled={currentPage === totalPages}
                  className="btn"
                  text={translate("web.next", "Next")}
                />
              </div>
            </div>
          ) : (
            <div className="text-center text-danger">
              {translate("web.nodataFound", "No Result Found")}
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
