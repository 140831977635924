import React from 'react'
import { formatDate } from '../helper/formatDate';
import { CompareDate } from '../helper/CompareDate';
import { Link } from 'react-router-dom';

export default function ShowEntryCard({i, item, translate, currentLanguage}) {
    return <div
    className="col-lg-6 col-md-12 col-sm-12 my-1 show-entry-first-div d-flex"
    key={i}
  >
    <div
      className="horseCardContainer row m0"
      style={{ display: "flex" }}
    >
      <div className="col-lg-4 col-md-8 col-sm-12 horse-market-card-div horseMarketImageContainer  showsImageContainer d-flex ">
        <img
          src={item.logo}
          alt="logo"
          style={{ margin: "auto" }} />
      </div>

      <div className="col-lg-8 col-md-8 col-sm-12  p0">
        <div className="col-md-12 col-sm-12 titleShowSchedule p0">
          <div className="col-md-12 col-sm-12 p0">
            <span className="horseMarketCardLabeltitle">
              {item.showName}
            </span>
          </div>
          {item.showParticipantRequestStatus ===
            "Returned" && (
              <div className="col-md-1 col-sm-12 textalignend">
                <i class="fa-solid fa-bell colorred"></i>
              </div>
            )}
        </div>

        <div className="row rtl m0">
          <div className="col-md-12 ">
            <span className="horseCardlabelContent ">
              {item.cityName}
            </span>
            <div className="end-date-showlist mt-1">
              <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
                <div>
                  <i
                    className="fa fa-trophy "
                    style={{ marginRight: 8 }}
                  ></i>
                  {item.showRank}
                </div>
              </div>
            </div>
          </div>
          {/* shows Start Date */}
          <div className=" start-date-showlist mt-1">
            <div className="col-md-12 px-2 background-color-orange start-end-date-display-ownerprofile">
              <div>
                <i className="fa fa-calendar "></i>
                {translate(
                  "web.startDate",
                  "Start Date"
                )}
                :
              </div>
              <div>
                {item.startDate &&
                  formatDate(item.startDate)}
              </div>
              {
                // <div>
                //   <span>{item.startTime}</span>
                // </div>
              }
            </div>
          </div>
          <div className=" end-date-showlist mt-1">
            <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
              <div>
                <i className="fa fa-calendar"></i>
                {translate("web.endDate", "End Date")}:
              </div>
              <div>
                {item.endDate &&
                  formatDate(item.endDate)}
              </div>
            </div>
          </div>
          {/* Show register dates */}
          <div className=" start-date-showlist mt-1">
            <div className="col-md-12 px-2 start-end-date-display-ownerprofile background-color-orange ">
              <div>
                <i className="fa fa-calendar "></i>
                {translate(
                  "web.registerStartDate",
                  "Register Start Date"
                )}
                :
              </div>
              <div>
                {formatDate(item.registerStartDate)}
              </div>
            </div>
          </div>

          <div className=" end-date-showlist mt-1">
            <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
              <div>
                <i className="fa fa-calendar"></i>
                {translate(
                  "web.registerEndDate",
                  "Register End Date"
                )}
                :
              </div>
              <div>
                {item.registerEndDate &&
                  formatDate(item.registerEndDate)}
              </div>
            </div>
          </div>

          {item.registerStartDateInter &&
            formatDate(item.registerStartDateInter) !==
            formatDate(item.registerStartDate) &&
            formatDate(item.registerEndDateInter) !==
            formatDate(item.registerEndDate) && (
              <React.Fragment>
                <div className="start-date-showlist mt-1">
                  <div className="col-md-12 px-2 background-color-orange start-end-date-display-ownerprofile">
                    <div>
                      <i className="fa fa-calendar "></i>
                      {translate(
                        "web.registerStartDateInter",
                        "Register Start Date Inter"
                      )}
                      :
                    </div>
                    <div>
                      {item.registerStartDateInter &&
                        formatDate(
                          item.registerStartDateInter
                        )}
                    </div>
                  </div>
                </div>

                <div className="end-date-showlist mt-1">
                  <div className="col-md-12 px-2 start-end-date-display-ownerprofile">
                    <div>
                      <i className="fa fa-calendar"></i>
                      {translate(
                        "web.registerEndDateInter",
                        "Register End Date Inter"
                      )}
                      :
                    </div>
                    <div>
                      {item.registerEndDateInter &&
                        formatDate(
                          item.registerEndDateInter
                        )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          <div className="row m0 rtl">
            <div className="col-sm-8 cardValueColor ">
              {translate(
                "web.maxNoPerOwner",
                "Max no of horses to register per owner"
              )}
              :
            </div>
            <div className="col-sm-4 cardKeyColor  ">
              {item.maxHorseNumberPerOwner > 0
                ? item.maxHorseNumberPerOwner
                : translate(
                  "web.unlimited",
                  "Unlimited"
                )}
            </div>
          </div>
          <span className="horseCardLabeltitle col-sm-12 text-end">
            {item.entryFees}{" "}
            {currentLanguage === "en" ? "AED" : "د.إ"}
          </span>
        </div>
      </div>
      <div className="row justify-content-end m0 align-items-center ">
        {(item.isShowRegisterStarted == true) && (
          <Link
            className={`btn button-saveasdraft col-sm-5  ${CompareDate(
              item.registerStartDate,
              item.endDate
            )
              ? "newbtn"
              : "btn-danger"}`}
            disabled={CompareDate(
              item.registerStartDate,
              item.endDate
            )
              ? false
              : true}
            onClick={(e) => {
              !CompareDate(
                item.registerStartDate,
                item.endDate
              ) && e.preventDefault();
            } }
            to={
              //`/showentrycard?entryFees=${item.entryFees}&maxHorses=${item.maxHorseNumberPerOwner}&showName=${item.showName}&showId=${item.id}&showParticipantRequestStatus=${item.showParticipantRequestStatus}`
              `/showentrycard?entryFees=${item.entryFees}&maxHorses=${parseInt(item.maxHorseNumberPerOwner)
              // parseInt(item.maxHorseNumberPerOwner) -
              // parseInt(item.completedRequestsCount)
              }&showNameAr=${item.showNameAr}&showNameEn=${item.showNameEn}&showId=${item.id}&showParticipantRequestStatus=${item.showParticipantRequestStatus}&completedRequestsCount=${item.completedRequestsCount}&registerStartDate=${item.registerStartDate}&registerEndDate=${item.registerEndDate}&registerStartDateInter=${item.registerStartDateInter}&registerEndDateInter=${item.registerEndDateInter}&showStartDate=${item.startDate}`
              // :  `/showentryconflict?maxHorses=${item.maxHorseNumberPerOwner}&showNameAr=${item.showNameAr}&showNameEn=${item.showNameEn}&showId=${item.id}`
            }
            style={CompareDate(
              item.registerStartDate,
              item.endDate
            )
              ? { opacity: 1, cursor: "pointer" }
              : {
                opacity: 0.5,
                cursor: "not-allowed",
              }}
          >
            {item.showParticipantRequestStatus ===
              "New" &&
              new Date() <
              new Date(item.registerEndDate)
              ? translate(
                "web.participateNow",
                "Participate Now"
              )
              : translate("web.continue", "Continue")
              // : translate(
              //   "web.addConflict",
              //   "Add Conflict"
              // )
            }
          </Link>
        )}
        {item.isShowJudgesAdded &&
          item.showParticipantRequestStatus ===
          "Completed" &&
          CompareDate(
            item.registerStartDate,
            item.startDate
          ) && (
            <Link
              className={`btn button-saveasdraft col-sm-5  ${CompareDate(
                item.registerStartDate,
                item.startDate
              )
                ? "newbtn"
                : "btn-danger"}`}
              disabled={CompareDate(
                item.registerStartDate,
                item.startDate
              )
                ? false
                : true}
              onClick={(e) => {
                !CompareDate(
                  item.registerStartDate,
                  item.startDate
                ) && e.preventDefault();
              } }
              to={`/showentryconflict?maxHorses=${item.maxHorseNumberPerOwner}&showNameAr=${item.showNameAr}&showNameEn=${item.showNameEn}&showId=${item.id}`}
              style={CompareDate(
                item.registerStartDate,
                item.startDate
              )
                ? { opacity: 1, cursor: "pointer" }
                : {
                  opacity: 0.5,
                  cursor: "not-allowed",
                }}
            >
              {translate(
                "web.addConflict",
                "Add Conflict"
              )}
            </Link>
          )}
        {item.showParticipantRequestStatus ===
          "Completed" &&
          item.IsShowRegisterStarted == true &&
          (item.maxHorseNumberPerOwner >
            item.completedRequestsCount ||
            item.maxHorseNumberPerOwner == 0) && (
            <Link
              className={`btn button-saveasdraft col-sm-6 ${CompareDate(
                item.registerStartDate,
                item.endDate
              )
                ? "newbtn"
                : "btn-danger"}`}
              style={CompareDate(
                item.registerStartDate,
                item.endDate
              )
                ? { opacity: 1, cursor: "pointer" }
                : {
                  opacity: 0.5,
                  cursor: "not-allowed",
                }}
              disabled={CompareDate(
                item.registerStartDate,
                item.endDate
              )
                ? false
                : true}
              onClick={(event) => {
                if (!CompareDate(
                  item.registerStartDate,
                  item.endDate
                )) {
                  event.preventDefault();
                }
              } }
              to={`/showentrycard?entryFees=${item.entryFees}&maxHorses=${parseInt(
                item.maxHorseNumberPerOwner
              )}&showNameAr=${item.showNameAr}&showNameEn=${item.showNameEn}&showId=${item.id}&showParticipantRequestStatus=${item.showParticipantRequestStatus}&registerStartDate=${item.registerStartDate}&registerEndDate=${item.registerEndDate}&registerStartDateInter=${item.registerStartDateInter}&registerEndDateInter=${item.registerEndDateInter}&showStartDate=${item.startDate}`}
            >
              {new Date() >
                new Date(item.registerEndDate) ? (
                <>
                  {translate(
                    "web.continue",
                    "Continue"
                  )}
                </>
              ) : (
                <>
                  {translate(
                    "web.addAdditionalHorse",
                    "Add Additional Horse"
                  )}
                </>
              )}
            </Link>
          )}
        <div className="col-md-6 col-sm-12 text-end ">
          <Link
            className="hoveronlink   rtl"
            to={`/showentrydetails?showId=${item.id}`}
          >
            {translate(
              "web.viewDetails",
              "View Details"
            )}
            <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"></i>
            <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"></i>
            <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"></i>
          </Link>
        </div>
      </div>
    </div>
  </div>
}
