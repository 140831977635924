import React, { useState, useEffect } from "react";
import LoginUaePassOTPForm from "../components/LoginUaePassOTPForm";
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function LoginUaePassPageOTP() {
  const [isWideScreen, setIsWideScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 600);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "84vh",
        display: "flex",
        position: "relative", 
      }}
    >
      {isWideScreen && <div style={{ flex: 1 }}></div>}
      <div
        style={{
          flex: 1,
          backgroundColor: "#E44D52",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          style={{ position: "absolute", right: 0, bottom: 0, height: "100%" }}
          src={`${BaseURL}/assets/img/loginnbg.png`}
          alt="loginbg"
        />
      </div>
      <div
        style={{
          position: "absolute",
          width: "50vw",
          maxWidth: 500,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <LoginUaePassOTPForm />
      </div>
    </div>
  );
}
