import React from 'react'
import { useTranslate } from './TranslationText';
import LazyLoadImage from './LazyLoadImage';
import ActionButton from './ActionButton';

export default function Album({item , index = 0 , handleClick , isShowDetailsPage = false} ) {
    const { translate } = useTranslate();


    return (
        <>
            
            <div className="secondSectionContainer MediaGallerySectionContainer">
                <div className="mediaGalleryContainer rtl">
                    {item.albumMedia.map((image, imgIndex) => (
                        imgIndex < 7 &&
                        <LazyLoadImage
                            key={`${index}-${imgIndex}`}
                            image={image}
                            albumId={item.albumId}
                            albumName={item.albumName}
                            onClick={() => handleClick(imgIndex, item.albumMedia)}
                            counter={imgIndex}
                            index={imgIndex}
                            startDate = {item.albumStartDate}
                            translate={translate}
                        />
                    ))}
                </div>
                <div className='row justRotate w90'>
                    <div className="hoveronlink ViewProfileLink justRotate">
                        <ActionButton text={translate(`web.viewAll`, 'View All')} to={`/album-details?id=${item.albumId}`} type={'internalLink'} />
                    </div>
                </div>
            </div>
        </>
    )
}
