import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import { formatDateTimeToString } from "../helper/formatDateTimeToString";
import { useTranslate } from "./TranslationText";
import SectionTitle from "./SectionTitle";
import ReactOwlCarousel from "react-owl-carousel";

const fetch = process.env.REACT_APP_HOME_PAGE_SPONSERS_POST_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function SponsersSection({ isHomePage = true }) {
  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );
  const [sponsers, setSponsers] = useState([{
    name: "",
    image: '',
    link: '',
    title: "",
  }]);
  const [total, setTotal] = useState(0);
  const { translate } = useTranslate();



  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return 'en-US';
    else return 'ar-AE'; // Defaulting to English for demonstration
  }


  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=10`, {
        headers: {
          "accept-language": getCurrentLanguageUmbraco(),

        }
      })
      const formattedData = umbraco.data?.items.map((item) => ({
        name: item.properties?.title,
        image: item.properties?.image ? item.properties?.image[0]?.url : '',
        link: item?.properties?.link ?? '',
        title: item?.properties?.title ?? "",
      }));
      setTotal(umbraco.data?.total)
      setSponsers(formattedData.filter(item => item.topOne !== true));
    }
    getdata();

  }, [currentLanguage])

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: false,
    dotsEach: 1,
    autoplay: true,
    startPosition: getCurrentLanguageUmbraco() == 'ar-AE' ? sponsers.length - 1 : 0,
    autoplayTimeout: 1500,
    autoplayHoverPause: true,


    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };


  return (
    <>
      {total > 0 &&
        <>
          <SectionTitle section_id={"sponsors"} section_name={translate('web.sponsors', "sponsors")} />
          <div className="container sponser_bg">
            <div className="row">
              {isHomePage &&
                <ReactOwlCarousel className="owl-theme" {...carouselOptions} marginWidth={1}>
                  {sponsers.map((item, index) => (
                    <div className="col-sm-12 text-center">
                      {item.link != '' ?
                        <a href={item.link} target={item.link != '' ? "_blank" : ''}>
                          {!!(item.image) &&

                            <img
                              src={`${MediaBaseURL}${item.image}`}
                              className="sponser_img"
                              alt="sponser"
                            />
                          }
                          <div className="sponser_title">{item.name}</div>
                        </a> :
                        <div>
                          {!!(item.image) &&
                            <img
                              src={`${MediaBaseURL}${item.image}`}
                              className="sponser_img"
                              alt="sponser"
                            />
                          }
                          <div className="sponser_title">{item.name}</div>
                        </div>}
                    </div>
                  ))}
                </ReactOwlCarousel>
              }
              {!isHomePage &&
                <div className="justRotate">
                  {sponsers.map((item, index) => (
                    <div className="col-lg-4 col-md-4 col-sm-12 justRotate text-center">
                      {item.link != '' ?
                        <a href={item.link} target={item.link != '' ? "_blank" : ''}>
                          {!!(item.image) &&

                            <img
                              src={`${MediaBaseURL}${item.image}`}
                              className="sponser_img"
                              alt="sponser"
                            />
                          }
                          <div className="sponser_title">{item.name}</div>
                        </a> :
                        <div>
                          {!!(item.image) &&
                            <img
                              src={`${MediaBaseURL}${item.image}`}
                              className="sponser_img"
                              alt="sponser"
                            />
                          }
                          <div className="sponser_title">{item.name}</div>
                        </div>
                      }
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
        </>
      }
    </>
  );
}
