import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import NetaqInput from '../components/NetaqInput'
import ActionButton from '../components/ActionButton'
import { ToastContainer, toast } from 'react-toastify';
import axios from '../axios/axios';
import axiosUmbraco from "../axios/axiosUmbraco";
import { useTranslate } from '../components/TranslationText';
import { useSelector } from 'react-redux';
import { RemovePlusFromString } from '../helper/RemovePlusFromString';
import Loader from '../components/Loader';
import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function ContactUs() {
    // umbraco
    const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

    const ContactUsID = process.env.REACT_APP_CONTANCT_US_ID
    const { translate } = useTranslate();

    const { currentLanguage } = useSelector(
        (state) => state.studBookSlice
    );
    const [contactus, setContactus] = useState([]);
    const [loaded, setloaded] = useState(false);


    const id = ContactUsID;
    const recaptchaRef = useRef();
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const [recaptchaValidate, setRecaptchaVaidate] = useState(false);


    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang == "en") return 'en-US';
        else return 'ar-AE'; // Defaulting to English for demonstration
    }


    useEffect(() => {
        async function getdata() {
            const umbraco = await axiosUmbraco.get(`/umbraco/delivery/api/v1/content/item?id=${id}`, {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(),

                }
            })
            const formattedData = umbraco.data?.map((item) => ({
                contactWhatsApp: item?.properties?.contactWhatsApp ?? '',
                contactTitle: item?.properties?.contactTitle ?? '',
                contactPhone: item?.properties?.contactPhone ?? '',
                contactMapUrl: item?.properties?.contactMapUrl ?? '',
                contactEmail: item?.properties?.contactEmail ?? '',
                contactDescription: item?.properties?.contactDescription ?? '',
                contactAddress: item?.properties?.contactAddress ?? '',
            }));;
            setContactus(formattedData);
            if (umbraco.data.length > 0) {
                setloaded(true);
                
            }
        }
        getdata();

       
    }, [currentLanguage]);

    useEffect(() => {
        const timer = setTimeout(() => {
            recaptchaRef.current.execute();
        }, 2000); // 1000 milliseconds = 1 second
    
        // Cleanup the timer if the component is unmounted
        return () => clearTimeout(timer);
      }, []);

    // end of umbraco
    const [contactDetails, setContactDetails] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setContactDetails({
            ...contactDetails,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const fields = ['name', 'email', 'subject', 'phone', 'message']; // Add more fields as needed
        const emptyFields = fields.filter(field => !contactDetails[field]);
        setIsLoading(true);
        if (emptyFields.length > 0) {
            // Notify the user that some fields are empty
            toast.error(`${translate('web.pleaseFillInTheAllfields', 'Please fill in the All fields')}: ${emptyFields.join(', ').toUpperCase()}`);
            setIsLoading(false);
            return;
        }
        try {
            const send = await axios.post('/contact/submitemail', { ...contactDetails , GoogleRecaptchaResponse:recaptchaToken })
            // console.log('send is ', send)
            if (send.data.responseCode === '200') {
                toast.success(translate('web.messageSentSuccecfully', 'Message Sent Succecfully'))
                setIsLoading(false);
                setContactDetails({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: ''
                });
            } else {
                toast.error('Message does not Sent Succecfully Check API')
                setIsLoading(false);

            }
        } catch (error) {
            toast.error('Error in try Crash')
            setIsLoading(false);
        }
    };
    const dismissAll = () => toast.dismiss();

    


 

    return (
        <> {loaded &&
            <>
                <ToastContainer position="top-right" onClick={dismissAll} theme="colored" autoClose={10000} />
                <div className='container-fluid px75'>
                    <div className='row noMarginRow rtl'>
                        {contactus.map((item) => (
                            <>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <h3 className='NewsPostTitle bold'>
                                        {item.contactTitle}
                                    </h3>
                                    <p className='Contact-US-Desc'>
                                        {item.contactDescription}
                                    </p>
                                    <Link to={item.contactMapUrl} target='_blank' className='contactUsLable'>
                                        <i className="fa-solid fa-map-marker"></i>
                                        <span>{translate('web.address', 'Address')}</span>
                                        <p className='Contact-US-Desc'>{item.contactAddress}</p>
                                        <div dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10880.55723120993!2d54.36395190190899!3d24.48718032014602!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6666e076cc95%3A0xc338a8b5c9f3925e!2sEmirates%20Arabian%20Horse%20Society!5e0!3m2!1sen!2sae!4v1714571792691!5m2!1sen!2sae" width="100%" height="150" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>` }} />
                                    </Link>
                                    <Link to={`mailto:${item.contactEmail}`} className='contactUsLable'>
                                        <i className="fa-regular fa-envelope"></i>
                                        <span>{translate('web.email', 'Email')}</span>
                                        <p>{item.contactEmail}</p>
                                    </Link>
                                    <Link to={`tel:${item.contactPhone}`} className='contactUsLable'>
                                        <i className="fa-solid fa-phone"></i>
                                        <span>{translate('web.phone', 'Phone')}</span>
                                        <p>{RemovePlusFromString(item.contactPhone)}</p>
                                    </Link>
                                    <Link to={`https://api.whatsapp.com/send/?phone=${item.contactWhatsApp}`} target="_blank" className='contactUsLable'>
                                        <i className="fa-brands fa-whatsapp"></i>
                                        <span>{translate('web.whatsApp', 'WhatsApp')}</span>
                                        <p>{RemovePlusFromString(item.contactWhatsApp)}</p>
                                    </Link>
                                </div>
                                <form onSubmit={handleSubmit} className='col-lg-6 col-md-6 col-sm-12 contactFormContainer' style={{position:'relative'}}>
                                    <input type='hidden' value={recaptchaToken} name='GoogleRecaptchaResponse' />
                                    <NetaqInput required={true} value={contactDetails.name} onChange={handleChange} name={'name'} label={translate('web.fullName', 'Full Name')} placeholder={translate('web.fullName', 'Full Name')} type={'text'} />
                                    <NetaqInput required={true} value={contactDetails.email} onChange={handleChange} name={'email'} label={translate('web.email', 'Email')} placeholder={translate('web.email', 'Email')} type={'email'} />
                                    <NetaqInput required={true} value={contactDetails.phone} onChange={handleChange} name={'phone'} label={translate('web.phone', 'Phone')} placeholder={translate('web.phone', 'Phone')} type={'text'} />
                                    <NetaqInput required={true} value={contactDetails.subject} onChange={handleChange} name={'subject'} label={translate('web.subject', 'Subject')} placeholder={translate('web.subject', 'Subject')} type={'text'} />
                                    <NetaqInput required={true} value={contactDetails.message} onChange={handleChange} name={'message'} label={translate('web.message', 'Message')} placeholder={translate('web.message', 'Message')} type={'textarea'} />
                                    {isLoading ? <Loader /> :
                                        <ActionButton type={'submit'} disabled={!recaptchaValidate} text={translate('web.submit', 'Submit')} />
                                    }
                                    <ReCAPTCHA
                                        sitekey={recaptchaSiteKey}
                                        size="invisible"
                                        ref={recaptchaRef}
                                        onChange={(token) => {
                                            setRecaptchaToken(token);
                                            if (token !== "") {
                                                setRecaptchaVaidate(true);
                                            }
                                        }}
                                    />
                                </form>
                            </>
                        ))}
                    </div>
                </div>
            </>
        }
        </>
    )
}
