import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HorseMarketFilter from "../components/HorseMarketFilter";
import {
  emptyPageHorseMarket,
  incrementPageHorseMarket,
  resetSearch,
} from "../Redux/HorseMarket/HorseMarketSlice";
import { FetchHorseMarketthunk } from "../Redux/HorseMarket/HorseMarketAction";
import { useTranslate } from "../components/TranslationText";
import SubmitBtn from "../components/SubmitBtn";
import Loader from "../components/Loader";
import { formateUAEName } from "../helper/formateUAEName";
import FilterButton from "../components/FilterButton";

export default function HorseMarket() {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const [showFilter, setShowFilter] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();

  const {
    horseMarketData,
    pageHorseMarket,
    takeHorseMarket,
    totalCountHorseMarket,
    marketType,
    stateShowMoreButtonHorse,
    horseMarketLoading,
  } = useSelector((state) => state.horseMarketSlice);

  const [filterCriteria, setFilterCriteria] = useState({
    search: "",
    gender: [],
    category: [],
    priceFrom: null,
    priceTo: null,
    country: null,
    cityId: null,
    birthDateFrom: null,
    birthDateTo: null,
    sortBy: "",
  });

  // console.log(horseMarketData);
  useEffect(() => {
    if (pageHorseMarket === 0) {
      dispatch(emptyPageHorseMarket());
      return;
    }
    let params = {
      skip: (pageHorseMarket - 1) * takeHorseMarket,
      take: takeHorseMarket,
      langauge: currentLanguage,
      search: filterCriteria.search,
      gender: filterCriteria.gender,
      category: filterCriteria.category,
      priceFrom: filterCriteria.priceFrom,
      priceTo: filterCriteria.priceTo,
      country: filterCriteria.country,
      birthDateFrom: filterCriteria.birthDateFrom,
      birthDateTo: filterCriteria.birthDateTo,
      sortBy: filterCriteria.sortBy,
      cityId: filterCriteria.cityId,
      originName: filterCriteria.OriginName,
      marketType: marketType,
    };
    dispatch(FetchHorseMarketthunk(params));
  }, [pageHorseMarket, filterCriteria, currentLanguage, marketType]);

  const updateHorseMarketList = (newFilters) => {
    //dispatch(resetSearch());
    //FetchHorseMarket(newFilters, 1);
    dispatch(emptyPageHorseMarket());
    setFilterCriteria(newFilters);
  };

  const handelShowMore = async () => {
    dispatch(incrementPageHorseMarket());
  };

  const FetchHorseMarket = (newFilters, page) => {
    let params = {
      skip: (page - 1) * takeHorseMarket,
      take: takeHorseMarket,
      langauge: currentLanguage,
      search: newFilters.search,
      gender: newFilters.gender,
      category: newFilters.category,
      priceFrom: newFilters.priceFrom,
      priceTo: newFilters.priceTo,
      country: newFilters.country,
      birthDateFrom: newFilters.birthDateFrom,
      birthDateTo: newFilters.birthDateTo,
      cityId: newFilters.cityId,
      sortBy: newFilters.sortBy,
      marketType: marketType,
    };
    dispatch(FetchHorseMarketthunk(params));
  };

  function formatDate(_date) {
    let date = new Date(_date);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }

    if (currentLanguage === "en") {
      return day + "/" + month + "/" + year;
    } else {
      return year + "/" + month + "/" + day;
    }
  }
   

  const handlePageChange = (page) => {
    dispatch(incrementPageHorseMarket(page));
    FetchHorseMarket(filterCriteria, page);
  };

  const renderPagination = () => {
    const pages = [];

    const numPages = Math.ceil(totalCountHorseMarket / takeHorseMarket);

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <span
          key={i}
          className="page-number-style"
          style={{
            background: i === pageHorseMarket && "rgba(0, 0, 0)",
            color: i === pageHorseMarket && "white",
          }}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </span>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageHorseMarket === 1) {
      _pages = pages
        .slice(pageHorseMarket - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseMarket > 1) {
      _pages = pages.slice(pageHorseMarket);
    }
    if (pageHorseMarket > 1 && numPages - pageHorseMarket > 3) {
      _pages = pages
        .slice(pageHorseMarket - 1, pageHorseMarket)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseMarket === 1 && numPages - pageHorseMarket <= 3) {
      _pages = pages;
      if (horseMarketData.length === 0) {
        _pages = [];
      }
    }

    if (pageHorseMarket > 1 && numPages - pageHorseMarket <= 3) {
      _pages = pages
        .slice(pageHorseMarket - 1, pageHorseMarket)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   FetchHorseMarket(filterCriteria, 1);
  // }, [marketType]);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // <div
        //     className={`page-overlay text-center ${
        //       horseMarketLoading ? "classdisplay" : "classnotdisplay"
        //     }`}
        //   >
        //     <img
        //       className="loading"
        //       src={`${BaseURL}/assets/img/loading.gif`}
        //       alt="Loading..."
        //     />
        //   </div>
      }

      <div className="row rtl">
        {windowWidth <= 970 && (
          // <button
          //   className="button-show-filter-style"
          //   onClick={toggleFilter}
          //   style={{
          //     background: isHovered && "#E44D52",
          //     color: isHovered && "#fff",
          //   }}
          //   onMouseOver={handleMouseOver}
          //   onMouseOut={handleMouseOut}
          // >
          //   {showFilter ? (
          //     <React.Fragment>
          //       {translate("web.hideFilter", "Hide Filter")}
          //     </React.Fragment>
          //   ) : (
          //     <React.Fragment>
          //       {translate("web.showFilter", "Show Filter")}
          //     </React.Fragment>
          //   )}
          // </button>
          <FilterButton  onClick={toggleFilter} />
        )}
        <div
          className="col-md-3 col-sm-12  width100Arabic768"
          style={{
            display:
              windowWidth >= 970 ? "block" : showFilter ? "block" : "none",
          }}
        >
          <HorseMarketFilter onSubmit={updateHorseMarketList} />
        </div>

        {!!horseMarketData && horseMarketData.length > 0 ? (
          <React.Fragment>
            <div className={"col-md-9 col-sm-12"}>
              <div className="row justRotate">
                {!!horseMarketData &&
                  horseMarketData.length > 0 &&
                  horseMarketData.map((item, i) => (
                    <div
                      className="col-lg-6 col-md-6 col-sm-12 width100Arabic768 horse-market-card "
                      key={i}
                    >
                      <div className="horseCardContainer ">
                        <div className="col-lg-5 col-md-5 col-sm-12 horse-market-card-div ">
                          <span className="horseMarketCardCategory rotateArabic ">
                            {item.horseCategory === "ShowHorse"
                              ? translate("web.showHorse", "show Horse")
                              : item.horseCategory === "FlatRace"
                                ? translate("web.flatRace", "Flat Race")
                                : item.horseCategory === "EnduranceHorse"
                                  ? translate(
                                    "web.enduranceHorse",
                                    "Endurance Horse"
                                  )
                                  : item.horseCategory}
                          </span>
                          <div className="horseMarketImageContainer_HorseMarket">
                            <img
                              src={
                                item.horseMarketMedia.length > 0
                                  ? item.horseMarketMedia[0].url
                                  : `${BaseURL}/assets/img/horsemarket.jpg`
                              }
                              alt="itemName"
                            />
                          </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12 ">
                          <span className="horseCardLabeltitle rotateArabic">
                            {item.horseName !== null &&
                              item.horseName !== "" ? (
                              item.registrationNumber + " - " + item.horseName
                            ) : (
                              <React.Fragment> &nbsp;</React.Fragment>
                            )}
                          </span>
                          <div className="row">
                            <div className="col-md-12">
                              <span className="horseCardlabelContent rotateArabic ">
                                {item.birthDate && formatDate(item.birthDate)}<br />{" "}
                                {translate(`web.${item.genderString.toLowerCase()}`,"item.genderString")}
                                {(item.birthDate === null ||
                                  item.birthDate === "") && (
                                    <React.Fragment> &nbsp;</React.Fragment>
                                  )}
                              </span>

                              <span className="horseCardlabelContent rotateArabic">
                                {item.countryName !== null &&
                                  item.countryName !== "" ? (
                                  item.cityName + "," + formateUAEName(item.countryName)
                                ) : (
                                  <React.Fragment> &nbsp;</React.Fragment>
                                )}
                              </span>
                              {
                                // <span className="horseCardlabelContent rotateArabic">
                                //   {item.genderString !== null &&
                                //   item.genderString !== "" ? (
                                //     item.genderString
                                //   ) : (
                                //     <React.Fragment> &nbsp;</React.Fragment>
                                //   )}
                                // </span>
                              }

                              <div className="row ">
                                <div className="col-sm-3 cardKeyColor rotateArabic ">
                                  {translate("web.sire", "Sire")}
                                </div>
                                <div className="col-sm-9 cardValueColor rotateArabic">
                                  {item.sireName}
                                </div>
                                <div className="col-sm-3 cardKeyColor rotateArabic">
                                  {translate("web.dam", "Dam")}
                                </div>
                                <div className="col-sm-9 cardValueColor rotateArabic">
                                  {item.damName}
                                </div>
                                <div className="col-sm-3 cardKeyColor rotateArabic">
                                  {translate("web.color", "Color")}
                                </div>
                                <div className="col-lg-9 cardValueColor rotateArabic">
                                  {item.color}
                                </div>
                                <div className="col-sm-3 cardKeyColor rotateArabic">
                                  {translate("web.origin", "Origin")}
                                </div>
                                <div className="col-lg-9 cardValueColor rotateArabic">
                                  {formateUAEName(item.originName)}
                                </div>
                              </div>

                              <span className="horseCardLabeltitle hoursePrice rotateArabic">
                                {item.horsePrice}{" "}
                                {currentLanguage === "en"
                                  ? "AED"
                                  : "د.إ"}
                              </span>
                            </div>
                          </div>

                          <Link
                            className="hoveronlink ViewProfileLink rotateArabic"
                            to={`/horsemarketprofile?id=${item.id}&horseId=${item.horseId}`}
                          >
                            {translate("web.viewDetails", "View Details")}
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                            ></i>
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                            ></i>
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                            ></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-md-3 col-sm-12"></div>
            <div className="col-lg-9 col-md-12 col-sm-12 text-center">
              {!horseMarketLoading &&
                !!horseMarketData &&
                horseMarketData.length > 0 ? (
                <SubmitBtn
                  marginBottom={50}
                  type={"loadmore"}
                  disabled={stateShowMoreButtonHorse}
                  onClick={handelShowMore}
                  label={`
            ${translate("web.showMore", "Show More")} (${horseMarketData.length
                    } ${translate("web.of", "Of")} ${totalCountHorseMarket})`}
                />
              ) : (
                ""
              )}
              {horseMarketLoading && <Loader />}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="row text-danger text-center">
              {!horseMarketLoading && translate("web.nodataFound", "No Data Found")}
              {horseMarketLoading && <Loader />}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
