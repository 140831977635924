import React, { useEffect, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchAuctionHorsesRequeststhunk,
  FetchUpdateAuctionHorsesthunk,
} from "../Redux/AuctionEntry/AuctionEntryAction";
import Select from "react-select";
import { resetToasterMessageAuctionEntry } from "../Redux/AuctionEntry/AuctionEntrySlice";
import PaymentDetailsCard from "../components/PaymentDetailsCard";

export default function Auctionentrytotalregisteredhorses() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const [subOwnerProfilesByIdList, setSubOwnerProfilesByIdList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [profile, setProfile] = useState({
    label:
      currentLanguage === "en"
        ? localStorage.getItem("EAHSUserFullName")
        : localStorage.getItem("currentSubAccountLabel"),
    value: localStorage.getItem("EAHSUserID"),
  });

  const searchParams = new URLSearchParams(location.search);

  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  const {
    auctionEntryLoading,
    toasterMessageAuctionEntry,
    auctionHorsesRequestsData,
  } = useSelector((state) => state.auctionEntrySlice);

  const { subOwnerProfilesByIdData } = useSelector(
    (state) => state.showEntrySlice
  );

  const horseFees = searchParams.has("horseFees")
    ? searchParams.get("horseFees")
    : null;

  const auctionNameAr = searchParams.has("auctionNameAr")
    ? searchParams.get("auctionNameAr")
    : null;

  const auctionNameEn = searchParams.has("auctionNameEn")
    ? searchParams.get("auctionNameEn")
    : null;

  const auctionId = searchParams.has("auctionId")
    ? searchParams.get("auctionId")
    : null;

  const maxHorses = searchParams.has("maxHorses")
    ? searchParams.get("maxHorses")
    : null;

  useEffect(() => {
    if (userProfile.accountType != "Agent") {
      let subOwnerProfilesParams = {
        id: localStorage.getItem("EAHSUserID"),
        langauge: currentLanguage,
      };
      dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));
    }

    let auctionHorsesRequestsParams = {
      ownerId:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      langauge: currentLanguage,
      auctionId: auctionId,
    };
    dispatch(FetchAuctionHorsesRequeststhunk(auctionHorsesRequestsParams));
  }, [currentLanguage]);

  useEffect(() => {
    setSubOwnerProfilesByIdList([profile, ...subOwnerProfilesByIdData]);

    if (
      subOwnerProfilesByIdData.length > 0 &&
      auctionHorsesRequestsData.length > 0
    ) {
      setProfileList(
        auctionHorsesRequestsData.map((item) => {
          return {
            label:
              localStorage.getItem("EAHSUserID") === item.ownerId
                ? currentLanguage === "en"
                  ? localStorage.getItem("EAHSUserFullName")
                  : localStorage.getItem("currentSubAccountLabel")
                : subOwnerProfilesByIdData.filter(
                    (ele) => ele.value === item.ownerId
                  )[0].text,
            value: item.ownerId,
            horseId: item.horseId,
          };
        })
      );
    }
  }, [subOwnerProfilesByIdData, auctionHorsesRequestsData]);

  const handelProceedToPayment = () => {
    let horseIds =
      subOwnerProfilesByIdData.length === 0
        ? auctionHorsesRequestsData.map((item) => {
            return {
              horseId: item.horseId,
              ownerId:
                userProfile.accountType != "Agent"
                  ? item.ownerId
                  : localStorage.getItem("currentSubAccountId"),
            };
          })
        : profileList.map((item) => {
            return { horseId: item.horseId, ownerId: item.value };
          });

    let params = {
      auctionId: auctionId,
      createdBy:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      auctionParticipantsHorsesList: horseIds,
    };
    dispatch(FetchUpdateAuctionHorsesthunk(params));
  };

  const handelUrlToPayment = () => {
    const returnUrl = `${BaseURL}/auctionentrysubmissionsuccessful?auctionId=${auctionId}`;

    const encodeURI = encodeURIComponent(returnUrl);

    window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${auctionId}&paymentType=4&userId=${localStorage.getItem(
      "EAHSUserID"
    )}&returnUrl=${encodeURI}`;
  };

  useEffect(() => {
    if (
      toasterMessageAuctionEntry != null &&
      toasterMessageAuctionEntry === "save"
    ) {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );

      dispatch(resetToasterMessageAuctionEntry());

      setTimeout(() => {
        handelUrlToPayment();
      }, 2000);
    }
  }, [toasterMessageAuctionEntry]);

  const dismissAll = () => toast.dismiss();

  const calcPropertyValue = (data) => {
    let count = 0;
    let total = 0;
    for (const obj of data) {
      count += 1;
      total += parseFloat(horseFees);
    }

    return [count, total];
  };

  const handelSubOwnerProfiles = (ele, horseId) => {
    let newProfileList = profileList.map((item) =>
      item.horseId === horseId
        ? { ...item, label: ele.label, value: ele.value }
        : item
    );
    setProfileList(newProfileList);
  };

  return (
    <div className="container-fluid container-fluid-padding row">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${
          auctionEntryLoading ? "classdisplay" : "classnotdisplay"
        }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {
        // title
      }
      <div className="col-md-12 col-sm-12 rtl mt-15 mb-25">
        <h1 className="fontWeight">
          {currentLanguage === "en" ? auctionNameEn : auctionNameAr}
        </h1>
      </div>
      {
        //cards
      }

      <div className="invoiceContainer">
        <div className="invoice">
          <div className="col-md-12 col-sm-12  bordershowentrytotal   padding-40 graybackgroundcolor">
            {
              //registered Horses cards
            }
            {auctionHorsesRequestsData.map((item, i) => (
              <div
                className="col-md-12 col-sm-12  bordershowentrytotal whitebackgroundcolor paymentRectangleInShow  mb-10 p-20"
                key={i}
              >
                <PaymentDetailsCard
                      isAuction = {true}
                      isRegisterdHorse={true}
                      horseName={item.horseName}
                      registerNr={item.registerNumber}
                      showFees={horseFees}
                      currentLanguage ={currentLanguage}
                      translate={translate}
                    />
                
                <div className="col-md-12 col-sm-12  rtl p0">
                  {subOwnerProfilesByIdList.length > 0 &&
                    userProfile.accountType != "Agent" && (
                      <React.Fragment>
                        <span className="horseCardLabeltitle">
                          {translate("web.Participateas", "Participate as")}
                        </span>
                        <Select
                          isDisabled={
                            item.ownerId === localStorage.getItem("EAHSUserID")
                          }
                          defaultValue={{
                            value: item.ownerId,
                            label: subOwnerProfilesByIdList.filter(
                              (ele) => ele.value === item.ownerId && !!ele.label
                            )[0]?.text,
                          }}
                          options={[
                            profile,
                            ...(!!subOwnerProfilesByIdList &&
                              subOwnerProfilesByIdList.filter(
                                (ele) =>  !!ele.text
                              ).map((a) => ({
                                label: a.text,
                                value: a.value,
                                text:a.text
                              }))),
                          ]}
                          value={profileList.filter(
                            (ele) => ele.horseId === item.horseId
                          )}
                          onChange={(e) =>
                            handelSubOwnerProfiles(e, item.horseId)
                          }
                          isSearchable
                          noOptionsMessage={() =>
                            translate("web.noOptions", "No Options")
                          }
                        />
                      </React.Fragment>
                    )}
                </div>
              </div>
            ))}
            {
              //total
            }
            <div className="col-md-12 col-sm-12 rtl p0 AuctionFilterNumberofhoursesContainer">
              <div className="col-md-6 col-sm-6  p0">
                <h4 className="fontWeight mb-15">
                  {translate("web.totalToPay", "Total To Pay")}{" "}
                  {` (${calcPropertyValue(auctionHorsesRequestsData)[0]})`}
                </h4>
              </div>
              <div className="col-md-6 col-sm-6 textalignend p0 width-fitcontent">
                <h5 className="fontWeight mb-15 bordershowentrytotal p9 rtl">
                  {`${calcPropertyValue(auctionHorsesRequestsData)[1]}`}{" "}
                  {currentLanguage === "en" ? "AED" : "د.إ"}
                </h5>
              </div>
            </div>
            {
              //proceed to payment button
            }
            <div className="col-md-12 col-sm-12 p0  rtl">
              <ActionButton
                type={"submit"}
                text={
                  <React.Fragment>
                    <i className="fa fa-save fa-lg marginrightleft-10"></i>
                    {translate("web.proceedToPayment", "Proceed To Payment")}
                  </React.Fragment>
                }
                width={"100%"}
                onClick={handelProceedToPayment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
