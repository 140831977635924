import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchChangePasswordAPI,
  FetchCheckUserHasOwnerProfileAPI,
  FetchCitiesAPI,
  FetchCreateHorseMarketAPI,
  FetchDeleteHorseMarketAPI,
  FetchGetOwnersListAPI,
  FetchHorseDataByRegisterNumberAPI,
  FetchHorsePassportAPI,
  FetchOwnerAppuserByIdAPI,
  FetchOwnerByIdAPI,
  FetchOwnerHorseMarketAPI,
  FetchOwnerHorsesAPI,
  FetchStudOwnerByIdAPI,
  FetchUpdateAccountAPI,
  FetchUpdateHorseMarketAPI,
  FetchUpdateOwnerAppuserAccountAPI,
} from "./OwnerProfileApi";

export const FetchOwnerHorsesthunk = createAsyncThunk(
  "OwnerProfile/FetchOwnerHorses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchOwnerHorsesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchOwnerHorsesDropDownthunk = createAsyncThunk(
  "OwnerProfile/FetchOwnerHorsesDropDown",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchOwnerHorsesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchOwnerByIdthunk = createAsyncThunk(
  "OwnerProfile/FetchOwnerById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchOwnerByIdAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUpdateAccountthunk = createAsyncThunk(
  "OwnerProfile/FetchUpdateAccount",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchUpdateAccountAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCitiesthunk = createAsyncThunk(
  "OwnerProfile/FetchCities",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCitiesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCreateHorseMarketthunk = createAsyncThunk(
  "OwnerProfile/FetchCreateHorseMarket",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCreateHorseMarketAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUpdateHorseMarketthunk = createAsyncThunk(
  "OwnerProfile/FetchUpdateHorseMarket",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchUpdateHorseMarketAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchHorseDataByRegisterNumberthunk = createAsyncThunk(
  "OwnerProfile/FetchHorseDataByRegisterNumber",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchHorseDataByRegisterNumberAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchOwnerHorseMarketthunk = createAsyncThunk(
  "OwnerProfile/FetchOwnerHorseMarket",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchOwnerHorseMarketAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchDeleteHorseMarketthunk = createAsyncThunk(
  "OwnerProfile/FetchDeleteHorseMarket",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchDeleteHorseMarketAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCheckUserHasOwnerProfilethunk = createAsyncThunk(
  "OwnerProfile/FetchCheckUserHasOwnerProfile",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCheckUserHasOwnerProfileAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchChangePasswordthunk = createAsyncThunk(
  "OwnerProfile/FetchChangePassword",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchChangePasswordAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchHorsePassportthunk = createAsyncThunk(
  "OwnerProfile/FetchHorsePassport",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchHorsePassportAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchOwnerAppuserByIdthunk = createAsyncThunk(
  "OwnerProfile/FetchOwnerAppuserById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchOwnerAppuserByIdAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUpdateOwnerAppuserAccountthunk = createAsyncThunk(
  "OwnerProfile/FetchUpdateOwnerAppuserAccount",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchUpdateOwnerAppuserAccountAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudOwnerByIdthunk = createAsyncThunk(
  "OwnerProfile/FetchStudOwnerById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudOwnerByIdAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetOwnersListthunk = createAsyncThunk(
  "OwnerProfile/FetchGetOwnersList",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetOwnersListAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
