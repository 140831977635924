import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  FetchStudBookAPI,
  FetchStudBookCountriesAPI,
  FetchStudBookHorseByOwnerAPI,
  FetchStudBookHorseByOwnerBreederAPI,
  FetchStudBookHorseCompetitionAPI,
  FetchStudBookHorseDetailsAPI,
  FetchStudBookHorseProgenyAPI,
  FetchStudBookHorsePedigreeByIdAPI,
  FetchStudBookOwnerAPI,
  FetchStudBookSiblingsDamAPI,
  FetchStudBookSiblingsSireAPI,
  FetchStudBookSiblingsSireAndDamAPI,
  FetchStudBookLanguageAPI,
  FetchStudBookOwnerDetailsAPI,
  FetchFilterOwnersOrUsersAPI,
} from "./StudBookApi";

export const FetchStudBookDatathunk = createAsyncThunk(
  "StudBook/FetchStudBookData",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookCountriesthunk = createAsyncThunk(
  "StudBook/FetchStudBookCountries",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookCountriesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookOwnerthunk = createAsyncThunk(
  "StudBook/FetchStudBookOwner",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookOwnerAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookHorseByOwnerthunk = createAsyncThunk(
  "StudBook/FetchStudBookHorseByOwner",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookHorseByOwnerAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookHorseByOwnerBreederthunk = createAsyncThunk(
  "StudBook/FetchStudBookHorseByOwnerBreeder",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookHorseByOwnerBreederAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookHorseDetailsthunk = createAsyncThunk(
  "StudBook/FetchStudBookHorseDetails",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookHorseDetailsAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookHorseProgenythunk = createAsyncThunk(
  "StudBook/FetchStudBookHorseProgeny",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookHorseProgenyAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookSiblingsSireAndDamthunk = createAsyncThunk(
  "StudBook/FetchStudBookSiblingsSireAndDam",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookSiblingsSireAndDamAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookSiblingsSirethunk = createAsyncThunk(
  "StudBook/FetchStudBookSiblingsSire",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookSiblingsSireAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookSiblingsDamthunk = createAsyncThunk(
  "StudBook/FetchStudBookSiblingsDam",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookSiblingsDamAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookHorseCompetitionthunk = createAsyncThunk(
  "StudBook/FetchStudBookHorseCompetition",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookHorseCompetitionAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookHorsePedigreeByIdthunk = createAsyncThunk(
  "StudBook/FetchStudBookHorsePedigreeById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookHorsePedigreeByIdAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookLanguagethunk = createAsyncThunk(
  "StudBook/FetchStudBookLanguage",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookLanguageAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookOwnerDetailsthunk = createAsyncThunk(
  "StudBook/FetchStudBookOwnerDetails",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookOwnerDetailsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchStudBookBreederOwnerDetailsthunk = createAsyncThunk(
  "StudBook/FetchStudBookBreederOwnerDetails",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchStudBookOwnerDetailsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchFilterOwnersOrUsersthunk = createAsyncThunk(
  "StudBook/FetchFilterOwnersOrUsers",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchFilterOwnersOrUsersAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
