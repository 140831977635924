import React from 'react'
import { useTranslate } from '../components/TranslationText';

export default function LiveStream() {
    const { translate } = useTranslate();
    const youtubeURL = translate('web.liveStreamingUrl', 'false');
    return (
        <>
            {(youtubeURL == "false" || youtubeURL == "") ? "" :
                <div className='container-fluid rtl' >
                    

                    <h2 className='NewsPostTitle bold' style={{margin:'0px 50px 15px 50px'}}>
                       {translate('web.liveStreamingTitle' , 'Live Streaming Title')}
                    </h2>
                    <iframe width="100%" style={{ height: '80vh' }}
                        src={`${youtubeURL}&autoplay=1`} title="YouTube video player"
                        frameborder="0" allow="accelerometer; autoplay; mute; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            }
        </>
    )
}
