import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import { useTranslate } from "./TranslationText";
import { Link } from "react-router-dom";
import { showFirst12Words, showFirst17Words, showFirst22Words } from "../helper/showFirstSixWords";
import NetaqInput from "./NetaqInput";

const fetch = process.env.REACT_APP_HOME_PAGE_SERVICES_lIST_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function ServicesSection({ isHomePage = false }) {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );
  const [services, setServices] = useState([{
    id: '',
    serviceName: "",
    category: [],
    estimateTime: "",
    feesAmount: 0,
    feesDescription: "",
    requiredDocuments: '',
    serviceForm: "",
    serviceImage: '',
    subTitle: "",
    termsAndConditions: ''
  }]);
  const [total, setTotal] = useState(0);
  const { translate } = useTranslate();

  const [mostUsedServices, setMostUsedServices] = useState([]);
  const [registerationServices, setRegisterationServices] = useState([]);
  const [notarisationServices, setNotarisationServices] = useState([]);
  const [approvalServices, setApprovalServices] = useState([]);
  const [activeTab, setActiveTab] = useState('Most Used Services')


  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return 'en-US';
    else return 'ar-AE'; // Defaulting to English for demonstration
  }


  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=50`, {
        headers: {
          "accept-language": getCurrentLanguageUmbraco(),

        }
      })
      const formattedData = umbraco.data?.items.map((item) => ({
        id: item?.id ?? '',
        serviceName: showFirst12Words(item?.name) ?? '',
        category: item?.properties?.category ?? [],
        estimateTime: item?.properties?.estimateTime ?? "",
        feesAmount: item?.properties?.feesAmount ?? 0,
        feesDescription: item?.properties?.feesDescription ?? "",
        requiredDocuments: item?.properties?.requiredDocuments?.markup ?? '',
        serviceForm: item?.properties?.serviceForm ?? "",
        serviceImage: item.properties?.serviceImage ? item.properties?.serviceImage[0]?.url : '',
        subTitle: showFirst17Words(item.properties?.subTitle) ?? "",
        termsAndConditions: item?.properties?.termsAndConditions?.markup ?? '',
      }));
      setTotal(umbraco.data?.total)
      setServices(formattedData);
      setMostUsedServices(formattedData.filter(service => service.category.includes("Most Used Services")))
      setRegisterationServices(formattedData.filter(service => service.category.includes("Registration Services")))
      setNotarisationServices(formattedData.filter(service => service.category.includes("Notarisation Services")))
      setApprovalServices(formattedData.filter(service => service.category.includes("Approval Services")))
    }
    getdata();

  }, [currentLanguage])
  const [searchKeyWord, setSearchKeyWord] = useState('');

  const filterdServices = services.filter(item => searchKeyWord.toLowerCase() === '' || item.serviceName.toLowerCase().includes(searchKeyWord.toLowerCase()))

  return (
    <>
      {total > 0 &&
        <div className="second_services_section_container">
          {/* Tabs */}
          {!isHomePage &&
              <div className="StudBookSearchBarContainer">
                <form
                  className="input-group"
                  style={{
                    paddingRight: 14,
                    width: "42%",
                    height: 53,
                    display: "flex",
                    border: "1px solid rgb(228, 77, 82)",
                    padding: "6px 7px",
                    borderRadius: 6,
                    justifyContent:'flex-start',
                    alignItems:'center',
                    margin:10,
                    marginBottom:20
                  }}
                >
                  <i
                    className="fas fa-search fa-xl"
                    style={{
                      color: "rgb(244 159 162)",
                    }}
                  ></i>
                  <input
                  type="text"
                  id="searchStudBook"
                  name="searchStudBook"
                  className="form-control"
                  value={searchKeyWord}
                  //autocomplete="on"
                  style={{ height: "38px", borderStyle: "hidden" }}
                  onChange={(e) => {
                    setActiveTab('All');
                    setSearchKeyWord(e.target.value);
                  }}
                  placeholder={translate('web.search', 'search')}

                />
                </form>
            </div>
          }

          <div className="second_section_bar">
            {mostUsedServices.length > 0 &&
              <a
                className="second_services_container"
                id="second_most_used_services"
                onClick={() => setActiveTab('Most Used Services')}
              >
                <div className="service_bar_img_and_link_conrtainer">
                  <img src={`${BaseURL}/assets/img/mostservice.png`} alt="alt" />
                  <span>{translate('web.mostUsedServices', 'Most Used Services')}</span>
                </div>

                <div className="service_bar_underline" style={{
                  opacity: activeTab == 'Most Used Services' ? 1 : 0,
                  visibility: activeTab == 'Most Used Services' ? 'visible' : "hidden"
                }}></div>
              </a>
            }
            {registerationServices.length > 0 &&
              <a
                className="second_services_container"
                id="second_registration_services"
                onClick={() => setActiveTab('Registration Services')}
              >
                <div className="service_bar_img_and_link_conrtainer">
                  <img src={`${BaseURL}/assets/img/memo.png`} alt="alt" />
                  <span>{translate('web.registrationServices', 'Registration Services')}</span>
                </div>

                <div className="service_bar_underline" style={{
                  opacity: activeTab == 'Registration Services' ? 1 : 0,
                  visibility: activeTab == 'Registration Services' ? 'visible' : "hidden"
                }}></div>
              </a>
            }

            {notarisationServices.length > 0 &&
              <a
                className="second_services_container"
                id="second_notarisation_services"
                onClick={() => setActiveTab('Notarisation Services')}
              >
                <div className="service_bar_img_and_link_conrtainer">
                  <img src={`${BaseURL}/assets/img/profile.png`} alt="alt" />
                  <span>{translate('web.notarisationServices', 'notarisation Services')}</span>
                </div>
                <div className="service_bar_underline" style={{
                  opacity: activeTab == 'Notarisation Services' ? 1 : 0,
                  visibility: activeTab == 'Notarisation Services' ? 'visible' : "hidden"
                }}></div>
              </a>
            }
            {approvalServices.length > 0 &&
              <a
                className="second_services_container"
                id="second_approval_services"
                onClick={() => setActiveTab('Approval Services')}
              >
                <div className="service_bar_img_and_link_conrtainer">
                  <img src={`${BaseURL}/assets/img/past.png`} alt="alt" />
                  <span>{translate('web.approvalServices', 'Approval Services')}</span>
                </div>
                <div className="service_bar_underline" style={{
                  opacity: activeTab == 'Approval Services' ? 1 : 0,
                  visibility: activeTab == 'Approval Services' ? 'visible' : "hidden"
                }}></div>
              </a>
            }
            {!isHomePage &&
              <a
                className="second_services_container"
                id="second_approval_services"
                onClick={() => setActiveTab('All')}
              >
                <div className="service_bar_img_and_link_conrtainer">
                  <img src={`${BaseURL}/assets/img/past.png`} alt="alt" />
                  <span>{translate('web.all', 'All')}</span>
                </div>
                <div className="service_bar_underline" style={{
                  opacity: activeTab == 'All' ? 1 : 0,
                  visibility: activeTab == 'All' ? 'visible' : "hidden"
                }}></div>
              </a>}


          </div>
          {/* services Section */}
          <div className="container rotateArabic" id="second_services_container_fluid">
            <div className="row" id="second_services_container_page">
              {activeTab == 'Most Used Services' && mostUsedServices.length > 0 &&
                mostUsedServices.map((service, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 rotateArabic bg-white" id="second_service_col">
                    <Link to={`/singleservice?id=${service.id}`} className="second_service_section_card_container">
                      <div className="top_line"></div>
                      <span className="second_service_section_category rtl">
                        {service.feesAmount > 0 ? service.feesAmount.toLocaleString('en-US') + " " + translate('web.aed', 'AED') : translate('web.freeService', 'Free Service')}
                      </span>
                      <div className="second_service_section_service_title bold">
                        {service.serviceName}
                      </div>
                      <div className="second_service_section_service_desc">
                        {service.subTitle}
                      </div>
                      <Link to={`/singleservice?id=${service.id}`} className="second_service_section_lets_start rtl">
                        {translate('web.letsStart', `Let's Start`)}
                        <span className="second_service_section_lets_start_left_arrow_first">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                        <span className="second_service_section_lets_start_left_arrow_second">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                      </Link>
                      <img
                        src={`${MediaBaseURL}${service.serviceImage}`}
                        alt=""
                        className="second_service_section_background_img"
                      />
                    </Link>
                  </div>
                ))}
              {activeTab == 'Registration Services' && registerationServices.length > 0 &&
                registerationServices.map((service, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 rotateArabic bg-white" id="second_service_col">
                    <Link to={`/singleservice?id=${service.id}`} className="second_service_section_card_container">
                      <div className="top_line"></div>
                      <span className="second_service_section_category rtl">
                        {service.feesAmount > 0 ? service.feesAmount.toLocaleString('en-US') + " " + translate('web.aed', 'AED') : translate('web.freeService', 'Free Service')}
                      </span>
                      <div className="second_service_section_service_title bold">
                        {service.serviceName}
                      </div>
                      <div className="second_service_section_service_desc">
                        {service.subTitle}
                      </div>
                      <Link to={`/singleservice?id=${service.id}`} className="second_service_section_lets_start rtl">
                        {translate('web.letsStart', `Let's Start`)}
                        <span className="second_service_section_lets_start_left_arrow_first">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                        <span className="second_service_section_lets_start_left_arrow_second">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                      </Link>
                      <img
                        src={`${MediaBaseURL}${service.serviceImage}`}
                        alt=""
                        className="second_service_section_background_img"
                      />
                    </Link>
                  </div>
                ))}
              {activeTab == 'Notarisation Services' && notarisationServices.length > 0 &&
                notarisationServices.map((service, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 rotateArabic bg-white" id="second_service_col">
                    <Link to={`/singleservice?id=${service.id}`} className="second_service_section_card_container">
                      <div className="top_line"></div>
                      <span className="second_service_section_category rtl">
                        {service.feesAmount > 0 ? service.feesAmount.toLocaleString('en-US') + " " + translate('web.aed', 'AED') : translate('web.freeService', 'Free Service')}
                      </span>
                      <div className="second_service_section_service_title bold">
                        {service.serviceName}
                      </div>
                      <div className="second_service_section_service_desc">
                        {service.subTitle}
                      </div>
                      <Link to={`/singleservice?id=${service.id}`} className="second_service_section_lets_start rtl">
                        {translate('web.letsStart', `Let's Start`)}
                        <span className="second_service_section_lets_start_left_arrow_first">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                        <span className="second_service_section_lets_start_left_arrow_second">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                      </Link>
                      <img
                        src={`${MediaBaseURL}${service.serviceImage}`}
                        alt=""
                        className="second_service_section_background_img"
                      />
                    </Link>
                  </div>
                ))}
              {activeTab == 'Approval Services' && approvalServices.length > 0 &&
                approvalServices.map((service, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 rotateArabic bg-white" id="second_service_col">
                    <Link to={`/singleservice?id=${service.id}`} className="second_service_section_card_container">
                      <div className="top_line"></div>
                      <span className="second_service_section_category rtl">
                        {service.feesAmount > 0 ? service.feesAmount.toLocaleString('en-US') + " " + translate('web.aed', 'AED') : translate('web.freeService', 'Free Service')}
                      </span>
                      <div className="second_service_section_service_title bold">
                        {service.serviceName}
                      </div>
                      <div className="second_service_section_service_desc">
                        {service.subTitle}
                      </div>
                      <Link to={`/singleservice?id=${service.id}`} className="second_service_section_lets_start rtl">
                        {translate('web.letsStart', `Let's Start`)}
                        <span className="second_service_section_lets_start_left_arrow_first">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                        <span className="second_service_section_lets_start_left_arrow_second">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                      </Link>
                      <img
                        src={`${MediaBaseURL}${service.serviceImage}`}
                        alt=""
                        className="second_service_section_background_img"
                      />
                    </Link>
                  </div>
                ))}

              {activeTab == 'All' && !isHomePage && services.length > 0 &&
                filterdServices.map((service, index) => (
                  <div className="col-lg-4 col-md-6 col-sm-12 rotateArabic bg-white" id="second_service_col">
                    <Link to={`/singleservice?id=${service.id}`} className="second_service_section_card_container">
                      <div className="top_line"></div>
                      <span className="second_service_section_category rtl">
                        {service.feesAmount > 0 ? service.feesAmount.toLocaleString('en-US') + " " + translate('web.aed', 'AED') : translate('web.freeService', 'Free Service')}
                      </span>
                      <div className="second_service_section_service_title bold">
                        {service.serviceName}
                      </div>
                      <div className="second_service_section_service_desc">
                        {service.subTitle}
                      </div>
                      <Link to={`/singleservice?id=${service.id}`} className="second_service_section_lets_start rtl">
                        {translate('web.letsStart', `Let's Start`)}
                        <span className="second_service_section_lets_start_left_arrow_first">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                        <span className="second_service_section_lets_start_left_arrow_second">
                          <i className="fa-solid fa-chevron-right rotateArabic"></i>
                        </span>
                      </Link>
                      <img
                        src={`${MediaBaseURL}${service.serviceImage}`}
                        alt=""
                        className="second_service_section_background_img"
                      />
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      }
    </>

  );
}
