import React, { useRef } from "react";
import { useTranslate } from "./TranslationText";
import { convertToCamelCase } from "../helper/convertToCamelCase";
export default function UploadFile({
  name = "",
  text,
  onChange,
  ismultiFile = false,
  accept,
  className = " ",
  iconClassName,
  disabled = false,
}) {
  const { translate } = useTranslate();
  const inputRef = useRef(null);
  const handleClick = () => {
    if (inputRef.current && !disabled) {
      inputRef.current.click();
    }
  };
  return (
    <span
      onClick={handleClick}
      style={{ cursor: disabled && "not-allowed", opacity: disabled && 0.65 }}
      className={
        disabled
          ? `opensigniturepad fileUploadSpanDisabled ${className}`
          : `opensigniturepad fileUploadSpan ${className}`
      }
    >
      {!!iconClassName !== "" && <i class={iconClassName}></i>}

      {translate(`web.${convertToCamelCase(text)}`, text)}
      <input
        style={{ display: "none" }}
        type="file"
        ref={inputRef}
        name={name}
        onChange={onChange}
        multiple={ismultiFile}
        placeholder={translate(`web.${convertToCamelCase(text)}`, text)}
        accept={accept}
      />
    </span>
  );
}
