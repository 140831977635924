import React, { useEffect, useRef, useState } from 'react'
import UploadFile from './UploadFile';
import Popup from 'reactjs-popup';
import SignaturePad from "react-signature-canvas";
import { useTranslate } from './TranslationText';
import NetaqInput from './NetaqInput';
import axios from '../axios/axios';
import Loader from './Loader';
import ActionButton from './ActionButton';
import FileUpload from './SimpleFileUpload';
import { getUserProfile } from '../helper/getUserProfile';
import dayjs from 'dayjs';

export default function UpgradeProfile({ toast, reloadProfile, setActiveTab }) {
    const { translate } = useTranslate();
    const signatureCanvas = useRef(null);
    const [imageURL, setImageURL] = useState(null);

    const onClearSignature = () => signatureCanvas.current.clear();
    const onSaveSignature = (onCloseSignaturePad) => {
        if (!signatureCanvas.current?.isEmpty()) {
            const imageData = signatureCanvas.current
                .getTrimmedCanvas()
                .toDataURL("image/png");
            setImageURL(imageData);
            onCloseSignaturePad();
        }
    };
    const [hovered, setHovered] = useState({
        sign: false,
        close: false,
        clear: false,
        save: false,
        secondsignature: false,
    });
    const generateButtonStyle = (isHovered) => ({
        background: isHovered ? "#ffffff" : "#E44D52",
        padding: "10px 20px",
        color: isHovered ? "#E44D52" : "#fff",
        outline: 0,
        border: "solid",
        borderWidth: 1,
        borderColor: "#E44D52",
        transition: "all 0.3s",
        display: "block",
        margin: "auto",
        cursor: "pointer",
    });

    const buttonSubmitStyle = generateButtonStyle(hovered.sign);
    const buttonsignatureStyle = generateButtonStyle(hovered.secondsignature);
    const buttoncloseStyle = generateButtonStyle(hovered.close);
    const buttonclearStyle = generateButtonStyle(hovered.clear);
    const buttonSaveStyle = generateButtonStyle(hovered.save);
    const handleMouseOver = (type) => {
        setHovered((prev) => ({ ...prev, [type]: true }));
    };

    const handleMouseOut = (type) => {
        setHovered((prev) => ({ ...prev, [type]: false }));
    };
    const handleSignitureFileChange = (e) => {
        let checkFileSize = true;
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                checkFileSize = false;
            } else {
                if (e.target.files && e.target.files.length > 0) {


                    var reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = function () {
                        setImageURL(reader.result);
                    };
                }
            }
        }

        if (!checkFileSize) {
            toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
        }
    };
    // onload functions
    useEffect(() => {
        getEmirateList();
    }, [])

    // get dropdown lists options
    const [emiratelistoptions, setEmirateListOptions] = useState([]);
    const [regionsList, setRegionsList] = useState([]);
    async function getEmirateList() {
        try {
            const emiratelist = await axios.get('/lookup/getmaincities')
            const formattedData = await emiratelist.data.result.map((item) => ({
                text: item.name,
                value: item.id,
            }));
            setEmirateListOptions(formattedData)
        } catch (error) {
            window.alert('error in emirates list')
        }

    }
    async function getRegionListbyID(id) {
        try {
            const regionList = await axios.get(`/lookup/getareasbyemirateid?EmirateId=${id}`)
            const formattedData = await regionList.data.result.map((item) => ({
                text: item.name,
                value: item.id,
            }));
            setRegionsList(formattedData)
        } catch (error) {
            window.alert('error in region list')
        }

    }
    // building submit form values
    const fatherSigniture = useRef(null);

    const [birthDate, setBirthDate] = useState(null);
    const [isYoungerThan21, setIsYoungerThan21] = useState(false);
    const [fatherHasAccount, setFatherHasAccount] = useState(true);
    const [fatherImageUrl, setFatherImageURL] = useState(null)
    const [issubmitting, setIsSubmitting] = useState(false);
    const [parentPassPortOrNationaIdFile, setParentPassPortOrNationaIdFile] = useState([]);




    const [upgradeDetails, setUpgradeDetails] = useState({
        'user-type': 2
    });



    // handlechange
    const handleChange = (event, input, index = 0) => {
        if (!!input && input === "selectedemiret") {
            getRegionListbyID(event.value);
            setUpgradeDetails((prevUpgradeDetails) => ({
                ...prevUpgradeDetails,
                selectedemirateId: event.value,
                selectedemirateIdDrop: event,
                selectedregionDrop: null
            }));
            return;
        }
        if (!!input && input === "region") {
            setUpgradeDetails((prevUpgradeDetails) => ({
                ...prevUpgradeDetails,
                selectedregion: event.value,
                selectedregionDrop: event,
            }));
            return;
        }
        setUpgradeDetails((prevUpgradeDetails) => ({
            ...prevUpgradeDetails,
            [event.target.name]: event.target.value,
        }));
    };
    const handleFatherRadioChange = (event) => {
        if (event.target.value == 'true') {
            setFatherHasAccount(true);
        } else {
            setFatherHasAccount(false);
        }
    }
    const onFatherSaveSignature = (onCloseSignaturePad) => {
        if (!fatherSigniture.current?.isEmpty()) {
            const imageData = fatherSigniture.current
                .getTrimmedCanvas()
                .toDataURL("image/png");
            setFatherImageURL(imageData);
            onCloseSignaturePad();
        }
    };
    const onFatherClearSignature = () => fatherSigniture.current.clear();
    const handleFatherFileChange = (e) => {
        let checkFileSize = true;
        if (e.target.files && e.target.files.length > 0) {
            if (e.target.files[0].size > 10 * 1024 * 1024) {
                checkFileSize = false;
            } else {
                if (e.target.files && e.target.files.length > 0) {


                    var reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = function () {
                        setFatherImageURL(reader.result);
                    };
                }
            }
        }

        if (!checkFileSize) {
            toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
        }
    };
    const parentNationaIdFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let checkFileSize = true;
            const fileListHook = [];

            for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
                if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
                    checkFileSize = false;
                    toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
                } else {
                    fileListHook.push({
                        documentFile: URL.createObjectURL(e.target.files[fileIndex]),
                        name: e.target.files[fileIndex].name,
                        file: e.target.files[fileIndex],
                    });
                }
            }

            setParentPassPortOrNationaIdFile(fileListHook);
        }
    };

    // handle submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const errors = [];

        if (!birthDate) {
            errors.push(translate('web.birthDateRequired', 'Birth date is required'));
        }

        if (upgradeDetails['user-type'] == 2) {
            if (!upgradeDetails.selectedemirateIdDrop) {
                errors.push(translate('web.chooseEmirateRequired', 'Choose Emirate is required'));
            }
            if (!upgradeDetails.selectedregionDrop) {
                errors.push(translate('web.chooseRegionRequired', 'Choose Region is required'));
            }
            if (isYoungerThan21 && !fatherHasAccount && parentPassPortOrNationaIdFile.length <= 0) {
                errors.push(translate('web.pleaseUploadYourFatherId', 'Please Upload Your Father ID'));
            }
            if (!imageURL) {
                errors.push(translate('web.pleaseUploadSigniture', 'Please Upload Your Signiture'));
            }
            // Add more checks for other required fields if necessary
        }

        if (errors.length > 0) {
            errors.forEach(error => toast.error(error));
            setIsSubmitting(false);
            return;
        }

        try {
            const upgradeNow = await axios.post('account/upgradeaccount', {
                Signture: imageURL,
                AccountType: upgradeDetails['user-type'],
                UpgradeStatus: upgradeDetails['user-type'] == 2 ? 1 : 2,
                ParentPassPortOrNationaIdFile: parentPassPortOrNationaIdFile[0] ?? '',
                ParentAccountRegNo: upgradeDetails.ParentAccountRegNo ?? '',
                ParentSignture: fatherImageUrl ?? '',
                EmirateId: upgradeDetails.selectedemirateId ?? '',
                AreaId: upgradeDetails.selectedregion ?? '',
                BirthDate: birthDate ?? '',
            }, {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            })

            if (upgradeNow.data.responseCode == '200') {
                setIsSubmitting(false);
                toast.success('web.upgradeRequestSuccess', 'Your Request is sent seceefully')
                const updateuserProfile = await getUserProfile();
                if (updateuserProfile) {
                    setActiveTab('accountSetting')
                    reloadProfile();

                }
            } else {
                setIsSubmitting(false);
                toast.error('web.error', 'An Error In Upgrade Account')

            }

        } catch (error) {
            console.log('error in upgrade', error)
            setIsSubmitting(false);
        }





    }

    return (
        <form onSubmit={handleSubmit} className='  rtl'>
            <div className='col-lg-12 col-md-12 col-sm-12 row rtl userinfocard'>
                <h2 className='userInfoTitle mx-0 p0 mb-1' style={{ color: "#E44D52" }}> {translate('web.upgradeTo', 'Upgrade To')} </h2>
                <div className="col-lg-3 col-md-3 col-sm-2 tooltip text-left-rtl mx-0">
                    <span class="tooltiptext">{translate('web.localOwnerToolTip', 'If You Have Are In UAE And You Have Hourses Not Registerd In EAHS')}</span>

                    <NetaqInput
                        type={"radio"}
                        name={"user-type"}
                        label={translate('web.localOwner', 'Local Owner')}
                        value={2}
                        checked={true}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-2 tooltip mx-2">
                    <span class="tooltiptext">{translate('web.internationalOwnerToolTip', 'If You have Hourses OutSide UAE And NOT without Regstration Number')}</span>

                    <NetaqInput
                        type={"radio"}
                        name={"user-type"}
                        label={translate('web.internationalOwner', 'International Owner')}
                        value={3}
                        onChange={handleChange}
                    />
                </div>
                {/* <label className="mx-0 p0 mb-1"> {//translate('web.neededInformation', 'Needed Information')} </label> <br /> */}
                <div className="col-lg-3 col-md-3 col-sm-6 p0  ">
                    <NetaqInput
                        type={"date"}
                        label={translate('web.birthDate', "Birth Date")}
                        name={"birthdate"}
                        maxDate={dayjs()}
                        required={true}
                        onChange={(e) => {
                            let now = new Date();
                            now.setHours(0, 0, 0, 0);
                            now.setFullYear(now.getFullYear() - 21);
                            setIsYoungerThan21(e.isAfter(now));
                            setBirthDate(e.toDate())
                        }}
                    />
                </div>
                <hr />
                {isYoungerThan21 === true && upgradeDetails['user-type'] == 2 &&
                    <>
                        <label className="mx-0 p0 my-2">
                            {translate('web.doYourFatherHaveAnAccountOnOurSystem', 'Do Your Father Have An Account On Our System?')}
                        </label>
                        <div className="col-lg-3 col-md-3 col-sm-3 mb-3">
                            <NetaqInput
                                type={"radio"}
                                label={translate("web.yes", "Yes")}
                                name={'fatherHasAccount'}
                                onChange={handleFatherRadioChange}
                                value={true}
                                checked={true}
                            />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-3 mb-1">
                            <NetaqInput
                                type={"radio"}
                                label={translate("web.no", "No")}
                                name={'fatherHasAccount'}
                                value={false}
                                onChange={handleFatherRadioChange}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6' ></div>
                        {fatherHasAccount == true &&
                            <div className="col-lg-6 col-md-6 col-sm-12 my-1">
                                <NetaqInput
                                    type={"text"}
                                    label={translate("web.yourFatherRegNumber", "Your Father Reg. Number")}
                                    placeholder={translate("web.yourFatherRegNumber", "Your Father Reg. Number")}
                                    name={"ParentAccountRegNo"}
                                    onChange={handleChange}
                                    required={true}
                                />
                            </div>
                        }
                        {
                            fatherHasAccount == false &&
                            <>
                                <div className="col-lg-6 col-md-12 col-sm-12 my-1">
                                    <label className='mx-0 p0 '>{translate('web.yourFathersignature', 'Your Father signature')} *</label>
                                    <div className="row p0 mx-0" >
                                        <div className="col-lg-12   col-md-12 col-sm-12   p0">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    alignContent: "center",
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap'
                                                }}
                                            >
                                                <Popup
                                                    modal
                                                    lockScroll
                                                    trigger={
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                            }}
                                                        >
                                                            <span
                                                                style={buttonSubmitStyle}
                                                                onMouseOver={() => handleMouseOver("sign")}
                                                                onMouseOut={() => handleMouseOut("sign")}
                                                            >
                                                                {translate('web.openSigniturePad', 'Open Signiture Pad')}
                                                            </span>
                                                        </div>
                                                    }
                                                    closeOnDocumentClick={false}
                                                    closeOnEscape
                                                >
                                                    {(close) => (
                                                        <>
                                                            <SignaturePad
                                                                ref={fatherSigniture}
                                                                canvasProps={{
                                                                    className: "signatureCanvas",
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    justifyContent: "space-between",
                                                                }}
                                                            >
                                                                <button
                                                                    style={buttonSaveStyle}
                                                                    onMouseOver={() => handleMouseOver("save")}
                                                                    onMouseOut={() => handleMouseOut("save")}
                                                                    onClick={() => {
                                                                        onFatherSaveSignature(close);
                                                                    }}
                                                                >
                                                                    {translate('web.save', 'Save')}
                                                                </button>
                                                                <button
                                                                    style={buttonclearStyle}
                                                                    onMouseOver={() => handleMouseOver("clear")}
                                                                    onMouseOut={() => handleMouseOut("clear")}
                                                                    onClick={onFatherClearSignature}
                                                                >
                                                                    {translate('web.clear', 'clear')}
                                                                </button>
                                                                <button
                                                                    style={buttoncloseStyle}
                                                                    onMouseOver={() => handleMouseOver("close")}
                                                                    onMouseOut={() => handleMouseOut("close")}
                                                                    onClick={close}
                                                                >
                                                                    {translate('web.close', 'close')}
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </Popup>
                                                <span style={{ margin: 'auto 5px' }} className="uaepassor">
                                                    {translate("web.or", "OR")}
                                                </span>

                                                <UploadFile
                                                    text={translate("web.uploadSingniturePhoto", "Upload Singniture Photo")}
                                                    onChange={handleFatherFileChange}
                                                />

                                                {fatherImageUrl ? (
                                                    <img
                                                        className='my-2'
                                                        src={fatherImageUrl}
                                                        alt="Owner Signature"
                                                        style={{
                                                            width: "100%",
                                                            maxWidth: 200,
                                                            maxHeight: 50,
                                                            marginLeft: 15,
                                                        }}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 my-1">
                                    <label >
                                        {translate('web.yourFatherID', 'Your FatherID  Copy')} <span className='color-eahsred' >*</span>
                                    </label>
                                    <FileUpload showToastMsg={toast}
                                        multiple={false} key={'ID UPLOAD'} initialFiles={parentPassPortOrNationaIdFile} isMulti={false} isServer={false} required={true} onChange={parentNationaIdFile} />
                                    <br />
                                </div>
                            </>
                        }
                    </>
                }



                {upgradeDetails['user-type'] == 2 &&
                    <>
                        <div className="col-lg-6 col-md-6 col-sm-12 my-1">
                            <NetaqInput
                                type={"select"}
                                label={translate("web.chooseEmirate", "Choose Emirate")}
                                placeholder={translate("web.chooseEmirate", "Choose Emirate")}
                                name={"selectedemiret"}
                                listSelect={emiratelistoptions}
                                value={upgradeDetails.selectedemirateIdDrop}
                                onChange={(evt) => handleChange(evt, "selectedemiret")}
                                required={true}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 my-1">
                            <NetaqInput
                                type={"select"}
                                label={translate("web.chooseRegion", "Choose Region")}
                                placeholder={translate("web.chooseRegion", "Choose Region")}
                                name={"region"}
                                listSelect={regionsList}
                                value={upgradeDetails.selectedregionDrop}
                                onChange={(evt) => handleChange(evt, "region")}
                                required={true}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 my-1'>
                            <label> {translate('web.yourSigniture', 'Your signature')}<span className='color-eahsred ms-1'>*</span></label>
                            <div className="row">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignContent: "center",
                                        alignItems: 'center',
                                        flexWrap: "wrap"
                                    }}
                                >
                                    <Popup
                                        modal
                                        lockScroll
                                        trigger={
                                            <div
                                                style={{
                                                    display: "flex",
                                                }}
                                            >
                                                <span
                                                    style={buttonSubmitStyle}
                                                    onMouseOver={() => handleMouseOver("sign")}
                                                    onMouseOut={() => handleMouseOut("sign")}
                                                >
                                                    {translate('web.openSigniturePad', 'Open Signiture Pad')}
                                                </span>
                                            </div>
                                        }
                                        closeOnDocumentClick={false}
                                        closeOnEscape
                                    >
                                        {(close) => (
                                            <>
                                                <SignaturePad
                                                    ref={signatureCanvas}
                                                    canvasProps={{
                                                        className: "signatureCanvas",
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <button
                                                        style={buttonSaveStyle}
                                                        onMouseOver={() => handleMouseOver("save")}
                                                        onMouseOut={() => handleMouseOut("save")}
                                                        onClick={() => {
                                                            onSaveSignature(close);
                                                        }}
                                                    >
                                                        {translate('web.save', 'save')}

                                                    </button>
                                                    <button
                                                        style={buttonclearStyle}
                                                        onMouseOver={() => handleMouseOver("clear")}
                                                        onMouseOut={() => handleMouseOut("clear")}
                                                        onClick={onClearSignature}
                                                    >
                                                        {translate('web.clear', 'Clear')}

                                                    </button>
                                                    <button
                                                        style={buttoncloseStyle}
                                                        onMouseOver={() => handleMouseOver("close")}
                                                        onMouseOut={() => handleMouseOut("close")}
                                                        onClick={close}
                                                    >
                                                        {translate('web.close', 'Close')}
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </Popup>
                                    <span style={{ margin: 'auto 5px' }} className="uaepassor">
                                        {translate("web.or", "OR")}
                                    </span>
                                    <UploadFile
                                        text="Upload Singniture Photo"
                                        onChange={handleSignitureFileChange}
                                    />
                                    {imageURL ? (
                                        <img
                                            className='my-2 mx-auto'
                                            src={imageURL}
                                            alt="Owner Signature"
                                            style={{
                                                width: "100%",
                                                maxWidth: 200,
                                                maxHeight: 50,
                                                marginLeft: 15,
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </>
                }
          
            {upgradeDetails['user-type'] == 3 && <div className='col-lg-12 col-md-12 col-sm-12'>
                <label> {translate('web.yourSigniture', 'Your signature')} ({translate('web.optional', 'Optional')})  </label>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignContent: "center",
                                alignItems: 'center'
                            }}
                        >
                            <Popup
                                modal
                                lockScroll
                                trigger={
                                    <div
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <span
                                            style={buttonSubmitStyle}
                                            onMouseOver={() => handleMouseOver("sign")}
                                            onMouseOut={() => handleMouseOut("sign")}
                                        >
                                            {translate('web.openSigniturePad', 'Open Signiture Pad')}
                                        </span>
                                    </div>
                                }
                                closeOnDocumentClick={false}
                                closeOnEscape
                            >
                                {(close) => (
                                    <>
                                        <SignaturePad
                                            ref={signatureCanvas}
                                            canvasProps={{
                                                className: "signatureCanvas",
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <button
                                                style={buttonSaveStyle}
                                                onMouseOver={() => handleMouseOver("save")}
                                                onMouseOut={() => handleMouseOut("save")}
                                                onClick={() => {
                                                    onSaveSignature(close);
                                                }}
                                            >
                                                {translate('web.save', 'save')}

                                            </button>
                                            <button
                                                style={buttonclearStyle}
                                                onMouseOver={() => handleMouseOver("clear")}
                                                onMouseOut={() => handleMouseOut("clear")}
                                                onClick={onClearSignature}
                                            >
                                                {translate('web.clear', 'Clear')}

                                            </button>
                                            <button
                                                style={buttoncloseStyle}
                                                onMouseOver={() => handleMouseOver("close")}
                                                onMouseOut={() => handleMouseOut("close")}
                                                onClick={close}
                                            >
                                                {translate('web.close', 'Close')}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </Popup>
                            <span style={{ margin: 'auto 5px' }} className="uaepassor">
                                {translate("web.or", "OR")}
                            </span>
                            <UploadFile
                                text="Upload Singniture Photo"
                                onChange={handleSignitureFileChange}
                            />
                            {imageURL ? (
                                <img
                                    src={imageURL}
                                    alt="Owner Signature"
                                    style={{
                                        width: "100%",
                                        maxWidth: 200,
                                        maxHeight: 50,
                                        marginLeft: 15,
                                    }}
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>}
            </div>


            {!!issubmitting ?
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <Loader />
                </div>
                :
                <ActionButton
                    margin={25}
                    width={100}
                    key={"Upgrade"}
                    text={translate("web.upgrade", "Upgrade")}
                    type={"submit"}
                />
            }
        </form>
    )
}
