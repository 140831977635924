import React, { useEffect, useState } from "react";
import NetaqInput from "./NetaqInput";
import SubmitBtn from "./SubmitBtn";
import { useDispatch, useSelector } from "react-redux";
import { FetchStudBookCountriesthunk } from "../Redux/StudBook/StudBookAction";
import { useTranslate } from "./TranslationText";
import Select from "react-select";

const StudBookFilter = ({ onSubmit, regNo, setRN }) => {
  const { studBookCountries, currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );

  const dispatch = useDispatch();
  const { translate } = useTranslate();

  useEffect(() => {
    dispatch(FetchStudBookCountriesthunk(currentLanguage));
  }, [currentLanguage]);

  const [filterState, setFilterState] = useState({
    gender: [],
    registrationNumber: regNo,
    origin: null,
    originDrop: {
      label: translate("web.chooseOrigin", "Choose Origin"),
      value: null,
    },
    ownerId: "",
    birthDateFrom: null,
    birthDateTo: null,
  });

  const [birthDateFrom , setBirthdateFrom] = useState(null);
  const [birthDateTo , setBirthdateTo] = useState(null);

  const handleChange = (event, input) => {
     
    if (!!input && input === "birthDateFrom") {
      setBirthdateFrom(event)
      setFilterState({
        ...filterState,
        birthDateFrom: event.$d.toISOString(),
      });
      return;
    }
    if (!!input && input === "birthDateTo") {
      setBirthdateTo(event)
      setFilterState({
        ...filterState,
        birthDateTo: event.$d.toISOString(),
      });
      return;
    }
    if (!!input && input === "origin") {
      setFilterState({
        ...filterState,
        origin: event.value,
        originDrop: event,
      });
      return;
    }

    if (event.target.name !== "gender") {
      setFilterState({
        ...filterState,
        [event.target.name]: event.target.value,
      });
    }
    if (event.target.name === "registrationNumber") {
      setRN(event.target.value);
    }

    if (event.target.name === "gender") {
      if (!event.target.checked) {
        setFilterState({
          ...filterState,
          gender: filterState.gender.filter(
            (item) => item !== parseInt(event.target.value)
          ),
        });
      } else {
        setFilterState({
          ...filterState,
          gender: filterState.gender.concat(parseInt(event.target.value)),
        });
      }
    }
  };

  const handleSubmit = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    onSubmit(filterState); // Pass filter state to auctionPage
  };

  const handleReset = (event) => {
    setBirthdateFrom(null)
    setBirthdateTo(null)
    setRN("");
    setFilterState({
      gender: [],
      horseUELN: "",
      origin: null,
      originDrop: {
        label: translate("web.chooseOrigin", "Choose Origin"),
        value: null,
      },
      ownerId: "",
      birthDateFrom: null,
      birthDateTo: null,
    });
  };

  return (
    <form
      className="horse-market-filter-form"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <h2>{translate('web.advancedSearch', 'Advanced Search')}</h2>

      <NetaqInput
        name={"registrationNumber"}
        label={translate("web.horseNameNumber", "Horse Name | Number")}
        placeholder={translate("web.horseNameNumber", "Horse Name | Number")}
        type={"text"}
        value={regNo}
        onChange={handleChange}
      />

      <NetaqInput
        name={"ownerId"}
        label={translate("web.ownerNameOrNumber", "Owner Name | Number")}
        placeholder={translate("web.ownerNameOrNumber", "Owner Name | Number")}
        type={"text"}
        onChange={handleChange}
      />

      <label className="mb-10">{translate("web.gender", "Gender")}</label>
      <div className="AuctionFilterNumberofhoursesContainer" style={{flexWrap:'wrap'}}>
        <NetaqInput
          name={"gender"}
          type={"checkbox"}
          value={1}
          label={translate("web.mare", "Mare")}
          onChange={handleChange}
        />

        <NetaqInput
          name={"gender"}
          type={"checkbox"}
          value={2}
          label={translate("web.stallion", "Stallion")}
          onChange={handleChange}
        />

        <NetaqInput
          name={"gender"}
          type={"checkbox"}
          value={3}
          label={translate("web.gelding", "Gelding")}
          onChange={handleChange}
        />
      </div>
      <label className="">{translate("web.birthDate", "Birthdate")}</label>
      <div className="AuctionFilterNumberofhoursesContainer">
        <NetaqInput
          name={"birthDateFrom"}
          type={"date"}
          label={translate("web.from", "From")}
          onChange={handleChange}
          value={birthDateFrom}
        />
        <NetaqInput
          name={"birthDateTo"}
          type={"date"}
          label={translate("web.to", "To")}
          onChange={handleChange}
          value={birthDateTo}
          minDate={birthDateFrom}
        />
      </div>


      <div className="countrymargin mt-2">
        <NetaqInput type={'select'} 
        label={translate("web.origin", "Origin")}
        listSelect={[
            {
              label: translate("web.chooseOrigin", "Choose Origin"),
              value: null,
            },
            ...(!!studBookCountries &&
              studBookCountries.map((a) => ({
                label: a.text,
                value: a.value,
              }))),
          ]}
          value={filterState.originDrop}
          onChange={(e) => handleChange(e, "origin")}
          placeholder={translate("web.chooseOrigin", "Choose Origin")}
          name={"origin"}
          noOptionsMessage={() => translate("web.noOptions", "No Options")}
          />
        {/* <Select
          // options={[
          //   {
          //     label: translate("web.chooseOrigin", "Choose Origin"),
          //     value: null,
          //   },
          //   ...(!!studBookCountries &&
          //     studBookCountries.map((a) => ({
          //       label: a.text,
          //       value: a.value,
          //     }))),
          // ]}
          value={filterState.originDrop}
          onChange={(e) => handleChange(e, "origin")}
          placeholder={translate("web.chooseOrigin", "Choose Origin")}
          name={"origin"}
          isSearchable
          noOptionsMessage={() => translate("web.noOptions", "No Options")}
        /> */}
      </div>
      {
        // <NetaqInput
        //   name={"origin"}
        //   type={"selectOrigin"}
        //   label={translate("web.chooseOrigin", "Choose Origin")}
        //   listSelect={studBookCountries}
        //   onChange={handleChange}
        // />
      }
      <div className="horse-market-filter-reset-apply-div">
        <SubmitBtn key={"reset"} type={"reset"} />

        <SubmitBtn
          text={translate("web.search", "Search")}
          key={"apply"}
          type={"submit"}

          disabled={
            filterState.gender.length === 0 &&
            filterState.origin === null &&
            filterState.registrationNumber === "" &&
            filterState.ownerId === "" &&
            filterState.birthDateFrom === null &&
            filterState.birthDateTo === null
          }
        />
      </div>
    </form>
  );
};

export default StudBookFilter;
