import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchChampionshipResultAPI,
  FetchShowChampionshipCardsAPI,
  FetchShowClassHorseSoringDetailsAPI,
  FetchShowClassRankListAPI,
  FetchShowClassesAPI,
  FetchShowGalleryAPI,
  FetchShowJudgesAPI,
  FetchShowbyIdAPI,
  FetchShowsAPI,
  getShowMediaGallery,
  getShowNews
} from "./ShowsApi";

export const FetchShowsthunk = createAsyncThunk(
  "Shows/FetchShows",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchLastShowsthunk = createAsyncThunk(
  "Shows/FetchLastShows",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowbyIdthunk = createAsyncThunk(
  "Shows/FetchShowbyId",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowbyIdAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowJudgesthunk = createAsyncThunk(
  "Shows/FetchShowJudges",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowJudgesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowClassRankListthunk = createAsyncThunk(
  "Shows/FetchShowClassRankList",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowClassRankListAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowChampionshipCardsthunk = createAsyncThunk(
  "Shows/FetchShowChampionshipCards",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowChampionshipCardsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchChampionshipResultthunk = createAsyncThunk(
  "Shows/FetchChampionshipResult",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchChampionshipResultAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowGallerythunk = createAsyncThunk(
  "Shows/FetchShowGallery",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowGalleryAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowClassesthunk = createAsyncThunk(
  "Shows/FetchShowClasses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowClassesAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchShowClassHorseSoringDetailsthunk = createAsyncThunk(
  "Shows/FetchShowClassHorseSoringDetails",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchShowClassHorseSoringDetailsAPI(param);
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const FetchShowsGallery = createAsyncThunk(
  "Shows/FetchShowsGallery",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await getShowMediaGallery(param);
      const data = response.data;
      return {data , param};
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const FetchShowsNews = createAsyncThunk(
  "Shows/FetchShowsNews",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await getShowNews(param);
      const data = response.data;
      return {data , param};
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
