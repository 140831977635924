import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import TranslationText, { useTranslate } from "./TranslationText";
import { Link, useLocation } from "react-router-dom";
import { RemovePlusFromString } from "../helper/RemovePlusFromString";
import SectionTitle from "./SectionTitle";

const FooterID = process.env.REACT_APP_HOME_PAGE_FOOTER_ID;

export default function Footer() {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const { translate } = useTranslate();
  const { pathname } = useLocation();

  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );
  const [footer, setFooter] = useState([]);
  const [loaded, setloaded] = useState(false);


  const id = FooterID;



  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return 'en-US';
    else return 'ar-AE'; // Defaulting to English for demonstration
  }


  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(`/umbraco/delivery/api/v1/content/item?id=${id}`, {
        headers: {
          "accept-language": getCurrentLanguageUmbraco(),

        }
      })
      const formattedData = umbraco.data?.map((item) => ({
        email: item?.properties?.email ?? '',
        location: item?.properties?.location ?? '',
        mobileNo: item?.properties?.mobileNo ?? '',
        whatsApp: item?.properties?.whatsApp ?? '',
        workingHours: item?.properties?.workingHours?.markup ?? '',
        facebookURL: item?.properties?.socialMedia[0]?.url ?? '',
        xtwitterURL: item?.properties?.socialMedia[1]?.url ?? '',
        instaURL: item?.properties?.socialMedia[2]?.url ?? '',
        youtubeURL: item?.properties?.socialMedia[3]?.url ?? '',
        PinterestURL: item?.properties?.socialMedia[4]?.url ?? '',
      }));;
      setFooter(formattedData);
      if (umbraco.data.length > 0) {
        setloaded(true);
      }
    }
    getdata();

  }, [currentLanguage])

  return (
    <>
      {pathname == '/studbook' && false &&
        <div className='container' style={{ marginTop: -75, marginBottom: 36 }}>
          <div className='downloadAppsContainerFlex' style={{ background: 'rgba(0,0,0,0.1)', zIndex: 500 }}>
            <div className='downloadAppsContainer'>
              {<div className='storeCard text-center'>
                <SectionTitle h2style={{ fontSize: 28 }} style={{ width: '100%' }} section_name={translate('web.otherStudbooks', "Other Studbook")} />
                <div className='storeCardsImagesContainer' style={{justifyContent:'center' , border:'1px solid rgb(20, 112, 73)' , maxWidth:400 }}>
                  {
                    <Link to={'https://vzap.org/zb-online/'} target='_blank' className='my-auto mx-2 bold' style={{color:'rgb(20, 112, 73)'}}>
                      {translate('web.vzapStudbook', 'VZAP studbook (German Studbook)').toUpperCase()}
                    </Link>
                  }
                  {
                    <Link to={'https://vzap.org/zb-online/'} target='_blank' className='storeImageLink mx-2'>
                      <img src={`${BaseURL}/assets/img/vzap.png`} className='storeImage' />
                    </Link>
                  }
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      }

      {loaded && <>
        {footer.map((item, index) => (
          <div
            className="container-fluid bg-gray-75-p-0-12w" key={index}
          >
            <div className="row" id="footer_main_row">
              <hr />
              <div className="col-sm-4">
                <h2 className="footertitle">
                  <TranslationText defaultText={'Eahs Address'} textkey={'web.eahsAddress'} />
                </h2>
                <p className="footer_text">
                  {item.location}
                  {/* <TranslationText defaultText={'Eahs Address Content'} textkey={'web.eahsAddressContent'} /> */}
                </p>
                <h2 className="footertitle">
                  <TranslationText defaultText={'Opening Hours'} textkey={'web.openingHours'} />
                </h2>
                <p dangerouslySetInnerHTML={{ __html: item.workingHours }} className="footer_text column" />

                <div className="row footerSocialRow">
                  {item.facebookURL != "" && item.facebookURL != null && <>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                      <a href={item.facebookURL} target="_blank">
                        <img
                          src={`${BaseURL}/assets/img/facebook.png`}
                          className="soical_media_img"
                          alt=""
                        />
                      </a>
                    </div>
                  </>}
                  {item.xtwitterURL != "" && item.xtwitterURL != null && <>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                      <a href={item.xtwitterURL} target="_blank">
                        <img
                          className="soical_media_img"
                          src={`${BaseURL}/assets/img/twitter.png`}
                          alt=""
                        />
                      </a>
                    </div>
                  </>}
                  {item.instaURL != "" && item.instaURL != null && <>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                      <a href={item.instaURL} target="_blank">
                        <img
                          className="soical_media_img"
                          src={`${BaseURL}/assets/img/instagram.png`}
                          alt=""
                        />
                      </a>
                    </div>
                  </>}
                  {item.youtubeURL != "" && item.youtubeURL != null && <>
                    <div className="col-lg-2 col-md-2 col-sm-2">
                      <a href={item.youtubeURL} target="_blank">
                        <img
                          className="soical_media_img"
                          src={`${BaseURL}/assets/img/youtub.png`}
                          alt=""
                        />
                      </a>
                    </div>
                  </>}
                  {item.PinterestURL != "" && item.PinterestURL != null &&
                    <>
                      <div className="col-lg-2 col-md-2 col-sm-2">
                        <a href={item.PinterestURL} target="_blank">
                          <img
                            className="soical_media_img"
                            src={`${BaseURL}/assets/img/pintrest.png`}
                            alt=""
                          />
                        </a>
                      </div>
                    </>}

                </div>
              </div>
              <div className="col-sm-4 row">
                <h2 className="footertitle">
                  <TranslationText defaultText={'Menu'} textkey={'web.menu'} />
                </h2>
                <div className="col-sm-6 ">
                  <Link to={'/'} className="footer_link">
                    <TranslationText defaultText={'Home'} textkey={'web.home'} />
                  </Link>
                  <Link to={'/services'} className="footer_link">
                    <TranslationText defaultText={'Services'} textkey={'web.services'} />
                  </Link>
                  <Link to={'/studbook'} className="footer_link">
                    <TranslationText defaultText={'StudBook'} textkey={'web.studBook'} />
                  </Link>
                  <Link to={'/horseMarket'} className="footer_link">
                    <TranslationText defaultText={'Owner Services'} textkey={'web.ownerServices'} />
                  </Link>
                  <Link to={'/calendar'} className="footer_link">
                    <TranslationText defaultText={'Events And Activites'} textkey={'web.eventsAndActivities'} />
                  </Link>
                  <Link to={'/shows'} className="footer_link">
                    <TranslationText defaultText={'Shows'} textkey={'web.shows'} />
                  </Link>
                </div>
                <div className="col-sm-6 smallscreennopaddingleft">
                  <Link to={'/auctions'} className="footer_link">
                    <TranslationText defaultText={'Auctions'} textkey={'web.auctions'} />
                  </Link>
                  <Link to={'/wahoconference'} className="footer_link">
                    <TranslationText defaultText={'Waho Conference'} textkey={'web.wahoConference'} />
                  </Link>
                  <Link to={'/news'} className="footer_link">
                    <TranslationText defaultText={'Media Center'} textkey={'web.mediaCenter'} />
                  </Link>
                  {/* <Link to={'/about-us'} className="footer_link"> */}
                  <Link className="footer_link">
                    <TranslationText defaultText={'About Us'} textkey={'web.aboutUs'} />
                  </Link>
                  <Link to={'/contactus'} className="footer_link">
                    <TranslationText defaultText={'Contact Us'} textkey={'web.contactUs'} />
                  </Link>
                </div>
              </div>
              <div className="col-sm-4">
                <h2 className="footertitle">
                  <TranslationText defaultText={'Contact Info'} textkey={'web.contactInfo'} />
                </h2>
                <br />
                {/* <div className="input-group transparent-bg">
                  <input
                    type="email"
                    className="form-control transparent-bg color_white"
                    placeholder={translate('web.enterYourEmail', 'Enter Your Email')}
                  />
                  <div className="input-group-addon transparent-bg">
                    <span className="glyphicon glyphicon-envelope white-icon"></span>
                  </div>
                </div>
                <br /> */}
                {item.email != "" && item.email != null &&
                  <>
                    <p className="footer_text">
                      <i
                        className="fa-regular fa-envelope font-22-white"
                      ></i>
                      &nbsp;&nbsp;{" "}
                      <TranslationText defaultText={'Email'} textkey={'web.email'} />

                      :&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href={`mailto:${item.email}`} className="color_white">
                        {item.email}
                      </a>
                    </p>
                    <br />
                  </>}
                {item.mobileNo != "" && item.mobileNo != null &&
                  <>
                    <p className="footer_text">
                      <i
                        className="fa-solid fa-phone font-22-white"
                      ></i>
                      &nbsp;&nbsp;
                      <TranslationText defaultText={'Phone No'} textkey={'web.phoneNo'} />
                      :&nbsp;&nbsp;
                      <a href={`tel:${item.mobileNo}`} className="color_white">
                        {RemovePlusFromString(item.mobileNo)}
                      </a>
                    </p>
                    <br />
                  </>
                }
                {item.whatsApp != "" && item.whatsApp != null &&
                  <p className="footer_text">
                    <i className="fa-brands fa-whatsapp font-22-white"></i>
                    &nbsp;&nbsp;{" "}
                    <TranslationText defaultText={'WhatsApp'} textkey={'web.whatsApp'} />
                    :&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`https://api.whatsapp.com/send/?phone=${item.whatsApp}`} target="_blank" className="color_white">
                      {RemovePlusFromString(item.whatsApp)}
                    </a>
                  </p>
                }
              </div>
            </div>
            <div className="row">
              <hr />
              <div className="col-sm-12 text-center all_right_reserved">
                © {new Date().getFullYear()}{" "}
                <TranslationText defaultText={'All Rights Reserved || EAHS | Emirates Arabian Horse Society'} textkey={'web.allRightsReserved'} />
              </div>
              <br />
              <br />
            </div>
          </div>
        ))}
      </>}
    </>
  );
}
