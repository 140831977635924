import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
import ActionButton from "./ActionButton";
import NetaqInput from "./NetaqInput";
import { getOrdinal } from "../helper/getOrdinal";
import { convertToCamelCase } from "../helper/convertToCamelCase";

const baseUmbracoURL = process.env.REACT_APP_UMBRACO;

export default function ScoringTab() {
  const { translate } = useTranslate();
  const [scoringResults, setScoringResutls] = useState(null);
  const [classHorseList, setClassHorseList] = useState([]);
  const [championshipList, setChampionshipList] = useState([]);
  const [activeTab, setActiveTab] = useState('classHorseList');
  const [isloaded, setIsLoaded] = useState(false);

  const [currentPageClassHorse, setCurrentPageClassHorse] = useState(1);
  const [currentPageChampionship, setCurrentPageChampionship] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(50);

  const lang = localStorage.getItem('eahsLang') ?? 'en';

  async function getScoringTable() {
    try {
      const response = await axiosInstance.get("Shows/GetLiveShowResult");
      if (response.data.responseCode === "200") {
        setScoringResutls(response.data.result);
        setClassHorseList(response.data.result.classHorseList);
        setChampionshipList(response.data.result.championshipList);
        setIsLoaded(true);
      } else {
        console.log("error in loading Participates");
      }
    } catch (error) {
      console.log("error in loading Participates", error);
    }
  }

  useEffect(() => {
    getScoringTable();
  }, []);

  const paginateList = (list, currentPage) => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return list.slice(indexOfFirstItem, indexOfLastItem);
  };

  const classHorseTotalPages = Math.ceil(classHorseList.length / itemsPerPage);
  const championshipTotalPages = Math.ceil(championshipList.length / itemsPerPage);

  const handlePageChange = (page, type) => {
    if (type === 'classHorseList') setCurrentPageClassHorse(page);
    if (type === 'championshipList') setCurrentPageChampionship(page);
  };

  const handleNextPage = (type) => {
    if (type === 'classHorseList' && currentPageClassHorse < classHorseTotalPages) {
      setCurrentPageClassHorse((prevPage) => prevPage + 1);
    } else if (type === 'championshipList' && currentPageChampionship < championshipTotalPages) {
      setCurrentPageChampionship((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = (type) => {
    if (type === 'classHorseList' && currentPageClassHorse > 1) {
      setCurrentPageClassHorse((prevPage) => prevPage - 1);
    } else if (type === 'championshipList' && currentPageChampionship > 1) {
      setCurrentPageChampionship((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 p0">
        <h3 className="col-lg-6 col-md-6 col-sm-6 bold rtl p0">
          {translate("web.liveShowResult", "Live Show Result")} <br />
          {scoringResults?.showName}
        </h3>

        <hr />

        {/* Tab Navigation */}
        <div className="col-12 row  AuctionDetailsRowTabs rtl p0 mx-0 mb-2">
          <div className="col-lg-2 col-md-2 col-sm-3 p0 me-2" style={{ minWidth: 220 }}>
            <span className={`customTabStyle ${activeTab === 'classHorseList' ? 'customTabactive' : ''}`}
              onClick={() => setActiveTab('classHorseList')}>
              {translate('web.classHorseList', 'Class Horse List')}
            </span>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-3 p0 me-2" style={{ minWidth: 220 }}>
            <span className={`customTabStyle ${activeTab === 'championshipList' ? 'customTabactive' : ''}`}
              onClick={() => setActiveTab('championshipList')}>
              {translate('web.championshipList', 'Championship List')}
            </span>
          </div>
        </div>

        {/* Class Horse List */}
        {activeTab === 'classHorseList' && (
          <>
            {!!scoringResults ? (
              <div className="row mx-0">
                <h3 className="col-lg-6 col-md-6 col-sm-12 bold rtl p0">{translate('web.classHorseList', 'Class Horse List')}  </h3>
                <div className="col-lg-6 col-md-6 col-sm-12 bold rtl p0">
                  <label>{translate('web.itemPerPage', 'Item Per Page')}</label>
                  <NetaqInput type={"selectislam"} disableEmptyValue={true} onChange={(e) => { setItemPerPage(e.target.value); setCurrentPageClassHorse(1) }} defaultValue={itemsPerPage} name={'rowperpage'} label={translate('web.itemPerPage', 'Item Per Page')} Options={[{ name: '25', value: 25 }, { name: '50', value: 50 }, { name: '75', value: 75 }, { name: '100', value: 100 }, { name: '150', value: 150 }, { name: '200', value: 200 }]} />
                </div>
                <h5 className="col-lg-12 col-md-12 col-sm-12 bold rtl p0 text-center my-3"> {translate('web.totalHorses', 'Total Horses') + ": " + classHorseList.length + " " + translate('web.horse', 'Horse')}</h5>
                <div className="scrolableTable">
                  <table className="table table-striped table-bordered rtl horseResultTable">
                    <thead className="theadHorseSelect">
                      <tr>
                        <th className="text-center">{translate("web.horseNo", "Horse No")}</th>
                        <th className="text-center">{translate("web.horseName", "Horse Name")}</th>
                        <th className="text-center">{translate("web.registerNo", "Register No")}</th>
                        <th className="text-center">{translate("web.className", "Class Name")}</th>
                        <th className="text-center">{translate("web.owner", "Owner")}</th>
                        <th className="text-center">{translate("web.scoringState", "Scoring Status")}</th>
                        <th className="text-center">{translate("web.scoringJSON", "Scoring JSON")}</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {paginateList(classHorseList, currentPageClassHorse).map((item, index) => (
                        <tr key={index}>
                          <td className="rtl">{item.horseNo}</td>
                          <td className="rtl">{item.horseName}</td>
                          <td className="rtl">{item.registerNo}</td>
                          <td className="rtl">{item.className}</td>
                          <td className="rtl">{item.owner}</td>
                          <td className="rtl">{item.scoringState}</td>
                          <td className="rtl">
                            {!!item.isScored && (
                              <ActionButton
                                type={"openLink"}
                                target="_blank"
                                text={'<i class="fa-solid fa-download"></i>'}
                                to={`${baseUmbracoURL}${item.scoringLink}`}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPageClassHorse}
                  totalPages={classHorseTotalPages}
                  handleNext={() => handleNextPage('classHorseList')}
                  handlePrev={() => handlePrevPage('classHorseList')}
                  handlePageChange={(page) => handlePageChange(page, 'classHorseList')}
                />
              </div>
            ) : (
              isloaded ?
                <div className="text-center text-danger">{translate("web.nodataFound", "No Result Found")}</div> :
                <Loader />
            )}
          </>
        )}

        {/* Championship List */}
        {activeTab === 'championshipList' && (
          <>
            {!!scoringResults ? (
              <div className="row mx-0">
                <h3 className="col-lg-6 col-md-6 col-sm-12 bold rtl p0">{translate('web.championshipList', 'Championship List')}</h3>
                <div className="col-lg-6 col-md-6 col-sm-12 bold rtl p0">
                  <label>{translate('web.itemPerPage', 'Item Per Page')}</label>
                  <NetaqInput type={"selectislam"} disableEmptyValue={true} onChange={(e) => { setItemPerPage(e.target.value); setCurrentPageChampionship(1) }} defaultValue={itemsPerPage} name={'rowperpage'} label={translate('web.itemPerPage', 'Item Per Page')} Options={[{ name: '25', value: 25 }, { name: '50', value: 50 }, { name: '75', value: 75 }, { name: '100', value: 100 }, { name: '150', value: 150 }, { name: '200', value: 200 }]} />
                </div>
                <h5 className="col-lg-12 col-md-12 col-sm-12 bold rtl p0 text-center my-3"> {translate('web.totalChampions', 'Total Champions') + ": " + championshipList.length + " " + translate('web.Champion', 'Champion')}</h5>

                <div className="scrolableTable">
                  <table className="table table-striped table-bordered rtl horseResultTable">
                    <thead className="theadHorseSelect">
                      <tr>
                        <th className="text-center">{translate("web.championName", "Champion Name")}</th>
                        <th className="text-center">{translate("web.scoringJSON", "Scoring JSON")}</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {paginateList(championshipList, currentPageChampionship).map((item, index) => (
                        <tr key={index}>
                          <td className="rtl">{item.championName}</td>
                          <td className="rtl">
                            {!!item.isScored && (
                              <ActionButton
                                type={"openLink"}
                                target="_blank"
                                text={'<i class="fa-solid fa-download"></i>'}
                                to={`${baseUmbracoURL}${item.scoringLink}`}
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  currentPage={currentPageChampionship}
                  totalPages={championshipTotalPages}
                  handleNext={() => handleNextPage('championshipList')}
                  handlePrev={() => handlePrevPage('championshipList')}
                  handlePageChange={(page) => handlePageChange(page, 'championshipList')}
                />
              </div>
            ) : (
              isloaded ?
                <div className="text-center text-danger">{translate("web.nodataFound", "No Result Found")}</div> :
                <Loader />
            )}
          </>
        )}
      </div>
    </>
  );
}
const Pagination = ({ currentPage, totalPages, handlePrev, handleNext, handlePageChange }) => {
  const getPageNumbers = () => {
    const range = 5;
    let start = Math.max(1, currentPage - Math.floor(range / 2));
    let end = Math.min(totalPages, currentPage + Math.floor(range / 2));

    // Adjust start and end if we're near the beginning or end of the page range
    if (currentPage <= Math.floor(range / 2)) {
      end = Math.min(totalPages, range);
    } else if (currentPage > totalPages - Math.floor(range / 2)) {
      start = Math.max(1, totalPages - range + 1);
    }

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
    return { pages, showPrevEllipsis: start > 1, showNextEllipsis: end < totalPages };
  };

  const { pages, showPrevEllipsis, showNextEllipsis } = getPageNumbers();

  return (
    <div className="pagination row m0 ">
      <ActionButton
        onClick={handlePrev}
        disabled={currentPage === 1}
        className={'col-lg-1 col-md-1 col-sm-2 my-1 p0'}
        type="normalbtn"
        label="Prev"
        text="Prev"
      />

      {/* Render "..." before the first page button if there are previous pages not shown */}
      {showPrevEllipsis && (
        <div className="col-lg-1 col-md-1 col-sm-1 my-1">
          <ActionButton
            disabled={true}
            type="bootstrapbtn"
            className={"bg-light text-dark"}
            label={'...'}
            text={'...'}
          />
        </div>
      )}

      {pages.map((page) => (
        <div className="col-lg-1 col-md-1 col-sm-1 my-1" key={page}>
          <ActionButton
            onClick={() => handlePageChange(page)}
            disabled={currentPage === page}
            type="bootstrapbtn"
            className={currentPage === page ? "active bg-white text-danger" : "bg-light text-dark"}
            label={page}
            text={page}
          />
        </div>
      ))}

      {/* Render "..." after the last page button if there are more pages not shown */}
      {showNextEllipsis && (
        <div className="col-lg-1 col-md-1 col-sm-1 my-1">
          <ActionButton
            disabled={true}
            type="bootstrapbtn"
            className={"bg-light text-dark"}
            label={'...'}
            text={'...'}
          />
        </div>
      )}


      <ActionButton
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={'col-lg-1 col-md-1 col-sm-2 my-1 p0'}
        type="normalbtn"
        label="Next"
        text="Next"
      />
    </div>
  );
};

