import axios from "../../axios/axios";
import axiosUmbraco from "../../axios/axiosUmbraco";

const umbracoShowGalleryId = process.env.REACT_APP_GALLERY_ID;
const umbracoShowNewsId = process.env.REACT_APP_HOME_PAGE_LATSEST_NEWS_ID;

export const FetchShowsAPI = async (param) => {
  try {
    const response = await axios.post(`/Shows/FilterShows`, {
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
      showName: param.showName,
      year: param.year,
      month: param.month,
      emirateId: param.emirateId,
      showStatus: param.showStatus,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowbyIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetShowbyId?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowJudgesAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetShowJudges?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowClassRankListAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetShowClassRankList?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowChampionshipCardsAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetShowChampionshipCards?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchChampionshipResultAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetChampionshipResult?ChapiomshipId=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowGalleryAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetShowGallery?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

// Check if Show Has Media Gallery in Umbraco
export const getShowMediaGallery = async  (param) => {
  try {
    let response = await axiosUmbraco.get(
      `/umbraco/delivery/api/v1/content?fetch=children:${umbracoShowGalleryId}&skip=0&take=100000000`,
      {
          headers: {
              "accept-language": param.langauge == 'en' ? "en-US" : "ar-AE" ,
          },
      }
  );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
}
export const getShowNews = async  (param) => {
  try {
    let response = await axiosUmbraco.get(
      `/umbraco/delivery/api/v1/content?fetch=${umbracoShowNewsId}&skip=0&take=100000000`,
      {
          headers: {
              "accept-language": param.langauge == 'en' ? "en-US" : "ar-AE" ,
          },
      }
  );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
}

export const FetchShowClassesAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetShowClasses?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowClassHorseSoringDetailsAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Shows/GetShowClassHorseSoringDetails?ShowClassHorseId=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
