export function getOrdinal(num, lang = 'en', specialRanks = false) {
    // English ordinals
    const englishOrdinals = ["th", "st", "nd", "rd"];
    
    // Arabic ordinals from 1st to 20th
    const arabicOrdinals = [
        "\u0627\u0644\u0623\u0648\u0644",    // 1st - الأول
        "\u0627\u0644\u062B\u0627\u0646\u064A",    // 2nd - الثاني
        "\u0627\u0644\u062B\u0627\u0644\u062B",  // 3rd - الثالث
        "\u0627\u0644\u0631\u0627\u0628\u0639",  // 4th - الرابع
        "\u0627\u0644\u062E\u0627\u0645\u0633",  // 5th - الخامس
        "\u0627\u0644\u0633\u0627\u062F\u0633",  // 6th - السادس
        "\u0627\u0644\u0633\u0627\u0628\u0639",  // 7th - السابع
        "\u0627\u0644\u062B\u0627\u0645\u0646",  // 8th - الثامن
        "\u0627\u0644\u062A\u0627\u0633\u0639",  // 9th - التاسع
        "\u0627\u0644\u0639\u0627\u0634\u0631",  // 10th - العاشر
        "\u0627\u0644\u062D\u0627\u062F\u064A \u0639\u0634\u0631",  // 11th - الحادي عشر
        "\u0627\u0644\u062B\u0627\u0646\u064A \u0639\u0634\u0631",  // 12th - الثاني عشر
        "\u0627\u0644\u062B\u0627\u0644\u062B \u0639\u0634\u0631", // 13th - الثالث عشر
        "\u0627\u0644\u0631\u0627\u0628\u0639 \u0639\u0634\u0631", // 14th - الرابع عشر
        "\u0627\u0644\u062E\u0627\u0645\u0633 \u0639\u0634\u0631", // 15th - الخامس عشر
        "\u0627\u0644\u0633\u0627\u062F\u0633 \u0639\u0634\u0631", // 16th - السادس عشر
        "\u0627\u0644\u0633\u0627\u0628\u0639 \u0639\u0634\u0631", // 17th - السابع عشر
        "\u0627\u0644\u062B\u0627\u0645\u0646 \u0639\u0634\u0631", // 18th - الثامن عشر
        "\u0627\u0644\u062A\u0627\u0633\u0639 \u0639\u0634\u0631", // 19th - التاسع عشر
        "\u0627\u0644\u0639\u0634\u0631\u0648\u0646"              // 20th - العشرون
    ];

    // Special ranks: Golden, Silver, Bronze
    const englishSpecial = ["Gold", "Silver", "Bronze"];
    const arabicSpecial = ["\u0630\u0647\u0628\u064A", "\u0641\u0636\u064A", "\u0628\u0631\u0648\u0646\u0632\u064A"];

    const mod100 = num % 100;
    const mod10 = num % 10;
    let suffix;

    if (specialRanks && num <= 3) {
        if (lang === 'en') {
            return englishSpecial[num - 1];
        } else if (lang === 'ar') {
            return arabicSpecial[num - 1];
        }
    }

    if (lang === 'en') {
        if (mod100 >= 11 && mod100 <= 13) {
            suffix = englishOrdinals[0]; // 'th' for 11th, 12th, 13th
        } else {
            suffix = englishOrdinals[mod10] || englishOrdinals[0]; // st, nd, rd, th
        }
        return `<span>${num}<sup>${suffix}</sup></span>`;
    } else if (lang === 'ar') {
        // Use Arabic ordinals for numbers from 1 to 20
        if (num >= 1 && num <= 20) {
            suffix = arabicOrdinals[num - 1];
            return suffix;
        } else {
            return "\u0640"; // Default for larger numbers: no suffix
        }
    }
}
