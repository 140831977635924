import React, { useEffect, useState } from "react";
import ActionButton from "./ActionButton";
import { useTranslate } from "./TranslationText";

export default function ImagesSlider({
  sliderName,
  type,
  auctionid,
  data,
  horseName,
  horseLotNumber,
  VideoURL = [],
}) {
  const auction_ID = auctionid ?? "unknownauction";

  let VideoURLs = typeof VideoURL === "string" ? [VideoURL] : VideoURL;

  VideoURLs = VideoURLs.filter(x => !!x);

  const imageExtensions = [
    ".jpg", ".jpeg", ".png", ".gif", ".bmp", ".webp", ".svg", ".tiff", ".ico", ".heic", ".heif", ".avif"
  ];


  const ReactBaseURL = process.env.REACT_APP_BASE_URL;
  const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
  const [BaseURL, setBaseURL] = useState(ReactBaseURL);
  const { translate } = useTranslate();
  const [auctionCoverImages, setImages] = useState([]);
  async function getvideoURI(link) {
    var regExp = /[?&]v=([^&]+)/;
    var match = regExp.exec(link);

    // Extracted value
    return match && match[1];
  }

  useEffect(() => {
    if (type === "auctionSlider") {
      setImages(
        data.filter(
          (item) =>
            item.entity === "AuctionCoverImage" ||
            item.entity === "AuctionFeatureImage"
        )
      );
    }
    if (type === "horseprofile") {
      setImages(
        data
          ? data.filter(
            (item) =>
              item.entity != "AuctionHorse" && imageExtensions.includes(item.fileType)
          )
          : []
      );
    }
    if (type === "bidding") {
      setImages(
        data
          ? data.filter(
            (item) =>
              item.entity === "HorseCoverImage" ||
              item.entity === "AuctionHorse" ||
              item.entity === "HorseFeatureImage"
          )
          : []
      );
    }
    if (type === "show") {
      const formatedData = data.map((item) => ({
        fileUrl: item,
      }));
      setImages(formatedData);
    }
    if (type === "singlepost") {
      const formatedData = data.map((item) => ({
        fileUrl: MediaBaseURL + item.url,
      }));
      setImages(formatedData);
      setBaseURL(MediaBaseURL);
    }
  }, [type, data]);

  return (
    <>
      {type === "horseprofile" ? (
        <React.Fragment>
          {/* <div className="horse-profile-slider-image-overlay"></div> */}
          <div className="row mx-0 py-2 rtl">
            <h3 className="col-sm-6  col-12 text-start" >{horseName} / {translate('web.lotNo', 'Lot No.')} {horseLotNumber}</h3>
            <div className="col-sm-6 col-12 d-flex">
              <ActionButton
                type={"enroll-now"}
                className="ms-auto"
                text={translate(
                  "web.auctionDetails",
                  "Auction Details"
                )}
                margin={0}
                key={"enroll-btn"}
                to={auction_ID}
              />
            </div>
          </div>
        </React.Fragment>
      ) : null}
      <div
        id={sliderName}
        className="carousel slide maxHeight480"
        data-ride="carousel"
        data-bs-interval="false"
      >
        {/* <!-- Indicators --> */}
        {VideoURLs.length > 0 ? (
          <React.Fragment>
            {
              <ol className="carousel-indicators image_slider_indicators zindex1500">
                {VideoURLs.map((item, i) => (
                  <li
                    key={i}
                    data-target={"#" + sliderName}
                    data-bs-target={"#" + sliderName}
                    data-slide-to={i}
                    data-bs-slide-to={i}
                    className={i === 0 && "active"}
                  ></li>
                ))}

                {auctionCoverImages.map((image, index) => (
                  <li
                    data-target={"#" + sliderName}
                    data-bs-target={"#" + sliderName}
                    data-slide-to={index + VideoURLs.length}
                    data-bs-slide-to={index + VideoURLs.length}
                  ></li>
                ))}
              </ol>
            }
          </React.Fragment>
        ) : (
          <React.Fragment>
            {auctionCoverImages.length > 1 && (
              <ol className="carousel-indicators image_slider_indicators zindex1500">
                {auctionCoverImages.map((image, index) => (
                  <li
                    data-target={"#" + sliderName}
                    data-bs-target={"#" + sliderName}
                    data-slide-to={index}
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : null}
                  ></li>
                ))}
              </ol>
            )}
          </React.Fragment>
        )}

        {/* <!-- Wrapper for slides --> */}

        <div className="carousel-inner">
          {VideoURLs.length > 0 ? (
            <React.Fragment>
              {VideoURLs.map((item, i) => (
                <div
                  key={i}
                  className={
                    i === 0
                      ? "carousel-item item imageSliderItem active zindex400"
                      : "carousel-item item imageSliderItem zindex400"
                  }
                >
                  <iframe
                    className="video_player maxHeight480 transformScale1"
                    title="YouTube video player"
                    width="100%"
                    src={
                      //"youtube.com/watch?v=",
                      //!item.includes("embed")
                      item != null &&
                      `${item.replace(
                        /youtube\.com\/(watch\?v=|live)/,
                        "youtube.com/embed/"
                      )}`
                    }
                    frameBorder="0"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="allowfullscreen"
                  ></iframe>
                </div>
              ))}

              {auctionCoverImages.map((image, index) => (
                <div className={"item carousel-item imageSliderItem zindex400"}>
                  {type == "singlepost" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "15px auto",
                      }}
                    >
                      <img
                        src={image.fileUrl}
                        className=""
                        style={{
                          height: 450,
                          maxHeight: "unset",
                          width: "unset",
                        }}
                        alt={image.entity}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={image.fileUrl}
                      className="maxHeight480 max_width_100"
                      alt={image.entity}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                      }}
                    />
                  )}
                </div>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {auctionCoverImages.map((image, index) => (
                <div
                  className={
                    index === 0
                      ? "carousel-item item imageSliderItem active zindex400"
                      : "carousel-item item imageSliderItem zindex400"
                  }
                >
                  {type == "singlepost" ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={image.fileUrl}
                        className=""
                        style={{
                          height: 450,
                          maxHeight: "unset",
                          width: "unset",
                          margin: "15px auto",
                        }}
                        alt={image.entity}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={image.fileUrl}
                      className={`max_width_100 ${type == 'horseprofile' ? 'maxh300':'maxHeight480'}`}
                      alt={image.entity}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                      }}
                    />
                  )}


                </div>
              ))}
            </React.Fragment>
          )}
        </div>
      </div>
    </>
  );
}
