import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import AboutUsSlider from "../components/AboutUsSlider";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";

export default function ShowsCategories() {
  const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

  const ShowsCategoriesID = process.env.REACT_APP_SHOWS_CATEFORIES_ID;
  const { translate } = useTranslate();

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const [showsCategories, setShowsCategories] = useState([]);
  const [loaded, setloaded] = useState(false);

  const id = ShowsCategoriesID;

  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return "en-US";
    else return "ar-AE"; // Defaulting to English for demonstration
  }

  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(
        `/umbraco/delivery/api/v1/content/item?id=${id}`,
        {
          headers: {
            "accept-language": getCurrentLanguageUmbraco(),
          },
        }
      );
      const formattedData = umbraco.data?.map((item) => ({
        ourVision: item?.properties?.ourVision ?? "",
        ourMessage: item?.properties?.ourMessage ?? "",
        aboutTitle: item?.properties?.showsCategoriesDescription ?? "",
        aboutCoverImage: item?.properties?.showsCategoriesImage[0]?.url ?? "",
      }));
      setShowsCategories(formattedData);
      if (umbraco.data.length > 0) {
        setloaded(true);
      }
    }
    getdata();
  }, [currentLanguage]);
  return (
    <>
      {
        loaded ?
          <>
            {showsCategories.map((item) => (
              <div className='container-fluid px75'>
                <div className='row'>
                  <h2 className="col-sm-12 bold rtl">{item.aboutTitle}</h2>
                  <div className='col-sm-12 aboutusImageContainer'>
                    <img alt={'ShowsCat img'} src={`${MediaBaseURL}${item.aboutCoverImage}`} />
                  </div>
                 
                </div>
                </div>

            ))}
          </>
          : (
            <Loader />
          )
      }
    </>
  );
}
