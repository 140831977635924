import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axios/axios";

export const BaseURL = process.env.REACT_APP_BASE_URL;
export const initialState = {
  auctions: [],
  isLoading: false,
  totalCount: 0,
  skip: 0,
  take: 6,
};
// Asynchronous thunk action to fetch auctions (replace with your API call)

export const fetchAuctions = createAsyncThunk(
  "auctions/fetchAuctions",
  async (filters, { getState }) => {
    const {
      /*auctionStartDateFrom, auctionStartDateTo, numberOfHorsesTo,*/ dateRange,
      ...filtersWithoutDate
    } = filters;
    const userID = localStorage.getItem("EAHSUserID") ?? "noid";
    const {
      auctions: { skip, take },
    } = getState(); // Get state from store

    // auctionName: "",
    // auctionType: "None",
    // auctionStartDateFrom: "",
    // auctionStartDateTo: "",
    // skip: 0,
    // take: 6,
    // numberOfHorsesFrom: 0,
    // numberOfHorsesTo: 0,
    // dateRange: "LiveAndHybrid",
    // reset:false

     
    const response = filters.enrolledAuction
      ? await axios.get(
          `/bidders/getuserenrolledauctions?Id=${userID}&auctionStatus=${
            dateRange == "None" ? "" : dateRange
          }&skip=${skip}&take=${take}&numberOfHorsesFrom=${
            filters.numberOfHorsesFrom
          }&numberOfHorsesTo=${filters.numberOfHorsesTo}&startdateFrom=${
            filters.auctionStartDateFrom
          }&startdateTo=${filters.auctionStartDateTo}&auctionName=${
            filters.auctionName
          }&auctionType=${filters.auctionType}`,
          {}
        )
      : await axios.post(`/auction/filter?${(dateRange && `dateRange=${dateRange}`)??""}`, {
          ...filtersWithoutDate,
          skip,
          take,
        });

console.debug('response is ')
    return {
    
      data: response.data,
      enrolled: filters.enrolledAuction,
      // auctionStartDateFrom,
      // auctionStartDateTo,
      // numberOfHorsesTo,
    }; // Replace with your actual API response
  }
);
