import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from './SectionTitle';
import { useTranslate } from './TranslationText';
import axiosInstance from '../axios/axiosUmbraco';
import { useSelector } from 'react-redux';

const BaseURL = process.env.REACT_APP_BASE_URL;
const FooterID = process.env.REACT_APP_HOME_PAGE_FOOTER_ID;

export default function DownloadAppsSection() {
    const { translate } = useTranslate();
    const [appsLinks, setAppsLinks] = useState([]);
    const [loaded, setloaded] = useState(false);
    const { currentLanguage } = useSelector(
        (state) => state.studBookSlice
    );
    const id = FooterID;


    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang == "en") return 'en-US';
        else return 'ar-AE'; // Defaulting to English for demonstration
    }


    useEffect(() => {
        async function getdata() {
            const umbraco = await axiosInstance.get(`/umbraco/delivery/api/v1/content/item?id=${id}`, {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(),

                }
            })
            const formattedData = umbraco.data?.map((item) => ({
                auctionMobileGoogleplayLink: item?.properties?.auctionAppGooglePlay ?? '',
                auctionMobileAppleStoreLink: item?.properties?.auctionAppAppleStore ?? '',
                ownerMobileGoogleplayLink: item?.properties?.ownersAppGooglePlay ?? '',
                ownerMobileAppleStoreLink: item?.properties?.ownersAppAppleStore ?? '',
            }));;
            setAppsLinks(formattedData);
            if (umbraco.data.length > 0) {
                setloaded(true);
            }
        }
        getdata();

    }, [currentLanguage])

   
    return (
        appsLinks.length > 0 && loaded &&
        <div className='container' id='appstores'>
            <div className='downloadAppsContainerFlex'>
                <div className='downloadAppsContainer'>
                    {appsLinks.map((item) => (
                        <>
                            {(item.ownerMobileAppleStoreLink !== '' || item.ownerMobileGoogleplayLink !== '') &&
                                <div className='storeCard text-center'>
                                    <SectionTitle h2style={{ fontSize: 28 }} style={{ width: '100%' }} section_name={translate('web.getOwnersApp', "Get Owners App")} />
                                    <br />
                                    <div className='storeCardsImagesContainer'>
                                        {item.ownerMobileGoogleplayLink !== '' &&
                                            <Link to={item.ownerMobileGoogleplayLink} target='_blank' className='storeImageLink'>
                                                <img src={`${BaseURL}/assets/img/googleplay.png`} className='storeImage' />
                                            </Link>
                                        }
                                        {item.ownerMobileAppleStoreLink !== '' &&
                                            <Link to={item.ownerMobileAppleStoreLink} target='_blank' className='storeImageLink'>
                                                <img src={`${BaseURL}/assets/img/appstore.png`} className='storeImage' />
                                            </Link>
                                        }
                                    </div>
                                </div>
                            }
                            {(item.ownerMobileAppleStoreLink !== '' || item.ownerMobileGoogleplayLink !== '') && (item.auctionMobileGoogleplayLink !== '' || item.auctionMobileGoogleplayLink !== '') &&
                                <div className='verticalSpacer'></div>
                            }
                            {(item.auctionMobileGoogleplayLink !== '' || item.auctionMobileGoogleplayLink !== '') &&
                                <div className='storeCard text-center'>
                                    <SectionTitle h2style={{ fontSize: 28 }} style={{ width: '100%' }} section_name={translate('web.getAuctionApp', "Get Auction App")} />
                                    <br />
                                    <div className='storeCardsImagesContainer'>
                                        {item.auctionMobileGoogleplayLink !== '' &&
                                            <Link to={item.auctionMobileGoogleplayLink} target='_blank' className='storeImageLink'>
                                                <img src={`${BaseURL}/assets/img/googleplay.png`} className='storeImage' />
                                            </Link>
                                        }
                                        {item.auctionMobileGoogleplayLink !== '' &&
                                            <Link to={item.auctionMobileAppleStoreLink} target='_blank' className='storeImageLink'>
                                                <img src={`${BaseURL}/assets/img/appstore.png`} className='storeImage' />
                                            </Link>
                                        }
                                    </div>
                                </div>
                            }
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}
