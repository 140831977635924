import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  FetchStudBookHorseByOwnerBreederthunk,
  FetchStudBookHorseByOwnerthunk,
} from "../Redux/StudBook/StudBookAction";
import {
  emptyPage,
  incrementPageOwnerDetails,
  resetSearch,
} from "../Redux/StudBook/StudBookSlice";
import { Link } from "react-router-dom";
import SubmitBtn from "../components/SubmitBtn";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import HorseCard from "../components/HorseCard";
import { FetchStudOwnerByIdthunk } from "../Redux/OwnerProfile/OwnerProfileAction";

const OwnerDetailsPage = () => {
  const dispatch = useDispatch();

  const ownerData = useRef({});

  ownerData.current = useLocation().state;

  const {
    studBookLoading,
    //studBookData,
    studBookOwnerDetailsData,
    stateShowMoreButtonHorse,
    totalCountOwnerDetails,
    pageOwnerDetails,
    take,
    currentLanguage,
    studBookOwnerDetailsStateNoDataFound,
  } = useSelector((state) => state.studBookSlice);

  const { studOwnerByIdData } = useSelector((state) => state.ownerProfileSlice);
  const { translate } = useTranslate();

  const [HorsesOwnedHovered, setHorsesOwnedHovered] = useState(false);
  const [HorsesOwnedActive, setHorsesOwnedActive] = useState(true);

  const [HorsesBredsHovered, setHorsesBredsHovered] = useState(false);
  const [HorsesBredsActive, setHorsesBredsActive] = useState(false);

  const [selectedSearchType, setSelectedSearchType] = useState(1);

  const navigate = useNavigate();

  const handleSelectedSearchType = (e) => {
    setSelectedSearchType(e);
    dispatch(resetSearch());
  };

  useEffect(() => {
    if (pageOwnerDetails === 0) {
      dispatch(emptyPage());
      return;
    }

    let params = {
      ownerId: !!ownerData.current && ownerData.current.id,
      skip: (pageOwnerDetails - 1) * take,
      take,
      langauge: currentLanguage,
    };
    if (selectedSearchType === 1) {
      dispatch(FetchStudBookHorseByOwnerthunk(params));
    }
    if (selectedSearchType === 2) {
      dispatch(FetchStudBookHorseByOwnerBreederthunk(params));
    }
  }, [pageOwnerDetails, currentLanguage, ownerData.current]);

  useEffect(() => {
    let params = {
      id: !!ownerData.current && ownerData.current.id,
      langauge: currentLanguage,
    };
    dispatch(FetchStudOwnerByIdthunk(params));
  }, [ownerData.current]);

  const TabStyle = {
    display: "block",
    marginRight: -10,
    marginLeft: -10,
    marginTop: 6,
    padding: "10px 20px",
    textAlign: "center",
    background: "rgba(0,0,0,0.08)",
    cursor: "pointer",
    color: "#000",
    transition: "all 300ms",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  };

  const handelShowMore = async () => {
    dispatch(incrementPageOwnerDetails());
  };

  return (
    <React.Fragment>
      <div
        className="container-fluid"
        style={{ paddingLeft: 75, paddingRight: 75 }}
      >
        <div className="row rtl ">
          <div className="col-md-12 col-sm-12   rtl">
            <span
              style={{
                fontWeight: "bold",
                fontSize: 20,
                textTransform: "capitalize",
                display: "block",
                marginBottom: 5,
              }}
            >
              {!!ownerData.current && ownerData.current.name}
            </span>
            <span
              style={{
                fontWeight: "bold",
                fontSize: 20,
                textTransform: "capitalize",
                display: "block",
                marginBottom: 5,
              }}
            >
              {translate("web.regno", "regno")}:{" "}
              {!!ownerData.current && ownerData.current.ownerId}
            </span>

            <span
              style={{
                fontWeight: "bold",
                fontSize: 17,
                textTransform: "capitalize",
                display: "block",
                marginBottom: 23,
                color: "rgba(0,0,0,0.6)",
              }}
            >
              {!!ownerData.current && ownerData.current.nationality}
            </span>

            {studOwnerByIdData.length > 0 &&
              studOwnerByIdData.map((item, i) => (
                <h4
                  key={i}
                  className="fontWeight C89C66 cursorPointer"
                  onClick={() => {
                    navigate("/ownerdetails", {
                      state: {
                        id: item.ownerStudId,
                        name: item.ownerStudName,
                        numberofHorsesBred: item.numberofHorsesBred,
                        numberofHorsesOwned: item.numberofHorsesOwned,
                        nationality: item.nationality,
                        ownerId: item.ownerStudNum,
                      },
                    });
                  }}
                >
                  {item.ownerStudType}: {item.ownerStudNum}
                  {" - "}
                  {item.ownerStudName}
                </h4>
              ))}
          </div>

          <div className="col-lg-2 col-md-4 col-sm-12 rtl  ">
            <span
              style={{
                ...TabStyle,
                background:
                  HorsesOwnedHovered || HorsesOwnedActive
                    ? "#E44D52"
                    : TabStyle.background,
                color:
                  HorsesOwnedHovered || HorsesOwnedActive
                    ? "#fff"
                    : TabStyle.color,
              }}
              onMouseOver={() => setHorsesOwnedHovered(true)}
              onMouseOut={() => setHorsesOwnedHovered(false)}
              onClick={() => {
                setHorsesOwnedActive(true);
                setHorsesBredsActive(false);
                //setSelectedSearchType(1);
                //dispatch(resetSearch());
                handleSelectedSearchType(1);
              }}
            >
              {translate("web.horsesOwned", "Horses Owned")}
              {!!ownerData.current &&
                " " + ownerData.current.numberofHorsesOwned}
            </span>
          </div>

          <div className="col-lg-2 col-md-4 col-sm-12 rtl  ">
            <span
              style={{
                ...TabStyle,
                background:
                  HorsesBredsHovered || HorsesBredsActive
                    ? "#E44D52"
                    : TabStyle.background,
                color:
                  HorsesBredsHovered || HorsesBredsActive
                    ? "#fff"
                    : TabStyle.color,
              }}
              onMouseOver={() => setHorsesBredsHovered(true)}
              onMouseOut={() => setHorsesBredsHovered(false)}
              onClick={() => {
                setHorsesOwnedActive(false);
                setHorsesBredsActive(true);
                //setSelectedSearchType(2);
                //dispatch(resetSearch());
                handleSelectedSearchType(2);
              }}
            >
              {translate("web.horsesBreds", "horsesBreds")}
              {!!ownerData.current &&
                " " + ownerData.current.numberofHorsesBred}
            </span>
          </div>

          <div
            className="row col-lg-12 col-md-12 col-sm-12 "
            style={{
              minHeight: 250,
              padding: 0,
              borderRadius: 5,
              background: "rgba(0, 0, 0, 0.08)",
              margin:'auto'
            }}
          >
            {!!studBookOwnerDetailsData &&
              studBookOwnerDetailsData.length > 0 &&
              studBookOwnerDetailsData.map((item, i) => (
                <HorseCard isArabicClass={true} item={item} i={i} />
              ))}

            {studBookOwnerDetailsStateNoDataFound && (
              <div
                className="col-lg-12 col-sm-12 text-danger mt-4 text-center"
                
              >
                {translate("web.nodataFound", "No Data Found !")}
              </div>
            )}

            <div
              className="col-lg-12 col-md-12 col-sm-12  text-center"
              style={{ marginTop: 20 }}
            >
              {!studBookLoading &&
              !!studBookOwnerDetailsData &&
              studBookOwnerDetailsData.length > 0 ? (
                <React.Fragment>
                  <SubmitBtn
                    disabled={stateShowMoreButtonHorse}
                    onClick={handelShowMore}
                    marginBottom={50}
                    type={"loadmore"}
                    label={`
                        ${translate("web.showMore", "Show More")} (${
                      studBookOwnerDetailsData.length
                    } ${translate("web.of", "Of")} ${totalCountOwnerDetails})`}
                  />
                </React.Fragment>
              ) : (
                ""
              )}
              {studBookLoading && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OwnerDetailsPage;
