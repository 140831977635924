import axios from "../../axios/axios";

export const FetchHorseMarketAPI = async (param) => {
  try {
     
    const response = await axios.post(`/HorseMarket/FilterHorseMarket`, {
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
      horseName: param.search,
      Gender: param.gender.length === 0 ? null : param.gender,
      horseCategory: param.category.length === 0 ? null : param.category,
      marketType: param.marketType,
      priceFrom: param.priceFrom,
      priceTo: param.priceTo,
      countryId: param.country,
      cityId:param.cityId,
      birthDateFrom: param.birthDateFrom,
      birthDateTo: param.birthDateTo,
      sortBy: param.sortBy,
    });

    return response;
    //return { data: [] };
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchHorseMarketDetailsAPI = async (param) => {
  try {
    // console.log(param);
    let response = await axios.get(
      `/HorseMarket/GetHorseMarketDetails?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
