import axios from "../../axios/axios";

export const FetchStudBookAPI = async (param) => {
  try {
    const response = await axios.post(`/StudBook/FilterHorses`, {
      registrationNumber: param.regNo,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
      genderType: param.gender,
      horseUELN: param.horseUELN,
      birthDateFrom: param.birthDateFrom,
      birthDateTo: param.birthDateTo,
      originId: param.origin,
      ownerRegisterNumber: param.ownerRegisterNumber,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookCountriesAPI = async (param) => {
  try {
    const response = await axios.get(`/StudBook/GetCountries?lang=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookOwnerAPI = async (param) => {
  try {
    const response = await axios.post(`/StudBook/FilterOwners`, {
      ownerId: param.ownerId,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookHorseByOwnerAPI = async (param) => {
  try {
    const response = await axios.post(`/StudBook/GetHorseByOwner`, {
      ownerId: param.ownerId,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookHorseByOwnerBreederAPI = async (param) => {
  try {
    const response = await axios.post(`/StudBook/GetHorseByOwnerBreeder`, {
      ownerId: param.ownerId,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookHorseDetailsAPI = async (param) => {
  try {
    const response = await axios.post(`/StudBook/GetHorseDetails`, {
      id: param.id,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    window.location.href = `${window.location.origin}/404-not-found`;
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookHorseProgenyAPI = async (param) => {
  try {
    let response = await axios.post(`/StudBook/GetHorseProgeny`, {
      id: param.id,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookSiblingsSireAndDamAPI = async (param) => {
  try {
    let response = await axios.post(`/StudBook/GetSiblingsSireAndDam`, {
      id: param.id,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookSiblingsSireAPI = async (param) => {
  try {
    let response = await axios.post(`/StudBook/GetSiblingsSire`, {
      id: param.id,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookSiblingsDamAPI = async (param) => {
  try {
    let response = await axios.post(`/StudBook/GetSiblingsDam`, {
      id: param.id,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookHorseCompetitionAPI = async (param) => {
  try {
    let response = await axios.post(`/StudBook/GetHorseCompetition`, {
      id: param.id,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookHorsePedigreeByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/StudBook/GetHorsePedigreeById?horseId=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookLanguageAPI = async (param) => {
  try {
    let response = await axios.get(
      `/StudBook/GetLanguageForWeb?language=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudBookOwnerDetailsAPI = async (param) => {
  try {
    let response = await axios.post(`/StudBook/GetOwnerDetails`, {
      ownerId: param.ownerId,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchFilterOwnersOrUsersAPI = async (param) => {
  try {
    const response = await axios.post(`/StudBook/FilterOwnersOrUsers`, {
      id: param.id,
      ownerId: param.ownerId,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
      tableType: param.tableType,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
