import { combineReducers } from "@reduxjs/toolkit";
import StudBookSlice from "../StudBook/StudBookSlice";
import auctionsReducer from "../Auctions/AuctionsSlice";
import auctionReducer from "../AuctionData/AuctionsDataSlice";
import hourseAuctionDataReducer from "../HorseAuctionData/HorseAuctionDataSlice";
import OwnerProfileSlice from "../OwnerProfile/OwnerProfileSlice";
import HorseMarketSlice from "../HorseMarket/HorseMarketSlice";
import ShowsSlice from "../Shows/ShowsSlice";
import ShowEntrySlice from "../ShowEntry/ShowEntrySlice";
import LookUpSlice from "../LookUp/LookUpSlice";
import AuctionEntrySlice from "../AuctionEntry/AuctionEntrySlice";
import ServicesRequestsSlice from "../ServicesRequests/ServicesRequestsSlice";

const RootReducer = combineReducers({
  studBookSlice: StudBookSlice,
  auctions: auctionsReducer,
  auction: auctionReducer,
  horseauctiondata: hourseAuctionDataReducer,
  ownerProfileSlice: OwnerProfileSlice,
  horseMarketSlice: HorseMarketSlice,
  showsSlice: ShowsSlice,
  showEntrySlice: ShowEntrySlice,
  lookUpSlice: LookUpSlice,
  auctionEntrySlice: AuctionEntrySlice,
  servicesRequestsSlice: ServicesRequestsSlice,

  // Add other reducers here
});

export default RootReducer;
