import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk,
  FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import dayjs from "dayjs";
import { FetchHorseColorsthunk } from "../Redux/LookUp/LookUpAction";
import axios from "../axios/axios";
import { FetchOwnerHorsesDropDownthunk } from "../Redux/OwnerProfile/OwnerProfileAction";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { base64ToBlob } from "../helper/base64ToBlob";
import { isMoreThanDays } from "../helper/isMoreThanDays";
import axiosMap from "axios";

export default function RequestRegisteringNewArabianHorseFoal() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { horseColorsData } = useSelector(
    (state) => state.lookUpSlice
  );
  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData,
    createRegisteringNewArabianHorseFoalServiceRequestData,
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));

  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    coveringCertificate: "",
    coveringCertificateDrop: {
      label: translate("web.coveringCertificate", "Covering Certificate"),
      value: null,
    },
    mareHorse: "",
    stallionHorse: "",
    mareOwner: "",
    stallionOwner: "",
    breeder: "",
    coveringDate: "",

    nameEn: "",
    nameAr: "",
    birthDate: null,
    color: "",
    colorDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    sex: "Stallion",
    emirate: "",
    emirateDrop: {
      label: translate("web.chooseEmirate", "Choose Emirate"),
      value: null,
    },
    area: "",
    areaDrop: {
      label: translate("web.chooseArea", "Choose Area"),
      value: null,
    },
    locationOnMap: "",
    embryoTransfer: false,
    recipientMare: "ArabianRegistered",

    recipientMareId: "",
    recipientMareIdDrop: {
      label: translate("web.recipientMareName", "Recipient Mare Name"),
      value: null,
    },
    transferDate: null,
    veterinarian: "",
    transferAddress: "",
    microchipNo: "",
    recipientMareNameEnUnReg: "",
    recipientMareNameArUnReg: "",
    isRegistration: true,
    address: "",
    mobile: "",

    filePathVeterinarianReport: "",
    fileTypeVeterinarianReport: "",
    fileNameVeterinarianReport: "",
    documentBase64VeterinarianReport: "",
    veterinarianReportAttachmentFile: null,

    eventName: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",
    registeringNewArabianHorseFoalId: null,
    isEmbryoTransfer: false,
    // recipientMareSireEnUnReg: "",
    // recipientMareSireArUnReg: "",
    // recipientMareDamEnUnReg: "",
    // recipientMareDamArUnReg: "",
    // recipientMareOwnerNameEnUnReg: "",
    // recipientMareOwnerNameArUnReg: "",
    // recipientMareBreederNameEnUnReg: "",
    // recipientMareBreederNameArUnReg: "",
    // recipientMareAddressUnReg: "",
    // recipientMareCurrentRegNoUnReg: "",
    // recipientMareUelnUnReg: "",
    // recipientMareColorUnReg: "",
    // recipientMareColorUnRegDrop: {
    //   label: translate("web.color", "Color"),
    //   value: null,
    // },
    // recipientMareOriginUnReg: "",
    // recipientMareOriginUnRegDrop: {
    //   label: translate("web.chooseOrigin", "Choose Origin"),
    //   value: null,
    // },
    // recipientMareCountryUnReg: "",
    // recipientMareCountryUnRegDrop: {
    //   label: translate("web.horseCountry", "Horse Country"),
    //   value: null,
    // },
    // recipientMareBirthDateUnReg: null,
  });

  const [errorCoveringCertificate, setErrorCoveringCertificate] = useState("none");
  const [errorNameEn, setErrorNameEn] = useState("none");
  const [errorNameAr, setErrorNameAr] = useState("none");
  const [errorBirthDate, setErrorBirthDate] = useState("none");
  const [errorColor, setErrorColor] = useState("none");
  const [errorEmirate, setErrorEmirate] = useState("none");
  const [errorArea, setErrorArea] = useState("none");
  const [errorLocationOnMap, setErrorLocationOnMap] = useState("none");
  const [errorRecipientMare, setErrorRecipientMare] = useState("none");
  const [errorRecipientMareNameEnUnReg, setErrorRecipientMareNameEnUnReg] = useState("none");
  const [errorRecipientMareNameArUnReg, setErrorRecipientMareNameArUnReg] = useState("none");
  const [errorMicrochipNo, setErrorMicrochipNo] = useState("none");
  const [errorTransferDate, setErrorTransferDate] = useState("none");
  const [errorVeterinarian, setErrorVeterinarian] = useState("none");
  const [errorTransferAddress, setErrorTransferAddress] = useState("none");
  const [errorVeterinarianReport, setErrorVeterinarianReport] = useState("none");
  const [errorAddress, setErrorAddress] = useState("none");
  const [errorMobile, setErrorMobile] = useState("none");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });

  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //const [geoLocation, setGeoLocation] = useState({ lat: 51.505, lng: -0.09 }); // Default to London
  //const [statusGeoLocation, setStatusGeoLocation] = useState("new");

  const [latitude, setLatitude] = useState(23.55344);
  const [longitude, setLongitude] = useState(53.56701);
  const [lockey] = useState(Math.floor(Math.random() * 10000 + 1000));

  const _isOnlinePayment = useRef("");

  const [emiratelistoptions, setEmirateListOptions] = useState([]);
  const [regionsList, setRegionsList] = useState([]);
  const [
    veterinarianReportAttachmentFile,
    setVeterinarianReportAttachmentFile,
  ] = useState([]);

  //function
  const handleFormChange = (e, input) => {
    if (input === "coveringCertificate") {
      let coveringCertificate = ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData.filter((item) => item.id == e.value);

      debugger
      setFormModel({
        ...formModel,
        coveringCertificate: e.value,
        coveringCertificateDrop: e,
        mareHorse: coveringCertificate[0]?.mare ?? "",
        stallionHorse: coveringCertificate[0]?.stallion ?? "",
        mareOwner: coveringCertificate[0]?.mareOwner ?? "",
        stallionOwner: coveringCertificate[0]?.stallionOwner ?? "",
        coveringDate: coveringCertificate[0]?.coveringDate ?? "",
        breeder: coveringCertificate[0]?.breeder ?? "",
        isEmbryoTransfer: coveringCertificate[0]?.isEmbryoTransfer ?? false,
      });
    } else if (!!input && input === "color") {
      setFormModel({
        ...formModel,
        color: e.value,
        colorDrop: e,
      });
    } else if (!!input && input === "emirate") {
      getRegionListbyID(e.value);

      setFormModel({
        ...formModel,
        emirate: e.value,
        emirateDrop: e,
        selectedregionDrop: null
      });
    } else if (!!input && input === "area") {
      setFormModel({
        ...formModel,
        area: e.value,
        areaDrop: e,
      });
    } else if (input === "embryoTransfer") {
      setFormModel({
        ...formModel,
        embryoTransfer: e.target.checked,
        recipientMare: "ArabianRegistered",
        recipientMareId: "",
        recipientMareIdDrop: {
          label: translate("web.recipientMareName", "Recipient Mare Name"),
          value: null,
        },
        recipientMareNameEnUnReg: "",
        recipientMareNameArUnReg: "",
        microchipNo: "",
        transferDate: null,
        veterinarian: "",
        transferAddress: "",
        veterinarianReportAttachmentFile: null,
      });

      setVeterinarianReportAttachmentFile([]);
    } else if (input === "recipientMare") {
      setFormModel({
        ...formModel,
        recipientMare: e.target.value,
        recipientMareId: "",
        recipientMareIdDrop: {
          label: translate("web.recipientMareName", "Recipient Mare Name"),
          value: null,
        },
        recipientMareNameEnUnReg: "",
        recipientMareNameArUnReg: "",
        microchipNo: "",
      });

    } else if (!!input && input === "recipientMareId") {
      setFormModel({
        ...formModel,
        recipientMareId: e.value,
        recipientMareIdDrop: e,
      });
    } else if (input === "receiveType") {
      setFormModel({
        ...formModel,
        isRegistration: e.target.value === "Registration",
        address: "",
        mobile: ""
      });
    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.coveringCertificate === ""
    ) {
      setErrorCoveringCertificate("");
      valid = false;
    }
    else {
      setErrorCoveringCertificate("none");
    }

    if (
      formModel.nameEn === ""
    ) {
      setErrorNameEn("");
      valid = false;
    }
    else {
      setErrorNameEn("none");
    }

    if (
      formModel.nameAr === ""
    ) {
      setErrorNameAr("");
      valid = false;
    }
    else {
      setErrorNameAr("none");
    }

    if (
      formModel.birthDate === null
    ) {
      setErrorBirthDate("");
      valid = false;
    }
    else {
      setErrorBirthDate("none");
    }

    if (
      formModel.color === ""
    ) {
      setErrorColor("");
      valid = false;
    }
    else {
      setErrorColor("none");
    }

    if (
      formModel.emirate === ""
    ) {
      setErrorEmirate("");
      valid = false;
    }
    else {
      setErrorEmirate("none");
    }

    if (
      formModel.area === ""
    ) {
      setErrorArea("");
      valid = false;
    }
    else {
      setErrorArea("none");
    }

    // if (
    //   formModel.locationOnMap === ""
    // ) {
    //   setErrorLocationOnMap("");
    //   valid = false;
    // }
    // else {
    //   setErrorLocationOnMap("none");
    // }

    if (
      formModel.embryoTransfer
    ) {

      if (
        formModel.recipientMare === "ArabianRegistered" && formModel.recipientMareId === ""
      ) {
        setErrorRecipientMare("");
        valid = false;
      }
      else {
        setErrorRecipientMare("none");
      }

      if (
        (formModel.recipientMare === "ArabianNotRegistered" || formModel.recipientMare === "NotArabian") && formModel.recipientMareNameEnUnReg === ""
      ) {
        setErrorRecipientMareNameEnUnReg("");
        valid = false;
      }
      else {
        setErrorRecipientMareNameEnUnReg("none");
      }
      if (
        (formModel.recipientMare === "ArabianNotRegistered" || formModel.recipientMare === "NotArabian") && formModel.recipientMareNameArUnReg === ""
      ) {
        setErrorRecipientMareNameArUnReg("");
        valid = false;
      }
      else {
        setErrorRecipientMareNameArUnReg("none");
      }

      if (
        (formModel.recipientMare === "ArabianNotRegistered" || formModel.recipientMare === "NotArabian") && formModel.microchipNo === ""
      ) {
        setErrorMicrochipNo("");
        valid = false;
      }
      else {
        setErrorMicrochipNo("none");
      }

      if (
        formModel.transferDate == null
      ) {
        setErrorTransferDate("");
        valid = false;
      }
      else {
        setErrorTransferDate("none");
      }

      if (
        formModel.veterinarian === ""
      ) {
        setErrorVeterinarian("");
        valid = false;
      }
      else {
        setErrorVeterinarian("none");
      }

      if (
        formModel.transferAddress == null
      ) {
        setErrorTransferAddress("");
        valid = false;
      }
      else {
        setErrorTransferAddress("none");
      }

      if (
        veterinarianReportAttachmentFile.length === 0 && formModel.documentBase64VeterinarianReport === ""
      ) {
        setErrorVeterinarianReport("");
        valid = false;
      }
      else {
        setErrorVeterinarianReport("none");
      }
    }

    if (
      !formModel.isRegistration && (formModel.address === "" || formModel.address == null)
    ) {
      setErrorAddress("");
      valid = false;
    }
    else {
      setErrorAddress("none");
    }

    if (
      !formModel.isRegistration && (formModel.mobile === "" || formModel.mobile == null)
    ) {
      setErrorMobile("");
      valid = false;
    }
    else {
      setErrorMobile("none");
    }

    //terms
    if (
      !checkedItems.option1 ||
      (!checkedItems.option2 && formModel.birthDate != null && isMoreThanDays(new Date(formModel.birthDate), getServiceDefinitionByIdData.penaltyDuration))
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    _isOnlinePayment.current = isOnlinePayment;

    if (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=9&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }

    else {
      if (
        validInputs()
      ) {
        debugger
        let params = {
          id: id,
          serviceDefinitionId: serviceId,
          status: status,
          eventName: formModel.eventName,
          ownerId: getUserID(),
          createdBy: localStorage.getItem("EAHSUserID"),
          registeringNewArabianHorseFoalId: formModel.registeringNewArabianHorseFoalId,

          coveringCertificateId: formModel.coveringCertificate,
          horseNameEn: formModel.nameEn,
          horseNameAr: formModel.nameAr,
          birthDateString: dayjs(formModel.birthDate).format("DD/MM/YYYY"),
          color: formModel.color,
          sex: formModel.sex,
          emirate: formModel.emirate,
          area: formModel.area,
          // locationOnMap: formModel.locationOnMap,
          //latitude: geoLocation.lat,
          //longitude: geoLocation.lng,
          latitude: latitude,
          longitude: longitude,
          embryoTransfer: formModel.embryoTransfer,
          recipientMare: formModel.recipientMare,
          recipientMareId: formModel.recipientMareId != null ? formModel.recipientMareId : '',
          recipientMareNameEnNotRegistered: formModel.recipientMareNameEnUnReg,
          recipientMareNameArNotRegistered: formModel.recipientMareNameArUnReg,
          microchipNo: formModel.microchipNo,
          transferDateString: formModel.transferDate != null ? dayjs(formModel.transferDate).format("DD/MM/YYYY") : '',
          veterinarian: formModel.veterinarian,
          transferAddress: formModel.transferAddress,
          veterinarianReportAttachmentFile: formModel.veterinarianReportAttachmentFile,
          receiveType: formModel.isRegistration ? 1 : 2,
          address: formModel.address,
          mobile: formModel.mobile,
        };

        if (action == null) {
          dispatch(FetchCreateRegisteringNewArabianHorseFoalServiceRequestthunk(params));
        } else if (action === "edit" || formModel.eventName === "SentBack") {
          dispatch(FetchUpdateRegisteringNewArabianHorseFoalServiceRequestthunk(params));
        }
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    dispatch(FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk(element.value));

    setProfile(element);

    setFormModel({
      ...formModel,
      coveringCertificate: "",
      coveringCertificateDrop: {
        label: translate("web.coveringCertificate", "Covering Certificate"),
        value: null,
      },
      mareHorse: "",
      stallionHorse: "",
      mareOwner: "",
      stallionOwner: "",
      breeder: "",
      coveringDate: "",
      recipientMareId: "",
      recipientMareIdDrop: {
        label: translate("web.recipientMareName", "Recipient Mare Name"),
        value: null,
      },
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "FoalRegistrations" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  async function getEmirateList() {
    try {
      const emiratelist = await axios.get('/lookup/getmaincities')

      const formattedData = await emiratelist.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));

      setEmirateListOptions(formattedData)
    } catch (error) {
      window.alert('error in emirates list')
    }
  }

  async function getRegionListbyID(id) {
    try {
      const regionList = await axios.get(`/lookup/getareasbyemirateid?EmirateId=${id}`)
      const formattedData = await regionList.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      setRegionsList(formattedData)
    } catch (error) {
      window.alert('error in region list')
    }
  }

  const handleUploadVeterinarianReportChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          veterinarianReportAttachmentFile: e.target.files[0],
        });

        setVeterinarianReportAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const selectLocation = async () => {
    try {
      const response = await axiosMap.get(
        `https://maps.atlasapps.net/map.aspx?getLocationString=${lockey}`
      );

      if (!response.data) {
        toast.error(translate("web.pleaseMoveLocationMap", "Please move the location on the map."));
      } else {
        const [lat, lng] = response.data.split(',').map(Number);
        setLatitude(lat);
        setLongitude(lng);
      }
    } catch (error) {
      toast.error(translate("web.errorFetchingLocationData", "Error fetching location data."));
    }
  };

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      navigate("/profile");
    }

    if (!!userProfile && userProfile?.accountType == "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

    getEmirateList();

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    dispatch(FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk(getUserID()));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));


    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId === serviceId)
    ) {
      debugger
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        registeringNewArabianHorseFoalId: getServicesRequestByIdData.registeringNewArabianHorseFoal.registeringNewArabianHorseFoalId,

        coveringCertificate: getServicesRequestByIdData.registeringNewArabianHorseFoal.coveringCertificateId,
        coveringCertificateDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.referenceNumber + " / " + translate("web.mare", "Mare") + ":" + getServicesRequestByIdData.registeringNewArabianHorseFoal.mare + " / " + translate("web.stallion", "Stallion") + ":" + getServicesRequestByIdData.registeringNewArabianHorseFoal.stallion,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.coveringCertificateId,
        },

        mareHorse: getServicesRequestByIdData.registeringNewArabianHorseFoal.mare,
        stallionHorse: getServicesRequestByIdData.registeringNewArabianHorseFoal.stallion,
        mareOwner: getServicesRequestByIdData.registeringNewArabianHorseFoal.mareOwner,
        stallionOwner: getServicesRequestByIdData.registeringNewArabianHorseFoal.stallionOwner,
        coveringDate: getServicesRequestByIdData.registeringNewArabianHorseFoal.coveringDate,
        breeder: getServicesRequestByIdData.registeringNewArabianHorseFoal.breeder,
        nameEn: getServicesRequestByIdData.registeringNewArabianHorseFoal.horseNameEn,
        nameAr: getServicesRequestByIdData.registeringNewArabianHorseFoal.horseNameAr,
        birthDate: getServicesRequestByIdData.registeringNewArabianHorseFoal.birthDate != null ? dayjs(getServicesRequestByIdData.registeringNewArabianHorseFoal.birthDate).format("YYYY-MM-DD") : null,
        color: getServicesRequestByIdData.registeringNewArabianHorseFoal.color,
        colorDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.colorName,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.color,
        },
        sex: getServicesRequestByIdData.registeringNewArabianHorseFoal.sex,
        emirate: getServicesRequestByIdData.registeringNewArabianHorseFoal.emirate,
        emirateDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.emirateName,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.emirate,
        },
        area: getServicesRequestByIdData.registeringNewArabianHorseFoal.area,
        areaDrop: {
          label: getServicesRequestByIdData.registeringNewArabianHorseFoal.areaName,
          value: getServicesRequestByIdData.registeringNewArabianHorseFoal.area,
        },
        //locationOnMap: getServicesRequestByIdData.registeringNewArabianHorseFoal.locationOnMap,
        embryoTransfer: getServicesRequestByIdData.registeringNewArabianHorseFoal.embryoTransfer,
        recipientMare: getServicesRequestByIdData.registeringNewArabianHorseFoal.recipientMare,
        recipientMareId: getServicesRequestByIdData.registeringNewArabianHorseFoal.recipientMareId,
        recipientMareNameEnUnReg: getServicesRequestByIdData.registeringNewArabianHorseFoal.recipientMareNameEnNotRegistered,
        recipientMareNameArUnReg: getServicesRequestByIdData.registeringNewArabianHorseFoal.recipientMareNameArNotRegistered,
        microchipNo: getServicesRequestByIdData.registeringNewArabianHorseFoal.microchipNo,
        transferDate: getServicesRequestByIdData.registeringNewArabianHorseFoal.transferDate != null ? dayjs(getServicesRequestByIdData.registeringNewArabianHorseFoal.transferDate).format("YYYY-MM-DD") : null,
        veterinarian: getServicesRequestByIdData.registeringNewArabianHorseFoal.veterinarian,
        transferAddress: getServicesRequestByIdData.registeringNewArabianHorseFoal.transferAddress,

        filePathVeterinarianReport: getServicesRequestByIdData.registeringNewArabianHorseFoal.filePathVeterinarianReport,
        fileTypeVeterinarianReport: getServicesRequestByIdData.registeringNewArabianHorseFoal.fileTypeVeterinarianReport,
        fileNameVeterinarianReport: getServicesRequestByIdData.registeringNewArabianHorseFoal.fileNameVeterinarianReport,
        documentBase64VeterinarianReport: getServicesRequestByIdData.registeringNewArabianHorseFoal.documentBase64VeterinarianReport,

        isRegistration: getServicesRequestByIdData.registeringNewArabianHorseFoal.isRegistration,
        address: getServicesRequestByIdData.registeringNewArabianHorseFoal.address,
        mobile: getServicesRequestByIdData.registeringNewArabianHorseFoal.mobile,
      });

      // setGeoLocation({ lat: getServicesRequestByIdData.registeringNewArabianHorseFoal.latitude, lng: getServicesRequestByIdData.registeringNewArabianHorseFoal.longitude });
      // setStatusGeoLocation("edit");

      setLatitude(getServicesRequestByIdData.registeringNewArabianHorseFoal.latitude);
      setLongitude(getServicesRequestByIdData.registeringNewArabianHorseFoal.longitude);
      //another owner
      if (
        getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId !==
        getServicesRequestByIdData.registeringNewArabianHorseFoal.createdBy &&
        subOwnerProfilesByIdData.length > 0
      ) {

        dispatch(FetchOwnerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownthunk(getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId));

        let params = {
          ownerId: getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId
            ).registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId
            )[`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.registeringNewArabianHorseFoal.ownerId
          ).value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
        option2: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    debugger
    //save
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id == null ? createRegisteringNewArabianHorseFoalServiceRequestData.id : id}&paymentType=9&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)
  //console.log(geoLocation);

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      {
        //content page
      }
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">
          {
            //Request Embryo Transfer Right Purchace Agreement title
          }
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {
            // Selected Account
          }
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-5">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}
          {
            //Covering Certificate details title
          }
          <h2 className="fontWeight colorred">
            {translate("web.coveringCertificateDetails", "Covering Certificate Details")}
          </h2>
          {
            //Covering Certificate content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            <div className="row mx-0 col-md-12 col-sm-12 rtl">
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.coveringCertificate", "Covering Certificate")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.coveringCertificate", "Covering Certificate"),
                      value: "",
                    },
                    ...(!!ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData &&
                      ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData.map((a) => ({
                        label: a.referenceNumber + " / " + translate("web.mare", "Mare:") + a.mare + " / " + translate("web.stallion", "Stallion:") + a.stallion,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.coveringCertificateDrop}
                  onChange={(e) => handleFormChange(e, "coveringCertificate")}
                  placeholder={translate("web.coveringCertificate", "Covering Certificate")}
                  name={"coveringCertificate"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                  key={"horse"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorCoveringCertificate }} className="errorCoveringCertificate">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.mareHorse", "Mare Horse")}
                  name={"mareHorse"}
                  type={"text"}
                  disabled={true}
                  value={formModel.mareHorse}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.mareOwner", "Mare Owner")}
                  name={"mareOwner"}
                  type={"text"}
                  disabled={true}
                  value={formModel.mareOwner}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breeder}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.stallionHorse", "Stallion Horse")}
                  name={"stallionHorse"}
                  type={"text"}
                  disabled={true}
                  value={
                    formModel.stallionHorse
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.stallionOwner", "stallion Owner")}
                  name={"stallionOwner"}
                  type={"text"}
                  disabled={true}
                  value={formModel.stallionOwner}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.coveringDate", "CoveringDate")}
                  name={"coveringDate"}
                  type={"text"}
                  disabled={true}
                  value={formModel.coveringDate}
                />
              </div>
            </div>
          </div>
          {
            //Horse details title
          }
          <h2 className="fontWeight colorred">
            {translate("web.horseDetails", "Horse Details")}
          </h2>
          {
            //Horse content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.name", "Name") + " (EN)"}
                name={"nameEn"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.nameEn}
                required={true}
                maxchars={27}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              {/* <span style={{ color: "red" }}>
                {
                  translate(
                    "web.nameShouldNotExceed27LettersIncludingSpaces",
                    "*Name should not exceed 27 letters including spaces*"
                  )
                }
              </span>
              <br /> */}
              <span className="Gray_color">
                {
                  translate(
                    "web.matchingArabicEnglishName",
                    "*Matching Arabic English Name*"
                  )
                }
              </span>
              <p style={{ color: "red", display: errorNameEn }}>
                {translate("web.requiredField", "Required Field")}
              </p>
            </div>

            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.name", "Name") + " (AR)"}
                name={"nameAr"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.nameAr}
                required={true}
                maxchars={27}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span className="Gray_color">
                {
                  translate(
                    "web.matchingArabicEnglishName",
                    "*Matching Arabic English Name*"
                  )
                }
              </span>
              <p style={{ color: "red", display: errorNameAr }}>
                {translate("web.requiredField", "Required Field")}
              </p>
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                htmlTags={
                  formModel.birthDate ?
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      {
                        dayjs(formModel.birthDate).format("DD/MM/YYYY")
                      }
                    </p> :
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      DD/MM/YYYY
                    </p>
                }
                name={"birthDate"}
                type={"normalDateService"}
                label={translate("web.birthDate", "Birth Date")}
                onChange={handleFormChange}
                value={formModel.birthDate}
                required={true}
                maxDate={dayjs().format("YYYY-MM-DD")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorBirthDate }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <label>
                {translate("web.color", "Color")}
                <i className="colorred"> *</i>
              </label>
              <Select
                options={[
                  {
                    label: translate("web.color", "Color"),
                    value: null,
                  },
                  ...(!!horseColorsData &&
                    horseColorsData.map((a) => ({
                      label: a.nameEn,
                      value: a.id,
                    }))),
                ]}
                value={formModel.colorDrop}
                onChange={(e) => handleFormChange(e, "color")}
                placeholder={translate("web.color", "Color")}
                name={"color"}
                isSearchable
                noOptionsMessage={() =>
                  translate("web.noOptions", "No Options")
                }
                key={"color"}
                isDisabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorColor }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <label>
                {translate("web.sex", "Sex")}
                <i className="colorred"> *</i>
              </label>
              <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
                <div style={{ maxWidth: 150 }}>
                  <NetaqInput
                    name={"sex"}
                    type={"radioServiceRequest"}
                    value={"Stallion"}
                    label={`${translate("web.stallion", "Stallion")}-`}
                    onChange={(e) => handleFormChange(e, "sex")}
                    checked={
                      formModel.sex === "Stallion"
                    }
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                </div>
                <div style={{ maxWidth: 150 }}>
                  <NetaqInput
                    name={"sex"}
                    type={"radioServiceRequest"}
                    value={"Mare"}
                    label={`${translate("web.mare", "Mare")}-`}
                    onChange={(e) => handleFormChange(e, "sex")}
                    checked={
                      formModel.sex === "Mare"
                    }
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                </div>
                <div style={{ maxWidth: 150 }}>
                  <NetaqInput
                    name={"sex"}
                    type={"radioServiceRequest"}
                    value={"Gelding"}
                    label={`${translate("web.gelding", "Gelding")}-`}
                    onChange={(e) => handleFormChange(e, "sex")}
                    checked={
                      formModel.sex === "Gelding"
                    }
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {
            //horse Location title
          }
          <h2 className="fontWeight colorred">
            {translate("web.horseLocation", "Horse Location")}
          </h2>
          {
            //horse Location content
          }
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            <div className="col-md-6 col-sm-12 rtl service">
              <NetaqInput
                type={"select"}
                label={translate("web.chooseEmirate", "Choose Emirate")}
                placeholder={translate("web.chooseEmirate", "Choose Emirate")}
                name={"emirate"}
                listSelect={emiratelistoptions}
                value={formModel.emirateDrop}
                onChange={(e) => handleFormChange(e, "emirate")}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorEmirate }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>

            <div className="col-md-6 col-sm-12 rtl service">
              <NetaqInput
                type={"select"}
                label={translate("web.area", "Area")}
                placeholder={translate("web.area", "Area")}
                name={"area"}
                listSelect={regionsList}
                value={formModel.areaDrop}
                onChange={(evt) => handleFormChange(evt, "area")}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorArea }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>

            <div className="col-md-12 col-sm-12 rtl service mt-3">
              {/* <MapWithCurrentLocation
                status={statusGeoLocation}
                location={geoLocation}
                setLocation={setGeoLocation}
                title={translate("web.locationOnMap", "Location On Map")}
                zoom={3}
                required={true}
                isInteractive={((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                  false
                  : true} /> */}

              <iframe
                id="Imap"
                src={`https://maps.atlasapps.net/map.aspx?set=yes&location=${latitude},${longitude}&key=${lockey}&MapsKey=AIzaSyB26sBNX-V2SgrBTaWpepPfXvttfiIb2YM`}
                width="100%"
                height="350"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
              ></iframe>
              <div className="col-md-3 mt-3">
                <ActionButton
                  className="btn-info text-white w-50"
                  type={"bootstrapbtn"}
                  text={`<i class="fa-solid fa-location-dot fa-lg marginrightleft-10"></i>                   
                          ${translate("web.selectLocation", "Select Location")}
                          `}
                  margin={"0"}
                  width={"100%"}
                  onClick={
                    selectLocation
                  }
                  disabled={
                    formModel.eventName === "SentBack"
                      ? true
                      : false
                  }
                />
              </div>

              <span style={{ color: "red", display: errorLocationOnMap }}>
                {translate("web.requiredField", "Required Field")}
              </span>
              {/* <NetaqInput
                label={translate("web.locationOnMap", "Location On Map")}
                name={"locationOnMap"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.locationOnMap}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              /> */}
            </div>
          </div>

          {
            ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData.length > 0 &&
            ownerCoveringCertificatesForRegisteringNewArabianHorseFoalDropDownData.filter((item) => item.id == formModel.coveringCertificate)[0]?.isEmbryoTransfer == true &&
            (
              <>
                {
                  //embryo transfer title
                }
                <h2 className="fontWeight colorred">
                  {translate("web.embryoTransfer", "Embryo Transfer")}
                </h2>
                {
                  //embryo transfer content
                }
                <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
                  {
                    //embryo Transfer
                  }
                  <div className="col-md-12 col-sm-12 rtl p0">
                    <NetaqInput
                      type={"checkbox"}
                      label={translate(
                        "web.embryoTransfer",
                        "Embryo Transfer"
                      )}
                      name="embryoTransfer"
                      checked={formModel.embryoTransfer}
                      onChange={(e) => handleFormChange(e, "embryoTransfer")}
                      disabled={
                        ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                          true
                          : false
                      }
                    />
                  </div>
                  {
                    formModel.embryoTransfer &&
                    <>
                      <div className="col-md-12 col-sm-12 mt-3">
                        <label>
                          {translate("web.recipientMare", "Recipient Mare")}
                          <i className="colorred"> *</i>
                        </label>
                        <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
                          <div style={{ maxWidth: 200 }}>
                            <NetaqInput
                              name={"recipientMare"}
                              type={"radioServiceRequest"}
                              value={"ArabianRegistered"}
                              label={`${translate("web.arabianRegistered", "Arabian Registered")}-`}
                              onChange={(e) => handleFormChange(e, "recipientMare")}
                              checked={
                                formModel.recipientMare === "ArabianRegistered"
                              }
                              disabled={
                                ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                  true
                                  : false
                              }
                            />
                          </div>
                          <div style={{ maxWidth: 200 }}>
                            <NetaqInput
                              name={"recipientMare"}
                              type={"radioServiceRequest"}
                              value={"ArabianNotRegistered"}
                              label={`${translate("web.arabianNotRegistered", "Arabian Not Registered")}-`}
                              onChange={(e) => handleFormChange(e, "recipientMare")}
                              checked={
                                formModel.recipientMare === "ArabianNotRegistered"
                              }
                              disabled={
                                ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                  true
                                  : false
                              }
                            />
                          </div>
                          <div style={{ maxWidth: 150 }}>
                            <NetaqInput
                              name={"recipientMare"}
                              type={"radioServiceRequest"}
                              value={"NotArabian"}
                              label={`${translate("web.notArabian", "Not Arabian")}-`}
                              onChange={(e) => handleFormChange(e, "recipientMare")}
                              checked={
                                formModel.recipientMare === "NotArabian"
                              }
                              disabled={
                                ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                  true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {
                        //recipient Mare name              
                      }
                      {
                        formModel.recipientMare === "ArabianRegistered" &&
                        (<div className="col-md-6 col-sm-12 mt-3">
                          <label>
                            {translate("web.recipientMareName", "Recipient Mare Name")}
                            <i className="colorred"> *</i>
                          </label>
                          <Select
                            options={[
                              {
                                label: translate("web.recipientMareName", "Recipient Mare Name"),
                                value: "",
                              },
                              ...(!!ownerHorsesDropDownData &&
                                ownerHorsesDropDownData.filter(q => q.gender === "Mare").map((a) => ({
                                  label: a.registerNumber + " - " + a.name,
                                  value: a.id,
                                }))),
                            ]}
                            value={formModel.recipientMareIdDrop}
                            onChange={(e) => handleFormChange(e, "recipientMareId")}
                            placeholder={translate("web.recipientMareName", "Recipient Mare Name")}
                            name={"recipientMareId"}
                            isSearchable
                            noOptionsMessage={() =>
                              translate("web.noOptions", "No Options")
                            }
                            autoFocus={true}
                            key={"recipientMareId"}
                            isDisabled={
                              ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                true
                                : false
                            }
                          />
                          <span style={{ color: "red", display: errorRecipientMare }}>
                            {translate("web.requiredField", "Required Field")}
                          </span>
                        </div>)
                      }
                      {
                        (formModel.recipientMare === "ArabianNotRegistered" || formModel.recipientMare === "NotArabian") &&
                        <>
                          <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                              label={translate("web.name", "Name") + " (EN)"}
                              name={"recipientMareNameEnUnReg"}
                              type={"text"}
                              onChange={handleFormChange}
                              value={formModel.recipientMareNameEnUnReg}
                              required={true}
                              disabled={
                                ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                  true
                                  : false
                              }
                            />
                            <span style={{ color: "red", display: errorRecipientMareNameEnUnReg }}>
                              {translate("web.requiredField", "Required Field")}
                            </span>
                          </div>
                          <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                              label={translate("web.name", "Name") + " (AR)"}
                              name={"recipientMareNameArUnReg"}
                              type={"text"}
                              onChange={handleFormChange}
                              value={formModel.recipientMareNameArUnReg}
                              required={true}
                              disabled={
                                ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                  true
                                  : false
                              }
                            />
                            <span style={{ color: "red", display: errorRecipientMareNameArUnReg }}>
                              {translate("web.requiredField", "Required Field")}
                            </span>
                          </div>
                          <div className="col-md-6 col-sm-12 mt-3">
                            <NetaqInput
                              label={translate("web.microchipNo", "Microchip No")}
                              name={"microchipNo"}
                              type={"text"}
                              onChange={handleFormChange}
                              value={formModel.microchipNo}
                              required={true}
                              disabled={
                                ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                  true
                                  : false
                              }
                            />
                            <span style={{ color: "red", display: errorMicrochipNo }}>
                              {translate("web.requiredField", "Required Field")}
                            </span>
                          </div>
                        </>
                      }
                      <div className="col-md-6 col-sm-12 mt-3">
                        <NetaqInput
                          htmlTags={
                            formModel.transferDate ?
                              <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                {
                                  dayjs(formModel.transferDate).format("DD/MM/YYYY")
                                }
                              </p> :
                              <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                                DD/MM/YYYY
                              </p>
                          }
                          name={"transferDate"}
                          type={"normalDateService"}
                          label={translate("web.transferDate", "Transfer Date")}
                          onChange={handleFormChange}
                          value={formModel.transferDate}
                          required={true}
                          maxDate={dayjs().format("YYYY-MM-DD")}
                          disabled={
                            ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                              true
                              : false
                          }
                        />
                        <span style={{ color: "red", display: errorTransferDate }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                        <NetaqInput
                          label={translate("web.veterinarian", "Veterinarian")}
                          name={"veterinarian"}
                          type={"text"}
                          onChange={handleFormChange}
                          value={formModel.veterinarian}
                          required={true}
                          disabled={
                            ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                              true
                              : false
                          }
                        />
                        <span style={{ color: "red", display: errorVeterinarian }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                        <NetaqInput
                          label={translate("web.transferAddress", "Transfer Address")}
                          name={"transferAddress"}
                          type={"text"}
                          onChange={handleFormChange}
                          value={formModel.transferAddress}
                          required={true}
                          disabled={
                            ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                              true
                              : false
                          }
                        />
                        <span style={{ color: "red", display: errorTransferAddress }}>
                          {translate("web.requiredField", "Required Field")}
                        </span>
                      </div>

                      <div className="col-md-6 col-sm-12 mt-3">
                        <div className="col-md-12 font-15 Gray_color mt-1">
                          {translate(
                            "web.veterinarianReport",
                            "Veterinarian Report (pdf,png)"
                          )}
                        </div>
                        <div className="col-md-12 col-sm-12">
                          <SimpleFileUpload
                            isMulti={false}
                            initialFiles={
                              !!formModel.filePathVeterinarianReport &&
                                !!formModel.documentBase64VeterinarianReport &&
                                veterinarianReportAttachmentFile.length == 0
                                ? [
                                  {
                                    file: base64ToBlob(
                                      formModel.documentBase64VeterinarianReport,
                                      formModel.fileTypeVeterinarianReport === ".pdf"
                                        ? "application/pdf"
                                        : "image/png"
                                    ),
                                    name: formModel.fileNameVeterinarianReport,
                                  },
                                ]
                                : veterinarianReportAttachmentFile
                            }
                            isServer={
                              !!formModel.filePathVeterinarianReport &&
                                !!formModel.documentBase64VeterinarianReport &&
                                veterinarianReportAttachmentFile.length == 0
                                ? true
                                : false
                            }
                            onChange={handleUploadVeterinarianReportChange}
                            accept={"image/*,application/pdf"}
                            disabled={
                              ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                                true
                                : false
                            }
                          />
                          <span style={{ color: "red", display: errorVeterinarianReport }}>
                            {translate("web.requiredField", "Required Field")}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </>

            )
          }

          {
            //How to receive a horse passport
          }
          <h2 className="fontWeight colorred">
            {translate(
              "web.receiveHorsePassport",
              "How to receive a horse passport"
            )}
          </h2>
          <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
            <div className="AuctionFilterRadioContainer">
              <div style={{ margin: "auto 0" }}>
                <NetaqInput
                  name={"receiveType"}
                  key={"Registration"}
                  type={"radioServiceRequest"}
                  value={"Registration"}
                  label={translate(
                    "web.registrationOffice",
                    "Received From EAHS Office"
                  )}
                  onChange={(e) => handleFormChange(e, "receiveType")}
                  checked={
                    formModel.isRegistration
                  }
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
              <div style={{ margin: "auto 30px" }}>
                <NetaqInput
                  name={"receiveType"}
                  key={"Post"}
                  type={"radioServiceRequest"}
                  value={"Post"}
                  label={translate("web.postOffice", "Post Delivery")}
                  onChange={(e) => handleFormChange(e, "receiveType")}
                  checked={
                    !formModel.isRegistration
                  }
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
            </div>
            {
              !formModel.isRegistration &&
              <>
                <div className="col-md-6 col-sm-12 mt-3">
                  <NetaqInput
                    label={translate("web.address", "Address")}
                    name={"address"}
                    type={"text"}
                    placeholder={translate("web.address", "Address")}
                    onChange={handleFormChange}
                    value={formModel.address}
                    required={true}
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                  <span style={{ color: "red", display: errorAddress }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                </div>
                <div
                  className="col-md-6 col-sm-12 mt-3"
                >
                  <NetaqInput
                    label={translate("web.smsPhoneNo", "Mobile No")}
                    name={"mobile"}
                    type={"text"}
                    placeholder={translate("web.smsPhoneNo", "Mobile No")}
                    onChange={handleFormChange}
                    value={formModel.mobile}
                    required={true}
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                  <span style={{ color: "red", display: errorMobile }}>
                    {translate("web.requiredField", "Required Field")}
                  </span>
                </div>
              </>
            }
          </div>

          {
            //Comments
          }
          {!!formModel.comment && (
            <>
              <h2 className="fontWeight colorred">
                {translate("web.comments", "Comments")}
              </h2>
              <div className="col-md-12 col-sm-12 padding-27 bordercategorycard mb-25 row rtl">
                {
                  // eahs Comment
                }
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.eahsComments", "EAHS Comments")}
                    name={"adminComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.comment}
                    disabled={true}
                  />
                </div>
              </div>
            </>
          )}

          {
            //terms
          }
          <h2 className="fontWeight colorred">
            {translate("web.terms", "Terms")}
          </h2>
          {
            formModel.birthDate != null && isMoreThanDays(new Date(formModel.birthDate), getServiceDefinitionByIdData.penaltyDuration) &&

            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.registeringNewArabianHorseFoalNote1",
                  "Registering New Arabian Horse Foal 1"
                ) + " ( " + getServiceDefinitionByIdData.penalty + translate(
                  "web.delayMustBePaid",
                  "Delay Must Be Paid"
                )}
                name="option2"
                checked={checkedItems.option2}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          }
          <div className="col-md-12 col-sm-12 rtl p0">
            <NetaqInput
              type={"checkbox"}
              label={translate(
                "web.ServiceGeneralNote",
                "Service General Note"
              )}
              name="option1"
              checked={checkedItems.option1}
              onChange={handleCheckboxChange}
              disabled={formModel.eventName === "" ? false : true}
            />
          </div>
          {
            // buttons
          }
          <div className="col-md-12 col-sm-12 mt-15 text-end">
            {
              //draft/pay/submit
            }
            {
              ((action == null || action === "edit") ||
                (action === "approve" && (formModel.eventName === "SentBack" || (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")))) && (
                <div className="row">
                  {
                    //draft
                  }
                  {(formModel.eventName === "" || formModel.eventName === "Draft") &&
                    <div className="col-md-3 ms-auto">
                      <ActionButton
                        type={"requests"}
                        className="btn buttonformshowentrycard height46 linkbtn w-100"
                        label={translate("web.draft", "Draft")}
                        text={
                          <>
                            <i className="fa fa-save fa-lg marginrightleft-10"></i>
                            {translate("web.draft", "Draft")}
                          </>
                        }
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => handelFormSubmit("Draft", "")}
                        disabled={formModel.eventName === "" || formModel.eventName === "Draft"
                          ? false
                          : true
                        }
                      />
                    </div>
                  }
                  {
                    //pay later
                  }
                  {userProfile.participateWithoutPayment == true && formModel.eventName !== "SentBack" && (
                    <div className="col-md-3 ms-auto">
                      <ActionButton
                        className="btn-info text-white w-100"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.approveAndPayLater", "Approve And Pay Later")}
                          `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}
                  {
                    //pay
                  }
                  {formModel.eventName !== "SentBack" &&
                    <div className="col-md-3">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 w-100"}
                        type={"requests"}
                        label={translate("web.approvePay", "Approve And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.approvePay", "Approve And Pay")}
                          </>
                        }
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  }

                  {
                    //submit
                  }
                  {action === "approve" && formModel.eventName === "SentBack" &&
                    <div className="col-md-3">
                      <ActionButton
                        type={"requests"}
                        className="btn-success text-white requestBtn w-100"
                        label={translate("web.submit", "Submit")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submit", "Submit")}
                          </>
                        }
                        margin={"auto 25px"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Submit", "")
                        }
                        disabled={
                          action === "approve" && formModel.eventName === "SentBack"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div >
  );
}
