export function isLessThanUndefinedYearOld(date, number) {
  const currentDate = new Date();

  const yearsDifference = currentDate.getFullYear() - date.getFullYear();
  if (yearsDifference < number) {
    return true;
  }

  return false;
}
