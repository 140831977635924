import axios from "../../axios/axios";

export const FetchShowEntryAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/FilterShowsEntry`, {
      ownerId: param.ownerId,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowEntrybyIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/GetShowEntrybyId?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowEntryFilesAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/GetShowEntryFiles?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowEntryOwnerHorsesAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/GetShowEntryOwnerHorses`, {
      ownerId: param.ownerId,
      createdBy: param.createdBy,
      searchValue: param.searchValue,
      showId: param.showId,
      langauge: param.langauge,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowEntryClassesAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/GetShowEntryClasses?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowScheduleAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/GetShowSchedule?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCreateShowEntryAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/CreateShowEntry`, {
      showId: param.showId,
      ownerId: param.ownerId,
      isDraft: param.isDraft,
      showParticipantsHorsesList: param.showParticipantsHorsesList,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchSearchInternationalHorsesAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/SearchInternationalHorses?name=${param.name}&countryId=${param.countryId}&uelnNo=${param.uelnNo}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchInternationalHorsesByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/GetInternationalHorsesById?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchAddEditInternationalHorseAPI = async (param) => {
  try {
    const formData = new FormData();
    if (param.id !== null) formData.append("id", param.id);

    formData.append("nameAr", param.nameAr);
    formData.append("nameEn", param.nameEn);
    formData.append("gender", param.gender);
    formData.append("birthDate", param.birthDate);
    formData.append("horseColorId", param.horseColorId);
    formData.append("address", param.address);
    formData.append("horseUELN", param.horseUELN);
    formData.append("originId", param.originId);
    formData.append("registrationNumber", param.registrationNumber);
    //formData.append("breederInfo", param.breederInfo);
    formData.append("breederNameAr", param.breederNameAr);
    formData.append("breederNameEn", param.breederNameEn);
    formData.append("isOwner", param.isOwner);
    formData.append("damNameEn", param.damNameEn);
    formData.append("damNameAr", param.damNameAr);
    formData.append("sireNameAr", param.sireNameAr);
    formData.append("sireNameEn", param.sireNameEn);
    formData.append("currentCountryId", param.currentCountryId);
    formData.append("ownerNameAr", param.ownerNameAr);
    formData.append("ownerNameEn", param.ownerNameEn);
    formData.append("createdBy", param.createdBy);
    param.horseQualificationsList.forEach((element, i) => {
      //formData.append(`horseQualificationsList[${i}].id`, null);
      formData.append(
        `horseQualificationsList[${i}].countryId`,
        element.countryId
      );
      formData.append(
        `horseQualificationsList[${i}].showName`,
        element.showName
      );
      formData.append(`horseQualificationsList[${i}].year`, element.year);
      formData.append(
        `horseQualificationsList[${i}].classRank`,
        element.classRank
      );
      formData.append(`horseQualificationsList[${i}].website`, element.website);
      formData.append(
        `horseQualificationsList[${i}].className`,
        element.className
      );
    });

    param.horseDocumentsList.forEach((element, i) => {
      if (element.id !== null)
        formData.append(`horseDocumentsList[${i}].id`, element.id);

      formData.append(
        `horseDocumentsList[${i}].documentUrl`,
        element.documentUrl
      );
      formData.append(
        `horseDocumentsList[${i}].documentType`,
        element.documentType
      );
      formData.append(
        `horseDocumentsList[${i}].documentFile`,
        element.documentFile
      );
    });

    const response = await axios.post(
      `/ShowEntry/AddEditInternationalHorse`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchMyShowParticipantsRequestAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/GetMyShowParticipantsRequest?id=${param.id}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowRequestAPI = async (param) => {
  try {
    param.pageName = "";
    let response = await axios.get(
      `/ShowEntry/GetShowRequest?id=${param.id}&showId=${param.showId}&langauge=${param.langauge}&pageName=${param.pageName}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowRequestHorsesAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/GetShowRequestHorses`, {
      showId: param.showId,
      ownerId: param.ownerId,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchAddJudgeConflictAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/AddJudgeConflict`, {
      showId: param.showId,
      ownerId: param.ownerId,
      judgeId: param.judgeId,
      isGeneral: param.isGeneral,
      selectedHorses: param.selectedHorses,
      reason: param.reason,
      description: param.description,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowConflictsAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/GetShowConflicts`, {
      showId: param.showId,
      ownerId: param.ownerId,
      langauge: param.langauge,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchDeleteJudgeConflictAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/DeleteJudgeConflict`, {
      id: param.id,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchSubOwnerProfilesByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/OwnerProfile/GetSubOwnerProfilesById?id=${param.id}&language=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchShowTermsAPI = async (param) => {
  try {
    let response = await axios.get(
      `/ShowEntry/GetShowTerms?showId=${param.showId}&langauge=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchOwnerRequestsCountAPI = async (param) => {
  try {
    const response = await axios.get(
      `/ShowEntry/GetOwnerRequestsCount?ownerId=${param.ownerId}&showId=${param.showId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchAddEditHorseQualificationAPI = async (param) => {
  try {
    const response = await axios.post(`/ShowEntry/AddEditHorseQualification`, {
      id: param.horseId,
      countryId: param.countryId,
      showName: param.showName,
      year: param.year,
      className: param.className,
      classRank: param.classRank,
      website: param.websiteURL,
    });
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetHorseQualificationByHorseIdAPI = async (param) => {
  try {
    const response = await axios.get(
      `/ShowEntry/GetHorseQualificationByHorseId?horseId=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
