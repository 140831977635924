import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { json, useLocation, useNavigate } from "react-router";
import {
  FetchShowEntryOwnerHorsesthunk,
  FetchSubOwnerProfilesByIdthunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ActionButton from "../components/ActionButton";
import Select from "react-select";
import { formatDate } from "../helper/formatDate";
import Popup from "reactjs-popup";
import NetaqInput from "../components/NetaqInput";
import {
  FetchAddEditAuctionHorsethunk,
  FetchAuctionHorsesRequeststhunk,
  FetchCountOwnerHaseHorseInAuctionEntrythunk,
  FetchDeleteAuctionHorsethunk,
  FetchGetOwnerAuctionHorsesCompletedthunk,
} from "../Redux/AuctionEntry/AuctionEntryAction";
import { resetToasterMessageAuctionEntry } from "../Redux/AuctionEntry/AuctionEntrySlice";
import UploadFile from "../components/UploadFile";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

export default function AuctionEntryCard() {
  const showEntryCardsRef = useRef(null);
  const [cardsRefCount, setCardsRefCount] = useState(0);
  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subProfiles = userProfile.subAccounts ?? [];
  const [showEntryHorseCard, setShowEntryHorseCard] = useState("");

  const dispatch = useDispatch();

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();

  const [profile, setProfile] = useState({
    label: userProfile.ownerRegisterNo +" - " + userProfile[`fullName${currentLanguage == 'en'?'':'Ar'}`],
    value:
      userProfile.accountType != "Agent"
        ? localStorage.getItem("EAHSUserID")
        : localStorage.getItem("currentSubAccountId"),
  });

  const [openPopup, setOpenPopup] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const horseFees = searchParams.has("horseFees")
    ? searchParams.get("horseFees")
    : null;

  const auctionNameAr = searchParams.has("auctionNameAr")
    ? searchParams.get("auctionNameAr")
    : null;

  const auctionNameEn = searchParams.has("auctionNameEn")
    ? searchParams.get("auctionNameEn")
    : null;

  const auctionId = searchParams.has("auctionId")
    ? searchParams.get("auctionId")
    : null;

  const maxHorsesUrl = searchParams.has("maxHorses")
    ? searchParams.get("maxHorses")
    : null;

  const { showEntryOwnerHorsesData } = useSelector(
    (state) => state.showEntrySlice
  );

  const [maxHorses, setMaxHorses] = useState(0);

  const {
    auctionEntryLoading,
    toasterMessageAuctionEntry,
    auctionHorsesRequestsData,
    countOwnerHaseHorseInAuctionEntryData,
    getOwnerAuctionHorsesCompletedData,
  } = useSelector((state) => state.auctionEntrySlice);

  // console.log(
  //   "getOwnerAuctionHorsesCompletedData",
  //   getOwnerAuctionHorsesCompletedData
  // );
  const formInputsReset = () => {
    return {
      auctionHorseId: null,
      auctionId: auctionId,
      horseId: null,
      horsePerformanceEn: "",
      horsePerformanceAr: "",
      horseStartingPrice: "",
      minimumPreferredPrice: "",
      horseIncrementalIncrease: "",
      horseVideo: "",
      horseName: "",
      regNo: "",
    };
  };

  const [formInputs, setFormInputs] = useState(formInputsReset());
  const [uploadCover, setUploadCover] = useState(null);
  const [uploadFeature, setUploadFeature] = useState(null);
  const [uploadAdditional, setUploadAdditional] = useState([]);

  const handleFormInputsChange = (event) => {
    setFormInputs({
      ...formInputs,
      [event.target.name]: event.target.value,
    });
  };

  const handleUploadCoverChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setUploadCover({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
          documentFile: e.target.files[0],
          id: null,
          documentType: "HorseCoverImage",
        });
      }
    }
  };

  const handleUploadFeatureChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setUploadFeature({
          file: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
          documentFile: e.target.files[0],
          id: null,
          documentType: "HorseFeatureImage",
        });
      }
    }
  };

  const handleUploadAdditionalChange = (e) => {
    let checkFileSize = true;
    if (e.target.files && e.target.files.length > 0) {
      let fileListHook = [];

      for (let index = 0; index < e.target.files.length; index++) {
        if (e.target.files[index].size > 10 * 1024 * 1024) {
          checkFileSize = false;
        } else {
          fileListHook.push({
            file: URL.createObjectURL(e.target.files[index]),
            name: e.target.files[index].name,
            documentFile: e.target.files[0],
            id: null,
            documentType: "AuctionHorse",
          });
        }
      }

      if (!checkFileSize) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      }

      setUploadAdditional(fileListHook);
    }
  };

  const handelFormSubmit = () => {
    if (
      formInputs.horsePerformanceEn !== "" &&
      formInputs.horsePerformanceAr !== "" &&
      formInputs.horseStartingPrice !== "" &&
      formInputs.minimumPreferredPrice !== "" &&
      formInputs.horseIncrementalIncrease !== "" &&
      //formInputs.horseVideo !== "" &&
      (uploadCover != null ||
        uploadFeature != null ||
        uploadAdditional.length > 0)
    ) {
      // let auctionHorseFiles = uploadAdditional
      //   .concat(uploadFeature)
      //   .concat(uploadCover);

      let auctionHorseFiles = [];
      if (uploadAdditional.length > 0) {
        auctionHorseFiles = [...uploadAdditional];
      }
      if (uploadFeature != null) {
        auctionHorseFiles = auctionHorseFiles.concat(uploadFeature);
      }
      if (uploadCover != null) {
        auctionHorseFiles = auctionHorseFiles.concat(uploadCover);
      }
      let params = {
        auctionHorseId: formInputs.auctionHorseId,
        auctionId: auctionId,
        horseId: formInputs.horseId,
        //ownerId: localStorage.getItem("EAHSUserID"),
        ownerId:
          userProfile.accountType != "Agent"
            ? profile.value == null
              ? localStorage.getItem("EAHSUserID")
              : profile.value
            : localStorage.getItem("currentSubAccountId"),
        createdBy: localStorage.getItem("EAHSUserID"),
        horsePerformanceEn: formInputs.horsePerformanceEn,
        horsePerformanceAr: formInputs.horsePerformanceAr,
        horseStartingPrice: formInputs.horseStartingPrice,
        minimumPreferredPrice: formInputs.minimumPreferredPrice,
        horseIncrementalIncrease: formInputs.horseIncrementalIncrease,
        horseVideo: formInputs.horseVideo,
        //createdBy: JSON.parse(localStorage.getItem("eahsUserProfile")).email,
        auctionHorseFiles: auctionHorseFiles,
      };
      dispatch(FetchAddEditAuctionHorsethunk(params));
    } else {
      toast.error(
        translate("web.completeMissingData", "Complete The Missing Data")
      );
    }
  };

  const handelFetchShowEntryOwnerHorses = () => {
    let params = {
      ownerId:
        userProfile.accountType != "Agent"
          ? profile.value == null
            ? localStorage.getItem("EAHSUserID")
            : profile.value
          : localStorage.getItem("currentSubAccountId"),
      createdBy: localStorage.getItem("EAHSUserID"),
      showId: "00000000-0000-0000-0000-000000000000",
      searchValue: null,
      langauge: currentLanguage,
    };
    dispatch(FetchShowEntryOwnerHorsesthunk(params));
  };

  useEffect(() => {
    handelFetchShowEntryOwnerHorses();
    if (userProfile.accountType != "Agent") {
      let subOwnerProfilesParams = {
        id: localStorage.getItem("EAHSUserID"),
        langauge: currentLanguage,
      };
      dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));
    }

    let auctionHorsesRequestsParams = {
      ownerId:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      langauge: currentLanguage,
      auctionId: auctionId,
    };
    dispatch(FetchAuctionHorsesRequeststhunk(auctionHorsesRequestsParams));

    let params = {
      ownerId:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      auctionId: auctionId,
    };
    dispatch(FetchCountOwnerHaseHorseInAuctionEntrythunk(params));

    dispatch(FetchGetOwnerAuctionHorsesCompletedthunk(params));
  }, [currentLanguage]);

  // console.log(
  //   "countOwnerHaseHorseInAuctionEntryData",
  //   countOwnerHaseHorseInAuctionEntryData
  // );

  // console.log("auctionHorsesRequestsData", auctionHorsesRequestsData);
  useEffect(() => {
    auctionHorsesRequestsData.map((item) => {
      for (const e of showEntryCardsRef.current.children) {
        if (
          e.getElementsByTagName("i")[0]?.tagName === "I" &&
          e.getElementsByTagName("i")[0].id === item.horseId
        ) {
          e.getElementsByTagName("i")[0].className = "";
        }
        // else {
        //   e.getElementsByTagName("i")[0].className =
        //     "fa fa-plus plusIcon fa-lg line-height";
        // }
      }
    });

    getOwnerAuctionHorsesCompletedData.map((item) => {
      for (const e of showEntryCardsRef.current.children) {
        if (
          e.getElementsByTagName("i")[0]?.tagName === "I" &&
          e.getElementsByTagName("i")[0].id === item
        ) {
          e.getElementsByTagName("i")[0].className = "";
        }
        // else {
        //   e.getElementsByTagName("i")[0].className =
        //     "fa fa-plus plusIcon fa-lg line-height";
        // }
      }
    });

    setMaxHorses(maxHorsesUrl - countOwnerHaseHorseInAuctionEntryData);
  }, [auctionHorsesRequestsData, showEntryOwnerHorsesData, showEntryHorseCard]);

  const filterShowEntryHorseCard = async (element) => {
    let params = {
      createdBy: localStorage.getItem("EAHSUserID"),
      ownerId:
        userProfile.accountType != "Agent"
          ? element.value
          : localStorage.getItem("currentSubAccountId"),
      showId: "00000000-0000-0000-0000-000000000000",
      searchValue: null,
      langauge: currentLanguage,
    };
    dispatch(FetchShowEntryOwnerHorsesthunk(params));
  };

  useEffect(() => {
    if (
      toasterMessageAuctionEntry != null &&
      toasterMessageAuctionEntry === "save"
    ) {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );
      dispatch(resetToasterMessageAuctionEntry());

      let auctionHorsesRequestsParams = {
        ownerId:
          userProfile.accountType != "Agent"
            ? localStorage.getItem("EAHSUserID")
            : localStorage.getItem("currentSubAccountId"),
        langauge: currentLanguage,
        auctionId: auctionId,
      };
      dispatch(FetchAuctionHorsesRequeststhunk(auctionHorsesRequestsParams));

      handelResetForm();
    }
  }, [toasterMessageAuctionEntry]);

  const dismissAll = () => toast.dismiss();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  const handelResetForm = () => {
    setOpenPopup(false);
    setUploadAdditional([]);
    setUploadCover(null);
    setUploadFeature(null);

    setFormInputs(formInputsReset());
  };

  useEffect(() => {
    let counthorses = showEntryOwnerHorsesData.filter((item) => {
      if (item.isRegisterd && showEntryHorseCard === "") {
        return item;
      } else if (item.isRegisterd && showEntryHorseCard !== "") {
        return (
          item.name.toLowerCase().includes(showEntryHorseCard.toLowerCase()) ||
          item.registerNumber.includes(showEntryHorseCard)
        );
      }
    });

    setCardsRefCount(counthorses.length);
  }, [showEntryHorseCard]);

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${
          auctionEntryLoading ? "classdisplay" : "classnotdisplay"
        }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      <div className="row  rtl">
        {
          // available horses div
        }
        <div className="col-md-3 col-sm-12   overflowauto">
          {
            // form
          }
          <div className="col-md-12 col-sm-12 p0">
            {subProfiles.length > 0 &&
              userProfile.accountType !== "Agent" && (
                <React.Fragment>
                  <label className="capatalize_text">
                    {translate("web.selectedAccount", "Selected Account")}
                  </label>
                  <Select
                    className="mt-10 zindex200"
                    options={[
                      {
                        label: userProfile.ownerRegisterNo +" - " + userProfile[`fullName${currentLanguage == 'en'?'':'Ar'}`],
                        value: localStorage.getItem("EAHSUserID"),
                      },
                      ...(!!subProfiles &&
                        subProfiles.map((a) => ({
                          label: a.registerNo +" - " + a[`text${currentLanguage == 'en'?'':'Ar'}`],
                          value: a.value,
                        }))),
                    ]}
                    value={profile}
                    onChange={(e) => {
                      filterShowEntryHorseCard(e);
                      setProfile(e);
                    }}
                    placeholder={localStorage.getItem("EAHSUserFullName")}
                    isSearchable
                    noOptionsMessage={() =>
                      translate("web.noOptions", "No Options")
                    }
                    styles={customStyles}
                  />
                </React.Fragment>
              )}
            {userProfile.accountType == "Agent" && (
              <>
                <h4 className="bold">
                  {localStorage.getItem("currentSubAccountLabel")}
                </h4>
              </>
            )}
            <div className="input-group formshowentrycard mt-15 mr-0">
              <i className="fas fa-search fa-xl iconformshowentrycard"></i>
              <input
                type="text"
                id="searchStudBook"
                name="searchStudBook"
                className="form-control height30hidden zindex0"
                onChange={(e) => {
                  setShowEntryHorseCard(e.target.value);
                }}
                placeholder={translate(
                  "web.searchAvailableHorses",
                  "Search for Horses, By Name Or Reg Number"
                )}
              />
            </div>
          </div>
          {
            // title
          }
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            <h4 className="mt-25 ">
              {translate(
                "web.yourAvailableHorsesForEntry",
                "Your Available Horses for entry"
              )}
            </h4>
          </div>
          {
            //registered Horses cards
          }
          <div ref={showEntryCardsRef} className="mobileRow">
            {!!showEntryOwnerHorsesData &&
              showEntryOwnerHorsesData.length > 0 &&
              showEntryOwnerHorsesData.some((obj) => obj.isRegisterd) &&
              showEntryOwnerHorsesData
                .filter((item) => {
                  if (item.isRegisterd && showEntryHorseCard === "") {
                    return item;
                  } else if (item.isRegisterd && showEntryHorseCard !== "") {
                    return (
                      item.name
                        .toLowerCase()
                        .includes(showEntryHorseCard.toLowerCase()) ||
                      item.registerNumber.includes(showEntryHorseCard)
                    );
                  }
                })
                .map((showEntryOwnerHorsesItem, iShowEntryOwnerHorsesItem) => (
                  <div
                    key={iShowEntryOwnerHorsesItem}
                    style={{ minWidth: 300 }}
                    className="col-lg-12 col-md-12 col-sm-12 horse-market-card showentrycard minHight150"
                    onClick={() => {
                      if (
                        ((maxHorses > 0 &&
                          auctionHorsesRequestsData.length < maxHorses) ||
                          maxHorses === 0) &&
                        auctionHorsesRequestsData.findIndex(
                          (obj) => obj.horseId === showEntryOwnerHorsesItem.id
                        ) === -1 &&
                        getOwnerAuctionHorsesCompletedData.findIndex(
                          (obj) => obj === showEntryOwnerHorsesItem.id
                        ) === -1
                      ) {
                        setFormInputs({
                          ...formInputs,
                          horseId: showEntryOwnerHorsesItem.id,
                          regNo: showEntryOwnerHorsesItem.registerNumber,
                          horseName: showEntryOwnerHorsesItem.name,
                        });

                        setOpenPopup((o) => !o);
                      }
                    }}
                  >
                    <div className="horseCardContainer">
                      <div className="col-md-12 titletrashshowentrycard">
                        <span className="horseCardLabeltitle text-transform-none">
                          {showEntryOwnerHorsesItem.registerNumber} {" - "}
                          {showEntryOwnerHorsesItem.name}
                        </span>
                        <i
                          className="fa fa-plus plusIcon fa-lg line-height"
                          id={showEntryOwnerHorsesItem.id}
                        ></i>
                      </div>

                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <span className="horseCardLabeltitle">
                            {showEntryOwnerHorsesItem.birthDate &&
                              formatDate(
                                showEntryOwnerHorsesItem.birthDate
                              )}{" "}
                            {" - "}{" "}
                            {`${translate(
                              "web." +
                                showEntryOwnerHorsesItem.gender.toLowerCase(),
                              showEntryOwnerHorsesItem.gender
                            )}`}
                          </span>
                          <span className="horseCardLabeltitle">
                            {showEntryOwnerHorsesItem.countryName != null &&
                              showEntryOwnerHorsesItem.countryName !== "" &&
                              showEntryOwnerHorsesItem.countryName}
                          </span>
                          {
                            // <span className="horseCardLabeltitle">
                            //   {`${translate(
                            //     "web." +
                            //       showEntryOwnerHorsesItem.gender.toLowerCase(),
                            //     showEntryOwnerHorsesItem.gender
                            //   )}`}
                            // </span>
                          }
                          {
                            //   <span className="horseCardLabeltitle fontsize16  fontWeight fontsize16">
                            //   {translate(
                            //     "web.registerationNumber",
                            //     "Registration Number"
                            //   )}
                            // </span>
                            // <span className="horseCardLabeltitle  fontsize18">
                            //   {showEntryOwnerHorsesItem.registerNumber}
                            // </span>
                          }
                        </div>
                      </div>
                      <span className="horseCardLabeltitle"></span>
                      <div className="col-md-12 titletrashshowentrycard titleShowSchedule">
                        {
                          // <span className="horseCardLabeltitle">
                          //     {horseFees} {currentLanguage === "en" ? "AED" : "د.إ"}
                          //   </span>
                        }
                        {getOwnerAuctionHorsesCompletedData.findIndex(
                          (obj) => obj === showEntryOwnerHorsesItem.id
                        ) > -1 && (
                          <span className="colorred horseCardLabeltitle textalignend">
                            *{" "}
                            {translate(
                              "web.horseAlreadyOnList",
                              "Already Added"
                            )}{" "}
                            *
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
          {
            //pop up
          }
          <Popup
            modal
            lockScroll
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            className="popCustomOverLay "
            contentStyle={{
              width: "100%",
              maxWidth: 1000,
              // height: '80vh',
            }}
          >
            {(close) => (
              <React.Fragment>
                <h2 className="bold m0 p0 ">
                  {translate("web.addHorseInAuction", "Add Horse At Auction")}
                </h2>
                <h3 className="bold m0 p0">
                  {currentLanguage === "en" ? auctionNameEn : auctionNameAr}
                </h3>
                <h3 className="bold m0 p0">
                  {formInputs.regNo + " - " + formInputs.horseName}
                </h3>
                <div className="divModalAuctionEntry overflowYSCroll">
                  {
                    //form
                  }
                  {
                    //Horse Rules in Auction Section label
                  }
                  <h3 className="fontWeight colorred textalignstart m0 p0 rtl">
                    {translate(
                      "web.horseRulesInAuction",
                      "Horse Rules in Auction"
                    )}
                  </h3>
                  {
                    //Horse Rules in Auction Section inputs
                  }
                  <div className="col-md-12 col-sm-12 padding-5 bordercategorycard row mx-0  rtl mb-25 textalignstart">
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate(
                          "web.horsePerformanceEn",
                          "Horse Performance (En)"
                        )}
                        name={"horsePerformanceEn"}
                        placeholder={translate(
                          "web.horsePerformanceEn",
                          "Horse Performance (En)"
                        )}
                        type={"textarea"}
                        maxchars={1150}
                        onChange={handleFormInputsChange}
                        value={formInputs.horsePerformanceEn}
                        required={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate(
                          "web.horsePerformanceAr",
                          "Horse Performance (Ar)"
                        )}
                        name={"horsePerformanceAr"}
                        placeholder={translate(
                          "web.horsePerformanceAr",
                          "Horse Performance (Ar)"
                        )}
                        type={"textarea"}
                        maxchars={1000}
                        onChange={handleFormInputsChange}
                        value={formInputs.horsePerformanceAr}
                        required={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate(
                          "web.horseStartingPrice",
                          "Horse Starting Price"
                        )}
                        name={"horseStartingPrice"}
                        placeholder={translate(
                          "web.horseStartingPrice",
                          "Horse Starting Price"
                        )}
                        type={"number"}
                        required={true}
                        onChange={handleFormInputsChange}
                        value={formInputs.horseStartingPrice}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate(
                          "web.minimumPreferredPrice",
                          "Minimum Preferred Price"
                        )}
                        name={"minimumPreferredPrice"}
                        placeholder={translate(
                          "web.minimumPreferredPrice",
                          "Minimum Preferred Price"
                        )}
                        type={"number"}
                        required={true}
                        onChange={handleFormInputsChange}
                        value={formInputs.minimumPreferredPrice}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate(
                          "web.horseIncrementalIncrease",
                          "Horse Incremental Increase"
                        )}
                        name={"horseIncrementalIncrease"}
                        placeholder={translate(
                          "web.horseIncrementalIncrease",
                          "Horse Incremental Increase"
                        )}
                        type={"number"}
                        required={true}
                        onChange={handleFormInputsChange}
                        value={formInputs.horseIncrementalIncrease}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate("web.horseVideo", "Horse Video Url")}
                        name={"horseVideo"}
                        placeholder={translate(
                          "web.horseVideo",
                          "Horse Video Url"
                        )}
                        type={"text"}
                        onChange={handleFormInputsChange}
                        value={formInputs.horseVideo}
                        //required={true}
                      />
                    </div>
                  </div>
                  {
                    //Horse Images Section label
                  }
                  <h3 className="fontWeight colorred textalignstart m0 p0 rtl">
                    {translate("web.horseImages", "Horse Images")}
                  </h3>
                  {
                    //Horse Images Section Inputs
                  }
                  <div className="col-md-12 col-sm-12 padding-5 bordercategorycard row mx-0   rtl mb-25 textalignstart">
                    {
                      // upload buttons
                    }
                    <div className="col-md-12 col-sm-12 ">
                      <span>
                        {translate(
                          "web.addAtLeastOneImage",
                          "Add at least one Image"
                        )}
                        <i className="colorred">*</i>
                      </span>
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                      <h4 className="bold m0 p0">
                        {translate("web.coverImage", "Cover Image")}
                      </h4>
                      <UploadFile
                        text="Upload"
                        className="maxWidth150"
                        iconClassName={
                          "fa-solid fa-upload fa-lg marginrightleft-5"
                        }
                        onChange={handleUploadCoverChange}
                        accept={"image/*"}
                      />
                      {uploadCover != null && (
                        <>
                          <br />
                          <div className="col-md-12 col-sm-12">
                            <Link
                              to={uploadCover.file}
                              className="C89C66 flex-center rowReverse  cursorPointer"
                              target="_blank"
                            >
                              <i className="fa fa-file fa-3x"></i>
                              <span className="black-color fontsize18 marginrightleft-10 ">
                                {uploadCover.name}
                              </span>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                      <h4 className=" bold m0 p0">
                        {translate("web.featureImage", "Feature Image")}
                      </h4>
                      <UploadFile
                        text="Upload"
                        className="maxWidth150"
                        iconClassName={
                          "fa-solid fa-upload fa-lg marginrightleft-5"
                        }
                        onChange={handleUploadFeatureChange}
                        accept={"image/*"}
                      />
                      {uploadFeature != null && (
                        <>
                          <br />
                          <div className="col-md-12 col-sm-12 mb-25">
                            <Link
                              to={uploadFeature.file}
                              className="C89C66 flex-center rowReverse  cursorPointer mb-25"
                              target="_blank"
                            >
                              <i className="fa fa-file fa-3x"></i>
                              <span className="black-color fontsize18 marginrightleft-10 ">
                                {uploadFeature.name}
                              </span>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-md-4 col-sm-12 ">
                      <h4 className="bold m0 p0">
                        {translate("web.additionalImages", "Additional Images")}
                      </h4>
                      <UploadFile
                        text="Upload"
                        ismultiFile={true}
                        className="maxWidth150"
                        iconClassName={
                          "fa-solid fa-upload fa-lg marginrightleft-5"
                        }
                        onChange={handleUploadAdditionalChange}
                        accept={"image/*"}
                      />
                      {uploadAdditional.length > 0 &&
                        uploadAdditional.map((item, i) => (
                          <>
                            <br />
                            <div className="col-md-12 col-sm-12  mb-25">
                              <Link
                                key={i}
                                to={item.file}
                                className="C89C66 flex-center rowReverse  cursorPointer mb-25"
                                target="_blank"
                              >
                                <i className="fa fa-file fa-3x"></i>
                                <span className="black-color fontsize18 marginrightleft-10   ">
                                  {item.name}
                                </span>
                              </Link>
                            </div>
                          </>
                        ))}
                    </div>
                    {
                      //upload imgs
                    }
                  </div>
                </div>
                <div className="divHorseSelect  mt0 ">
                  <div
                    className="bidSubmit divHorseSelectClose  height-fitcontent"
                    onClick={() => {
                      handelResetForm();
                    }}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" rtl">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={handelFormSubmit}
                    >
                      <i className="fa fa-plus"></i>
                      {translate("web.addTheHorse", "Add The Horse")}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Popup>
        </div>
        {
          //content
        }
        <div className="col-md-9 col-sm-12 graybackgroundcolor minheight700 paddingonMobile">
          <h1 className="fontWeigh  addhorseHere">
            {currentLanguage === "en" ? auctionNameEn : auctionNameAr}
            {" - "} {translate("web.horseFees", "Horse Fees")} {"("} {horseFees}{" "}
            {currentLanguage === "en" ? "AED" : "د.إ"} {")"}
          </h1>
          <h2 className="fontWeigh  addhorseHere">
            {translate("web.addHorsesHere", "Add Horses Here")}
          </h2>
          <h3 className="graycolor  addhorseHere">
            {
              // maxHorses > 0 &&
              // `${translate(
              //   "web.pleaseRegisterHorsesMax",
              //   `Please note you register for up to`
              // )} ${maxHorses} ${translate("web.horsesmax", `horses max`)}`
            }

            {maxHorsesUrl > 0 &&
              `${translate(
                "web.yourCanParticipateBy",
                `Your can participate by`
              )} ${maxHorsesUrl} ${translate("web.horsesmax", `horses max`)} (${
                maxHorsesUrl -
                (auctionHorsesRequestsData.length +
                  countOwnerHaseHorseInAuctionEntryData)
              }/${maxHorsesUrl})`}
          </h3>
          <div className="col-lg-12 col-md-12 col-sm-12 ">
            {auctionHorsesRequestsData.length === 0 && cardsRefCount > 0 && (
              <h4 className="graycolor">
                {currentLanguage === "en"
                  ? translate(
                      "web.selectHorses",
                      "Select horses from the available horses on the left side"
                    )
                  : translate(
                      "web.selectHorsesRight",
                      "Select horses from the available horses on the reight side"
                    )}
              </h4>
            )}

            {maxHorses > 0 &&
              auctionHorsesRequestsData.length === maxHorses && (
                <h4 className="colorred">
                  {translate(
                    "web.reachedMaximumNumberHorses",
                    "you’ve reached the maximum number of horses for the competition"
                  )}
                </h4>
              )}
          </div>

          {
            // tabs
          }
          <div className="col-lg-12 col-md-12 col-sm-12  mt-10">
            <Tabs>
              <TabList>
                <Tab>{translate("web.draft", "Draft")}</Tab>
                {getOwnerAuctionHorsesCompletedData.length > 0 && (
                  <Tab>
                    {translate("web.horseAlreadyOnList", "Already Added")}
                  </Tab>
                )}
              </TabList>
              <TabPanel className={' row m0'}>
                {auctionHorsesRequestsData.map((item, i) => (
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                    key={i}
                  >
                    <div
                      className="horseCardContainer"
                      style={{ minHeight: 135 }}
                    >
                      <div className="col-md-12 titletrashshowentrycard">
                        <span className="horseCardLabeltitle text-transform-none">
                          {item.registerNumber}
                          {" - "} {item.horseName}
                        </span>
                        <i
                          className="fa fa-trash plusIcon cursorPointer remove"
                          onClick={() => {
                            let params = {
                              auctionHorseId: item.auctionHorseId,
                              createdBy: localStorage.getItem("EAHSUserID"),
                            };

                            dispatch(FetchDeleteAuctionHorsethunk(params));
                          }}
                        ></i>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <span className="horseCardLabeltitle ">
                            {item.birthDate != null && item.birthDate !== ""
                              ? item.birthDate && formatDate(item.birthDate)
                              : ""}
                            {" - "}
                            {`${translate(
                              "web." + item.gender.toLowerCase(),
                              item.gender
                            )}`}
                          </span>
                          <span className="horseCardLabeltitle ">
                            {item.countryName != null &&
                              item.countryName !== "" &&
                              item.countryName}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12 titletrashshowentrycard d-flex justify-content-end">
                        <span
                          className="horseMarketCardLabeltitle rtlabs l0 cursorPointer"
                          onClick={() => {
                            setFormInputs({
                              auctionHorseId: item.auctionHorseId,
                              auctionId: item.auctionId,
                              horseId: item.horseId,
                              horsePerformanceEn: item.horsePerformanceEn,
                              horsePerformanceAr: item.horsePerformanceAr,
                              horseStartingPrice: item.horseStartingPrice,
                              minimumPreferredPrice: item.minimumPreferredPrice,
                              horseIncrementalIncrease:
                                item.horseIncrementalIncrease,
                              horseVideo: item.horseVideo,
                              horseName: item.horseName,
                              regNo: item.registerNumber,
                            });

                            let covImage = null;

                            if (item.coverImage != null) {
                              covImage = {
                                file: item.coverImage.fileUrl,
                                name: item.coverImage.name,
                                documentFile: null,
                                id: item.coverImage.id,
                                documentType: item.coverImage.documentType,
                              };
                            }

                            let featImage = null;
                            if (item.featureImage != null) {
                              featImage = {
                                file: item.featureImage.fileUrl,
                                name: item.featureImage.name,
                                documentFile: null,
                                id: item.featureImage.id,
                                documentType: item.featureImage.documentType,
                              };
                            }

                            let additionalList = [];

                            if (
                              item.additionalImage != null &&
                              item.additionalImage.length > 0
                            ) {
                              item.additionalImage.map((itemImage) =>
                                additionalList.push({
                                  file: itemImage.fileUrl,
                                  name: itemImage.name,
                                  documentFile: null,
                                  id: itemImage.id,
                                  documentType: itemImage.documentType,
                                })
                              );
                            }

                            setUploadCover(covImage);
                            setUploadFeature(featImage);
                            setUploadAdditional(additionalList);
                            setOpenPopup((o) => !o);
                          }}
                        >
                          <img
                            alt="editicon"
                            src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1lZGl0IiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMSA0SDRhMiAyIDAgMCAwLTIgMnYxNGEyIDIgMCAwIDAgMiAyaDE0YTIgMiAwIDAgMCAyLTJ2LTciLz48cGF0aCBkPSJNMTguNSAyLjVhMi4xMjEgMi4xMjEgMCAwIDEgMyAzTDEyIDE1bC00IDEgMS00IDkuNS05LjV6Ii8+PC9zdmc+"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </TabPanel>

              <TabPanel className={' row m0'}>
                {getOwnerAuctionHorsesCompletedData.length > 0 &&
                  showEntryOwnerHorsesData.length > 0 &&
                  showEntryOwnerHorsesData
                    .filter((item) =>
                      getOwnerAuctionHorsesCompletedData.includes(item.id)
                    )
                    .map((item, i) => (
                      <div
                        className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                        key={i}
                      >
                        <div className="horseCardContainer">
                          <div className="col-md-12 titletrashshowentrycard">
                            <span className="horseCardLabeltitle text-transform-none">
                              {item.registerNumber}
                              {" - "}
                              {item.name}
                            </span>
                          </div>
                          <div className="row">
                            <div className="col-md-12 mb-5">
                              <span className="horseCardLabeltitle ">
                                {item.birthDate != null && item.birthDate !== ""
                                  ? item.birthDate && formatDate(item.birthDate)
                                  : ""}
                                {" - "}
                                {`${translate(
                                  "web." + item.gender.toLowerCase(),
                                  item.gender
                                )}`}
                              </span>
                              <span className="horseCardLabeltitle ">
                                {item.countryName != null &&
                                  item.countryName !== "" &&
                                  item.countryName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </TabPanel>
            </Tabs>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-15  textalignend">
            {auctionHorsesRequestsData.length > 0 ? (
              <ActionButton
                className={""}
                type={"submit"}
                text={
                  <React.Fragment>
                    <i className="fa fa-save fa-lg marginrightleft-10"></i>
                    {translate("web.proceedToPayment", "Proceed To Payment")}
                  </React.Fragment>
                }
                margin={"10px"}
                onClick={() =>
                  navigate(
                    `/auctionentrytotalregisteredhorses?maxHorses=${maxHorses}&horseFees=${horseFees}&auctionNameAr=${auctionNameAr}&auctionNameEn=${auctionNameEn}&auctionId=${auctionId}`
                  )
                }
                disabled={auctionHorsesRequestsData.length > 0 ? false : true}
              />
            ) : (
              <button
                className="btn button-saveasdraft btn-danger  marginrightleft-10"
                disabled
              >
                <i className="fa fa-save fa-lg marginrightleft-10"></i>
                {translate("web.proceedToPayment", "Proceed To Payment")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
