import React from 'react'
import { useTranslate } from './TranslationText'
import { convertToCamelCase } from '../helper/convertToCamelCase';
import { getOrdinal } from '../helper/getOrdinal';

export default function PrizeCard({ data, specialRanks = false }) {
    const { translate } = useTranslate();
    const lang = localStorage.getItem('eahsLang') ?? 'en';
    return (
        <div className='col-lg-3 col-md-5 col-sm-10 mx-md-2 my-2 text-center p0' style={{ fontSize: 14, background: '#FCFAF7', border: '2px solid #C89C66' }}>
            <div className='row m0  rtl theadHorseSelect px-1' style={{ fontSize: 14 }}>
                {data.showPrizeCageoriesChampionsList.length > 0 &&
                    <div className='col-3 p0 bold'>
                        {translate(`web.${convertToCamelCase(data?.stage)}`, data?.stage)}
                    </div>
                }
                {data?.fromRank == data?.toRank ?
                    <>
                        <div className='col-6 p0 bold'>
                            {translate('web.rank', 'Rank')}
                        </div>
                    </> :
                    <>
                        <div className='col-3 p0 bold'>
                            {translate('web.rankFrom', 'Rank From')}
                        </div>
                        <div className='col-3 p0 bold'>
                            {translate('web.rankTo', 'Rank To')}
                        </div>
                    </>
                }
                <div className='col-3 p0 bold'>
                    {translate('web.prizeVal', 'Prize Value')}
                </div>
            </div>
            <div className='row mt-2 mx-0 rtl px-1'>
                {data.showPrizeCageoriesChampionsList.length > 0 ?
                    <>
                        {data?.showPrizeCageoriesChampionsList.map((cat, index) => (
                            <>
                                <div className='col-3 p0'>
                                    {cat.name}
                                </div>
                                {data?.fromRank == data?.toRank ?
                                    <div className='col-6 bold p0' dangerouslySetInnerHTML={{ __html: getOrdinal(data?.fromRank, lang, data?.fromRank < 4 ? specialRanks : false) }} />
                                    :
                                    <>
                                        <div className='col-3 bold p0' dangerouslySetInnerHTML={{ __html: getOrdinal(data?.fromRank, lang, data?.fromRank < 4 ? specialRanks : false) }} />

                                        <div className='col-3 bold p0' dangerouslySetInnerHTML={{ __html: getOrdinal(data?.toRank, lang, data?.fromRank < 4 ? specialRanks : false) }} />
                                    </>
                                }
                                <div className='col-3 bold p0'>
                                    {data?.prizeValue.toLocaleString()} {translate('web.aed', 'AED')}
                                </div>
                            </>
                        ))}
                    </> :
                    <>
                        {data?.fromRank == data?.toRank ?
                            <div className='col-6 bold p0' dangerouslySetInnerHTML={{ __html: getOrdinal(data?.fromRank, lang, data?.fromRank < 4 ? specialRanks : false) }} />
                            :
                            <>
                                <div className='col-3 bold p0' dangerouslySetInnerHTML={{ __html: getOrdinal(data?.fromRank, lang, data?.fromRank < 4 ? specialRanks : false) }} />
                                <div className='col-3 bold p0' dangerouslySetInnerHTML={{ __html: getOrdinal(data?.toRank, lang, data?.fromRank < 4 ? specialRanks : false) }} />
                            </>
                        }
                        <div className='col-3 bold p0'>
                            {data?.prizeValue.toLocaleString()} {translate('web.aed', 'AED')}
                        </div>
                    </>
                }


            </div>
        </div>
    )
}
