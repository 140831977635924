import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchBankDetailsAPI,
  FetchCitiesAPI,
  FetchCountriesAPI,
  FetchHorseColorsAPI,
} from "./LookUpApi";

export const FetchHorseColorsthunk = createAsyncThunk(
  "LookUp/FetchHorseColors",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchHorseColorsAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCountriesthunk = createAsyncThunk(
  "LookUp/FetchCountries",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCountriesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchBankDetailsthunk = createAsyncThunk(
  "LookUp/FetchBankDetails",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchBankDetailsAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCitiesthunk = createAsyncThunk(
  "LookUp/FetchCities",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCitiesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
