import React, { useState, useEffect } from "react";
import { FaFingerprint } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslate } from "./TranslationText";

export default function SubmitBtn({
  type,
  marginBottom,
  text,
  margin,
  width,
  label,
  nextLink,
  to,
  disabled,
  target,
  ...props
}) {
  const { translate } = useTranslate();

  const { onClick } = props;
  const [isHovered, setIsHovered] = useState(false);
  const redirect_uri = process.env.REACT_APP_UAE_PASS_REDIRECT_LINK;
  const client_id = process.env.REACT_APP_UAE_PASS_CLIENT;
  const authLink = process.env.REACT_APP_UAE_PASS_AUTH_LINK;
  const loginlink = `${authLink}?response_type=code&client_id=${client_id}&scope=urn:uae:digitalid:profile:general&state=UzkLPOzcsHs4SL9cNZ7bFATd&redirect_uri=${redirect_uri}&acr_values=urn:safelayer:tws:policies:authentication:level:low`;
  //console.log(redirect_uri);
  //console.log("authlink is", loginlink);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleButtonClick = () => {
    // Logic to handle button click and redirect to an external URL
    window.location.href = nextLink ? nextLink : loginlink;
  };

  const buttonSubmitStyle = {
    background: isHovered ? "#ffffff" : "#E44D52",
    padding: "10px 20px",
    color: isHovered ? "#E44D52" : "#fff",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: "#E44D52",
    transition: "all 0.3s",
    marginLeft: margin ? margin : 5,
    marginBottom: marginBottom ? marginBottom : 0,
    ...(margin && { margin }), // Apply margin if margin parameter is passed
    ...(width && { width }), // Apply width if width parameter is passed
  };
  const buttonResetStyle = {
    background: isHovered ? "#E44D52" : "#ffffff",
    padding: "10px 20px",
    color: isHovered ? "#fff" : "#E44D52",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: "#E44D52",
    transition: "all 0.3s",
    marginBottom: marginBottom ? marginBottom : 0,
    ...(margin && { margin }), // Apply margin if margin parameter is passed
    ...(width && { width }), // Apply width if width parameter is passed
  };
  const buttonShowMoretStyle = {
    background: isHovered ? "#E44D52" : "#ffffff",
    padding: "10px 20px",
    color: isHovered ? "#fff" : "#E44D52",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: "#E44D52",
    transition: "all 0.3s",
    marginBottom: marginBottom ? marginBottom : 0,
  };
  return (
    <React.Fragment>
      {type === "submit" ? (
        <button
          type="submit"
          className="btn"
          style={buttonSubmitStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          disabled={disabled}
        >
          {text}
        </button>
      ) : null}
      {type === "confirmandpay" ? (
        <button
          type="submit"
          className="btn"
          style={buttonSubmitStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
        >
          {text}
        </button>
      ) : null}
      {type === "enrollNow" ? (
        <button
          type="submit"
          className="btn"
          style={buttonSubmitStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
        >
          {text}
        </button>
      ) : null}
      {type === "startbiding" ? (
        <Link
          className="btn"
          style={buttonSubmitStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          to={`/bidding?auctionHorseId=${to}`}
        >
          {text}
        </Link>
      ) : null}
      {type === "loginuaepass" ? (
        <button
          type="button"
          className="btn"
          style={buttonResetStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleButtonClick}
        >
          <FaFingerprint color="#000" className="mr-10" />
          {text}
        </button>
      ) : null}
      {type === "reset" ? (
        <button
          type="reset"
          className="btn"
          style={buttonResetStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {translate("web.reset", "Reset")}
        </button>
      ) : null}
      {type === "view-details" ? (
        <Link
          to={`/horse-auction?horseid=${to}`}
          className="btn"
          style={buttonResetStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {text}
        </Link>
      ) : null}
      {type === "loadmore" ? (
        <button
          disabled={disabled}
          type="button"
          className="btn"
          style={buttonShowMoretStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
        >
          {!!label ? label : translate("web.showMore", "showMore")}
        </button>
      ) : null}
      {type === "enroll-now" ? (
        <Link
          to={`/auction-bidding?auction-id=${to}`}
          className="btn"
          style={buttonSubmitStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {text}
        </Link>
      ) : null}

      {type === "link" ? (
        <Link
          to={to}
          className="btn"
          style={buttonSubmitStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          disabled={disabled}
        >
          {text}
        </Link>
      ) : null}
      {type === "linkReset" ? (
        <Link
          to={to}
          className="btn"
          style={buttonResetStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          target={target}
        >
          {text}
        </Link>
      ) : null}
    </React.Fragment>
  );
}
