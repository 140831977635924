import React, { useEffect, useState } from 'react'
import { useTranslate } from '../components/TranslationText';
import { useSelector } from 'react-redux';
import axiosUmbraco from '../axios/axiosUmbraco';
import Loader from '../components/Loader';

export default function OrgChartPage() {
    const fetch = process.env.REACT_APP_ORGCHART_ID
    const { translate } = useTranslate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const { currentLanguage } = useSelector(
        (state) => state.studBookSlice
    );
    const [orgChart, setOrgChart] = useState([]);
    const [level1, setlevel1] = useState([]);
    const [level2, setlevel2] = useState([]);
    const [level3, setlevel3] = useState([]);
    const [level2Responsive, setlevel2Responsive] = useState([]);
    const [loaded, setloaded] = useState(false);
    const [total, setTotal] = useState(0);





    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang == "en") return 'en-US';
        else return 'ar-AE'; // Defaulting to English for demonstration
    }
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        async function getdata() {
            const umbraco = await axiosUmbraco.get(`umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=10`, {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(),

                }
            })
            const formattedData = umbraco.data?.items?.map((item) => ({
                organizationalChartChildsList: item?.properties?.organizationalChartChildsList ?? [],
                organizationalChartLevel: item?.properties?.organizationalChartLevel ?? '',
                organizationalChartOrder: item?.properties?.organizationalChartOrder ?? 0,
                organizationalChartTitle: item?.properties?.organizationalChartTitle ?? '',
                organizationalChartSubtitle: item?.properties?.organizationalChartSubtitle ?? '',
            }));;
            setOrgChart(formattedData);
            // console.log('formated data is', formattedData)

            if (umbraco.data.items.length > 0) {
                const level1sortedData = formattedData
                    .filter(level1 => level1.organizationalChartLevel === "Level 1")
                    .sort((a, b) => a.organizationalChartOrder - b.organizationalChartOrder);
                const level2sortedData = formattedData
                    .filter(level2 => level2.organizationalChartLevel === "Level 2")
                    .sort((a, b) => a.organizationalChartOrder - b.organizationalChartOrder);
                const level3sortedData = formattedData
                    .filter(level3 => level3.organizationalChartLevel === "Level 3")
                    .sort((a, b) => a.organizationalChartOrder - b.organizationalChartOrder);
                setlevel1(level1sortedData);
                setlevel2(level2sortedData);
                setlevel3(level3sortedData);
                // const [first, second] = level2sortedData;
                // const responsivelevel2sortedData = [first, second];
                setlevel2Responsive(level2sortedData);
                setloaded(true);
            }
            setTotal(umbraco.data.total)
        }
        getdata();

    }, [currentLanguage])
    return (
        <>
            {total < 1 ? <Loader /> :
                windowWidth > 970 &&
                <div className='container-fluid px75'>
                    <div className='OrgChartContainer'>
                        <div className='OrgChartHeader'>
                            {/* first Card */}
                            {level1.map((item, index) => (
                                <div className='OrgChartHeaderCardContainer' style={{ position: index == (level1.length - 1) ? 'relative' : 'static' }}>
                                    <div className={index == 0 ? 'OrgChartHeaderCard isFirstOrgChartCard' : 'OrgChartHeaderCard'}>
                                        <span>{item.organizationalChartTitle}</span>
                                        <p>
                                            {item.organizationalChartSubtitle}
                                        </p>
                                    </div>
                                    {index == (level1.length - 1) ?
                                        <>
                                            <div className='OrgChartHeaderCardSpacer hidetheSpacer' />
                                            <div className='OrgChartHeaderCardSpacer lastSpacer' />
                                        </>
                                        : <div className='OrgChartHeaderCardSpacer' />
                                    }

                                </div>
                            ))}
                        </div>
                        <div className='OrgChartLevelTwoContainer MultiItemInRow'>
                            <div className='OrgChartHeaderCardContainer'>
                                {level2.map((item, index) => (
                                    <>
                                        {index <= 2 &&
                                            <>
                                                <div className='OrgChartHeaderCard '>
                                                    <span>{item.organizationalChartTitle}</span>
                                                    <p>
                                                        {item.organizationalChartSubtitle}
                                                    </p>

                                                </div>
                                                {index < 1 &&
                                                    <div className='OrgChartHorizentalHeaderCardSpacer' />
                                                }


                                            </>


                                        }
                                    </>

                                ))}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: 165 }}>
                                <div className='OrgChartHeaderCardSpacer hidetheSpacer' />
                                <div className='OrgChartHeaderCardSpacer hidetheSpacer' />
                            </div>
                        </div>
                        <div className='OrgChartSectionSpacer' />
                        <div className='OrgChartLevelTwoContainer'>
                            {level3.map((item, index) => (
                                <>
                                    {index <= 3 &&
                                        <div className='OrgChartHeaderCardContainer'>
                                            <div className='OrgChartHeaderCardSpacer' />
                                            <div className='OrgChartHeaderCard '>
                                                <span>{item.organizationalChartTitle}</span>
                                                <p>
                                                    {item.organizationalChartSubtitle}
                                                </p>
                                            </div>
                                            {item.organizationalChartChildsList.map((child) => (

                                                <div className='ClusterContainer' >
                                                    <div className='OrgChartHeaderCard '>
                                                        <span>{child}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    }
                                </>

                            ))}
                        </div>
                    </div>
                </div>
            }
            {windowWidth <= 970 &&
                <>
                    <div className='container-fluid px75'>
                        <div className='OrgChartContainer'>
                            <div className='OrgChartHeader'>
                                {/* first Card */}
                                {level1.map((item, index) => (
                                    <div className='OrgChartHeaderCardContainer Responsive'>
                                        <div className={index == 0 ? 'OrgChartHeaderCard isFirstOrgChartCard' : 'OrgChartHeaderCard'}>
                                            <span>{item.organizationalChartTitle}</span>
                                            <p>
                                                {item.organizationalChartSubtitle}
                                            </p>
                                        </div>
                                        {index < level1.length - 1 ?
                                            <div className='OrgChartHeaderCardSpacer'>
                                            </div> : <br />
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className='OrgChartLevelTwoContainer OrgChartLevelTwoContainerResponsive NewLevel2ResponsiveContainer'>
                                <div className='OrgChartSectionSpacerResponsive' />
                                {level2Responsive.map((item, index) => (
                                    <>
                                        {index <= 2 &&
                                            <div className='OrgChartHeaderCardContainer Responsive OrgChartHeaderCardContainerResponsiveLevel2 NewLevel2Responsive'>
                                                <div className='orgChartHorizentalSpacerResponsive' />
                                                <div className='OrgChartHeaderCard '>
                                                    <span>{item.organizationalChartTitle}</span>
                                                    <p>
                                                        {item.organizationalChartSubtitle}
                                                    </p>
                                                </div>
                                            </div>
                                        }

                                    </>

                                ))}
                            </div>
                            <div className='OrgChartLevelTwoContainer OrgChartLevelTwoContainerResponsive'>
                                <div className='OrgChartSectionSpacerResponsive' />
                                {level3.map((item, index) => (
                                    <>
                                        {index <= 3 &&
                                            <div className='OrgChartHeaderCardContainer Responsive OrgChartHeaderCardContainerResponsiveLevel2'>
                                                <div className='orgChartHorizentalSpacerResponsive' />
                                                <div className='OrgChartHeaderCard '>
                                                    <span>{item.organizationalChartTitle}</span>
                                                    <p>
                                                        {item.organizationalChartSubtitle}
                                                    </p>
                                                </div>
                                                {item.organizationalChartChildsList.map((child) => (

                                                    <div className='ClusterContainer Responsive' >
                                                        <div className='OrgChartHeaderCard '>
                                                            <span>{child}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                                <br />
                                            </div>
                                        }

                                    </>

                                ))}
                            </div>
                        </div>
                    </div>

                </>

            }
        </>
    )
}
