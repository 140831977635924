import React, { useEffect, useMemo, useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { useTranslate } from "./TranslationText";
import { GuidGenerator } from "../helper/GuidGenerator";

const ShowPrizeDynamicTable = ({ stageList, rows, setRows, showsCategoriesRows, champoinsRows, isEdit }) => {
    const { translate } = useTranslate();

    const champoinNameList = champoinsRows.filter((champ) => !!champ.col1).map((row) => ({
        name: row.col1,
        text: row.col1,
        value: row.col1
    }))
    const showsCategoriesList = showsCategoriesRows.filter((champ) => !!champ.col1).map((row) => ({
        name: row.col1,
        text: row.col1,
        value: row.col1
    }));
    const [selectedCategories, setSelectedCategories] = useState([])

    const filterOptions = () => {
        const options = rows.map((row, index) => {
            const sourceItems = row.col1 == 'Classes' ? showsCategoriesList : champoinNameList;
            const currentOptions = selectedCategories[index]?.filter(opt => sourceItems.some(s => s.value === opt.value));
            return currentOptions;
        });
        return options;
    }

    useEffect(()=> {
        setSelectedCategories(filterOptions());
    },[])
    console.debug('showsCategoriesRows', showsCategoriesRows)

    useEffect(() => {
        const options = filterOptions();
        setSelectedCategories(options)
    }, [champoinsRows, showsCategoriesRows]);

    // Function to handle input change
    const handleInputChange = (index, e, isDropDown = false, inputName) => {
        var name;
        var value;
        var label;
        if (isDropDown) {
            name = inputName;
            label = inputName;
            value = e.map((x) => (x.value));

            const newSelectedCategories = [...selectedCategories.slice(0, index), e, ...selectedCategories.slice(index + 1)];
            console.debug('newSelectedCategories', newSelectedCategories)
            setSelectedCategories(newSelectedCategories);
        } else {
            name = e.target.name;
            label = e.target.name;
            value = e.target.value;
        }
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    };

    // Function to add a new row
    const addRow = (e) => {
        setRows([...rows, { col1: "", col2: "", col3: "", col4: "", col5: "", customGuid: GuidGenerator(), isPristine: true }]);
    };

    // Function to delete a row
    const deleteRow = (index) => {
        const updatedRows = rows.filter((row, i) => i !== index);
        setRows(updatedRows);
    };

    const lang = localStorage.getItem('eahsLang') ?? 'en';

    return (
        <div style={{ borderRadius: 5, border: '1px solid red', padding: 10 }}>
            <table border="1" style={{ width: "100%", textAlign: "center", border: 0, borderCollapse: "separate", borderSpacing: "0 10px", }} >
                <thead>
                    <tr>
                        <th>{translate('web.stage', 'Stage')}</th>
                        <th>{translate('web.categoriesOrChampionship', 'Categories / Championship')}</th>
                        <th>{translate('web.fromRank', 'From Rank')}</th>
                        <th>{translate('web.toRank', 'To Rank')}</th>
                        <th>{translate('web.prizeValueAED', 'Prize Value (AED)')} </th>
                        <th>{translate('web.action', 'Action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td className="px-1">
                                <NetaqInput
                                    type={'selectislam'}
                                    Options={stageList}
                                    label={translate('web.stage', 'Stage')}
                                    hideLable={true} required={true} name="col1"
                                    value={row.col1}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.stage', 'Stage')}
                                />
                            </td>
                            <td className="px-1">
                                {!isEdit || !!row.isPristine || (Array.isArray(row.col2) && row.col2.length > 0) ? <NetaqInput
                                    type={'select'}
                                    listSelect={row.col1 == 'Classes' ? showsCategoriesList : champoinNameList}
                                    label={row.col1 == 'Classes' ? translate('web.categories', 'Categories') : translate('web.championships', 'Championships')}
                                    hideLable={true}
                                    required={true}
                                    defaultValue={!isEdit || !!row.isPristine ? [] : row.col2.map(cat => { return { label: lang == 'en' ? cat : showsCategoriesRows.find(catObj => catObj.col1 === cat).col2, text: cat, value: cat } })}
                                    name="col2"
                                    isMulti={true}
                                    value={selectedCategories[index]}
                                    onChange={(e) => handleInputChange(index, e, true, 'col2')}
                                    placeholder={row.col1 == 'Classes' ? translate('web.categories', 'Categories') : translate('web.championships', 'Championships')}
                                /> : <pre>{row.col2}</pre>
                                } </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="number"
                                    name="col3"
                                    value={row.col3}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.fromRank', 'From Rank')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="number"
                                    name="col4"
                                    value={row.col4}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.toRank', 'To Rank')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="number"
                                    name="col5"
                                    value={row.col5}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.prizeValueAED', 'Prize Value (AED)')}
                                    required={true}
                                />
                            </td>

                            <td>
                                <ActionButton onClick={() => deleteRow(index)} className="mx-auto" text={'<i class="fa-solid fa-trash"></i>'} type={'normalbtn'} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ActionButton onClick={(e) => addRow(e)} className="mt-3" text={translate('web.addItem', 'Add Item')} type={'normalbtn'} />
        </div>
    );
};

export default ShowPrizeDynamicTable;
