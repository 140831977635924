export function getThumbnailUrlFromEmbedLink(embedLink) {
  let videoId;

  if (embedLink.includes("youtube.com/embed/")) {
      // Extract the video ID from the embed URL
      const urlParts = embedLink.split("/");
      videoId = urlParts[urlParts.length - 1];
  } else if (embedLink.includes("youtube.com/watch?v=")) {
      // Extract the video ID from the watch URL
      const urlParams = new URLSearchParams(new URL(embedLink).search);
      videoId = urlParams.get("v");
  } else {
      return embedLink; // Not a YouTube link
  }

  // Construct the thumbnail URL using the video ID
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`; // Adjust for desired size

  return thumbnailUrl;
}


  export function convertYouTubeURL(url) {
    // Use a regular expression to extract the value of the 'v' parameter
    const regex = /[?&]v=([^&#]*)/;
    const match = url.match(regex);
    
    if (match && match[1]) {
        const videoId = match[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}`;
        return embedUrl;
    } else {
        return url;
    }
}