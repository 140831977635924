export function formatDate(str, isDash = false) {
  const date = new Date(str)
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  if (isDash) {
    return `${day}-${month}-${year}`;
  } else {
    return `${day}/${month}/${year}`;
  }
}
