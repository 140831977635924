import React, { useEffect, useState } from "react";
import ImagesSlider from "../components/ImagesSlider";
import CollapseLabel from "../components/CollapseLabel";
import PedigreeChart from "../components/PedigreeChart";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHorseAuctionData,
  resetHorseAuctionData,
} from "../Redux/HorseAuctionData/HorseAuctionDataSlice";
import { useTranslate } from "../components/TranslationText";
import { convertToCamelCase } from "../helper/convertToCamelCase";
import { FetchStudBookHorsePedigreeByIdthunk } from "../Redux/StudBook/StudBookAction";
import HorseDetailsPedigreeChart from "../components/HorseDetailsPedigreeChart";

export default function HorseProfile() {
  const { translate } = useTranslate();
  const { studBookPedigreeData } = useSelector((state) => state.studBookSlice);

  // get hourse data from backend
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const [horse, setHorse] = useState(horseData);
  const horse = useSelector((state) => state.horseauctiondata.horseData);

  const aboutArray = Object.entries(horse.about);
  const horseAttachment = horse.horseAttachment;

  const currentHorseId = searchParams.has("horseid")
    ? searchParams.get("horseid")
    : null;
  const currentHorsepedgreeId = searchParams.has("pidgreeid")
    ? searchParams.get("pidgreeid")
    : null;
  const currentAuctionId = searchParams.has("auctionid")
    ? searchParams.get("auctionid")
    : null;

  const relatedUrls = searchParams.has("relatedUrls")
    ? searchParams.get("relatedUrls")
    : null;
  const dispatch = useDispatch();
  const currentLanguage = localStorage.getItem('eahsLang') ?? 'en';

  useEffect(() => {
    async function getHorseData() {
      dispatch(resetHorseAuctionData());
      dispatch(fetchHorseAuctionData(currentHorseId));
    }
    getHorseData();
  }, [dispatch, currentHorseId]);

  useEffect(() => {
    let paramsHorsePedigree = {
      id: currentHorsepedgreeId,
      langauge: currentLanguage,
    };
    dispatch(FetchStudBookHorsePedigreeByIdthunk(paramsHorsePedigree));
  }, [currentLanguage, currentHorsepedgreeId]);
  return (
    <React.Fragment>
      {!!horse && (
        <div className="container-fluid" style={{ padding: "0px 50px" }}>
          <div className="row" style={{ margin: 0 }}>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
              {relatedUrls !== "null" && relatedUrls != null ? (
                <ImagesSlider
                  data={horseAttachment}
                  sliderName={"horseprofile"}
                  type={"horseprofile"}
                  auctionid={currentAuctionId}
                  horseName={horse.about.Name}
                  HorseLotNumber={horse.about["lot No"]}
                  VideoURL={relatedUrls}
                />
              ) : (
                <ImagesSlider
                  data={horseAttachment}
                  sliderName={"horseprofile"}
                  type={"horseprofile"}
                  auctionid={currentAuctionId}
                  horseName={horse.about.Name}
                  horseLotNumber={horse.about["lot No"]}
                />
              )}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <CollapseLabel name={translate("web.aboutHorse", "About Horse")}>
                {
                  <div className="row right-to-left" style={{ margin: 0 }}>
                    {aboutArray.map(([key, value]) => (
                      <React.Fragment>
                        <div
                          className="col-lg-2 col-md-3 col-sm-4"
                          style={{ paddingLeft: 0, marginBottom: 15 }}
                        >
                          <span style={{ display: "block", color: "#777777" }}>
                            {translate(`web.${convertToCamelCase(key)}`, key)}
                          </span>
                          <span
                            style={{ display: "block", fontWeight: "bold" }}
                          >
                            {value}
                          </span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                }
              </CollapseLabel>
              <CollapseLabel name={translate("web.pedigree", "Pedigree")}>
                {!!studBookPedigreeData && (
                  <HorseDetailsPedigreeChart hierarchy={studBookPedigreeData} />
                )}
              </CollapseLabel>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
