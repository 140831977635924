import React, { useState } from 'react'
import Lightbox from 'react-18-image-lightbox';
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function HorseGallery({ data }) {
    const [images, setImages] = useState(reformatImages(data));
    const [imageslightbox, setImageslightbox] = useState(reformatImages(data));

    function reformatImages(images){
        return (
            images.map((image, index) => ({
                src: MediaBaseURL+image.url,
                width: image.width,
                height: image.height,
                isvideo: image.mediaType == "Image" ? false : true,
                tags: image.isvideo ? [
                    { value: <i className="fa-solid fa-play" style={{ fontSize: 18 }}></i> },
                ] : [],
            }))
        );
    }

    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    function getVideoFrame(index) {
        return (<iframe width="90%" height="90%" src={imageslightbox[index].src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>);
    }


    return (
        <div className='row rtl align-items-center'>
            {data.map((item,index) => (
                item.mediaType == "umbracoMediaVideo" ?
                    < div className='col-lg-4 col-md-6 col-sm-12 mt-1' >
                        <video src={`${MediaBaseURL}${item.url}`} height={275} controls style={{cursor:'pointer'}} />
                    </div>
                    :
                    < div className='col-lg-4 col-md-6 col-sm-12 mt-1' >
                        <img src={`${MediaBaseURL}${item.url}`} height={275} onClick={()=>handleClick(index)} style={{cursor:'pointer'}} />
                    </div>
            ))
            }
            {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={imageslightbox[index].src}
                  
                    onCloseRequest={handleClose}
                  
                    wrapperClassName='galleryContainer'
                    imageLoadErrorMessage={getVideoFrame(index)}
                />
            )}
        </div >
    )
}
