import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { emptyPageShows, incrementPageShows } from "../Redux/Shows/ShowsSlice";
import { FetchShowsthunk } from "../Redux/Shows/ShowsAction";
import { useTranslate } from "../components/TranslationText";
import SubmitBtn from "../components/SubmitBtn";
import Loader from "../components/Loader";
import ShowsFilter from "../components/ShowsFilter";
import { convertToCamelCase } from "../helper/convertToCamelCase";
import ComingSoon from "../components/ComingSoon";
import FilterButton from "../components/FilterButton";
import { formatDate } from "../helper/formatDate";
import ShowCard from "../components/ShowCard";

export default function Shows() {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const [showFilter, setShowFilter] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();

  const {
    showsData,
    pageShows,
    takeShows,
    totalCountShows,
    stateShowMoreButtonHorse,
    showsLoading,
  } = useSelector((state) => state.showsSlice);

  const [filterCriteria, setFilterCriteria] = useState({
    search: null,
    year: "",
    month: "",
    country: null,
    showStatus: [],
  });

  useEffect(() => {
    if (pageShows === 0) {
      dispatch(emptyPageShows());
      return;
    }

    let params = {
      skip: (pageShows - 1) * takeShows,
      take: takeShows,
      langauge: currentLanguage,
      showName: filterCriteria.search,
      year: filterCriteria.year === "" ? null : filterCriteria.year,
      month: filterCriteria.month === "" ? null : filterCriteria.month,
      emirateId: filterCriteria.country,
      showStatus: filterCriteria.showStatus,
    };
    dispatch(FetchShowsthunk(params));
  }, [pageShows, filterCriteria, currentLanguage]);

  const updateShowsList = (newFilters) => {
    dispatch(emptyPageShows());
    setFilterCriteria(newFilters);
  };

  const handelShowMore = async () => {
    dispatch(incrementPageShows());
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${showsLoading ? "classdisplay" : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      <div className="row rtl">
        {windowWidth <= 970 && (
          <div className="row m0 text-center justify-content-center">
            <div className="col-md-2 col-sm-3 text-center">
              <FilterButton onClick={toggleFilter} />
            </div>
          </div>
        )}
        {
          // Shows Filter div
        }
        <div
          className="col-lg-3 col-md-12 col-sm-12  floatLeftArabic768"
          style={{
            display:
              windowWidth >= 970 ? "block" : showFilter ? "block" : "none",
          }}
        >
          <ShowsFilter onSubmit={updateShowsList} />
        </div>

        {!!showsData && showsData.length > 0 ? (
          <React.Fragment>
            <div className={"col-md-9 col-sm-12 p0 justRotate"}>
              {/* <div className="row m0responsive"> */}
              <div className="showNewFlexRow justRotate">
                {showsData.map((item, i) => (
                  <ShowCard key={i} i={i} item={item} />
                ))}
              </div>
            </div>

            <div className="col-md-3 col-sm-12"></div>
            {
              // Show More div
            }
            <div className="col-lg-9 col-md-12 col-sm-12 text-center">
              {!showsLoading && !!showsData && showsData.length > 0 ? (
                <SubmitBtn
                  marginBottom={50}
                  type={"loadmore"}
                  disabled={stateShowMoreButtonHorse}
                  onClick={handelShowMore}
                  label={`
            ${translate("web.showMore", "Show More")} (${showsData.length
                    } ${translate("web.of", "Of")} ${totalCountShows})`}
                />
              ) : (
                ""
              )}
              {showsLoading && <Loader />}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="col-md-9 col-sm-12  text-danger text-center">
              <h2>{translate("web.nodataFound", "No Data Found")}</h2>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
    // <ComingSoon />
  );
}
