import React, { useEffect } from 'react'
import { useTranslate } from "./TranslationText";

export default function ComingSoon({
    text = 'web.comingSoon',
    defaultText = "Coming Soon",
}) {
    const { translate } = useTranslate();

    const isnotfoundPage = window.location.pathname == "/404-not-found" ? true : false;

    return (
        <>
            <div className='section_title rtl comingSoon'>
                <h2 style={{ textAlign: 'center' }} >
                    {isnotfoundPage ? translate('web.notFoundPageMessage', "Oops! The page you are looking for doesn't exist. Please check the URL or return to the homepage") : translate(text, defaultText)}
                    {!isnotfoundPage &&
                        <span className='comingSoonAnimationContainer'>
                            <span className='anmationdot1'>.</span>
                        </span>
                    }
                </h2>
            </div>
            {/* <div style={{display:'flex', alignItems:'center' , justifyContent:"center"}}>

                <img src='./assets/img/notFound.png' style={{ width: '80%', maxWidth: 600, alignSelf: 'center', margin: '10px auto' }} />
            </div> */}
        </>
    )
}
