import React, { useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { useTranslate } from "./TranslationText";
import { convertToCamelCase } from "../helper/convertToCamelCase";

const EducationalFilter = ({ onSubmit, categoriesList }) => {
  const [checker, setChecker] = useState(true);
  const { translate } = useTranslate();
  const [dateStartFrom, setDateStartFrom] = useState(null);
  const [dateStartTo, setDateStartTo] = useState(null);

  const [filterState, setFilterState] = useState({
    // Initial filter values
    coursTitle: "",
    coursecategory: "both",
    DateFrom: "",
    DateTo: "",
  });


  const handleChange = (event, input) => {
    if (!!input && input === "DateFrom") {
      setDateStartFrom(event)
      setFilterState({
        ...filterState,
        DateFrom: event.toDate(),
      });
      return;
    }
    if (!!input && input === "DateTo") {
      setDateStartTo(event)
      setFilterState({
        ...filterState,
        DateTo: event.toDate(),
      });
      return;
    }
    const value = event.target.type == 'checkbox' ? event.target.checked : event.target.value
    setFilterState({
      ...filterState,
      [event.target.name]: value,
    });


  };

  const handleSubmit = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    onSubmit(filterState); // Pass filter state to News
  };
  const handleReset = (event) => {
    window.scrollTo(0, 0);

    setDateStartFrom(null)
    setDateStartTo(null)

    setFilterState({
      coursTitle: "",
      coursecategory: "all",
      DateFrom: "",
      DateTo: "",
    });
  };



  return (
    <form
      className="AuctionFilter rtl"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <h2>{translate('web.simplesearch', 'Search')}</h2>
      <NetaqInput
        name={'coursTitle'}
        label={translate("web.courseTitle", "Course Title")}
        key={"search"}
        placeholder={translate("web.courseTitle", "Course Title")}
        type={"text"}
        onChange={handleChange}
      />
      <label className="mb-10">{translate("web.courseCategory", "Course Category")}</label>
      <div className="AuctionFilterRadioContainer">
        {categoriesList.map((cat) => (
          <NetaqInput
            name={"coursecategory"}
            key={"health"}
            type={"radio"}
            value={cat}
            label={translate(`web.${convertToCamelCase(cat)}`, cat)}
            onChange={handleChange}
          />
        ))}

        <NetaqInput
          name={"coursecategory"}
          key={"all"}
          type={"radio"}
          value={"all"}
          checked={checker}
          label={translate("web.all", "All")}
          onChange={handleChange}
        />
      </div>


      <label className="">{translate("web.date", "Date")}</label>
      <div className="AuctionFilterNumberofhoursesContainer">
        <NetaqInput
          name={"DateFrom"}
          key={"DateFrom"}
          type={"date"}
          label={translate("web.from", "From")}
          onChange={handleChange}
          value={dateStartFrom}
        />
        <NetaqInput
          name={"DateTo"}
          key={"DateTo"}
          type={"date"}
          label={translate("web.to", "To")}
          onChange={handleChange}
          value={dateStartTo}
          minDate={dateStartFrom}
        />
      </div>
      <div className="AuctionFilterNumberofhoursesContainer mt-3">
        <ActionButton key={"reset"} type={"reset"} />
        <ActionButton text={translate("web.search", "Search")} key={"apply"} type={"submit"} />
      </div>
    </form>
  );
};

export default EducationalFilter;
