import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "../Reducers/RootReducer";
import { thunk } from "redux-thunk";

const store = configureStore({
  reducer: RootReducer,
  // Add middleware, devtools, etc. here
});

export default store;
