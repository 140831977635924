import React, { useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { useTranslate } from "./TranslationText";
import { GuidGenerator } from "../helper/GuidGenerator";

const ChampionsDynamicTable = ({rows,setRows,genderList,rankList}) => {
    const {translate} = useTranslate();

    // Function to handle input change
    const handleInputChange = (index, e, isDropDown = false) => {
        var name;
        var value;
        var label;
        if (isDropDown) {
            name = e.label;
            label = e.label;
            value = e.value;

        }
        else {
            name = e.target.name;
            label = e.target.name;
            value = e.target.value;
        }
        const updatedRows = [...rows];
        updatedRows[index][name] = value;
        setRows(updatedRows);
    };

    // Function to add a new row
    const addRow = (e) => {
        setRows([...rows, { col1: "", col2: "", col3: "", col4: ""  , customGuid:GuidGenerator()}]);
    };

    // Function to delete a row
    const deleteRow = (index) => {
        const updatedRows = rows.filter((row, i) => i !== index);
        setRows(updatedRows);
    };

    return (
        <div style={{borderRadius:5, border:'1px solid red' , padding:10}}>
            <table border="1" style={{ width: "100%", textAlign: "center", border: 0, borderCollapse: "separate", borderSpacing: "0 10px", }} >
                <thead>
                    <tr>
                        <th>{translate('web.championshipName','Championship Name')}</th>
                        <th>{translate('web.championshipNameArabic' ,'Championship Name Arabic')}</th>
                        <th>{translate('web.gender' ,'Gender')}</th>
                        <th>{translate('web.showrank')}</th>
                        <th>{translate('web.action','Action')}</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr key={index}>
                            <td className="px-1">
                                <NetaqInput
                                    type="text"
                                    hideLable={true}
                                    name="col1"
                                    value={row.col1}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.championshipName','Championship Name')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput
                                    hideLable={true}
                                    type="text"
                                    name="col2"
                                    value={row.col2}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.championshipNameArabic' ,'Championship Name Arabic')}
                                    required={true}
                                />
                            </td>
                            <td className="px-1">
                                <NetaqInput type={'selectislam'}
                                    Options={genderList} label={translate('web.gender' ,'Gender')}
                                    hideLable={true} required={true} name="col3"
                                    value={row.col3}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.gender' ,'Gender')} />

                            </td>
                            <td className="px-1">
                                <NetaqInput type={'selectislam'}
                                    Options={[{name:'1', text:'1' , value:1}, {name:'2', text:'2' , value:2} , {name:'3', text:'3' , value:3} ]}
                                    label={translate('web.showrank')}
                                    hideLable={true} required={true} name="col4"
                                    value={row.col4}
                                    onChange={(e) => handleInputChange(index, e)}
                                    placeholder={translate('web.showrank')} />
                            </td>
                            <td>
                                <ActionButton onClick={() => deleteRow(index)} className="mx-auto" text={'<i class="fa-solid fa-trash"></i>'} type={'normalbtn'} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ActionButton onClick={(e) => addRow(e)} className="mt-3" text={translate('web.addItem','Add Item')} type={'normalbtn'} />
        </div>
    );
};

export default ChampionsDynamicTable;
