export function CompareDate(firstDate, secondDate) {
  const startDate = new Date(firstDate);
  const endDate = new Date(secondDate);
  const now = new Date();

  // Set the time part to 00:00:00 for date comparison only
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  now.setHours(0, 0, 0, 0);

  if (now >= startDate && now <= endDate) {
    return true;
  } else {
    return false;
  }
}
