import React from 'react'
import { formatDate } from '../helper/formatDate';
import { CompareDate } from '../helper/CompareDate';
import { Link } from 'react-router-dom';

export default function AuctionEntry({setSearchAuction, translate, auctionEntryData, searchAuction, currentLanguage}) {
    return <div className="col-lg-9 col-md-8 col-sm-12 row m0 rtl">
    <div className="col-lg-12 col-sm-12">
      <div className="form-group ownerProfileInput">
        <i className="fas fa-search fa-xl iconformshowentrycard"></i>
        <input
          type="text"
          className="form-control height30hidden zindex0"
          onChange={(e) => {
            setSearchAuction(e.target.value);
          } }
          placeholder={translate(
            "web.searchAuction",
            "Search By Auction Name"
          )} />
      </div>

      {!!auctionEntryData && auctionEntryData.length > 0 ? (
        auctionEntryData
          .filter((item) => {
            if (searchAuction === "") {
              return item;
            } else if (searchAuction !== "") {
              return (
                item.nameAr
                  .toLowerCase()
                  .includes(searchAuction.toLowerCase()) ||
                item.nameEn
                  .toLowerCase()
                  .includes(searchAuction.toLowerCase())
              );
            }
          })
          .map((item, i) => (
            <div
              className="col-lg-6 col-md-6 col-sm-12  show-entry-first-div"
              key={i}
            >
              <div
                className="horseCardContainer row mx-1"
                style={{ display: "flex" }}
              >
                <div className="col-lg-4 col-md-12 col-sm-12 horse-market-card-div horseMarketImageContainer  showsImageContainer ">
                  <img src={item.attachment} alt="logo" />
                </div>

                <div className="col-lg-8 col-md-8 col-sm-12 p0">
                  <div className="col-md-12 col-sm-12 titleShowSchedule p0">
                    <div className="col-md-12 col-sm-12 p0">
                      <span className="horseMarketCardLabeltitle">
                        {currentLanguage === "en"
                          ? item.nameEn
                          : item.nameAr}
                      </span>
                    </div>
                  </div>

                  <div className="row justRotate ">
                    <div className="col-md-12">
                      <div className="justRotate start-date-showlist ">
                        <div className="col-md-12 background-color-orange px-2 start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar "></i>
                            {translate(
                              "web.startDate",
                              "Start Date"
                            )}
                            :
                          </div>
                          <div>
                            {item.startDate &&
                              formatDate(item.startDate)}
                          </div>
                        </div>
                      </div>

                      <div className="justRotate end-date-showlist">
                        <div className="col-md-12 start-end-date-display-ownerprofile px-2">
                          <div>
                            <i className="fa fa-calendar"></i>
                            {translate("web.endDate", "End Date")}:
                          </div>
                          <div>
                            {item.endDate &&
                              formatDate(item.endDate)}
                          </div>
                        </div>
                      </div>

                      <div className="justRotate start-date-showlist ">
                        <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile px-2">
                          <div>
                            <i className="fa fa-calendar "></i>
                            {translate(
                              "web.participationStartDate",
                              "Participation Start Date"
                            )}
                            :
                          </div>
                          <div>
                            {item.participationStartDate &&
                              formatDate(
                                item.participationStartDate
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="justRotate end-date-showlist">
                        <div className="col-md-12 start-end-date-display-ownerprofile px-2">
                          <div>
                            <i className="fa fa-calendar"></i>
                            {translate(
                              "web.participationEndDate",
                              "Participation End Date"
                            )}
                            :
                          </div>
                          <div>
                            {item.participationEndDate &&
                              formatDate(item.participationEndDate)}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-10 cardValueColor justRotate mt-11wahoconference">
                          {translate(
                            "web.maxNoPerOwner",
                            "Max no of horses to register per owner"
                          )}
                          :
                        </div>
                        <div className="col-sm-2 cardKeyColor justRotate mt-11wahoconference">
                          {item.maxNumberPerOwner}
                        </div>
                      </div>
                      <span className="horseCardLabeltitle justRotate mt-11wahoconference textalignend">
                        {item.horseFees}{" "}
                        {currentLanguage === "en" ? "AED" : "د.إ"}
                      </span>
                    </div>
                  </div>

                  <div className="textalignend">
                    <Link
                      className={`btn button-saveasdraft ${CompareDate(
                        item.participationStartDate,
                        item.participationEndDate
                      )
                        ? "newbtn"
                        : "btn-danger"}`}
                      disabled={CompareDate(
                        item.participationStartDate,
                        item.participationEndDate
                      )
                        ? false
                        : true}
                      style={CompareDate(
                        item.participationStartDate,
                        item.participationEndDate
                      )
                        ? { opacity: 1, cursor: "pointer" }
                        : { opacity: 0.5, cursor: "not-allowed" }}
                      onClick={(event) => {
                        if (!CompareDate(
                          item.participationStartDate,
                          item.participationEndDate
                        )) {
                          event.preventDefault();
                        }
                      } }
                      to={`/auctionentrycard?horseFees=${item.horseFees}&maxHorses=${parseInt(
                        item.maxNumberPerOwner
                      )}&auctionNameAr=${item.nameAr}&auctionNameEn=${item.nameEn}&auctionId=${item.id}`}
                    >
                      {item.checkRequests
                        ? translate("web.continue", "Continue")
                        : translate(
                          "web.participateNow",
                          "Participate Now"
                        )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))
      ) : (
        <div>
          {0 + " " + translate("web.resultsfound", "Results Found")}
        </div>
      )}
    </div>
  </div>;
}
