import React, { useEffect, useState } from 'react'
import { useTranslate } from '../components/TranslationText'
import Popup from "reactjs-popup";
import axios from "../axios/axios";
import { ToastContainer, toast } from "react-toastify";
import SiteMap from '../components/SiteMap';
import { useLocation, useNavigate } from 'react-router';
import { getTimeStatus } from '../helper/timeUtils';
import ImagesSlider from '../components/ImagesSlider';
import CronometerCounter from '../components/CronometerCounter';
import { limit, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { bidsCollectionRef } from '../firebase/collections';
import { Link } from 'react-router-dom';
import ActionButton from '../components/ActionButton';
import Loader from '../components/Loader';
import { convertToCamelCase } from '../helper/convertToCamelCase';
import { formateDateToMac } from '../helper/convertDateToISO';

const BaseURL = process.env.REACT_APP_BASE_URL;

export default function HybridBiddingPage() {
    const navigate = useNavigate();
    const { translate } = useTranslate();
    const location = useLocation();
    const [horseEntryTime, setHorseEntryTime] = useState(new Date());
    // const pageLoadTime = Timestamp.now();
    const pageLoadTime = new Date();

    const searchParams = new URLSearchParams(location.search);
    const auctionId = searchParams.has("auctionID")
        ? searchParams.get("auctionID")
        : "";
    const [enrollButtonText, setEnrollButtonText] = useState(
        translate("web.enrollNow", "Enroll Now")
    );



    const user = localStorage.getItem('eahsUserProfile') ?? '';
    const userProfile = !!user ?
        JSON.parse(localStorage.getItem("eahsUserProfile")) : '';

    const [userProfileAuction, setUserProfileAuction] = useState({
        "Full Name": "",
        Nationality: "",
        Address: "",
        "Phone Number": "",
        "Emirates ID": "",
    });

    const currentLanguage = localStorage.getItem('eahsLang') ?? 'en';

    const [auctionDataLoaded, setAuctionDataLoaded] = useState(false);
    const [biddersDataLoaded, setBiddersDataLoaded] = useState(false);
    const [auctionData, setAuctionData] = useState({});
    const [biddersData, setBiddersData] = useState([]);
    const [horseData, setHorseData] = useState([]);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isBidForOthers, setIsBidForOthers] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");


    // bidding information
    const [isEnrolledUser, setIsEnrolledUser] = useState(false);
    const [bidderNumber, setBidderNumber] = useState(0);
    const [bidderId, setBidderId] = useState('');
    const [newBid, setNewBid] = useState(0);
    const [UIincrementalIncrease, setIncrementalIncrease] = useState(0);
    let incrementalIncrease = 0;

    // get auction data 
    async function getAuctionData(id = auctionId) {
        try {
            const res = await axios.get(`/auction/getauctionwithrunninghorse?Id=${id}`);
            if (res.data.status.responseCode == "200") {
                const auctionFormatedData = {
                    name: res.data.result.name,
                    startDate: res.data.result.startDate,
                    endDate: res.data.result.endDate,
                    liveUrl: res.data.result.liveUrl ?? '',
                    introVideoUrl: res.data.result.introVideoUrl ?? '',
                    fees: res.data.result.auctionFees,
                    termsAndConditions: res.data.result.termsAndConditionsTextEn,
                    termsAndConditionsAr: res.data.result.termsAndConditionsTextAr,
                    isEnrollIngOpen: res?.data?.result?.isEnrollOpened ?? false
                }
                setAuctionData(auctionFormatedData);
                const inArenaHorses = res.data.result.auctionSelectedHorses.filter((horse) => horse.isHorseInArena == true);
                setHorseData(inArenaHorses);
                if (inArenaHorses.length > 0) {
                    getBiddersData(id, inArenaHorses[0].horseId, inArenaHorses[0].incrementalIncrease, inArenaHorses[0].startingPrice);
                    setIncrementalIncrease(inArenaHorses[0].incrementalIncrease);
                    incrementalIncrease = inArenaHorses[0].incrementalIncrease;
                    setHorseEntryTime(new Date(inArenaHorses[0].horseDateInArena));
                }
                setAuctionDataLoaded(true);
            }
        } catch (error) {
            console.debug('error in getting auction data', error)
        }
    }
    // get biddres
    async function getBiddersData(auctionId, horseId, incrementalIncrease = 0, startingPrice = 0) {
        try {
            const bids = await axios.get(`/bid?auctionId=${auctionId}&horseId=${horseId}`)
            if (bids.data.status.responseCode == "200") {
                setBiddersData(bids.data.result);
                setBiddersDataLoaded(true);
                if (bids.data.result.length > 0) {
                    setNewBid(parseInt(bids.data.result[0].horseAmount ?? 0) + incrementalIncrease);
                } else {
                    setNewBid(parseInt(startingPrice ?? 0) + incrementalIncrease);

                }
            }
        } catch (error) {
            console.error('error in getting bidders Data', error);
        }
    }

    // get enrollment Status
    async function getEnrollmentStatus() {
        try {
            const res = await axios.get(`/bidders/isauctionuserenrolled?userId=${userProfile?.id}&auctionId=${auctionId}`);

            if (res.data.status.responseCode == "200") {
                if (res.data.result.paymentStatus.toLowerCase() == 'paid' || res.data.result.paymentStatus.toLowerCase() == 'EnrollWIthoutPayment'.toLowerCase()) {
                    setIsEnrolledUser(true);
                    setBidderNumber(res.data.result.bidderNumber);
                    setBidderId(res.data.result.bidderId)
                }
            }

        } catch (error) {
            console.error('error in check enrollment', error)
        }

    }


    // submit new bid
    async function handleOnsubmit(close) {
        if (isEnrolledUser === true) {
            async function placeBid() {
                const placeBid = await axios.post("/bid", {
                    bidAmount: newBid,
                    hourseAmount: newBid,
                    bidderID: bidderId,
                    auctionID: auctionId,
                    horseId: horseData[0].horseId,
                    bidderName: "",
                    deviceType: 2,
                    bidderNumber: bidderNumber,
                    bidderType: "online",
                });
                if (placeBid?.data?.status?.responseCode == "200") {
                    toast.success(
                        translate("web.submissionSuccessful", "Submission Successful")
                    );
                } else {
                    toast.error(
                        translate("web.errorInPlacingBid", "Error in Placing Bid")
                    );
                }
            }
            placeBid();

            close();
        } else {
            // Show the success message

            toast.error(
                translate(
                    "web.pleaseEnrollAndMakepayment",
                    "Please Enroll And Make payment"
                )
            );
            setTimeout(() => {
                navigate(-1)
            }, 5000);


            close();
        }
    }


    // now get the Inital data on page Load
    useEffect(() => {
        getEnrollmentStatus();
        getAuctionData();
    }, [])

    // firestore 
    const oneMinuteAgo = new Date();
    // oneMinuteAgo.setMinutes(oneMinuteAgo.getMinutes() - 1);
    useEffect(() => {
        const q = query(
            bidsCollectionRef,
            where("AuctionId", "==", auctionId),
            orderBy('TimeStamp', 'desc'),
            where("TimeStamp", ">=", oneMinuteAgo),
            limit(1),
        );

        // Set up a real-time listener for new documents
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newEntries = snapshot.docs.map((doc) => {
                const data = doc.data();

                // Format date before setting it into state
                return {
                    id: doc.id,
                    ...data,
                };
            });

            if (newEntries.length > 0) {

                // add new bid to the Table on UI
                if (!!newEntries[0].BidderNumber) {
                    const formattedBidders = newEntries.map((bidder) => ({
                        bidderNumber: bidder.BidderNumber,
                        bidderName: currentLanguage == 'en' ? bidder.BidderName : bidder.BidderNameAr,
                        horseAmount: parseInt(bidder.HorseAmount),
                        bidTime: bidder.BidTime,
                        bidderType: bidder.BidderType
                    }));

                    setBiddersData((prevDocs) => [...formattedBidders, ...prevDocs]);
                    debugger
                    if (formattedBidders.length > 0) {
                        setNewBid(parseInt(formattedBidders[0].horseAmount) + incrementalIncrease);
                        debugger;
                    }
                }

                if (newEntries[0].ChangeIncrementalIncreaseAmount == 'True') {
                    setIncrementalIncrease(parseInt(newEntries[0].NewIncrementalIncreaseAmount));
                    incrementalIncrease = parseInt(newEntries[0].NewIncrementalIncreaseAmount);
                    setNewBid((prev) => prev - parseInt(newEntries[0].OldIncrementalIncreaseAmount) + parseInt(newEntries[0].NewIncrementalIncreaseAmount))
                }
                if (newEntries[0].HorseStatus == 'StartBidding') {
                    toast.warn('new Horse Added');
                    setBiddersData([]);
                    getAuctionData();

                }
                if (newEntries[0].HorseStatus == 'Sold') {
                    toast.success('Current horse is Sold');
                    setHorseData([]);
                    setBiddersData([]);
                }
                if (newEntries[0].HorseStatus == 'UnSold') {
                    toast.error('Current horse is UnSold');
                    setHorseData([]);
                    setBiddersData([]);
                }

            }

        });

        // Clean up listener on unmount
        return () => unsubscribe();
    }, []);

    const dismissAll = () => toast.dismiss();

    // enroll Now
    const checkEnrollment = () => {
        const accessToken = localStorage.getItem('accessTokenforEAHS') ?? '';
        if (!!accessToken) {
            const formattedData = {
                "Full Name": userProfile.fullName,
                Nationality: userProfile.nationalityName,
                Address: userProfile.address,
                "Phone Number": userProfile.phone,
                "Emirates ID": userProfile.idNumber,
            };
            setUserProfileAuction(formattedData);


        } else {
            navigate(`/login?redirectURL=/hybridBidding?auctionID=${auctionId}`)
        }
    }

    const handleCheckboxChange = (e) => {
        setAcceptTerms(e.target.checked);
    };
    const handleExternalLink = (url) => {
        window.location.href = url + `&returnUrl=${BaseURL}/hybridBidding?auctionID=${auctionId}`;
    };
    async function makePayment(paymentType = paymentMethod, close) {
        const userID = localStorage.getItem("EAHSUserID");
        setLoader(true);
        try {
            switch (paymentType) {
                case "onlinepayment":
                    const payment = await axios.post(
                        "/bidders/enrollbidder",
                        {
                            UserId: userID,
                            EnrolledAuctionId: auctionId,
                            DepositPayment: "EnrollWithPayment",
                            PaymentStatus: "Paid",
                            PaymentMethod: "OnlinePayment",
                            BidderFiles: "",
                            IsBidForOthers: isBidForOthers
                        },
                        {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        }
                    );
                    const paymentURL = payment.data.result.url;

                    setLoader(false);
                    if (payment.data.status.responseCode === "200") {
                        handleExternalLink(paymentURL);
                    }

                    break;
                case "banktransfer":
                    const bankpayment = await axios.post(
                        "/bidders/enrollbidder",
                        {
                            UserId: userID,
                            EnrolledAuctionId: auctionId,
                            DepositPayment: "EnrollWithPayment",
                            PaymentStatus: "Paid",
                            PaymentMethod: "BankTransfer",
                            // BidderFiles: uploadedFiles,
                            IsBidForOthers: isBidForOthers
                            //BidderFiles: uploadedFiles[0],
                        },
                        {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        }
                    );
                    setLoader(false);
                    if (bankpayment.data.status.responseCode === "200") {
                        // console.log("bankpayment is ", bankpayment.data);
                        toast.success(
                            translate(
                                "web.bankTransfer",
                                "it may take up to 3 working days to completed "
                            )
                        );
                        close();
                    }
                    break;
                case "cash":
                    const cashpayment = await axios.post(
                        "/bidders/enrollbidder",
                        {
                            UserId: userID,
                            EnrolledAuctionId: auctionId,
                            DepositPayment: "EnrollWithPayment",
                            PaymentStatus: "Paid",
                            PaymentMethod: "Cash",
                            IsBidForOthers: isBidForOthers,
                            BidderFiles: "",
                        },
                        {
                            headers: {
                                "content-type": "multipart/form-data",
                            },
                        }
                    );
                    setLoader(false);
                    if (cashpayment.data.status.responseCode === "200") {
                        toast.success(
                            translate(
                                "web.cashEnrollMsg",
                                "Please Proceed to complete the payment at EAHS office "
                            )
                        );
                        close();
                    }
                    break;

                default:
                    toast.error(
                        translate(
                            "web.pleaseSelectPaymentMethod",
                            "Please select payment method"
                        )
                    );
                    break;
            }
        } catch (error) {
            setLoader(false);
            toast.error(translate('web.errorInMakePaymentPleaseContactAdmin', 'error in make payment Please Contact Admin'))
        }
    }

    const requestRefund = async () => {
        try {
            const res = await axios.post(`/bidders/refundrequest?auctionId=${auctionId}`);
            console.debug('refunded', res);
            if (res.data.status.responseCode == "200") {
                toast.success(translate('web.requestSendSuccessfully', 'Request Send Successfully'))
                setIsEnrolledUser(false);
            } else {
                if (res.data.status.responseCode == "100") {
                    toast.error(translate(`web.${convertToCamelCase(res.data.status.detailedResponseCode)}`, res.data.status.detailedResponseCode))
                }
            }

        } catch (error) {
            toast.error('error in requesting refund Please Contact the Admin')
        }
    }




    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                onClick={dismissAll}
                theme="colored"
                autoClose={5000}
            />

            {!!auctionDataLoaded && (
                <React.Fragment>
                    <SiteMap
                        siteTree={[
                            `${translate(
                                `web.${getTimeStatus(
                                    auctionData.startDate,
                                    auctionData.endDate
                                ).toLowerCase()}Auction`,
                                `${getTimeStatus(
                                    auctionData.startDate,
                                    auctionData.endDate
                                ).toLowerCase()}Auction`
                            )}`,
                            auctionData.name,
                            horseData.length > 0 ?
                                horseData[0].horseName : translate('web.noHorseInArena', 'No Horse In Arena'),
                        ]}
                    />

                    <div className="container-fluid p0">
                        <div className="row m0-bgred rtl">
                            <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12 p0">
                                <ImagesSlider
                                    sliderName={"bidding"}
                                    data={horseData.length > 0 ? horseData[0].horseAttachment : []}
                                    type={"bidding"}
                                    horseName={"myname"}
                                    VideoURL={[auctionData?.liveUrl, auctionData?.introVideoUrl]}
                                />
                            </div>


                            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 p0-dflex-col right-to-left">
                                <div className="w100-bgred-f1p3020">
                                    {horseData.length > 0 ?
                                        <>
                                            <span className="d-block-font25-fw-bold-white">
                                                {horseData[0]?.horseName} {translate("web.lotNo", "Lot No")}.{" "}
                                                {horseData[0]?.lotNumber}
                                            </span>
                                            {/* <p className="fz15-mt15-white">{horseData[0]?.horseDescription}</p> */}
                                            <div className="BiddingPageSpacer"></div>
                                            <div className="row my-15">
                                                <div className="col-lg-4  col-md-4  col-sm-4">
                                                    <span className="d-block-font12-white">
                                                        {translate("web.startPrice", "Start Price")}
                                                    </span>
                                                    <span className="d-block-font20-white">
                                                        {horseData[0]?.startingPrice?.toLocaleString("en-US")}{" "}
                                                        {translate("web.aed", "AED")}
                                                    </span>
                                                </div>
                                                <div className="col-lg-6  col-md-6  col-sm-6">
                                                    <span className="d-block-font12-white">
                                                        {translate(
                                                            "web.incrementalIncreaseAmount",
                                                            "Incremental Increase Amount"
                                                        )}
                                                    </span>
                                                    <span className="d-block-font20-white">
                                                        {UIincrementalIncrease.toLocaleString("en-US")}{" "}
                                                        {translate("web.aed", "AED")}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="BiddingPageSpacer"></div>
                                            <div className="text-center py20">
                                                <i className="fa-regular fa-clock white-30"></i>
                                            </div>
                                            <CronometerCounter endDate={horseEntryTime} countUp={true} isMinutes={true} />

                                        </> :
                                        <span className="d-block-font25-fw-bold-white">
                                            {translate("web.noHorseInArenaRightNow", "No Horse In Arena Right Now")}
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="text-center my-15">
                            <span className="viewDetailsDiv">
                                {translate("web.auctionBidding", "auction bidding")}
                            </span>
                        </div>
                        <div className="row BiddingPageRow rtl">
                            {isEnrolledUser &&
                                <div className='col-6 text-start'  >
                                    <div className='btn btn-danger text-white' onClick={requestRefund} >
                                        {translate('web.requestRefund', 'Request Refund')}
                                    </div>
                                </div>
                            }
                            <div className={isEnrolledUser ? 'col-6 text-end' : 'col-12 text-end'} >
                                <Link to={`/fullScreenBidding?auctionID=${auctionId}`} title={translate('web.watchInFullScreenMode', 'watch in full screen mode')} target='_blank' className='btn btn-danger text-white' >
                                    <i className="fa-solid fa-up-right-and-down-left-from-center"></i>
                                </Link>
                            </div>
                            {horseData.auctionType !== "Live" && (
                                (horseData.length > 0) &&
                                <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12">
                                    {isEnrolledUser == false ?
                                        <>
                                            <span className='text-danger'>
                                                {translate(
                                                    "web.youNeedEnrollandPayTostartBidding",
                                                    "You Need to Enroll and Pay To start Bidding"
                                                )}
                                            </span>
                                            {auctionData.isEnrollIngOpen &&
                                                <Popup
                                                    trigger={<ActionButton
                                                        type={"enrollNow"}
                                                        className='mt-2'
                                                        width={150}
                                                        text={enrollButtonText}
                                                    />}
                                                    closeOnEscape={true}
                                                    modal
                                                    lockScroll
                                                    overlayStyle={{ background: 'rgba(0,0,0,0.5)', zIndex: 1550 }}
                                                    onOpen={checkEnrollment}
                                                    contentStyle={{ padding: '20px 50px' }}

                                                >
                                                    {(close) => (
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <div className="text-center">
                                                                    <span className="showAuctionPaymentConfirmationspan">
                                                                        {translate("web.enrollment", "Enrollment")}
                                                                    </span>
                                                                </div>
                                                                <div className="text-center">
                                                                    <span className="showAuctionPaymentConfirmationspan color-eahsred">
                                                                        {translate("web.auctionFees", "Auction Fees")}:{" "}
                                                                        {auctionData?.fees?.toLocaleString('en-US')} {translate("web.aed", "AED")}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {Object.entries(userProfileAuction).map(([key, value]) => (
                                                                <div className="row rtl">
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-1 mb-1 text-center auctionPaymentuserKeyCol ">
                                                                        <span className="auctionPaymentuserkeyspan">
                                                                            {translate(`web.${convertToCamelCase(key)}`, key)}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 mt-1 mb-1 text-center auctionPaymentuserValCol ">
                                                                        <span className="auctionPaymentuserValspan">{value}</span>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="row rtl">
                                                                <div className="form-check-inline text-start  rtl ">
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="isBidForOthers"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="isBidForOthers"
                                                                            name="isBidForOther"
                                                                            value="accepted"
                                                                            checked={isBidForOthers}
                                                                            onChange={(e) => setIsBidForOthers(e.target.checked)}
                                                                        />
                                                                        <div key={'iiFileItem'} className="iAgreeToTheTermsAndConditionsOfTheAuction" style={{ minWidth: 120, display: 'inline-block' }}>
                                                                            {translate(
                                                                                "web.isBidForOthers",
                                                                                "Is Bid For Others"
                                                                            )}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline text-start  rtl ">
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="accepttermsandconditions"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="accepttermsandconditions"
                                                                            name="qazwsx"
                                                                            value="accepted"
                                                                            checked={acceptTerms}
                                                                            onChange={handleCheckboxChange}
                                                                        />

                                                                        <Popup
                                                                            modal
                                                                            trigger={
                                                                                <div key={'iFileItem'} className="iAgreeToTheTermsAndConditionsOfTheAuction" style={{ minWidth: 120, display: 'inline-block' }}>
                                                                                    {translate(
                                                                                        "web.iAgreeToTheTermsAndConditionsOfTheAuction",
                                                                                        "I agree to the terms and conditions of the auction"
                                                                                    )}
                                                                                </div>
                                                                            }
                                                                            closeOnDocumentClick={true}
                                                                            closeOnEscape
                                                                            className="popupUnsetmaxWidth"
                                                                            overlayStyle={{ zIndex: 5000 }}
                                                                            contentStyle={{ maxWidth: 700 }}
                                                                        >
                                                                            {(close) => (
                                                                                <React.Fragment>
                                                                                    <div
                                                                                        className="text-start"
                                                                                        style={{ maxHeight: '60vh', overflowY: 'scroll' }}
                                                                                        dangerouslySetInnerHTML={{ __html: currentLanguage == 'en' ? auctionData.termsAndConditions : auctionData.termsAndConditionsAr }}
                                                                                    />
                                                                                    <br />
                                                                                    <div
                                                                                        className="rtl"
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'row',
                                                                                            justifyContent: 'space-around',
                                                                                        }}
                                                                                    >
                                                                                        <ActionButton type={'btn'} label={translate("web.close", "close")} onClick={close} text={translate("web.close", "close")} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </Popup>
                                                                    </label>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-6 text-center pt-10 ">
                                                                    <ActionButton
                                                                        margin={0}
                                                                        onClick={close}
                                                                        label={translate("web.cancel", "Cancel")}
                                                                        type={"loadmore"}
                                                                        key={"cancel"}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col-lg-6 col-md-6 col-sm-6 text-center position-relative pt-10 "
                                                                    style={{ opacity: acceptTerms ? 1 : 0.5 }}
                                                                >
                                                                    {!acceptTerms && <div className="acceptTerms"></div>}
                                                                    {loader ? (
                                                                        <Loader />
                                                                    ) : (
                                                                        <ActionButton
                                                                            margin={0}
                                                                            text={translate(
                                                                                "web.confirmAndPayNow",
                                                                                "Confirm & Pay Now"
                                                                            )}
                                                                            onClick={() => makePayment('onlinepayment', close)}
                                                                            type={"confirmandpay"}
                                                                            key={"Payment"}
                                                                        />
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </div>

                                                    )}

                                                </Popup>
                                            }

                                        </>
                                        : (
                                            horseData.length > 0 && (
                                                <div className="w100br-6">
                                                    <div className="text-center">
                                                        <span className="viewDetailsDiv">
                                                            {translate("web.addNewBid", "Add New Bid")}
                                                        </span>
                                                    </div>

                                                    <div className="AuctionDetailsSpacer"></div>
                                                    <div className="BiddingPageRow2 rtl">
                                                        <span className="d-block-f15-bold">
                                                            {translate("web.bidderNo", "Bidder Nubmer")}
                                                        </span>
                                                        <span className="d-block-f15-bold">
                                                            {bidderNumber}
                                                        </span>
                                                    </div>
                                                    <div className="BiddingPageRow2" style={{ flexWrap: 'wrap' }}>
                                                        <span className="dflex-bold-20-white-p2-br-5">
                                                            <div className="dflex-jcenter-acenter-text-center-br2">
                                                                <span className="display_block color-eahsred">
                                                                    {newBid} {translate("web.aed", "AED")}
                                                                </span>
                                                            </div>
                                                        </span>
                                                        <Popup
                                                            modal
                                                            lockScroll
                                                            trigger={
                                                                <div className="bidSubmit">
                                                                    {" "}
                                                                    {translate("web.submit", "Submit")}
                                                                </div>
                                                            }
                                                            closeOnDocumentClick={false}
                                                            closeOnEscape
                                                            contentStyle={{ width: "90%", maxWidth: 400 }}
                                                        >
                                                            {(close) => (
                                                                <React.Fragment>
                                                                    <div
                                                                        style={{ fontSize: 20, fontWeight: "bold" }}
                                                                    >
                                                                        {translate(
                                                                            "web.youwillbidforanamountof",
                                                                            "You will bid for an amount of"
                                                                        )}
                                                                    </div>
                                                                    <span
                                                                        style={{
                                                                            display: "block",
                                                                            color: "#E44D52",
                                                                            fontWeight: "bold",
                                                                            fontSize: 20,
                                                                        }}
                                                                    >
                                                                        {newBid}{" "}
                                                                        <span style={{ color: "#000", fontSize: 15 }}>
                                                                            {translate("web.aed", "AED")}
                                                                        </span>
                                                                    </span>
                                                                    <div
                                                                        className="rtl"
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            justifyContent: "space-between",
                                                                            marginTop: "25px",
                                                                            flexWrap: "wrap",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="bidSubmit"
                                                                            style={{
                                                                                background: "#fff",
                                                                                color: "#E44D52",
                                                                                margin: "5px auto",
                                                                            }}
                                                                            onClick={() => close()}
                                                                        >
                                                                            {translate("web.cancel", "Cancel")}
                                                                        </div>
                                                                        <div
                                                                            className="bidSubmit"
                                                                            onClick={() => handleOnsubmit(close)}
                                                                            style={{ width: 150, margin: "5px auto" }}
                                                                        >
                                                                            {translate("web.submit", "Submit")}
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )}
                                                        </Popup>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                </div>
                            )}
                            {biddersDataLoaded &&
                                <div className={"col-xl-8 col-lg-9 col-md-12 col-sm-12 right-to-left mx-auto"}>
                                    <div className="text-center red-f20-pt0">
                                        {translate("web.listOfBids", "List Of Bids")}
                                    </div>
                                    <div className="tableFixHead">
                                        <table id="listofbidstable" className="table table-striped table-bordered rtl horseResultTable">
                                            <thead>
                                                <tr>
                                                    <th className='text-center' style={{ maxWidth: 50 }}>{translate("web.bidderNo", "Bidder No")}</th>
                                                    <th className='text-center' style={{ maxWidth: 100 }} >{translate("web.bidderName", "Bidder Name")}</th>
                                                    <th className='text-center' style={{ maxWidth: 75 }}>{translate("web.bidAmount", "Bid Amount")}</th>
                                                    <th className='text-center' style={{ maxWidth: 100 }} >{translate("web.bidTime", "Bid Time")}</th>
                                                    <th className='text-start' >{translate("web.bidderType", "Bidder Type")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {biddersData.map((bidder) => (
                                                    <tr>
                                                        <td className='text-center' style={{ maxWidth: 50 }}>{bidder.bidderNumber}</td>
                                                        <td className='text-center' style={{ maxWidth: 100 }} >
                                                            {bidder.bidderName}
                                                        </td>
                                                        <td className='text-center' style={{ maxWidth: 75 }}>
                                                            {bidder.horseAmount.toLocaleString('en-US')} {translate("web.aed", "AED")}
                                                        </td>
                                                        <td className='text-center' style={{ maxWidth: 100 }}>
                                                            {formateDateToMac(bidder.bidTime)}
                                                        </td>
                                                        <td className='text-start'>
                                                            {bidder.bidderType.toLowerCase() == "live" ||
                                                                bidder.bidderType.toLowerCase() == "حضوري" ? (
                                                                <React.Fragment>
                                                                    <i className="fa-solid fa-circle color-eahsred"></i>{" "}
                                                                    {translate("web.live", "Live")}
                                                                </React.Fragment>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {bidder.bidderType.toLowerCase() == "online" ||
                                                                bidder.bidderType.toLowerCase() == "عبر الانترنت" ? (
                                                                <React.Fragment>
                                                                    {" "}
                                                                    <i className="fa-solid fa-circle color-green"></i>{" "}
                                                                    {translate("web.onlineBidder", "Online")}
                                                                </React.Fragment>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </React.Fragment>
                // <></>
            )}
        </React.Fragment>
    )
}
