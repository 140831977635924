import React, { useEffect, useRef, useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "../axios/axios";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
// import { toast } from "react-toastify";
import LoginUaePassOTPForm from "./LoginUaePassOTPForm";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export default function ForgetPasswordForm({showToastmessage , setEmail , setShowOTP , toast}) {


  const [loginCred, setLoginCred] = useState({
    email: "",
    GoogleRecaptchaResponse:''
  });
  const [loginError, setLoginError] = useState(null);
  const [loader, setLoader] = useState(false);
  const {translate} = useTranslate();


  const handleChange = (event) => {
    setLoginCred({
      ...loginCred,
      [event.target.name]: event.target.value,
    });
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginState = await tryLogin(loginCred); // Pass filter state to auctionPage
  };

  async function tryLogin(loginCred) {
    setLoader(true)
    const response = await axios.post("/account/forgotpassword", { ...loginCred,recaptchaToken });
    await setLoginError(null);
    if (response.data.status.responseCode === "200") {
      setLoginError('Password reset instructions sent to your email.')
      toast.success(translate ('web.resetPassSuccess' ,'Password reset instructions sent to your email.') )
      setLoader(false);
      setShowOTP(true);
      return true;
    }
    else {
      setLoginError('Check Your Email Please');
      setLoader(false);
      showToastmessage(translate ('web.resetPassFail' ,'Check Your Email Please') )

      return false;
    }
  }

  // recaptcha
  const [recaptchaValidate, setRecaptchaVaidate] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");


  const recaptchaRef = useRef();

  useEffect(() => {
    recaptchaRef.current.execute();
  }, [])
 
  return (
    <>
      <form className="bg-gray-p-25 right-to-left"
        onSubmit={handleSubmit}
      >
        <h2>{translate('web.forgetPassword','Forget Password')}</h2>
        <h5>
          {translate('web.pleaseEnterYourEmailtoSendPasswordResetLink','Please Enter Your Email to Send Password Reset Link')}
        </h5>
        <NetaqInput
          name={"email"}
          key={"email"}
          label={translate('web.email','email')}
          placeholder={"Email"}
          type={"email"}
          onChange={handleChange}
        />
         <ReCAPTCHA sitekey={recaptchaSiteKey}
          size="invisible"
          ref={recaptchaRef}
          onChange={(token) => {
            setRecaptchaToken(token);
            if(token !== ""){
              setRecaptchaVaidate(true)
            }
          }} />
      
        {!!loader ? <Loader /> : ''}

        <div className="d-flex-jc-center-fld-col">
          <ActionButton
            margin={5}
            width={"100%"}
            key={"apply"}
            text={translate('web.sendResetLink',"Send Reset Link")}
            type={"submit"}
            disabled={!recaptchaValidate}
          />
        </div>
      </form>
      
    </>

  );
}
