import axios from "../../axios/axios";

export const FetchHorseColorsAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Lookup/GetHorseColors?lang=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCountriesAPI = async (param) => {
  try {
    let response = await axios.get(
      `/Lookup/GetCountries?lang=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchBankDetailsAPI = async () => {
  try {
    let response = await axios.get(`/Lookup/GetBankDetails`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCitiesAPI = async () => {
  try {
    let response = await axios.get(`/Lookup/GetCities`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
