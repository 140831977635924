import axios from "../../axios/axios";

export const FetchAuctionEntryAPI = async (param) => {
  try {
    const response = await axios.get(
      `/AuctionEntry/GetAuctionEntry?langauge=${param.langauge}&ownerId=${param.ownerId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchAddEditAuctionHorseAPI = async (param) => {
  try {
    const formData = new FormData();

    if (param.auctionHorseId !== null)
      formData.append("id", param.auctionHorseId);
    formData.append("auctionId", param.auctionId);
    formData.append("horseId", param.horseId);
    formData.append("ownerId", param.ownerId);
    formData.append("horsePerformanceEn", param.horsePerformanceEn);
    formData.append("horsePerformanceAr", param.horsePerformanceAr);
    formData.append("horseStartingPrice", param.horseStartingPrice);
    formData.append("minimumPreferredPrice", param.minimumPreferredPrice);
    formData.append("horseIncrementalIncrease", param.horseIncrementalIncrease);
    formData.append("horseVideo", param.horseVideo);
    formData.append("createdBy", param.createdBy);
    param.auctionHorseFiles.forEach((element, i) => {
      if (element.id !== null)
        formData.append(`auctionHorseFiles[${i}].id`, element.id);

      formData.append(
        `auctionHorseFiles[${i}].documentType`,
        element.documentType
      );
      formData.append(
        `auctionHorseFiles[${i}].documentFile`,
        element.documentFile
      );
    });

    const response = await axios.post(
      `/AuctionEntry/AddEditAuctionHorse`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchAuctionHorsesRequestsAPI = async (param) => {
  try {
    const response = await axios.get(
      `/AuctionEntry/GetAuctionHorsesRequests?ownerId=${param.ownerId}&langauge=${param.langauge}&auctionId=${param.auctionId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchDeleteAuctionHorseAPI = async (param) => {
  try {
    const response = await axios.get(
      `/AuctionEntry/DeleteAuctionHorse?id=${param.auctionHorseId}&createdBy=${param.createdBy}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateListAuctionHorsesIdsAPI = async (param) => {
  try {
    const response = await axios.post(
      `/AuctionEntry/UpdateListAuctionHorsesIds`,
      {
        ids: param.ids,
        createdBy: param.createdBy,
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCheckOwnerHaseHorseInAuctionEntryAPI = async (param) => {
  try {
    const response = await axios.get(
      `/AuctionEntry/CheckOwnerHaseHorseInAuctionEntry?ownerId=${param.ownerId}&auctionId=${param.auctionId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCountOwnerHaseHorseInAuctionEntryAPI = async (param) => {
  try {
    const response = await axios.get(
      `/AuctionEntry/CountOwnerHaseHorseInAuctionEntry?ownerId=${param.ownerId}&auctionId=${param.auctionId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetOwnerAuctionHorsesCompletedAPI = async (param) => {
  try {
    const response = await axios.get(
      `/AuctionEntry/GetOwnerAuctionHorsesCompleted?ownerId=${param.ownerId}&auctionId=${param.auctionId}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateAuctionHorsesAPI = async (param) => {
  try {
    const response = await axios.post(`/AuctionEntry/UpdateAuctionHorses`, {
      auctionId: param.auctionId,
      createdBy: param.createdBy,
      auctionParticipantsHorsesList: param.auctionParticipantsHorsesList,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetAuctionByIdAPI = async (param) => {
  try {
    const response = await axios.get(`/Auction/Id?id=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
