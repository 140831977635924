import React, { useEffect, useRef, useState } from 'react'
import Calendar from '../components/Calendar'
import { useTranslate } from "../components/TranslationText";
import ComingSoon from '../components/ComingSoon';
import { CalenderSeasonsCalculator } from '../helper/CalenderSeasonsCalculator';
import axios from '../axios/axios';
import { getDayfromDate } from '../helper/getDayfromDate';
import axiosUmbraco from '../axios/axiosUmbraco';
import { getEventColor } from '../helper/getEventColor';
import { Link } from 'react-router-dom';
import { getEventLink } from '../helper/getEventLink';
import Loader from '../components/Loader';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ActionButton from '../components/ActionButton';
import domtoimage from 'dom-to-image';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Canvg } from 'canvg';


const eventColorsID = process.env.REACT_APP_EVENTS_COLORS_ID;
const umbracoEvents = process.env.REACT_APP_UMBRACO_EVENTS_ID;

export default function CalendarPage() {
    const { translate } = useTranslate()

    const [nowDate, setNowDate] = useState(new Date());
    const [dates, setDates] = useState(CalenderSeasonsCalculator(nowDate.getFullYear()));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const componentRef = useRef(null);
    const [isCapturing, setIsCapturing] = useState(false);

    const loadImages = () => {
        return new Promise((resolve) => {
            const images = document.images;
            let loadedCount = 0;
            const totalImages = images.length;

            if (totalImages === 0) {
                resolve();
            }

            for (let i = 0; i < totalImages; i++) {
                const image = images[i];
                if (image.complete) {
                    loadedCount++;
                    if (loadedCount === totalImages) {
                        resolve();
                    }
                } else {
                    image.onload = () => {
                        loadedCount++;
                        if (loadedCount === totalImages) {
                            resolve();
                        }
                    };
                    image.onerror = () => {
                        loadedCount++;
                        if (loadedCount === totalImages) {
                            resolve();
                        }
                    };
                }
            }
        });
    };
    const setImagesCrossOrigin = () => {
        document.querySelectorAll('img').forEach((img) => {
            if (!img.crossOrigin) {
                img.crossOrigin = 'anonymous';
            }
        });
    };
    // const captureFullPage = async (seasonName = 'fullpage-desktop-view') => {
    //     setImagesCrossOrigin();
    //     await loadImages();
    //     // Capture full page screenshot
    //     const myCurrentWidth = window.innerWidth;
    //     document.body.style.width = `${Math.max(myCurrentWidth, 1500)}px`; // Adjust to any desktop width
    //     document.body.style.overflowX = 'hidden'; // Hide horizontal scrollbar
    //     html2canvas(componentRef.current, {
    //         scale: 1, // Increase scale for better quality
    //         useCORS: true,
    //         logging: true,  // Enable logging to debug CORS and other issues
    //         width: document.body.scrollWidth,
    //         height: document.body.scrollHeight,
    //         onclone: (documentClone) => {
    //             // Make sure images are loaded in the cloned document
    //             documentClone.querySelectorAll('img').forEach((img) => {
    //                 if (!img.complete) {
    //                     img.onload = () => {};
    //                 }
    //             });
    //         },
    //     }).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');

    //         // Create a PDF with jsPDF
    //         const pdf = new jsPDF('p', 'mm', 'a4'); // Set to portrait mode and A4 size

    //         const imgWidth = 210; // A4 width in mm
    //         const pageHeight = 297; // A4 height in mm
    //         const imgHeight = (canvas.height * imgWidth) / canvas.width;

    //         let heightLeft = imgHeight;
    //         let position = 0;

    //         // Add the image in multiple pages if it exceeds page height
    //         while (heightLeft > 0) {
    //             pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //             heightLeft -= pageHeight;
    //             position -= pageHeight;

    //             if (heightLeft > 0) {
    //                 pdf.addPage();
    //             }
    //         }

    //         // Save the generated PDF
    //         pdf.save(`${seasonName}.pdf`);
    //         // document.body.style.width = `${myCurrentWidth}px`;
    //         document.body.style.width = `unset`;
    //     });
    // }
    const loadAllImages = () => {
        return Promise.all(
            Array.from(document.images).map((img) => {
                return new Promise((resolve, reject) => {
                    if (img.complete) {
                        resolve();
                    } else {
                        img.onload = resolve;
                        img.onerror = reject; // Handle errors as well
                    }
                });
            })
        );
    };

    const disableLazyLoading = () => {
        document.querySelectorAll('img[loading="lazy"]').forEach((img) => {
            img.setAttribute('loading', 'eager');
        });
    };
    const handleImageErrors = () => {
        document.querySelectorAll('img').forEach((img) => {
            img.onerror = () => {
                console.warn('Image failed to load, replacing with placeholder: ', img.src);
                img.src = 'path/to/placeholder.png'; // Replace with a local placeholder image
            };
        });
    };
    const setCrossOriginForImages = () => {
        document.querySelectorAll('img').forEach((img) => {
            if (!img.crossOrigin) {
                img.crossOrigin = 'anonymous';
            }
        });
    };
    const fetchImageAsDataURL = (url) => {
        return new Promise((resolve, reject) => {
            // Use XMLHttpRequest to handle CORS more explicitly
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result);
                };
                reader.onerror = reject;
                reader.readAsDataURL(xhr.response);
            };
            xhr.onerror = reject;
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    };
    const replaceImagesWithBase64 = async () => {
        const images = Array.from(document.querySelectorAll('img'));
        for (const img of images) {
            try {
                const dataUrl = await fetchImageAsDataURL(img.src);
                img.src = dataUrl;  // Replace with Base64 version
                img.crossOrigin = null;  // Remove cross-origin to avoid conflicts
            } catch (error) {
                console.warn(`Failed to load image from URL: ${img.src}`, error);
            }
        }
    };
    const prepareImagesForCapture = async () => {
        setCrossOriginForImages();
        disableLazyLoading();
        await replaceImagesWithBase64();  // Fetch and replace images with data URLs
        await loadAllImages();
    };

    const captureFullPage = async (seasonName = 'fullpage-desktop-view') => {
        try {
            await prepareImagesForCapture(); // Prepare all images as base64
            setTimeout(() => {
                const myCurrentWidth = window.innerWidth;
                document.body.style.width = `${Math.max(myCurrentWidth, 1500)}px`; // Expand to capture full content
                document.body.style.overflowX = 'hidden';

                domtoimage.toPng(componentRef.current, { useCORS: true })
                    .then((dataUrl) => {
                        const img = new Image();
                        img.src = dataUrl;

                        img.onload = () => {
                            // Create a PDF with jsPDF
                            const pdf = new jsPDF('p', 'mm', 'a4');
                            const imgWidth = 210; // A4 width in mm
                            const pageHeight = 297; // A4 height in mm
                            const imgHeight = (img.height * imgWidth) / img.width;

                            let heightLeft = imgHeight;
                            let position = 0;

                            while (heightLeft > 0) {
                                pdf.addImage(img, 'PNG', 0, position, imgWidth, imgHeight);
                                heightLeft -= pageHeight;
                                position -= pageHeight;

                                if (heightLeft > 0) {
                                    pdf.addPage();
                                }
                            }

                            // Save the generated PDF
                            pdf.save(`${seasonName}.pdf`);
                            document.body.style.width = 'unset';
                        };
                    })
                    .catch((error) => {
                        console.error("Error generating image from DOM: ", error);
                    });
            }, 1000); // Short delay to ensure rendering completes
        } catch (error) {
            console.error("Error converting images to Base64: ", error);
        }
    };


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setDates(CalenderSeasonsCalculator(nowDate.getFullYear()));
    }, [nowDate]);

    const handleNext = () => {
        setNowDate(prev => {
            const newDate = new Date(prev.getTime());
            newDate.setFullYear(prev.getFullYear() + 1);
            return newDate;
        });
        getseasons(dates[0].getFullYear() + 1, dates[6].getFullYear() + 1);
        setLoaded(false);
    };

    const handlePrev = () => {
        setNowDate(prev => {
            const newDate = new Date(prev.getTime());
            newDate.setFullYear(prev.getFullYear() - 1);
            return newDate;
        });
        getseasons(dates[0].getFullYear() - 1, dates[6].getFullYear() - 1);
        setLoaded(false);

    };

    function getCurrentLanguageUmbraco(isUmbracoAPI = false) {
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (!isUmbracoAPI) {
            return lang === "en" ? 'en' : 'ar';
        } else {
            return lang === "en" ? 'en-US' : 'ar-AE';
        }
    }

    const [events, setEvents] = useState([]);
    const [eventColors, setEventsColors] = useState([]);
    const [renderdMonthes, setRenderdMonthes] = useState([]);
    const [eventsCountsInCurrentSeason, setEventsCountInCurrentSeason] = useState(0);

    async function getLatestSeason() {
        const latestseasonis = await axios.get('shows/GetLastSeason')
        const [startDate, endDate] = latestseasonis.data.result.split("-");
        return { startDate, endDate }
    }


    async function getseasons(startDate, endDate) {
        const eventsColors = await axiosUmbraco.get(`/umbraco/delivery/api/v1/content?fetch=children:${eventColorsID}`,
            {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(true),
                },
            });

        const formattedColors = eventsColors.data.items.map((item) => ({
            eventName: item?.properties?.eventName ?? '',
            eventDisplayName: item?.properties?.eventDisplayName ?? '',
            eventCode: item?.properties?.eventCode ?? '',
            eventColor: item?.properties?.eventColor ?? '',
        }));

        setEventsColors(formattedColors);

        const eventsFromUmbraco = await axiosUmbraco.get(`/umbraco/delivery/api/v1/content?fetch=children:${umbracoEvents}`,
            {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(true),
                },
            });

        const formatedUmbracoEvents = eventsFromUmbraco.data.items.map((item) => ({
            title: item.properties.eventTitle,
            start: item.properties.eventStart,
            id: "",
            end: item.properties.eventEnd,
            type: item.properties.type,
            backgroundColor: getEventColor(item.properties.type, formattedColors, true),
            eventBG: getEventColor(item.properties.type, formattedColors, true),
        }));

        const incomingEvents = await axios.get(`shows/getseasonshowandauctonsbyseason?season=${startDate}-${endDate}&Langauge=${getCurrentLanguageUmbraco(false)}`);

        const formatedEvents = incomingEvents.data.result.map((item) => ({
            title: item.title,
            id: item.id ?? '',
            start: item.start,
            end: item.end,
            type: item.type,
            backgroundColor: (item.logo != null && item.logo !== "") ? `url(${item.logo})` : getEventColor(item.type, formattedColors, false),
            eventBG: getEventColor(item.type, formattedColors, false),
        }));

        const totalEvents = [...formatedUmbracoEvents, ...formatedEvents];
        const filterdTotalEvents = totalEvents.filter((item) => {
            const myFullYear = new Date(item.start).getFullYear();
            const isbetween = (myFullYear >= startDate && myFullYear < endDate)
            return isbetween
        })


        setEventsCountInCurrentSeason(filterdTotalEvents.length);
        setLoaded(true);
        setEvents(totalEvents);
        // Extract unique months from totalEvents' start and end dates
        const monthsSet = new Set();
        totalEvents.forEach(event => {
            if (event.start) {
                const startMonth = new Date(event.start).getMonth();
                monthsSet.add(startMonth);
            }
            if (event.end) {
                const endMonth = new Date(event.end).getMonth();
                monthsSet.add(endMonth);
            }
        });

        // Convert set to array and sort
        const renderedMonths = Array.from(monthsSet).sort((a, b) => a - b);
        setRenderdMonthes(renderedMonths);
    }

    useEffect(() => {
        async function fetchData() {
            const { startDate, endDate } = await getLatestSeason();
            setNowDate(new Date(startDate, 0, 1)); // Ensure the start date is set correctly
            setDates(CalenderSeasonsCalculator(startDate));
            getseasons(startDate, endDate);
        }
        fetchData();
    }, []);

    function chooseEventsByindex(index, date) {
        return events.map(item => ({ ...item, startDate: new Date(item.start), endDate: new Date(item.end) }))
            .filter(item => (item.startDate.getMonth() === (index + 9) % 12 && item.startDate.getFullYear() === date.getFullYear()) || item.endDate.getMonth() === (index + 9) % 12 && item.endDate.getFullYear() === date.getFullYear());
    }

    useEffect(() => {
        console.debug('event!!!', events);
    }, [events])


    return (
        <>
            {loaded ?
                <div className='container-fluid px75' ref={componentRef} >
                    <div className='row rtl'>
                        <div className='col-12 text-center'>
                            <div className="calnderuttonsContainer">
                                <div className="calenderBtn tooltip" onClick={handlePrev}>
                                    <i className="fa-solid fa-chevron-left justRotate"></i>
                                    <span class="tooltiptext">{translate('web.prev', 'Prev')}</span>
                                </div>
                                <div className="year fc-toolbar-title  rtl">
                                    {dates[0].getFullYear()} - {dates[6].getFullYear()}
                                </div>
                                <div className="calenderBtn tooltip" onClick={handleNext}>
                                    <i className="fa-solid fa-chevron-right justRotate"></i>
                                    <span class="tooltiptext">{translate('web.next', 'Next')}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justRotate' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        {
                            dates.map((date, index) => ({
                                date,
                                events: chooseEventsByindex(index, date)
                            })).map((item, index) => {
                                // isNoEventsinSeason(item.events.length > 0, index, dates.length);
                                return (
                                    item.events.length > 0 &&
                                    <div key={index} className={'col-lg-4 col-md-12 col-sm-12 justRotate w100OnMobile'} >
                                        <Calendar showHeader={false} isLeftSide={true} events={events} initialDate={item.date} aspectRatio={1} />
                                        <div className='eventsContainer'>
                                            <EventsDates events={item.events} />
                                        </div>
                                    </div>
                                )
                            }

                            )
                        }
                        {eventsCountsInCurrentSeason == 0 &&
                            <div className='justRotate' style={{ margin: 'auto' }}>
                                <ComingSoon text={'web.notAvailableNow'} defaultText={'Not Available Now'} />
                            </div>
                        }
                        <div className='calendarLegendContainer justRotate rtl' >
                            {eventColors.map((item, index) => (
                                <div key={index} className='legendCard'>
                                    <div className='legendCardrect' style={{ background: item.eventColor }}></div>
                                    <div className='legendCardDescription'>{item.eventDisplayName}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                : <Loader />
            }
            {/* <div className='row m0 text-start  '>
                <div className='mt-3'>
                    <ActionButton
                        type={'normalbtn'}
                        onClick={() => captureFullPage(`${dates[0].getFullYear()}-${dates[6].getFullYear()}`)}
                        label={'Capture Screenshot'}
                        text={translate('web.printPdf', 'Print Pdf')}
                    />
                </div>
            </div> */}
        </>
    );

    function EventsDates({ events }) {
        return events.sort((a, b) => (new Date(a.start) - new Date(b.start))).map((event, index) => (
            getEventLink(event.type, event.id) == "" ?
                <div key={index} className='eventRow rtl'>
                    <div className='dayContainer' style={{ background: event.eventBG }}>{getDayfromDate(event.start)}</div>
                    <div className='dayContainer' style={{ background: event.eventBG }}>{getDayfromDate(event.end)}</div>
                    {event.backgroundColor.includes('url') &&
                        <div className='dayContainer' style={{ background: event.backgroundColor ?? event.eventBG, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                    }
                    <div className='eventName'>{event.title}</div>
                </div>
                :
                <Link key={index} to={getEventLink(event.type, event.id)} className='eventRow rtl'>
                    <div className='dayContainer' style={{ background: event.eventBG, }}>{getDayfromDate(event.start)}</div>
                    <div className='dayContainer' style={{ background: event.eventBG, }}>{getDayfromDate(event.end)}</div>
                    {event.backgroundColor.includes('url') ?
                        <div className='dayContainer' style={{ background: event.backgroundColor ?? event.eventBG, backgroundSize: "cover", backgroundPosition: "center" }}></div> :
                        <div className='dayContainer' style={{ background: '#fff', backgroundSize: "cover", backgroundPosition: "center" }}></div>
                    }
                    <div className='eventName'>{event.title}</div>
                </Link>
        ));
    }
}
