export function getEventLink(type,id){
    if(type == 3){
        return '/singleauction?id='+id;
    }else if (type == 1 || type == 2){
        return '/showdetails?id='+id;
    }
    else{
        return '';
    }
}

// event Type From Backend )show || Auction

export function getEventType(type){
    if(type == 1){
        return 'show';
    }
    else if(type == 2){
        return 'auction';
    }
    else {
        return 'Unkown event type';
    }
}