import React, { useEffect, useState } from 'react'
import axios from '../axios/axios';
import Loader from './Loader';
import NetaqInput from './NetaqInput';
import ActionButton from './ActionButton';
import { useTranslate } from './TranslationText';
import UploadFile from './UploadFile';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import GrantedPermissionClaimsComponent from './Profile.GrantedPermissionClaims';

export default function OwnerPermisions({ toast, reloadProfile, setActiveTab }) {
    const [oldPermisions, setOldPermisions] = useState([]);
    const [loaded, setIsLoaded] = useState(false);
    const { translate } = useTranslate();
    const [newPermissionCred, setNewPermissionCred] = useState({})
    const [existingOwnerPermisionRequest, setExistingOwnerPermisionRequest] = useState({})
    const [ownersList, setOwnnersList] = useState([]);
    const [permissionsList, setPermissionsList] = useState([]);
    const [authFilesFinal, setAuthFileFinal] = useState([]);
    const [agentAuthFiles, setAgentAuthFiles] = useState([]);
    const [agentPermissionClaims, setAgentPermissionClaims] = useState([]);

    async function getPermissionList() {
        try {
            const permisionsssList = await axios.get(`/lookup/getpermissions`)

            // console.log('permisions are', permisionsssList)
            const formattedData = await permisionsssList.data.result.map((item) => ({
                text: item.permissionNameEn,
                value: item.id,
                id: item.permissionName
            }));
            setPermissionsList(formattedData)
        } catch (error) {
            window.alert('error in region list')
        }

    }
    async function getoldPermisions() {

        try {
            const request = await axios.get('account/getagentpermissions');

            if (request.data.responseCode == "200") {
                setOldPermisions(request.data.result)
                setIsLoaded(true);
            } else {
                toast.error('web.error', 'Error')
                setIsLoaded(true);

            }


        } catch (error) {
            setIsLoaded(true);
            // console.log('error in getting old permisions', error)
        }



    }


    useEffect(() => {
        getoldPermisions();
        getPermissionList();
    }, [])

   
    const [ownersListLoaded, setOwnersListLoaded] = useState(false);




    // delete current permision
    async function handleDeleteAgency(close, id) {
        // console.log(id);
        try {
            const deleteRequest = await axios.post('/account/deleteagentpermission', { id: id })

            if (deleteRequest.data.responseCode == "200") {
                toast.success(translate('web.yourDeleteRequestIsDone', 'Your Delete Request Is Done'));
                getoldPermisions();
            } else {
                toast.error(translate('web.yourDeleteRequestIsFailed', 'Your Delete Request Is Failed'))
                getoldPermisions();

            }

        } catch (error) {
            // console.log('error in deleting agyncy', error)
            getoldPermisions();
            toast.error(translate('web.errorInDeletingAgency', 'Error In Deleting Agency'))
        }



        close();
    }

    // add new permisions request
    const [issubmitting, setIsSubmitting] = useState(false);
    let agentPermissionList = [];

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const ownersArray = Object.keys(newPermissionCred)
            .filter(key => key.startsWith('ownerRegNumber') && !key.includes('Drop'))
            .map(key => newPermissionCred[key]); // Mapping to get an array of values
        // Extract authorization documents start dates as an array
        const authFilesStartDates = Object.keys(newPermissionCred)
            .filter(key => key.startsWith('autharizationDocumentsStartDate') && !key.includes('Drop'))
            .map(key => newPermissionCred[key]); // Mapping to get an array of values

        // Extract authorization documents expiry dates as an array
        const authFilesEndDates = Object.keys(newPermissionCred)
            .filter(key => key.startsWith('autharizationDocumentsExpiryDate') && !key.includes('Drop'))
            .map(key => newPermissionCred[key]); // Mapping to get an array of values   

        // Loop through the arrays to create agentPermission objects
        // Loop through the arrays to create agentPermission objects
        for (let index = 0; index < agentPermissionClaims.length; index++) {

            let agentPermisionObject = {};
            agentPermisionObject.Id = agentPermissionClaims[index].selectedOwner.value;
            agentPermisionObject.StartDate = agentPermissionClaims[index].fromDate.toDate();
            agentPermisionObject.ExpirationDate = agentPermissionClaims[index].toDate.toDate();

            const permissionsCustomList = agentPermissionClaims[index].selectedPermissions;
            const sentPermissions = permissionsCustomList.map((permision) => (permision.value));
            agentPermisionObject.PesmissionId = sentPermissions;
            agentPermisionObject.agentAuthFiles = agentPermissionClaims[index].agentAuthFiles;
            // Update the state with the new agent permission object
            agentPermissionList = [...agentPermissionList, agentPermisionObject];

        }
        const formData = new FormData();
        for (const [key, value] of Object.entries(newPermissionCred)) {
            if (value instanceof Object && 'value' in value) {
                formData.append(key, value.value);
            } else {
                formData.append(key, value);
            }
        }
        formData.append('UpgradeStatus', '4');
        formData.append('AccountType', '4');
        for (let q = 0; q < agentPermissionList.length; q++) {
            const element = agentPermissionList[q];
            formData.append(`AgentPermissionsList[${q}][Id]`, element.Id);
            formData.append(`AgentPermissionsList[${q}][StartDate]`, new Date(element.StartDate).toISOString());
            formData.append(`AgentPermissionsList[${q}][ExpirationDate]`, new Date(element.ExpirationDate).toISOString());
            for (let s = 0; s < element.PesmissionId.length; s++) {
                const perm = element.PesmissionId[s];
                formData.append(`AgentPermissionsList[${q}][PesmissionId][${s}]`, perm);
            }
        }
        const extractedData = agentPermissionClaims.flatMap(fileObj => {
            return fileObj.agentAuthFiles.map((item, index) => ({
                [`Id`]: fileObj.selectedOwner.value,
                [`AuthorizeFile`]: item.file
            }));
        });


        for (let z = 0; z < extractedData.length; z++) {
            formData.append(`AuthFilesList[${z}].Id`, extractedData[z].Id);
            formData.append(`AuthFilesList[${z}].AuthorizeFile`, extractedData[z].AuthorizeFile);
        }
        const sendNewRequest = await axios.post('/account/upgradeaccount', formData, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        })

        if (sendNewRequest.data?.responseCode == "200") {
            toast.success(translate('web.successful', 'Successful'));
            getoldPermisions();
            setNewPermissionCred({});
            setIsSubmitting(false);

        }
        else if (sendNewRequest.data.responseCode == "100") {
            toast.error(translate('web.faildToSubmit.', "Faild To Submit"));
            getoldPermisions();

        }
        else {
            toast.error(sendNewRequest.data.detailedResponseCode);
            getoldPermisions();
            setIsSubmitting(false);
        }

    }

    return (
        <div className='col-lg-12 col-md-12 col-sm-12 '>
            {loaded ?
                <div className='col-lg-12 col-md-12 col-sm-12 p0'>
                    <h3 className='col-lg-12 col-md-12 col-sm-12 bold  rtl p0'>
                        {translate('web.currentPrmisions', 'Current permisions')}
                    </h3>
                    {oldPermisions.length > 0 && (
                        <>
                            <table className="table table-striped table-bordered rtl horseResultTable">
                                <thead className="theadHorseSelect">
                                    <tr>
                                        <th className="text-center">
                                            {translate('web.ownerName', 'Owner Name')}
                                        </th>
                                        <th className="text-center">
                                            {translate('web.permisions', 'Permisions')}
                                        </th>
                                        <th className="text-center">
                                            {translate('web.authFiles', 'Auth Files')}
                                        </th>
                                        <th className="text-center">
                                            {translate('web.status', 'Status')}
                                        </th>

                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {oldPermisions.map((item, index) => (
                                        <tr>
                                            <td>{item.ownerName}</td>
                                            <td> {item.permissionsList.map((perm, _x) =>
                                            (<>
                                                {perm.permissionNameEn} {_x + 1 == item.permissionsList.length ? "" : ', '}
                                            </>))}
                                            </td>
                                            <td>{item.authorizeFileList.map((file, index) => (
                                                <>
                                                    <Link
                                                        to={file.authorizeFile}
                                                        className="C89C66 flex-center cursorPointer mb-25"
                                                        target="_blank"
                                                    >
                                                        <i className="fa fa-file fa-3x"></i>
                                                        <span className="black-color fontsize18 marginrightleft-10">
                                                            {`auth${index}`}
                                                        </span>
                                                    </Link>
                                                    {index + 1 == item.authorizeFileList.length ? "" : <br />}
                                                </>
                                            ))}
                                            </td>
                                            
                                            <td>
                                                {item.isConfirmed  ?  new Date(item.expirationDate) > new Date()? translate('web.active', 'Active'):  <div className='pendingFlag'>{translate('web.expired', 'expired')}</div>  : <div className='pendingFlag'>{translate('web.pending', 'Pending')}</div>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </>
                    )}
                </div>
                : <Loader />
            }
           
                <GrantedPermissionClaimsComponent isInOwnerProfile={true} ownersListLoaded={ownersListLoaded} ownersList={ownersList} permissionsList={permissionsList} toast={toast} agentPermissionClaimsChanged={setAgentPermissionClaims} />

            <div className="col-lg-12 col-md-12 col-sm-12  text-center" style={{ marginTop: 40 }}>
                {issubmitting ? <Loader /> :
                    <ActionButton type={"submit"}
                        label={"Save"}
                        text={translate("web.save", "Save")}
                        onClick={handleSubmit} />
                }
            </div>

        </div>
    )
}