import React from 'react'
import { formatDate } from '../helper/formatDate'
import { Link } from 'react-router-dom'
import { useTranslate } from './TranslationText';
import { formateUAEName } from '../helper/formateUAEName';


export default function HorseCard({ item, i , showDownloadPassport , isArabicClass }) {
    const { translate } = useTranslate();
    const BaseBACKENDURL = process.env.REACT_APP_BACKEND_API;

    const langauge = localStorage.getItem('eahsLang') ?? 'en';

    return (
        <div
            key={i}
            className={isArabicClass ?"col-lg-4 col-md-6 col-sm-12  width100Arabic768 rtl":"col-lg-4 col-md-6 col-sm-12 rtl"}
            style={{ marginTop: 10 }}
        >
            <div
                style={{
                    position: "relative",
                    minHeight: 200,
                    padding: "10px 20px",
                    background: "#FCFAF7",
                    borderTop: "2px solid #C89C66",
                    borderRadius: 5,
                    marginBottom: 15,
                }}
            >
                <span
                    style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        textTransform: "capitalize",
                        display: "block",
                        marginBottom: 5,
                    }}
                >
                    {item.name}
                </span>
                <span
                    style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        textTransform: "capitalize",
                        display: "block",
                        marginBottom: 23,
                    }}
                >
                    {translate("web.regno", "Regno")}: {item.registerNumber}
                </span>

                <div className="row">
                    <div
                        className="col-md-8 col-sm-12"
                        style={{
                            marginBottom: 22,
                        }}
                    >
                        <span className='rtlmr0'
                            style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textTransform: "capitalize",
                                display: "block",
                                marginBottom: 5,
                                marginRight: 195,
                                color: "rgb(119, 119, 119)",
                            }}
                        >
                            {translate("web.origin", "origin")}
                        </span>

                        <span
                            style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textTransform: "capitalize",
                                display: "inline",
                                marginBottom: 5,
                                // marginRight: 75,
                                color: "black",
                            }}
                        >
                            {formateUAEName(item.origin)}
                        </span>
                    </div>

                    <div
                        className="col-md-4 col-sm-12"
                        style={{
                            marginBottom: 22,
                        }}
                    >
                        <span
                            style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textTransform: "capitalize",
                                display: "block",
                                marginBottom: 5,
                                color: "rgb(119, 119, 119)",
                            }}
                        >
                            {translate("web.birthDate", "Birthdate")}
                        </span>

                        <span
                            style={{
                                fontWeight: "bold",
                                fontSize: 15,
                                textTransform: "capitalize",
                                display: "inline",
                                marginBottom: 5,
                                color: "black",
                            }}
                        >
                            {item.birthDate && formatDate(item.birthDate)}
                        </span>
                    </div>
                </div>
                <div className='row m0 justify-content-end' style={{padding:'0 15px'}}>
                <Link to={`/horseDetails?horseId=${item.id}`}
                    className="hoveronspan ViewProfileLink p0  rtl"
                    style={langauge == 'en'? {textAlign:'right' , marginRight:-10}:{textAlign:'left' , marginLeft:-10}}
                >
                    {translate("web.viewDetails", "View Details")}
                    <i
                        className="fa-solid fa-chevron-right justRotate red-fz-8-op-25"
                    ></i>
                    <i
                        className="fa-solid fa-chevron-right justRotate red-fz-8-op-50"
                    ></i>
                    <i
                        className="fa-solid fa-chevron-right  justRotate red-fz-8-op-1"
                    ></i>
                </Link>
                {!!showDownloadPassport && (
                    <Link
                        className="hoveronlink ViewProfileLink rtlabs"
                        style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            textAlign:'end'
                        }}
                        to={`${BaseBACKENDURL}/OwnerProfile/HorsePassport?id=${item.id}`}
                        target="_blank"
                    //onClick={() =>
                    //dispatch(FetchHorsePassportthunk(item.id))
                    //}
                    >
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9 8L7 8C4.79086 8 3 9.79086 3 12L3 17C3 19.2091 4.79086 21 7 21L19 21C21.2091 21 23 19.2091 23 17L23 12C23 9.79086 21.2091 8 19 8L17 8"
                                stroke="#777777"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M10 13L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L16 13"
                                stroke="#777777"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                            <path
                                d="M13 15L13 3"
                                stroke="#777777"
                                stroke-width="1.5"
                                stroke-linecap="round"
                            />
                        </svg>
                    </Link>
                )}
                </div>
            </div>
        </div>
    )
}
