export function translateDayToArabic(day, lang) {
  const days = {
    "Sunday": "الأحد", // al-aḥad
    "Monday": "الاثنين", // al-ithnayn
    "Tuesday": "الثلاثاء", // ath- والثلاثاء
    "Wednesday": "الأربعاء", // al-arbi‘aa
    "Thursday": "الخميس", // al-khamis
    "Friday": "الجمعة", // al-jum‘ah
    "Saturday": "السبت", // as-sabt
  };
  if (lang == 'ar') {
    return days[day] || "يوم غير معروف"; // "yawm ghair ma'roof" (day not found)
  } else {
    return day;
  }
}

export function translateMonthToArabic(month, lang) {

  const months = {
    "January": "يناير", // yanayir
    "February": "فبراير", // fibrayir
    "March": "مارس", // maaris
    "April": "أبريل", // abril
    "May": "مايو", // mayoo
    "June": "يونيو", // yoonyo
    "July": "يوليو", // yoolioo
    "August": "أغسطس", // ughustus
    "September": "سبتمبر", // septembir
    "October": "أكتوبر", // octobir
    "November": "نوفمبر", // nofembar
    "December": "ديسمبر", // deesembar
  };
  const shortMonth = {
    'JAN': "يناير",
    'FEB': "فبراير",
    'MAR': "مارس",
    'APR': "أبريل",
    'MAY': "مايو",
    'JUN': "يونيو",
    'JUL': "يوليو",
    'AUG': "أغسطس",
    'SEP': "سبتمبر",
    'OCT': "أكتوبر",
    'NOV': "نوفمبر",
    'DEC': "ديسمبر"
  }
  if (lang == 'ar-AE') {
    return shortMonth[month] || "شهر غير معروف"; // "shahr ghair ma'roof" (month not found)
  }
  if (lang == 'ar') {
    return months[month] || "شهر غير معروف"; // "shahr ghair ma'roof" (month not found)
  } else {
    return month;
  }
}