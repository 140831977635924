import React, { useEffect, useState } from "react";
import axiosInstance from "../axios/axios";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
import ActionButton from "./ActionButton";
import NetaqInput from "./NetaqInput";
import { getOrdinal } from "../helper/getOrdinal";
import { convertToCamelCase } from "../helper/convertToCamelCase";
import Popup from "reactjs-popup";
import UploadFile from "./SimpleFileUpload";
import { extractChars } from "../helper/extractChart";
import { Link } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import { formatDate } from "../helper/formatDate";


export default function WonHorses({ toast }) {
    const { translate } = useTranslate();
    const [wonHorsesData, setWonHorsesData] = useState([]);
    const [displayedWonHorsesData, setDisplayedWonHorsesData] = useState([]);

    const [totalCount, setTotalCount] = useState(0);
    const itemsPerPage = 20;
    const [searchByHorseName, setSearchByHorseName] = useState(null);
    const [searchByAuctionName, setSearchByAuctionName] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [noParticipatesFound, setNoParticipatesFound] = useState(false);

    async function getAuctionHistory() {
        try {
            const response = await axiosInstance.get("/auction/getauctionhistory");
            if (response.status == 200) {
                const Participates = response.data;
                setTotalCount(response.data.length);
                setWonHorsesData(Participates);
                setNoParticipatesFound(Participates.length === 0);
                setDataLoaded(true);
            } else {
                console.log("error in loading Participates");
                setDataLoaded(true);
            }
        } catch (error) {
            console.log("error in loading Participates", error);
            setDataLoaded(true);
        }
    }

    useEffect(() => {
        getAuctionHistory();
    }, []); // Fetch data on `skip` change for pagination

    const handleReset = () => {
        setSearchByHorseName('');
        setSearchByAuctionName('');
        setFromDate(null);
        setToDate(null);
       
        setCurrentPage(1);

    };

    const handleSubmit = () => {
        setCurrentPage(1);
    };

    const filteredData = wonHorsesData.filter((item) => {
        const matchesHorseName = searchByHorseName ? item.horse.toLowerCase().includes(searchByHorseName.toLowerCase()) : true;
        const matchesAuctionName = searchByAuctionName ? item.auctionName.toLowerCase().includes(searchByAuctionName.toLowerCase()) : true;
        const matchesFromDate = fromDate ? new Date(item.auctionDate) >= new Date(fromDate) : true;
        const matchesToDate = toDate ? new Date(item.auctionDate) <= new Date(toDate) : true;
        return matchesHorseName && matchesAuctionName && matchesFromDate && matchesToDate;
    });

    const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const handlePrevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredData.length / itemsPerPage)));
    };
    useEffect(()=>{
        setCurrentPage(1);
    },[toDate,fromDate,searchByHorseName,searchByAuctionName])


    // completeDataReuqest
    const [ownerFullName, setOwnerFullName] = useState('');
    const [isSendingRequest, setIsSendingRequest] = useState(false);
    const [passportCopy, setOwnerPassportCopy] = useState([]);
    const [isLocalRegitration, setIsLocalRegistraion] = useState(true);
    const [isForMe, setIsForMe] = useState(true);
    const [isRegisterd, setIsRegisterd] = useState(true);
    const handleUploadFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            let checkFileSize = true;
            const fileListHook = [];

            for (let fileIndex = 0; fileIndex < e.target.files.length; fileIndex++) {
                if (e.target.files[fileIndex].size > 10 * 1024 * 1024) {
                    checkFileSize = false;
                    toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
                } else {
                    const file = e.target.files[fileIndex];
                    const allowedMimeTypes = ['image', 'application/pdf'];
                    if (!allowedMimeTypes.some(type => file.type.startsWith(type))) {
                        toast.error(translate("web.invalidFileType", "Only images and PDFs allowed."));
                        continue; // Skip to the next file if type is not allowed
                    }
                    fileListHook.push({
                        documentFile: URL.createObjectURL(e.target.files[fileIndex]),
                        name: e.target.files[fileIndex].name,
                        file: e.target.files[fileIndex],
                    });
                }
            }

            setOwnerPassportCopy(fileListHook);
        }
    };

    async function completeDataRequest(id, close, index) {
        // auction/completeauctionadditionaldata
        const formData = new FormData();
        formData.append(`id`, id);
        formData.append(`thisHorseForLocal`, isLocalRegitration);
        formData.append(`iPurchasedThisHorseForMe`, isForMe);
        if (!isForMe) {
            formData.append(`isOtherOwnerRegisteredInEAHS`, isRegisterd);
            if (isRegisterd) {
                if ((selectedOwner?.value == "" || selectedOwner?.value == null) ?? true) {
                    toast.error(translate('web.pleaseSelectOwner', 'Please Select Owner'));
                    return;
                }
                formData.append(`ownerId`, selectedOwner?.ownerRegNo);
            }
            if (!isRegisterd) {
                if (ownerFullName == '') {
                    toast.error(translate('web.pleaseEnterOwnerFullName', 'Please Enter Owner Full Name'));
                    return;
                }
                if (passportCopy.length <= 0) {
                    toast.error(translate('web.pleaseUploadOwnerPassport', 'Please Upload Owner Passport'));
                    return;
                }
                formData.append(`ownerFullName`, ownerFullName);
                formData.append(`ownerPassPortFile`, passportCopy[0]?.file ?? null);
            }
        }


        try {
            setIsSendingRequest(true);
            const res = await axiosInstance.post('auction/completeauctionadditionaldata', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                }
            });
            if (res.status == 200) {
                toast.success(translate('web.yourRequestIsSentSuccessfully', 'Your Request Is Sent Successfully'));
                setIsSendingRequest(false);
                setSelectedOwner({});
                setOwnerPassportCopy([]);
                setOwnerFullName('');
                setWonHorsesData((prev) => {
                    const newData = prev;
                    newData[index].isCompletedAdditionalData = true;
                    return newData;
                })
                close();


            } else {
                setSelectedOwner({});
                setOwnerPassportCopy([]);
                setOwnerFullName('');
                toast.error(translate('web.anExpectedErrorPleaseContactTheAdmin', 'An Expected Error Please Contact The Admin'));
                setIsSendingRequest(false);
                close();

            }

        } catch (error) {
            setSelectedOwner({});
            setOwnerPassportCopy([]);
            setOwnerFullName('');
            toast.error(translate('web.anExpectedErrorPleaseContactTheAdmin', 'An Expected Error Please Contact The Admin'));
            setIsSendingRequest(false);
            close();
        }


    }


    // owner Search
    const [inputValue, setInputValue] = useState('');
    const [selectedOwner, setSelectedOwner] = useState({});
    const [timeoutId, setTimeoutId] = useState(0);


    const promiseOptions = (searchKeyword) =>
        new Promise((resolve) => {
            if (timeoutId > 0) {
                clearTimeout(timeoutId);
            }
            const id = setTimeout(async () => {
                const response = await axiosInstance.post('/StudBook/FilterOwners', { langauge: localStorage.getItem('eahsLang') ?? 'en', ownerId: searchKeyword, skip: 0, take: 10000 })
                const data = await response.data.ownersList;
                const options = data.map((item) => ({
                    label: item.ownerId + " - " + item.name,
                    value: item.id,
                    ownerRegNo: item.ownerId
                }));
                resolve(options);
            }, 1000);

            setTimeoutId(id);
        });

    return (
        <>
            {dataLoaded ? (
                <div className="col-lg-12 col-md-12 col-sm-12 p0">
                    <h3 className="col-lg-12 col-md-12 col-sm-12 bold rtl p0">
                        {translate("web.horsesAcquiredAtAuctions", "Horses Acquired At Auctions")}
                    </h3>
                    <div className="filter-controls rtl mb-3">
                        <div
                            className="row p0"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                                alignItems: "center",
                            }}
                        >
                            <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                                <NetaqInput
                                    type={"text"}
                                    label={translate("web.auctionName", "Auction Name")}
                                    placeholder={translate("web.auctionNamee", "Auction Name")}
                                    onChange={(e) => setSearchByAuctionName(e.target.value)}
                                    value={searchByAuctionName}
                                    name={translate("web.auctionName", "Auction Name")}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 mt-2">
                                <NetaqInput
                                    type={"text"}
                                    label={translate("web.horseName", "Horse Name")}
                                    placeholder={translate("web.horseName", "Horse Name")}
                                    onChange={(e) => setSearchByHorseName(e.target.value)}
                                    value={searchByHorseName}
                                    name={translate("web.horseName", "Horse Name")}
                                />
                            </div>
                            <div className="col-lg-1 col-md-2 col-sm-12 mt-2" style={{ minWidth: 160 }}>
                                <NetaqInput
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e)}
                                    label={translate("web.from", "From")}
                                    name={translate("web.from", "From")}
                                />
                            </div>
                            <div className="col-lg-1 col-md-2 col-sm-12 mt-2" style={{ minWidth: 160 }}>
                                <NetaqInput
                                    type="date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e)}
                                    label={translate("web.to", "To")}
                                    name={translate("web.to", "To")}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 mt-3" style={{ display: "flex" }}>
                                <ActionButton
                                    type={"resetandfetch"}
                                    margin={"5px auto 0 auto"}
                                    label={translate("web.reset", "Reset")}
                                    text={translate("web.reset", "Reset")}
                                    onClick={handleReset}
                                />
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 mt-3" style={{ display: "flex" }}>
                                <ActionButton
                                    type={"submit"}
                                    margin={"5px auto 0 auto"}
                                    label={translate("web.search", "Search")}
                                    text={translate("web.search", "Search")}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />

                    {paginatedData.length > 0 ? (
                        <div className="scrolableTable">
                            <table className="table table-striped table-bordered rtl horseResultTable">
                                <thead className="theadHorseSelect">
                                    <tr>
                                        <th className="text-center">
                                            {translate("web.auctionName", "Auction Name")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.auctionDate", "Auction Date")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.lotNo", "Lot No")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.horseName", "Horse Name")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.price", "Price")}
                                        </th>
                                        <th className="text-center">
                                            {translate("web.action", "Action")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {paginatedData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="rtl" >{item?.auctionName}</td>
                                                <td className="rtl">{formatDate(item?.auctionDate)}</td>
                                                <td className="rtl">{item?.lotNo}</td>
                                                <td className="rtl">{!!item?.horseNo && item?.horseNo + " - "} {item?.horse}</td>
                                                <td className="rtl"> {parseInt(item?.price).toLocaleString('en-US')}  {translate('web.aed', 'AED')} </td>
                                                <td className="rtl">
                                                    {item.isCompletedAdditionalData == false ?
                                                        <Popup modal
                                                            lockScroll
                                                            closeOnDocumentClick={false}
                                                            closeOnEscape
                                                            style={{ background: "red" }}
                                                            contentStyle={{
                                                                width: "100%",
                                                                maxWidth: 1000,
                                                                zIndex: 5000000,
                                                                padding: 30
                                                            }}
                                                            overlayStyle={{ zIndex: 5000 }}
                                                            trigger={<ActionButton type={'normalbtn'} className="tooltip" text={translate('web.completeTheData', 'Complete The Data')} />}
                                                        >
                                                            {(close) => (
                                                                <>
                                                                    <form className="row text-start rtl mx-0" onSubmit={(e) => { e.preventDefault(); completeDataRequest(item.id, close, index) }}>
                                                                        <h5 className="bold px-0 mb-3 rtl">{translate('web.completeDataFor', 'Complete Data For')}  {item?.horse} - {translate("web.lotNo", "Lot No")}: {item?.lotNo} </h5>
                                                                        <div className="row rtl mx-0 pb-2" style={{ border: '1px solid #C89C66', background: 'rgb(252, 250, 247)', borderRadius: 10 }}>
                                                                            <div className="row  my-2">
                                                                                <label>{translate('web.thisHorseFor', 'This Horse For')} <i style={{ color: "red" }}>*</i></label>
                                                                                <div className="col-4" >
                                                                                    <NetaqInput label={translate('web.localRegistration', 'local Registration')} type={'radio'} onChange={(e) => setIsLocalRegistraion(true)} checked={isLocalRegitration} name={'horseType'} />
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <NetaqInput label={translate('web.export', 'export')} type={'radio'} onChange={(e) => setIsLocalRegistraion(false)} checked={!isLocalRegitration} name={'horseType'} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row my-2">
                                                                                <label>{translate('web.iPurchaseThisHorseFor', 'I Purchased This Horse For')} <i style={{ color: "red" }}>*</i></label>
                                                                                <div className="col-4" >
                                                                                    <NetaqInput label={translate('web.forMe', 'For Me')} type={'radio'} onChange={(e) => setIsForMe(true)} checked={isForMe} name={'isForMe'} />
                                                                                </div>
                                                                                <div className="col-4">
                                                                                    <NetaqInput label={translate('web.forOther', 'For Other')} type={'radio'} onChange={(e) => setIsForMe(false)} checked={!isForMe} name={'isForMe'} />
                                                                                </div>
                                                                            </div>
                                                                            {!isForMe &&
                                                                                <>
                                                                                    <div className="row my-2">
                                                                                        <label>{translate('web.isTheOtherOwnerRegisterdInEAHS', 'Is The Other Owner Registerd In EAHS')} <i style={{ color: "red" }}>*</i></label>
                                                                                        <div className="col-4">
                                                                                            <NetaqInput label={translate('web.yes', 'Yes')} type={'radio'} onChange={(e) => setIsRegisterd(true)} required={true} checked={isRegisterd} name={'otherType'} />
                                                                                        </div>
                                                                                        <div className="col-4">
                                                                                            <NetaqInput label={translate('web.no', 'No')} type={'radio'} onChange={(e) => setIsRegisterd(false)} required={true} checked={!isRegisterd} name={'otherType'} />
                                                                                        </div>
                                                                                    </div>
                                                                                    {isRegisterd ?
                                                                                        <>
                                                                                            <div className="col-lg-6 col-md-6 col-sm-12" >
                                                                                                <label className="capatalize_text">
                                                                                                    {translate("web.chooseOwner", "Choose Owner")}
                                                                                                    <i style={{ color: "red" }}>*</i>
                                                                                                </label>
                                                                                                <AsyncSelect
                                                                                                    isClearable
                                                                                                    loadOptions={promiseOptions}
                                                                                                    defaultOptions={false} // Set to true to load initial options
                                                                                                    onInputChange={(newValue) => setInputValue(newValue)}
                                                                                                    inputValue={inputValue}
                                                                                                    loadingMessage={() => translate("web.loading", "Loading")}
                                                                                                    onChange={(selectedItem) => {
                                                                                                        setSelectedOwner(selectedItem);
                                                                                                    }}
                                                                                                    placeholder={translate("web.typeToSearch", "Type To Search") + "..."}
                                                                                                    noOptionsMessage={() => translate("web.noOptions", "No Options")}
                                                                                                />
                                                                                            </div>
                                                                                        </> :
                                                                                        <>
                                                                                            <div className="col-md-6 col-sm-12 mt-2">
                                                                                                <NetaqInput label={translate('web.ownerFullName', 'Owner Full Name')} type={'text'} onChange={(e) => setOwnerFullName(e.target.value)} required={true} name={'ownerFullName'} />
                                                                                            </div>
                                                                                            <div className="col-md-6 col-sm-12 mt-2">
                                                                                                <label>{translate('web.ownerPassportCopy', 'Owner Passport Copy')} <span style={{ color: 'red' }}>*</span> </label>
                                                                                                <UploadFile
                                                                                                    key={'anykey' + index}
                                                                                                    initialFiles={passportCopy}
                                                                                                    text={translate('web.ownerPassportCopy', 'Owner Passport Copy')}
                                                                                                    ismultiFile={false}
                                                                                                    isServer={false}
                                                                                                    accept="image/*,application/pdf"
                                                                                                    name={`ownerPassportCopy`}
                                                                                                    className="text-center "
                                                                                                    onChange={(e) => handleUploadFile(e)}
                                                                                                />

                                                                                            </div>
                                                                                        </>}
                                                                                </>
                                                                            }

                                                                        </div>
                                                                        <div className="ms-auto col-md-1 col-sm-12 text-center mt-3">
                                                                            <ActionButton type={'btn'} text={translate('web.close', 'Close')} onClick={() => { setOwnerFullName(''); setOwnerPassportCopy([]); close(); }} />
                                                                        </div>
                                                                        <div className="col-md-2 col-sm-12 text-center mt-3">
                                                                            {isSendingRequest ? <Loader /> :
                                                                                <ActionButton type={'submit'} text={translate('web.submit', 'Submit')} onClick={() => (console.log('as'))} />
                                                                            }
                                                                        </div>
                                                                    </form>
                                                                </>
                                                            )}
                                                        </Popup>
                                                        :translate('web.completed' , 'Completed')
                                                    }
                                                </td>
                                            </tr>)
                                    })}
                                </tbody>
                            </table>

                            <div className="pagination-buttons rtl" style={{ display: "flex", alignItems: "center" }}>
                                <ActionButton
                                    type={"normalbtn"}
                                    className="btn"
                                    text={translate("web.prev", "Prev")}
                                    margin={"auto 10px"}
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                />
                                <span>{`${currentPage} / ${Math.ceil(filteredData.length / itemsPerPage)}`}</span>
                                <ActionButton
                                    type={"normalbtn"}
                                    className="btn"
                                    text={translate("web.next", "Next")}
                                    margin={"auto 10px"}
                                    onClick={handleNextPage}
                                    disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="text-center text-danger">
                            {translate("web.nodataFound", "No Result Found")}
                        </div>
                    )}
                </div>
            ) : (
                <Loader />
            )}
        </>
    );
}
