import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from './TranslationText';


const HorseDetailsPedigreeChart = ({ hierarchy, pageName }) => {
  const navigate = useNavigate();

  // if(hierarchy?.horseRegistrationNumber === null || hierarchy?.horseRegistrationNumber === "null" || hierarchy?.horseRegistrationNumber === ""  ){
  //   navigate('/404-not-found');
  // }

  const { translate } = useTranslate();
  return (
    <div className="d-flex-js-center-align-items-center" >
      {!!hierarchy ?
        <div className="tree-container">
          <div className="first-level">
            <span className="horseNamePedigree">
              {hierarchy?.horseRegistrationNumber ?? ""}{" "}
              {!!(hierarchy?.horseRegistrationNumber) && "-"} {hierarchy.horseName}
              <span className="PedigreeChart"></span>
            </span>
          </div>
          <div className="first-level-spacer">
            <div className="upspacerPedigree"></div>
            <div className="downspacerPedigree"></div>
          </div>
          <div className="second-level">
            <div className="second-level-dad">
              {!!hierarchy.sireRegistrationNumber ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.sireId}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.sireRegistrationNumber}
                  {!!(hierarchy.sireRegistrationNumber) && "-"}

                  {hierarchy.sireName}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.sireName != null && hierarchy.sireName !== "" ? (
                    hierarchy.sireName
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-mom">
              {!!hierarchy.damRegistrationNumber ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.damId}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.damRegistrationNumber}
                  {!!(hierarchy.damRegistrationNumber) && "-"}
                  {hierarchy.damName}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.damName != null && hierarchy.damName !== "" ? (
                    hierarchy.damName
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
          </div>
          <div className="second-level-spacer">
            <div className="second-level-spacer-container-mom">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
          </div>
          <div className="third-level">
            <div className="second-level-dad-first">
              {!!hierarchy.siresSireRegistrationNumber ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.siresSireId}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.siresSireRegistrationNumber}
                  {!!(hierarchy.siresSireRegistrationNumber) && "-"}
                  {hierarchy.siresSireName}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.siresSireName != null &&
                    hierarchy.siresSireName !== "" ? (
                    hierarchy.siresSireName
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-mom-first">
              {!!hierarchy.sireDamRegistrationNumber ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.sireDamId}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.sireDamRegistrationNumber}
                  {!!(hierarchy.sireDamRegistrationNumber) && "-"}
                  {hierarchy.sireDamName}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.sireDamName != null &&
                    hierarchy.sireDamName !== "" ? (
                    hierarchy.sireDamName
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-dad-second">
              {!!hierarchy.damsSireRegistrationNumber ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.damsSireId}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.damsSireRegistrationNumber}
                  {!!(hierarchy.damsSireRegistrationNumber) && "-"}
                  {hierarchy.damsSireName}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.damsSireName != null &&
                    hierarchy.damsSireName !== "" ? (
                    hierarchy.damsSireName
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-mom-second">
              {!!hierarchy.damsDamRegistrationNumber ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.damsDamId}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.damsDamRegistrationNumber}
                  {!!(hierarchy.damsDamRegistrationNumber) && "-"}
                  {hierarchy.damsDamName}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.damsDamName != null &&
                    hierarchy.damsDamName !== "" ? (
                    hierarchy.damsDamName
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
          </div>

          <div className="third-level-spacer">
            <div className="second-level-spacer-container-mom-first">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-first">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-mom-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
          </div>

          <div className="fourth-level">
            <div className="second-level-dad-first-first">
              {!!hierarchy.fatherARegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherAIdLevel3}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherARegistrationNumberLevel3}
                  {!!(hierarchy.fatherARegistrationNumberLevel3) && "-"}
                  {hierarchy.fatherANameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherANameLevel3 != null &&
                    hierarchy.fatherANameLevel3 !== "" ? (
                    hierarchy.fatherANameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-mom-first-second">
              {!!hierarchy.motherARegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherAIdLevel3}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherARegistrationNumberLevel3}
                  {!!(hierarchy.motherARegistrationNumberLevel3) && "-"}
                  {hierarchy.motherANameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherARegistrationNumberLevel3}{" "}
                  {!!hierarchy.motherARegistrationNumberLevel3 && "-"}
                  {hierarchy.motherANameLevel3}&nbsp;
                  {/* {hierarchy.motherANameLevel3 != null &&
                    hierarchy.motherANameLevel3 !== "" ? (
                    hierarchy.motherANameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )} */}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-dad-second-first">
              {!!hierarchy.fatherBRegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherBIdLevel3}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherBRegistrationNumberLevel3}
                  {!!(hierarchy.fatherBRegistrationNumberLevel3) && "-"}
                  {hierarchy.fatherBNameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherBNameLevel3 != null &&
                    hierarchy.fatherBNameLevel3 !== "" ? (
                    hierarchy.fatherBNameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-mom-second-second">
              {!!hierarchy.motherBRegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherBIdLevel3}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherBRegistrationNumberLevel3}
                  {!!(hierarchy.motherBRegistrationNumberLevel3) && "-"}
                  {hierarchy.motherBNameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherBNameLevel3 != null &&
                    hierarchy.motherBNameLevel3 !== "" ? (
                    hierarchy.motherBNameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-dad-third-first">
              {!!hierarchy.fatherCRegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherCIdLevel3}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherCRegistrationNumberLevel3}
                  {!!(hierarchy.fatherCRegistrationNumberLevel3) && "-"}
                  {hierarchy.fatherCNameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherCNameLevel3 != null &&
                    hierarchy.fatherCNameLevel3 !== "" ? (
                    hierarchy.fatherCNameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-mom-third-second">
              {!!hierarchy.motherCRegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherCIdLevel3}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherCRegistrationNumberLevel3}
                  {!!(hierarchy.motherCRegistrationNumberLevel3) && "-"}
                  {hierarchy.motherCNameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherCNameLevel3 != null &&
                    hierarchy.motherCNameLevel3 !== "" ? (
                    hierarchy.motherCNameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-first">
              {!!hierarchy.fatherDRegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherDIdLevel3}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherDRegistrationNumberLevel3}
                  {!!(hierarchy.fatherDRegistrationNumberLevel3) && "-"}
                  {hierarchy.fatherDNameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherDNameLevel3 != null &&
                    hierarchy.fatherDNameLevel3 !== "" ? (
                    hierarchy.fatherDNameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
            <div className="second-level-mom-fourth-second">
              {!!hierarchy.motherDRegistrationNumberLevel3 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherDIdLevel3}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherDRegistrationNumberLevel3}
                  {!!(hierarchy.motherDRegistrationNumberLevel3) && "-"}
                  {hierarchy.motherDNameLevel3}
                  <span className="PedigreeChart"></span>
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherDNameLevel3 != null &&
                    hierarchy.motherDNameLevel3 !== "" ? (
                    hierarchy.motherDNameLevel3
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                  <span className="PedigreeChart"></span>
                </span>
              )}
            </div>
          </div>

          <div className="third-level-spacer">
            <div className="second-level-spacer-container-mom-first">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-first">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-mom-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
            <div className="second-level-spacer-container-dad-second">
              <div className="upspacerPedigree"></div>
              <div className="downspacerPedigree"></div>
            </div>
          </div>

          <div className="fourth-level">
            <div className="second-level-mom-first-first">
              {!!hierarchy.fatherARegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherAIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherARegistrationNumberLevel4}
                  {!!(hierarchy.fatherARegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherANameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherANameLevel4 != null &&
                    hierarchy.fatherANameLevel4 !== "" ? (
                    hierarchy.fatherANameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>

            <div className="second-level-dad-first-second">
              {!!hierarchy.motherARegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherAIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherARegistrationNumberLevel4}
                  {!!(hierarchy.motherARegistrationNumberLevel4) && "-"}
                  {hierarchy.motherANameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherANameLevel4 != null &&
                    hierarchy.motherANameLevel4 !== "" ? (
                    hierarchy.motherANameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-mom-second-first">
              {!!hierarchy.fatherBRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherBIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherBRegistrationNumberLevel4}
                  {!!(hierarchy.fatherBRegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherBNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherBNameLevel4 != null &&
                    hierarchy.fatherBNameLevel4 !== "" ? (
                    hierarchy.fatherBNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-second-second">
              {!!hierarchy.motherBRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherBIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherBRegistrationNumberLevel4}
                  {!!(hierarchy.motherBRegistrationNumberLevel4) && "-"}
                  {hierarchy.motherBNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherBNameLevel4 != null &&
                    hierarchy.motherBNameLevel4 !== "" ? (
                    hierarchy.motherBNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-mom-third-first">
              {!!hierarchy.fatherCRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherCIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherCRegistrationNumberLevel4}
                  {!!(hierarchy.fatherCRegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherCNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherCNameLevel4 != null &&
                    hierarchy.fatherCNameLevel4 !== "" ? (
                    hierarchy.fatherCNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-third-second">
              {!!hierarchy.motherCRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherCIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherCRegistrationNumberLevel4}
                  {!!(hierarchy.motherCRegistrationNumberLevel4) && "-"}
                  {hierarchy.motherCNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherCNameLevel4 != null &&
                    hierarchy.motherCNameLevel4 !== "" ? (
                    hierarchy.motherCNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-mom-fourth-first">
              {!!hierarchy.fatherDRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherDIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherDRegistrationNumberLevel4}
                  {!!(hierarchy.fatherDRegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherDNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherDNameLevel4 != null &&
                    hierarchy.fatherDNameLevel4 !== "" ? (
                    hierarchy.fatherDNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.motherDRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherDIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherDRegistrationNumberLevel4}
                  {!!(hierarchy.motherDRegistrationNumberLevel4) && "-"}
                  {hierarchy.motherDNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherDNameLevel4 != null &&
                    hierarchy.motherDNameLevel4 !== "" ? (
                    hierarchy.motherDNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.fatherERegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherEIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherERegistrationNumberLevel4}
                  {!!(hierarchy.fatherERegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherENameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherENameLevel4 != null &&
                    hierarchy.fatherENameLevel4 !== "" ? (
                    hierarchy.fatherENameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.motherERegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherEIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherERegistrationNumberLevel4}
                  {!!(hierarchy.motherERegistrationNumberLevel4) && "-"}
                  {hierarchy.motherENameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherENameLevel4 != null &&
                    hierarchy.motherENameLevel4 !== "" ? (
                    hierarchy.motherENameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.fatherFRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherFIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherFRegistrationNumberLevel4}
                  {!!(hierarchy.fatherFRegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherFNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherFNameLevel4 != null &&
                    hierarchy.fatherFNameLevel4 !== "" ? (
                    hierarchy.fatherFNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.motherFRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherFIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherFRegistrationNumberLevel4}
                  {!!(hierarchy.motherFRegistrationNumberLevel4) && "-"}
                  {hierarchy.motherFNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherFNameLevel4 != null &&
                    hierarchy.motherFNameLevel4 !== "" ? (
                    hierarchy.motherFNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.fatherGRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherGIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherGRegistrationNumberLevel4}
                  {!!(hierarchy.fatherGRegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherGNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherGNameLevel4 != null &&
                    hierarchy.fatherGNameLevel4 !== "" ? (
                    hierarchy.fatherGNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.motherGRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherGIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherGRegistrationNumberLevel4}
                  {!!(hierarchy.motherGRegistrationNumberLevel4) && "-"}
                  {hierarchy.motherGNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherGNameLevel4 != null &&
                    hierarchy.motherGNameLevel4 !== "" ? (
                    hierarchy.motherGNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.fatherHRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.fatherHIdLevel4}`}
                  className="horseNamePedigree color_Blue"
                >
                  {hierarchy.fatherHRegistrationNumberLevel4}
                  {!!(hierarchy.fatherHRegistrationNumberLevel4) && "-"}
                  {hierarchy.fatherHNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree color_Blue">
                  {hierarchy.fatherHNameLevel4 != null &&
                    hierarchy.fatherHNameLevel4 !== "" ? (
                    hierarchy.fatherHNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
            <div className="second-level-dad-fourth-second">
              {!!hierarchy.motherHRegistrationNumberLevel4 ? (
                <Link
                  to={`/horseDetails?horseId=${hierarchy.motherHIdLevel4}`}
                  className="horseNamePedigree perpul"
                >
                  {hierarchy.motherHRegistrationNumberLevel4}
                  {!!(hierarchy.motherHRegistrationNumberLevel4) && "-"}
                  {hierarchy.motherHNameLevel4}
                </Link>
              ) : (
                <span className="horseNamePedigree perpul">
                  {hierarchy.motherHNameLevel4 != null &&
                    hierarchy.motherHNameLevel4 !== "" ? (
                    hierarchy.motherHNameLevel4
                  ) : (
                    <React.Fragment> &nbsp;</React.Fragment>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
        : <div className="bold text-center danger">
          {translate('web.thisHorseIsNotExist', 'This Horse Is Not Exist')}
        </div>
      }
    </div>
  );
};

export default HorseDetailsPedigreeChart;
