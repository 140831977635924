import React, { useEffect, useState } from 'react';
import ActionButton from '../components/ActionButton';
import { useTranslate } from '../components/TranslationText';
import { useSelector } from 'react-redux';
import axios from '../axios/axiosUmbraco';
import { formatDate } from '../helper/formatDate';
import Album from '../components/Album';
import Lightbox from 'react-18-image-lightbox';
import NetaqInput from '../components/NetaqInput';

const GalleryId = process.env.REACT_APP_GALLERY_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function GalleryPage() {
    const { translate } = useTranslate();
    const { currentLanguage } = useSelector((state) => state.studBookSlice);

    const [gallery, setGalleryItems] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const albumsPerPage = 5;

    function getCurrentLanguageUmbraco() {
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang === "en") return "en-US";
        else return "ar-AE";
    }
    const [seasonList, setSeasonList] = useState([]);
    const [searchKeyWord, setSearchKeyWord] = useState("");
    const [selectedSeason, setSellectedSeason] = useState({
        label: translate('web.season', 'Season'),
        value: null,
    });

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            const umbraco = await axios.get(
                `/umbraco/delivery/api/v1/content?fetch=children:${GalleryId}&skip=0&take=2000`,
                {
                    headers: {
                        "accept-language": getCurrentLanguageUmbraco(),
                    },
                }
            );

            const sortedData = umbraco.data.items.sort((a, b) => new Date(b.properties.eventStartDate) - new Date(a.properties.eventStartDate));
            const formattedData = sortedData.map((item) => ({
                albumName: item?.properties?.albumName.toLowerCase() ?? "",
                albumMedia: item?.properties?.albumCover ?? [],
                albumId: item?.id ?? '',
                albumStartDate: formatDate(item?.properties?.eventStartDate) ?? '',
                albumEndDate: formatDate(item?.properties?.eventEndDate) ?? "",
                eventId: item?.properties?.eventId ?? "",
                season: item?.properties?.season ? item?.properties?.season : "2024-2025"
            }));
            setGalleryItems(formattedData);
            setDisplayedItem(formattedData)
            const uniqueFormattedData = [...new Set(formattedData.map(x => x.season))]
                .map(season => ({ label: season, value: season, text: season }));
            setSeasonList(uniqueFormattedData);
            setLoaded(true);
            setIsLoading(false);
        }
        getData();
    }, [currentLanguage]);

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    // light Box
    const [imageslightbox, setImageslightbox] = useState([]);
    const [index, setIndex] = useState(-1);

    const currentImage = imageslightbox[index];
    const nextIndex = (index + 1) % imageslightbox.length;
    const nextImage = imageslightbox[nextIndex] || currentImage;
    const prevIndex = (index + imageslightbox.length - 1) % imageslightbox.length;
    const prevImage = imageslightbox[prevIndex] || currentImage;

    const handleClick = (index, albumMedia) => {
        setIndex(index);
        setImageslightbox(albumMedia.map((media) => MediaBaseURL + media.url));
    };
    const handleClose = () => setIndex(-1);

    useEffect(() => {
        console.log('searchKeyWord', searchKeyWord)
        if (selectedSeason?.value != null && searchKeyWord.trim() != '') {
            setDisplayedItem((prev) => {
                let previousItems = gallery;
                let filterdBySeason = previousItems.filter(x => x.season == selectedSeason.value);
                let filterdByKeyword = filterdBySeason.filter(x => x.albumName.includes(searchKeyWord.toLowerCase()))
                return filterdByKeyword;
            })
            setPage(0);
        } else if (selectedSeason?.value != null) {
            setDisplayedItem((prev) => {
                let previousItems = gallery;
                let filterdBySeason = previousItems.filter(x => x.season == selectedSeason.value);
                return filterdBySeason;
            })
            setPage(0);
        } else if (searchKeyWord.trim() != '') {
            setDisplayedItem((prev) => {
                let previousItems = gallery;
                let filterdByKeyword = previousItems.filter(x => x.albumName.includes(searchKeyWord.toLowerCase()))
                return filterdByKeyword;
            })
            setPage(0);
        } else {
            setDisplayedItem(gallery);
            setPage(0);
            return;
        }

    }, [searchKeyWord, selectedSeason])



    const [displayedItems, setDisplayedItem] = useState(gallery);
    const displayedAlbums = displayedItems.slice(0, (page + 1) * albumsPerPage);
    const currentlang = localStorage.getItem('eahsLang') ?? 'en';

    return (
        <div className='container-fluid px75'>
            <div className='row m0 rtl' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                    <div className="col-lg-1 col-md-1 col-sm-12 mt-1" style={{ minWidth: currentlang == 'en' ? "20%" : '15%' }}>
                        <h3 className='NewsPostTitle bold' style={{ marginTop: 'unset' }}>
                            {translate('web.gallery', 'Gallery')}
                        </h3>
                    </div>
                <div className='col-lg-3 col-md-3 col-sm-12 mt-1' >
                    <NetaqInput type={'select'} isClearable={true} name={'season'} placeholder={translate('web.season', 'Season')} label={translate('web.season', 'Season')} listSelect={seasonList} value={selectedSeason} hideLable={true} onChange={(e) => setSellectedSeason(e)} />
                </div>
                <div className='col-lg-3 col-md-3 col-sm-12 mt-1' >
                    <NetaqInput type={'text'} style={{ height: 38 }} name={'search'} hideLable={true} placeholder={translate('web.search', 'Search').toUpperCase()} label={translate('web.search', 'Search')} value={searchKeyWord} onChange={(e) => setSearchKeyWord(e.target.value)} />
                </div>
            </div>
            {loaded && displayedAlbums.map((item, index) => (
                <Album handleClick={handleClick} item={item} index={index} key={index} />
            ))}
            {loaded && displayedAlbums.length < displayedItems.length && (
                <div className="row m0 rtl text-center">
                    <ActionButton label={translate('web.loadMore', 'Load More')} type={'loadmore'} onClick={loadMore} disabled={isLoading} className="loadMoreBtn" />
                </div>
            )}

            {!!currentImage && (
                <>
                    <Lightbox
                        mainSrc={imageslightbox[index]}
                        nextSrc={imageslightbox[(index + 1) % imageslightbox.length]}
                        prevSrc={imageslightbox[(index + imageslightbox.length - 1) % imageslightbox.length]}
                        onCloseRequest={handleClose}
                        onMovePrevRequest={() =>
                            setIndex((index + imageslightbox.length - 1) % imageslightbox.length)
                        }
                        onMoveNextRequest={() =>
                            setIndex((index + 1) % imageslightbox.length)
                        }
                        wrapperClassName='galleryContainer'
                    />
                </>
            )}
        </div>
    );
}
