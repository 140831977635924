import React, { useEffect } from "react";
import { useTranslate } from "../components/TranslationText";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchGetAuctionByIdthunk } from "../Redux/AuctionEntry/AuctionEntryAction";

export default function AuctionEntrySubmissionSuccessful() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { translate } = useTranslate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const auctionId = searchParams.has("auctionId")
    ? searchParams.get("auctionId")
    : null;

  const dispatch = useDispatch();

  const { auctionEntryLoading, auctionByIdData } = useSelector(
    (state) => state.auctionEntrySlice
  );

  useEffect(() => {
    dispatch(FetchGetAuctionByIdthunk(auctionId));
  }, []);

  return (
    <div className="container-fluid container-fluid-padding row">
      {
        // title
      }
      <div className="col-md-12 col-sm-12  mt-15 mb-25">
        <h1 className="fontWeight">{auctionByIdData.name}</h1>
      </div>
      {
        //text
      }
      <div className="col-md-12 col-sm-12 text-center">
        <h1 className="color-green">
          {translate(
            "web.paymentCompletedSuccessful",
            "Payment Completed Successful!"
          )}
        </h1>
        <Link
          type="button"
          className="btn buttonformshowentrycard height46 linkbtn mt-50"
          to={`/profile`}
        >
          <span className="ml-10 margin-left-unset">
            <i class="fa-solid fa-arrow-left fa-lg marginrightleft-10"></i>
            {translate("web.back", "Back")}
          </span>
        </Link>
      </div>
    </div>
  );
}
