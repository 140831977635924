import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useLazyLoad from './useLazyLoad';
import { convertToCamelCase } from '../helper/convertToCamelCase';
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function LazyLoadImage({ image, albumId, albumName, counter,onClick, translate , index  , startDate}) {
    const [ref, isIntersecting] = useLazyLoad();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <a className="mediaCard"  style={{width: (index == 0 && windowWidth > 768) ? '100%' :(
            ((index == 1  && windowWidth > 768) || (index == 2  && windowWidth > 768))? '25%':
            (index < 7  && windowWidth > 768) ? '20%': 
            'unset')}} ref={ref}>
            {isIntersecting && (
                <img src={MediaBaseURL + image.url} onClick={onClick} alt="ChangeMe" style={{cursor:'pointer' , height: (index == 0 && windowWidth > 768) ? 'unset' :(
                    (index == 1 || index == 2)? 'unset':
                    200) , width: (index == 0  && windowWidth > 768) ? '100%' :(
                        (index == 1 || index == 2)? '100%':
                        'unset')}} />
            )}
            {counter === 0 && (
                <Link to={`/album-details?id=${albumId}`} className="mediaOverLay text-right-rtl roboto-bold">
                    <span style={{textTransform:'capitalize'}}>
                    {albumName}
                    </span>
                    <span style={{ width: '100%', display: 'block', textAlign: 'left' }} className="mediaViewMore roboto-regular">
                    <i
                        className="fa fa-calendar C89C66"
                        style={{ margin: "0 5px" }}
                    ></i>
                    <span> {startDate}</span>
                    </span>
                </Link>
            )}
        </a>
    );
}
