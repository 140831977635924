export function isLessThanOneYearOld(date) {
  const currentDate = new Date();

  // Calculate the difference in years
  const yearsDifference = currentDate.getFullYear() - date.getFullYear();

  // If the year difference is more than 1, return false
  if (yearsDifference > 1) {
    return false;
  }

  // If the year difference is 1, check if the current date is before the birth date's anniversary
  if (yearsDifference === 1) {
    const lastBirthday = new Date(date);

    // console.log("1", lastBirthday); // true if birthDate is less than a year ago, otherwise false

    lastBirthday.setFullYear(currentDate.getFullYear());
    // console.log("2", lastBirthday); // true if birthDate is less than a year ago, otherwise false

    return currentDate < lastBirthday;
  }

  // If the year difference is 0, the birth date is within the last year
  return true;
}
