import { createSlice } from "@reduxjs/toolkit";
import {
  FetchAddEditAuctionHorsethunk,
  FetchAuctionEntrythunk,
  FetchAuctionHorsesRequeststhunk,
  FetchCheckOwnerHaseHorseInAuctionEntrythunk,
  FetchCountOwnerHaseHorseInAuctionEntrythunk,
  FetchDeleteAuctionHorsethunk,
  FetchGetAuctionByIdthunk,
  FetchGetOwnerAuctionHorsesCompletedthunk,
  FetchUpdateAuctionHorsesthunk,
  FetchUpdateListAuctionHorsesIdsthunk,
} from "./AuctionEntryAction";

const initialState = {
  auctionEntryData: [],
  auctionHorsesRequestsData: [],
  checkOwnerHaseHorseInAuctionEntryData: {},
  getOwnerAuctionHorsesCompletedData: [],
  auctionByIdData: {},
  countOwnerHaseHorseInAuctionEntryData: 0,
  auctionEntryLoading: false,
  auctionEntryError: null,
  toasterMessageAuctionEntry: null,
  navigateStatus: false,
};

const AuctionEntrySlice = createSlice({
  name: "AuctionEntry",
  initialState,
  reducers: {
    resetToasterMessageAuctionEntry: (state) => {
      state.toasterMessageAuctionEntry = null;
      //state.navigateStatus = true;
    },
    resetNavigateStatus: (state) => {
      //state.navigateStatus = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchAuctionEntrythunk.pending, (state, action) => {
      state.auctionEntryLoading = true;
    });
    builder.addCase(FetchAuctionEntrythunk.fulfilled, (state, action) => {
      state.auctionEntryLoading = false;
      state.auctionEntryData = action.payload.result;
    });
    builder.addCase(FetchAuctionEntrythunk.rejected, (state, action) => {
      state.auctionEntryLoading = false;
      state.auctionEntryError = action.payload;
      state.auctionEntryData = [];
    });

    builder.addCase(FetchAddEditAuctionHorsethunk.pending, (state, action) => {
      state.auctionEntryLoading = true;
    });
    builder.addCase(
      FetchAddEditAuctionHorsethunk.fulfilled,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.toasterMessageAuctionEntry = "save";
      }
    );
    builder.addCase(FetchAddEditAuctionHorsethunk.rejected, (state, action) => {
      state.auctionEntryLoading = false;
      state.auctionEntryError = action.payload;
      state.toasterMessageAuctionEntry = null;
    });

    builder.addCase(
      FetchAuctionHorsesRequeststhunk.pending,
      (state, action) => {
        state.auctionEntryLoading = true;
      }
    );
    builder.addCase(
      FetchAuctionHorsesRequeststhunk.fulfilled,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.auctionHorsesRequestsData = action.payload.result;
      }
    );
    builder.addCase(
      FetchAuctionHorsesRequeststhunk.rejected,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.auctionEntryError = action.payload;
        state.auctionHorsesRequestsData = [];
      }
    );

    builder.addCase(FetchDeleteAuctionHorsethunk.pending, (state, action) => {
      state.auctionEntryLoading = true;
    });
    builder.addCase(FetchDeleteAuctionHorsethunk.fulfilled, (state, action) => {
      state.auctionEntryLoading = false;
      state.toasterMessageAuctionEntry = "save";
    });
    builder.addCase(FetchDeleteAuctionHorsethunk.rejected, (state, action) => {
      state.auctionEntryLoading = false;
      state.auctionEntryError = action.payload;
      state.toasterMessageAuctionEntry = null;
    });

    builder.addCase(
      FetchUpdateListAuctionHorsesIdsthunk.pending,
      (state, action) => {
        state.auctionEntryLoading = true;
      }
    );
    builder.addCase(
      FetchUpdateListAuctionHorsesIdsthunk.fulfilled,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.toasterMessageAuctionEntry = "save";
      }
    );
    builder.addCase(
      FetchUpdateListAuctionHorsesIdsthunk.rejected,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.auctionEntryError = action.payload;
        state.toasterMessageAuctionEntry = null;
      }
    );

    builder.addCase(
      FetchCheckOwnerHaseHorseInAuctionEntrythunk.pending,
      (state, action) => {
        state.auctionEntryLoading = true;
      }
    );
    builder.addCase(
      FetchCheckOwnerHaseHorseInAuctionEntrythunk.fulfilled,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.checkOwnerHaseHorseInAuctionEntryData = action.payload.result;
      }
    );
    builder.addCase(
      FetchCheckOwnerHaseHorseInAuctionEntrythunk.rejected,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.auctionEntryError = action.payload;
        state.checkOwnerHaseHorseInAuctionEntryData = {};
      }
    );

    builder.addCase(
      FetchCountOwnerHaseHorseInAuctionEntrythunk.pending,
      (state, action) => {
        state.auctionEntryLoading = true;
      }
    );
    builder.addCase(
      FetchCountOwnerHaseHorseInAuctionEntrythunk.fulfilled,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.countOwnerHaseHorseInAuctionEntryData = action.payload.result;
      }
    );
    builder.addCase(
      FetchCountOwnerHaseHorseInAuctionEntrythunk.rejected,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.auctionEntryError = action.payload;
        state.countOwnerHaseHorseInAuctionEntryData = 0;
      }
    );

    builder.addCase(
      FetchGetOwnerAuctionHorsesCompletedthunk.pending,
      (state, action) => {
        state.auctionEntryLoading = true;
      }
    );
    builder.addCase(
      FetchGetOwnerAuctionHorsesCompletedthunk.fulfilled,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.getOwnerAuctionHorsesCompletedData = action.payload.result;
      }
    );
    builder.addCase(
      FetchGetOwnerAuctionHorsesCompletedthunk.rejected,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.auctionEntryError = action.payload;
        state.getOwnerAuctionHorsesCompletedData = [];
      }
    );

    builder.addCase(FetchUpdateAuctionHorsesthunk.pending, (state, action) => {
      state.auctionEntryLoading = true;
    });
    builder.addCase(
      FetchUpdateAuctionHorsesthunk.fulfilled,
      (state, action) => {
        state.auctionEntryLoading = false;
        state.toasterMessageAuctionEntry = "save";
      }
    );
    builder.addCase(FetchUpdateAuctionHorsesthunk.rejected, (state, action) => {
      state.auctionEntryLoading = false;
      state.auctionEntryError = action.payload;
      state.toasterMessageAuctionEntry = null;
    });

    builder.addCase(FetchGetAuctionByIdthunk.pending, (state, action) => {
      state.auctionEntryLoading = true;
    });
    builder.addCase(FetchGetAuctionByIdthunk.fulfilled, (state, action) => {
      state.auctionEntryLoading = false;
      state.auctionByIdData = action.payload.result;
    });
    builder.addCase(FetchGetAuctionByIdthunk.rejected, (state, action) => {
      state.auctionEntryLoading = false;
      state.auctionEntryError = action.payload;
      state.auctionByIdData = {};
    });
  },
});

export const { resetToasterMessageAuctionEntry, resetNavigateStatus } =
  AuctionEntrySlice.actions;
export default AuctionEntrySlice.reducer;
