import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { showFirst22Words } from "../helper/showFirstSixWords";
import { translateMonthToArabic } from "../helper/translateDayToArabic";
import axios from "../axios/axios";
import { formatDateTimeToString } from "../helper/formatDateTimeToString";
import { getEventType } from "../helper/getEventLink";
import ComingSoon from "../components/ComingSoon";
import { useTranslate } from "../components/TranslationText";
import { convertToCamelCase } from "../helper/convertToCamelCase";

const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function OpendBooking({isAds=false , isMobileWebView=false}) {
  const [openeningEvents, setOpeningEvents] = useState([]);
  const navigation = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const {translate} = useTranslate()
  async function getOpeningEvents() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    const response = await axios.get(
      `bookingtable/getbookingtablesevents?Langauge=${lang}&isAdvertisingPackage=${isAds}`
    );

    const formattedData = response.data.result.map((item, index) => ({
      endate: item.endate,
      eventName: isAds ?  translate('web.advertisPackage','Advertis Package')+ " " + `(${translate(`web.${convertToCamelCase(item.type)}`, item.type)})` :item.eventName,
      id: item.id,
      startDate: item.startDate,
      templateId: item.templateId,
      logo:
        item.logo == null
          ? `${BaseURL}/assets/img/horseauction.png`
          : item.logo,
      type: translate(`web.${convertToCamelCase(item.type)}`, item.type),
    }));

    if(formattedData.length == 1){
      navigation(isAds? `/${isMobileWebView?"m-":""}ads-book-table?eventid=${formattedData[0].id}` : `/${isMobileWebView?"m-":""}book-table?eventid=${formattedData[0].id}`)
      return;
    }

     

    setOpeningEvents(formattedData);
    setLoaded(true);

     
    return response.data.result;
  }
  function getCurrentLanguageUmbraco() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return "en-US";
    else return "ar-AE";
  }

  useEffect(() => {
    getOpeningEvents();
  }, []);

  return (
    loaded &&
    openeningEvents.length > 0 ?
    <>
        <div className='container-fluid p-0-10w'>
            <div className='row rtl'>
                {openeningEvents.map((item, index) => (
                    <>
                        <Link to={isAds?`/ads-book-table?eventid=${item.id}`:`/book-table?eventid=${item.id}`} className="col-lg-4 col-md-6 col-sm-12 ">
                            <div  className="custom_card">
                                <div
                                    className="custom_card_img_container position-relative"
                                    style={{overflow:'hidden' , display:'flex' , justifyContent:'center' , alignItems:'center'}}

                                >
                                    <img
                                        src={item.logo}
                                        alt=""
                                        style={{height:260 , width:'unset' , transform:'scale(1.3)'}}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                                        }}
                                    />
                                </div>
                                <div className="image_black_over_lay" style={{zIndex:-1 , background:'#fff' , border:'#C89C66 1px solid'}}></div>

                                <div className="image_over_lay ">{item.type}</div>
                               
                                {/* <div className="card_paragraph">{item.eventName}</div> */}
                            </div>
                            <div className="card_title_row justRotate">
                                    <div className="card_title" >{item.eventName}</div>
                                    <div className="card_date">
                                        <i
                                            className="fa fa-calendar color-green mx-1"
                                            aria-hidden="true"
                                        ></i>
                                        {formatDateTimeToString(item.startDate , getCurrentLanguageUmbraco())}
                                    </div>
                                </div>
                        </Link>
                    </>
                ))}
            </div>
        </div>
    </>:
    <ComingSoon />
  );
}
