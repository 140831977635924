export function isMoreThanDays(birthDate, days) {
    const currentDate = new Date();

    const threeMonthsAgo = new Date();

    var months = days / 30.44;

    threeMonthsAgo.setMonth(currentDate.getMonth() - Math.round(months));

    if (birthDate >= threeMonthsAgo) {
        return false;
    } else {
        return true;
    }
}
