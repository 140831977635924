import React, { useEffect } from "react";
import { useTranslate } from "../components/TranslationText";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchGetServicesRequestByIdthunk } from "../Redux/ServicesRequests/ServicesRequestsAction";

export default function ServicesRequestsPaymentSuccessful() {
  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { getServicesRequestByIdData } = useSelector(
    (state) => state.servicesRequestsSlice
  );

  //translate
  const { translate } = useTranslate();

  //Location
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceRequestId")
    ? searchParams.get("serviceRequestId")
    : null;

  //dispatch
  const dispatch = useDispatch();

  //Effect
  useEffect(() => {
    let params = {
      language: currentLanguage,
      id: serviceId,
    };

    dispatch(FetchGetServicesRequestByIdthunk(params));
  }, []);

  console.log("getServicesRequestByIdData", getServicesRequestByIdData);
  return (
    <div className="container-fluid container-fluid-padding row">
      {
        // title
      }
      <div className="col-md-12 col-sm-12  mt-15 mb-25">
        <h1 className="fontWeight">
          {getServicesRequestByIdData.serviceDefinitionName}
        </h1>
      </div>
      {
        //text
      }
      <div className="col-md-12 col-sm-12 text-center">
        <h1 className="color-green">
          {translate(
            "web.paymentCompletedSuccessful",
            "Payment Completed Successful!"
          )}
        </h1>
        <Link
          type="button"
          className="btn buttonformshowentrycard height46 linkbtn mt-50"
          to={`/profile?activeTab=${"e_services"}`}
        >
          <span className="ml-10 margin-left-unset">
            <i class="fa-solid fa-arrow-left fa-lg marginrightleft-10"></i>
            {translate("web.back", "Back")}
          </span>
        </Link>
      </div>
    </div>
  );
}
