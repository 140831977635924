import React, { useState, useEffect } from "react";
import ForgetPasswordForm from "../components/ForgetPasswordForm";
import { ToastContainer, toast } from 'react-toastify';
import LoginUaePassOTPForm from "../components/LoginUaePassOTPForm";
import { useNavigate } from "react-router";
import axios from "../axios/axios";
import { useTranslate } from "../components/TranslationText";
const BaseURL = process.env.REACT_APP_BASE_URL;


export default function ForgetPasswordPage() {
  const navigate = useNavigate();
  const { translate } = useTranslate();


  const [isWideScreen, setIsWideScreen] = useState(true);
  const [showOTP, setShowOTP] = useState(false)
  const [otp, setOtp] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const [email, setEmail] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 600);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function showToastmessage(message, success = false) {
    if (success) {
      toast.success(message);
    } else {
      toast.error(message);
    }

  }
  const dismissAll = () => toast.dismiss();

  const handleOTPSubmit = async (event) => {
    event.preventDefault();
    const verfy = await axios.post('/account/resetpassword', { email: email, token: otp, newPassword: newPassword },)
    if (verfy.data.status.responseCode == 200) {
      showToastmessage(translate('web.yourPasswordHasBeenReset', 'Your Password Has Been Reset'), true);
      setShowOTP(false);
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } else if (verfy.data.status.responseCode == 100) {
      showToastmessage(verfy.data.status.detailedResponseCode);

    } else {
      showToastmessage('web.errorInresetPassword', 'Error In reset Password');
    }
  }


  return (
    <>
      <ToastContainer position="top-right" onClick={dismissAll} theme="colored" autoClose={5000} />

      <div className="LoginPage">
        {isWideScreen && <div style={{ flex: 1 }}></div>}
        {/* <div className="LoginPageContent" style={{flex:0.7}}>
          <img
            className="LoginPageBG"
            src={`${BaseURL}/assets/img/Longinnew.png`}
            alt="loginbg"
            style={{ opacity: 1, top: 0, right: 0, left: 0, bottom: 0, margin: 'auto' }}
          />
        </div> */}
        <div className="LoginFormContainer">
          <ForgetPasswordForm setEmail={setEmail} setShowOTP={setShowOTP} showToastmessage={showToastmessage} toast={toast} />
        </div>
      </div>
      {showOTP &&
        <LoginUaePassOTPForm setOTP={setOtp} onSubmit={handleOTPSubmit} sendNewPassword={setNewPassword} />
      }
    </>
  );
}
