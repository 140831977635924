import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import AboutUsSlider from "../components/AboutUsSlider";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { showFirst22Words } from "../helper/showFirstSixWords";

export default function AboutUs() {
  const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  const AboutUsID = process.env.REACT_APP_ABOUT_US_ID;
  const { translate } = useTranslate();

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const [aboutus, setAboutus] = useState([]);
  const [loaded, setloaded] = useState(false);

  const id = AboutUsID;

  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return "en-US";
    else return "ar-AE"; // Defaulting to English for demonstration
  }

  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(
        `/umbraco/delivery/api/v1/content/item?id=${id}`,
        {
          headers: {
            "accept-language": getCurrentLanguageUmbraco(),
          },
        }
      );
      const formattedData = umbraco.data?.map((item) => ({
        ourVision: item?.properties?.ourVision ?? "",
        ourMessage: item?.properties?.ourMessage ?? "",
        aboutTitle: item?.properties?.aboutTitle ?? "",
        aboutCoverImage: item?.properties?.aboutCoverImage ? item?.properties?.aboutCoverImage[0]?.url : "",
        firstImage: item?.properties?.rightImage ? item?.properties?.rightImage[0]?.url : "",
        secondImage: item?.properties?.leftImage ? item?.properties?.leftImage[0]?.url : "",
        firstImageTitle: item?.properties?.rightImageTitle ?? "",
        firstImageDesc: item?.properties?.rightImageDesc ?? "",
        secondImageTitle: item?.properties?.leftImageTitle ?? "",
        secondImageDesc: item?.properties?.leftImageDesc ?? "",
        firstImageBody: item?.properties?.rightImageBody ?? "",
        secondImageBody: item?.properties?.leftImageBody ?? "",
      }));
      setAboutus(formattedData);
      if (umbraco.data.length > 0) {
        setloaded(true);
      }
    }
    getdata();
  }, [currentLanguage]);
  return (
    <>
      {
        loaded ?
          <>
            {aboutus.map((item) => (
              <div className='container-fluid px75'>
                <div className='row'>
                  <h2 className="col-sm-12 bold rtl">{item.aboutTitle}</h2>

                  <div className="aboutUsImagesRowContainer">
                    <div className="aboutUsImagesRow">
                      {!!item.secondImage &&
                        <div className="aboutUsImagesBox">
                          <img alt={'about us img'} src={`${MediaBaseURL}${item.secondImage}`} />
                          <div className="vipMensDesc" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <h4 className="bold" style={{textAlign:'left'}} >{item.firstImageTitle}</h4>
                            <p style={{textAlign:'left'}}>{item.firstImageDesc}</p>
                            <p style={{textAlign:'left'}}>{showFirst22Words(item.firstImageBody)}</p>
                          </div>
                          <Link className="hoveronlink ViewProfileLink" style={{ margin: 'auto 10px 10px auto' , minWidth:100}}>
                            {translate("web.viewMore", "View More")}
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                            ></i>
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                            ></i>
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                            ></i>
                          </Link>
                        </div>

                      }
                      {!!item.firstImage &&
                        <div className="aboutUsImagesBox">
                          <img alt={'about us img'} src={`${MediaBaseURL}${item.firstImage}`} />
                          <div className="vipMensDesc" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            <h4 className="bold" style={{textAlign:'left'}}>{item.secondImageTitle}</h4>
                            <p style={{textAlign:'left' ,maxWidth:'90%'}}>{item.secondImageDesc}</p>
                            <p style={{textAlign:'left', maxWidth:'90%'}}>{showFirst22Words(item.secondImageBody)}</p>
                          </div>
                          <Link className="hoveronlink ViewProfileLink" style={{ margin: 'auto 10px 10px auto' , minWidth:100 }}>
                            {translate("web.viewMore", "View More")}
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                            ></i>
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                            ></i>
                            <i
                              className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                            ></i>
                          </Link>
                        </div>
                      }
                    </div>
                  </div>

                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', position: 'relative' }}>
                    <div className="justRotate aBoutusItemBorder" >
                      <div className="col-md-12 col-sm-12 text-center">
                        <img style={{ filter: 'grayscale(1)' }} src={BaseURL + "/assets/img/ourmission.png"} />
                      </div>
                      <h2 className="col-sm-12 text-center bold rtl">{translate('web.ourVision', 'Our Vision')}</h2>
                      <p className='col-sm-12 rtl fontsize18 ' >
                        {item.ourVision}
                      </p>
                    </div>
                    <div className="justRotate aBoutusItemBorder" >
                      <div className="col-md-12 col-sm-12 text-center">
                        <img src={BaseURL + "/assets/img/eahscirclelogo.png"} />
                      </div>
                      <h2 className="col-sm-12 text-center bold rtl">{translate('web.ourMessage', 'Our Message')}</h2>
                      <p className='col-sm-12 rtl fontsize18 ' >
                        {item.ourMessage}
                      </p>
                    </div>
                  </div>
                  {!!item.aboutCoverImage &&
                    <div className='col-sm-12 aboutusImageContainer'>
                      <img src={`${MediaBaseURL}${item.aboutCoverImage}`} alt="aboutus" style={{ width: 50 }} />
                    </div>
                  }
                  {/* <h2 className="col-sm-12 bold rtl">{translate('web.ourMessage', 'Our Message')}</h2>
                  <p className='col-sm-12 rtl fontsize18 '>
                    {item.ourMessage}
                  </p> */}
                  <h2 className="col-sm-12 bold rtl">{translate('web.eahshistory', 'Eahs History')}</h2>
                </div>
                <AboutUsSlider />
              </div>

            ))}
          </>
          : (
            <Loader />
          )
      }
    </>
  );
}
