import React from 'react'
import { formatDate } from '../helper/formatDate';

export default function CategoryCard({ translate, i, item }) {
    return <div
        className="col-md-2 col-sm-12 text-center bordercategorycard row rtl    "
        key={i}
        style={{ fontSize: 14, margin: '5px 5px'  , minWidth:302}}
    >
        {!!item.className && (
            <div className="fontWeight col-sm-12 text-start p0" style={{ fontSize: 16}}>
                {item.className}
            </div>
        )}
      
        <div className="horseCardlabelContent fontWeight col-lg-12 col-md-12 col-sm-12 p0 text-start" style={{ fontSize: 14 }}>
        </div>
        <div className=" col-lg-12 col-md-12 col-sm-12 p0 text-start" style={{ fontSize: 14 }}>
            <span className='fontWeight'>
                {translate("web.birthDate", "Birth Date") + " "}
                {translate("web.from", "From") + " "}
            </span>
            {item.birthDateFrom && formatDate(item.birthDateFrom) + " "}
            <span className='fontWeight'>
                {translate("web.to", "To") + " "}
            </span>
            {item.birthDateTo && formatDate(item.birthDateTo)}
        </div>
        {/* <div className="horseCardlabelContent fontWeight col-lg-12 col-md-12 col-sm-12 p0  text-start" style={{ fontSize: 14 }}>
        </div> */}
        <div className="horseCardlabelContent fontWeight col-lg-12 col-md-12 col-sm-12 p0 text-start" style={{ fontSize: 14 }}>
        </div>

        {!!item.maxNumPerClass && item.maxNumPerClass > 0 ?
            <>
                <div className="col-lg-12 col-md-12 col-sm-12 p0 text-start " style={{ fontSize: 14,  }}>
                    <span style={{ fontWeight: 'bold' }}>
                        {translate("web.horses", "Horses") + " "}
                    </span>
                    {/* {(item.maxNumPerClass - item.remainingHorses) + " "} */}
                    {(item.completedHorses) + " "}
                    {/* {translate("web.of", "Of") + " " + item.maxNumPerClass + " "} */}
                    {translate("web.of", "Of") + " " + item.maxNumPerClass + " "}
                    <span className='text-danger' style={{ fontWeight: 'bold' }}>
                        {translate("web.remaining", "Remaining") + " "}
                        {/* {item.remainingHorses} */}
                        {item.remainingHorses}
                    </span>
                </div>
                {/* <div className="horseCardlabelContent fontWeight col-lg-6 col-md-6 col-sm-12 p0 text-start" style={{fontSize:14 ,borderTop:'1px solid #000'}}>
                </div>
                <div className="horseCardlabelContent colorred fontWeight col-lg-6 col-md-6 col-sm-12 p0 text-start" style={{fontSize:14 , borderTop:'1px solid #000'}}>
                </div>
                <div className="horseCardlabelContent fontWeight col-lg-6 col-md-6 col-sm-12 p0 text-start" style={{fontSize:14 , borderTop:'1px solid #000'}}>
                </div> */}
            </>
            :
            <>
                <div className="col-lg-12 col-md-12 col-sm-12 p0 text-start " style={{ fontSize: 14,  }}>
                    <span style={{ fontWeight: 'bold' }}>
                        {translate("web.horsesConut", "عدد الخيول") + " "}
                        {item.completedHorses} {" "}
                    </span>
                    <span style={{ fontWeight: 'bold' }} >
                        {translate("web.noMaxNumber", "ولا يوجد حد أقصى") + " "}
                    </span>
                </div>
            </>
        }
    </div>;
}

