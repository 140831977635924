import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from '../components/TranslationText';
import ActionButton from '../components/ActionButton';
import { useSelector } from 'react-redux';
import axios from "../axios/axiosUmbraco";
import Loader from '../components/Loader';

const BaseURL = process.env.REACT_APP_BASE_URL;
const id = process.env.REACT_APP_HORSE_SUPPLIES_PAGE_CONTENT_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function HorseSupplies() {
    const { translate } = useTranslate();

    const { currentLanguage } = useSelector(
        (state) => state.studBookSlice
    );



    const [labelObj, setLabelObj] = useState(null);
    const [suppliers, setSuppliers] = useState([]);



    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang == "en") return 'en-US';
        else return 'ar-AE'; // Defaulting to English for demonstration
    }

    async function getdata() {
        const umbraco = await axios.get(`/umbraco/delivery/api/v1/content/item/${id}`, {
            headers: {
                "accept-language": getCurrentLanguageUmbraco(),

            }
        })
        const formattedData =
        {
            labelTitle: umbraco.data.properties.listTitle,
            labelDescription: umbraco.data.properties.listDescription
        }
        setLabelObj(formattedData);
    }

    async function getsuppliers() {
        const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=descendants:${id}&skip=0&take=10`, {
            headers: {
                "accept-language": getCurrentLanguageUmbraco(),

            }
        })
        const formattedData = umbraco.data?.items.map((item) => ({
            itemID: item?.id ?? '',
            itemCover: item.properties?.itemCover ? item.properties?.itemCover[0]?.url : '',
            itemShortDescription: item?.properties?.itemShortDescription ?? '',
            itemTitle: item?.properties?.itemTitle ?? "",
            itemWebsite: item?.properties?.itemWebsite ?? "",
            itemPhone: item?.properties?.itemPhone ?? "",
            itemPdfText: item?.properties?.itemPdfText ?? "",
            itemPdfUrl: item?.properties?.itemPdf ?? "",
            itemEmail: item?.properties?.itemEmail ?? "",
        }));
        setSuppliers(formattedData);

    }

    useEffect(() => {
        getsuppliers()
        getdata();

    }, [currentLanguage])
    const langauge = localStorage.getItem('eahsLang') ?? 'en';


    return (
        <>
            {!!labelObj ?
                <div className='container-fluid px75'>
                    <div className='row m0 rtl'>
                        <h2 className='supplierTitle'>
                            {labelObj.labelTitle}
                        </h2>
                        <p className='supplierdesc'>
                            {labelObj.labelDescription}
                        </p>
                    </div>
                    <div className='row m0 p0 rtl'>
                        {suppliers.length > 0 &&
                            suppliers.map((item) => (
                                <div className='col-lg-12 col-md-12 col-sm-12 row my-5 rotateArabic SupplierCardContainer' style={{display:"flex"}}>
                                    <Link to={`/SingleSupplier?id=${item.itemID}`} className='col-lg-4 col-md-4 col-sm-12 text-center rotateArabic supplierImageContainer'>
                                        <img alt='supplier' src={`${MediaBaseURL}${item.itemCover}`} />
                                    </Link>
                                    <div className='col-lg-8 col-md-8 col-sm-12 supplierImageContainer rotateArabic supplierDetailsContainer' style={{display:"flex"}}>
                                        <div>
                                            <Link to={`/SingleSupplier?id=${item.itemID}`} className='SupplierTitle'>
                                                {item.itemTitle}
                                            </Link>
                                            {!!(item.itemPhone) && item.itemPhone != '' &&
                                                <Link to={`tel:${item.itemPhone}`} target='_blank' className='Suplliericon' >
                                                    <i className="fa-solid fa-phone "></i>
                                                    <span>{item.itemPhone}</span>
                                                </Link>
                                            }
                                            {!!(item.itemEmail) && item.itemEmail != '' &&
                                                <Link to={`mailto:${item.itemEmail}`} target='_blank' className='Suplliericon'>
                                                    <i className="fa-regular fa-envelope "></i>
                                                    <span>{item.itemEmail}</span>
                                                </Link>
                                            }
                                            {!!(item.itemWebsite) && item.itemWebsite != '' &&
                                                <Link to={`https://${item.itemWebsite}`} target='_blank' className='Suplliericon'>
                                                    <i className="fa-solid fa-globe "></i>
                                                    <span>{item.itemWebsite}</span>
                                                </Link>
                                            }
                                        </div>
                                        <h4 className='Supplierdesc'>
                                            <h4 className='SupplierTitle'>
                                                {translate('web.about', 'About')}
                                            </h4>
                                            {item.itemShortDescription}
                                        </h4>
                                        {item.itemPdfUrl !== '' && !!item.itemPdfUrl &&

                                            <ActionButton margin={0} type={'openLink'} text={translate('web.downloadProfile', 'Download Profile')} to={`${MediaBaseURL}${item.itemPdfUrl}`} />
                                        }
                                    </div>
                                    <div className='row justify-content-end'>
                                        <Link to={`/SingleSupplier?id=${item.itemID}`} className=' hoveronlink rotateArabic'
                                            style={langauge == 'en' ? { textAlign: 'right', cursor: "pointer", marginRight: -10 } : { textAlign: 'left', cursor: "pointer", marginLeft: -10 }}

                                        >
                                            {translate("web.viewDetails", "View Details")}
                                            <i
                                                className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                                            ></i>
                                            <i
                                                className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                                            ></i>
                                            <i
                                                className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                                            ></i>
                                        </Link>
                                    </div>
                                </div>

                            ))}
                    </div>
                </div> : <Loader />
            }

        </>
    )
}
