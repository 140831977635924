// auctionSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios/axios';

export const fetchHorseAuctionData = createAsyncThunk(
  'horseauctiondata/fetchHorseAuctionData',
  async (currentHorseId, thunkAPI) => {
    try {
      const response = await  axios.get(
        `/auction/getauctionhorsedetails?auctionHorseId=${currentHorseId}`
      );;
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

function replaceNullWithUnknown(obj) {
  for (let key in obj) {
      if (obj[key] === null) {
          obj[key] = "unknown";
      } else if (typeof obj[key] === 'object') {
          replaceNullWithUnknown(obj[key]);
      }
  }
}

const hourseAuctionDataSlice = createSlice({
  name: 'horseauctiondata',
  initialState: {
    horseData:{about: {
      Name: "horseName",
      "Birth Date": "04/01/2023",
      "Reg Number": "UAE # 25892",
      Dam: "RIMAS AL KAYFI",
      "Breed Number": "7",
      Gender: "Gelding",
      Color: "Bay",
      Owner: "Hamid Talal",
      Breeder: "HELAL OBAID ALI MARAN ALMANSOORI",
    },
    horseAttachment: [
      {
        id: "883b3038-37b2-4825-7b89-08dc1c5a2c18",
        fileName: "9dd78b0f-84ad-44b6-bc83-2c100f36706f_download (2).jpg",
        fileType: ".jpg",
        fileUrl:
          "https://dev.eahs.org:8016/ImagesUploads/9dd78b0f-84ad-44b6-bc83-2c100f36706f_download (2).jpg",
        entityId: "f55aa82e-f25c-412e-d691-08dc1c5f5683",
        entity: "AuctionHorse",
        isActive: true,
      },
      {
        id: "f918db28-8b3b-4fd5-7b8c-08dc1c5a2c18",
        fileName: "89def8e1-57e9-4524-a86b-bbf7571e50b7_download.png",
        fileType: ".png",
        fileUrl:
          "https://dev.eahs.org:8016/ImagesUploads/0975e0da-22a4-4608-a52c-6eb63e57ecf4_download (1).jpg",
        entityId: "f55aa82e-f25c-412e-d691-08dc1c5f5683",
        entity: "HorseCoverImage",
        isActive: true,
      },
    ],},
    loading: false,
    error: null,
    hierarchyData:{
      id: 1,
      name: "John Doe",
      children: [
        {
          id: 2,
          name: "Child 2",
          children: [
            {
              id: 4,
              name: "Child 4",
              children: [
                { id: 8, name: "Child 8" , children:[{id:16,name:'child 16'},{id:17,name:'17'}]},
                { id: 9, name: "Child 9", children:[{id:18,name:'child 18'},{id:19,name:'19'}] },
              ],
            },
            {
              id: 5,
              name: "Child 5",
              children: [
                { id: 10, name: "Child 10" ,children:[{id:20,name:'child 20'},{id:21,name:'21'}] },
                { id: 11, name: "Child 11",children:[{id:22,name:'child 22'},{id:23,name:'23'}]},
              ],
            },
          ],
        },
        {
          id: 3,
          name: "Child 3",
          children: [
            {
              id: 6,
              name: "Child 6",
              children: [
                { id: 12, name: "Child 12" , children:[{id:24,name:'child 24'},{id:25,name:'25'}] },
                { id: 13, name: "Child 13" ,children:[{id:26,name:'child 26'},{id:27,name:'27'}]},
              ],
            },
            {
              id: 7,
              name: "Child 7",
              children: [
                { id: 14, name: "Child 14", children:[{id:28,name:'child 28'},{id:29,name:'29'}] },
                { id: 15, name: "Child 15",children:[{id:30,name:'child 30'},{id:31,name:'31'}] },
              ],
            },
          ],
        },
      ],
    }
    
  },
  reducers: {
    // Other reducers if needed
    resetHorseAuctionData: (state) => {
      state.horseData={about: {
        Name: "horseName",
        "Birth Date": "04/01/2023",
        "Reg Number": "UAE # 25892",
        Dam: "RIMAS AL KAYFI",
        "Breed Number": "7",
        Gender: "Gelding",
        Color: "Bay",
        Owner: "Hamid Talal",
        Breeder: "HELAL OBAID ALI MARAN ALMANSOORI",
      },
      horseAttachment: [
        {
          id: "883b3038-37b2-4825-7b89-08dc1c5a2c18",
          fileName: "9dd78b0f-84ad-44b6-bc83-2c100f36706f_download (2).jpg",
          fileType: ".jpg",
          fileUrl:
            "https://dev.eahs.org:8016/ImagesUploads/9dd78b0f-84ad-44b6-bc83-2c100f36706f_download (2).jpg",
          entityId: "f55aa82e-f25c-412e-d691-08dc1c5f5683",
          entity: "AuctionHorse",
          isActive: true,
        },
        {
          id: "f918db28-8b3b-4fd5-7b8c-08dc1c5a2c18",
          fileName: "89def8e1-57e9-4524-a86b-bbf7571e50b7_download.png",
          fileType: ".png",
          fileUrl:
            "https://dev.eahs.org:8016/ImagesUploads/0975e0da-22a4-4608-a52c-6eb63e57ecf4_download (1).jpg",
          entityId: "f55aa82e-f25c-412e-d691-08dc1c5f5683",
          entity: "HorseCoverImage",
          isActive: true,
        },
      ],};
      state.loading= false;
      state.error= null;
      state.hierarchyData={
        id: 1,
        name: "John Doe",
        children: [
          {
            id: 2,
            name: "Child 2",
            children: [
              {
                id: 4,
                name: "Child 4",
                children: [
                  { id: 8, name: "Child 8" , children:[{id:16,name:'child 16'},{id:17,name:'17'}]},
                  { id: 9, name: "Child 9", children:[{id:18,name:'child 18'},{id:19,name:'19'}] },
                ],
              },
              {
                id: 5,
                name: "Child 5",
                children: [
                  { id: 10, name: "Child 10" ,children:[{id:20,name:'child 20'},{id:21,name:'21'}] },
                  { id: 11, name: "Child 11",children:[{id:22,name:'child 22'},{id:23,name:'23'}]},
                ],
              },
            ],
          },
          {
            id: 3,
            name: "Child 3",
            children: [
              {
                id: 6,
                name: "Child 6",
                children: [
                  { id: 12, name: "Child 12" , children:[{id:24,name:'child 24'},{id:25,name:'25'}] },
                  { id: 13, name: "Child 13" ,children:[{id:26,name:'child 26'},{id:27,name:'27'}]},
                ],
              },
              {
                id: 7,
                name: "Child 7",
                children: [
                  { id: 14, name: "Child 14", children:[{id:28,name:'child 28'},{id:29,name:'29'}] },
                  { id: 15, name: "Child 15",children:[{id:30,name:'child 30'},{id:31,name:'31'}] },
                ],
              },
            ],
          },
        ],
      };
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchHorseAuctionData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHorseAuctionData.fulfilled, (state, action) => {
        const { result } = action.payload;
        const formattedData = {
          about: {
            Name: result.horseName,
            "Birth Date": result.horseBirthdate,
            "Reg Number": `UAE # ${result.registrationNumber}`,
            Dam: result.damNameEn,
            "lot No": result.lotNumber,
            "Sire": result.sireName,
            Gender: result.sex,
            Color: result.horseColor,
            Owner: result.ownerName,
            Breeder: result.breeder,
          },
          horseAttachment: result.horseAttachment,
        };
        const gethierarchy = {
          id: result.auctionHorseId,
          name: result.horseName,
          children: [
            {
              id: result.sireId,
              name: result.sireName,
              children: [
                {
                  id: result.siresSireId,
                  name: result.siresSireName,
                  children: [
                    { id: result.fatherAIdLevel3, name: result.fatherANameEnLevel3 , children:[{id:result.fatherAIdLevel4,name:result.fatherANameEnLevel4},{id:result.motherAIdLevel4,name:result.motherANameEnLevel4}]},
                    { id: result.motherAIdLevel3, name: result.motherANameEnLevel3, children:[{id:result.fatherBIdLevel4,name:result.fatherBNameEnLevel4},{id:result.motherBIdLevel4,name:result.motherBNameEnLevel4}] },
                  ],
                },
                {
                  id: result.damsSireId,
                  name: result.damsSireName,
                  children: [
                    { id: result.fatherBIdLevel3, name: result.fatherBNameEnLevel3 ,children:[{id:result.fatherCIdLevel4,name:result.fatherCNameEnLevel4},{id:result.motherCIdLevel4,name:result.motherCNameEnLevel4}] },
                    { id: result.motherBIdLevel3, name: result.motherBNameEnLevel3,children:[{id:result.fatherDIdLevel4,name:result.fatherDNameEnLevel4},{id:result.motherDIdLevel4,name:result.motherDNameEnLevel4}]},
                  ],
                },
              ],
            },
            {
              id: result.damId,
              name: result.damNameEn,
              children: [
                {
                  id: result.siresDamId,
                  name: result.sireDamName,
                  children: [
                    { id: result.fatherCIdLevel3, name: result.fatherCNameEnLevel3 , children:[{id:result.fatherEIdLevel4,name:result.fatherENameEnLevel4},{id:result.motherEIdLevel4,name:result.motherENameEnLevel4}] },
                    { id: result.motherCIdLevel3, name: result.motherCNameEnLevel3 ,children:[{id:result.fatherFIdLevel4,name:result.fatherFNameEnLevel4},{id:result.motherFIdLevel4,name:result.motherFNameEnLevel4}]},
                  ],
                },
                {
                  id: result.damsDamId,
                  name: result.damsDamName,
                  children: [
                    { id: result.fatherDIdLevel3, name: result.fatherDNameEnLevel3, children:[{id:result.fatherGIdLevel4,name:result.fatherGNameEnLevel4},{id:result.motherGIdLevel4,name:result.motherGNameEnLevel4}] },
                    { id: result.motherDIdLevel3, name: result.motherDNameEnLevel3,children:[{id:result.fatherHIdLevel4,name:result.fatherHNameEnLevel4},{id:result.motherHIdLevel4,name:result.motherHNameEnLevel4}] },
                  ],
                },
              ],
            },
          ],
        };
        replaceNullWithUnknown(gethierarchy);

        // const gethierarchy = {
        //   id: result.auctionHorseId,
        //   name: result.horseName,
        //   children: [
        //     {
        //       id: 2,
        //       name: result?.sireName
        //         ? result?.sireName
        //         : "Unknown",
        //       children: [
        //         {
        //           id: 4,
        //           name: result?.siresSireName
        //             ? result?.siresSireName
        //             : "Unknown",
        //           children: [
        //             {
        //               id: 8,
        //               name: result?.damsDamNameEnLevel3
        //                 ? result?.siresSireNameEnLevel3
        //                 : "Unknown",
        //             },
        //             {
        //               id: 9,
        //               name: result?.damsSireNameEnLevel3
        //                 ? result?.damsSireNameEnLevel3
        //                 : "Unknown",
        //             },
        //           ],
        //         },
        //         {
        //           id: 5,
        //           name: result?.sireDamName
        //             ? result?.sireDamName
        //             : "Unknown",
        //           children: [
        //             { id: 10, name: "Child 10" },
        //             { id: 11, name: "Child 12" },
        //           ],
        //         },
        //       ],
        //     },
        //     {
        //       id: 3,
        //       name: result?.damNameEn
        //         ? result?.damNameEn
        //         : "Unknown",
        //       children: [
        //         {
        //           id: 6,
        //           name: result?.damsSireName
        //             ? result?.damsSireName
        //             : "Unknown",
        //           children: [
        //             { id: 12, name: "Child 12" },
        //             { id: 13, name: "Child 13" },
        //           ],
        //         },
        //         {
        //           id: 7,
        //           name: result?.damsDamName
        //             ? result?.damsDamName
        //             : "Unknown",
        //           children: [
        //             { id: 14, name: "Child 14" },
        //             { id: 15, name: "Child 15" },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // };
        state.loading = false;
        state.horseData = formattedData;
        state.hierarchyData = gethierarchy;
      })
      .addCase(fetchHorseAuctionData.rejected, (state) => {
        state.loading = false;
        // Handle error cases (optional)
      });
  },
});
export const { resetHorseAuctionData } = hourseAuctionDataSlice.actions;

export default hourseAuctionDataSlice.reducer;
