import { createSlice } from "@reduxjs/toolkit";
import {
  FetchFilterOwnersOrUsersthunk,
  FetchStudBookBreederOwnerDetailsthunk,
  FetchStudBookCountriesthunk,
  FetchStudBookDatathunk,
  FetchStudBookHorseByOwnerBreederthunk,
  FetchStudBookHorseByOwnerthunk,
  FetchStudBookHorseCompetitionthunk,
  FetchStudBookHorseDetailsthunk,
  FetchStudBookHorsePedigreeByIdthunk,
  FetchStudBookHorseProgenythunk,
  FetchStudBookLanguagethunk,
  FetchStudBookOwnerDetailsthunk,
  FetchStudBookOwnerthunk,
  FetchStudBookSiblingsDamthunk,
  FetchStudBookSiblingsSireAndDamthunk,
  FetchStudBookSiblingsSirethunk,
} from "./StudBookAction";

const initialState = {
  studBookData: [],
  studBookOwnerDetailsData: [],
  studBookProgenyData: [],
  studBookSiblingsSireAndDamData: [],
  studBookSiblingsSireData: [],
  studBookSiblingsDamData: [],
  studBookHorseCompetitionData: [],
  studBookPedigreeData: {},
  studBookLanguageData:
    JSON.parse(localStorage.getItem("eahsLanguageCache")) ?? [],
  studBookHorseDetailsData: {},
  studBookCountries: [],
  ownerDetailsData: [],
  breederOwnerDetailsData: [],
  filterOwnersOrUsersData: [],
  studBookLoading: false,
  studBookError: null,
  stateShowMoreButtonHorse: false,
  take: 12,
  page: 0,

  totalCount: 0,
  pageOwnerDetails: 0,
  totalCountOwnerDetails: 0,

  pageProgeny: 1,
  takeProgeny: 12,
  totalCountProgeny: 0,

  pageSiblingsSireAndDam: 1,
  takeSiblingsSireAndDam: 12,
  totalCountSiblingsSireAndDam: 0,

  pageSiblingsSire: 1,
  takeSiblingsSire: 12,
  totalCountSiblingsSire: 0,

  pageSiblingsDam: 1,
  takeSiblingsDam: 12,
  totalCountSiblingsDam: 0,

  pageHorseCompetition: 1,
  takeHorseCompetition: 12,
  totalCountHorseCompetition: 0,
  currentLanguage: localStorage.getItem("eahsLang") ?? "en",
  stateNoDataFound: false,
  studBookOwnerDetailsStateNoDataFound: false,
};

const StudBookSlice = createSlice({
  name: "StudBook",
  initialState,
  reducers: {
    // Action to set loading state
    incrementPage: (state) => {
      state.page += 1;
    },
    incrementPageOwnerDetails: (state) => {
      state.pageOwnerDetails += 1;
    },
    incrementPageProgeny: (state, action) => {
      state.pageProgeny = action.payload;
    },
    incrementPageSiblingsSireAndDam: (state, action) => {
      state.pageSiblingsSireAndDam = action.payload;
    },
    incrementPageSiblingsSire: (state, action) => {
      state.pageSiblingsSire = action.payload;
    },
    incrementPageSiblingsDam: (state, action) => {
      state.pageSiblingsDam = action.payload;
    },
    incrementPageHorseCompetition: (state, action) => {
      state.pageHorseCompetition = action.payload;
    },
    setSystemLanguage: (state, action) => {
      state.currentLanguage = action.payload;
      localStorage.setItem("eahsLang", action.payload);
    },

    emptyPage: (state) => {
      state.page = 1;
      state.pageOwnerDetails = 1;
    },
    resetSearch: (state) => {
      state.page = 0;
      state.studBookData = [];
      state.studBookOwnerDetailsData = [];

      state.pageOwnerDetails = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchStudBookDatathunk.pending, (state, action) => {
      state.studBookLoading = true;
      state.stateNoDataFound = false;
    });
    builder.addCase(FetchStudBookDatathunk.fulfilled, (state, action) => {
      state.studBookLoading = false;

      if (state.page === 1) {
        state.studBookData = action.payload.horsesList;
        if (state.studBookData.length === 0) {
          state.stateNoDataFound = true;
        }
      } else {
        state.studBookData = state.studBookData.concat(
          action.payload.horsesList
        );
      }

      if (
        (state.studBookData.length > 0 &&
          state.studBookData.length < state.take) ||
        action.payload.totalCount === state.studBookData.length
      ) {
        state.stateShowMoreButtonHorse = true;
      } else {
        state.stateShowMoreButtonHorse = false;
      }

      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(FetchStudBookDatathunk.rejected, (state, action) => {
      state.studBookLoading = false;
      state.studBookError = action.payload;
      state.studBookData = [];
      state.stateShowMoreButtonHorse = false;
      state.totalCount = 0;
    });

    builder.addCase(FetchStudBookCountriesthunk.pending, (state, action) => {
      state.studBookLoading = true;
    });
    builder.addCase(FetchStudBookCountriesthunk.fulfilled, (state, action) => {
      state.studBookLoading = false;

      state.studBookCountries = action.payload;
    });
    builder.addCase(FetchStudBookCountriesthunk.rejected, (state, action) => {
      state.studBookLoading = false;
      state.studBookError = action.payload;
    });

    builder.addCase(FetchStudBookOwnerthunk.pending, (state, action) => {
      state.studBookLoading = true;
      state.stateNoDataFound = false;
    });
    builder.addCase(FetchStudBookOwnerthunk.fulfilled, (state, action) => {
      state.studBookLoading = false;
      if (state.page === 1) {
        state.studBookData = action.payload.ownersList;
        if (state.studBookData.length === 0) {
          state.stateNoDataFound = true;
        }
      } else {
        state.studBookData = state.studBookData.concat(
          action.payload.ownersList
        );
      }

      if (
        (state.studBookData.length > 0 &&
          state.studBookData.length < state.take) ||
        action.payload.totalCount === state.studBookData.length
      ) {
        state.stateShowMoreButtonHorse = true;
      } else {
        state.stateShowMoreButtonHorse = false;
      }
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(FetchStudBookOwnerthunk.rejected, (state, action) => {
      state.studBookLoading = false;
      state.studBookError = action.payload;
      state.studBookData = [];
      state.stateShowMoreButtonHorse = false;
      state.totalCount = 0;
    });

    builder.addCase(FetchFilterOwnersOrUsersthunk.pending, (state, action) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchFilterOwnersOrUsersthunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        state.filterOwnersOrUsersData = action.payload.ownersList;
        state.totalCount = action.payload.totalCount;
      }
    );
    builder.addCase(FetchFilterOwnersOrUsersthunk.rejected, (state, action) => {
      state.studBookLoading = false;
      state.studBookError = action.payload;
      state.filterOwnersOrUsersData = [];
      state.totalCount = 0;
    });

    builder.addCase(FetchStudBookHorseByOwnerthunk.pending, (state, action) => {
      state.studBookLoading = true;
      state.studBookOwnerDetailsStateNoDataFound = false;
    });
    builder.addCase(
      FetchStudBookHorseByOwnerthunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        if (state.pageOwnerDetails === 1) {
          state.studBookOwnerDetailsData = action.payload.horsesList;
          if (state.studBookOwnerDetailsData.length === 0) {
            state.studBookOwnerDetailsStateNoDataFound = true;
          }
        } else {
          state.studBookOwnerDetailsData =
            state.studBookOwnerDetailsData.concat(action.payload.horsesList);
        }
        if (
          (state.studBookOwnerDetailsData.length > 0 &&
            state.studBookOwnerDetailsData.length < state.take) ||
          action.payload.totalCount === state.studBookOwnerDetailsData.length
        ) {
          state.stateShowMoreButtonHorse = true;
        } else {
          state.stateShowMoreButtonHorse = false;
        }

        state.totalCountOwnerDetails = action.payload.totalCount;
      }
    );
    builder.addCase(
      FetchStudBookHorseByOwnerthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookOwnerDetailsData = [];
        state.stateShowMoreButtonHorse = false;
        state.totalCountOwnerDetails = 0;
      }
    );

    builder.addCase(
      FetchStudBookHorseByOwnerBreederthunk.pending,
      (state, action) => {
        state.studBookLoading = true;
        state.studBookOwnerDetailsStateNoDataFound = false;
      }
    );
    builder.addCase(
      FetchStudBookHorseByOwnerBreederthunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        if (state.pageOwnerDetails === 1) {
          state.studBookOwnerDetailsData = action.payload.horsesList;
          if (state.studBookOwnerDetailsData.length === 0) {
            state.studBookOwnerDetailsStateNoDataFound = true;
          }
        } else {
          state.studBookOwnerDetailsData =
            state.studBookOwnerDetailsData.concat(action.payload.horsesList);
        }
        if (
          (state.studBookOwnerDetailsData.length > 0 &&
            state.studBookOwnerDetailsData.length < state.take) ||
          action.payload.totalCountOwnerDetails ===
            state.studBookOwnerDetailsData.length
        ) {
          state.stateShowMoreButtonHorse = true;
        } else {
          state.stateShowMoreButtonHorse = false;
        }
        state.totalCountOwnerDetails = action.payload.totalCount;
      }
    );
    builder.addCase(
      FetchStudBookHorseByOwnerBreederthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookOwnerDetailsData = [];
        state.stateShowMoreButtonHorse = false;
      }
    );

    builder.addCase(FetchStudBookHorseDetailsthunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookHorseDetailsthunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookHorseDetailsData = action.payload.horse;
      }
    );
    builder.addCase(
      FetchStudBookHorseDetailsthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookHorseDetailsData = {};
      }
    );

    builder.addCase(FetchStudBookHorseProgenythunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookHorseProgenythunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookProgenyData = action.payload.horsesList;
        //state.studBookProgenyData = data.horsesList;
        state.totalCountProgeny = action.payload.totalCount;
        //state.totalCountProgeny = data.totalCount;
      }
    );
    builder.addCase(
      FetchStudBookHorseProgenythunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookProgenyData = [];
      }
    );

    builder.addCase(FetchStudBookSiblingsSireAndDamthunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookSiblingsSireAndDamthunk.fulfilled,
      (state, action) => {
        let data = {
          totalCount: 10,
          horsesList: [
            {
              id: "test 1",
              name: "test 11",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 12",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 13",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 14",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 15",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 16",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 17",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 18",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 19",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 20",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
          ].splice(
            (state.pageSiblingsSireAndDam - 1) * state.takeSiblingsSireAndDam,
            state.takeSiblingsSireAndDam
          ),
        };

        state.studBookLoading = false;
        state.studBookSiblingsSireAndDamData = action.payload.horsesList;
        //state.studBookSiblingsSireAndDamData = data.horsesList;
        //state.totalCountSiblingsSireAndDam = data.totalCount;
        state.totalCountSiblingsSireAndDam = action.payload.totalCount;
      }
    );
    builder.addCase(
      FetchStudBookSiblingsSireAndDamthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookSiblingsSireAndDamData = [];
      }
    );

    builder.addCase(FetchStudBookSiblingsSirethunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookSiblingsSirethunk.fulfilled,
      (state, action) => {
        let data = {
          totalCount: 10,
          horsesList: [
            {
              id: "test 1",
              name: "test 21",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 22",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 23",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 24",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 25",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 26",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 27",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 28",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 29",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 30",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
          ].splice(
            (state.pageSiblingsSire - 1) * state.takeSiblingsSire,
            state.takeSiblingsSire
          ),
        };

        state.studBookLoading = false;
        state.studBookSiblingsSireData = action.payload.horsesList;
        //state.studBookSiblingsSireData = data.horsesList;
        //state.totalCountSiblingsSire = data.totalCount;
        state.totalCountSiblingsSire = action.payload.totalCount;
      }
    );
    builder.addCase(
      FetchStudBookSiblingsSirethunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookSiblingsSireData = [];
      }
    );

    builder.addCase(FetchStudBookSiblingsDamthunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookSiblingsDamthunk.fulfilled,
      (state, action) => {
        let data = {
          totalCount: 10,
          horsesList: [
            {
              id: "test 1",
              name: "test 31",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 32",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 33",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 34",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 35",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 36",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 37",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 38",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 39",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
            {
              id: "test 1",
              name: "test 40",
              registerNumber: "reg 1",
              origin: "test",
              birthDate: "1-1-2002",
              gender: "test",
              color: "test",
              owner: "test",
              breeder: "test",
            },
          ].splice(
            (state.pageSiblingsDam - 1) * state.takeSiblingsDam,
            state.takeSiblingsDam
          ),
        };

        state.studBookLoading = false;
        state.studBookSiblingsDamData = action.payload.horsesList;
        //state.studBookSiblingsDamData = data.horsesList;
        //state.totalCountSiblingsDam = data.totalCount;
        state.totalCountSiblingsDam = action.payload.totalCount;
      }
    );
    builder.addCase(FetchStudBookSiblingsDamthunk.rejected, (state, action) => {
      state.studBookLoading = false;
      state.studBookError = action.payload;
      state.studBookSiblingsDamData = [];
    });

    builder.addCase(FetchStudBookHorseCompetitionthunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookHorseCompetitionthunk.fulfilled,
      (state, action) => {
        let data = {
          totalCount: 10,
          horseCompetitionList: [
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "Gold",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "100",
            },
            {
              id: "test 1",
              showName: "test 2",
              classificationMedal: "Silver",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "200",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "Bronze",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "300",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "reg 1",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "400",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "reg 1",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "500",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "reg 1",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "600",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "reg 1",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "700",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "reg 1",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "800",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "reg 1",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "900",
            },
            {
              id: "test 1",
              showName: "test 31",
              classificationMedal: "reg 1",
              className: "test",
              date: "1-1-2002",
              rank: "test",
              totalPoints: "1000",
            },
          ].splice(
            (state.pageHorseCompetition - 1) * state.takeHorseCompetition,
            state.takeHorseCompetition
          ),
        };

        state.studBookLoading = false;
        state.studBookHorseCompetitionData =
          action.payload.horseCompetitionList;
        //state.studBookHorseCompetitionData = data.horseCompetitionList;
        //state.totalCountHorseCompetition = data.totalCount;
        state.totalCountHorseCompetition = action.payload.totalCount;
      }
    );
    builder.addCase(
      FetchStudBookHorseCompetitionthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookHorseCompetitionData = [];
      }
    );

    builder.addCase(FetchStudBookHorsePedigreeByIdthunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookHorsePedigreeByIdthunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookPedigreeData = action.payload;
      }
    );
    builder.addCase(
      FetchStudBookHorsePedigreeByIdthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.studBookPedigreeData = {};
      }
    );

    builder.addCase(FetchStudBookLanguagethunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(FetchStudBookLanguagethunk.fulfilled, (state, action) => {
      state.studBookLoading = false;
      state.studBookLanguageData =
        action.payload.cmsTranslationViewsList.reduce(function (
          previous,
          current
        ) {
          previous[current.key] = current.value;
          return previous;
        },
        {});
      localStorage.setItem(
        "eahsLanguageCache",
        JSON.stringify(
          action.payload.cmsTranslationViewsList.reduce(function (
            previous,
            current
          ) {
            previous[current.key] = current.value;
            return previous;
          },
          {})
        )
      );
    });
    builder.addCase(FetchStudBookLanguagethunk.rejected, (state, action) => {
      state.studBookLoading = false;
      state.studBookError = action.payload;
      state.studBookLanguageData = {};
    });

    builder.addCase(FetchStudBookOwnerDetailsthunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookOwnerDetailsthunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        state.ownerDetailsData = action.payload.owner;
      }
    );
    builder.addCase(
      FetchStudBookOwnerDetailsthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.ownerDetailsData = [];
      }
    );

    builder.addCase(FetchStudBookBreederOwnerDetailsthunk.pending, (state) => {
      state.studBookLoading = true;
    });
    builder.addCase(
      FetchStudBookBreederOwnerDetailsthunk.fulfilled,
      (state, action) => {
        state.studBookLoading = false;
        state.breederOwnerDetailsData = action.payload.owner;
      }
    );
    builder.addCase(
      FetchStudBookBreederOwnerDetailsthunk.rejected,
      (state, action) => {
        state.studBookLoading = false;
        state.studBookError = action.payload;
        state.breederOwnerDetailsData = [];
      }
    );
  },
});

// Export the reducer and actions

export const {
  incrementPage,
  emptyPage,
  resetSearch,
  incrementPageOwnerDetails,
  incrementPageProgeny,
  incrementPageSiblingsSireAndDam,
  incrementPageSiblingsSire,
  incrementPageSiblingsDam,
  incrementPageHorseCompetition,
  setSystemLanguage,
} = StudBookSlice.actions;
export default StudBookSlice.reducer;
