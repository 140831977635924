import React, { useEffect } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { FetchMyShowParticipantsRequestthunk } from "../Redux/ShowEntry/ShowEntryAction";
import { Link } from "react-router-dom";
import { myShowParticipantsRequestChooses } from "../Redux/ShowEntry/ShowEntrySlice";

export default function ShowEntryParticipantsRequest() {
  const navigate = useNavigate();
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { showEntryLoading, myShowParticipantsRequestData } = useSelector(
    (state) => state.showEntrySlice
  );

  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const showName = searchParams.has("showName")
    ? searchParams.get("showName")
    : null;

  const maxHorses = searchParams.has("maxHorses")
    ? searchParams.get("maxHorses")
    : null;

  useEffect(() => {
    let params = {
      id: localStorage.getItem("EAHSUserID"),
      langauge: currentLanguage,
    };
    dispatch(FetchMyShowParticipantsRequestthunk(params));
  }, [currentLanguage]);

  const handelSubmit = (item) => {
    dispatch(myShowParticipantsRequestChooses([item]));
    navigate(
      `/showentrytotalregisteredhorses?maxHorses=${maxHorses}&showName=${showName}`
    );
  };

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${
          showEntryLoading ? "classdisplay" : "classnotdisplay"
        }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      <div className="row rtl">
        {
          // title
        }
        <div className="col-md-12 col-sm-12 ">
          <h1 className="fontWeight">{showName}</h1>

          <h1 className="fontWeight colorred">
            {translate(
              "web.showParticipantsRequest",
              "Show Participants Request"
            )}
          </h1>
          {
            //tabel requests
          }
          <div className="col-md-12 col-sm-12 mb-25">
            <table className="table table-striped table-bordered">
              <thead className="theadHorseSelect">
                <tr>
                  <th className="text-center">
                    {translate("web.showName", "Show Name")}
                  </th>
                  <th className="text-center">
                    {translate("web.horseName", "horseName")}
                  </th>
                  <th className="text-center">
                    {translate("web.statusName", "Status Name")}
                  </th>
                  <th className="text-center">
                    {translate("web.amount", "Amount")}
                  </th>
                  <th className="text-center">
                    {translate("web.action", "Action")}
                  </th>
                </tr>
              </thead>
              <tbody className="text-center fontWeight fontsize18">
                {!!myShowParticipantsRequestData &&
                !showEntryLoading &&
                !!myShowParticipantsRequestData.length > 0 ? (
                  myShowParticipantsRequestData.map((item, i) => (
                    <tr key={i}>
                      <td>{item.showName}</td>
                      <td>{item.horseName}</td>
                      <td>{item.statusName}</td>
                      <td>{item.amount}</td>
                      <td>
                        <Link
                          type="button"
                          className="btn buttonformshowentrycard height46 linkbtn"
                          to={`/showentryconflict??maxHorses=${maxHorses}&showName=${showName}`}
                        >
                          <span className="ml-10">
                            {translate("web.judgeConflict", "Judge Conflict")}
                          </span>
                        </Link>
                        <ActionButton
                          type={"submit"}
                          label={translate("web.submit", "Submit")}
                          text={translate("web.submit", "Submit")}
                          onClick={() => handelSubmit(item)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <h2 className="row text-danger text-center col-md-12 minHeight-174">
                        {translate("web.nodataFound", "No Data Found !")}
                      </h2>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
