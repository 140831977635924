import React, { useState } from "react";

export default function CollapseLabel({ children, name }) {
  const [tableVisible, setTableVisible] = useState(true);

  const toggleTableVisibility = () => {
    setTableVisible(!tableVisible);
  };

  return (
    <>
      <div
        className="collapseLableStyle right-to-left"
        onClick={toggleTableVisibility}
      >
        <span className="font-24">{name}</span>{" "}
        {tableVisible ? (
          <i className="fa-solid fa-chevron-up m-auto-10-font-20"></i>
        ) : (
          <i className="fa-solid fa-chevron-down m-auto-10-font-20"></i>
        )}
      </div>

      {tableVisible && children}
    </>
  );
}
