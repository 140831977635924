import React, { useState, useEffect } from "react";
import AuctionFilter from "../components/AuctionFilter";
import EventsList from "../components/EventsList";
import { useTranslate } from "../components/TranslationText";
import ComingSoon from "../components/ComingSoon";
import FilterButton from "../components/FilterButton";
import DownloadAppsSection from '../components/DownloadAppsSection'
export default function AuctionPage() {
  const [showFilter, setShowFilter] = useState(false); // Initially hide filter
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(false);
  const [filters, setFilters] = useState({
    // Initial filter values
    auctionName: "",
    auctionType: "None",
    auctionStartDateFrom: "",
    auctionStartDateTo: "",
    skip: 0,
    take: 6,
    numberOfHorsesFrom: 0,
    numberOfHorsesTo: 0,
    dateRange: null,
    reset: false,
  });
  const { translate } = useTranslate();
  const currentLanguage = localStorage.getItem('eahsLang');


  const updateAuctionList = (newFilters) => {
    setFilters({ ...newFilters, reset: true });
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const buttonShowFiltertStyle = {
    background: isHovered ? "#E44D52" : "#ffffff",
    padding: "10px 20px",
    color: isHovered ? "#fff" : "#E44D52",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: "#E44D52",
    transition: "all 0.3s",
    display: "block",
    margin: "10px auto",
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to toggle filter visibility
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <React.Fragment>
      <div
        className="container-fluid px75">
        <div className="row rtl">
          {/* Button to toggle filter visibility */}
          {windowWidth <= 970 && (
            // <button
            //   onClick={toggleFilter}
            //   style={buttonShowFiltertStyle}
            //   onMouseOver={handleMouseOver}
            //   onMouseOut={handleMouseOut}
            // >
            //   {showFilter ? translate('web.hideFilter', "Hide Filter") : translate('web.showFilter', "Show Filter")}
            // </button>
            <div className="row m0 text-center justify-content-center">
            <div class="col-md-2 col-sm-3 text-center">
              <FilterButton onClick={toggleFilter} />
            </div>
            </div>
          )}


          <div
            className="col-md-3 col-sm-12 "
            style={{
              display:
                windowWidth >= 970 ? "block" : showFilter ? "block" : "none",
            }}
          >
            <AuctionFilter onSubmit={updateAuctionList} />
          </div>
          <div className={"col-md-9 col-sm-12"}>
            <EventsList filters={filters} />
          </div>


        </div>
      </div>
      <DownloadAppsSection />

    </React.Fragment>
    // <ComingSoon />
  );
}
