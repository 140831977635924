// import React from 'react'
// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
// import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
// import esLocale from '@fullcalendar/core/locales/es';
// // import 'bootstrap/dist/css/bootstrap.css';
// import '@fortawesome/fontawesome-free/css/all.css';
// import bootstrapPlugin from '@fullcalendar/bootstrap';
// import { useSelector } from 'react-redux';

// function renderEventContent(eventInfo) {
//     return (
//         <>
//             <b>{eventInfo.timeText}</b>
//             <i>{eventInfo.event.title}</i>
//         </>
//     )
// }

// export default function Calendar({ showHeader, aspectRatio, isLeftSide , initialDate }) {
//     /**
//      * Event Types:
//      * internationalShow
//      * internationalShowReception
//      * Championship
//      * ChampionshipReception
//      * Exhibition
//      * ExhibitionReception
//      * 
//      */
//     const { currentLanguage } = useSelector((state) => state.studBookSlice);
//     console.log('cccccccc',currentLanguage)

//     const eventType = 'internationalShow'
//     const handleDateClick = (arg) => {
//         alert(arg.dateStr)
//     }



//     return (
//         <div>
//             <FullCalendar
//                 plugins={[dayGridPlugin, interactionPlugin, bootstrapPlugin]}
//                 initialView="dayGridMonth"
//                 weekends={true}
//                 aspectRatio={aspectRatio}
//                 headerToolbar={ { start: false ,center: 'title',end:false} }
//                 handleWindowResize
//                 viewClassNames={'calendarContainer leftCalender'}
//                 slotLabelClassNames={'calendarSlot'}
//                 slotLaneClassNames={'calendarSlot'}
//                 initialDate={initialDate}
//                 themeSystem={'standart'}
//                 expandRows={false}
//                 locale={currentLanguage}
//                 direction={currentLanguage == 'ar' ? 'rtl' :'ltr'}


//                 events={[
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-05-25', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent internationalShow` : 'internationalShow' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-05-26', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent internationalShowReception` : 'internationalShowReception' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-05-27', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent Championship` : 'Championship' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-05-28', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent ChampionshipReception` : 'ChampionshipReception' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-05-29', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent Exhibition` : 'Exhibition' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-05-30', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent ExhibitionReception` : 'ExhibitionReception' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-02-25', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent internationalShow` : 'internationalShow' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-02-26', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent internationalShowReception` : 'internationalShowReception' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-02-27', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent Championship` : 'Championship' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-02-28', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent ChampionshipReception` : 'ChampionshipReception' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-02-24', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent Exhibition` : 'Exhibition' },
//                     { title: 'Abu Dhabi International hunting & equestrian exhibition', date: '2024-02-23', url: 'wwww.google.com', display: 'block', className: isLeftSide ? `hideevent ExhibitionReception` : 'ExhibitionReception' },
//                 ]}
//                 // dateClick={handleDateClick}
//                 eventContent={renderEventContent}
//                 businessHours={{ editable: true }}
//             />
//         </div>
//     )
// }
import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import esLocale from '@fullcalendar/core/locales/es';
import '@fortawesome/fontawesome-free/css/all.css';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { useSelector } from 'react-redux';

function renderEventContent(eventInfo) {
    return (
        <div className='custom-event-style' style={{ background: eventInfo.backgroundColor, opacity: 0.3 }}>

        </div>
    )
}

export default function Calendar({ showHeader, aspectRatio, isLeftSide, initialDate , events }) {
    const { currentLanguage } = useSelector((state) => state.studBookSlice);
    const calendarRef = useRef(null);
    const [reformatedEvent,setReformatedEvents] = useState(generateEvents(events));

    function generateEvents(events) {
        // Helper function to generate all dates between two dates
        function getDatesInRange(startDate, endDate) {
            const dates = [];
            let currentDate = new Date(startDate);
            while (currentDate <= endDate) {
                dates.push(new Date(currentDate));
                currentDate.setDate(currentDate.getDate() + 1);
            }
            return dates;
        }
    
        // Array to hold the new events
        const newEvents = [];
    
        // Iterate over each event in the original array
        events.forEach(item => {
            // Parse the start and end dates
            const startDate = new Date(item.start);
            const endDate = new Date(item.end);
    
            // Generate all dates between the start and end dates
            const datesInRange = getDatesInRange(startDate, endDate);
    
            // Create a new event for each date in the date range
            datesInRange.forEach(date => {
                newEvents.push({
                    title: item.title,
                    date: date, // Convert date to YYYY-MM-DD format
                    type: item.type,
                    backgroundColor: item.backgroundColor
                });
            });
        });
    
        return newEvents;
    }
    useEffect(() => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(initialDate);

        setReformatedEvents(generateEvents(events));

    }, [initialDate , events]);



    const handleDateClick = (arg) => {
        alert(arg.dateStr)
    }

    return (
            <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin, bootstrapPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                aspectRatio={aspectRatio}
                headerToolbar={{ start: false, center: 'title', end: false }}
                handleWindowResize
                viewClassNames={'calendarContainer leftCalender'}
                slotLabelClassNames={'calendarSlot'}
                slotLaneClassNames={'calendarSlot'}
                initialDate={initialDate}
                themeSystem={'standart'}
                expandRows={false}
                // showNonCurrentDates={false}
                locale={currentLanguage}
                direction={currentLanguage === 'ar' ? 'rtl' : 'ltr'}
                events={reformatedEvent}
                eventContent={renderEventContent}
                businessHours={{ editable: true }}
            />
    )
}
