export function showFirstSixWords(str) {
    // Split the string into an array of words
    let words = str.split(' ');
    
    // Check if the array has at least 6 words
    if (words.length >= 7) {
        // If yes, join the first six words and return
        return words.slice(0, 7).join(' ') + '...';
    } else {
        // If no, return the original string
        return str;
    }
}
export function showFirstFourWords(str) {
    // Split the string into an array of words
    let words = str.split(' ');
    
    // Check if the array has at least 6 words
    if (words.length >= 4) {
        // If yes, join the first six words and return
        return words.slice(0, 4).join(' ') + '...';
    } else {
        // If no, return the original string
        return str;
    }
}
export function showFirst22Words(str) {
    // Split the string into an array of words
    let words = str.split(' ');
    
    // Check if the array has at least 6 words
    if (words.length >= 22) {
        // If yes, join the first six words and return
        return words.slice(0, 22).join(' ') + '...';
    } else {
        // If no, return the original string
        return str;
    }
}
export function showFirst12Words(str) {
    // Split the string into an array of words
    let words = str.split(' ');
    
    // Check if the array has at least 6 words
    if (words.length >= 12) {
        // If yes, join the first six words and return
        return words.slice(0, 12).join(' ') + '...';
    } else {
        // If no, return the original string
        return str;
    }
}
export function showFirst17Words(str) {
    // Split the string into an array of words
    let words = str.split(' ');
    
    // Check if the array has at least 6 words
    if (words.length >= 17) {
        // If yes, join the first six words and return
        return words.slice(0, 17).join(' ') + '...';
    } else {
        // If no, return the original string
        return str;
    }
}

export function  getFirstAndLastWords(str) {
    return str;
    const words = str.split(/[\s,]/); //  Split by spaces, commas, or any whitespace characters
    return {
      firstWord: words[0],
      lastWord: words[words.length - 1]
    };
  }