export function convertDateToISO(input) {
    // Check if input is already in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.SSS or longer)
    const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+$/;
    if (isoFormatRegex.test(input)) {
        return input; // Return directly if already in ISO format
    }

    // If not in ISO format, proceed with conversion from MM/DD/YYYY hh:mm:ss:SSS AM/PM
    const [datePart, timePart, period] = input.split(' ');
    const [month, day, year] = datePart.split('/');
    let [hours, minutes, secondsMillis] = timePart.split(':');
    const [seconds, millis] = secondsMillis.split(':');

    // Convert to 24-hour format if necessary
    if (period === 'PM' && hours !== '12') {
        hours = String(Number(hours) + 12);
    } else if (period === 'AM' && hours === '12') {
        hours = '00';
    }

    // Format the result in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.SSS)
    const isoString = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hours.padStart(2, '0')}:${minutes}:${seconds}.${millis ?? '000000'}`;

    return isoString;
}

// Function to check if the input is in ISO 8601 format
function isISO8601(input) {
    const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+$/;
    return isoFormatRegex.test(input);
}

// Function to convert ISO 8601 format to 'YYYY/MM/DD hh:mm AM/PM' format
export function convertISOToCustomFormat(input) {
    if (!isISO8601(input)) {
        throw new Error("Input is not in the expected ISO format.");
    }

    // Split the input into date and time components
    const [datePart, timePart] = input.split('T');
    const [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');

    // Convert to 12-hour format
    let period = 'AM';
    hours = parseInt(hours, 10);
    if (hours >= 12) {
        period = 'PM';
        if (hours > 12) {
            hours -= 12;
        }
    } else if (hours === 0) {
        hours = 12;
    }

    // Format the output string as 'YYYY/MM/DD hh:mm AM/PM'
    const formattedDate = `${year}/${month}/${day} ${String(hours).padStart(2, '0')}:${minutes} ${period}`;
    return formattedDate;
}



export function formateDateToMac(stringDate) {
    const newString = convertDateToISO(stringDate);
    const date = convertISOToCustomFormat(newString);

    return date;
}