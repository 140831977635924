import React, { useEffect, useState } from 'react'
import ActionButton from '../components/ActionButton';
import { getOrdinal } from '../helper/getOrdinal';
import { useTranslate } from '../components/TranslationText';
import axios from '../axios/axios';
import axiosUmbraco from '../axios/axiosUmbraco';

import Loader from '../components/Loader';
import ComingSoon from '../components/ComingSoon';

const BaseURL = process.env.REACT_APP_BASE_URL;
const pageID = process.env.REACT_APP_UMBRACO_EAHS_AWARD_PAGE_ID;
const umbracoBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function AwardPage() {
    const [activeTab, setActiveTab] = useState(1);
    const [mainActiveTab, setMainActiveTab] = useState(1);
    const [prizeTab, setprizeTab] = useState(1);
    const { translate } = useTranslate();
    const [pointsDestributionData, setPointsDitrebutionData] = useState([]);
    const [liveRanking, setLiveRanking] = useState([]);
    const [umbracoData, setUmbracoData] = useState(null);
    const { loaded, setLoaded } = useState(false);
    const [seniorStallions, setSeniorStallions] = useState([]);
    const [juniorFillies, setJuniorFillies] = useState([]);
    const [seniorMares, setSeniorMares] = useState([]);
    const [juniorColts, setJuniorColts] = useState([]);

    async function getPointsDestributionData() {
        try {
            const response = await axios.get('eahsaward/geteahsawardmatrix');
            if (response.data.responseCode == "200") {

                const res = response.data.result;
                // console.debug('res is' , res);
                const fres = formatData(res);
                setPointsDitrebutionData(fres);
            }
        } catch (error) {
            console.error('error in getting pointsDestributionData', error);
        }

    }

    async function getLiveRankingData() {
        try {
            const response = await axios.get('eahsaward/geteahsawardliveranking');
            if (response.data.responseCode == "200") {
                setLiveRanking(response.data.result);
            }
        } catch (error) {
            console.error('error in getting LiveRanking Data', error);

        }

    }

    async function getDataFromUmbraco() {

        try {
            const res = await axiosUmbraco.get(`umbraco/delivery/api/v1/content/item?id=${pageID}`);
            if (res.data.length > 0) {
                setUmbracoData(res?.data[0]?.properties);
                setLoaded(true);
            }
        } catch (error) {
            console.error('error in Loading Data From Umbraco', error);
        }

    }

    const getPrizesData = async () => {
        try {
          const response = await axiosUmbraco.get(`umbraco/delivery/api/v1/content?fetch=children:${pageID}&skip=0&take=10000`); // Replace with your API endpoint
          const items = response.data.items;
  
          // Process items into respective categories
          const seniorStallions = items.filter(item => item.properties.prizeCategory === 'Senior Stallions')
                                        .sort((a, b) => a.properties.rank - b.properties.rank);
          const juniorFillies = items.filter(item => item.properties.prizeCategory === 'Junior Fillies')
                                      .sort((a, b) => a.properties.rank - b.properties.rank);
          const seniorMares = items.filter(item => item.properties.prizeCategory === 'Senior Mares')
                                    .sort((a, b) => a.properties.rank - b.properties.rank);
          const juniorColts = items.filter(item => item.properties.prizeCategory === 'Junior Colts')
                                   .sort((a, b) => a.properties.rank - b.properties.rank);
  
          // Set the state for each category
          setSeniorStallions(seniorStallions);
          setJuniorFillies(juniorFillies);
          setSeniorMares(seniorMares);
          setJuniorColts(juniorColts);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };

    const lang = localStorage.getItem('eahsLang') ?? 'en';

    useEffect(() => {
        getPointsDestributionData();
        getLiveRankingData();
        getDataFromUmbraco();
        getPrizesData();
    }, [])

    const formatData = (responseData) => {
        return responseData.map((rankArray) => {
            const reformattedObject = {};

            // Assuming the `classRank` is the same for all items in the rankArray
            if (rankArray.length > 0) {
                reformattedObject.classRank = rankArray[0].classRank;
            }

            rankArray.forEach((item) => {
                // Map showRankName to desired keys and store points as values
                switch (item.showRankName) {
                    case 'Title':
                        reformattedObject.title = item.points;
                        break;
                    case 'A. Int':
                        reformattedObject.aint = item.points;
                        break;
                    case 'B. Int':
                        reformattedObject.bint = item.points;
                        break;
                    case 'B. Nat':
                        reformattedObject.bnat = item.points;
                        break;
                    case 'N. Nat':
                        reformattedObject.nnat = item.points;
                        break;
                    case 'C. Int':
                        reformattedObject.cint = item.points;
                        break;
                    case 'C. Nat':
                        reformattedObject.cnat = item.points;
                        break;
                    case 'Sp. Org':
                        reformattedObject.sporg = item.points;
                        break;
                    default:
                        break;
                }
            });

            return reformattedObject;
        });
    };


    function filterClassDataByCategory(activeTab) {
        let data;
        switch (activeTab) {
            case 1:
                data = liveRanking.filter(x => x.category == "JuniorFemale")
                break;
            case 2:
                data = liveRanking.filter(x => x.category == "SeniorFemale")
                break;
            case 3:
                data = liveRanking.filter(x => x.category == "JuniorMale")
                break;
            case 4:
                data = liveRanking.filter(x => x.category == "SeniorMale")
                break;

            default:
                data = []
                break;
        }
        return data;
    }

    return (
        <div className='container'>
            {loaded ? <Loader /> :
                <>
                    {(umbracoData == null || (umbracoData?.Published == false)) ? <ComingSoon /> :
                        <div className='row rtl'>
                            {umbracoData.headerImage.length > 0 &&
                                <div className='col-12 p0'>
                                    <img src={`${umbracoBaseURL}${umbracoData.headerImage[0]?.url}`} style={{ width: '100%' }} />
                                </div>
                            }
                            <div className='col-12 mt-3 p0'>
                                <h3 className='bold'>{umbracoData?.title}</h3>
                                <h5 className='italic' >{umbracoData?.subtitle}</h5>
                            </div>
                            <div className='col-12 p0 mt-2 currentFont'>
                                <div className="col-12 row AuctionDetailsRow AuctionDetailsRowTabs rtl p0 my-1" style={{ padding: 0 }}>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p0 me-2" style={{ minWidth: 220 }}>
                                        <span
                                            className={`customTabStyle ${mainActiveTab == 1 ? 'customTabactive' : ''}`}
                                            onClick={() => {
                                                setMainActiveTab(0);
                                                setTimeout(() => {
                                                    setMainActiveTab(1);
                                                }, 200);
                                            }}
                                        >
                                            {lang == 'en' ? 'Award’s rules' : 'قوانين الجائزة'}

                                        </span>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p0 me-2" style={{ minWidth: 220 }}>
                                        <span
                                            className={`customTabStyle ${mainActiveTab == 2 ? 'customTabactive' : ''}`}
                                            onClick={() => {
                                                setMainActiveTab(0);
                                                setTimeout(() => {
                                                    setMainActiveTab(2);
                                                }, 200);
                                            }}
                                        >
                                            {lang == 'en' ? 'Points distribution table' : 'جدول توزيع النقاط'}

                                        </span>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p0 me-2" style={{ minWidth: 220 }}>
                                        <span
                                            className={`customTabStyle ${mainActiveTab == 3 ? 'customTabactive' : ''}`}
                                            onClick={() => {
                                                setMainActiveTab(0);
                                                setTimeout(() => {
                                                    setMainActiveTab(3);
                                                }, 200);
                                            }}
                                        >
                                            {lang == 'en' ?
                                                'Prize money' : 'الجوائز المالية'
                                            }
                                        </span>
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-3 p0 me-2" style={{ minWidth: 220 }}>
                                        <span
                                            className={`customTabStyle ${mainActiveTab == 4 ? 'customTabactive' : ''}`}
                                            onClick={() => {
                                                setMainActiveTab(0);
                                                setTimeout(() => {
                                                    setMainActiveTab(4);
                                                }, 200);
                                            }}
                                        >
                                            {lang == 'en' ?
                                                'Live ranking' : 'النتائج اللحظية'
                                            }
                                        </span>
                                    </div>
                                </div>

                                {mainActiveTab == 1 &&
                                    <div dangerouslySetInnerHTML={{ __html: umbracoData?.awardRules?.markup }} />
                                }
                                {mainActiveTab == 2 &&
                                    <>
                                        <h5 className='bold mt-4 text-center'>
                                            {lang == 'en' ? 'Points distribution table' : 'جدول توزيع النقاط'}
                                        </h5>
                                        <div className="scrolableTable mx-auto " style={{ maxWidth: 800 }}>
                                            <table className="table table-striped table-bordered rtl horseResultTable">
                                                <thead className="theadHorseSelect">

                                                    <tr>
                                                        <th className="text-center">
                                                            {lang == 'en' ? 'Rank' : 'المركز'}
                                                        </th>
                                                        <th className="text-center">
                                                            Title
                                                        </th>
                                                        <th className="text-center">
                                                            A. Int.
                                                        </th>
                                                        <th className="text-center">
                                                            B. Int.
                                                        </th>
                                                        <th className="text-center">
                                                            B. Nat
                                                        </th>
                                                        <th className="text-center">
                                                            N. Nat
                                                        </th>
                                                        <th className="text-center">
                                                            C. Int
                                                        </th>
                                                        <th className="text-center">
                                                            C. Nat
                                                        </th>
                                                        <th className="text-center">
                                                            Sp. Org
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="text-center">
                                                    {pointsDestributionData.map((x, index) => (
                                                        <tr >
                                                            <td className="rtl text-center bold">{x.classRank}</td>
                                                            <td className="rtl text-center bold">
                                                                {x.title}
                                                            </td>
                                                            <td className="rtl text-center bold">
                                                                {x.aint}
                                                            </td>
                                                            <td className="rtl text-center bold">
                                                                {x.bint}
                                                            </td>
                                                            <td
                                                                className={`rtl text-center bold `}
                                                            >
                                                                {x.bnat}
                                                            </td>
                                                            <td className="rtl text-center bold" >
                                                                {x.nnat}
                                                            </td>
                                                            <td className="rtl text-center bold" >
                                                                {x.cint}
                                                            </td>
                                                            <td className="rtl text-center bold">
                                                                {x.cnat}
                                                            </td>
                                                            <td className="rtl text-center bold">
                                                                {x.sporg}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {lang == 'en' ? '' : <p style={{fontSize:'12px'}} class="bold text-start text-danger">* اللغة المعتمدة هي اللغة الانجليزية</p>}
                                    </>
                                }
                            </div>
                            {mainActiveTab == 3 &&
                                <div className='col-12 p0 mt-3'>

                                    <h5 className='bold'> {umbracoData?.prizeTitle}</h5>
                                    <h5 className='bold mt-3'>{umbracoData?.prizeSubtitle}</h5>

                                    <ul>
                                        <li>
                                            {lang == 'en' ? 'Junior Fillies' : 'المهرات'}
                                        </li>
                                        <li>
                                            {lang == 'en' ? 'Senior Mares' : 'الأفراس'}
                                        </li>
                                        <li>
                                            {lang == 'en' ?
                                                'Junior Colts' : 'الأمهار'
                                            }
                                        </li>
                                        <li>
                                            {lang == 'en' ?
                                                'Senior Stallions' : 'الفحول'
                                            }
                                        </li>
                                    </ul>
                                    <h5 className='bold my-3'>{umbracoData?.totalPrizeMoneyLable}</h5>

                                    {/* <div className="col-12 row AuctionDetailsRow AuctionDetailsRowTabs rtl p0 mx-0" style={{ padding: 0 }}>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${prizeTab == 1 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setprizeTab(0);
                                                    setTimeout(() => {
                                                        setprizeTab(1);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ? 'Junior Fillies' : 'المهرات'}

                                            </span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${prizeTab == 2 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setprizeTab(0);
                                                    setTimeout(() => {
                                                        setprizeTab(2);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ? 'Senior Mares' : 'الأفراس'}

                                            </span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${prizeTab == 3 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setprizeTab(0);
                                                    setTimeout(() => {
                                                        setprizeTab(3);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ?
                                                    'Junior Colts' : 'الأمهار'
                                                }
                                            </span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${prizeTab == 4 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setprizeTab(0);
                                                    setTimeout(() => {
                                                        setprizeTab(4);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ?
                                                    'Senior Stallions' : 'الفحول'
                                                }
                                            </span>
                                        </div>
                                    </div> */}
                                    {(prizeTab == 1 || prizeTab == 2 || prizeTab == 3 || prizeTab == 4) &&
                                        <div className='row rtl mt-3 mx-0 '>
                                            <div className='col-lg-6 col-md-6 col-sm-12'>
                                                <table className="table tablenominwidth table-striped table-bordered rtl horseResultTable">
                                                    <thead className="theadHorseSelect">

                                                        <tr>
                                                            <th className="text-center">
                                                                {translate('web.rank', 'Rank')}
                                                            </th>
                                                            <th className="text-center">
                                                                {translate('web.prizeVal', 'Prize Value')}
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="text-center">
                                                        {prizeTab == 1 && juniorFillies.map((x, index) => (
                                                            <tr >
                                                                <td className="rtl text-center bold" dangerouslySetInnerHTML={{ __html: getOrdinal(x.properties.rank, lang) }} />
                                                                <td
                                                                    className={`rtl text-center `}
                                                                >
                                                                    {x.properties.prizeValue.toLocaleString('en-US')}  {translate('web.aed', 'AED')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {/* {prizeTab == 2 && seniorMares.map((x, index) => (
                                                            <tr >
                                                                <td className="rtl text-center bold" dangerouslySetInnerHTML={{ __html: getOrdinal(x.properties.rank, lang) }} />
                                                                <td
                                                                    className={`rtl text-center `}
                                                                >
                                                                    {x.properties.prizeValue.toLocaleString('en-US')}  {translate('web.aed', 'AED')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {prizeTab == 3 && juniorColts.map((x, index) => (
                                                            <tr >
                                                                <td className="rtl text-center bold" dangerouslySetInnerHTML={{ __html: getOrdinal(x.properties.rank, lang) }} />
                                                                <td
                                                                    className={`rtl text-center `}
                                                                >
                                                                    {x.properties.prizeValue.toLocaleString('en-US')}  {translate('web.aed', 'AED')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        {prizeTab == 4 && seniorStallions.map((x, index) => (
                                                            <tr >
                                                                <td className="rtl text-center bold" dangerouslySetInnerHTML={{ __html: getOrdinal(x.properties.rank, lang) }} />
                                                                <td
                                                                    className={`rtl text-center `}
                                                                >
                                                                    {x.properties.prizeValue.toLocaleString('en-US')}  {translate('web.aed', 'AED')}
                                                                </td>
                                                            </tr>
                                                        ))} */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }

                                </div>
                            }
                            {mainActiveTab == 4 &&
                                <>
                                    <div dangerouslySetInnerHTML={{__html:umbracoData?.currentSeason?.markup}} />

                                    <div className="col-12 row AuctionDetailsRow AuctionDetailsRowTabs rtl p0 m0" style={{ padding: 0 }}>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${activeTab == 1 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setActiveTab(0);
                                                    setTimeout(() => {
                                                        setActiveTab(1);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ? 'Junior Fillies' : 'المهرات'}

                                            </span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${activeTab == 2 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setActiveTab(0);
                                                    setTimeout(() => {
                                                        setActiveTab(2);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ? 'Senior Mares' : 'الأفراس'}

                                            </span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${activeTab == 3 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setActiveTab(0);
                                                    setTimeout(() => {
                                                        setActiveTab(3);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ?
                                                    'Junior Colts' : 'الأمهار'
                                                }
                                            </span>
                                        </div>
                                        <div className="col-lg-2 col-md-2 col-sm-3 p0 mx-2" style={{ minWidth: 220 }}>
                                            <span
                                                className={`customTabStyle ${activeTab == 4 ? 'customTabactive' : ''}`}
                                                onClick={() => {
                                                    setActiveTab(0);
                                                    setTimeout(() => {
                                                        setActiveTab(4);
                                                    }, 200);
                                                }}
                                            >
                                                {lang == 'en' ?
                                                    'Senior Stallions' : 'الفحول'
                                                }
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-12 p0 mt-2 currentFont'>
                                        {liveRanking.length > 0 ?
                                            <div className="scrolableTable px-2">
                                                {((activeTab == 1 || activeTab == 2 || activeTab == 3 || activeTab == 4)) &&
                                                    <table className="table table-striped table-bordered rtl horseResultTable">
                                                        <thead className="theadHorseSelect">

                                                            <tr>
                                                                <th className="text-center">
                                                                    {translate('web.rank', 'Rank')}
                                                                </th>
                                                                <th className="text-center">
                                                                    {translate('web.ownerRegNo', 'Owner Reg No')}
                                                                </th>
                                                                <th className="text-center">
                                                                    {translate('web.owner', 'Owner')}
                                                                </th>
                                                                <th className="text-center">
                                                                    {translate('web.horseRegNo', 'Horse Reg No')}
                                                                </th>
                                                                <th className="text-center">
                                                                    {translate('web.theHorse', 'Horse')}
                                                                </th>
                                                                <th className="text-center">
                                                                    {translate('web.totalPoints', 'Total Points')}
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center">
                                                            {filterClassDataByCategory(activeTab).map((x, index) => (
                                                                <tr >
                                                                    <td className="rtl text-center bold" dangerouslySetInnerHTML={{ __html: getOrdinal(x.finalRank, lang) }} />
                                                                    <td className="rtl text-center">
                                                                        {x.ownerRegNo}
                                                                    </td>
                                                                    <td className="rtl text-center">
                                                                       {x.owner}
                                                                    </td>
                                                                    <td className="rtl text-center">
                                                                        {x.horseRegNo}
                                                                    </td>
                                                                    <td className="rtl text-center">
                                                                        {x.horse}
                                                                    </td>
                                                                    <td
                                                                        className={`rtl text-center `}
                                                                    >
                                                                        {x.totalPoints}
                                                                    </td>


                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </div>
                                            :
                                            <Loader />
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    }
                </>
            }
        </div>
    )
}
