import React from 'react'
import SectionTitle from '../components/SectionTitle'
import ServicesSection from '../components/ServicesSection'
import { useTranslate } from '../components/TranslationText'

export default function ServicesPage() {
    const {translate} = useTranslate();
  return (
    <>
        <ServicesSection />
    </>
  )
}
