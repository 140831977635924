
/**
 * Can be refactored as 
 * @deprecated return Array.from({ length: 7 }, (_, i) => new Date(year - (month < 8 ? 1 : 0), i + 8, 1));
 * By Walid
 * @param {number} year The season start year. Start Year will Come From Server So no need for if else
 * @returns Array of season months as date object
 */
export function CalenderSeasonsCalculator(year = new Date().getFullYear()) {
    const now = new Date();
    const month = now.getMonth();

    let dates = [];
        dates = [
            new Date(Number(year), 9, 1), // 1st October last Number(year)
            new Date(Number(year), 10, 1), // 1st November last Number(year)
            new Date(Number(year), 11, 1), // 1st December last Number(year)
            new Date(Number(year) + 1, 0, 1), // 1st January next Number(year)
            new Date(Number(year) + 1, 1, 1), // 1st February next Number(year)
            new Date(Number(year) + 1, 2, 1),  // 1st March next year
            new Date(Number(year) + 1, 3, 1),  // 1st April next year
            new Date(Number(year) + 1, 4, 1),  // 1st May next year
            new Date(Number(year) + 1, 5, 1),  // 1st June next year
            new Date(Number(year) + 1, 6, 1),  // 1st July next year
            new Date(Number(year) + 1, 7, 1),  // 1st August next year
            new Date(Number(year) + 1, 8, 1),  // 1st September next year
        ];
    return dates;
}

