import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import ActionButton from "../components/ActionButton";
import { useLocation } from "react-router";
import { formatDateTimeToString } from "../helper/formatDateTimeToString";
import ImagesSlider from "../components/ImagesSlider";

const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function SinglePost() {
    const BaseURL = process.env.REACT_APP_BASE_URL;
    const { currentLanguage } = useSelector(
        (state) => state.studBookSlice
    );
    const [post, setPost] = useState([]);
    const { translate } = useTranslate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.has("id")
        ? searchParams.get("id")
        : '';



    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang == "en") return 'en-US';
        else return 'ar-AE'; // Defaulting to English for demonstration
    }


    useEffect(() => {
        async function getdata() {
            const umbraco = await axios.get(`/umbraco/delivery/api/v1/content/item?id=${id}`, {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(),

                }
            })
            // console.log('Umbraco News are umbraco is', umbraco.data);
            const formattedData = umbraco.data?.map((item) => ({
                Item_Title: item?.properties?.Item_Title ?? '',
                Item_Date: formatDateTimeToString(item?.properties?.Item_Date, getCurrentLanguageUmbraco()) ?? "",
                Item_ShortDescription: item?.properties?.Item_ShortDescription ?? '',
                Item_Body: item?.properties?.Item_Body?.markup ?? '',
                Item_CoverImage: item.properties?.Item_CoverImage ? item.properties?.Item_CoverImage : [],
                createdBy: item?.properties?.createdBy ?? "",
            }));;
            setPost(formattedData);
        }
        getdata();

    }, [currentLanguage])
    return (
        <>
            {post.length === 0 &&
                <Loader />
            }
            {post.length > 0 &&
                post.map((post) => (
                    <div className='container'>
                        <div className='row rtl'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <h3 className='NewsPostTitle bold'>
                                    {post.Item_Title}
                                </h3>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='NewsPostDate'>
                                    {post.Item_Date}
                                </div>
                            </div>
                            {/* {post.createdBy != "" && post.createdBy != null && post.createdBy != undefined &&
                                <div className='col-lg-9 col-md-9 col-sm-6'>
                                    <div className='NewsPostDate'>
                                        {translate('web.createdBy', 'Created By') + ": " + post.createdBy}
                                    </div>
                                </div>
                            } */}
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                {post.Item_CoverImage.length > 0 && post.Item_CoverImage.length == 1 &&
                                    <img className='NewsPostimg singlePostImage' src={`${MediaBaseURL}${post.Item_CoverImage[0].url}`} alt='postName' />
                                }
                                {post.Item_CoverImage.length > 1 &&
                                    <ImagesSlider
                                        data={post.Item_CoverImage}
                                        sliderName={"singlepost"}
                                        type={"singlepost"}
                                    />
                                }
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div dangerouslySetInnerHTML={{ __html: post.Item_Body }} className='NewsPostBody' />
                            </div>

                        </div>
                    </div>
                ))
            }
        </>

    )
}
