import React, { useRef, useEffect, useState } from 'react';

const YouTubePlayer = ({ videoId, isSecondOption = false, item }) => {
  const playerRef = useRef(null);
  const playerInstanceRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const createPlayer = () => {
    if (playerInstanceRef.current) {
      // If the player already exists, load the new video
      playerInstanceRef.current.loadVideoById(videoId);
    } else {
      // Create a new player instance
      playerInstanceRef.current = new window.YT.Player(playerRef.current, {
        height: '100%',
        width: '100%',
        videoId: videoId,
        playerVars: {
          autoplay: 1,
          loop: 1,
          playlist: videoId, // Required for looping
        },
        events: {
          onReady: (event) => {
            event.target.mute(); // Mute the video by default
            event.target.playVideo(); // Start playing the video
          },
        },
      });
    }
  };

  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        // If YT object doesn't exist, load the YouTube API script
        const scriptTag = document.createElement('script');
        scriptTag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(scriptTag);

        scriptTag.onload = () => {
          // Once the script is loaded, create the player
          window.YT.ready(() => {
            createPlayer();
          });
        };
      } else {
        // If the YT object already exists, create the player immediately
        createPlayer();
      }
    };

    loadYouTubeAPI();

    return () => {
      // Clean up the player instance when the component is unmounted
      if (playerInstanceRef.current) {
        playerInstanceRef.current.destroy();
        playerInstanceRef.current = null;
      }
    };
  }, [videoId]); // Depend on videoId to recreate the player when it changes

  const muteUnmuteTrigger = () => {
    if (isMuted) {
      playerInstanceRef.current.unMute();
    } else {
      playerInstanceRef.current.mute();
    }
    setIsMuted(!isMuted);
  };

  return (
    <>
      {!isSecondOption ? (
        <>
          <div className='video_player' ref={playerRef} style={{maxHeight:380}} />
          {/* <div
            style={{
              zIndex: 1000,
              position: 'absolute',
              right: 75,
              top: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className='volumeControl' onClick={muteUnmuteTrigger}>
              {isMuted ? (
                <i className='fa-solid fa-volume-xmark'></i>
              ) : (
                <i className='fa-solid fa-volume-high'></i>
              )}
            </div>
          </div> */}
        </>
      ) : (
        <div
          className='container justRotate'
          style={{
            display: 'flex',
            flexWrap: 'wrap-reverse',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 25,
          }}
        >
          <div
            className='currentFont justRotate text-center'
            style={{ maxWidth: 500, fontSize: 50, fontWeight: 'bold' }}
          >
            {item.title} <br />
          </div>
          <div
            className='justRotate'
            style={{ width: '50%', minHeight: '30vh' , maxHeight:380 }}
            ref={playerRef}
          />
        </div>
      )}
    </>
  );
};

export default YouTubePlayer;
