import React from "react";
import { Link } from "react-router-dom";

const PedigreeChart = ({ hierarchy }) => {
  // console.log("data is hierarchy", hierarchy);
  const horseProfileURL = '/horsedetails?horseId=';
  function checkid(id) {
    if (id == 'unknown') return '';

    else return id;
  }
  return (
    <div className="d-flex-js-center-align-items-center">
      <div className="tree-container">
        <div className="first-level">
          <Link className="horseName">
            <span className="horseNameSpan">
              {hierarchy.name}
            </span>
            <span className="PedigreeChartSpan" ></span>
          </Link>
        </div>
        <div className="first-level-spacer">
          <div className="upspacer"></div>
          <div className="downspacer"></div>
        </div>
        <div className="second-level">
          <div className="second-level-mom ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-dad ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
        </div>
        <div className="second-level-spacer">
          <div className="second-level-spacer-container-mom">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
        </div>
        <div className="third-level">
          <div className="second-level-mom-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[0].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-dad-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[1].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-mom-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[0].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-dad-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[1].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
        </div>
        <div className="third-level-spacer">
          <div className="second-level-spacer-container-mom-first">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-first">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-mom-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
        </div>
        <div className="fourth-level">
          <div className="second-level-mom-first-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[0].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[0].children[0].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-dad-first-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[0].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[0].children[1].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-mom-second-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[1].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[1].children[0].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-dad-second-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[1].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[1].children[1].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-mom-third-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[0].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[0].children[0].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-dad-third-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[0].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[0].children[1].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-mom-fourth-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[1].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[1].children[0].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[1].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[1].children[1].name}
              </span>
              <span className="PedigreeChartSpan" ></span>
            </Link>
          </div>
        </div>
        <div className="third-level-spacer">
          <div className="second-level-spacer-container-mom-first">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-first">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-mom-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
          <div className="second-level-spacer-container-dad-second">
            <div className="upspacer"></div>
            <div className="downspacer"></div>
          </div>
        </div>
        <div className="fourth-level">
          <div className="second-level-mom-first-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[0].children[0].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[0].children[0].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-first-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[0].children[0].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[0].children[0].children[1].name}
              </span>
            </Link>
          </div>
          <div className="second-level-mom-second-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[0].children[1].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[0].children[1].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-second-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[0].children[1].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[0].children[1].children[1].name}
              </span>
            </Link>
          </div>
          <div className="second-level-mom-third-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[1].children[0].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[1].children[0].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-third-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[1].children[0].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[1].children[0].children[1].name}
              </span>
            </Link>
          </div>
          <div className="second-level-mom-fourth-first ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[1].children[1].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[1].children[1].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[0].children[1].children[1].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[0].children[1].children[1].children[1].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[0].children[0].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[0].children[0].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[0].children[0].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[0].children[0].children[1].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second " >
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[0].children[1].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[0].children[1].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[0].children[1].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[0].children[1].children[1].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[1].children[0].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[1].children[0].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[1].children[0].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[1].children[0].children[1].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[1].children[1].children[0].id)}`} className="horseName color_Blue">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[1].children[1].children[0].name}
              </span>
            </Link>
          </div>
          <div className="second-level-dad-fourth-second ">
            <Link to={`${horseProfileURL}${checkid(hierarchy.children[1].children[1].children[1].children[1].id)}`} className="horseName perpul">
              <span className="horseNameSpan">
                {hierarchy.children[1].children[1].children[1].children[1].name}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PedigreeChart;