import React, { useEffect, useState } from 'react';
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { convertYouTubeURL, getThumbnailUrlFromEmbedLink } from '../helper/getThumbnailUrlFromEmbedLink';
import { useLocation } from 'react-router';
import { useTranslate } from '../components/TranslationText';
import { useSelector } from 'react-redux';
import axios from '../axios/axiosUmbraco';
import { formatDate } from '../helper/formatDate';
import ActionButton from '../components/ActionButton';

const GalleryId = process.env.REACT_APP_GALLERY_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function AlbumPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentAlbumId = searchParams.has("id")
        ? searchParams.get("id")
        : false;

    const { translate } = useTranslate();

    const { currentLanguage } = useSelector((state) => state.studBookSlice);
    const [gallery, setGalleryItems] = useState([]);
    const [loaded, setLoaded] = useState(false);

    // Pagination states
    const [page, setPage] = useState(1); // Current page
    const [pageSize] = useState(20); // Number of images per page
    const [formattedImages, setFormattedImages] = useState([]);
    const [images, setImages] = useState([]);
    const [imageslightbox, setImageslightbox] = useState([]);

    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang === "en") return "en-US";
        else return "ar-AE"; // Defaulting to English for demonstration
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const umbraco = await axios.get(
                    `/umbraco/delivery/api/v1/content/item?id=${currentAlbumId}`,
                    {
                        headers: {
                            "accept-language": getCurrentLanguageUmbraco(),
                        },
                    }
                );

                 

                const formattedData = umbraco.data?.map((item) => ({
                    albumName: item?.properties?.albumName ?? "",
                    albumVideos: item?.properties?.albumMedia ?? [],
                    albumImages: item?.properties?.albumImages ?? [],
                    eventStartDate: item?.properties?.eventStartDate ?? '',
                    eventEndDate: item?.properties?.eventEndDate ?? '',
                    mediaFolderPath: item?.properties?.mediaFolderPath ?? '',
                }));

                if (formattedData[0].mediaFolderPath != '') {
                    const folderImages = await axios.get(`umbraco/delivery/api/v1/media?fetch=children:${formattedData[0].mediaFolderPath}&skip=0&take=2000`)

                    if (folderImages.data.items.length > 0) {
                        const images = folderImages.data.items.map((image, index) => ({
                            src: MediaBaseURL + image.url,
                            width: 320,
                            isvideo: false,
                            height: 174,
                            caption: image.name,
                        }));

                        const videos = formattedData[0].albumVideos.map((video, index) => ({
                            src: video.properties.albumVideo,
                            width: 320,
                            isvideo: true,
                            height: 174,
                            caption: 'video',
                        }));

                        setGalleryItems(formattedData);
                        setFormattedImages([...videos, ...images]);
                        setImages(reformatImages([...videos, ...images], false));
                        setImageslightbox(reformatImages([...videos, ...images], true));
                        setLoaded(true);
                    }
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [currentLanguage]);

    // Function to load more images
    const loadMoreImages = () => {
        // Here you can implement logic to fetch more images or simply increment the page number
        // For demonstration, let's increment the page number
        setPage(page + 1);
    };

    function reformatImages(images, isLightBox) {
        if (isLightBox !== true) {
            return (
                images.map((image, index) => ({
                    src: image.isvideo ? convertYouTubeURL(getThumbnailUrlFromEmbedLink(image.src)) : getThumbnailUrlFromEmbedLink(image.src),
                    width: image.width,
                    height: image.height,
                    isvideo: image.isvideo,
                    tags: image.isvideo ? [
                        { value: <i className="fa-solid fa-play" style={{ fontSize: 18 }}></i> },
                    ] : [],
                }))
            );
        } else {
            return (
                images.map((image, index) => ({
                    src: convertYouTubeURL(image.src),
                    width: image.width,
                    height: image.height,
                    isvideo: image.isvideo,
                    tags: image.isvideo ? [
                        { value: <i className="fa-solid fa-play" style={{ fontSize: 18 }}></i> },
                    ] : [],
                }))
            );
        }
    }

    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    function getVideoFrame(index) {
        return (<iframe width="90%" height="90%" src={imageslightbox[index].src} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>);
    }

    return (
        <div className='container-fluid px75'>
            {loaded &&
                gallery.map((album, index) => (
                    <React.Fragment key={index}>
                        <div className='row m0 rtl'>
                            <h2 className='supplierTitle'>
                                {album.albumName}
                            </h2>
                        </div>
                        <div className='row m0 justRotate'>
                            <div className="col-md-2  showDetaisLabel justRotate rtl">
                                <i
                                    className="fa fa-calendar C89C66"
                                    style={{ margin: "0 5px" }}
                                ></i>
                                <span>{translate('web.from', 'From')} : {formatDate(album.eventStartDate)}</span>
                            </div>
                            <div className="col-md-2  showDetaisLabel justRotate rtl">
                                <i
                                    className="fa fa-calendar C89C66"
                                    style={{ margin: "0 5px" }}
                                ></i>
                                <span>{translate('web.to', 'To')} : {formatDate(album.eventStartDate)}</span>
                            </div>
                        </div>
                        <div className='row m0 rtl'>
                            <Gallery
                                images={images.slice(0, page * pageSize)} // Displaying images based on pagination
                                onClick={(index) => handleClick(index)} // Handle click logic
                                enableImageSelection={false}
                                thumbnailStyle={{height:"unset" , width:'100%'}}
                            />
                        </div>
                        {formattedImages.length > page * pageSize && ( // Check if there are more images to load
                            <div className='row justify-content-center text-center'>
                                <div className='col-lg-2 col-md-2 col-sm-12 mt-3'>

                                <ActionButton label={translate('web.loadMore', 'Load More')} type={'loadmore'} onClick={loadMoreImages} className="loadMoreBtn" />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            {!!currentImage && (
                /* @ts-ignore */
                <Lightbox
                    mainSrc={imageslightbox[index].src}
                    nextSrc={imageslightbox[(index + 1) % imageslightbox.length].src}
                    prevSrc={imageslightbox[(index + imageslightbox.length - 1) % imageslightbox.length].src}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={() =>
                        setIndex((index + imageslightbox.length - 1) % imageslightbox.length)
                    }
                    onMoveNextRequest={() =>
                        setIndex((index + 1) % imageslightbox.length)
                    }
                    wrapperClassName='galleryContainer'
                    imageLoadErrorMessage={getVideoFrame(index)}
                />
            )}
        </div>
    );
}
