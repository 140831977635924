import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslate } from "./TranslationText";
import { Link } from "react-router-dom";
const BaseURL = process.env.REACT_APP_BASE_URL;

function FileUpload({
  multiple = true,
  onFilesUploaded,
  emptyFiles = true,
  setEmptyFiles,
  urlImages,
  setUrlImages,
  showToastMsg,
  noBorder = false,
  required
}) {
  const [files, setFiles] = useState([]);
  const { translate } = useTranslate();

  const onDrop = useCallback(
    (acceptedFiles) => {
      let newFiles = [];
      multiple? newFiles = [...files, ...acceptedFiles] : newFiles = [...acceptedFiles]

      // console.log(newFiles);
      setFiles(newFiles);
      onFilesUploaded(newFiles);
    },
    [files, onFilesUploaded]
  );

  useEffect(() => {
    if (!emptyFiles) {
      setFiles([]);
      setEmptyFiles(true);
    }
  }, [emptyFiles]);

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    onFilesUploaded(updatedFiles);
  };

  const handelRemoveImage = (index) => {
    const updatedImages = [...urlImages];
    updatedImages.splice(index, 1);
    setUrlImages(updatedImages);
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((fileRejection) => {
      fileRejection.errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          showToastMsg();
        }
      });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: 10 * 1024 * 1024,
    onDropRejected,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
      //"video/mp4": [],
    },
    multiple: multiple,
  });
 

  return (
    <div className="row FileUploadRow m0" style={{display:'flex', justifyContent:'center' , alignItems:'center' , border:noBorder?0:'1px solid #CDAA84'}}>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className="DragActive">
            {" "}
            <img
              src={`${BaseURL}/assets/img/uploadicon.png`}
              alt="uploadicon"
              className="uploadicon"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${BaseURL}/assets/img/horseauction.png`;
              }}
            />{" "}
            {translate("web.Dropthefileshere", "Drop the files here ...")}
          </div>
        ) : (
          <div className="DragActive">
            <img
              src={`${BaseURL}/assets/img/uploadicon.png`}
              alt="uploadicon"
              className="uploadicon"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${BaseURL}/assets/img/horseauction.png`;
              }}
            />
            <h4>{translate("web.choosefile", "Choose file")}</h4>
            <span className="Gray_color">
              {translate("web.typeformats", "PDF")}
            </span>
            <div className="Upload_container">
              <span className="margin_auto">
                {translate("web.upload", "Upload")}
              </span>
            </div>
          </div>
        )}
        
      </div>
      <div className="mt-20d-flex-flexwrap">
        {files.map((file, index) => (
          <div key={index} style={fileStyle}>
            <div>
              {file.name} - {file.size} bytes
            </div>
            <div
              className="Upload_container2"
              onClick={() => removeFile(index)}
            >
              <span className="margin_auto">X</span>
            </div>
            {file.type.startsWith("image") && (
              <img
                src={URL.createObjectURL(file)}
                alt="Preview"
                className="UploadPreviewIMG"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                }}
              />
            )}
            {file.type.startsWith("video") && (
              <video controls className="UploadPreviewIMG">
                <source src={URL.createObjectURL(file)} type={file.type} />
              </video>
            )}
            {file.type.startsWith("application/pdf") && (
              <Link
                to={URL.createObjectURL(file)}
                className="C89C66 flex-center cursorPointer"
                target="_blank"
              >
                <i className="fa fa-file-pdf fa-7x UploadPreviewIMG"></i>
              </Link>
            )}
          </div>
        ))}

        {!!urlImages &&
          urlImages.length > 0 &&
          urlImages.map((url, index) => (
            <div key={index} style={fileStyle}>
              <div>{url.substr(url.lastIndexOf("/") + 1)}</div>
              <div
                className="Upload_container2"
                onClick={() => handelRemoveImage(index)}
              >
                <span className="margin_auto">X</span>
              </div>
              {url.substr(url.lastIndexOf(".") + 1) === "pdf" ? (
                <Link
                  to={url}
                  className="C89C66 flex-center cursorPointer"
                  target="_blank"
                >
                  <i className="fa fa-file-pdf fa-7x UploadPreviewIMG"></i>
                </Link>
              ) : (
                <img
                  src={url}
                  alt="Preview"
                  className="UploadPreviewIMG"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                  }}
                />
              )}
            </div>
          ))}
      </div>
    </div>
  );
}

const dropzoneStyle = {
  border: "0px dashed #CDAA84",
  borderRadius: "4px",
  padding: "5px 20px",
  textAlign: "center",
  cursor: "pointer",
  maxWidth: 500,
};

const fileStyle = {
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "10px",
  marginTop: "10px",
};
export default FileUpload;
