import React, { useEffect } from "react";
import { useTranslate } from "../components/TranslationText";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchShowEntrybyIdthunk } from "../Redux/ShowEntry/ShowEntryAction";

export default function BookingSubmissionSuccessful() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let EventName = searchParams.has("EventName") ? searchParams.get("EventName") : '';
  const lang = localStorage.getItem('eahsLang');
  if (lang == 'ar') {
    let encodedString = `&${window.location.hash}`;

    // Decode the HTML entities to characters
    let decodedString = encodedString.replace(/&#x([0-9A-F]{1,4});/gi, function (match, num) {
      return String.fromCharCode(parseInt(num, 16));
    });

    // Decode the URL-encoded parts
    decodedString = decodeURIComponent(decodedString);
    EventName = decodedString
  }




  return (
    <div className="container-fluid container-fluid-padding row">
      {
        // title
      }
      <div className="col-md-12 col-sm-12  mt-15 mb-25">
        <h1 className="fontWeight">{EventName}</h1>
      </div>
      {
        //text
      }
      <div className="col-md-12 col-sm-12 text-center">
        <h1 className="color-green">
          {translate(
            "web.paymentCompletedSuccessful",
            "Payment Completed Successful!"
          )}
        </h1>


        <Link
          type="button"
          className="btn buttonformshowentrycard height46 linkbtn mt-50"
          to={`/`}
        >
          <span className="ml-10 margin-left-unset">
            <i class="fa-solid fa-arrow-left fa-lg marginrightleft-10"></i>
            {translate("web.back", "Back")}
          </span>
        </Link>
      </div>
    </div>
  );
}
