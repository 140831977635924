import React, { useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { useDispatch, useSelector } from 'react-redux';
import { resetFilter } from "../Redux/Auctions/AuctionsSlice";
import { fetchAuctions } from '../Redux/Auctions/AuctionActions';
import { useTranslate } from "./TranslationText";

const NewsFilter = ({ onSubmit }) => {
  const [checker, setChecker] = useState(true);
  const { translate } = useTranslate();
  const [dateStartFrom, setDateStartFrom] = useState(null);
  const [dateStartTo, setDateStartTo] = useState(null);

  const [filterState, setFilterState] = useState({
    // Initial filter values
    newsName: "",
    newscategory: "both",
    newsClassification:"both",
    DateFrom: "",
    DateTo: "",
  });

  const dispatch = useDispatch();

  const handleChange = (event, input) => {
    if (!!input && input === "DateFrom") {
      setDateStartFrom(event)
      setFilterState({
        ...filterState,
        DateFrom: event.$d.toISOString(),
      });
      return;
    }
    if (!!input && input === "DateTo") {
      setDateStartTo(event)
      setFilterState({
        ...filterState,
        DateTo: event.$d.toISOString(),
      });
      return;
    }
    const value = event.target.type == 'checkbox' ? event.target.checked : event.target.value
    setFilterState({
      ...filterState,
      [event.target.name]: value,
    });


  };

  const handleSubmit = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    onSubmit(filterState); // Pass filter state to News
    // dispatch(resetFilter());
    // dispatch(fetchAuctions(filterState));
  };
  const handleReset = (event) => {
    window.scrollTo(0, 0);

    setDateStartFrom(null)
    setDateStartTo(null)

    setFilterState({
      newsName: "",
      newscategory: "both",
      newsClassification:"both",
      DateFrom: "",
      DateTo: "",
    });
  };



  return (
    <form
      className="AuctionFilter rtl"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <h2>{translate('web.advancedSearch', 'Advanced Search')}</h2>
      <NetaqInput
        name={'newsName'}
        label={translate("web.newsTitle", "News Title")}
        key={"search"}
        placeholder={translate("web.newsTitle", "News Title")}
        type={"text"}
        onChange={handleChange}
      />
      <label className="mb-10">{translate("web.newsType", "News Type")}</label>
      <div className="AuctionFilterRadioContainer">
        <NetaqInput
          name={"newscategory"}
          key={"fromEAHS"}
          type={"radio"}
          value={"fromEAHS"}
          label={translate("web.fromEAHS", "from EAHS")}
          onChange={handleChange}
        />
        <NetaqInput
          name={"newscategory"}
          key={"aboutEAHS"}
          type={"radio"}
          value={"aboutEAHS"}
          label={translate("web.aboutEAHS", "about EAHS")}
          onChange={handleChange}
        />

        <NetaqInput
          name={"newscategory"}
          key={"both"}
          type={"radio"}
          value={"both"}
          checked={checker}
          label={translate("web.both", "both")}
          onChange={handleChange}
        />
      </div>
      <label className="mb-10">{translate("web.newsClassification", "News Classification")}</label>
      <div className="AuctionFilterRadioContainer">
        <NetaqInput
          name={"newsClassification"}
          key={"General"}
          type={"radio"}
          value={"General"}
          label={translate("web.general", "General")}
          onChange={handleChange}
        />
        <NetaqInput
          name={"newsClassification"}
          key={"ActivitiesAndEvents"}
          type={"radio"}
          value={"ActivitiesAndEvents"}
          label={translate("web.eventsAndActivities", "Events And Activities")}
          onChange={handleChange}
        />

        <NetaqInput
          name={"newsClassification"}
          key={"both"}
          type={"radio"}
          value={"both"}
          checked={checker}
          label={translate("web.both", "both")}
          onChange={handleChange}
        />
      </div>

      <label className="">{translate("web.date", "Date")}</label>
      <div className="AuctionFilterNumberofhoursesContainer">
        <NetaqInput
          name={"DateFrom"}
          key={"DateFrom"}
          type={"date"}
          label={translate("web.from", "From")}
          onChange={handleChange}
          value={dateStartFrom}
        />
        <NetaqInput
          name={"DateTo"}
          key={"DateTo"}
          type={"date"}
          label={translate("web.to", "To")}
          onChange={handleChange}
          value={dateStartTo}
          minDate={dateStartFrom}
        />
      </div>
      <div className="AuctionFilterNumberofhoursesContainer mt-3">
        <ActionButton key={"reset"} type={"reset"} />
        <ActionButton text={translate("web.search", "Search")} key={"apply"} type={"submit"} />
      </div>
    </form>
  );
};

export default NewsFilter;
