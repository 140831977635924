import React, { useEffect, useState } from "react";
import NetaqInput from "../components/NetaqInput";
import { Link } from "react-router-dom";
import { useTranslate } from "../components/TranslationText";
import Loader from '../components/Loader';
import axios from '../axios/axios';
import Lightbox from "react-18-image-lightbox";
import WahoCard from "../components/WahoCardImage";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import CompetitionsTab from "../components/CompetitionsTab";
import {
  incrementPageHorseCompetition,

} from "../Redux/StudBook/StudBookSlice";
import {
  FetchStudBookHorsePedigreeByIdthunk,
  FetchStudBookHorseCompetitionthunk,
} from "../Redux/StudBook/StudBookAction";
import ActionButton from "../components/ActionButton";
import HorseDetailsPedigreeChart from "../components/HorseDetailsPedigreeChart";
import HorseGallery from "../components/HorseGallery";

const BaseURL = process.env.REACT_APP_BASE_URL;
const umbracoBase = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function WahoTrophy() {
  const { translate } = useTranslate();
  const [wahoList, setWahoList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorFound, setErrorFound] = useState(false);

  const [year, setYear] = useState({
    label: translate('web.year', 'Year'),
    value: null,
  });

  const [isSettedBefore, setIsSettedBefore] = useState(false);

  const handleChange = (event) => {
    setYear(event);
    if (!isSettedBefore) {
      setIsSettedBefore(true);
    }
    return;
  };

  const currentlang = localStorage.getItem('eahsLang') ?? 'en';
  const [yearsList, setYearsList] = useState([]);

  // get waho List
  async function getWahoList(x = true) {
    try {
      const response = await axios.get(`/wahotrophy/list?year=${x && year.value != null ? year.value : 0}&lang=${currentlang}`);
      if (response.data.responseCode == "200") {
        if (response.data.result.length > 0) {
          setWahoList(response.data.result.sort((b, a) => a.year - b.year));
          setLoaded(true);
        }
        else {
          setWahoList(response.data.result);
          setLoaded(true);
        }
        const formattedYearslist = response.data.result.map((item) => ({ text: item.year, value: item.year }));
        if (!isSettedBefore) {
          setYearsList(formattedYearslist);
        }
      } else {
        console.log('error in getting waho trophy');
        setLoaded(true);
        setErrorFound(true);
      }
    } catch (error) {
      console.log('error in getting waho trophy');
      setLoaded(true);
      setErrorFound(true);
    }
  }

  useEffect(() => {
    if (!!year) {
      getWahoList();
    } else {
      getWahoList(false);
    }
  }, [year]);

  // light Box
  const [imageslightbox, setImageslightbox] = useState([]);
  const [index, setIndex] = useState(-1);

  const currentImage = imageslightbox[index];
  const nextIndex = (index + 1) % imageslightbox.length;
  const nextImage = imageslightbox[nextIndex] || currentImage;
  const prevIndex = (index + imageslightbox.length - 1) % imageslightbox.length;
  const prevImage = imageslightbox[prevIndex] || currentImage;

  const handleClick = (clickedIndex, albumMedia, isBase64 = false) => {
    const allImages = wahoList.map((item) =>
      isBase64 ? albumMedia : `${umbracoBase}${albumMedia}`
    );

    setImageslightbox(allImages);
    setIndex(clickedIndex);
  };

  const handleClose = () => setIndex(-1);

  // compititions
  const {

    studBookHorseCompetitionData,
    takeHorseCompetition,
    pageHorseCompetition,
    totalCountHorseCompetition,
    studBookPedigreeData
  } = useSelector((state) => state.studBookSlice);

  const dispatch = useDispatch();

  const handlePageChangeHorseCompetition = (page) => {
    dispatch(incrementPageHorseCompetition(page));
  };

  const paginiateStyle = {
    width: 36,
    height: 36,
    background: "#E44D52",
    display: "inline-block",
    lineHeight: "37px",
    color: "#fff",
    cursor: "pointer",
    margin: 5,
    borderRadius: 8,
  };
  const pagenumberStyle = {
    ...paginiateStyle,
    width: 36,
    height: 36,
    background: "#ffffff",
    display: "inline-block",
    lineHeight: "37px",
    color: "black",
    cursor: "pointer",
    margin: 5,
    borderRadius: 8,
    borderColor: "#c0cedd",
    borderStyle: "solid",
  };

  const renderPaginationHorseCompetition = () => {
    const pages = [];

    const numPages = Math.ceil(
      totalCountHorseCompetition / takeHorseCompetition
    );

    for (let i = 1; i <= numPages; i++) {
      pages.push(
        <React.Fragment>
          <span
            key={i}
            style={{
              ...pagenumberStyle,
              background:
                i === pageHorseCompetition
                  ? "rgba(0, 0, 0)"
                  : pagenumberStyle.background,
              color:
                i === pageHorseCompetition ? "white" : pagenumberStyle.color,
            }}
            onClick={() => handlePageChangeHorseCompetition(i)}
          >
            {i}
          </span>
        </React.Fragment>
      );
    }

    let _pages = [];

    if (pages.length >= 5 && pageHorseCompetition === 1) {
      _pages = pages
        .slice(pageHorseCompetition - 1, 3)
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseCompetition > 1) {
      _pages = pages.slice(pageHorseCompetition);
    }
    if (pageHorseCompetition > 1 && numPages - pageHorseCompetition > 3) {
      _pages = pages
        .slice(pageHorseCompetition - 1, pageHorseCompetition)
        .concat(_pages.slice(0, 2))
        .concat(<span>...</span>)
        .concat(pages.slice(-1));
    }

    if (pageHorseCompetition === 1 && numPages - pageHorseCompetition <= 3) {
      _pages = pages;
      if (studBookHorseCompetitionData.length === 0) {
        _pages = [];
      }
    }

    if (pageHorseCompetition > 1 && numPages - pageHorseCompetition <= 3) {
      _pages = pages
        .slice(pageHorseCompetition - 1, pageHorseCompetition)
        .concat(_pages.slice(0, 3));
    }

    return _pages;
  };

  // end of compitiitns


  return (
    <div className='container-fluid px75'>
      {loaded && wahoList.length > 0 ? (
        <>
          {errorFound && (
            <div className="row m0 rtl text-danger">
              {translate('web.errorInGettingData', 'Error in getting Data')}
            </div>
          )}
          <div className='row m0 rtl' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
            <div className="col-lg-1 col-md-1 col-sm-12" style={{ minWidth: currentlang == 'en' ? "20%" : '15%' }}>
              <h3 className='NewsPostTitle bold' style={{ marginTop: 'unset' }}>
                {translate('web.theWahoTrophy', 'The Waho Trophy')}
              </h3>
            </div>
            {yearsList.length > 0 && (
              <div className='col-lg-3 col-md-3 col-sm-12' >
                <NetaqInput
                  isClearable={true}
                  type={'select'}
                  name={'year'}
                  label={translate('web.year', 'Year')}
                  placeholder={translate('web.year', 'Year')}
                  listSelect={yearsList}
                  value={year}
                  hideLable={true}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>

          {wahoList.map((item, index) => (
            <div className='row m0 rtl' style={{ padding: '10px 0', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} key={item.id}>
              <div className='wahoCard'>
                <img
                  className='wahoCardImg'
                  onClick={() => handleClick(index, [item.image])}
                  src={`${umbracoBase}${item.image}`}
                  alt='ChangeMe'
                />
                <div className='wahoCardContent'>
                  <div>
                    <div className='wahoContentTitle'>{item.year} : {item.name} - {item.registerNo}</div>
                    <div className='wahotrophyRow'>
                      <div className='wahoContentCompition '>{item.subTitle}</div>
                    </div>
                  </div>
                  <p className='wahoContentDescription' dangerouslySetInnerHTML={{ __html: item.description }} />

                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
                  {!!item.certificate &&
                    // <img
                    //   className='wahoCardImg'
                    //   onClick={() => handleClick(index, [item.certificate])}
                    //   src={`${umbracoBase}${item.certificate}`}
                    //   alt='ChangeMe'
                    // // style={{margin:'auto'}}
                    // />
                    <Link className=" hoveronspan ViewProfileLink text-end " style={{ width: 254 }}
                      onClick={() => handleClick(index, [item.certificate])}
                    >
                      {translate('web.showCertificate', 'Show Certificate')}
                      <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"></i>
                      <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"></i>
                      <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"></i>
                    </Link>

                  }

                  <Popup
                    modal
                    lockScroll={true}
                    onOpen={(e) => {
                      e.preventDefault();
                      let paramsHorseCompetition = {
                        id: item.id,
                        skip: (pageHorseCompetition - 1) * takeHorseCompetition,
                        take: takeHorseCompetition,
                        langauge: currentlang,
                      };
                      dispatch(FetchStudBookHorseCompetitionthunk(paramsHorseCompetition));
                    }}
                    trigger={
                      <Link className=" hoveronspan ViewProfileLink text-end " style={{ width: 254, textTransform: 'capitalize', cursor: 'pointer' }}

                      >
                        {translate('web.results', 'Results').toLowerCase()}
                        <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"></i>
                        <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"></i>
                        <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"></i>
                      </Link>
                    }
                    closeOnDocumentClick={false}
                    closeOnEscape
                    
                    contentStyle={{ background: 'rgba(255,255,255,1)' , width:'95%' , maxWidth:'1100px' , padding:'25px 5px'}}
                    overlayStyle={{ background: 'rgba(0,0,0,0.2)' }}
                  >
                    {(close) => (
                      <div className="row rtl justify-content-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 judgesList " style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
                          <CompetitionsTab
                            studBookHorseCompetitionData={studBookHorseCompetitionData}
                            translate={translate}
                            pageHorseCompetition={pageHorseCompetition}
                            handlePageChangeHorseCompetition={handlePageChangeHorseCompetition}
                            renderPaginationHorseCompetition={renderPaginationHorseCompetition}
                            totalCountHorseCompetition={totalCountHorseCompetition}
                            takeHorseCompetition={takeHorseCompetition}
                            className={'justify-content-center'}
                          />
                        </div>
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 text-center mt-3"
                        >
                          <ActionButton text={translate("web.close", "Close")} type={'submit'} onClick={close} margin={'auto'} />
                        </div>
                      </div>
                    )}
                  </Popup>
                  <Popup
                    modal
                    lockScroll={true}
                    className="popupmaxwidthUnset"
                    onOpen={(e) => {
                      e.preventDefault();


                      let paramsHorsePedigree = {
                        id: item.id,
                        langauge: currentlang,
                      };
                      dispatch(FetchStudBookHorsePedigreeByIdthunk(paramsHorsePedigree));

                    }}
                    trigger={
                      <Link className=" hoveronspan ViewProfileLink text-end  " style={{ width: 254, textTransform: 'capitalize', cursor: 'pointer' }}

                      >
                        {translate("web.pedigree", "Pedigree").toLowerCase()}
                        <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"></i>
                        <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"></i>
                        <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"></i>
                      </Link>
                    }
                    closeOnDocumentClick={false}
                    closeOnEscape
                    contentStyle={{ background: 'rgba(0,0,0,0)' }}
                    overlayStyle={{ background: 'rgba(0,0,0,0.2)' }}
                  >
                    {(close) => (
                      <div className="row  justify-content-center" >
                        <div className="col-lg-12 col-md-12 col-sm-12 judgesList " style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
                          <HorseDetailsPedigreeChart hierarchy={studBookPedigreeData} />
                        </div>
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 text-center mt-3"
                        >
                          <ActionButton text={translate("web.close", "Close")} type={'submit'} onClick={close} margin={'auto'} />
                        </div>
                      </div>
                    )}
                  </Popup>
                  {!!item.horseGallery &&
                    <>
                      <Popup
                        modal
                        lockScroll={true}
                        className="popupmaxwidthUnset"
                        trigger={
                          <Link className=" hoveronspan ViewProfileLink text-end  " style={{ width: 254, textTransform: 'capitalize', cursor: 'pointer' }}

                          >
                            {translate("web.mediaGallery", "Media Gallery").toLowerCase()}
                            <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"></i>
                            <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"></i>
                            <i className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"></i>
                          </Link>
                        }
                        closeOnDocumentClick={false}
                        closeOnEscape
                        contentStyle={{ background: 'rgba(0,0,0,0)' }}
                        overlayStyle={{ background: 'rgba(0,0,0,0.2)' }}
                      >
                        {(close) => (
                          <div className="row rtl justify-content-center" >
                            <div className="col-lg-12 col-md-12 col-sm-12 judgesList " style={{ maxHeight: 350, overflowY: 'scroll' }}>
                              <HorseGallery data={item.horseGallery} />
                            </div>
                            <div
                              className="col-lg-12 col-md-12 col-sm-12 text-center mt-3"
                            >
                              <ActionButton text={translate("web.close", "Close")} type={'submit'} onClick={close} margin={'auto'} />
                            </div>
                          </div>
                        )}
                      </Popup>
                    </>
                  }
                </div>

                {/* <WahoCard index={index} item={item} handleClick={handleClick} /> */}

              </div>
            </div>
          ))}
          {!!currentImage && (
            <Lightbox
              mainSrc={imageslightbox[index]}
              onCloseRequest={handleClose}
              wrapperClassName='galleryContainer'
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

