import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchHorseMarketAPI,
  FetchHorseMarketDetailsAPI,
} from "./HorseMarketApi";

export const FetchHorseMarketthunk = createAsyncThunk(
  "HorseMarket/FetchHorseMarket",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchHorseMarketAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchHorseMarketDetailsthunk = createAsyncThunk(
  "HorseMarket/FetchHorseMarketDetails",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchHorseMarketDetailsAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
