import React, { useState, useEffect } from 'react';
import pdfToImageUrl from '../helper/pdfToImage';
const umbracoBase = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;


function WahoCard({ item, index, handleClick }) {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        async function fetchImageUrl() {
            const url = await pdfToImageUrl(umbracoBase + item.certificate);
            setImageUrl(url);
        }
        fetchImageUrl();
    }, [item.certificate]); // Re-run the effect if item.certificate changes

    return (
        !!imageUrl && 

            <img
            className='wahoCardImg'
            onClick={() => handleClick(index, [imageUrl] , true)}
            src={imageUrl || 'default-placeholder.png'} // Use a placeholder until the image URL is ready
            alt='ChangeMe'
            />
        
    );
}

export default WahoCard;
