import React, { useEffect, useState } from "react";
import NetaqInput from "./NetaqInput";
import { useDispatch, useSelector } from "react-redux";
import { FetchStudBookCountriesthunk } from "../Redux/StudBook/StudBookAction";
import SubmitBtn from "./SubmitBtn";
import { useTranslate } from "./TranslationText";
import Select from "react-select";
import axios from "../axios/axios";

const HorseMarketFilter = ({ onSubmit }) => {
  const { studBookCountries, currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );

  const { marketType } = useSelector((state) => state.horseMarketSlice);
  const { translate } = useTranslate();
  const [cities, setCities] = useState([]);

  const dispatch = useDispatch();

  const [filterState, setFilterState] = useState({
    search: "",
    gender: [],
    category: [],
    priceFrom: null,
    priceTo: null,
    country: null,
    cityId: null,
    cityDrop: {
      label: translate("web.chooseEmirate", "Choose Emirate"),
      value: null,
    },
    countryDrop: {
      label: translate("web.chooseOrigin", "Choose Origin"),
      value: null,
    },
    birthDateFrom: null,
    birthDateTo: null,
    sortBy: "",
    sortByDrop: {
      label: translate("web.pleaseSelect", "Please Select"),
      value: null,
    },
  });
  const [birthDateFrom, setBirthdateFrom] = useState(null);
  const [birthDateTo, setBirthdateTo] = useState(null);

  useEffect(() => {
    dispatch(FetchStudBookCountriesthunk(currentLanguage));
  }, [currentLanguage]);

  const handleChange = (event, input) => {
    if (!!input && input === "birthDateFrom") {
      setBirthdateFrom(event);
      setFilterState({
        ...filterState,
        birthDateFrom: event.$d.toISOString(),
      });
      return;
    }
    if (!!input && input === "birthDateTo") {
      setBirthdateTo(event);
      setFilterState({
        ...filterState,
        birthDateTo: event.$d.toISOString(),
      });
      return;
    }
    if (!!input && input === "country") {
      setFilterState({
        ...filterState,
        country: event.value,
        countryDrop: event,
      });
      return;
    }
    if (!!input && input === "cityId") {
      setFilterState({
        ...filterState,
        cityId: event.value,
        cityDrop: event,
      });
      return;
    }
    if (!!input && input === "sortBy") {
      setFilterState({
        ...filterState,
        sortBy: event.value.toString(),
        sortByDrop: event,
      });
      return;
    }

    if (event.target.name !== "gender") {
      setFilterState({
        ...filterState,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === "gender") {
      if (!event.target.checked) {
        setFilterState({
          ...filterState,
          gender: filterState.gender.filter(
            (item) => item !== parseInt(event.target.value)
          ),
        });
      } else {
        setFilterState({
          ...filterState,
          gender: filterState.gender.concat(parseInt(event.target.value)),
        });
      }
    }

    if (event.target.name === "category") {
      if (!event.target.checked) {
        setFilterState({
          ...filterState,
          category: filterState.category.filter(
            (item) => item !== parseInt(event.target.value)
          ),
        });
      } else {
        setFilterState({
          ...filterState,
          category: filterState.category.concat(parseInt(event.target.value)),
        });
      }
    }
  };

  const handleSubmit = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    onSubmit(filterState);
  };

  const handleReset = () => {
    setBirthdateFrom(null);
    setBirthdateTo(null);
    setFilterState({
      search: "",
      gender: [],
      category: [],
      priceFrom: null,
      priceTo: null,
      country: null,
      countryDrop: {
        label: translate("web.chooseOrigin", "Choose Origin"),
        value: null,
      },
      cityId: null,
      cityDrop: {
        label: translate("web.chooseEmirate", "Choose Emirate"),
        value: null,
      },
      birthDateFrom: null,
      birthDateTo: null,
      sortBy: null,
      sortByDrop: {
        label: translate("web.pleaseSelect", "Please Select"),
        value: null,
      },
    });
  };

  const sortBy = [
    {
      text: translate("web.priceLowest", "Price Lowest"),
      value: 1,
    },
    {
      text: translate("web.priceHighest", "Price Highest"),
      value: 2,
    },
    {
      text: translate("web.ageYunger", "Age Yunger"),
      value: 3,
    },
    {
      text: translate("web.ageOlder", "Age Older"),
      value: 4,
    },
  ];
  async function getCitiesList() {
    try {
      const citiesList = await axios.get("/lookup/getcities");
      const formattedData = await citiesList.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      setCities(formattedData);
    } catch (error) {
      console.log("error in get Cities");
    }
  }

  useEffect(() => {
    getCitiesList();
  }, [currentLanguage]);

  return (
    <form
      className="horse-market-filter-form "
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <h2>{translate('web.advancedSearch', 'Advanced Search')}</h2>
      <NetaqInput
        label={translate("web.horseNameOrNumber", "Horse Name || Number")}
        name={"search"}
        placeholder={translate("web.horseNameOrNumber", "Horse Name || Number")}
        type={"text"}
        onChange={handleChange}
      />

      {marketType !== "SemenMarket" && (
        <React.Fragment>
          <label className="mb-10">{translate("web.gender", "Gender")}</label>
          <div className="AuctionFilterRadioContainer">
            <NetaqInput
              name={"gender"}
              type={"checkbox"}
              value={1}
              label={translate("web.mare", "Mare")}
              onChange={handleChange}
            />

            <NetaqInput
              name={"gender"}
              type={"checkbox"}
              value={2}
              label={translate("web.stallion", "Stallion")}
              onChange={handleChange}
            />

            <NetaqInput
              name={"gender"}
              type={"checkbox"}
              value={3}
              label={translate("web.gelding", "gelding")}
              onChange={handleChange}
            />
          </div>
        </React.Fragment>
      )}
      <label className="mb-10">{translate("web.category", "Category")}</label>
      <div className="AuctionFilterRadioContainer HorseMarketCategoriesContainer">
        <NetaqInput
          name={"category"}
          type={"checkbox"}
          value={1}
          label={translate("web.showHorse", "show Horse")}
          onChange={handleChange}
        />

        <NetaqInput
          name={"category"}
          type={"checkbox"}
          value={2}
          label={translate("web.flatRace", "Flat Race")}
          onChange={handleChange}
        />

        <NetaqInput
          name={"category"}
          type={"checkbox"}
          value={3}
          label={translate("web.enduranceHorse", "Endurance Horse")}
          onChange={handleChange}
        />
      </div>
      <NetaqInput
        name={"cityId"}
        type={"select"}
        label={translate("web.emirate", "City")}
        placeholder={translate("web.pleaseSelect", "Please Select")}
        listSelect={cities}
        onChange={(e) => handleChange(e, "cityId")}
        value={filterState.cityDrop}
      />
      <label className="mb-10">{translate("web.origin", "Origin")}</label>
      <div className="countrymargin">
        <Select
          options={[
            ...(!!studBookCountries &&
              studBookCountries.map((a) => ({
                label: a.text,
                value: a.value,
              }))),
          ]}
          value={filterState.countryDrop}
          onChange={(e) => handleChange(e, "country")}
          placeholder={translate("web.chooseOrigin", "Choose Origin")}
          name={"country"}
          isSearchable
          hideSelectedOptions
          noOptionsMessage={() => translate("web.noOptions", "No Options")}
        />
      </div>
      {marketType !== "HorseCharity" && (
        <React.Fragment>
          <label>{translate("web.price", "Price")}</label>
          <div className="AuctionFilterNumberofhoursesContainer">
            <NetaqInput
              name={"priceFrom"}
              type={"number"}
              label={translate("web.from", "From")}
              value={0}
              onChange={handleChange}
            />
            <NetaqInput
              name={"priceTo"}
              type={"number"}
              label={translate("web.to", "To")}
              onChange={handleChange}
              value={50}
              minValue={filterState.priceFrom}
            />
          </div>
        </React.Fragment>
      )}
      <label>{translate("web.birthDate", "Birthdate")}</label>
      <div className="AuctionFilterNumberofhoursesContainer AuctionFilterBirthDate">
        <NetaqInput
          name={"birthDateFrom"}
          type={"date"}
          label={translate("web.from", "From")}
          onChange={handleChange}
          value={birthDateFrom}
        />
        <NetaqInput
          name={"birthDateTo"}
          type={"date"}
          label={translate("web.to", "To")}
          onChange={handleChange}
          value={birthDateTo}
          minDate={birthDateFrom}
        />
      </div>

      <NetaqInput
        name={"sortBy"}
        type={"select"}
        label={translate("web.sortBy", "Sort By")}
        placeholder={translate("web.pleaseSelect", "Please Select")}
        listSelect={sortBy}
        onChange={(e) => handleChange(e, "sortBy")}
        value={filterState.sortByDrop}
      />

      {
        // <NetaqInput
        //   name={"country"}
        //   type={"selectOrigin"}
        //   label={translate("web.chooseCountry", "Choose Country")}
        //   listSelect={studBookCountries}
        //   onChange={handleChange}
        // />
      }
      <div className="horse-market-filter-reset-apply-div">
        <SubmitBtn key={"reset"} type={"reset"} />
        <SubmitBtn
          text={translate("web.search", "Search")}
          key={"apply"}
          type={"submit"}
        />
      </div>
    </form>
  );
};

export default HorseMarketFilter;
