import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import { useTranslate } from "./TranslationText";
import Loader from "./Loader";
import { brReplace } from "../helper/brReplace";


const fetch = process.env.REACT_APP_HISTORY_LIST_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
export default function AboutUsSlider() {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const { translate } = useTranslate();
  const [maxHeight, setMaxHeight] = useState(0);
  const itemRefs = useRef([]);
  function setItemsHeights() {
    const heights = itemRefs.current.map(item => item.clientHeight);
    const maxHeight = Math.max(...heights);
    setMaxHeight(maxHeight);
  }


  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return 'en-US';
    else return 'ar-AE'; // Defaulting to English for demonstration
  }


  useEffect(() => {
    async function getdata() {
      const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=10`, {
        headers: {
          "accept-language": getCurrentLanguageUmbraco(),

        }
      })

      const formattedData = umbraco.data?.items.map((item) => ({
        id: item.id,
        historyTitle: item?.properties?.historyTitle,
        historyDescription: item?.properties?.historyDescription?.markup ?? '',
        historyImage: item.properties?.historyImage ? item.properties?.historyImage[0]?.url : '',
      }));
      // Sort formattedData by historyTitle
      const language = getCurrentLanguageUmbraco();
      var sortedData = [];
      if (language === "en-US") {
        sortedData = formattedData.sort((a, b) => a.historyTitle - b.historyTitle);
      } else {
        sortedData = formattedData.sort((a, b) => a.historyTitle - b.historyTitle);
      }
      setItems(sortedData);
      setTotal(umbraco.data?.total)
    }
    getdata();
    setItemsHeights();

  }, [currentLanguage])




  useEffect(() => {
    // Calculate the maximum height of the carousel items

  }, [items]);

  const carouselOptions = {
    loop: false,
    margin: 10,
    nav: false,
    dotsEach: 1,
    autoplay: false,
    startPosition: getCurrentLanguageUmbraco() == 'ar-AE' ? items.length - 1 : 0,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,


    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };

  return (<>
    {total > 0 &&
      <>
        {/* <OwlCarousel className="owl-theme" {...carouselOptions} marginWidth={1}>
          {total > 0 && items.map((item, index) => (
            <>{!!item.historyImage ? 
              <div key={item.id} className="carousel-item aboutusItem" ref={el => (itemRefs.current[index] = el)} style={{ minHeight: 475 }}>
              <div className="row justRotate">
                <div className="col-lg-6 col-md-6 col-sm-12 justRotate">
                  <img className="historyImage" alt="asd" src={`${MediaBaseURL}${item.historyImage}`} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 justRotate">
                  <div className="historyTitle text-right-rtl" >{item.historyTitle}</div>
                  <div className="history_paragraph fontsize18 currentFont" dangerouslySetInnerHTML={{ __html: item.historyDescription }} />
                </div>
              </div>
            </div>
            : 
            <div key={item.id} className="carousel-item aboutusItem" ref={el => (itemRefs.current[index] = el)} style={{ minHeight: 475 }}>
              <div className="row justRotate">
                <div className="col-lg-12 col-md-12 col-sm-12 justRotate">
                  <div className="historyTitle text-right-rtl" >{item.historyTitle}</div>
                  <div className="history_paragraph fontsize18 currentFont" dangerouslySetInnerHTML={{ __html: item.historyDescription }} />
                </div>
              </div>
            </div>
            }
            </>

          ))}
        </OwlCarousel> */}
        <div className="AboutUsHistoryCardContainer">
          {total > 0 && items.map((item, index) => (
            <div className="AboutUsHistoryCard">
              <div className="redCircleAboutHistory" />
              <div className="historyTitle text-right-rtl">{item.historyTitle}</div>
              <div className="fontsize18 currentFont" dangerouslySetInnerHTML={{ __html: item.historyDescription }} />
            </div>
          ))}
        </div>
      </>
    }
    {total === 0 && <Loader />}
  </>);
}
