import React, { useEffect, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import ActionButton from "../components/ActionButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FetchCreateShowEntrythunk,
  FetchShowRequestthunk,
  FetchShowTermsthunk,
  FetchSubOwnerProfilesByIdthunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import { ToastContainer, toast } from "react-toastify";
import {
  resetNavigateStatus,
  resetToasterMessageShowEntry,
} from "../Redux/ShowEntry/ShowEntrySlice";
import Select from "react-select";
import PaymentDetailsCard from "../components/PaymentDetailsCard";
import Popup from "reactjs-popup";
import axiosInstance from "../axios/axios";

export default function ShowEntryTotalRegisteredHorses() {
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const [isOnlinePayment, setIsOnlinePayment] = useState(true);
  const [isNoPaymentOwner, setIsNoPaymentOwner] = useState(false);

  const handleMouseOver = (type) => {
    setHovered((prev) => ({ ...prev, [type]: true }));
  };

  const handleMouseOut = (type) => {
    setHovered((prev) => ({ ...prev, [type]: false }));
  };

  const generateButtonStyle = (isHovered) => ({
    background: !isHovered ? "#ffffff" : "#E44D52",
    padding: "10px 20px",
    color: !isHovered ? "#E44D52" : "#fff",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: "#E44D52",
    transition: "all 0.3s",
    display: "block",
    cursor: "pointer",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "bold",
  });
  const [hovered, setHovered] = useState({
    sign: false,
    close: false,
    clear: false,
    save: false,
    secondsignature: false,
  });

  const buttonSubmitStyle = generateButtonStyle(hovered.sign);
  const buttonSaveStyle = generateButtonStyle(hovered.save);
  const buttonclearStyle = generateButtonStyle(!hovered.clear);
  const buttoncloseStyle = generateButtonStyle(hovered.close);

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const {
    showEntryLoading,
    myShowParticipantsRequestChoosesData,
    toasterMessageShowEntry,
    showRequestData,
    navigateStatus,
    showTermsData,
    subOwnerProfilesByIdData,
    createShowEntryData,
  } = useSelector((state) => state.showEntrySlice);

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkes, setcheckes] = useState([]);
  const [subOwnerProfilesByIdList, setSubOwnerProfilesByIdList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [profile, setProfile] = useState({
    label:
      currentLanguage === "en"
        ? localStorage.getItem("EAHSUserFullName")
        : localStorage.getItem("currentSubAccountLabel"),
    value: localStorage.getItem("EAHSUserID"),
  });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const maxHorses = searchParams.has("maxHorses")
    ? searchParams.get("maxHorses")
    : null;

  const showNameAr = searchParams.has("showNameAr")
    ? searchParams.get("showNameAr")
    : null;

  const showNameEn = searchParams.has("showNameEn")
    ? searchParams.get("showNameEn")
    : null;

  const showId = searchParams.has("showId") ? searchParams.get("showId") : null;

  const entryFees = searchParams.has("entryFees")
    ? searchParams.get("entryFees")
    : null;

  const calcPropertyValue = (data) => {
    let count = 0;
    let total = 0;
    for (const obj of data) {
      count += 1;
      total += obj.amount;
      //if (obj.isRegistered) {
      //count += 1;
      //total += obj.amount;
      //}
    }

    return [count, total];
  };

  // console.log("userProfile", userProfile);

  async function checkIfIsNoPaymentOwner(showid, ownerId) {
    try {
      const check = await axiosInstance.post(
        `showentry/CheckOwnerFreeJoin?showId=${showid}&OwnerId=${ownerId}`,
        { showId: showid, OwnerId: ownerId }
      );
      if (check.data.responseCode == "200") {
        const isFree = check.data.responseText == "true" ? true : false;
        setIsNoPaymentOwner(isFree);
      }
    } catch (error) {
      setIsNoPaymentOwner(false);
    }
  }

  useEffect(() => {
    console.debug("showTermsData", showTermsData);
  }, [showTermsData]);

  useEffect(() => {
    let requestParams = {
      id:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      showId: showId,
      pageName: "Checkout",
      langauge: currentLanguage,
    };
    dispatch(FetchShowRequestthunk(requestParams));
    checkIfIsNoPaymentOwner(requestParams.showId, requestParams.id);

    let showTermsParams = {
      showId: showId,
      langauge: currentLanguage,
    };
    dispatch(FetchShowTermsthunk(showTermsParams));

    let subOwnerProfilesParams = {
      id:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));
  }, [currentLanguage]);

  const handelProceedToPayment = (isPayLater = false) => {
    if (isPayLater) {
      setIsOnlinePayment(false);
    } else {
      setIsOnlinePayment(true);
    }
    let horseIds =
      subOwnerProfilesByIdData.length === 0
        ? showRequestData.map((item) => {
          return {
            horseId: item.horseId,
            partictedOwnerId: item.horseOwnerId,
            isRequiredConfirmation: item.isRequiredConfirmation,
          };
        })
        : profileList.map((item) => {
          return {
            horseId: item.horseId,
            partictedOwnerId: item.value,
            isRequiredConfirmation: item.isRequiredConfirmation,
          };
        });

    let params = {
      showId: showRequestData[0].showId,
      ownerId:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      isDraft: false,
      showParticipantsHorsesList: horseIds,
      isOnline: isPayLater ? "no" : "yes",
    };
    dispatch(FetchCreateShowEntrythunk(params));
  };

  useEffect(() => {
    setSubOwnerProfilesByIdList([profile, ...subOwnerProfilesByIdData]);

    if (subOwnerProfilesByIdData.length > 0 && showRequestData.length > 0) {
      setProfileList(
        showRequestData.map((item) => {
          return {
            label:
              localStorage.getItem("EAHSUserID") === item.horseOwnerId
                ? currentLanguage === "en"
                  ? localStorage.getItem("EAHSUserFullName")
                  : localStorage.getItem("currentSubAccountLabel")
                : subOwnerProfilesByIdData.filter(
                  (ele) => ele.value === item.horseOwnerId
                )[0]?.text,
            text:
              localStorage.getItem("EAHSUserID") === item.horseOwnerId
                ? currentLanguage === "en"
                  ? localStorage.getItem("EAHSUserFullName")
                  : localStorage.getItem("currentSubAccountLabel")
                : subOwnerProfilesByIdData.filter(
                  (ele) => ele.value === item.horseOwnerId
                )[0]?.text,
            value: item.horseOwnerId,
            horseId: item.horseId,
            isRequiredConfirmation: item.isRequiredConfirmation,
          };
        })
      );
    }
  }, [subOwnerProfilesByIdData, showRequestData]);
  // console.log("profiel lissssssssssssssst is ", profileList);
  // console.log("showRequestData ", showRequestData);
  useEffect(() => {
    if (toasterMessageShowEntry != null && toasterMessageShowEntry === "save") {
      toast.success(
        translate("web.dataSavedSuccessfully", "Data Saved Successfully")
      );
      dispatch(resetToasterMessageShowEntry());
    } else if (toasterMessageShowEntry == null && navigateStatus) {
      dispatch(resetNavigateStatus());

      let checkWaitingHorsesList =
        createShowEntryData.waitingHorsesList.length > 0;
      setTimeout(() => {
        if (showRequestData.filter((obj) => obj.status === 2).length > 0) {
          const returnUrl = `${BaseURL}/profile?activeTab=paymentHistory`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${showId}&paymentType=3&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment ? "yes" : "no"
            }&lang=${currentLanguage}`;
        } else if (
          showRequestData.filter((obj) => obj.isRegistered).length === 0 ||
          (showRequestData.filter((obj) => obj.isRegistered).length > 0 &&
            // entryFees == 0
            calcPropertyValue(showRequestData)[1] == 0)
        ) {
          if (showRequestData.filter((obj) => obj.isRegistered).length === 0) {
            navigate(`/profile?activeTab=paymentHistory`);
          } else {
            navigate(`/profile?activeTab=paymentHistory`);
          }
        } else {
          const returnUrl = `${BaseURL}/profile?activeTab=paymentHistory`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${showId}&paymentType=3&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment ? "yes" : "no"
            }&lang=${currentLanguage}`;
        }
      }, 1000);
    }
  }, [toasterMessageShowEntry]);

  const dismissAll = () => toast.dismiss();

  const handelSubOwnerProfiles = (ele, horseId) => {
    let newProfileList = profileList.map((item) =>
      item.horseId === horseId
        ? { ...item, label: ele.label, value: ele.value }
        : item
    );
    setProfileList(newProfileList);
  };
  return (
    <div className="container-fluid container-fluid-padding row">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${showEntryLoading ? "classdisplay" : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {
        // title
      }
      <div className="col-md-12 col-sm-12 rtl mt-15 mb-25">
        <h2 className="fontWeight">
          {currentLanguage === "en" ? showNameEn : showNameAr}
        </h2>
      </div>
      {
        //cards
      }
      <div className="invoiceContainer">
        <div className="">
          <div className="col-md-12 col-sm-12  bordershowentrytotal maxwidth-853 left-29 padding-40 graybackgroundcolor row m0 rtl ">
            {
              //sub Owner Profiles
            }

            {
              //title registered Horses
            }
            {
              //myShowParticipantsRequestChoosesData.some(
              showRequestData.some((obj) => obj.amount > 0) && (
                <h4 className="fontWeight rtl mb-25">
                  {/* {translate("web.registeredHorses", "Registered Horses")} */}
                  {translate("web.totalToPay", "Total To Pay")}
                </h4>
              )
            }

            {
              //registered Horses cards
            }
            {showRequestData.some((obj) => obj.amount > 0) &&
              showRequestData
                .filter((item) => item.amount > 0 && !item.isRequiredConfirmation && (item.statusName == "PendingPayment" || item.statusName == "Draft"))
                .map((item, i) => (
                  <div
                    className="col-md-5 col-sm-12  bordershowentrytotal whitebackgroundcolor paymentRectangleInShow mb-10 p-20 aligin-self-start"
                    key={i}
                  >
                    <PaymentDetailsCard
                      isRegisterdHorse={true}
                      horseName={item.horseName}
                      categoryName={item.categoryName}
                      registerNr={item.horseRegisterNo}
                      showFees={item.amount}
                      currentLanguage={currentLanguage}
                      translate={translate}
                    />
                    {/* <div className="col-md-12 col-sm-12   rtl p0">
                      {subOwnerProfilesByIdList.length > 0 &&
                        userProfile.accountType !== "Agent" && (
                          <React.Fragment>
                            <span className="horseCardLabeltitle">
                              {translate("web.Participateas", "Participate as")}
                            </span>
                            <Select
                              isDisabled={
                                item.horseOwnerId ===
                                localStorage.getItem("EAHSUserID")
                              }
                              defaultValue={{
                                value: item.horseOwnerId,
                                label: subOwnerProfilesByIdList.filter(
                                  (ele) => ele.value === item.horseOwnerId
                                )?.text,
                              }}
                              options={[
                                profile,
                                ...(!!subOwnerProfilesByIdList &&
                                  subOwnerProfilesByIdList
                                    .map((a) => ({
                                      label: a.text,
                                      value: a.value,
                                    }))
                                    .filter((a) => !!a.label && !!a.value)),
                              ]}
                              value={profileList.filter(
                                (ele) =>
                                  ele.horseId === item.horseId && !!ele.label
                              )}
                              onChange={(e) =>
                                handelSubOwnerProfiles(e, item.horseId)
                              }
                              placeholder={
                                currentLanguage === "en"
                                  ? localStorage.getItem("EAHSUserFullName")
                                  : localStorage.getItem(
                                    "currentSubAccountLabel"
                                  )
                              }
                              isSearchable
                              noOptionsMessage={() =>
                                translate("web.noOptions", "No Options")
                              }
                            />
                          </React.Fragment>
                        )}
                    </div> */}
                  </div>
                ))}

            {
              //title Unregistered Horses
            }
            {showRequestData.some((obj) => (obj.amount <= 0 || obj.isRequiredConfirmation) && obj.status != 4 && obj.status != 1) && (
              <div className="col-md-12 col-sm-12 rtl p0">
                <h4 className="fontWeight">
                  {translate("web.pendingReviewHorses", "Pending Review Horses")}
                </h4>
              </div>
            )}
            {
              //Unregistered Horses cards
            }
            {showRequestData.some((obj) => (obj.amount <= 0 || obj.isRequiredConfirmation) && obj.status != 4 && obj.status != 1) &&
              showRequestData
                .filter((item) => (item.amount <= 0 || item.isRequiredConfirmation) && item.status != 4 && item.status != 1)
                .map((item, i) => (
                  <div
                    className="col-md-5 col-sm-12   p0 aligin-self-start"
                    key={i}
                  >
                    <div className="col-md-12 col-sm-12  bordershowentrytotal  whitebackgroundcolor paymentRectangleInShow  mb-10 p-20 ">
                      <PaymentDetailsCard
                        isRegisterdHorse={false}
                        horseName={item.horseName}
                        categoryName={item.categoryName}
                        showFees={item.amount}
                        currentLanguage={currentLanguage}
                        translate={translate}
                      />
                      {/* <div className="col-md-12 col-sm-12   rtl p0">
                        {subOwnerProfilesByIdList.length > 0 &&
                          userProfile.accountType !== "Agent" && (
                            <React.Fragment>
                              <span className="horseCardLabeltitle ">
                                {translate(
                                  "web.Participateas",
                                  "Participate as"
                                )}
                              </span>
                              <Select
                                isDisabled={
                                  item.horseOwnerId ===
                                  localStorage.getItem("EAHSUserID")
                                }
                                defaultValue={{
                                  value: item.horseOwnerId,
                                  label: subOwnerProfilesByIdList.filter(
                                    (ele) => ele.value === item.horseOwnerId
                                  )?.text,
                                }}
                                options={[
                                  profile,
                                  ...(!!subOwnerProfilesByIdList &&
                                    subOwnerProfilesByIdList.map((a) => ({
                                      label: a.text,
                                      value: a.value,
                                    }))),
                                ]}
                                value={profileList.filter(
                                  (ele) =>
                                    ele.horseId === item.horseId && !!ele.label
                                )}
                                onChange={(e) =>
                                  handelSubOwnerProfiles(e, item.horseId)
                                }
                                // placeholder={
                                //   currentLanguage === "en"
                                //     ? localStorage.getItem("EAHSUserFullName")
                                //     : localStorage.getItem(
                                //         "currentSubAccountLabel"
                                //       )
                                // }
                                isSearchable
                                noOptionsMessage={() =>
                                  translate("web.noOptions", "No Options")
                                }
                              />
                            </React.Fragment>
                          )}
                      </div> */}
                    </div>
                  </div>
                ))}

            {
              //title Waiting List Horses
            }
            {showRequestData.some(
              (obj) => obj.statusName === "WaitingList"
            ) && (
                <div className="col-md-12 col-sm-12 rtl p0">
                  <h4 className="fontWeight">
                    {translate("web.waitingListHorse", "Waiting List Horses")}
                  </h4>
                </div>
              )}

            {
              //Waiting List Horses cards
            }
            {showRequestData.some((obj) => obj.statusName === "WaitingList") &&
              showRequestData
                .filter((item) => item.statusName === "WaitingList")
                .map((item, i) => (
                  <div
                    className="col-md-5 col-sm-12   p0 aligin-self-start"
                    key={i}
                  >
                    <div className="col-md-12 col-sm-12 bordershowentrytotal whitebackgroundcolor paymentRectangleInShow mb-10 p-20">
                      <PaymentDetailsCard
                        isRegisterdHorse={false}
                        horseName={item.horseName}
                        categoryName={item.categoryName}
                        registerNr={item.horseRegisterNo}
                        showFees={item.amount}
                        currentLanguage={currentLanguage}
                        translate={translate}
                      />
                      {/* <div className="col-md-12 col-sm-12   rtl p0">
                        {subOwnerProfilesByIdList.length > 0 &&
                          userProfile.accountType !== "Agent"  && (
                            <React.Fragment>
                              <span className="horseCardLabeltitle ">
                                {translate(
                                  "web.Participateas",
                                  "Participate as"
                                )}
                              </span>
                              <Select
                                isDisabled={
                                  item.horseOwnerId ===
                                  localStorage.getItem("EAHSUserID")
                                }
                                defaultValue={{
                                  value: item.horseOwnerId,
                                  label: subOwnerProfilesByIdList.filter(
                                    (ele) => ele.value === item.horseOwnerId
                                  )?.text,
                                }}
                                options={[
                                  profile,
                                  ...(!!subOwnerProfilesByIdList &&
                                    subOwnerProfilesByIdList.map((a) => ({
                                      label: a.text,
                                      value: a.value,
                                    }))),
                                ]}
                                value={profileList.filter(
                                  (ele) =>
                                    ele.horseId === item.horseId && !!ele.label
                                )}
                                onChange={(e) =>
                                  handelSubOwnerProfiles(e, item.horseId)
                                }
                                isSearchable
                                noOptionsMessage={() =>
                                  translate("web.noOptions", "No Options")
                                }
                              />
                            </React.Fragment>
                          )}
                      </div> */}
                    </div>
                  </div>
                ))}

            {
              //total
            }
            <div className="col-md-12 col-sm-12 rtl p0 AuctionFilterNumberofhoursesContainer">
              <div className="col-md-6 col-sm-12  p0">
                <h4 className="fontWeight mb-15">
                  {translate("web.totalToPay", "Total To Pay")}{" "}
                  {` (${calcPropertyValue(
                    showRequestData.filter((x) => x.amount > 0)
                  )[0]
                    })`}
                </h4>
              </div>
              <div className="col-md-6 col-sm-12  rtl text-left-rtl p0 width-fitcontent">
                <h5 className="fontWeight mb-15 bordershowentrytotal p9">
                  {`${calcPropertyValue(showRequestData)[1]}`}{" "}
                  {currentLanguage === "en" ? "AED" : "د.إ"}
                </h5>
              </div>
            </div>

            <div className="col-md-12 col-sm-12 rtl p0 mb-25">
              {showRequestData.filter((obj) => (obj.amount <= 0 || obj.isRequiredConfirmation) && obj.status != 4 && obj.status != 1).length >
                0 && (
                  <h4 className="fontWeight">
                    {translate(
                      "web.youHaveHorsesToReview",
                      "You Have Horses Will Wait For Review"
                    )}
                  </h4>
                )}
            </div>
            {
              //checkboxs
            }

            {showTermsData.length > 0 &&
              showTermsData.map((item, i) => (
                <div className="col-md-12 col-sm-12 rtl p0" key={i}>
                  <NetaqInput
                    name={item.fileName}
                    type={"checkbox"}
                    htmlTags={
                      <Popup
                        modal
                        trigger={
                          <div
                            key={i}
                            className="C89C66 flex-center cursorPointer mb-25"
                            style={{
                              color: "blue",
                              textDecorationLine: "underline",
                            }}
                          >
                            {item.fileName}
                          </div>
                        }
                        closeOnDocumentClick={true}
                        closeOnEscape
                        className="popupUnsetmaxWidth"
                      >
                        {(close) => (
                          <React.Fragment>
                            <div
                              className="text-start"
                              style={{ maxHeight: 300, overflowY: "scroll" }}
                              dangerouslySetInnerHTML={{
                                __html: item.termsAndConditions,
                              }}
                            />
                            <br />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <button
                                style={buttonclearStyle}
                                onMouseOver={() => handleMouseOver("clear")}
                                onMouseOut={() => handleMouseOut("clear")}
                                onClick={() => {
                                  // Check the checkbox
                                  const checkboxes = document.querySelectorAll(
                                    'input[type="checkbox"]'
                                  );
                                  checkboxes[i].checked = true;

                                  // Update the state to reflect the checkbox is checked
                                  setcheckes(
                                    Array.from(checkboxes).map(
                                      (checkbox) => checkbox.checked
                                    )
                                  );

                                  // Close the popup
                                  close();
                                }}
                              >
                                {translate("web.accept", "Accept")}
                              </button>
                              <button
                                style={buttoncloseStyle}
                                onMouseOver={() => handleMouseOver("close")}
                                onMouseOut={() => handleMouseOut("close")}
                                onClick={close}
                              >
                                {translate("web.close", "close")}
                              </button>
                            </div>
                          </React.Fragment>
                        )}
                      </Popup>
                    }
                    onChange={() => {
                      setcheckes(
                        Array.from(
                          document.querySelectorAll('input[type="checkbox"]')
                        ).map((checkbox) => checkbox.checked)
                      );
                    }}
                  />
                </div>
              ))}

            {
              //proceed to payment button
            }
            <div className="col-md-12 col-sm-12 p0 row m0">
              {showTermsData.length === 0 ||
                (checkes.length > 0 &&
                  checkes.some((obj) => obj === false) === false) === true ? (
                isNoPaymentOwner ? (
                  <>
                    <div className="col-md-5 col-sm-12 text-center mx-auto">
                      <ActionButton
                        type={"submit"}
                        text={
                          <React.Fragment>
                            <i class="fa-solid fa-sack-dollar fa-lg marginrightleft-10"></i>
                            {showRequestData.length > 0 &&
                              calcPropertyValue(showRequestData)[1] > 0
                              ? translate(
                                "web.proceedToPayment",
                                "Proceed To Payment"
                              )
                              : translate(
                                "web.submitYourRequest",
                                "Submit Your Request"
                              )}
                          </React.Fragment>
                        }
                        margin={"auto"}
                        width={"100%"}
                        onClick={() => handelProceedToPayment(false)}
                      />
                    </div>
                    <div className="col-md-5 col-sm-12 text-center mx-auto">
                      <ActionButton
                        type={"bootstrapbtn"}
                        className="btn-info text-white"
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>
                            ${showRequestData.length > 0 &&
                            calcPropertyValue(showRequestData)[1] > 0
                            ? translate("web.payLater", "Pay Later")
                            : translate(
                              "web.submitYourRequest",
                              "Submit Your Request"
                            )
                          }
                            `}
                        margin={"auto"}
                        width={"100%"}
                        onClick={() => handelProceedToPayment(true)}
                      />
                    </div>
                  </>
                ) : (
                  <ActionButton
                    type={"submit"}
                    text={
                      <React.Fragment>
                        <i class="fa-solid fa-sack-dollar fa-lg marginrightleft-10"></i>
                        {showRequestData.length > 0 &&
                          calcPropertyValue(showRequestData)[1] > 0
                          ? translate(
                            "web.proceedToPayment",
                            "Proceed To Payment"
                          )
                          : translate(
                            "web.submitYourRequest",
                            "Submit Your Request"
                          )}
                      </React.Fragment>
                    }
                    margin={"auto"}
                    width={"100%"}
                    onClick={handelProceedToPayment}
                  />
                )
              ) : isNoPaymentOwner ? (
                <>
                  <div className="col-md-5 col-sm-12 text-center mx-auto">
                    <button
                      className="btn btn-danger width_100 pading-10-20"
                      disabled
                    >
                      <i class="fa-solid fa-sack-dollar fa-lg marginrightleft-10"></i>
                      {showRequestData.length > 0 &&
                        calcPropertyValue(showRequestData)[1] > 0
                        ? translate(
                          "web.proceedToPayment",
                          "Proceed To Payment"
                        )
                        : translate(
                          "web.submitYourRequest",
                          "Submit Your Request"
                        )}
                    </button>
                  </div>
                  <div className="col-md-5 col-sm-12 text-center mx-auto">
                    <button
                      className="btn btn-info text-white width_100 pading-10-20"
                      disabled
                    >
                      <i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>
                      {showRequestData.length > 0 &&
                        calcPropertyValue(showRequestData)[1] > 0
                        ? translate("web.payLater", "Pay Later")
                        : translate(
                          "web.submitYourRequest",
                          "Submit Your Request"
                        )}
                    </button>
                  </div>
                </>
              ) : (
                <button
                  className="btn btn-danger width_100 pading-10-20"
                  disabled
                >
                  <i class="fa-solid fa-sack-dollar fa-lg marginrightleft-10"></i>
                  {showRequestData.length > 0 &&
                    calcPropertyValue(showRequestData)[1] > 0
                    ? translate("web.proceedToPayment", "Proceed To Payment")
                    : translate("web.submitYourRequest", "Submit Your Request")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
