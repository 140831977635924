import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import ActionButton from "../components/ActionButton";
import { useLocation } from "react-router";
import { formatDateTimeToString } from "../helper/formatDateTimeToString";
import { Link } from "react-router-dom";
import { isNotWhiteSpaceOrEmpty } from "../helper/StringExtentions";
import { RemovePlusFromString } from "../helper/RemovePlusFromString";

export default function SingleTransportCompany() {
    const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;
    const BaseURL = process.env.REACT_APP_BASE_URL;
    const { currentLanguage } = useSelector(
        (state) => state.studBookSlice
    );
    const [post, setPost] = useState([]);
    const { translate } = useTranslate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const id = searchParams.has("id")
        ? searchParams.get("id")
        : '';

    const [showContactDetails, setShowContactDetails] = useState(false);


    function getCurrentLanguageUmbraco() {
        // Replace this with your logic to retrieve the current language
        // For example, you can retrieve it from localStorage or from a state variable
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang == "en") return 'en-US';
        else return 'ar-AE'; // Defaulting to English for demonstration
    }


    useEffect(() => {
        async function getdata() {
            const umbraco = await axios.get(`/umbraco/delivery/api/v1/content/item?id=${id}`, {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(),

                }
            })
            // console.log('Umbraco Suppliers asd are umbraco is', umbraco.data);
            const formattedData = umbraco.data?.map((item) => ({
                itemID: item?.id ?? '',
                itemCover: item.properties?.itemCover ? item.properties?.itemCover[0]?.url : '',
                itemImage: item.properties?.itemImage ? item.properties?.itemImage[0]?.url : '',
                itemGallery: item.properties?.itemGallery ? item.properties?.itemGallery : [],
                itemShortDescription: item?.properties?.itemShortDescription ?? '',
                itemTitle: item?.properties?.itemTitle ?? "",
                itemWebsite: item?.properties?.itemWebsite ?? "",
                itemPhone: item?.properties?.itemPhone ?? "",
                itemPdfText: item?.properties?.itemPdfText ?? "",
                itemPdfUrl: item?.properties?.itemPdf ?? "",
                itemEmail: item?.properties?.itemEmail ?? "",
                itemDescription: item?.properties?.itemDescription?.markup ?? "",
                itemDescription2: item?.properties?.itemDescription2?.markup ?? "",
                itemDescription3: item?.properties?.itemDescription3?.markup ?? "",
            }));
            setShowContactDetails(isNotWhiteSpaceOrEmpty(formattedData[0]?.itemEmail ?? '') || isNotWhiteSpaceOrEmpty(formattedData[0]?.itemPhone ?? '') || isNotWhiteSpaceOrEmpty(formattedData[0]?.itemWebsite ?? ''))
            setPost(formattedData);
        }
        getdata();

    }, [currentLanguage])
    return (
        <>
            {post.length === 0 &&
                <Loader />
            }
            {post.length > 0 &&
                post.map((post) => (
                    <div className='container'>
                        <div className='row rtl '>
                            <div className='col-lg-12 col-md-12 col-sm-12 '>
                                <div className="singlesupplierimagecontainer">
                                    <img className='NewsPostimg' src={`${MediaBaseURL}${post.itemImage}`} alt='postName' />
                                </div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <h3 className='NewsPostTitle bold'>
                                    {post.itemTitle}
                                </h3>
                            </div>
                            {!!(post.itemDescription) && post.itemDescription != '' &&
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-1' style={{margin:0 }}>
                                    <div dangerouslySetInnerHTML={{ __html: post.itemDescription }} className='SupplierCardContainer' />
                                </div>
                            }
                            {!!(post.itemDescription2) && post.itemDescription2 != '' &&
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-1' style={{margin:0 }}>
                                    <div dangerouslySetInnerHTML={{ __html: post.itemDescription2 }} className='SupplierCardContainer' />
                                </div>
                            }
                            {!!(post.itemDescription3) && post.itemDescription3 != '' &&
                                <div className='col-lg-12 col-md-12 col-sm-12 mt-1' style={{margin:0 }}>
                                    <div dangerouslySetInnerHTML={{ __html: post.itemDescription3 }} className='SupplierCardContainer' />
                                </div>
                            }
                            {showContactDetails &&
                                (<div className='col-lg-12 col-md-12 col-sm-12 mt-1' style={{margin:0 }}>
                                    <div className='SupplierCardContainer' >
                                        <h3 className='NewsPostTitle bold'>
                                            {translate('web.contactdetails', 'Contact Details')}
                                        </h3>
                                        {!!(post.itemPhone) && post.itemPhone != '' &&

                                            <Link to={`tel:${post.itemPhone}`} target='_blank' className='Suplliericon' >
                                                <i className="fa-solid fa-phone "></i>
                                                <span>{RemovePlusFromString(post.itemPhone)}</span>
                                            </Link>
                                        }
                                        {!!(post.itemEmail) && post.itemEmail != '' &&

                                            <Link to={`mailto:${post.itemEmail}`} target='_blank' className='Suplliericon'>
                                                <i className="fa-regular fa-envelope "></i>
                                                <span>{post.itemEmail}</span>
                                            </Link>
                                        }
                                        {!!(post.itemWebsite) && post.itemWebsite != '' &&
                                            <Link to={`https://${post.itemWebsite}`} target='_blank' className='Suplliericon'>
                                                <i className="fa-solid fa-globe "></i>
                                                <span>{post.itemWebsite}</span>
                                            </Link>
                                        }
                                        <div className="SupplierCardImageContainer">
                                            <img src={`${MediaBaseURL}${post.itemCover}`} alt={post.itemTitle} />
                                        </div>
                                    </div>
                                </div>)
                            }
                            {post.itemGallery.length > 0 &&
                            <div className='col-lg-12 col-md-12 col-sm-12 mt-1' style={{margin:0 }}>
                                <div className='SupplierCardContainer' >
                                    <div className="imageGalleryFlex">
                                        {post.itemGallery.map((item)=>(
                                            <img src={`${MediaBaseURL}${item.url}`} alt={item.url} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            }

                        </div>
                    </div>
                ))
            }
        </>
    )
}
