import { createSlice } from "@reduxjs/toolkit";
import {
  FetchChangePasswordthunk,
  FetchCheckUserHasOwnerProfilethunk,
  FetchCitiesthunk,
  FetchCreateHorseMarketthunk,
  FetchDeleteHorseMarketthunk,
  FetchGetOwnersListthunk,
  FetchHorseDataByRegisterNumberthunk,
  FetchHorsePassportthunk,
  FetchOwnerAppuserByIdthunk,
  FetchOwnerByIdthunk,
  FetchOwnerHorseMarketthunk,
  FetchOwnerHorsesDropDownthunk,
  FetchOwnerHorsesthunk,
  FetchStudOwnerByIdthunk,
  FetchUpdateAccountthunk,
  FetchUpdateHorseMarketthunk,
  FetchUpdateOwnerAppuserAccountthunk,
} from "./OwnerProfileAction";

const initialState = {
  ownerHorsesData: [],
  ownerHorsesDropDownData: [],
  ownerDetailsData: {},
  OwnerByIdData: {},
  citiesData: [],
  horseDataByRegisterNumberData: {},
  ownerHorseMarketData: [],
  ownerAppuserByIdData: {},
  studOwnerByIdData: [],
  getOwnersListData: [],
  take: 12,
  page: 0,
  totalCountOwnerHorses: 0,
  ownerProfileLoading: false,
  ownerProfileError: null,
  stateShowMoreButtonHorse: false,
  //takeOwnerHorseMarket: 12,
  //pageOwnerHorseMarket: 0,
  //totalCountOwnerHorseMarket: 0,
  checkUserHasOwnerProfile: false,
  detailedResponseCode: "",
  responseCode: "",
  toasterMessage: null,
  setCurrentSubAccountId: localStorage.getItem("currentSubAccountId"),
};

const OwnerProfileSlice = createSlice({
  name: "OwnerProfile",
  initialState,
  reducers: {
    incrementPage: (state) => {
      state.page += 1;
    },

    setCurrentSubAccount: (state) => {
      state.setCurrentSubAccountId = localStorage.getItem("currentSubAccountId");
    },
    emptyPage: (state) => {
      state.page = 0;
    },

    resetSearch: (state) => {
      state.page = 1;
      state.ownerHorsesData = [];
      state.totalCountOwnerHorses = 0;
    },
    resetToasterMessage: (state) => {
      state.toasterMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchOwnerHorsesthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchOwnerHorsesthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;

      if (state.page === 1) {
        state.ownerHorsesData = action.payload.horsesList;
      } else {
        state.ownerHorsesData = state.ownerHorsesData.concat(
          action.payload.horsesList
        );
      }

      if (
        (state.ownerHorsesData.length > 0 &&
          state.ownerHorsesData.length < state.take) ||
        action.payload.totalCount === state.ownerHorsesData.length
      ) {
        state.stateShowMoreButtonHorse = true;
      } else {
        state.stateShowMoreButtonHorse = false;
      }
      state.totalCountOwnerHorses = action.payload.totalCount;
      state.ownerDetailsData = action.payload.owner;
      // console.log("state.ownerHorsesData");
      // console.log(state.ownerHorsesData);
      // console.log(state.ownerDetailsData);
    });
    builder.addCase(FetchOwnerHorsesthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.ownerHorsesData = [];
      state.stateShowMoreButtonHorse = false;
      state.totalCountOwnerHorses = 0;
      state.ownerDetailsData = {};
    });

    builder.addCase(FetchOwnerHorsesDropDownthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(
      FetchOwnerHorsesDropDownthunk.fulfilled,
      (state, action) => {
        state.ownerProfileLoading = false;
        state.ownerHorsesDropDownData = action.payload.horsesList;
      }
    );
    builder.addCase(FetchOwnerHorsesDropDownthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.ownerHorsesDropDownData = [];
    });

    builder.addCase(FetchOwnerByIdthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchOwnerByIdthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.OwnerByIdData = action.payload.result;
    });
    builder.addCase(FetchOwnerByIdthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.OwnerByIdData = {};
    });

    builder.addCase(FetchUpdateAccountthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchUpdateAccountthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
    });
    builder.addCase(FetchUpdateAccountthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
    });

    builder.addCase(FetchCitiesthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchCitiesthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;

      state.citiesData = action.payload.result;
    });
    builder.addCase(FetchCitiesthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.citiesData = [];
    });

    builder.addCase(FetchCreateHorseMarketthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
      state.toasterMessage = null;
    });
    builder.addCase(FetchCreateHorseMarketthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.toasterMessage = "save";
    });
    builder.addCase(FetchCreateHorseMarketthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.toasterMessage = null;
    });

    builder.addCase(FetchUpdateHorseMarketthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
      state.toasterMessage = null;
    });
    builder.addCase(FetchUpdateHorseMarketthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.toasterMessage = "update";
    });
    builder.addCase(FetchUpdateHorseMarketthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.toasterMessage = null;
    });

    builder.addCase(
      FetchHorseDataByRegisterNumberthunk.pending,
      (state, action) => {
        state.ownerProfileLoading = true;
      }
    );
    builder.addCase(
      FetchHorseDataByRegisterNumberthunk.fulfilled,
      (state, action) => {
        state.ownerProfileLoading = false;

        state.horseDataByRegisterNumberData = action.payload.result;
      }
    );
    builder.addCase(
      FetchHorseDataByRegisterNumberthunk.rejected,
      (state, action) => {
        state.ownerProfileLoading = false;
        state.ownerProfileError = action.payload;
        state.horseDataByRegisterNumberData = {};
      }
    );

    builder.addCase(FetchOwnerHorseMarketthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchOwnerHorseMarketthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;

      state.ownerHorseMarketData = action.payload.result;
    });
    builder.addCase(FetchOwnerHorseMarketthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.ownerHorseMarketData = [];
    });

    builder.addCase(FetchDeleteHorseMarketthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
      state.toasterMessage = null;
    });
    builder.addCase(FetchDeleteHorseMarketthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.toasterMessage = "delete";
      state.ownerHorseMarketData = state.ownerHorseMarketData.filter(
        (a) => a.id !== action.payload.result
      );
    });
    builder.addCase(FetchDeleteHorseMarketthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.toasterMessage = null;
    });

    builder.addCase(
      FetchCheckUserHasOwnerProfilethunk.pending,
      (state, action) => {
        state.ownerProfileLoading = true;
      }
    );
    builder.addCase(
      FetchCheckUserHasOwnerProfilethunk.fulfilled,
      (state, action) => {
        state.ownerProfileLoading = false;
        state.checkUserHasOwnerProfile = action.payload.result;
      }
    );
    builder.addCase(
      FetchCheckUserHasOwnerProfilethunk.rejected,
      (state, action) => {
        state.ownerProfileLoading = false;
        state.ownerProfileError = action.payload;
      }
    );

    builder.addCase(FetchChangePasswordthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
      state.detailedResponseCode = "";
      state.responseCode = "";
      state.toasterMessage = null;
    });
    builder.addCase(FetchChangePasswordthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = null;
      state.detailedResponseCode = action.payload.status.detailedResponseCode;
      state.responseCode = action.payload.status.responseCode;
      state.toasterMessage = "changepassword";
    });
    builder.addCase(FetchChangePasswordthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.detailedResponseCode = action.payload.status.detailedResponseCode;
      state.responseCode = action.payload.status.responseCode;
      state.toasterMessage = null;
    });

    builder.addCase(FetchHorsePassportthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchHorsePassportthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = null;
    });
    builder.addCase(FetchHorsePassportthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
    });

    builder.addCase(FetchOwnerAppuserByIdthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchOwnerAppuserByIdthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = null;
      state.ownerAppuserByIdData = action.payload.result;
    });
    builder.addCase(FetchOwnerAppuserByIdthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
    });

    builder.addCase(
      FetchUpdateOwnerAppuserAccountthunk.pending,
      (state, action) => {
        state.ownerProfileLoading = true;
        state.toasterMessage = null;
      }
    );
    builder.addCase(
      FetchUpdateOwnerAppuserAccountthunk.fulfilled,
      (state, action) => {
        state.ownerProfileLoading = false;
        state.toasterMessage = "update";
      }
    );
    builder.addCase(
      FetchUpdateOwnerAppuserAccountthunk.rejected,
      (state, action) => {
        state.ownerProfileLoading = false;
        state.ownerProfileError = action.payload;
        state.toasterMessage = null;
      }
    );

    builder.addCase(FetchStudOwnerByIdthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchStudOwnerByIdthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;
      state.studOwnerByIdData = action.payload.result;
    });
    builder.addCase(FetchStudOwnerByIdthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.studOwnerByIdData = [];
    });

    builder.addCase(FetchGetOwnersListthunk.pending, (state, action) => {
      state.ownerProfileLoading = true;
    });
    builder.addCase(FetchGetOwnersListthunk.fulfilled, (state, action) => {
      state.ownerProfileLoading = false;

      state.getOwnersListData = action.payload.result;
    });
    builder.addCase(FetchGetOwnersListthunk.rejected, (state, action) => {
      state.ownerProfileLoading = false;
      state.ownerProfileError = action.payload;
      state.getOwnersListData = [];
    });
  },
});

export const { incrementPage, emptyPage, resetSearch, resetToasterMessage, setCurrentSubAccount } =
  OwnerProfileSlice.actions;
export default OwnerProfileSlice.reducer;
