import React, { useCallback, useEffect, useState } from "react";
import SiteMap from "../components/SiteMap";
import ImagesSlider from "../components/ImagesSlider";
import CronometerCounter from "../components/CronometerCounter";
import Popup from "reactjs-popup";
import axios from "../axios/axios";
import { useLocation, useNavigate } from "react-router";
import { bidsCollectionRef } from "../firebase/collections";
import {
  getFirestore,
  collection,
  limit,
  where,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { wait } from "@testing-library/user-event/dist/utils";
import { isEqual } from "lodash";
import { Colors } from "../constants/theme";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchAuctionData,
  getAuctionHourses,
} from "../Redux/AuctionData/AuctionsDataSlice";
import { getTimeStatus } from "../helper/timeUtils";
import { useTranslate } from "../components/TranslationText";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CompareDate } from "../helper/CompareDate";

export default function BiddingPage() {
  // bidsCollectionRef
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentAuctionId = searchParams.has("auctionID")
    ? searchParams.get("auctionID")
    : "";
  const currentHorseIdparam = searchParams.has("auctionHorseId")
    ? searchParams.get("auctionHorseId")
    : "";
  const [currentHorseId, setCurrentHorseId] = useState(currentHorseIdparam);
  const currentHorseIdBackendparam = searchParams.has("HorseId")
    ? searchParams.get("HorseId")
    : "";
  const [currentHorseIdBackend, setCurrentHorseIdBackend] = useState(currentHorseIdBackendparam);

  const bidderId = searchParams.has("bidderId")
    ? searchParams.get("bidderId")
    : "";
  const BidderNumber = searchParams.has("BidderNumber")
    ? searchParams.get("BidderNumber")
    : "";
  const VideoURL = searchParams.has("VideoURL")
    ? searchParams.get("VideoURL")
    : "";

  const { translate } = useTranslate();

  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const [horseData, setHorseData] = useState(null);
  // const [auctionData, setAuctionData] = useState({});
  const [horseAttachment, setHorseAttachment] = useState([]);

  // console.log("horse", horseAttachment);

  const [biddersData, setBiddersData] = useState([]);

  const [latestBid, setLatestBid] = useState(
    (horseData?.latestBid
      ? horseData?.latestBid
      : horseData?.HorseStartingPrice) ?? 1
  );
  const [newBid, setNewBid] = useState(latestBid + horseData?.Incremental ?? 0);
  const [multiplieAcmount, setMultiplieAcmount] = useState(2);

  async function increaseNewBid() {
    setMultiplieAcmount((prevMultiplieAmount) => prevMultiplieAmount + 1);
    setNewBid((prevNewBid) => prevNewBid + horseData?.Incremental);
  }

  async function decreaseNewBid() {
    if (multiplieAcmount > 2) {
      setMultiplieAcmount((prevMultiplieAmount) => prevMultiplieAmount - 1);
      setNewBid((prevNewBid) => prevNewBid - horseData?.Incremental);
    }
  }

  async function checkEnrollment() {
    const paymentStatus = localStorage.getItem(currentAuctionId);
    if (paymentStatus === "Paid") {
      return true;
    } else {
      return false;
    }
  }

  async function handleOnsubmit(close) {
    const paymentStatus = await checkEnrollment();
    if (paymentStatus === true) {


      async function placeBid() {
        const placeBid = await axios.post("/bid", {
          bidAmount: newBid,
          hourseAmount: newBid,
          bidderID: bidderId,
          auctionID: currentAuctionId,
          horseId: currentHorseIdBackend,
          bidderName: "",
          deviceType: 2,
          bidderNumber: BidderNumber,
          bidderType: "online",
        });
        if (placeBid?.data?.status?.responseCode == "200") {
          toast.success(
            translate("web.submissionSuccessful", "Submission Successful")
          );
        } else {
          toast.error(
            translate("web.errorInPlacingBid", "Error in Placing Bid")
          );
        }
      }
      placeBid();

      close();
    } else {
      // Show the success message

      toast.error(
        translate(
          "web.pleaseEnrollAndMakepayment",
          "Please Enroll And Make payment"
        )
      );
      setTimeout(() => {
        navigate(-1)
      }, 5000);


      close();
    }
  }
  const allData = useSelector((state) => state.auction);

  const auctionData = useSelector((state) => state.auction.auctionData);
  const liveURL = auctionData?.liveUrl ?? "";
  let isBetweenDates =
    !!auctionData &&
    CompareDate(auctionData?.auctionStartDate, auctionData.auctionEndDate);
  // console.log("auctionData", auctionData);
  // get and filter hourse Data
  async function getHorseAuctionData(currentId = currentHorseId, externalisinArena = false) {
    try {
      const filterresponsetogethorse = await allData.auctionHorses.filter(
        (item) => item.auctionHorseId === currentId
      );
      const formattedData = {
        AuctionName: auctionData.auctionName,
        HorseName: filterresponsetogethorse[0].horseName,
        HorseDesc: filterresponsetogethorse[0].horseDescription,
        HorseLotNo: filterresponsetogethorse[0].lotNumber,
        HorseStartingPrice: filterresponsetogethorse[0].startingPrice ?? 0,
        Incremental: filterresponsetogethorse[0].incrementalIncrease,
        AuctionEndDate: new Date(auctionData.auctionEndDate),
        horseId: filterresponsetogethorse[0].horseId,
        auctionType: auctionData.auctionType,
        HorseStatus: filterresponsetogethorse[0].horseSellingStatus,
        inArena: externalisinArena ? true : filterresponsetogethorse[0].isHorseInArena,
      };
      setHorseAttachment(filterresponsetogethorse[0].horseAttachment);
      setHorseData(formattedData);
      if (externalisinArena) {
        getInitalBiddersData(filterresponsetogethorse[0].horseId);
        setCurrentHorseIdBackend(filterresponsetogethorse[0].horseId);
        const url = new URL(window.location.href);
        url.searchParams.set('HorseId', filterresponsetogethorse[0].horseId);
        window.history.replaceState(null, '', url.toString());
      }

      setLatestBid(filterresponsetogethorse[0].startingPrice);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    const updatedBid = latestBid + (horseData?.Incremental ?? 0);
    setNewBid(updatedBid);
  }, [latestBid, horseData?.Incremental]);
  const navigate = useNavigate();

  // firestore
  const [firestoreData, setFireStoreData] = useState([]);

  const filterCollection = async (auctionID) => {
    const q = query(
      bidsCollectionRef,
      // where("AuctionHorseId", "==", auctionHorseId), // Filter by AuctionHorseID
      where("AuctionId", "==", auctionID),
      orderBy('TimeStamp', 'desc'), limit(1)
    );

    return q;
  };

  // get date from string
  function parseDateString(dateString) {
    // return dateString;
    const parts = dateString.split(/[/: ]/); // Split the string by '/', ':', and ' ' characters
    const day = parseInt(parts[0], 10); // Get the day
    const month = parseInt(parts[1], 10) - 1; // Get the month (subtract 1 as months are zero-based in JavaScript)
    const year = parseInt(parts[2], 10); // Get the year
    const hours = parseInt(parts[3], 10); // Get the hours
    const minutes = parseInt(parts[4], 10); // Get the minutes
    const seconds = parseInt(parts[5], 10); // Get the seconds
    const milliseconds = parseInt(parts[6], 10); // Get the milliseconds

    // Check if it's AM or PM and adjust hours accordingly
    let adjustedHours = hours;
    if (parts[7].toLowerCase() === "pm" && hours < 12) {
      adjustedHours += 12;
    } else if (parts[7].toLowerCase() === "am" && hours === 12) {
      adjustedHours = 0;
    }

    // Create a new Date object
    const date = new Date(
      year,
      month,
      day,
      adjustedHours,
      minutes,
      seconds,
      milliseconds
    );

    return date;
  }
  // get passed minuts
  function minutesPassedSince(date) {
    return date.toLocaleString();
  }

  // get bidders data from backend server
  async function getInitalBiddersData(horseId = currentHorseIdBackend) {
    try {
      const biddersData = await axios.get(
        `bid?auctionId=${currentAuctionId}&horseId=${horseId}`
      );

      const formattedData = biddersData.data.result.map((bid) => ({
        BidderNumber: bid.bidderNumber,
        BidderName: bid.bidderName,
        BidderNameAr: bid.bidderName,
        BidAmount: bid.horseAmount,
        BidTime: new Date(bid.bidTime),
        BidderType: bid?.bidderType?.toLowerCase(),
      }));

      const sorted = formattedData.sort((a, b) => b.BidAmount - a.BidAmount);

      setBiddersData(sorted);

      setLatestBid(sorted[0].BidAmount);
    } catch (error) {
      console.error("Error fetching initial bidders data:", error);
    }
  }
  const dispatch = useDispatch();
  
  const fetchData = useCallback(async () => {
    try {
      // Now, proceed to fetch data from Firestore
      await getHorseAuctionData();

      if (!horseData) {
        await dispatch(fetchAuctionData(currentAuctionId));
        await getHorseAuctionData();
      }

      await getInitalBiddersData(); // Fetch initial bidders data

      const desiredHorseId = currentHorseId;
      const desiredAuctionId = currentAuctionId;

      // Fetch Firestore query
      const q = await filterCollection(desiredAuctionId);

      // Set up the snapshot listener and obtain the unsubscribe function
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newData = [];

        querySnapshot.forEach((doc) => {
          newData.push(doc.data());
        });

        setFireStoreData(newData);
        console.debug('my New Data', newData);

        if (newData.length > 0) {
          if (desiredAuctionId === newData[0]?.AuctionId) {
            if (newData[0]?.ChangeIncrementalIncreaseAmount?.toLowerCase() === 'true') {
              setHorseData(prevHorseData => ({
                ...prevHorseData,
                Incremental: parseInt(newData[0]?.NewIncrementalIncreaseAmount)
              }));
            }
          }
          if (newData[0]?.HorseStatus != null && newData[0]?.HorseStatus != undefined && newData[0]?.HorseStatus != '' && newData[0]?.HorseStatus != 'null') {
            if (['sold', 'unsold'].includes(newData[0]?.HorseStatus.toLowerCase())) {

            if (newData[0]?.HorseStatus) {
              setHorseData(prevHorseData => ({
                ...prevHorseData,
                HorseStatus: newData[0]?.HorseStatus,
                inArena: false
              }));
              if (newData[0]?.HorseStatus.toLowerCase() !== 'startbidding') {
                toast.warning(
                  `${translate('web.currentHorseIs', 'Current Horse Is')} ${translate(`web.${newData[0]?.HorseStatus.toLowerCase()}`, newData[0]?.HorseStatus)}`
                );
                if (['sold', 'unsold'].includes(newData[0]?.HorseStatus.toLowerCase())) {
                  setBiddersData([]);
                }
              }
            }
          } else if (newData[0]?.HorseStatus?.toLowerCase() === 'startbidding') {
            toast.warning(
              `${translate('web.newHorseIs', 'New Horse Is')} ${translate('web.added', 'added')}`
            );
            setCurrentHorseId(newData[0]?.AuctionHorseId);
             
            getHorseAuctionData(newData[0]?.AuctionHorseId, true);
            const url = new URL(window.location.href);
            url.searchParams.set('auctionHorseId', newData[0]?.AuctionHorseId);
            window.history.replaceState(null, '', url.toString());
          }

          }
        }

        const formattedData = newData
          .filter(item => item.BidderNumber )
          .map(bid => ({
            BidderNumber: bid.BidderNumber,
            BidderName: bid.BidderName,
            BidderNameAr: bid.BidderNameAr,
            BidAmount: parseInt(bid.HorseAmount),
            BidTime: parseDateString(bid.BidTime),
            BidderType: bid?.BidderType?.toLowerCase(),
          }))
          .sort((a, b) => b.BidTime.getTime() - a.BidTime.getTime());

        if (formattedData.length > 0) {
          setLatestBid(prev => (prev >= formattedData[0].BidAmount ? prev : formattedData[0].BidAmount));
        setBiddersData(prev => {
          const combinedData = [...formattedData, ...prev];
          const uniqueData = combinedData.filter(
            (obj, index, self) =>
              index === self.findIndex(t => t.BidAmount === obj.BidAmount)
          );
          return uniqueData.sort((a, b) => b.BidAmount - a.BidAmount);
        });
        }

      });

      // Return the unsubscribe function from onSnapshot if defined
      return unsubscribe;
    } catch (error) {
      console.error('Error fetching data:', error);
      return null; // Return null if there is an error
    }
  }, []);

  


  useEffect(() => {
    // Define an async wrapper to handle the asynchronous fetchData
   

      let unsubscribe;
  
      const fetchAndSubscribe = async () => {
        unsubscribe = await fetchData(); // Await fetchData to get the unsubscribe function
      };
  
      fetchAndSubscribe(); // Call the wrapper function
  
      return () => {
        // Clean up: call unsubscribe if it's defined and a function
        if (unsubscribe && typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
  }, []);


  // console.log('horse DAta issss',horseData)
  const dismissAll = () => toast.dismiss();


  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {!!horseData && (
        <React.Fragment>
          <SiteMap
            siteTree={[
              `${translate(
                `web.${getTimeStatus(
                  auctionData.auctionStartDate,
                  auctionData.auctionEndDate
                ).toLowerCase()}Auction`,
                `${getTimeStatus(
                  auctionData.auctionStartDate,
                  auctionData.auctionEndDate
                ).toLowerCase()}Auction`
              )}`,
              horseData.AuctionName,
              horseData.HorseName,
            ]}
          />

          <div className="container-fluid p0">
            <div className="row m0-bgred">
              {currentLanguage == "en" && (
                <div className="col-lg-8 col-md-8 col-sm-12 p0">
                  <ImagesSlider
                    sliderName={"bidding"}
                    data={horseAttachment}
                    type={"bidding"}
                    horseName={"myname"}
                    VideoURL={
                      !!liveURL &&
                        isBetweenDates &&
                        auctionData.auctionType != "Online"
                        ? [liveURL, VideoURL]
                        : [VideoURL]
                    }
                  />
                </div>
              )}

              <div className="col-lg-4 col-md-4 col-sm-12 p0-dflex-col right-to-left">
                <div className="w100-bgred-f1p3020">
                  {((horseData?.HorseStatus.toLowerCase() != 'sold' && horseData?.HorseStatus.toLowerCase() != 'unsold' || horseData.inArena == true)) ?
                    <>
                      <span className="d-block-font25-fw-bold-white">
                        {horseData.HorseName} {translate("web.lotNo", "Lot No")}.{" "}
                        {horseData.HorseLotNo}
                      </span>
                      <p className="fz15-mt15-white">{horseData?.HorseDesc}</p>
                      <div className="BiddingPageSpacer"></div>
                      <div className="row my-15">
                        <div className="col-lg-4  col-md-4  col-sm-4">
                          <span className="d-block-font12-white">
                            {translate("web.startPrice", "Start Price")}
                          </span>
                          <span className="d-block-font20-white">
                            {horseData?.HorseStartingPrice?.toLocaleString("en-US")}{" "}
                            {translate("web.aed", "AED")}
                          </span>
                        </div>
                        <div className="col-lg-6  col-md-6  col-sm-6">
                          <span className="d-block-font12-white">
                            {translate(
                              "web.incrementalIncreaseAmount",
                              "Incremental Increase Amount"
                            )}
                          </span>
                          <span className="d-block-font20-white">
                            {horseData?.Incremental?.toLocaleString("en-US")}{" "}
                            {translate("web.aed", "AED")}
                          </span>
                        </div>
                      </div>
                      <div className="BiddingPageSpacer"></div>
                      {new Date(horseData.AuctionEndDate) > new Date() && (
                        <>
                          <div className="text-center py20">
                            <i className="fa-regular fa-clock white-30"></i>
                          </div>
                          <CronometerCounter endDate={horseData.AuctionEndDate} />
                        </>
                      )}
                    </> :
                    <span className="d-block-font25-fw-bold-white">
                      {translate("web.noHorseInArenaRightNow", "No Horse In Arena Right Now")}
                    </span>
                  }

                </div>
              </div>
              {currentLanguage == "ar" && (
                <div className="col-lg-8 col-md-8 col-sm-12 p0">
                  <ImagesSlider
                    sliderName={"bidding"}
                    data={horseAttachment}
                    type={"bidding"}
                    horseName={"myname"}
                    VideoURL={
                      !!liveURL && isBetweenDates
                        ? [liveURL, VideoURL]
                        : [VideoURL]
                    }
                  />
                </div>
              )}
            </div>
            <div className="text-center my-15">
              <span className="viewDetailsDiv">
                {translate("web.auctionBidding", "auction bidding")}
              </span>
            </div>
            <div className="row BiddingPageRow">
              {horseData.auctionType !== "Live" && (
                (horseData?.HorseStatus.toLowerCase() != 'sold' && horseData?.HorseStatus.toLowerCase() != 'unsold' || horseData.inArena == true) &&
                <div className="col-lg-4 col-md-12 col-sm-12">
                  {BidderNumber === "" ? (
                    horseData.AuctionEndDate < new Date() ? (
                      <></>
                    ) : (
                      <span style={{ color: Colors.PRIMARY_RED }}>
                        {translate(
                          "web.youNeedEnrollandPayTostartBidding",
                          "You Need to Enroll and Pay To start Bidding"
                        )}
                      </span>
                    )
                  ) : (
                    horseData.AuctionEndDate > new Date() && (
                      <div className="w100br-6">
                        <div className="text-center">
                          <span className="viewDetailsDiv">
                            {translate("web.addNewBid", "Add New Bid")}
                          </span>
                        </div>

                        <div className="AuctionDetailsSpacer"></div>
                        <div className="BiddingPageRow2 rtl">
                          <span className="d-block-f15-bold">
                            {translate("web.bidderNo", "Bidder Nubmer")}
                          </span>
                          <span className="d-block-f15-bold">
                            {BidderNumber}
                          </span>
                        </div>
                        {auctionData.auctionType.toLowerCase() != 'hybrid' && <>

                          <div className="BiddingPageRow2">
                            <span
                              className="minusandaddiconstyle"
                              onClick={() => decreaseNewBid()}
                            >
                              <i className="fa-solid fa-minus"></i>
                            </span>
                            <span className="dflex-bold-20-white-p2-br-5">
                              <div className="dflex-jcenter-acenter-w125-text-center">
                                <span className="dblock">
                                  {newBid}
                                  <span className="font-10">
                                    {translate("web.aed", "AED")}
                                  </span>
                                </span>
                              </div>
                            </span>
                            <span
                              className="minusandaddiconstyle"
                              onClick={() => increaseNewBid()}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </span>
                          </div>
                          <div className="AuctionDetailsSpacer"></div>
                        </>}
                        <div className="BiddingPageRow2">
                          <span className="dflex-bold-20-white-p2-br-5">
                            <div className="dflex-jcenter-acenter-text-center-br2">
                              <span className="display_block color-eahsred">
                                {newBid} {translate("web.aed", "AED")}
                              </span>
                            </div>
                          </span>
                          <Popup
                            modal
                            lockScroll
                            trigger={
                              <div className="bidSubmit">
                                {" "}
                                {translate("web.submit", "Submit")}
                              </div>
                            }
                            closeOnDocumentClick={false}
                            closeOnEscape
                            contentStyle={{ width: "90%", maxWidth: 400 }}
                          >
                            {(close) => (
                              <React.Fragment>
                                <div
                                  style={{ fontSize: 20, fontWeight: "bold" }}
                                >
                                  {translate(
                                    "web.youwillbidforanamountof",
                                    "You will bid for an amount of"
                                  )}
                                </div>
                                <span
                                  style={{
                                    display: "block",
                                    color: "#E44D52",
                                    fontWeight: "bold",
                                    fontSize: 20,
                                  }}
                                >
                                  {newBid}{" "}
                                  <span style={{ color: "#000", fontSize: 15 }}>
                                    {translate("web.aed", "AED")}
                                  </span>
                                </span>
                                <div
                                  className="rtl"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginTop: "25px",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  <div
                                    className="bidSubmit"
                                    style={{
                                      background: "#fff",
                                      color: "#E44D52",
                                      margin: "5px auto",
                                    }}
                                    onClick={() => close()}
                                  >
                                    {auctionData.auctionType.toLowerCase() != 'hybrid' ? translate("web.editBid", "Edit Bid") : translate("web.cancel", "Cancel")}
                                  </div>
                                  <div
                                    className="bidSubmit"
                                    onClick={() => handleOnsubmit(close)}
                                    style={{ width: 150, margin: "5px auto" }}
                                  >
                                    {translate("web.submit", "Submit")}
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                          </Popup>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              <div
                className={
                  horseData.auctionType !== "Live"
                    ? "col-lg-8 col-md-12 col-sm-12 right-to-left mx-auto"
                    : "col-lg-12 col-md-12 col-sm-12 right-to-left mx-auto"
                }
              >
                <div className="text-center red-f20-pt0">
                  {translate("web.listOfBids", "List Of Bids")}
                </div>
                <div className="tableFixHead">
                  <table id="listofbidstable">
                    <thead>
                      <tr>
                        <th>{translate("web.bidderNo", "Bidder No")}</th>
                        <th>{translate("web.bidderName", "Bidder Name")}</th>
                        <th>{translate("web.bidAmount", "Bid Amount")}</th>
                        <th>{translate("web.bidTime", "Bid Time")}</th>
                        <th>{translate("web.bidderType", "Bidder Type")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {biddersData.map((bidder) => (
                        <tr>
                          <td>{bidder.BidderNumber}</td>
                          <td>
                            {currentLanguage == "en"
                              ? bidder.BidderName
                              : bidder.BidderNameAr}
                          </td>
                          <td>
                            {bidder.BidAmount.toLocaleString("en-US")}
                            {translate("web.aed", "AED")}
                          </td>
                          <td>
                            {minutesPassedSince(bidder.BidTime)}{" "}
                            {/* {translate("web.minutes ago", "minutes ago")} */}
                          </td>
                          <td>
                            {bidder.BidderType == "live" ||
                              bidder.BidderType == "حضوري" ? (
                              <React.Fragment>
                                <i className="fa-solid fa-circle color-eahsred"></i>{" "}
                                {translate("web.live", "Live")}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                            {bidder.BidderType == "online" ||
                              bidder.BidderType == "عبر الانترنت" ? (
                              <React.Fragment>
                                {" "}
                                <i className="fa-solid fa-circle color-green"></i>{" "}
                                {translate("web.onlineBidder", "Online")}
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
