import React, { useState } from 'react'
import { useTranslate } from './TranslationText';
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function FilterButton({onClick , isStudBookPage = false }) {
    const [isHovered , setIsHovered] = useState(false);

    const {translate} = useTranslate();
    return (
        <button
        type="button"
        className="btn filterbtn tooltip"
        style={isStudBookPage ? {display:'inline-block'}:{display:'block'}}
        onClick={onClick}
        onMouseLeave={()=>setIsHovered(false)}
        onMouseOver={() => setIsHovered(true)}
      >
              <span class="tooltiptext">{translate('web.showFilter' , 'Show Filter')}</span>

        <img
          src={
            isHovered
              ? `${BaseURL}/assets/img/filterWhite.png`
              : `${BaseURL}/assets/img/filter.png`
          }
          alt="filterimg"
        />
      </button>
    )
}
