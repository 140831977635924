export function formatDateTimeToString(date , lang='en-US' , withtime = false) {
  if (lang !='en-US'){
    const dateTime = new Date(date);
    const months = ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر'];
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    if(withtime){

      return `${dateTime.getDate()} ${months[dateTime.getMonth()]} ${dateTime.getFullYear()}, ${formattedHours}:${formattedMinutes}${amOrPm}`;
    }else{
      return `${dateTime.getDate()} ${months[dateTime.getMonth()]} ${dateTime.getFullYear()}`;

    }
  }else{
    const dateTime = new Date(date);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    if(withtime){
      return `${dateTime.getDate()} ${months[dateTime.getMonth()]} ${dateTime.getFullYear()}, ${formattedHours}:${formattedMinutes}${amOrPm}`;
    }else{
      return `${dateTime.getDate()} ${months[dateTime.getMonth()]} ${dateTime.getFullYear()}`;
    }
  }
  }
  
 
  