// timeUtils.js
export function getTimeStatus(startDate, endDate) {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (currentDate < start) {
        return "Upcoming";
    } else if (currentDate >= start && currentDate <= end) {
        return "Running";
    } else {
        return "Past";
    }
}
