import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

export default function ScrollToTop() {
  const { pathname } = useLocation();

  const location = useLocation();

  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState("");

  const accesstoken = localStorage.getItem("accessTokenforEAHS") ?? "";
  const jsonString = localStorage.getItem("eahsUserProfile");



  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname == "/add-organizer-request") {
      if (jsonString) {
        const data = JSON.parse(jsonString);
        setUserProfile(data);
        if (data.accountType != "Organizer") {
          navigate("/404-not-found");
        }
      } else {
        navigate("/404-not-found");
      }
    } else if (
      pathname.toLowerCase() == "/requesthorseownershiptransferseller".toLowerCase()
      || pathname.toLowerCase() == "/requestcoveringcertificate".toLowerCase()
      || pathname.toLowerCase() == "/requestembryoOocytetransferpermit".toLowerCase()
      || pathname.toLowerCase() == "/requestembryotransferrightpurchaceagreement".toLowerCase()
      || pathname.toLowerCase() == "/requestRegisteringNewArabianHorseFoal".toLowerCase()
    ) {
      if (accesstoken == "") {
        navigate(`/login?redirectURL=${location.pathname + location.search}`);
      }
    } else if (pathname == "/profile" && accesstoken == "") {
      navigate(`/login`);
    }
    else if (
      pathname == "/showentrycard" ||
      pathname == "/showentrytotalregisteredhorses" ||
      pathname == "/contactus" ||
      pathname == "/profile"
    ) {
      try {
        setTimeout(() => {
          window.Tawk_API.showWidget();
        }, 500);
      } catch (error) {
        console.log(error);
      }

      // Cleanup function to remove the script when component unmounts or path changes
      return () => {
        window.Tawk_API.hideWidget();
      };
    }
  }, [pathname, navigate]);

  //const dismissAll = () => toast.dismiss();

  return null;
}
