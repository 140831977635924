import React, { useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { Link } from "react-router-dom";

export default function LoginUaePassForm() {
  return (
    <form
      style={{
        backgroundColor: "#F7F7F7",
        padding: 25,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img
        src="/assets/img/uaepasslogo.png"
        alt="uaepasslogo"
        style={{
          width: 115,
          height: 127,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      <h2>Login by UAE pass</h2>
      <span>
        Don’t have an account?{" "}
        <Link style={{ color: "#E44D52" }}>Create UAE Pass</Link>{" "}
      </span>
      <br />
      <br />
      <NetaqInput
        name={"Email-or-Mobile-Phone"}
        key={"Email-or-Mobile-Phone"}
        placeholder={"Enter Your Phone or Email"}
        type={"text"}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <ActionButton
          margin={5}
          width={"100%"}
          key={"loginuaepass"}
          text={"Login With UAE PASS"}
          type={"loginuaepass"}
          nextLink={"/uaepassotp"}
        />
      </div>
    </form>
  );
}
