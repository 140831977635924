import React, { useEffect, useRef, useState } from "react";
import NetaqInput from "./NetaqInput";
import { useDispatch, useSelector } from "react-redux";
import { FetchStudBookCountriesthunk } from "../Redux/StudBook/StudBookAction";
import SubmitBtn from "./SubmitBtn";
import { useTranslate } from "./TranslationText";
import Select from "react-select";
import { FetchCitiesthunk } from "../Redux/OwnerProfile/OwnerProfileAction";

const ShowsFilter = ({ onSubmit }) => {
  const [checker, setChecker] = useState(true);

  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { citiesData } = useSelector((state) => state.ownerProfileSlice);

  const { translate } = useTranslate();

  const dispatch = useDispatch();

  const filterReset = () => {
    return {
      search: "",
      year: "",
      month: 0,
      country: null,
      countryDrop: {
        label: translate("web.chooseEmirate", "Choose Emirate"),
        value: null,
      },
      monthDrop: {
        label: translate("web.month", "Month"),
        text: translate("web.month", "Month"),
        value: null,
      },
      showStatus: [],
    };
  };

  const [filterState, setFilterState] = useState(filterReset());

  const handleReset = () => {

    setFilterState(filterReset());
  };

  const handleChange = (event, input) => {
    if (!!input && input === "country") {
      setFilterState({
        ...filterState,
        country: event.value,
        countryDrop: event,
      });
    } else if (!!input && input === "month") {
      setFilterState({
        ...filterState,
        month: event.value,
        monthDrop: event,
        showStatus: ["All"]
      });
    }
    else if (!!input && input === "showStatus") {
      if (!event.target.checked) {
        setFilterState({
          ...filterState,
          showStatus: filterState.showStatus.filter(
            (item) => item !== event.target.value
          ),
        });
      } else {
        setFilterState({
          ...filterState,
          showStatus: filterState.showStatus.concat(event.target.value),
        });
      }
    }
    else if (event.target.name === "year") {
      
        setFilterState({
          ...filterState,
          [event.target.name]: event.target.value,
          showStatus: ["All"]
        });
      
    } else {
      setFilterState({
        ...filterState,
        [event.target.name]: event.target.value,
      });
    }

  
  };

  useEffect(() => {
    // dispatch(FetchStudBookCountriesthunk(currentLanguage));
    dispatch(FetchCitiesthunk());
    if (filterState.countryDrop.value == null) {
      setFilterState({
        ...filterState,
        countryDrop: {
          label: currentLanguage === "en" ? "Choose Emirate" : "اختر الإمارة",
          value: null,
        },
      });
    } else {
      setFilterState({
        ...filterState,
      });
    }
  }, [currentLanguage]);

  const handleSubmit = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    onSubmit(filterState);
  };

  return (
    <form
      className="horse-market-filter-form"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <h2>{translate('web.advancedSearch', 'Advanced Search')}</h2>

      <NetaqInput
        label={translate("web.showTitle", "Show Title")}
        name={"search"}
        placeholder={translate("web.showTitle", "Show Title")}
        type={"text"}
        onChange={handleChange}
      />

      <label className="mb-10">
        {translate("web.showStatus", "Show Status")}
      </label>
      <div className="AuctionFilterRadioContainer mb-10">
        <NetaqInput
          name={"showStatus"}
          key={"Running"}
          type={"checkbox"}
          value={"Ongoing"}
          label={translate("web.running", "Running")}
          onChange={(e) => handleChange(e, "showStatus")}
          disabled={!(filterState.year == "") || !(filterState.month == 0) ? true : false}
        />
        <NetaqInput
          name={"showStatus"}
          key={"Upcoming"}
          type={"checkbox"}
          value={"Upcoming"}
          label={translate("web.upcoming", "Upcoming")}
          onChange={(e) => handleChange(e, "showStatus")}
          disabled={!(filterState.year == "") || !(filterState.month == 0) ? true : false}

        />

        <NetaqInput
          name={"showStatus"}
          key={"Past"}
          type={"checkbox"}
          value={"Past"}
          label={translate("web.past", "Past")}
          onChange={(e) => handleChange(e, "showStatus")}
          disabled={!(filterState.year == "") || !(filterState.month == 0) ? true : false}

        />
      </div>

      <label className="mb-10">
        {translate("web.showLocation", "Show Location")}
      </label>

      <div className="countrymargin">
        <Select
          options={[
            {
              label:
                currentLanguage === "en" ? "Choose Emirate" : "اختر الإمارة",
              value: null,
            },
            ...(!!citiesData &&
              citiesData.map((a) => ({
                label: currentLanguage === "en" ? a.name : a.nameAr,
                value: a.id,
              }))),
          ]}
          value={filterState.countryDrop}
          onChange={(e) => handleChange(e, "country")}
          placeholder={translate("web.chooseEmirate", "Choose Emirate")}
          name={"country"}
          isSearchable
          noOptionsMessage={() => translate("web.noOptions", "No Options")}
        />
      </div>

      {
        // <NetaqInput
        //   name={"year"}
        //   type={"number"}
        //   label={translate("web.year", "Year")}
        //   onChange={handleChange}
        // />
      }
      <label className="capatalize_text">{translate("web.year", "Year")}</label>
      <input
        style={{ marginBottom: 10 }}
        className="form-control"
        name="year"
        type="number"
        min="1900"
        max="3000"
        step="1"
        defaultValue={""}
        onChange={handleChange}
        disabled={( filterState.showStatus.length == 0 || filterState.showStatus[0] == "All") ? false : true}
      />

      <NetaqInput
        type={"select"}
        listSelect={[
          {
            text: translate("web.all", "All"),
            value: 0,
          },
          {
            text: translate("web.January", "January"),
            value: 1,
          },
          {
            text: translate("web.February", "February"),
            value: 2,
          },
          { text: translate("web.March", "March"), value: 3 },
          { text: translate("web.April", "April"), value: 4 },
          { text: translate("web.May", "May"), value: 5 },
          { text: translate("web.June", "June"), value: 6 },
          { text: translate("web.July", "July"), value: 7 },
          { text: translate("web.August", "August"), value: 8 },
          {
            text: translate("web.September", "September"),
            value: 9,
          },
          {
            text: translate("web.October", "October"),
            value: 10,
          },
          {
            text: translate("web.November", "November"),
            value: 11,
          },
          {
            text: translate("web.December", "December"),
            value: 12,
          },
        ]}
        value={filterState.monthDrop}
        name={"month"}
        placeholder={translate("web.month", "Month")}
        label={translate("web.month", "Month")}
        onChange={(e) => handleChange(e, "month")}
        disabled={( filterState.showStatus.length == 0 || filterState.showStatus[0] == "All" ) ? false : true}
      />

      {
        // <NetaqInput
        //   name={"month"}
        //   type={"number"}
        //   label={translate("web.month", "Month")}
        //   onChange={handleChange}
        // />
      }

      <div className="horse-market-filter-reset-apply-div mt-3">
        <SubmitBtn key={"reset"} type={"reset"} />
        <SubmitBtn
          text={translate("web.search", "Search")}
          key={"apply"}
          type={"submit"}
        />
      </div>
    </form>
  );
};

export default ShowsFilter;
