import React from 'react'
import { useTranslate } from './TranslationText'
import HorseCard from './HorseCard';

export default function StudBookTab({ Data, handlePageChange, page, totalCount, take, renderPagination , noBackGround }) {
    const { translate } = useTranslate();
    return (
        <div className="col-lg-12 col-md-12 col-sm-12 mt-1  rtl row  selectedSearchTypeContainer width100Arabic768 m0 " style={{background:noBackGround && "#fff" ,}}>
            {Data.length > 0 &&
                Data.map((item, i) => (
                    <HorseCard isArabicClass={true} item={item} i={i} />
                ))}

            {Data.length === 0 && (
                <div className="row text-danger text-center  siblingNoDataFound">
                    <span className="">
                        {translate("web.nodataFound", "No data Found!")}
                    </span>
                </div>
            )}
            <div className="col-lg-12 col-md-12 col-sm-12  text-center-rtl rtl width100Arabic768 mt-15 text-center">
                {page !== 1 && (
                    <React.Fragment>
                        <span
                            className="pagination-style"
                            onClick={() => handlePageChange(1)}
                        >
                            <i className="fa-solid fa-chevron-left rotateArabic"></i>
                            <i className="fa-solid fa-chevron-left rotateArabic"></i>
                        </span>

                        <span
                            className="pagination-style"
                            onClick={() =>
                                handlePageChange(page - 1)
                            }
                        >
                            <i className="fa-solid fa-chevron-left rotateArabic"></i>
                        </span>
                    </React.Fragment>
                )}

                {renderPagination()}

                {Data.length > 0 &&
                    page !==
                    Math.ceil(
                        totalCount / take
                    ) && (
                        <React.Fragment>
                            <span
                                className="pagination-style"
                                onClick={() =>
                                    handlePageChange(page + 1)
                                }
                            >
                                <i className="fa-solid fa-chevron-right rotateArabic"></i>
                            </span>

                            <span
                                className="pagination-style"
                                onClick={() =>
                                    handlePageChange(
                                        Math.ceil(
                                            totalCount / take
                                        )
                                    )
                                }
                            >
                                <i className="fa-solid fa-chevron-right rotateArabic"></i>
                                <i className="fa-solid fa-chevron-right rotateArabic"></i>
                            </span>
                        </React.Fragment>
                    )}
            </div>
        </div>
    )
}
