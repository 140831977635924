import { createSlice } from "@reduxjs/toolkit";
import {
  FetchBankDetailsthunk,
  FetchCitiesthunk,
  FetchCountriesthunk,
  FetchHorseColorsthunk,
} from "./LookUpAction";

const initialState = {
  horseColorsData: [],
  countriesData: [],
  citiesLookUpData: [],
  lookUpLoading: false,
  lookUpError: null,
  bankDetailsData: {},
};

const LookUpSlice = createSlice({
  name: "LookUp",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(FetchHorseColorsthunk.pending, (state, action) => {
      state.lookUpLoading = true;
    });
    builder.addCase(FetchHorseColorsthunk.fulfilled, (state, action) => {
      state.lookUpLoading = false;
      state.horseColorsData = action.payload.result;
    });
    builder.addCase(FetchHorseColorsthunk.rejected, (state, action) => {
      state.lookUpLoading = false;
      state.lookUpError = action.payload;
      state.horseColorsData = [];
    });

    builder.addCase(FetchCountriesthunk.pending, (state, action) => {
      state.lookUpLoading = true;
    });
    builder.addCase(FetchCountriesthunk.fulfilled, (state, action) => {
      state.lookUpLoading = false;
      state.countriesData = action.payload;
    });
    builder.addCase(FetchCountriesthunk.rejected, (state, action) => {
      state.lookUpLoading = false;
      state.lookUpError = action.payload;
      state.countriesData = [];
    });

    builder.addCase(FetchBankDetailsthunk.pending, (state) => {
      state.lookUpLoading = true;
    });
    builder.addCase(FetchBankDetailsthunk.fulfilled, (state, action) => {
      state.lookUpLoading = false;
      state.bankDetailsData = action.payload.result;
    });
    builder.addCase(FetchBankDetailsthunk.rejected, (state, action) => {
      state.lookUpLoading = false;
      state.lookUpError = action.payload;
      state.bankDetailsData = {};
    });

    builder.addCase(FetchCitiesthunk.pending, (state, action) => {
      state.lookUpLoading = true;
    });
    builder.addCase(FetchCitiesthunk.fulfilled, (state, action) => {
      state.lookUpLoading = false;
      state.citiesLookUpData = action.payload;
    });
    builder.addCase(FetchCitiesthunk.rejected, (state, action) => {
      state.lookUpLoading = false;
      state.lookUpError = action.payload.result;
      state.citiesLookUpData = [];
    });
  },
});

export default LookUpSlice.reducer;
