import { createSlice } from "@reduxjs/toolkit";
import {
  FetchHorseMarketDetailsthunk,
  FetchHorseMarketthunk,
} from "./HorseMarketAction";

const initialState = {
  horseMarketData: [],
  horseMarketDetailsData: {},
  horseMarketLoading: false,
  horseMarketError: null,
  pageHorseMarket: 0,
  takeHorseMarket: 12,
  totalCountHorseMarket: 0,
  marketType: "HorseMarket",
  stateShowMoreButtonHorse: false,
};

const HorseMarketSlice = createSlice({
  name: "HorseMarket",
  initialState,
  reducers: {
    incrementPageHorseMarket: (state, action) => {
      //state.pageHorseMarket = action.payload;
      state.pageHorseMarket += 1;
    },
    emptyPageHorseMarket: (state) => {
      state.pageHorseMarket = 1;
    },
    resetSearch: (state) => {
      state.pageHorseMarket = 0;
      state.horseMarketData = [];
      state.totalCountHorseMarket = 0;
    },
    setMarketType: (state, action) => {
      state.marketType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchHorseMarketthunk.pending, (state) => {
      state.horseMarketLoading = true;
    });
    builder.addCase(FetchHorseMarketthunk.fulfilled, (state, action) => {
      let data = {
        totalCount: 10,
        horsesList: [
          {
            horseCategory: "test 1",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 2",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 3",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 4",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 5",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 6",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 7",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 8",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 9",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
          {
            horseCategory: "test 10",
            horseMarketMedia: [],
            englisName: "test",
            arabicName: "تجربة",
            birthDate: "1-1-2002",
            genderString: "test",
            address: "test",
            sireNameEn: "test",
            sireNameAr: "تجربة",
            damNameEn: "damNameEn",
            damNameAr: "تجربة",
            colorEn: "test",
            colorAr: "تجربة",
            originEn: "test",
            originAr: "تجربة",
            horsePrice: 123,
            horseId: "1",
          },
        ].splice(
          (state.pageHorseMarket - 1) * state.takeHorseMarket,
          state.takeHorseMarket
        ),
      };

      state.horseMarketLoading = false;

      if (state.pageHorseMarket === 1) {
        state.horseMarketData = action.payload.result;
      } else {
        state.horseMarketData = state.horseMarketData.concat(
          action.payload.result
        );
      }

      if (
        (state.horseMarketData.length > 0 &&
          state.horseMarketData.length < state.takeHorseMarket) ||
        action.payload.totalCount === state.horseMarketData.length
      ) {
        state.stateShowMoreButtonHorse = true;
      } else {
        state.stateShowMoreButtonHorse = false;
      }

      //state.horseMarketData = action.payload.result;
      //state.horseMarketData = data.horsesList;
      state.totalCountHorseMarket = action.payload.totalCount;
      //state.totalCountHorseMarket = data.totalCount;
    });
    builder.addCase(FetchHorseMarketthunk.rejected, (state, action) => {
      state.horseMarketLoading = false;
      state.horseMarketError = action.payload;
      state.horseMarketData = [];

      state.stateShowMoreButtonHorse = false;
      state.totalCountHorseMarket = 0;
    });

    builder.addCase(FetchHorseMarketDetailsthunk.pending, (state) => {
      state.horseMarketLoading = true;
    });
    builder.addCase(FetchHorseMarketDetailsthunk.fulfilled, (state, action) => {
      state.horseMarketLoading = false;
      state.horseMarketDetailsData = action.payload.result;
    });
    builder.addCase(FetchHorseMarketDetailsthunk.rejected, (state, action) => {
      state.horseMarketLoading = false;
      state.horseMarketError = action.payload;
      state.horseMarketDetailsData = {};
    });
  },
});

export const {
  incrementPageHorseMarket,
  resetSearch,
  setMarketType,
  emptyPageHorseMarket,
} = HorseMarketSlice.actions;
export default HorseMarketSlice.reducer;
