import { createSlice } from "@reduxjs/toolkit";
import {
  FetchChampionshipResultthunk,
  FetchLastShowsthunk,
  FetchShowChampionshipCardsthunk,
  FetchShowClassHorseSoringDetailsthunk,
  FetchShowClassRankListthunk,
  FetchShowClassesthunk,
  FetchShowGallerythunk,
  FetchShowJudgesthunk,
  FetchShowbyIdthunk,
  FetchShowsthunk,
  FetchShowsGallery,
  FetchShowsNews,
} from "./ShowsAction";
import { formatDate } from "../../helper/formatDate";
import { formatDateArray } from "../../helper/formatDateArray"

const initialState = {
  showsData: [],
  lastShowsData: [],
  showbyIdData: {},
  showJudgesData: [],
  showClassRankListData: [],
  showChampionshipCardsData: [],
  championshipResultData: {},
  showGalleryData: [],
  showMediaData: [],
  showNewsData: [],
  showClassesData: [],
  showClassHorseSoringDetailsData: [],
  showsLoading: false,
  showsError: null,
  pageShows: 0,
  takeShows: 12,
  totalCountShows: 0,
  stateShowMoreButtonHorse: false,
};

const ShowsSlice = createSlice({
  name: "Shows",
  initialState,
  reducers: {
    incrementPageShows: (state, action) => {
      state.pageShows += 1;
    },
    emptyPageShows: (state) => {
      state.pageShows = 1;
    },
    resetSearch: (state) => {
      state.pageShows = 0;
      state.showsData = [];
      state.totalCountShows = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(FetchShowsthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchShowsthunk.fulfilled, (state, action) => {

      state.showsLoading = false;
      if (state.pageShows === 1) {  
        state.showsData = action.payload.result;
      } else {
        const shows = state.showsData.concat(action.payload.result);
        state.showsData = shows;
      }

      if (
        (state.showsData.length > 0 &&
          state.showsData.length < state.takeShows) ||
        action.payload.totalCount === state.showsData.length
      ) {
        state.stateShowMoreButtonHorse = true;
      } else {
        state.stateShowMoreButtonHorse = false;
      }

      state.totalCountShows = action.payload.totalCount;
    });
    builder.addCase(FetchShowsthunk.rejected, (state, action) => {

      state.showsLoading = false;
      state.showsError = action.payload;
      state.showsData = [];

      state.stateShowMoreButtonHorse = false;
      state.totalCountShows = 0;
    });

    builder.addCase(FetchLastShowsthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchLastShowsthunk.fulfilled, (state, action) => {
      state.showsLoading = false;
      state.lastShowsData = action.payload.result;
    });
    builder.addCase(FetchLastShowsthunk.rejected, (state, action) => {
      state.showsLoading = false;
      state.showsError = action.payload;
      state.lastShowsData = [];
    });

    builder.addCase(FetchShowbyIdthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchShowbyIdthunk.fulfilled, (state, action) => {
      state.showsLoading = false;
      state.showbyIdData = action.payload.result;

    });
    builder.addCase(FetchShowbyIdthunk.rejected, (state, action) => {
      state.showsLoading = false;
      state.showsError = action.payload;
      state.showbyIdData = {};
    });

    builder.addCase(FetchShowJudgesthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchShowJudgesthunk.fulfilled, (state, action) => {
      state.showsLoading = false;
      state.showJudgesData = action.payload.result;
    });
    builder.addCase(FetchShowJudgesthunk.rejected, (state, action) => {
      state.showsLoading = false;
      state.showsError = action.payload;
      state.showJudgesData = [];
    });

    builder.addCase(FetchShowClassRankListthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchShowClassRankListthunk.fulfilled, (state, action) => {
      state.showsLoading = false;
      const data = [
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
        { rank: "test1", horseNumber: 2, name: "test1", totalPoint: 2 },
      ];
      //state.showClassRankListData = data;
      state.showClassRankListData = action.payload.result;
    });
    builder.addCase(FetchShowClassRankListthunk.rejected, (state, action) => {
      state.showsLoading = false;
      state.showsError = action.payload;
      state.showClassRankListData = [];
    });

    builder.addCase(FetchShowChampionshipCardsthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(
      FetchShowChampionshipCardsthunk.fulfilled,
      (state, action) => {
        state.showsLoading = false;
        state.showChampionshipCardsData = action.payload.result;
      }
    );
    builder.addCase(
      FetchShowChampionshipCardsthunk.rejected,
      (state, action) => {
        state.showsLoading = false;
        state.showsError = action.payload;
        state.showChampionshipCardsData = [];
      }
    );

    builder.addCase(FetchChampionshipResultthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchChampionshipResultthunk.fulfilled, (state, action) => {
      state.showsLoading = false;
      state.championshipResultData = action.payload;
    });
    builder.addCase(FetchChampionshipResultthunk.rejected, (state, action) => {
      state.showsLoading = false;
      state.showsError = action.payload;
      state.championshipResultData = {};
    });

    builder.addCase(FetchShowGallerythunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchShowGallerythunk.fulfilled, (state, action) => {
      state.showsLoading = false;
      let data = [
        {
          fileType: "Image",
          url: "https://localhost:44329/img/957a8894-51b2-4778-9682-f66e8f4166ba_ad logo-final.png",
        },
        {
          fileType: "Image",
          url: "https://localhost:44329/img/957a8894-51b2-4778-9682-f66e8f4166ba_ad logo-final.png",
        },
        {
          fileType: "Image",
          url: "https://localhost:44329/img/957a8894-51b2-4778-9682-f66e8f4166ba_ad logo-final.png",
        },
        {
          fileType: "Video",
          url: "https://www.youtube.com/watch?v=CKi-KhMfzss",
        },
        {
          fileType: "Video",
          url: "https://www.youtube.com/watch?v=CKi-KhMfzss",
        },
      ];
      //state.showGalleryData = data;
      state.showGalleryData = action.payload.result;
    });
    builder.addCase(FetchShowGallerythunk.rejected, (state, action) => {
      state.showsLoading = false;
      state.showsError = action.payload;
      state.showGalleryData = {};
    });

    builder.addCase(FetchShowClassesthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(FetchShowClassesthunk.fulfilled, (state, action) => {
      state.showsLoading = false;
      state.showClassesData = action.payload.result;
    });
    builder.addCase(FetchShowClassesthunk.rejected, (state, action) => {
      state.showsLoading = false;
      state.showsError = action.payload;
      state.showClassesData = [];
    });

    builder.addCase(FetchShowClassHorseSoringDetailsthunk.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(
      FetchShowClassHorseSoringDetailsthunk.fulfilled,
      (state, action) => {
        state.showsLoading = false;
        state.showClassHorseSoringDetailsData = action.payload.result;
      }
    );
    builder.addCase(
      FetchShowClassHorseSoringDetailsthunk.rejected,
      (state, action) => {
        state.showsLoading = false;
        state.showsError = action.payload;
        state.showClassHorseSoringDetailsData = [];
      }
    );
    builder.addCase(FetchShowsGallery.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(
      FetchShowsGallery.fulfilled,
      (state, action) => {
        state.showsLoading = false;
        const formatedData = action.payload.data.items.map((item) => (
          {
            albumName: item?.properties?.albumName ?? "",
            albumMedia: item?.properties?.albumCover ?? [],
            albumId: item?.id ?? '',
            albumStartDate: formatDate(item?.properties?.eventStartDate) ?? '',
            albumEndDate: formatDate(item?.properties?.eventEndDate) ?? "",
            eventId: item?.properties?.eventId ?? ""
          }
        ))

        const filterdShows = formatedData.filter((item) => item.eventId == action.payload.param.id)
        state.showMediaData = filterdShows;
      }
    );
    builder.addCase(
      FetchShowsGallery.rejected,
      (state, action) => {
        state.showsLoading = false;
        state.showsError = action.payload;
        state.showClassHorseSoringDetailsData = [];
      }
    );
    builder.addCase(FetchShowsNews.pending, (state) => {
      state.showsLoading = true;
    });
    builder.addCase(
      FetchShowsNews.fulfilled,
      (state, action) => {
        state.showsLoading = false;
        const formatedData = action.payload.data.items.map((item) => (
          {
            id: item.id,
            name: item.name,
            Item_Body: item?.properties?.Item_Body?.markup ?? '',
            Item_CoverImage: item.properties?.Item_CoverImage ? item.properties?.Item_CoverImage[0]?.url : '',
            Item_Date: item.properties.Item_Date ? formatDateArray(item?.properties?.Item_Date) : '',
            Item_Date_Normal: item.properties.Item_Date ? new Date(item?.properties?.Item_Date) : '',
            Item_ShortDescription: item?.properties?.Item_ShortDescription ?? '',
            Item_Title: item?.properties?.Item_Title ?? '',
            createdBy: item?.properties?.createdBy ?? '',
            externalLink: item?.properties?.externalLink ?? false,
            item_Feature: item?.properties?.item_Feature ?? true,
            newsUrl: item?.properties?.externalLink ? item?.properties?.newsUrl : `/singlePost?id=${item.id}`,
            topOne: item?.properties?.topOne ?? false,
            newCategory: item?.properties?.newCategory ?? [],
            eventID: item?.properties?.eventId ?? ""
          }
        ))
        const filterdNews = formatedData.filter((item) => item.eventID == action.payload.param.id)

        state.showNewsData = filterdNews;
      }
    );
    builder.addCase(
      FetchShowsNews.rejected,
      (state, action) => {
        state.showsLoading = false;
        state.showsError = action.payload;
        state.showClassHorseSoringDetailsData = [];
      }
    );
  },
});

export const { incrementPageShows, resetSearch, emptyPageShows } =
  ShowsSlice.actions;
export default ShowsSlice.reducer;
