import React, { useRef, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ActionButton from "./ActionButton";

import { useDispatch, useSelector } from 'react-redux';
import { fetchAuctions } from '../Redux/Auctions/AuctionActions';
import { resetFilter } from "../Redux/Auctions/AuctionsSlice";
import Loader from "./Loader";
import { useTranslate } from "./TranslationText";
function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const BaseURL = process.env.REACT_APP_BASE_URL;


const data = [
  {
    eventImageSrc: `${BaseURL}/assets/img/horseauction.png`,
    eventName: "myname",
    auctionType: "Online",
    eventDate: new Date(),
    eventEndDate: new Date(),
    eventPrice: "500",
    EventHorses: 50,
    eventLink: "/singleauction?id=1",
    eventDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
];

// Component for individual image container
function ImageContainer({ data, index }) {
  const navigation = useNavigate();
  const imageContainerRef = useRef(null);
  const {translate} = useTranslate();
  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );

  // useEffect(() => {
  //   function setContainerHeight() {
  //     const container = imageContainerRef.current;
  //     if (container) {
  //       const width = container.offsetWidth;
  //       container.style.height = `${width}px`;
  //     }
  //   }

  //   setContainerHeight();
  //   window.addEventListener("resize", setContainerHeight);
  //   return () => {
  //     window.removeEventListener("resize", setContainerHeight);
  //   };
  // }, [currentLanguage]);

  return (
    <React.Fragment>
      <div className="col-md-4 col-sm-6 col-12 " key={index}>
        <div ref={imageContainerRef} className="eventimage-container">
          <div
            className="eventoverlay"
            onClick={() => navigation(`${data.eventLink}`)}
          ></div>{" "}
          {/* Overlay for black background */}
          <img
            src={data.eventImageSrc}
            alt={data.eventName}
            className="eventimage"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${BaseURL}/assets/img/horseauction.png`;
            }}
          />
          {/* <span className="EventListSpan">
            {translate(`web.${data.auctionType.toLowerCase()}`,data.auctionType)}
          </span> */}
          <div className="EventListDiv rtl">
            <span className="font-20-d-block">
              {data.eventName}
            </span>

            <span className="EventListDivSpan">
              {data.eventDescription}
            </span>
            <div className="EventListDivSpacer"></div>
            <div className="d-flex-row-spb-c">
              <div className="font-11">
                <i
                  className="fa fa-calendar goldenicon-mr-5"
                  aria-hidden="true"
                ></i>
                {formatDate(data.eventDate)}
              </div>
              <div className="font-11">
                <i
                  className="fa-solid fa-sack-dollar goldenicon-mr-5"
                ></i>
                {data.eventPrice} {translate('web.aed','AED')}
              </div>
            </div>
            <div className="EventListDivSpacer"></div>
            <div className="d-flex-row-spb-c viewDetailsRow">
              <div className="font-12 color-eahsred">
                {data.EventHorses} {translate(`web.horses`,'Horses')}
              </div>
              <Link
                className="hoveronlink"
                to={data.eventLink}
              >
                {translate("web.viewDetails","View Details")}
                <i
                  className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                ></i>
                <i
                  className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                ></i>
                <i
                  className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                ></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function EventsList({ filters }) {
  // Define the number of containers you want

  const dispatch = useDispatch();
  const auctions = useSelector((state) => state.auctions.auctions);
  const isLoading = useSelector((state) => state.auctions.isLoading);
  const totalCount = useSelector((state) => state.auctions.totalCount);
  const [viewMore, setViewMore] = useState(false); 
  const {translate} = useTranslate();
  

  useEffect(() => {
    setViewMore(false)
    if (filters.reset) {
      dispatch(resetFilter());
      }

    const { reset, ...dataFilters } = filters;
    dispatch(fetchAuctions(dataFilters));
  }, [filters]);

  const handelShowMore = () => {
    setViewMore(true)
    dispatch(fetchAuctions(filters));

  }
  if(isLoading && !viewMore){
    return <>
    <Loader/>
    </>
  }

  return (
    <div className="row justify-content-start">
      {/* Render the specified number of containers */}

      {!!auctions && auctions.length > 0 ? (
        auctions.map((itemData, index) => (
          <ImageContainer key={index} data={itemData} index={index} />
        ))
      ) : (
        <div
          className="col-lg-12 col-md-12 col-sm-12 text-center   EventListDataFoundDiv"
        >
          {translate('web.noAuctionFound','No Auction found with Your Filter Parameters')}
        </div>
      )}

      {totalCount > auctions.length && (
        <div className="col-lg-12 col-md-12 col-sm-12 text-center ">

          {!isLoading &&<ActionButton
            onClick={() => handelShowMore()}
            marginBottom={50}
            type={"loadmore"}
            label={translate("web.viewMore","View More")}
          />}
          {isLoading && (
            <Loader />
          )}
        </div>
      )}
    </div>
  );
}
