import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FetchAddEditAuctionHorseAPI,
  FetchAuctionEntryAPI,
  FetchAuctionHorsesRequestsAPI,
  FetchCheckOwnerHaseHorseInAuctionEntryAPI,
  FetchCountOwnerHaseHorseInAuctionEntryAPI,
  FetchDeleteAuctionHorseAPI,
  FetchGetAuctionByIdAPI,
  FetchGetOwnerAuctionHorsesCompletedAPI,
  FetchOwnerRequestsCountAPI,
  FetchUpdateAuctionHorsesAPI,
  FetchUpdateListAuctionHorsesIdsAPI,
} from "./AuctionEntryApi";

export const FetchAuctionEntrythunk = createAsyncThunk(
  "AuctionEntry/FetchAuctionEntry",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchAuctionEntryAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchAddEditAuctionHorsethunk = createAsyncThunk(
  "AuctionEntry/FetchAddEditAuctionHorse",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchAddEditAuctionHorseAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchAuctionHorsesRequeststhunk = createAsyncThunk(
  "AuctionEntry/FetchAuctionHorsesRequests",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchAuctionHorsesRequestsAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchDeleteAuctionHorsethunk = createAsyncThunk(
  "AuctionEntry/FetchDeleteAuctionHorse",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchDeleteAuctionHorseAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUpdateListAuctionHorsesIdsthunk = createAsyncThunk(
  "AuctionEntry/FetchUpdateListAuctionHorsesIds",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchUpdateListAuctionHorsesIdsAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCheckOwnerHaseHorseInAuctionEntrythunk = createAsyncThunk(
  "AuctionEntry/FetchCheckOwnerHaseHorseInAuctionEntry",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCheckOwnerHaseHorseInAuctionEntryAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchCountOwnerHaseHorseInAuctionEntrythunk = createAsyncThunk(
  "AuctionEntry/FetchCountOwnerHaseHorseInAuctionEntry",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchCountOwnerHaseHorseInAuctionEntryAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetOwnerAuctionHorsesCompletedthunk = createAsyncThunk(
  "AuctionEntry/FetchGetOwnerAuctionHorsesCompleted",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetOwnerAuctionHorsesCompletedAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUpdateAuctionHorsesthunk = createAsyncThunk(
  "AuctionEntry/FetchUpdateAuctionHorses",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchUpdateAuctionHorsesAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchGetAuctionByIdthunk = createAsyncThunk(
  "AuctionEntry/FetchGetAuctionById",
  async (param, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const response = await FetchGetAuctionByIdAPI(param); // Using Axios for the API call
      const data = response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
