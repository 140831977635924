import React, { useEffect, useState } from 'react'
import axiosInstance from '../axios/axios';
import { useLocation } from 'react-router';
import { useTranslate } from '../components/TranslationText';
import { ToastContainer, toast } from "react-toastify";
import { limit, onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';
import { bidsCollectionRef } from '../firebase/collections';
import { parseDateString } from '../helper/parseDateTimeToString';
import CronometerCounter from '../components/CronometerCounter';
import ComingSoon from '../components/ComingSoon';
import { formateDateToMac } from '../helper/convertDateToISO';
const BaseURL = process.env.REACT_APP_BASE_URL;


export default function LiveBiddingScreen() {
    const { translate } = useTranslate();
    const pageLoadTime = Timestamp.now();
    const [horseEntryTime, setHorseEntryTime] = useState(new Date())
    const currentLanguage = localStorage.getItem("eahsLang") ?? "en";
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentAuctionId = searchParams.has("auctionID")
        ? searchParams.get("auctionID")
        : "";

    const [auctionId, setAuctionId] = useState(currentAuctionId);
    const [auctionDataloaded, setAuctionDataLoaded] = useState(false);
    const [biddersLoaded, setBiddersLoaded] = useState(false);

    const [auctionData, setAuctionData] = useState({});
    const [horseData, setHorseData] = useState([]);
    const [biddersData, setBiddersData] = useState([]);

    async function getAuctionData(id = auctionId) {
        try {
            const res = await axiosInstance.get(`/auction/getauctionwithrunninghorse?Id=${id}`);
            if (res.data.status.responseCode == "200") {
                const auctionFormatedData = {
                    name: res.data.result.name,
                    startDate: res.data.result.startDate,
                    endDate: res.data.result.endDate,
                    logo: res.data?.result?.auctionAttachment.filter((item) => item.entity == 'AuctionFeatureImage') ?? [],
                    newLogo: res.data?.result?.logo ?? '',
                }
                setAuctionData(auctionFormatedData);
                const inArenaHorses = res.data.result.auctionSelectedHorses.filter((horse) => horse.isHorseInArena == true);
                setHorseData(inArenaHorses);
                if (inArenaHorses.length > 0) {
                    getBiddersData(id, inArenaHorses[0].horseId)
                    setHorseEntryTime(new Date(inArenaHorses[0].horseDateInArena))
                }
                setAuctionDataLoaded(true);
            }
        } catch (error) {
            console.debug('error in getting auction data', error)
        }
    }

    async function getBiddersData(auctionId, horseId) {
        try {
            const bids = await axiosInstance.get(`/bid?auctionId=${auctionId}&horseId=${horseId}`)
            if (bids.data.status.responseCode == "200") {
                setBiddersData(bids.data.result);
                setBiddersLoaded(true);
            }
        } catch (error) {

        }
    }




    useEffect(() => {
        getAuctionData();

    }, [])

    // firestore 
    const oneMinuteAgo = new Date();

    useEffect(() => {
        const q = query(
            bidsCollectionRef,
            where("AuctionId", "==", auctionId),
            orderBy('TimeStamp', 'desc'),
            where("TimeStamp", ">=", oneMinuteAgo),
            limit(1),
        );

        // Set up a real-time listener for new documents
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newEntries = snapshot.docs.map((doc) => {
                const data = doc.data();

                // Format date before setting it into state
                return {
                    id: doc.id,
                    ...data,
                };
            });

            if (newEntries.length > 0) {
                // add new bid to the Table on UI
                if (!!newEntries[0].BidderNumber) {
                    const formattedBidders = newEntries.map((bidder) => ({
                        bidderNumber: bidder.BidderNumber,
                        bidderName: currentLanguage == 'en' ? bidder.BidderName : bidder.BidderNameAr,
                        horseAmount: parseInt(bidder.HorseAmount),
                        bidTime: bidder.BidTime,
                        bidderType: bidder.BidderType
                    }));

                    setBiddersData((prevDocs) => [...formattedBidders, ...prevDocs]);
                }

                if (newEntries[0].HorseStatus == 'StartBidding') {
                    toast.warn('new Horse Added');
                    setBiddersData([]);
                    getAuctionData();
                }
                if (newEntries[0].HorseStatus == 'Sold') {
                    toast.success('Current horse is Sold');
                    setHorseData([]);
                    setBiddersData([]);
                }
                if (newEntries[0].HorseStatus == 'UnSold') {
                    toast.error('Current horse is UnSold');
                    setHorseData([]);
                    setBiddersData([]);
                }

            }

        });

        // Clean up listener on unmount
        return () => unsubscribe();
    }, []);

    const dismissAll = () => toast.dismiss();

    return (
        <>
            <ToastContainer
                position="top-right"
                onClick={dismissAll}
                theme="colored"
                autoClose={5000}
            />
            <div className='container-lg  mt-4 fullScreenBorder'>
                {auctionDataloaded &&
                    <>
                        <div className='d-flex mx-0 justify-content-between mb-3 align-items-center px-3'>
                            <div style={{ minWidth: 200 }}>
                                <img src={`${BaseURL}/assets/img/squarlogo.png`} alt='logo' style={{ height: '13vh' }} />
                            </div>
                            <div>
                                <h2 className='text-center capatalize_text bold' >{auctionData.name}</h2>

                                {horseData.length > 0 &&
                                    <div >
                                        <h3 className='text-center capatalize_text bold'>{horseData[0].lotNumber} - {horseData[0].horseName}</h3>
                                    </div>}
                            </div>
                            <div className='d-flex  justify-content-between  align-items-center' style={{ minWidth: 200 }}>
                                {/* {horseData.length > 0 &&
                                    <>
                                        <CronometerCounter endDate={horseEntryTime} countUp={true} isMinutes={true} className='text-danger ms-4' />
                                        <i className="fa-solid fa-clock text-danger mx-2 mt-1" style={{ fontSize: 20 }}></i>
                                    </>
                                } */}
                                {!!auctionData.newLogo &&
                                    <img src={auctionData.newLogo} className='ms-auto' alt='logo' style={{ height: '13vh' }} />
                                }
                            </div>
                        </div>
                    </>
                }
                <div className="tableFixHead" style={{ maxHeight: '70vh', height: '100vh', overflowY: 'hidden' }}>
                    <table className='table table-striped table-bordered' id="listofbidstable">
                        <thead>
                            <tr>
                                <th className='text-center' style={{ fontWeight: 'bold' }} >{translate("web.bidderNo", "Bidder No")}</th>
                                <th className='text-center' style={{ fontWeight: 'bold' }} >{translate("web.bidderName", "Bidder Name")}</th>
                                <th className='text-center' style={{ fontWeight: 'bold' }} >{translate("web.bidAmount", "Bid Amount")}</th>
                                <th className='text-center' style={{ fontWeight: 'bold' }} >{translate("web.bidTime", "Bid Time")}</th>
                                <th className='text-center bold' style={{ fontWeight: 'bold' }} >{translate("web.bidderType", "Bidder Type")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {biddersData.map((bidder) => (
                                <tr>
                                    <td className='text-center'>{bidder.bidderNumber}</td>
                                    <td className='text-center'>
                                        {bidder.bidderName}
                                    </td>
                                    <td className='text-center'>
                                        {bidder.horseAmount.toLocaleString('en-US')} {translate("web.aed", "AED")}
                                    </td>
                                    <td className='text-center'>
                                        {formateDateToMac(bidder.bidTime)}
                                    </td>
                                    <td className='text-center'>
                                        {bidder.bidderType.toLowerCase() == "live" ||
                                            bidder.bidderType.toLowerCase() == "حضوري" ? (
                                            <React.Fragment>
                                                <i className="fa-solid fa-circle color-eahsred"></i>{" "}
                                                {translate("web.live", "Live")}
                                            </React.Fragment>
                                        ) : (
                                            ""
                                        )}
                                        {bidder.bidderType.toLowerCase() == "online" ||
                                            bidder.bidderType.toLowerCase() == "عبر الانترنت" ? (
                                            <React.Fragment>
                                                {" "}
                                                <i className="fa-solid fa-circle color-green"></i>{" "}
                                                {translate("web.onlineBidder", "Online")}
                                            </React.Fragment>
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
