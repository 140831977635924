import React, { useEffect, useState } from "react";
import CollapseLabel from "../components/CollapseLabel";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchStudBookHorsePedigreeByIdthunk } from "../Redux/StudBook/StudBookAction";
import { FetchHorseMarketDetailsthunk } from "../Redux/HorseMarket/HorseMarketAction";
import HorseDetailsPedigreeChart from "../components/HorseDetailsPedigreeChart";

import { useTranslate } from "../components/TranslationText";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loader from "../components/Loader";
import ActionButton from "../components/ActionButton";

export default function HorseMarketProfile() {
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentId = searchParams.has("id") ? searchParams.get("id") : null;
  const currentHorseId = searchParams.has("horseId")
    ? searchParams.get("horseId")
    : null;

  const { studBookPedigreeData, currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );
  const { translate } = useTranslate();

  const { horseMarketDetailsData, horseMarketLoading } = useSelector(
    (state) => state.horseMarketSlice
  );

  const aboutArray = Object.entries(horseMarketDetailsData);

  const renderVideo = (item) => {
    return (
      // <div className="video-wrapper">
      //   {item.video === true ? (
      //     <iframe
      //       width="100%"
      //       height="480px"
      //       src={item.embedUrl}
      //       frameBorder="0"
      //       allowFullScreen
      //       title="ex"
      //     />
      //   ) : (
      //     <img
      //       src={`${BaseURL}/assets/img/novideo.png`}
      //       className="novideofound"
      //       alt="no video found"
      //     />
      //   )}
      // </div>
      <div className="video-wrapper">
        {item.video === true ? (
          <iframe
            width="100%"
            height="480px"
            src={item.embedUrl}
            frameBorder="0"
            allowFullScreen
            title="ex"
          />
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
    );
  };
  function getThumbnailUrlFromEmbedLink(embedLink) {
    // Check if it's a valid YouTube embed link
    if (!embedLink.includes("youtube.com/embed/")) {
      return null; // Not a YouTube embed link
    }
  
    // Extract the video ID from the URL
    const urlParts = embedLink.split("/");
    const videoId = urlParts[urlParts.length - 1];
  
    // Construct the thumbnail URL using the video ID
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`; // Adjust for desired size
  
    return thumbnailUrl;
  }

  const items =
    horseMarketDetailsData?.youTubeVideoUrl?.includes(
      "youtube.com/watch?v="
    ) === true
      ? [
          {
            embedUrl: `${
              horseMarketDetailsData?.youTubeVideoUrl?.includes(
                "youtube.com/watch?v="
              ) === true
                ? horseMarketDetailsData?.youTubeVideoUrl?.replace(
                    "youtube.com/watch?v=",
                    "youtube.com/embed/"
                  )
                : ""
            }`,
            original: `${BaseURL}/assets/img/video-icon.png`,
            // thumbnail: `${BaseURL}/assets/img/video-icon.png`,
            thumbnail: `${
              horseMarketDetailsData?.youTubeVideoUrl?.includes(
                "youtube.com/watch?v="
              ) === true
                ? getThumbnailUrlFromEmbedLink(horseMarketDetailsData?.youTubeVideoUrl?.replace(
                    "youtube.com/watch?v=",
                    "youtube.com/embed/"
                  ))
                : ""
            }`,
            renderItem: renderVideo.bind(this),
            video: horseMarketDetailsData?.youTubeVideoUrl?.includes(
              "youtube.com/watch?v="
            ),
          },

          ...horseMarketDetailsData?.horseMarketMedia?.map((a) => ({
            original:
              a.url !== null && a.url !== ""
                ? a.url
                : `${BaseURL}/assets/img/horsemarket.jpg`,
            thumbnail:
              a.url !== null && a.url !== ""
                ? a.url
                : `${BaseURL}/assets/img/horsemarket.jpg`,
          })),
          //...images,
        ]
      : horseMarketDetailsData?.horseMarketMedia?.map((a) => ({
          original:
            a.url !== null && a.url !== ""
              ? a.url
              : `${BaseURL}/assets/img/horsemarket.jpg`,
          thumbnail:
            a.url !== null && a.url !== ""
              ? a.url
              : `${BaseURL}/assets/img/horsemarket.jpg`,
        }));
  //...images,

  const dispatch = useDispatch();

  useEffect(() => {
    async function getHorseData() {
      let paramsHorseMarketDetails = {
        id: currentId,
        langauge: currentLanguage,
      };
      let paramsHorsePedigree = {
        id: currentHorseId,
        langauge: currentLanguage,
      };
      dispatch(FetchHorseMarketDetailsthunk(paramsHorseMarketDetails));
      dispatch(FetchStudBookHorsePedigreeByIdthunk(paramsHorsePedigree));
    }
    getHorseData();
  }, [dispatch, currentId, currentLanguage]);

  function formatDate(_date) {
    let date = new Date(_date);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (currentLanguage === "en") {
      return day + "-" + month + "-" + year;
    } else {
      return year + "-" + month + "-" + day;
    }
  }

  return (
    <React.Fragment>
      {horseMarketLoading && <Loader />}

      {!!horseMarketDetailsData &&
        !horseMarketLoading &&
        horseMarketDetailsData.horseMarketMedia != null &&
        !!studBookPedigreeData &&
        !horseMarketLoading && (
          <div className="container-fluid p-0-50px">
            <div className="row studBookPedigreeDataRow rtl">
              {
                // <div style={{ padding: "1em" }}>
                //   <div className="row" style={{ height: 148, width: 264 }}>
                //     <div
                //       className="col-lg-12 col-md-12 col-sm-12"
                //       style={{ height: "inherit" }}
                //     >
                //       <img
                //         className="horse-category-img"
                //         src={`${BaseURL}/assets/img/horsemarket.jpg`}
                //         alt="itemName"
                //         style={{ height: 143, width: 239 }}
                //       />
                //     </div>
                //     <div
                //       className="col-lg-12 col-md-12 col-sm-12"
                //       style={{ height: "inherit" }}
                //     >
                //       <img
                //         className="horse-category-img"
                //         src={`${BaseURL}/assets/img/horsemarket.jpg`}
                //         alt="itemName"
                //         style={{ height: 143, width: 239 }}
                //       />
                //     </div>
                //     <div
                //       className="col-lg-12 col-md-12 col-sm-12"
                //       style={{ height: "inherit" }}
                //     >
                //       <img
                //         className="horse-category-img"
                //         src={`${BaseURL}/assets/img/horsemarket.jpg`}
                //         alt="itemName"
                //         style={{ height: 143, width: 239 }}
                //       />
                //     </div>
                //   </div>
                // </div>
              }
              <div className="col-md-8 col-sm-12 pt1em">
                {
                  <ImageGallery
                    items={items}
                    showPlayButton={false}
                    showIndex={true}
                    showFullscreenButton={false}
                  />
                }
                {
                  // <img
                  //   className="horse-category-img"
                  //   src={
                  //     horseMarketDetailsData.horseMarketMedia.length > 0
                  //       ? horseMarketDetailsData.horseMarketMedia[0].url
                  //       : `${BaseURL}/assets/img/horsemarket.jpg`
                  //   }
                  //   alt="itemName"
                  //   style={{ height: 438, width: 586 }}
                  // />
                }
              </div>
              <div className="col-md-4 col-sm-12" style={{ paddingLeft: "1em" }}>
                <h1
                  className="horseMarketDetailsDataHorseName"
                  style={{ marginTop: 10 }}
                >
                  {horseMarketDetailsData.registrationNumber} {" - "}{" "}
                  {horseMarketDetailsData.horseName}
                </h1>
                <h2
                  className="horseMarketDetailsDatadetails"
                >
                  {" "}
                  {translate("web.details", "Details")}
                </h2>
                <div className="row">
                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p
                      className="horseMarketDetailsDataGender"
                      style={{ display: "inline" }}
                    >
                      {" "}
                      {translate("web.gender", "gender")}
                    </p>{" "}
                    <span
                      className="horseMarketDetailsDataGenderString"
                      style={{ fontWeight: "bold", marginLeft: 50 }}
                    >
                      {translate(`web.${horseMarketDetailsData.genderString.toLowerCase()}`, horseMarketDetailsData.genderString)}
                    </span>
                  </div>
                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p
                      className="horseMarketDetailsDataBirthdate"
                      style={{ display: "inline" }}
                    >
                      {" "}
                      {translate("web.birthDate", "Birthdate")}
                    </p>{" "}
                    <span style={{ fontWeight: "bold", marginLeft: 32 }}>
                      {horseMarketDetailsData.birthDate &&
                        formatDate(horseMarketDetailsData.birthDate)}
                    </span>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p
                      className="horseMarketDetailsDataCategory"
                      style={{ display: "inline" }}
                    >
                      {" "}
                      {translate("web.category", "Category")}
                    </p>{" "}
                    <span
                      className="horseMarketDetailsDataHorseCategory"
                      style={{ fontWeight: "bold", marginLeft: 40 }}
                    >
                      {horseMarketDetailsData.horseCategory != null
                        ? horseMarketDetailsData.horseCategory === "ShowHorse"
                          ? translate("web.showHorse","Show Horse")
                          : horseMarketDetailsData.horseCategory === "FlatRace"
                          ? translate("web.flatRace","Flat Race")
                          : horseMarketDetailsData.horseCategory ===
                          translate('web.enduranceHorse',"EnduranceHorse")
                          ? translate("web.enduranceHorse","Endurance Horse")
                          : horseMarketDetailsData.horseCategory
                        : translate(`web.${horseMarketDetailsData.horseCategory.toLowerCase()}`,horseMarketDetailsData.horseCategory)}
                    </span>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p
                      className="horseMarketDetailsDataColor"
                      style={{ display: "inline" }}
                    >
                      {" "}
                      {translate("web.color", "Color")}
                    </p>{" "}
                    <span
                      className="horseMarketDetailsDataColorVal"
                      style={{ fontWeight: "bold", marginLeft: 64 }}
                    >
                      {horseMarketDetailsData.color}
                    </span>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p
                      className="horseMarketDetailsDataColor"
                      style={{ display: "inline" }}
                    >
                      {" "}
                      {translate("web.owner", "Owner")}
                    </p>{" "}
                    <span
                      className="horseMarketDetailsDataColorVal"
                      style={{ fontWeight: "bold", marginLeft: 55 }}
                    >
                      {horseMarketDetailsData.ownerName}
                    </span>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p
                      className="horseMarketDetailsDataColor"
                      style={{ display: "inline" }}
                    >
                      {" "}
                      {translate("web.address", "address")}
                    </p>{" "}
                    <span
                      className="horseMarketDetailsDataColorVal"
                      style={{ fontWeight: "bold", marginLeft: 49 }}
                    >
                      {horseMarketDetailsData.address}
                    </span>
                  </div>
                </div>

                <h2 className="horseMarketDetailsDatadetails">
                  {translate("web.contact", "Contact")}
                </h2>
                <div className="row">
                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p style={{ display: "inline" }}>
                      {" "}
                      {translate("web.email", "Email")}
                    </p>{" "}
                    <span style={{ fontWeight: "bold", marginLeft: 50 }}>
                      {horseMarketDetailsData.email}
                    </span>
                  </div>

                  <div className="col-md-12" style={{ marginBottom: 7 }}>
                    <p style={{ display: "inline" }}>
                      {" "}
                      {translate("web.phone", "Phone")}
                    </p>{" "}
                    <span style={{ fontWeight: "bold", marginLeft: 45 }}>
                      {horseMarketDetailsData.contact}
                    </span>
                  </div>
                </div>

                <h2 className="horsePrice" style={{ fontWeight: "bold" }}>
                  {" "}
                  {horseMarketDetailsData.horsePrice}{" "}
                  {currentLanguage === "en" ? "AED" : "د.إ"}
                </h2>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <h2 className="rtl">
                {" "}
                {translate("web.pedigree", "Pedigree")}
              </h2>
              <HorseDetailsPedigreeChart
                hierarchy={studBookPedigreeData}
                pageName={"HorseMarketProfile"}
              />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 text-center" style={{marginTop:10}}>
              <ActionButton to={`/horsedetails?horseId=${currentHorseId}`} type={'internalLink'} text={translate('web.StudBookDetails',  'STUD BOOK DETAILS')} />
            </div>
          </div>
        )}
    </React.Fragment>
  );
}
