import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../components/TranslationText";
import SubmitBtn from "../components/SubmitBtn";
import Loader from "../components/Loader";
import { json, useLocation, useNavigate } from "react-router";
import {
  FetchAddEditHorseQualificationthunk,
  FetchCreateShowEntryRegisteredthunk,
  FetchCreateShowEntrythunk,
  FetchGetHorseQualificationByHorseIdthunk,
  FetchMyShowParticipantsRequestthunk,
  FetchOwnerRequestsCountthunk,
  FetchShowEntryOwnerHorsesthunk,
  FetchShowRequestthunk,
  FetchSubOwnerProfilesByIdthunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import { Link, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  handelActiveTabOwnerProfile,
  handelResetShowEntryTempData,
  handelShowEntryTempData,
  myShowParticipantsRequestChooses,
  resetIsDraftStatus,
  resetNavigateStatus,
  resetToasterMessageShowEntry,
  setToasterAsSave,
} from "../Redux/ShowEntry/ShowEntrySlice";
import ActionButton from "../components/ActionButton";
import Select from "react-select";
import { CompareDate } from "../helper/CompareDate";
import { formatDate } from "../helper/formatDate";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Popup from "reactjs-popup";
import NetaqInput from "../components/NetaqInput";
import { FetchCountriesthunk } from "../Redux/LookUp/LookUpAction";
import dayjs from "dayjs";
import axiosInstance from "../axios/axios";
import { convertToCamelCase } from "../helper/convertToCamelCase";

export default function ShowEntryCard() {
  const showEntryCardsRef = useRef(null);
  const showEntryCardsUnregisteredRef = useRef(null);
  const [cardsRefCount, setCardsRefCount] = useState(0);

  const [showEntryCards, setShowEntryCards] = useState([]);
  const [showEntryCompletedCards, setShowEntryCompletedCards] = useState([]);
  const [showEntryHorseCard, setShowEntryHorseCard] = useState("");
  const [maxHorses, setMaxHorses] = useState(0);
  const showEntryClLoading = useRef(false);
  const [openPopup, setOpenPopup] = useState(false);
  const horseQualificationState = useRef(false);

  const dispatch = useDispatch();

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { countriesData } = useSelector((state) => state.lookUpSlice);

  const { translate } = useTranslate();

  const horseQualificationReset = () => {
    return {
      horseId: null,
      regNo: "",
      horseName: "",
      birthDate: "",
      countryName: "",
      gender: "",
      showHorseStatus: "",
      isRegistered: true,
      countryQual: null,
      countryQualDrop: {
        label: translate("web.countryName", "Country Name"),
        value: null,
      },
      showName: "",
      year: null,
      className: "",
      classRank: "",
      websiteURL: "",
    };
  };

  const [horseQualification, setHorseQualification] = useState(
    horseQualificationReset()
  );

  const [yearDate, setYeardate] = useState(null);

  const handleHorseQualificationChange = (event, input) => {
    if (!!input && input === "countryQual") {
      setHorseQualification({
        ...horseQualification,
        countryQual: event.value,
        countryQualDrop: event,
      });
    } else if (!!input && input === "year") {
      setYeardate(event);
      setHorseQualification({
        ...horseQualification,
        year: event.$d.toISOString(),
      });
    } else {
      setHorseQualification({
        ...horseQualification,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handelResetHorseQualification = () => {
    setOpenPopup(false);

    setHorseQualification(horseQualificationReset());

    setYeardate(null);
  };

  const handelHorseQualificationSubmit = async () => {
    if (
      horseQualification.countryQual != null &&
      horseQualification.showName !== "" &&
      horseQualification.year != null &&
      horseQualification.className !== "" &&
      horseQualification.classRank !== ""
    ) {
      horseQualificationState.current = true;

      let params = {
        horseId: horseQualification.horseId,
        countryId: horseQualification.countryQual,
        showName: horseQualification.showName,
        year: horseQualification.year,
        className: horseQualification.className,
        classRank: horseQualification.classRank,
        websiteURL: horseQualification.websiteURL,
      };
      // dispatch(FetchAddEditHorseQualificationthunk(params));
      const response = await axiosInstance.post(`/ShowEntry/AddEditHorseQualification`, {
        id: params.horseId,
        countryId: params.countryId,
        showName: params.showName,
        year: params.year,
        className: params.className,
        classRank: params.classRank,
        website: params.websiteURL,
      });
      if (response.status == 200) {
        dispatch(setToasterAsSave());
        setTimeout(() => {
          dispatch(
            handelShowEntryTempData({
              type: "add",
              data: {
                horseId: horseQualification.horseId,
                horseName: horseQualification.horseName,
                horseBirthDate: horseQualification.birthDate,
                horseOrigin: horseQualification.countryName,
                horseGender: horseQualification.gender,
                horseRegisterNo: horseQualification.regNo,
                amount: entryFees,
                statusName: horseQualification.showHorseStatus,
                notes: "",
                isRequiredConfirmation: true,
              },
            })
          );
          setShowEntryCards((prevState) => [
            ...prevState,
            {
              id: horseQualification.horseId,
              title: horseQualification.horseName,
              date: horseQualification.birthDate,
              country: horseQualification.countryName,
              gender: horseQualification.gender,
              regNo: horseQualification.regNo,
              price: entryFees,
              showHorseStatus: horseQualification.showHorseStatus,
              isRegisterd: horseQualification.isRegistered,
              isQualified: false,
              isRequiredConfirmation: true,
            },
          ]);
        }, 500);

      }


      setOpenPopup(false);
    } else {
      toast.error(
        translate("web.completeMissingData", "Complete The Missing Data")
      );
    }
  };
  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subProfiles = userProfile.subAccounts ?? [];

  const [profile, setProfile] = useState({
    label: userProfile.ownerRegisterNo + " - " + userProfile[`fullName${currentLanguage == 'en' ? '' : 'Ar'}`],
    value: localStorage.getItem("EAHSUserID"),
  });

  const draftState = useRef(true);

  const navigate = useNavigate();

  const location = useLocation();
  //const searchParams = new URLSearchParams(location.search);
  const [searchParams, setSearchParams] = useSearchParams(location.search);

  const entryFees = searchParams.has("entryFees")
    ? searchParams.get("entryFees")
    : null;

  const showNameAr = searchParams.has("showNameAr")
    ? searchParams.get("showNameAr")
    : null;

  const showNameEn = searchParams.has("showNameEn")
    ? searchParams.get("showNameEn")
    : null;

  const showId = searchParams.has("showId") ? searchParams.get("showId") : null;

  let addRegisterHorseId = searchParams.has("addRegisterHorseId")
    ? searchParams.get("addRegisterHorseId")
    : null;
  //: "26527b5d-fd38-4f00-aa33-462b00ad9f51";

  const maxHorsesUrl = searchParams.has("maxHorses")
    ? parseInt(searchParams.get("maxHorses"))
    : null;
  const registerStartDate = searchParams.has("registerStartDate")
    ? searchParams.get("registerStartDate")
    : null;

  const registerEndDate = searchParams.has("registerEndDate")
    ? searchParams.get("registerEndDate")
    : null;

  const registerStartDateInter = searchParams.has("registerStartDateInter")
    ? searchParams.get("registerStartDateInter")
    : null;

  const registerEndDateInter = searchParams.has("registerEndDateInter")
    ? searchParams.get("registerEndDateInter")
    : null;
  const showStartDate = searchParams.has("showStartDate")
    ? searchParams.get("showStartDate")
    : null;

  const {
    showEntryLoading,
    showEntryOwnerHorsesData,
    toasterMessageShowEntry,
    myShowParticipantsRequestData,
    showRequestData,
    navigateStatus,
    showEntryTempData,
    ownerRequestsCountData,
    createShowEntryData,
    isDraftRegistered,
    isDraftUnRegistered,
    getHorseQualificationByHorseIdData,
  } = useSelector((state) => state.showEntrySlice);

  // console.log("showEntryCompletedCards", showEntryCompletedCards);
  // console.log("showEntryCards", showEntryCards);
  // console.log("horseQualification", horseQualification);

  const [isNoPaymentOwner, setIsNoPaymentOwner] = useState(false);
  async function checkIfIsNoPaymentOwner(showid, ownerId) {
    try {
      const check = await axiosInstance.post(
        `showentry/CheckOwnerFreeJoin?showId=${showid}&OwnerId=${ownerId}`,
        { showId: showid, OwnerId: ownerId }
      );
      if (check.data.responseCode == "200") {
        const isFree = check.data.responseText == "true" ? true : false;
        setIsNoPaymentOwner(isFree);
      }
    } catch (error) {
      setIsNoPaymentOwner(false);
    }
  }

  const handelFetchShowEntryOwnerHorsesAndRequests = () => {
    let params = {
      ownerId:
        userProfile.accountType != "Agent"
          ? profile.value == null
            ? localStorage.getItem("EAHSUserID")
            : profile.value
          : localStorage.getItem("currentSubAccountId"),
      createdBy: localStorage.getItem("EAHSUserID"),
      showId: showId,
      searchValue: null,
      langauge: currentLanguage,
    };
    dispatch(FetchShowEntryOwnerHorsesthunk(params));
    checkIfIsNoPaymentOwner(params.showId, params.ownerId);

    let requestParams = {
      id: localStorage.getItem("EAHSUserID"),
      showId: showId,
      langauge: currentLanguage,
    };
    dispatch(FetchShowRequestthunk(requestParams));
  };




  useEffect(() => {
    handelFetchShowEntryOwnerHorsesAndRequests();

    if (userProfile.accountType != "Agent") {
      let subOwnerProfilesParams = {
        id: localStorage.getItem("EAHSUserID"),
        langauge: currentLanguage,
      };
      dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));
    }

    dispatch(handelActiveTabOwnerProfile("showEntry"));

    let params = {
      ownerId:
        userProfile.accountType != "Agent"
          ? localStorage.getItem("EAHSUserID")
          : localStorage.getItem("currentSubAccountId"),
      showId: showId,
    };

    dispatch(FetchOwnerRequestsCountthunk(params));

    dispatch(FetchCountriesthunk({ langauge: currentLanguage }));
  }, [currentLanguage]);

  useEffect(() => {
    showEntryClLoading.current = true;

    let results = [];

    if (showEntryTempData.length > 0) {
      results = showEntryTempData.filter(
        ({ id: id1 }) => !showRequestData.some(({ id: id2 }) => id2 === id1)
      );
    }

    const showRequestList = showRequestData.concat(...results).map((item) => {
      return {
        id: item.horseId,
        title: item.horseName,
        date: item.horseBirthDate,
        country: item.horseOrigin,
        gender: item.horseGender,
        regNo: item.horseRegisterNo,
        price: item.amount,
        showHorseStatus: item.statusName,
        notes: item.notes,
        isRegisterd: item.isRegistered,

        isRequiredConfirmation: item.isRequiredConfirmation,
      };
    });

    setShowEntryCards(showRequestList);

    showEntryClLoading.current = false;
  }, [showRequestData]);

  useEffect(() => {
    showEntryClLoading.current = true;

    let completedHorses = showEntryOwnerHorsesData.filter(
      (item) =>
        item.showHorseStatus === "Completed" ||
        item.showHorseStatus === "PendingConfirmation"
      //item.showHorseStatus === "WaitingList"
    );

    setShowEntryCompletedCards(completedHorses);

    let showEntryCardObj = null;
    debugger;
    if (
      addRegisterHorseId != null &&
      showEntryCards.filter((obj) => obj.id === addRegisterHorseId).length ===
      0 &&
      (maxHorsesUrl == 0 ||
        (maxHorsesUrl > 0 && (showEntryCards.length < maxHorsesUrl)))
    ) {
      debugger
      for (const e of showEntryCardsUnregisteredRef.current.children) {
        if (e.attributes.divid.nodeValue === addRegisterHorseId) {
          showEntryCardObj = {
            id: addRegisterHorseId,
            title: e.attributes.divtitle.nodeValue,
            date: e.attributes.divdate.nodeValue,
            country: e.attributes.divcountry.nodeValue,
            gender: e.attributes.divgender.nodeValue,
            regNo: e.attributes.divregno.nodeValue,
            price: entryFees,
            showHorseStatus: e.attributes.divshowHorseStatus.nodeValue,
            isRegisterd: JSON.parse(e.attributes.divisRegisterd.nodeValue),
            isRequiredConfirmation: null,
          };
        }
      }
    }

    if (showEntryCardObj != null) {
      setShowEntryCards((oldValues) => [...oldValues, showEntryCardObj]);
    }

    showEntryClLoading.current = false;
  }, [showEntryOwnerHorsesData]);

  useEffect(() => {
    showEntryClLoading.current = true;

    showEntryCards.map((item) => {
      if (item.isQualified == undefined) {
        for (let e of showEntryCardsRef.current.children) {
          if (
            e.getElementsByTagName("i") !== undefined &&
            e.getElementsByTagName("i")[0]?.tagName === "I" &&
            e.getElementsByTagName("i")[0].id === item.id
          ) {
            e.getElementsByTagName("i")[0].className = "";
          } else {
            if (
              e.getElementsByTagName("i") !== undefined &&
              e.getElementsByTagName("i").length > 0 &&
              !showEntryCards.some(
                (q) => q.id == e.getElementsByTagName("i")[0].id
              )
              && !showEntryOwnerHorsesData.some(
                (q) => q.id == e.getElementsByTagName("i")[0].id && !!q.notEligibleReason
              )
              && showEntryCompletedCards.some(
                (q) => q.id != e.getElementsByTagName("i")[0].id
              )
            ) {
              e.getElementsByTagName("i")[0].className =
                "fa fa-plus plusIcon fa-lg line-height";
            }
          }
        }
      }
      for (const e of showEntryCardsUnregisteredRef.current.children) {
        // console.log("eeee", e);
        if (
          e.getElementsByTagName("div")[0]?.getElementsByTagName("div")[3]
            ?.tagName === "DIV" &&
          e.getElementsByTagName("div")[0]?.getElementsByTagName("div")[3]
            ?.id === `edit-${item.id}`
        ) {
          e
            .getElementsByTagName("div")[0]
            .getElementsByTagName(
              "div"
            )[3].className = `col-md-12 titletrashshowentrycard`;
          const x = document.getElementById(item.id);
          if (!!x) {
            x.style = "display:none;";
          }
        }
      }
    });

    showRequestData.map((item) => {
      for (let e of showEntryCardsRef.current.children) {
        if (
          e.getElementsByTagName("i") !== undefined &&
          e.getElementsByTagName("i")[0]?.tagName === "I" &&
          e.getElementsByTagName("i")[0].id === item.horseId
        ) {
          e.getElementsByTagName("i")[0].className = "";
        } else {
          if (
            e.getElementsByTagName("i") !== undefined &&
            e.getElementsByTagName("i").length > 0 &&
            e.getElementsByTagName("i")[0].attributes.isNotQualified ==
            undefined &&
            !showRequestData.some(
              (q) => q.horseId == e.getElementsByTagName("i")[0].id
            )
            && !showEntryOwnerHorsesData.some(
              (q) => q.id == e.getElementsByTagName("i")[0].id && !!q.notEligibleReason
            )
            && showEntryCompletedCards.some(
              (q) => q.id != e.getElementsByTagName("i")[0].id
            )
          ) {
            e.getElementsByTagName("i")[0].className =
              "fa fa-plus plusIcon fa-lg line-height";
          }

          if (
            e.getElementsByTagName("i") !== undefined &&
            e.getElementsByTagName("i").length > 0 &&
            e.getElementsByTagName("i")[0].attributes.isNotQualified !==
            undefined &&
            !showRequestData.some(
              (q) => q.horseId == e.getElementsByTagName("i")[0].id
            )
            && showEntryCompletedCards.some(
              (q) => q.id != e.getElementsByTagName("i")[0].id
            )
          ) {
            e.getElementsByTagName("i")[0].className =
              "fa-solid fa-ban fa-lg line-height cursorPointer";
          }
        }
      }
    });

    showEntryCards.map((item) => {
      for (const e of showEntryCardsUnregisteredRef.current.children) {
        if (
          e.getElementsByTagName("div")[0]?.getElementsByTagName("div")[3]
            ?.tagName === "DIV" &&
          e.getElementsByTagName("div")[0]?.getElementsByTagName("div")[3]
            ?.id === `edit-${item.id}`
        ) {
          e
            .getElementsByTagName("div")[0]
            .getElementsByTagName(
              "div"
            )[3].className = `col-md-12 titletrashshowentrycard`;
          // document.getElementById(item.id).style = "display:none;";
          const x = document.getElementById(item.id);
          if (!!x) {
            x.style = "display:none;";
          }
        }
      }
    });

    if (addRegisterHorseId != null) {
      for (const e of showEntryCardsUnregisteredRef.current.children) {
        if (
          e.getElementsByTagName("div")[0]?.getElementsByTagName("div")[3]
            ?.tagName === "DIV" &&
          e.getElementsByTagName("div")[0]?.getElementsByTagName("div")[3]
            ?.id === `edit-${addRegisterHorseId}`
        ) {
          e
            .getElementsByTagName("div")[0]
            .getElementsByTagName(
              "div"
            )[3].className = `col-md-12 titletrashshowentrycard`;

          // document.getElementById(addRegisterHorseId).style = "display:none;";
          const x = document.getElementById(addRegisterHorseId);
          if (!!x) {
            x.style = "display:none;";
          }
        }
      }
    }

    setMaxHorses(maxHorsesUrl - ownerRequestsCountData.completedRequestsCount);


    showEntryClLoading.current = false;
  }, [showRequestData, showEntryOwnerHorsesData]);

  useEffect(() => {
    let counthorses = showEntryOwnerHorsesData.filter((item) => {
      if (showEntryHorseCard === "") {
        return item;
      } else if (showEntryHorseCard !== "") {
        return (
          item.name.toLowerCase().includes(showEntryHorseCard.toLowerCase()) ||
          item.registerNumber.includes(showEntryHorseCard)
        );
      }
    });

    setCardsRefCount(counthorses.length);
  }, [showEntryHorseCard]);

  const filterShowEntryHorseCard = async (element) => {
    let params = {
      createdBy: localStorage.getItem("EAHSUserID"),
      ownerId: element.value,
      // ownerId:
      //   profile.value == null
      //     ? localStorage.getItem("EAHSUserID")
      //     : profile.value,
      showId: showId,
      //searchValue: showEntryHorseCard,
      searchValue: null,
      langauge: currentLanguage,
    };
    dispatch(FetchShowEntryOwnerHorsesthunk(params));
  };

  const handelCreateShowEntry = (isDraft) => {
    draftState.current = isDraft;



    if (!isDraft) {
      let showParticipantsHorsesRegList = showEntryCards
        .filter(
          (item) =>
            item.isRegisterd &&
            (item.isRequiredConfirmation == null ||
              item.isRequiredConfirmation == false)
        )
        .map((item) => {
          return {
            horseId: item.id,
            partictedOwnerId: null,
            isRequiredConfirmation: null,
          };
        });

      let showParticipantsHorsesUnRegList = showEntryCards
        .filter(
          (item) =>
            !item.isRegisterd ||
            (item.isRegisterd && item.isRequiredConfirmation == true)
        )
        .map((item) => {
          return {
            horseId: item.id,
            partictedOwnerId: null,
            isRequiredConfirmation: item.isRequiredConfirmation,
          };
        });

      let paramsReg = {
        showId,
        ownerId:
          userProfile.accountType != "Agent"
            ? localStorage.getItem("EAHSUserID")
            : localStorage.getItem("currentSubAccountId"),
        isDraft: false,
        showParticipantsHorsesList: showParticipantsHorsesRegList,
      };

      let paramsUnReg = {
        showId,
        ownerId:
          userProfile.accountType != "Agent"
            ? localStorage.getItem("EAHSUserID")
            : localStorage.getItem("currentSubAccountId"),
        isDraft: true,
        showParticipantsHorsesList: showParticipantsHorsesUnRegList,
      };
      if (showParticipantsHorsesUnRegList.length > 0) {
        dispatch(FetchCreateShowEntrythunk(paramsUnReg));
      }
      if (showParticipantsHorsesRegList.length > 0) {
        dispatch(FetchCreateShowEntryRegisteredthunk(paramsReg));
      }
    } else {
      let showParticipantsHorsesList = showEntryCards.map((item) => {
        return {
          horseId: item.id,
          partictedOwnerId: null,
          isRequiredConfirmation: item.isRequiredConfirmation,
        };
      });
      let params = {
        showId,
        ownerId:
          userProfile.accountType != "Agent"
            ? localStorage.getItem("EAHSUserID")
            : localStorage.getItem("currentSubAccountId"),
        isDraft: true,
        showParticipantsHorsesList: showParticipantsHorsesList,
      };
      dispatch(FetchCreateShowEntrythunk(params));
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("addRegisterHorseId", null);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    if (
      getHorseQualificationByHorseIdData &&
      Object.keys(getHorseQualificationByHorseIdData).length > 0
    ) {
      setHorseQualification({
        ...horseQualification,
        countryQual: getHorseQualificationByHorseIdData.countryId,
        countryQualDrop:
          getHorseQualificationByHorseIdData.countryId == null
            ? {
              label: translate("web.countryName", "Country Name"),
              value: null,
            }
            : {
              label: countriesData.find(
                (item) =>
                  item.value === getHorseQualificationByHorseIdData.countryId
              )?.text,
              value: getHorseQualificationByHorseIdData.countryId,
            },
        showName: getHorseQualificationByHorseIdData.showName,
        year: new Date(getHorseQualificationByHorseIdData.year).toISOString(),
        className: getHorseQualificationByHorseIdData.className,
        classRank: getHorseQualificationByHorseIdData.classRank,
        websiteURL: getHorseQualificationByHorseIdData.website,
      });

      setYeardate(dayjs(getHorseQualificationByHorseIdData.year));
    }
  }, [getHorseQualificationByHorseIdData]);

  useEffect(() => {
    if (
      (toasterMessageShowEntry != null && toasterMessageShowEntry === "save") ||
      toasterMessageShowEntry === "saveDraft"
    ) {
      if (toasterMessageShowEntry === "save") {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      } else if (toasterMessageShowEntry === "saveDraft") {
        toast.warn(
          translate(
            "web.dataSavedAsDraftYouHaveNotParticipatedYetClickOnContinueToParticipateNow",
            "Data Saved As Draft You Have not Participated Yet - Click On Continue To Participate Now"
          )
        );
      }

      dispatch(resetToasterMessageShowEntry());
    }
    else if (toasterMessageShowEntry == null && navigateStatus) {
      if (horseQualificationState.current) {
        horseQualificationState.current = false;
        handelResetHorseQualification();
      } else {
        dispatch(resetNavigateStatus());
        dispatch(handelResetShowEntryTempData());


        if (
          ((!isDraftRegistered &&
            showEntryCards.filter((obj) => obj.isRegisterd).length > 0) ||
            (isDraftRegistered &&
              showEntryCards.filter((obj) => obj.isRegisterd).length === 0) ||
            showEntryCards.filter(
              (obj) => obj.isRegisterd && obj.isRequiredConfirmation == true
            ).length > 0) &&
          ((!isDraftUnRegistered &&
            showEntryCards.filter((obj) => !obj.isRegisterd).length === 0) ||
            (isDraftUnRegistered &&
              showEntryCards.filter((obj) => !obj.isRegisterd).length > 0) ||
            (isDraftUnRegistered &&
              showEntryCards.filter(
                (obj) => obj.isRegisterd && obj.isRequiredConfirmation == true
              ).length > 0)) &&
          draftState.current === false
        ) {

          dispatch(resetIsDraftStatus());
          setTimeout(() => {
            navigate(
              `/showentrytotalregisteredhorses?maxHorses=${maxHorsesUrl}&showNameAr=${showNameAr}&showNameEn=${showNameEn}&showId=${showId}&entryFees=${entryFees}`
            );
          }, 1500);
        } else if (draftState.current === true) {
          dispatch(resetIsDraftStatus());
          handelFetchShowEntryOwnerHorsesAndRequests();
        }
      }
    }
  }, [toasterMessageShowEntry]);

  const dismissAll = () => toast.dismiss();

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${showEntryLoading || showEntryClLoading.current === true
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      <div className="row  rtl">
        {
          // available horses div
        }
        <div className="col-md-3 col-sm-12   overflowauto">
          {
            // form
          }
          <div className="col-md-12 col-sm-12 p0">
            {subProfiles.length > 0 &&
              userProfile.accountType != "Agent" && (
                <React.Fragment>
                  <label className="capatalize_text">
                    {translate("web.selectedAccount", "Selected Account")}
                  </label>
                  <Select
                    className="mt-10 zindex200"
                    options={[
                      {
                        label: userProfile.ownerRegisterNo + " - " + userProfile[`fullName${currentLanguage == 'en' ? '' : 'Ar'}`],
                        value: localStorage.getItem("EAHSUserID"),
                      },
                      ...(!!subProfiles &&
                        !!subProfiles.length > 0 &&
                        subProfiles.map((a) => ({
                          label: a.registerNo + " - " + a[`text${currentLanguage == 'en' ? '' : 'Ar'}`],
                          value: a.value,
                        }))),
                    ]}
                    value={profile}
                    onChange={(e) => {
                      filterShowEntryHorseCard(e);
                      setProfile(e);
                    }}
                    placeholder={localStorage.getItem("EAHSUserFullName")}
                    isSearchable
                    noOptionsMessage={() =>
                      translate("web.noOptions", "No Options")
                    }
                    styles={customStyles}
                  />
                </React.Fragment>
              )}
            {userProfile.accountType == "Agent" && (
              <>
                <h4 className="bold">
                  {localStorage.getItem("currentSubAccountLabel")}
                </h4>
              </>
            )}
            {CompareDate(registerStartDate, registerEndDate) &&
              <div
                // onSubmit={(e) => {
                //   e.preventDefault();
                //   filterShowEntryHorseCard();
                // }}
                className="input-group formshowentrycard mt-15 mr-0"
              >
                <i className="fas fa-search fa-xl iconformshowentrycard"></i>
                <input
                  type="text"
                  id="searchStudBook"
                  name="searchStudBook"
                  className="form-control height30hidden zindex0"
                  onChange={(e) => {
                    setShowEntryHorseCard(e.target.value);
                  }}
                  placeholder={translate(
                    "web.searchAvailableHorses",
                    "Search for Horses, By Name Or Reg Number"
                  )}
                />
              </div>}
            {
              //   <div className="col-md-2 col-sm-12 p0">
              //   <button
              //     type="button"
              //     className="btn buttonformshowentrycard linkbtn marginLeft_-5"
              //     onClick={filterShowEntryHorseCard}
              //   >
              //     {translate("web.search", "Search")}
              //   </button>
              // </div>
            }
          </div>
          {
            // card
          }
          {
            //title registered Horses
          }
          {!!showEntryOwnerHorsesData &&
            showEntryOwnerHorsesData.length > 0 &&
            CompareDate(registerStartDate, registerEndDate) &&
            showEntryOwnerHorsesData.some((obj) => obj.isRegisterd) && (
              <div className="col-md-12 col-sm-12  mt-15">
                <h4 className="fontWeight colorred">
                  {translate("web.registeredHorses", "Registered Horses")}
                </h4>
              </div>
            )}
          {
            //registered Horses cards
          }
          <div ref={showEntryCardsRef} className="mobileRow">
            {!!showEntryOwnerHorsesData &&
              showEntryOwnerHorsesData.length > 0 &&
              CompareDate(registerStartDate, registerEndDate) &&
              showEntryOwnerHorsesData.some((obj) => obj.isRegisterd) &&
              showEntryOwnerHorsesData
                .filter((item) => {
                  if (item.isRegisterd && showEntryHorseCard === "") {
                    return item;
                  } else if (item.isRegisterd && showEntryHorseCard !== "") {
                    return (
                      item.name
                        .toLowerCase()
                        .includes(showEntryHorseCard.toLowerCase()) ||
                      item.registerNumber.includes(showEntryHorseCard)
                    );
                  }
                })
                .map((showEntryOwnerHorsesItem, iShowEntryOwnerHorsesItem) => (
                  <div
                    divId={showEntryOwnerHorsesItem.id}
                    divTitle={showEntryOwnerHorsesItem.name}
                    divDate={showEntryOwnerHorsesItem.birthDate}
                    divCountry={showEntryOwnerHorsesItem.countryName}
                    divGender={showEntryOwnerHorsesItem.gender}
                    divRegNo={showEntryOwnerHorsesItem.registerNumber}
                    divIsRegisterd={"" + showEntryOwnerHorsesItem.isRegisterd}
                    divShowHorseStatus={
                      showEntryOwnerHorsesItem.showHorseStatus
                    }
                    key={iShowEntryOwnerHorsesItem}
                    style={{ minWidth: 300 }}
                    className={`col-lg-12 col-md-12 col-sm-12 horse-market-card showentrycard minHight150 ${showEntryOwnerHorsesItem.isQualified && "cursorPointer"
                      }`}
                    onClick={() => {
                      if (
                        ((maxHorsesUrl > 0 &&
                          showEntryCards.filter(
                            (item) =>
                              item.showHorseStatus === "" ||
                              item.showHorseStatus === "Draft"
                          ).length < maxHorses) ||
                          maxHorsesUrl == 0) &&
                        showEntryCards.findIndex(
                          (obj) => obj.id === showEntryOwnerHorsesItem.id
                        ) === -1 &&
                        ((showEntryOwnerHorsesItem.isQualified &&
                          (showEntryOwnerHorsesItem.showHorseStatus === "" ||
                            showEntryOwnerHorsesItem.showHorseStatus ===
                            "Draft")) ||
                          (!showEntryOwnerHorsesItem.isQualified &&
                            showEntryOwnerHorsesItem.notEligibleReason === ""))
                      ) {
                        if (
                          !showEntryOwnerHorsesItem.isQualified &&
                          showEntryOwnerHorsesItem.notEligibleReason === ""
                        ) {
                          setHorseQualification({
                            ...horseQualification,
                            horseId: showEntryOwnerHorsesItem.id,
                            horseName: showEntryOwnerHorsesItem.name,
                            regNo: showEntryOwnerHorsesItem.registerNumber,
                            birthDate: showEntryOwnerHorsesItem.birthDate,
                            countryName: showEntryOwnerHorsesItem.countryName,
                            gender: showEntryOwnerHorsesItem.gender,
                            showHorseStatus:
                              showEntryOwnerHorsesItem.showHorseStatus,
                            isRegistered: showEntryOwnerHorsesItem.isRegisterd,
                          });

                          dispatch(
                            FetchGetHorseQualificationByHorseIdthunk(
                              showEntryOwnerHorsesItem.id
                            )
                          );

                          setOpenPopup((o) => !o);
                        } else {
                          dispatch(
                            handelShowEntryTempData({
                              type: "add",
                              data: {
                                horseId: showEntryOwnerHorsesItem.id,
                                horseName: showEntryOwnerHorsesItem.name,
                                horseBirthDate:
                                  showEntryOwnerHorsesItem.birthDate,
                                horseOrigin:
                                  showEntryOwnerHorsesItem.countryName,
                                horseGender: showEntryOwnerHorsesItem.gender,
                                horseRegisterNo:
                                  showEntryOwnerHorsesItem.registerNumber,
                                amount: entryFees,
                                statusName:
                                  showEntryOwnerHorsesItem.showHorseStatus,
                                notes: "",
                                isRequiredConfirmation: null,
                              },
                            })
                          );

                          setShowEntryCards((prevState) => [
                            ...prevState,
                            {
                              id: showEntryOwnerHorsesItem.id,
                              title: showEntryOwnerHorsesItem.name,
                              date: showEntryOwnerHorsesItem.birthDate,
                              country: showEntryOwnerHorsesItem.countryName,
                              gender: showEntryOwnerHorsesItem.gender,
                              regNo: showEntryOwnerHorsesItem.registerNumber,
                              price: entryFees,
                              showHorseStatus:
                                showEntryOwnerHorsesItem.showHorseStatus,
                              isRegisterd: showEntryOwnerHorsesItem.isRegisterd,
                              isRequiredConfirmation: null,
                            },
                          ]);

                          for (const e of showEntryCardsRef.current.children) {
                            if (
                              e.getElementsByTagName("i")[0]?.tagName === "I" &&
                              e.getElementsByTagName("i")[0].id ===
                              showEntryOwnerHorsesItem.id
                            ) {
                              e.getElementsByTagName("i")[0].className = "";
                              return;
                            }
                          }
                        }
                      }
                    }}
                  >
                    <div
                      className={`horseCardContainer ${!showEntryOwnerHorsesItem.isQualified &&
                        "isNotQualifiedBackground"
                        }`}
                    >
                      <div className="col-md-12 titletrashshowentrycard">
                        <span className="horseCardLabeltitle text-transform-none">
                          {`${showEntryOwnerHorsesItem.registerNumber} - ${showEntryOwnerHorsesItem.name}`}
                        </span>
                        {
                          //showEntryOwnerHorsesItem.isQualified &&
                          //   showEntryOwnerHorsesItem.showHorseStatus === "" && (
                          //     <i
                          //       className="fa fa-plus plusIcon fa-lg line-height"
                          //       id={showEntryOwnerHorsesItem.id}
                          //     ></i>
                          //   )
                        }

                        {
                          <i
                            className={
                              (showEntryOwnerHorsesItem.isQualified &&
                                showEntryOwnerHorsesItem.showHorseStatus ===
                                "") ||
                                (!showEntryOwnerHorsesItem.isQualified &&
                                  showEntryOwnerHorsesItem.notEligibleReason ===
                                  "")
                                ? "fa fa-plus plusIcon fa-lg line-height"
                                : ""
                            }
                            id={showEntryOwnerHorsesItem.id}
                          ></i>
                        }
                      </div>

                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <span className="horseCardLabeltitle ">
                            {showEntryOwnerHorsesItem.birthDate &&
                              formatDate(showEntryOwnerHorsesItem.birthDate)}
                            {" - "}
                            {`${translate(
                              "web." +
                              showEntryOwnerHorsesItem.gender.toLowerCase(),
                              showEntryOwnerHorsesItem.gender
                            )}`}
                          </span>
                        </div>
                      </div>
                      <span className="horseCardLabeltitle"></span>

                      <div className="col-md-12 titletrashshowentrycard">
                        {!showEntryOwnerHorsesItem.isQualified && (
                          <span className="colorred horseCardLabeltitle textalignend mt-1">
                            {translate("web.notEligible", "Not eligible")}
                          </span>
                        )}
                        {!showEntryOwnerHorsesItem.isQualified &&
                          showEntryOwnerHorsesItem.notEligibleReason === "" && (
                            <i
                              className="fa-solid fa-ban fa-lg line-height cursorPointer"
                              isNotQualified={showEntryOwnerHorsesItem.id}
                              id={`isNotQualified-${showEntryOwnerHorsesItem.id}`}
                            ></i>
                          )}
                      </div>
                      <div className="col-md-12 titletrashshowentrycard">
                        {!showEntryOwnerHorsesItem.isQualified && (
                          <span className="horseCardLabeltitle  colorred">
                            {translate(`web.Reason${convertToCamelCase(showEntryOwnerHorsesItem.notEligibleReason)}`, convertToCamelCase(showEntryOwnerHorsesItem.notEligibleReason))}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
          </div>

          {
            //pop up
          }
          <Popup
            modal
            lockScroll
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            className="popCustomOverLay "
            contentStyle={{
              width: "100%",
              maxWidth: 1000,
            }}
          >
            {(close) => (
              <React.Fragment>
                <h4 className="bold p0 ">
                  {translate("web.horsequalification", "Horse Qualification")}
                </h4>
                <h4 className="bold p0">
                  {currentLanguage === "en" ? showNameEn : showNameAr}
                </h4>
                <h4 className="bold p0">
                  {horseQualification.regNo +
                    " - " +
                    horseQualification.horseName}
                </h4>
                <div className="divModalAuctionEntry overflowYSCroll mt-10">
                  {
                    //Horse qualifications
                  }
                  <div className="col-md-12 col-sm-12 padding-5 bordercategorycard m0 row rtl  ">
                    <div className="col-md-6 col-sm-12  mb-10">
                      <label>
                        {translate("web.countryName", "Country Name")}
                        <i className="colorred">*</i>
                      </label>
                      <Select
                        options={[
                          {
                            label: translate("web.countryName", "Country Name"),
                            value: null,
                          },
                          ...(!!countriesData &&
                            countriesData.map((a) => ({
                              label: a.text,
                              value: a.value,
                            }))),
                        ]}
                        value={horseQualification.countryQualDrop}
                        onChange={(e) =>
                          handleHorseQualificationChange(e, "countryQual")
                        }
                        placeholder={translate(
                          "web.countryName",
                          "Country Name"
                        )}
                        name={"countryQual"}
                        isSearchable
                        noOptionsMessage={() =>
                          translate("web.noOptions", "No Options")
                        }
                      />
                    </div>
                    <div className="col-md-3 col-sm-12 ">
                      <NetaqInput
                        label={translate("web.showName", "Show Name")}
                        name={"showName"}
                        placeholder={translate("web.showName", "Show Name")}
                        type={"text"}
                        onChange={handleHorseQualificationChange}
                        value={horseQualification.showName}
                        required={true}
                      />
                    </div>
                    <div className="col-md-3 col-sm-12 ">
                      <NetaqInput
                        name={"year"}
                        type={"date"}
                        label={translate("web.year", "Year")}
                        onChange={(e) =>
                          handleHorseQualificationChange(e, "year")
                        }
                        value={yearDate}
                        required={true}
                        maxDate={dayjs()}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate("web.className", "Class Name")}
                        name={"className"}
                        placeholder={translate("web.className", "Class Name")}
                        type={"text"}
                        onChange={handleHorseQualificationChange}
                        value={horseQualification.className}
                        required={true}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 ">
                      <label>
                        {translate("web.classRank", "Class Rank")}
                        <i className="colorred">*</i>
                      </label>
                      <input
                        className="form-control"
                        name="classRank"
                        type="number"
                        min="1"
                        step="1"
                        onChange={handleHorseQualificationChange}
                        value={horseQualification.classRank}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12 ">
                      <NetaqInput
                        label={translate(
                          "web.referenceWebsiteURL",
                          "Reference Website URL"
                        )}
                        name={"websiteURL"}
                        placeholder={translate("web.websiteURL", "Website URL")}
                        type={"text"}
                        onChange={handleHorseQualificationChange}
                        value={horseQualification.websiteURL}
                      />
                    </div>
                  </div>
                </div>
                <div className="row rtl justify-content-between m0 mt-2">
                  <div className="col-md-3 col-sm-12 mt-1">
                    <div
                      className="bidSubmit divHorseSelectClose  height-fitcontent"
                      onClick={() => {
                        handelResetHorseQualification();
                      }}
                    >
                      {translate("web.close", "Close")}
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12 mt-1">
                    <div className=" rtl">
                      <button
                        type="button"
                        className="btn btn-danger btn-lg"
                        onClick={() => {
                          handelHorseQualificationSubmit();

                          for (const e of showEntryCardsRef.current.children) {
                            if (
                              e.getElementsByTagName("i")[0]?.tagName === "I" &&
                              e.getElementsByTagName("i")[0].id ===
                              horseQualification.horseId
                            ) {
                              e.getElementsByTagName("i")[0].className = "";
                              return;
                            }
                          }
                        }}
                      >
                        <i className="fa fa-plus"></i>
                        {translate("web.addTheHorse", "Add The Horse")}
                      </button>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Popup>

          {
            //title Unregistered Horses
          }
          {!!showEntryOwnerHorsesData &&
            showEntryOwnerHorsesData.length > 0 &&
            CompareDate(registerStartDateInter, registerEndDateInter) &&
            showEntryOwnerHorsesData.some((obj) => !obj.isRegisterd) && (
              <div className="col-md-12 col-sm-12 ">
                <h4 className="fontWeight colorred">
                  {translate("web.internationalHorse", "International Horses")}
                </h4>
              </div>
            )}

          {
            //Unregistered Horses cards
          }
          <div ref={showEntryCardsUnregisteredRef} className="mobileRow">
            {!!showEntryOwnerHorsesData &&
              showEntryOwnerHorsesData.length > 0 &&
              CompareDate(registerStartDateInter, registerEndDateInter) &&
              showEntryOwnerHorsesData.some((obj) => !obj.isRegisterd) &&
              showEntryOwnerHorsesData
                .filter((item) => {
                  if (!item.isRegisterd && showEntryHorseCard === "") {
                    return item;
                  } else if (!item.isRegisterd && showEntryHorseCard !== "") {

                    return (
                      item.name
                        .toLowerCase()
                        .includes(showEntryHorseCard.toLowerCase()) ||
                      item.registerNumber.includes(showEntryHorseCard)
                    );
                  }
                })
                .map((showEntryOwnerHorsesItem, iShowEntryOwnerHorsesItem) => (
                  <div
                    divId={showEntryOwnerHorsesItem.id}
                    divTitle={showEntryOwnerHorsesItem.name}
                    divDate={showEntryOwnerHorsesItem.birthDate}
                    divCountry={showEntryOwnerHorsesItem.countryName}
                    divGender={showEntryOwnerHorsesItem.gender}
                    divRegNo={showEntryOwnerHorsesItem.registerNumber}
                    divShowHorseStatus={
                      showEntryOwnerHorsesItem.showHorseStatus
                    }
                    divIsRegisterd={"" + showEntryOwnerHorsesItem.isRegisterd}
                    key={iShowEntryOwnerHorsesItem}
                    style={{ minWidth: 300 }}
                    className={`col-lg-12 col-md-12 col-sm-12 horse-market-card showentrycard minHight150 `}
                  >
                    <div
                      className={`horseCardContainer ${!showEntryOwnerHorsesItem.isQualified &&
                        "isNotQualifiedBackground"
                        }`}
                    >
                      <div
                        className={`col-md-12 titletrashshowentrycard ${showEntryOwnerHorsesItem.isQualified &&
                          "cursorPointer"
                          }`}
                        onClick={() => {
                          if (
                            ((maxHorsesUrl > 0 &&
                              showEntryCards.filter(
                                (item) =>
                                  item.showHorseStatus === "" ||
                                  item.showHorseStatus === "Draft"
                              ).length < maxHorses) ||
                              maxHorsesUrl == 0) &&
                            showEntryCards.findIndex(
                              (obj) => obj.id === showEntryOwnerHorsesItem.id
                            ) === -1 &&
                            //showEntryOwnerHorsesItem.isQualified &&
                            (showEntryOwnerHorsesItem.showHorseStatus === "" ||
                              showEntryOwnerHorsesItem.showHorseStatus ===
                              "Returned" ||
                              showEntryOwnerHorsesItem.showHorseStatus ===
                              "Draft")
                          ) {
                            navigate(
                              `/addRegisterHorse?maxHorses=${maxHorsesUrl}&showNameAr=${showNameAr}&showNameEn=${showNameEn}&entryFees=${entryFees}&showId=${showId}&unregisterHorseId=${showEntryOwnerHorsesItem.id}&registerStartDate=${registerStartDate}&registerEndDate=${registerEndDate}&registerStartDateInter=${registerStartDateInter}&registerEndDateInter=${registerEndDateInter}`
                            );
                            //     dispatch(
                            //       handelShowEntryTempData({
                            //         type: "add",
                            //         data: {
                            //           horseId: showEntryOwnerHorsesItem.id,
                            //           horseName: showEntryOwnerHorsesItem.name,
                            //           horseBirthDate:
                            //             showEntryOwnerHorsesItem.birthDate,
                            //           horseOrigin:
                            //             showEntryOwnerHorsesItem.countryName,
                            //           horseGender: showEntryOwnerHorsesItem.gender,
                            //           horseRegisterNo:
                            //             showEntryOwnerHorsesItem.registerNumber,
                            //           amount: entryFees,
                            //           statusName:
                            //             showEntryOwnerHorsesItem.showHorseStatus,
                            //           notes: showEntryOwnerHorsesItem.notes,
                            //         },
                            //       })
                            //     );

                            //     setShowEntryCards((prevState) => [
                            //       ...prevState,
                            //       {
                            //         id: showEntryOwnerHorsesItem.id,
                            //         title: showEntryOwnerHorsesItem.name,
                            //         date: showEntryOwnerHorsesItem.birthDate,
                            //         country: showEntryOwnerHorsesItem.countryName,
                            //         gender: showEntryOwnerHorsesItem.gender,
                            //         regNo: showEntryOwnerHorsesItem.registerNumber,
                            //         price: entryFees,
                            //         showHorseStatus:
                            //           showEntryOwnerHorsesItem.showHorseStatus,
                            //         notes: showEntryOwnerHorsesItem.notes,
                            //         isRegisterd:
                            //           showEntryOwnerHorsesItem.isRegisterd,
                            //       },
                            //     ]);

                            //     for (const e of showEntryCardsUnregisteredRef
                            //       .current.children) {
                            //       if (
                            //         e.getElementsByTagName("i")[0]?.tagName ===
                            //           "I" &&
                            //         e.getElementsByTagName("i")[0].id ===
                            //           showEntryOwnerHorsesItem.id
                            //       ) {
                            //         e.getElementsByTagName("i")[0].className = "";
                            //         return;
                            //       }
                            //     }
                          }
                        }}
                      >
                        <span className="horseCardLabeltitle text-transform-none">
                          {showEntryOwnerHorsesItem.name}
                        </span>
                        {(showEntryOwnerHorsesItem.showHorseStatus === "" ||
                          showEntryOwnerHorsesItem.showHorseStatus ===
                          "Returned" ||
                          showEntryOwnerHorsesItem.showHorseStatus ===
                          "Draft") && (
                            <i
                              className={`fa fa-plus plusIcon fa-lg line-height ${showEntryOwnerHorsesItem.showHorseStatus ===
                                "Returned" && "colorred"
                                }`}
                              id={showEntryOwnerHorsesItem.id}
                            ></i>
                          )}
                      </div>

                      <div className="row">
                        <div className="col-md-12 mb-5">
                          <span className="horseCardLabeltitle">
                            {showEntryOwnerHorsesItem.birthDate != null &&
                              showEntryOwnerHorsesItem.birthDate !== "" ? (
                              showEntryOwnerHorsesItem.birthDate &&
                              formatDate(showEntryOwnerHorsesItem.birthDate) +
                              " - " +
                              translate(
                                "web." +
                                showEntryOwnerHorsesItem.gender.toLowerCase(),
                                showEntryOwnerHorsesItem.gender
                              )
                            ) : (
                              <React.Fragment>
                                {translate(
                                  "web." +
                                  showEntryOwnerHorsesItem.gender.toLowerCase(),
                                  showEntryOwnerHorsesItem.gender
                                )}
                              </React.Fragment>
                            )}
                          </span>
                          <span className="horseCardLabeltitle">
                            {showEntryOwnerHorsesItem.countryName != null &&
                              showEntryOwnerHorsesItem.countryName !== "" &&
                              showEntryOwnerHorsesItem.countryName}
                          </span>
                        </div>
                      </div>
                      <span className="horseCardLabeltitle"></span>

                      <div
                        id={`edit-${showEntryOwnerHorsesItem.id}`}
                        className="col-md-12 titletrashshowentrycard classnotdisplayImp"
                      >
                        {(showEntryOwnerHorsesItem.showHorseStatus ===
                          "Draft" ||
                          showEntryOwnerHorsesItem.showHorseStatus ===
                          "Returned" ||
                          showEntryOwnerHorsesItem.showHorseStatus === "") && (
                            <Link
                              className="horseMarketCardLabeltitle rtlabs l0 cursorPointer edit-unregisterhorsebutton"
                              to={`/addRegisterHorse?maxHorses=${maxHorsesUrl}&showNameAr=${showNameAr}&showNameEn=${showNameEn}&entryFees=${entryFees}&showId=${showId}&unregisterHorseId=${showEntryOwnerHorsesItem.id}&registerStartDate=${registerStartDate}&registerEndDate=${registerEndDate}&registerStartDateInter=${registerStartDateInter}&registerEndDateInter=${registerEndDateInter}`}
                            >
                              <img
                                alt="editicon"
                                src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1lZGl0IiBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMSA0SDRhMiAyIDAgMCAwLTIgMnYxNGEyIDIgMCAwIDAgMiAyaDE0YTIgMiAwIDAgMCAyLTJ2LTciLz48cGF0aCBkPSJNMTguNSAyLjVhMi4xMjEgMi4xMjEgMCAwIDEgMyAzTDEyIDE1bC00IDEgMS00IDkuNS05LjV6Ii8+PC9zdmc+"
                              />
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        {
          //content
        }
        <div className="col-md-9 col-sm-12 graybackgroundcolor minheight700 paddingonMobile">
          {
            //show Name
          }
          <h4 className="fontWeigh  addhorseHere">
            {currentLanguage === "en" ? showNameEn : showNameAr}
            {" - "} {translate("web.showFees", "Show Fees")} {"("} {entryFees}{" "}
            {currentLanguage === "en" ? "AED" : "د.إ"} {")"}
          </h4>
          {
            //You can participate by
          }
          <h4 className="graycolor  addhorseHere">
            {/* {`${translate("web.selectedHorses", `Selected Horses`)} (${showEntryCards.filter(
              (item) =>
                item.showHorseStatus === "" ||
                item.showHorseStatus === "Draft"
            ).length
              })`} {' - '}  */}
            {maxHorsesUrl > 0 &&
              `${translate(
                "web.yourCanParticipateBy",
                `Your can participate by`
              )} ${maxHorsesUrl} ${translate("web.horsesmax", `horses max`)} (${showEntryCards.filter(
                (item) =>
                  item.showHorseStatus === "" ||
                  item.showHorseStatus === "Draft" ||
                  item.showHorseStatus === "PendingPayment"
              ).length + showEntryCompletedCards.length
              }/${maxHorsesUrl})`}
          </h4>
          {
            //Selected Horses
          }
          <h4 className="graycolor  addhorseHere"></h4>
          {
            //Add International Horse
          }
          <div className="col-lg-12 col-md-12 col-sm-12 row justify-content-end m0 p0 ">
            {((!!maxHorsesUrl &&
              maxHorsesUrl > 0 &&
              showEntryCards.filter(
                (item) =>
                  item.showHorseStatus === "" ||
                  item.showHorseStatus === "Draft"
              ).length < maxHorses) ||
              maxHorsesUrl == 0) &&
              CompareDate(registerStartDateInter, registerEndDateInter) && (
                <div className="col-lg-4 col-md-4 col-sm-6 text-end">
                  <Link
                    type="button"
                    className="btn buttonformshowentrycard height46 linkbtn"
                    to={`/addRegisterHorse?maxHorses=${maxHorses}&showNameAr=${showNameAr}&showNameEn=${showNameEn}&entryFees=${entryFees}&showId=${showId}&registerStartDate=${registerStartDate}&registerEndDate=${registerEndDate}&registerStartDateInter=${registerStartDateInter}&registerEndDateInter=${registerEndDateInter}&showStartDate=${showStartDate}`}
                  >
                    <i className="fa fa-plus"></i>
                    <span className="ml-10">
                      {translate(
                        "web.addInternationalHorse",
                        "Add International Horse"
                      )}
                    </span>
                  </Link>
                </div>
              )}

            {/* {showEntryCards.length === 0 && cardsRefCount > 0 && (
              <h4 className="graycolor">
                {currentLanguage === "en"
                  ? translate(
                    "web.selectHorses",
                    "Select horses from the available horses on the left side"
                  )
                  : translate(
                    "web.selectHorsesRight",
                    "Select horses from the available horses on the reight side"
                  )}
              </h4>
            )} */}

            {maxHorsesUrl > 0 &&
              showEntryCards.filter(
                (item) =>
                  item.showHorseStatus === "" ||
                  item.showHorseStatus === "Draft" ||
                  item.showHorseStatus === "PendingPayment"
              ).length + showEntryCompletedCards.length == maxHorsesUrl && (
                <h4 className="colorred">
                  {translate(
                    "web.reachedMaximumNumberHorses",
                    "you’ve reached the maximum number of horses"
                  )}
                </h4>
              )}
          </div>
          {
            // tabs
          }
          <div className="col-lg-12 col-md-12 col-sm-12  mt-10">
            <Tabs>
              <TabList>
                {showEntryCards.some(
                  (obj) =>
                    obj.showHorseStatus === "" ||
                    obj.showHorseStatus === "Draft"
                ) && <Tab>{translate("web.newDraft", "New/Draft")}</Tab>}

                {showEntryCards.some(
                  (obj) => obj.showHorseStatus === "PendingPayment"
                ) && (
                    <Tab>
                      {translate("web.pendingpayment", "Pending Payment")}
                    </Tab>
                  )}
                {showEntryCards.some(
                  (obj) => obj.showHorseStatus === "Returned"
                ) && <Tab>{translate("web.returned", "Returned")}</Tab>}
                {showEntryCompletedCards.some(
                  (obj) => obj.showHorseStatus === "Completed"
                ) && <Tab>{translate("web.completed", "Completed")}</Tab>}
                {showEntryCompletedCards.some(
                  (obj) => obj.showHorseStatus === "PendingConfirmation"
                ) && (
                    <Tab>
                      {translate(
                        "web.pendingConfirmation",
                        "Pending Confirmation"
                      )}
                    </Tab>
                  )}
                {showEntryCards.some(
                  (obj) => obj.showHorseStatus === "WaitingList"
                ) && <Tab>{translate("web.waitingList", "Waiting List")}</Tab>}
              </TabList>

              {
                // new/draft horses Cards
              }
              {showEntryCards.some(
                (obj) =>
                  obj.showHorseStatus === "" || obj.showHorseStatus === "Draft"
              ) && (
                  <TabPanel className={" row m0 rtl"}>
                    {showEntryCards
                      .filter(
                        (item) =>
                          item.showHorseStatus === "" ||
                          item.showHorseStatus === "Draft"
                      )
                      .map((item, i) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                          key={i}
                          style={{ minHeight: 125 }}
                        >
                          <div
                            className="horseCardContainer"
                            style={{ minHeight: 125 }}
                          >
                            <div className="col-md-12 titletrashshowentrycard">
                              <span className="horseCardLabeltitle  text-transform-none">
                                {item.regNo !== "" && item.isRegisterd
                                  ? item.regNo + " - " + item.title
                                  : item.title}
                              </span>

                              {(item.showHorseStatus === "" ||
                                item.showHorseStatus === "Draft") && (
                                  <i
                                    className="fa fa-trash plusIcon cursorPointer remove"
                                    onClick={() => {
                                      dispatch(
                                        handelShowEntryTempData({
                                          type: "remove",
                                          data: item.id,
                                        })
                                      );

                                      setShowEntryCards((oldValues) => {
                                        return oldValues.filter(
                                          (oldItem) => oldItem.id !== item.id
                                        );
                                      });

                                      for (const e of showEntryCardsRef.current
                                        .children) {
                                        if (
                                          e.getElementsByTagName("i")[0]
                                            ?.tagName === "I" &&
                                          e.getElementsByTagName("i")[0].id ===
                                          item.id
                                        ) {
                                          e.getElementsByTagName("i")[0].className =
                                            "fa fa-plus plusIcon fa-lg line-height";
                                          return;
                                        }
                                      }

                                      for (const e of showEntryCardsUnregisteredRef
                                        .current.children) {
                                        if (
                                          e.getElementsByTagName("i")[0]
                                            ?.tagName === "I" &&
                                          e.getElementsByTagName("i")[0].id ===
                                          item.id
                                        ) {
                                          // e.getElementsByTagName("i")[0].className =
                                          //   "fa fa-plus plusIcon fa-lg line-height";
                                          const x = document.getElementById(
                                            item.id
                                          );
                                          if (!!x) {
                                            x.style = "display:block;";
                                          }
                                        }

                                        if (
                                          e.getElementsByTagName("i")[0]
                                            ?.tagName === "I" &&
                                          e.getElementsByTagName("i")[0].id ===
                                          item.id &&
                                          item.showHorseStatus === "Returned"
                                        ) {
                                          // e.getElementsByTagName("i")[0].className =
                                          //   "fa fa-plus plusIcon fa-lg line-height colorred";
                                          const x = document.getElementById(
                                            item.id
                                          );
                                          if (!!x) {
                                            x.style = "display:block;";
                                          }
                                          // document.getElementById(item.id).style =
                                          //   "display:block;";
                                          return;
                                        }

                                        if (
                                          e
                                            .getElementsByTagName("div")[0]
                                            ?.getElementsByTagName("div")[3]
                                            ?.tagName === "DIV" &&
                                          e
                                            .getElementsByTagName("div")[0]
                                            ?.getElementsByTagName("div")[3]?.id ===
                                          `edit-${item.id}`
                                        ) {
                                          e
                                            .getElementsByTagName("div")[0]
                                            .getElementsByTagName(
                                              "div"
                                            )[3].className = `col-md-12 titletrashshowentrycard classnotdisplayImp`;
                                        }
                                      }
                                    }}
                                  ></i>
                                )}
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-5">
                                <span className="horseCardLabeltitle ">
                                  {item.date != null && item.date !== ""
                                    ? item.date && formatDate(item.date)
                                    : ""}
                                  {" - "}
                                  {`${translate(
                                    "web." + item.gender.toLowerCase(),
                                    item.gender
                                  )}`}
                                </span>
                                <span className="horseCardLabeltitle">
                                  {item.country != null &&
                                    item.country !== "" &&
                                    !item.isRegisterd
                                    ? item.country
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </TabPanel>
                )}
              {
                // pending payment horses Cards
              }
              {showEntryCards.some(
                (obj) => obj.showHorseStatus === "PendingPayment"
              ) && (
                  <TabPanel className={" row m0 rtl"}>
                    {showEntryCards
                      .filter((item) => item.showHorseStatus === "PendingPayment")
                      .map((item, i) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                          key={i}
                          style={{ minHeight: 125 }}
                        >
                          <div
                            className="horseCardContainer"
                            style={{ minHeight: 125 }}
                          >
                            <div className="col-md-12 titletrashshowentrycard">
                              <span className="horseCardLabeltitle  text-transform-none">
                                {item.regNo !== "" && item.isRegisterd
                                  ? item.regNo + " - " + item.title
                                  : item.title}
                              </span>

                              {item.showHorseStatus === "PendingPayment" && CompareDate(registerStartDate, registerEndDate) && (
                                <i
                                  className="fa fa-trash plusIcon cursorPointer remove"
                                  onClick={() => {
                                    dispatch(
                                      handelShowEntryTempData({
                                        type: "remove",
                                        data: item.id,
                                      })
                                    );

                                    setShowEntryCards((oldValues) => {
                                      return oldValues.filter(
                                        (oldItem) => oldItem.id !== item.id
                                      );
                                    });

                                    for (const e of showEntryCardsRef.current
                                      .children) {
                                      if (
                                        e.getElementsByTagName("i")[0]
                                          ?.tagName === "I" &&
                                        e.getElementsByTagName("i")[0].id ===
                                        item.id
                                      ) {
                                        e.getElementsByTagName("i")[0].className =
                                          "fa fa-plus plusIcon fa-lg line-height";
                                        return;
                                      }
                                    }

                                    for (const e of showEntryCardsUnregisteredRef
                                      .current.children) {
                                      if (
                                        e.getElementsByTagName("i")[0]
                                          ?.tagName === "I" &&
                                        e.getElementsByTagName("i")[0].id ===
                                        item.id
                                      ) {
                                        // e.getElementsByTagName("i")[0].className =
                                        //   "fa fa-plus plusIcon fa-lg line-height";
                                        const x = document.getElementById(
                                          item.id
                                        );
                                        if (!!x) {
                                          x.style = "display:block;";
                                        }
                                        // document.getElementById(item.id).style =
                                        //   "display:block;";
                                      }

                                      if (
                                        e.getElementsByTagName("i")[0]
                                          ?.tagName === "I" &&
                                        e.getElementsByTagName("i")[0].id ===
                                        item.id &&
                                        item.showHorseStatus === "Returned"
                                      ) {
                                        // e.getElementsByTagName("i")[0].className =
                                        //   "fa fa-plus plusIcon fa-lg line-height colorred";
                                        const x = document.getElementById(
                                          item.id
                                        );
                                        if (!!x) {
                                          x.style = "display:block;";
                                        }
                                        // document.getElementById(item.id).style =
                                        //   "display:block;";
                                        return;
                                      }

                                      if (
                                        e
                                          .getElementsByTagName("div")[0]
                                          ?.getElementsByTagName("div")[3]
                                          ?.tagName === "DIV" &&
                                        e
                                          .getElementsByTagName("div")[0]
                                          ?.getElementsByTagName("div")[3]?.id ===
                                        `edit-${item.id}`
                                      ) {
                                        e
                                          .getElementsByTagName("div")[0]
                                          .getElementsByTagName(
                                            "div"
                                          )[3].className = `col-md-12 titletrashshowentrycard classnotdisplayImp`;
                                      }
                                    }
                                  }}
                                ></i>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-5">
                                <span className="horseCardLabeltitle ">
                                  {item.date != null && item.date !== ""
                                    ? item.date && formatDate(item.date)
                                    : ""}
                                  {" - "}
                                  {`${translate(
                                    "web." + item.gender.toLowerCase(),
                                    item.gender
                                  )}`}
                                </span>
                                <span className="horseCardLabeltitle ">
                                  {item.country != null &&
                                    item.country !== "" &&
                                    !item.isRegisterd
                                    ? item.country
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </TabPanel>
                )}
              {
                // returned horses Cards
              }
              {showEntryCards.some(
                (obj) => obj.showHorseStatus === "Returned"
              ) && (
                  <TabPanel className={" row m0 rtl"}>
                    {showEntryCards
                      .filter((item) => item.showHorseStatus === "Returned")
                      .map((item, i) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                          key={i}
                          style={{ minHeight: 125 }}
                        >
                          <div
                            className="horseCardContainer"
                            style={{ minHeight: 125 }}
                          >
                            <div className="col-md-12 titletrashshowentrycard">
                              <span className="horseCardLabeltitle  text-transform-none">
                                {item.regNo !== "" && item.isRegisterd
                                  ? item.regNo + " - " + item.title
                                  : item.title}
                              </span>

                              {item.showHorseStatus === "Returned" && (
                                <i
                                  className="fa fa-trash plusIcon cursorPointer remove"
                                  onClick={() => {
                                    dispatch(
                                      handelShowEntryTempData({
                                        type: "remove",
                                        data: item.id,
                                      })
                                    );

                                    setShowEntryCards((oldValues) => {
                                      return oldValues.filter(
                                        (oldItem) => oldItem.id !== item.id
                                      );
                                    });

                                    for (const e of showEntryCardsRef.current
                                      .children) {
                                      if (
                                        e.getElementsByTagName("i")[0]
                                          ?.tagName === "I" &&
                                        e.getElementsByTagName("i")[0].id ===
                                        item.id
                                      ) {
                                        e.getElementsByTagName("i")[0].className =
                                          "fa fa-plus plusIcon fa-lg line-height";
                                        return;
                                      }
                                    }

                                    for (const e of showEntryCardsUnregisteredRef
                                      .current.children) {
                                      if (
                                        e.getElementsByTagName("i")[0]
                                          ?.tagName === "I" &&
                                        e.getElementsByTagName("i")[0].id ===
                                        item.id
                                      ) {
                                        // e.getElementsByTagName("i")[0].className =
                                        //   "fa fa-plus plusIcon fa-lg line-height";
                                        const x = document.getElementById(
                                          item.id
                                        );
                                        if (!!x) {
                                          x.style = "display:block;";
                                        }
                                        // document.getElementById(item.id).style =
                                        //   "display:block;";
                                      }

                                      if (
                                        e.getElementsByTagName("i")[0]
                                          ?.tagName === "I" &&
                                        e.getElementsByTagName("i")[0].id ===
                                        item.id &&
                                        item.showHorseStatus === "Returned"
                                      ) {
                                        // e.getElementsByTagName("i")[0].className =
                                        //   "fa fa-plus plusIcon fa-lg line-height colorred";
                                        const x = document.getElementById(
                                          item.id
                                        );
                                        if (!!x) {
                                          x.style = "display:block;";
                                        }
                                        // document.getElementById(item.id).style =
                                        //   "display:block;";
                                        return;
                                      }

                                      if (
                                        e
                                          .getElementsByTagName("div")[0]
                                          ?.getElementsByTagName("div")[3]
                                          ?.tagName === "DIV" &&
                                        e
                                          .getElementsByTagName("div")[0]
                                          ?.getElementsByTagName("div")[3]?.id ===
                                        `edit-${item.id}`
                                      ) {
                                        e
                                          .getElementsByTagName("div")[0]
                                          .getElementsByTagName(
                                            "div"
                                          )[3].className = `col-md-12 titletrashshowentrycard classnotdisplayImp`;
                                      }
                                    }
                                  }}
                                ></i>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-5">
                                <span className="horseCardLabeltitle ">
                                  {item.date != null && item.date !== ""
                                    ? item.date && formatDate(item.date)
                                    : ""}
                                  {" - "}
                                  {`${translate(
                                    "web." + item.gender.toLowerCase(),
                                    item.gender
                                  )}`}
                                </span>
                                <span className="horseCardLabeltitle ">
                                  {item.country != null &&
                                    item.country !== "" &&
                                    !item.isRegisterd
                                    ? item.country
                                    : ""}
                                </span>
                              </div>
                            </div>
                            {item.showHorseStatus === "Returned" ? (
                              <span className="horseCardLabeltitle  colorred">
                                {item.notes}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                  </TabPanel>
                )}
              {
                // completed horses Cards
              }
              {showEntryCompletedCards.some(
                (obj) => obj.showHorseStatus === "Completed"
              ) && (
                  <TabPanel className={" row m0 rtl"}>
                    {showEntryCompletedCards
                      .filter((item) => item.showHorseStatus === "Completed")
                      .map((item, i) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                          key={i}
                          style={{ minHeight: 125 }}
                        >
                          <div
                            className="horseCardContainer"
                            style={{ minHeight: 125 }}
                          >
                            <div className="col-md-12 titletrashshowentrycard">
                              <span className="horseCardLabeltitle  text-transform-none">
                                {item.registerNumber !== "" && item.isRegisterd
                                  ? item.registerNumber + " - " + item.name
                                  : item.name}
                              </span>
                              <i
                                title={translate("web.completed", "Completed")}
                                className="fa fa-check color-green plusIcon cursorPointer remove"
                              ></i>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-5">
                                <span className="horseCardLabeltitle">
                                  {item.birthDate != null && item.birthDate !== ""
                                    ? item.birthDate && formatDate(item.birthDate)
                                    : ""}
                                  {" - "}
                                  {`${translate(
                                    "web." + item.gender.toLowerCase(),
                                    item.gender
                                  )}`}
                                </span>
                                <span className="horseCardLabeltitle ">
                                  {item.countryName != null &&
                                    !item.isRegisterd &&
                                    item.countryName !== ""
                                    ? item.countryName
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </TabPanel>
                )}
              {
                // Pending Confirmation horses cards
              }
              {showEntryCompletedCards.some(
                (obj) => obj.showHorseStatus === "PendingConfirmation"
              ) && (
                  <TabPanel className={" row m0 rtl"}>
                    {showEntryCompletedCards
                      .filter(
                        (item) => item.showHorseStatus === "PendingConfirmation"
                      )
                      .map((item, i) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                          key={i}
                          style={{ minHeight: 125 }}
                        >
                          <div
                            className="horseCardContainer"
                            style={{ minHeight: 125 }}
                          >
                            <div className="col-md-12 titletrashshowentrycard">
                              <span className="horseCardLabeltitle  text-transform-none">
                                {item.registerNumber !== "" && item.isRegisterd
                                  ? item.registerNumber + " - " + item.name
                                  : item.name}
                              </span>
                              <i
                                title={translate(
                                  "web.pendingConfirmation",
                                  "Pending Confirmation"
                                )}
                                className="fa fa-history color-green plusIcon cursorPointer remove"
                              ></i>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-5">
                                <span className="horseCardLabeltitle">
                                  {item.birthDate != null && item.birthDate !== ""
                                    ? item.birthDate && formatDate(item.birthDate)
                                    : ""}
                                  {" - "}
                                  {`${translate(
                                    "web." + item.gender.toLowerCase(),
                                    item.gender
                                  )}`}
                                </span>
                                <span className="horseCardLabeltitle ">
                                  {item.countryName != null &&
                                    !item.isRegisterd &&
                                    item.countryName !== ""
                                    ? item.countryName
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </TabPanel>
                )}

              {
                //Waiting List horses cards
              }
              {showEntryCards.some(
                (obj) => obj.showHorseStatus === "WaitingList"
              ) && (
                  <TabPanel className={" row m0 rtl"}>
                    {showEntryCards
                      .filter((item) => item.showHorseStatus === "WaitingList")
                      .map((item, i) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-12 horse-market-card showentrycard  minHight150"
                          key={i}
                          style={{ minHeight: 125 }}
                        >
                          <div
                            className="horseCardContainer"
                            style={{ minHeight: 125 }}
                          >
                            <div className="col-md-12 titletrashshowentrycard">
                              <span className="horseCardLabeltitle text-transform-none">
                                {item.regNo !== "" && item.isRegisterd
                                  ? item.regNo + " - " + item.title
                                  : item.title}
                              </span>
                              <i
                                title={translate(
                                  "web.waitingList",
                                  "Waiting List"
                                )}
                                className="fa fa-clock colorred plusIcon cursorPointer remove"
                              ></i>
                            </div>
                            <div className="row">
                              <div className="col-md-12 mb-5">
                                <span className="horseCardLabeltitle">
                                  {item.date != null && item.date !== ""
                                    ? item.date && formatDate(item.date)
                                    : ""}
                                  {" - "}
                                  {`${translate(
                                    "web." + item.gender.toLowerCase(),
                                    item.gender
                                  )}`}
                                </span>
                                <span className="horseCardLabeltitle ">
                                  {item.country != null &&
                                    item.country !== "" &&
                                    !item.isRegisterd
                                    ? item.country
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </TabPanel>
                )}
            </Tabs>
          </div>
          {
            //buttons
          }
          <div className="col-lg-12 col-md-12 col-sm-12 mt-15  textalignend">
            {CompareDate(registerStartDate, registerEndDate) &&
              <button
                className="btn button-saveasdraft  linkbtn"
                onClick={() => handelCreateShowEntry(true)}
              >
                <i className="fa fa-save fa-lg marginrightleft-10"></i>
                {translate("web.saveAsDraft", "Save as draft")}
              </button>
            }
            {showEntryCards.length > 0 ? (
              <ActionButton
                className={""}
                type={"submit"}
                text={
                  <React.Fragment>
                    <i className="fa-solid fa-square-caret-right marginrightleft-10 justRotate"></i>
                    {isNoPaymentOwner
                      ? translate("web.submit", "Submit")
                      : translate("web.continue", "Continue")}
                  </React.Fragment>
                }
                margin={"10px"}
                onClick={() => handelCreateShowEntry(false)}
                disabled={showEntryCards.length > 0 ? false : true}
              />
            ) : (
              <button
                className="btn button-saveasdraft btn-danger  marginrightleft-10"
                disabled
              >
                <i className="fa-solid fa-square-caret-right marginrightleft-10 justRotate"></i>
                {isNoPaymentOwner
                  ? translate("web.submit", "Submit")
                  : translate("web.continue", "Continue")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}