// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDGNFQxlrV6LoLtXs7o97bONoOmuy-8bzQ",
  authDomain: "eahs-auctions.firebaseapp.com",
  databaseURL:
    "https://eahs-auctions-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eahs-auctions",
  storageBucket: "eahs-auctions.appspot.com",
  messagingSenderId: "255214821809",
  appId: "1:255214821809:web:b0f1e82f52a956e06c5d90",
  measurementId: "G-FNCLH1KGKW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
