import React from 'react'

export default function PaymentDetailsCard({ isRegisterdHorse = false, horseName, categoryName, registerNr = '', showFees, currentLanguage, translate, isAuction = false }) {
    return (
        <>
            <span className="horseCardLabeltitle col-sm-12 p0 m0 rtl fontWeight">
                {horseName}
            </span>
            {!isAuction &&
                <>
                    <div className="col-md-6 col-sm-12    rtl p0">
                        <span className="horseCardLabeltitle ">
                            {translate("web.category", "Category")}
                        </span>
                    </div>
                    <div className="col-md-6 col-sm-12 p0    ">
                        <span className="horseCardLabeltitle ">
                            {translate("web.year", "Year")} {categoryName}
                        </span>
                    </div>
                </>
            }
            {isRegisterdHorse &&
                <>
                    <div className="col-md-6 col-sm-12   rtl p0">
                        <span className="horseCardLabeltitle ">
                            {translate(
                                "web.registerationNumber",
                                "Registration Number"
                            )}
                        </span>
                    </div>
                    <div className="col-md-6 col-sm-12 p0    rtl">
                        <span className="horseCardLabeltitle ">
                            {registerNr}
                        </span>
                    </div>
                </>}

            <div className="col-md-6 col-sm-12 p0   rtl">
                <span className="horseCardLabeltitle ">
                    {translate("web.showFees", "Show Fees")}
                </span>
            </div>
            <div className="col-md-6 col-sm-12 p0 rtl">
                <span className="horseCardLabeltitle ">
                    {showFees} {currentLanguage === "en" ? "AED" : "د.إ"}
                </span>
            </div>
        </>
    )
}
