import React from 'react'
import { convertToCamelCase } from "../helper/convertToCamelCase";
import { formatDate } from "../helper/formatDate";
import { countWords } from "../helper/countWords";
import { Link } from 'react-router-dom';
import { useTranslate } from "./TranslationText";

const BaseURL = process.env.REACT_APP_BASE_URL;


export default function ShowCard({ item, i }) {
    const { translate } = useTranslate();

    return (
        // <div
        //     className="col-lg-6 col-md-12 col-sm-12 horse-market-card ShowsCard width100Arabic768"
        //     key={i}
        // >
        //     <div className="horseCardContainer flexColumnOnMDDevices  showCardComponent"  >
        //         {
        //             //img div
        //         }
        //         <div
        //             style={{ display: 'flex', minWidth: 100, justifyContent: 'center', alignItems: 'center' }}>
        //             <img
        //                 style={{ width: '100%' }}
        //                 src={item.logo}
        //                 onError={(e) => {
        //                     e.target.onerror = null;
        //                     e.target.src = `${BaseURL}/assets/img/horseauction.png`;
        //                 }}
        //                 alt="itemName"
        //             />
        //         </div>

        //         {
        //             //data div
        //         }
        //         <div className="col-lg-8 col-md-12 col-sm-12 ">
        //             <span className="horseCardLabeltitle showCardTitle rotateArabic">
        //                 {item.showName !== null && item.showName !== "" ? (
        //                     item.showName
        //                 ) : (
        //                     <React.Fragment> &nbsp;</React.Fragment>
        //                 )}
        //             </span>

        //             <div className="row">
        //                 <div className="col-md-12 col-sm-12 rtl  mb-5">
        //                     <br />
        //                     <div className="col-md-12 col-sm-12  rtl p0 start-date-showlist">
        //                         {item.showRank !== null &&
        //                             item.showRank !== "" && (
        //                                 <div className="col-md-12 col-sm-12  rotateArabic rtl width100responsive" >
        //                                     <i className="fa fa-trophy " style={{ margin: '0 5px' }}></i>
        //                                     {item.showRank}
        //                                 </div>
        //                             )}
        //                     </div>
        //                     <div className="col-md-12 col-sm-12 p0 rtl rotateArabic width100responsive end-date-showlist">
        //                         <div className="col-md-12 col-sm-12 width100responsive"  >
        //                             <i className="fa-solid fa-location-dot " style={{ margin: '0 5px' }}></i>
        //                             {translate(`web.${convertToCamelCase(item.cityName)}`, item.cityName)}
        //                         </div>
        //                     </div>
        //                     <div className="col-md-12 col-sm-12 p0  start-date-showlist justRotate" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
        //                         <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center' }} >
        //                             <i className="fa fa-calendar" ></i>
        //                             <div style={{ minWidth: 130, margin: '0 5px' }}>
        //                                 {translate("web.startDate", "Start Date")}:
        //                             </div>
        //                         </div>
        //                         <div className="text-center " style={{ textAlign: 'center' }}>
        //                             {formatDate(item.startDate)}
        //                         </div>

        //                     </div>

        //                     <div className="col-md-12 col-sm-12 p0 end-date-showlist justRotate" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px' }}>
        //                         <div style={{ display: 'flex', justifyContent: "flex-start", alignItems: 'center' }} >
        //                             <i className="fa fa-calendar " ></i>
        //                             <div style={{ margin: '0 5px' }} >
        //                                 {translate("web.endDate", "End Date")}:
        //                             </div>
        //                         </div>
        //                         <div className="text-center" style={{ textAlign: 'center' }}>
        //                             {formatDate(item.endDate)}
        //                         </div>
        //                     </div>

        //                 </div>
        //             </div>
        //             {item.showName !== null && item.showName !== "" && countWords(item.showName) > 5 ? <></> :
        //                 <br />
        //             }
        //             {!!(item?.termsAndConditions) ?
        //                 <div className="row" style={{ marginTop: 10 }}>
        //                     <div className="col-md-6 col-sm-12 left-to-right">
        //                         <Link to={item.termsAndConditions} className="C89C66 rotateArabic " target="_blank">
        //                             <i className="fa fa-file-pdf  rtl" style={{ margin: '0 5px' }}></i>
        //                             <span className=" rtl" style={{ color: 'rgb(51,51,51' }}>{translate(`web.T&C`, 'T&C')}</span>
        //                         </Link>
        //                     </div>
        //                     <div className="col-md-6 col-sm-12">
        //                         <Link
        //                             className="hoveronlink ViewProfileLink rotateArabic rtl"
        //                             to={`/showdetails?id=${item.id}`}
        //                         >
        //                             {translate("web.viewDetails", "View Details")}
        //                             <i
        //                                 className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
        //                             ></i>
        //                             <i
        //                                 className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
        //                             ></i>
        //                             <i
        //                                 className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
        //                             ></i>
        //                         </Link>
        //                     </div>
        //                 </div> :
        //                 <div className="row" style={{ marginTop: 10 }}>
        //                     <Link
        //                         className="hoveronlink ViewProfileLink rotateArabic rtl"
        //                         to={`/showdetails?id=${item.id}`}
        //                     >
        //                         {translate("web.viewDetails", "View Details")}
        //                         <i
        //                             className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
        //                         ></i>
        //                         <i
        //                             className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
        //                         ></i>
        //                         <i
        //                             className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
        //                         ></i>
        //                     </Link>
        //                 </div>
        //             }

        //         </div>
        //     </div>
        // </div>
        <div className='showCardFinial'>
            <div className='showImageNewContainer'>
                <img
                    src={item.logo}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                    }}
                    alt="itemName" />
            </div>
            <div className='showNewTitleWithDetails'>

                {item.showName !== null && item.showName !== "" && (
                    <div className='showNewTitle'>
                        {item.showName}
                    </div>
                )}
                <div className='showNewDetials'>
                    <div className='showNewRank'>
                        <i className="fa fa-trophy " ></i> {item.showRank}
                    </div>
                    <div className='showNewLocation'>
                        <i className="fa-solid fa-location-dot" ></i>{translate(`web.${convertToCamelCase(item.cityName)}`, item.cityName)}
                    </div>
                    <div className='showNewRank showNewStartDate'>
                        <div>
                            <i className="fa fa-calendar" ></i>{translate("web.startDate", "Start Date")}:
                        </div>
                        <div>
                            {formatDate(item.startDate)}
                        </div>
                    </div>
                    <div className='showNewLocation showNewStartDate'>
                        <div>
                            <i className="fa fa-calendar " ></i>{translate("web.endDate", "End Date")}:
                        </div>
                        <div>
                            {formatDate(item.endDate)}
                        </div>
                    </div>
                    {(new Date(item.startDate) > new Date()) && (!!item.registerStartDate) && (item.registerEndDate) && <>
                        <div className='showNewRank showNewStartDate'>
                            <div>
                                <i className="fa fa-calendar" ></i>{translate("web.regStartDate", "Reg Start Date")}:
                            </div>
                            <div>
                                {formatDate(item.registerStartDate)}
                            </div>
                        </div>
                        <div className='showNewLocation showNewStartDate'>
                            <div>
                                <i className="fa fa-calendar " ></i>{translate("web.regEndDate", "Reg End Date")}:
                            </div>
                            <div>
                                {formatDate(item.registerEndDate)}
                            </div>
                        </div>
                    </>
                    }

                    <div className='showNewViewDetails'>
                        {(new Date(item.startDate) > new Date()) ?
                            <>
                                {item.haveTermsAndConditions &&
                                    <Link
                                        className="hoveronlink ViewProfileLink  rtl"
                                        to={`/SingleTermsAndConditionsShowPage?id=${item.id}`}
                                    >
                                        {translate("web.termsAndConditions", "termsAndConditions")}
                                        <i
                                            className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                                        ></i>
                                        <i
                                            className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                                        ></i>
                                        <i
                                            className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                                        ></i>
                                    </Link>
                                }
                            </>

                            :
                            <Link
                                className="hoveronlink ViewProfileLink  rtl"
                                to={`/showdetails?id=${item.id}`}
                            >
                                {translate("web.viewDetails", "View Details")}
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                                ></i>
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                                ></i>
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                                ></i>
                            </Link>
                        }
                        {/* <Link
                        className="hoveronlink ViewProfileLink rotateArabic rtl"
                        to={`/showdetails?id=${item.id}`}
                    >
                        {translate("web.viewDetails", "View Details")}
                        <i
                            className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                        ></i>
                        <i
                            className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                        ></i>
                        <i
                            className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                        ></i>
                    </Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
