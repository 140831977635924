export function StringDateArEn(dateString, language = 'en') {
    // English to Arabic day of the week and month name mappings
    const daysOfWeek = {
      "Saturday": { en: "Saturday", ar: "السبت" },
      "Sunday": { en: "Sunday", ar: "الأحد" },
      "Monday": { en: "Monday", ar: "الإثنين" },
      "Tuesday": { en: "Tuesday", ar: "الثلاثاء" },
      "Wednesday": { en: "Wednesday", ar: "الأربعاء" },
      "Thursday": { en: "Thursday", ar: "الخميس" },
      "Friday": { en: "Friday", ar: "الجمعة" }
    };
  
    const months = {
      "Jan": { en: "Jan", ar: "يناير" },
      "Feb": { en: "Feb", ar: "فبراير" },
      "Mar": { en: "Mar", ar: "مارس" },
      "Apr": { en: "Apr", ar: "أبريل" },
      "May": { en: "May", ar: "مايو" },
      "Jun": { en: "Jun", ar: "يونيو" },
      "Jul": { en: "Jul", ar: "يوليو" },
      "Aug": { en: "Aug", ar: "أغسطس" },
      "Sep": { en: "Sep", ar: "سبتمبر" },
      "Oct": { en: "Oct", ar: "أكتوبر" },
      "Nov": { en: "Nov", ar: "نوفمبر" },
      "Dec": { en: "Dec", ar: "ديسمبر" }
    };
  
    // Split the string into parts
    const [dayOfWeek, dayOfMonth, , month, year] = dateString.split(' ');
  
    // Convert to Arabic or keep English based on the language parameter
    const translatedDayOfWeek = daysOfWeek[dayOfWeek][language];
    const translatedMonth = months[month][language];
  
    // Format the date in the specified language
    if (language === 'ar') {
      return `${translatedDayOfWeek} ${parseInt(dayOfMonth)} من ${translatedMonth} ${year}`;
    } else {
      return `${translatedDayOfWeek} ${parseInt(dayOfMonth)} of ${translatedMonth} ${year}`;
    }
  }
  

  