import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslate } from '../components/TranslationText';
import ActionButton from '../components/ActionButton';
import { useSelector } from 'react-redux';
import axios from "../axios/axiosUmbraco";
import Loader from '../components/Loader';
import NewsFilter from '../components/NewsFilter';
import { showFirst12Words, showFirst22Words } from '../helper/showFirstSixWords';
import FilterButton from '../components/FilterButton';
import { translateMonthToArabic } from '../helper/translateDayToArabic';

const BaseURL = process.env.REACT_APP_BASE_URL;
const fetch = process.env.REACT_APP_HOME_PAGE_LATSEST_NEWS_ID;
const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

export default function NewsPage() {
    function formatDateArray(incomingdate) {
        const dateTime = new Date(incomingdate);
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const date = new Date(dateTime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = months[date.getMonth()];
        const year = String(date.getFullYear());
        return [day, month, year];
    }

    const { translate } = useTranslate();
    const { currentLanguage } = useSelector((state) => state.studBookSlice);
    const itemsPerPage = 12;
    const [visibleItems, setVisibleItems] = useState(itemsPerPage);
    const [latestNews, setLatestNews] = useState([]);
    const [allNews, setAllNews] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);


    function generateSearchTitle(newsName) {
        // Check if newsName is empty
        if (!newsName) {
            return '';
        }

        // Split the newsName into individual words
        const words = newsName.split(' ');

        // Initialize an empty array to store the filter strings
        const filterStrings = [];

        // Iterate through each word and construct the filter string
        words.forEach(word => {
            // Construct the filter string for the current word
            const filterString = `&filter=name:${word}`;

            // Add the filter string to the array
            filterStrings.push(filterString);
        });

        // Join the filter strings with '&' to form the final searchTitle
        const searchTitle = filterStrings.join('&');

        return searchTitle;
    }

    const [showmoreEnabled, setShowmoreEnabled] = useState(true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    function getCurrentLanguageUmbraco() {
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (lang === "en") return 'en-US';
        else return 'ar-AE';
    }

    async function getdata() {
        setIsLoading(true);
        const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=2000`, {
            headers: {
                "accept-language": getCurrentLanguageUmbraco(),
            },
        });
        const formattedData = umbraco.data?.items.map((item) => ({
            id: item.id,
            name: item.name,
            Item_Body: item?.properties?.Item_Body?.markup ?? '',
            Item_CoverImage: item.properties?.Item_CoverImage ? item.properties?.Item_CoverImage[0]?.url : '',
            Item_Date: item.properties.Item_Date ? formatDateArray(item?.properties?.Item_Date) : '',
            Item_ShortDescription: item?.properties?.Item_ShortDescription ?? '',
            Item_Title: item?.properties?.Item_Title ?? '',
            createdBy: item?.properties?.createdBy ?? '',
            Item_Date_Normal: item.properties.Item_Date ? new Date(item?.properties?.Item_Date) : '',
            externalLink: item?.properties?.externalLink ?? false,
            item_Feature: item?.properties?.item_Feature ?? true,
            newsUrl: item?.properties?.externalLink ? item?.properties?.newsUrl : `/singlePost?id=${item.id}`,
            topOne: item?.properties?.topOne ?? false,
        }));
        const sortedData = formattedData.sort((b, a) => a.Item_Date_Normal - b.Item_Date_Normal);
        setTotal(umbraco.data?.total);
        setLatestNews(sortedData);
        setAllNews(sortedData);
        setIsLoading(false);
    }

    useEffect(() => {
        getdata();
    }, [currentLanguage]);

    const onSubmit = async (filters) => {
        setShowmoreEnabled(false);
        const searchTitle = filters.newsName;
        const dateFrom = filters.DateFrom !== '' ? new Date(filters.DateFrom) : '';
        const dateTo = filters.DateTo !== '' ? new Date(filters.DateTo) : '';
        const umbraco = await axios.get(`/umbraco/delivery/api/v1/content?fetch=${fetch}&skip=0&take=2000`, {
            headers: {
                "accept-language": getCurrentLanguageUmbraco(),
            },
        });
        const formattedData = umbraco.data?.items.map((item) => ({
            id: item.id,
            name: item.name,
            Item_Body: item?.properties?.Item_Body?.markup ?? '',
            Item_CoverImage: item.properties?.Item_CoverImage ? item.properties?.Item_CoverImage[0]?.url : '',
            Item_Date: item.properties.Item_Date ? formatDateArray(item?.properties?.Item_Date) : '',
            Item_Date_Normal: item.properties.Item_Date ? new Date(item?.properties?.Item_Date) : '',
            Item_ShortDescription: item?.properties?.Item_ShortDescription ?? '',
            Item_Title: item?.properties?.Item_Title ?? '',
            createdBy: item?.properties?.createdBy ?? '',
            externalLink: item?.properties?.externalLink ?? false,
            item_Feature: item?.properties?.item_Feature ?? true,
            newsUrl: item?.properties?.externalLink ? item?.properties?.newsUrl : `/singlePost?id=${item.id}`,
            topOne: item?.properties?.topOne ?? false,
            newCategory: item?.properties?.newCategory ?? [],
            newsClassification: item?.properties?.classfication ?? '',
        }));

        const filteredByDate = formattedData.filter((item) => {
            if (dateFrom && dateTo) {
                var endDate = dateTo.setHours(23, 59, 59, 99);
                return item.Item_Date_Normal >= dateFrom && item.Item_Date_Normal <= endDate;
            } else if (dateFrom) {
                return item.Item_Date_Normal >= dateFrom;
            } else if (dateTo) {
                var endDate = dateTo.setHours(23, 59, 59, 99);
                return item.Item_Date_Normal <= endDate;
            }
            return true;
        }).sort((b, a) => a.Item_Date_Normal - b.Item_Date_Normal);

        let filteredData = filteredByDate;
        switch (filters.newscategory) {
            case 'aboutEAHS':
                filteredData = filteredByDate.filter(item => item.newCategory.includes("About EHS"));
                break;
            case 'fromEAHS':
                filteredData = filteredByDate.filter(item => item.newCategory.includes("From EAHS"));
                break;
            default:
                break;
        }

        let filterdbyClassification = filteredData;
        switch (filters.newsClassification) {
            case 'General':
                filterdbyClassification = filteredData.filter(item => item.newsClassification == "General");

                break;
            case 'ActivitiesAndEvents':
                filterdbyClassification = filteredData.filter(item => item.newsClassification == "Activities and events");

                break;
            default:

                break;
        }

        let filteredByTitle = filterdbyClassification;

        if (searchTitle != null && searchTitle != "" && searchTitle != undefined) {
            filteredByTitle = filteredByTitle.filter(item => item.Item_Title.includes(searchTitle));

        }


        setAllNews(filteredByTitle);
        setVisibleItems(itemsPerPage);
    };

    const loadMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
    };

    return (
        <>
            {total === 0 && <Loader />}
            {total > 0 && latestNews.length > 0 && (
                <div className="container-fluid px75">
                    <div className="row m0">
                        <h2 className="col-sm-12 p0 bold rtl capatalise">
                            {translate('web.latestNews', 'Latest News')}
                        </h2>
                    </div>
                    <div className="row m0 rtl">
                        {allNews.length > 0 && (
                            <div className="row m0 text-center justify-content-center">
                                {windowWidth <= 970 && (
                                    <div class="col-md-2 col-sm-3 text-center">
                                        <FilterButton onClick={() => setShowFilter(!showFilter)} />
                                    </div>
                                )}
                            </div>
                        )}
                        <div
                            className="col-lg-3 col-md-3 col-sm-12 NewsFilterContainer width100Arabic768 "
                            style={{
                                display: windowWidth > 970 ? 'block' : showFilter ? 'block' : 'none',
                            }}
                        >
                            <NewsFilter onSubmit={onSubmit} />
                        </div>
                        <div className={'col-lg-9 col-md-9 col-sm-12 '}>
                            <div className="row m0 rtl">
                                <div className="row m0   rtl">
                                    {allNews.length > 0 ?
                                        allNews.slice(0, visibleItems).map((item, index) => (
                                            <div className='col-lg-4 col-md-6 px-5px col-sm-12  ' key={index}>
                                                <Link to={item.newsUrl} target={item.externalLink ? '_blank' : '_self'} className="card">
                                                    <div style={{ borderRadius: 5, minHeight: 475, margin: '5px 0', overflow: 'hidden' }}>
                                                        <div style={{width:'100%' , maxHeight:200  , overflow:'hidden'}}>
                                                        <img src={`${MediaBaseURL}${item.Item_CoverImage}`} className="card-img-top newsImage" alt="..." />
                                                        </div>
                                                        <div className="card-body px-0">
                                                            <h5 className="card-title px-0 newsPageNewsTitle">{showFirst12Words(item.Item_Title)}</h5>
                                                            <p className="card-text px-0 newsPageNewsDescription">{showFirst22Words(item.Item_ShortDescription)}</p>
                                                            <div className="newsPageNewsDateContainer">
                                                                <div className="newsPageNewsDay">{item.Item_Date[0]}</div>
                                                                <div className="newsPageDateSpacer"></div>
                                                                <div className="newsPageMonthAndYear">
                                                                    <span>{translateMonthToArabic(item.Item_Date[1], getCurrentLanguageUmbraco())}</span>
                                                                    <span>{item.Item_Date[2]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )) :
                                        <div className='text-center   bold color-eahsred'>
                                            {translate('web.nodataFound', 'No News Found with Your Filter Parameters')}
                                        </div>
                                    }
                                </div>
                                <div className='col-lg-12 col-md-12 px-5px col-sm-12 my-5 text-center'>
                                    {visibleItems < allNews.length && (
                                        <ActionButton label={translate('web.showMore', 'Show More')} type={'loadmore'} onClick={loadMore} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
