import React, { useEffect, useState } from 'react'
import axiosInstance from '../axios/axiosUmbraco';
import Loader from '../components/Loader';
import ComingSoon from '../components/ComingSoon';

const pageid = process.env.REACT_APP_UMBRACO_COURSES_PAGE_ID;

export default function CoursesPage() {

    const [pageData, setPageData] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    async function getData() {
        try {
            const response = await axiosInstance.get(`/umbraco/delivery/api/v1/content/item/${pageid}`)
            setPageData(response.data.properties);
            setIsLoaded(true);
        } catch (error) {
            setIsLoaded(true);
            setIsError(true);
        }
    }

    useEffect(() => {
        getData();
    }, [])


    return (
        <>
            {isLoaded && !isError ?
                <div className='container'>
                    {!!pageData.title || !!pageData.link || !!pageData.description ?
                        <>
                            {!!pageData.title &&
                                <div className='row rtl'>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <h3 className='NewsPostTitle bold'>
                                            {pageData.title}
                                        </h3>
                                    </div>
                                </div>
                            }
                            {!!pageData.link &&
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                    <div style={{ width: '75%', maxHeight: 500, minWidth: 250 }} dangerouslySetInnerHTML={{ __html: `<div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:80%;left:0;right:0;top:0;bottom:0;" src="${pageData.link}"></iframe></div>` }}></div>
                                </div>
                            }
                            {!!pageData.description && <div className='row rtl m0'>
                                <div className='col-lg-12 col-md-12 col-sm-12' dangerouslySetInnerHTML={{ __html: pageData.description.markup }} />
                            </div>}
                        </>
                        :
                        <ComingSoon />
                    }
                </div>
                :
                <>
                    {!isError &&
                        <Loader />
                    }
                    {isError &&
                        <ComingSoon />
                    }

                </>
            }
        </>
    )
}
