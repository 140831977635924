import React from "react";
import RegisterForm from "../components/RegisterForm";
import ActionButton from "../components/ActionButton";
import { useTranslate } from "../components/TranslationText";

export default function RegisterPage() {
  const { translate } = useTranslate()
  return (
    <div
      className="container-fluid RegistrationPage"
      style={{ paddingRight: 75, paddingLeft: 75 }}
    >
      <div className="row rtl ">
        <div className="col-lg-12 col-md-12 col-sm-12 rtl">
          <div className="col-sm-12 rtl p0">
            <div className="col-lg-3 col-md-3 col-sm-12 rtl p0">
              <ActionButton
                margin={5}
                width={"100%"}
                key={"signupuaepass"}
                text={"Sign in With UAE PASS"}
                type={"signupuaepass"}
              />
              <div className="UaePassSeperator">
                <div className="Seperator" ></div>
                <span className="uaepassor">{translate('web.or', 'OR')}</span>
                <div className="Seperator" ></div>
              </div>
            </div>
          </div>
          <h2>{translate('web.registerNewAccount','Register New Account')}</h2>
        </div>
      </div>
      <div className="row rtl RigistrationForm">
        <RegisterForm />
      </div>
    </div>
  );
}
