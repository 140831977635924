import React, { useState } from "react";
import NetaqInput from "./NetaqInput";
import ActionButton from "./ActionButton";
import { useDispatch, useSelector } from 'react-redux';
import { resetFilter } from "../Redux/Auctions/AuctionsSlice";
import { fetchAuctions } from '../Redux/Auctions/AuctionActions';
import { useTranslate } from "./TranslationText";

const AuctionFilter = ({ onSubmit }) => {
  const [checker, setChecker] = useState(true);
  const accessToken = localStorage.getItem("accessTokenforEAHS");
  const { translate } = useTranslate();
  const currentLanguage = localStorage.getItem('eahsLang');


  const [filterState, setFilterState] = useState({
    // Initial filter values
    auctionName: "",
    auctionType: "None",
    auctionStartDateFrom: "",
    auctionStartDateTo: "",
    skip: 0,
    take: 6,
    numberOfHorsesFrom: 0,
    numberOfHorsesTo: 0,
    dateRange: "LiveAndHybrid",
    enrolledAuction: false,
  });

  const dispatch = useDispatch();
  const [auctionStartFrom, setAuctionStartFrom] = useState(null);
  const [auctionStartTo, setAuctionStartTo] = useState(null);
  const handleChange = (event, input) => {
    if (!!input && input === "auctionStartDateFrom") {
      setAuctionStartFrom(event)
   
      setFilterState({
        ...filterState,
        auctionStartDateFrom: event.toDate(),
      });

      window.auctionFilter = {
        auctionStartFrom:event
      }
      console.debug('auctionFilter::handleChange::: %o',event);
      return;
    }
    if (!!input && input === "auctionStartDateTo") {
      setAuctionStartTo(event)
      setFilterState({
        ...filterState,
        auctionStartDateTo: event.$d.toISOString(),
      });
      return;
    }
    const value = event.target.type == 'checkbox' ? event.target.checked : event.target.value
    setFilterState({
      ...filterState,
      [event.target.name]: value,

    });


  };

  const handleSubmit = (event) => {
    window.scrollTo(0, 0);
    event.preventDefault();
    onSubmit(filterState); // Pass filter state to auctionPage
    // dispatch(resetFilter());
    // dispatch(fetchAuctions(filterState));
  };
  const handleReset = (event) => {
    setAuctionStartFrom(null);
    setAuctionStartTo(null);
    setFilterState({
      auctionName: "",
      auctionType: "None",
      auctionStartDateFrom: "",
      auctionStartDateTo: "",
      skip: 0,
      take: 0,
      numberOfHorsesFrom: 0,
      numberOfHorsesTo: 0,
      dateRange: "LiveAndHybrid",
      enrolledAuction: false,
    });
  };



  return (
    <form
      className="AuctionFilter rtl"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <h2>{translate('web.advancedSearch', 'Advanced Search')}</h2>
      <NetaqInput
        name={'auctionName'}
        label={translate("web.auctionName", "auction Name")}
        key={"search"}
        placeholder={translate("web.auctionName", "Auction Name")}
        type={"text"}
        onChange={handleChange}
      />
      <span className="AuctionFilterSpan">
        {translate('web.auctionStage', 'Auction Stage')}
      </span>
      {accessToken != null && accessToken !== "" && (
        <NetaqInput
          name={"enrolledAuction"}
          key={"enrolledAuction"}
          type={"checkbox"}
          checked={filterState.enrolledAuction}
          label={translate('web.enrolledAuctionWithQuestionMark', "Enrolled Auction?")}
          onChange={handleChange}
        />
      )}
      <div className="AuctionFilterRadioContainer">
        <NetaqInput
          name={"dateRange"}
          key={"Running"}
          type={"radio"}
          value={"LiveAndHybrid"}
          label={translate("web.running", "Running")}
          onChange={handleChange}
          checked={checker}
        />
        <NetaqInput
          name={"dateRange"}
          key={"Upcoming"}
          type={"radio"}
          value={"Upcoming"}
          label={translate("web.upcoming", "Upcoming")}
          onChange={handleChange}
        />

        <NetaqInput
          name={"dateRange"}
          key={"Past"}
          type={"radio"}
          value={"Past"}
          label={translate("web.past", "Past")}
          onChange={handleChange}
        />
      </div>

      {/* <span className="AuctionFilterSpan">
        {translate("web.auctionType", "Auction Type")}
      </span>
      <div className="AuctionFilterRadioContainer">
        <NetaqInput
          name={"auctionType"}
          key={"online"}
          type={"radio"}
          value={"online"}
          label={translate("web.online", "online")}
          onChange={handleChange}
        />
        <NetaqInput
          name={"auctionType"}
          key={"live"}
          type={"radio"}
          value={"live"}
          label={translate("web.live", "live")}
          onChange={handleChange}
        />
        <NetaqInput
          name={"auctionType"}
          key={"hybrid"}
          type={"radio"}
          value={"hybrid"}
          label={translate("web.hybrid", "hybrid")}
          onChange={handleChange}
        />
        <NetaqInput
          name={"auctionType"}
          key={"all"}
          type={"radio"}
          value={"None"}
          checked={checker}
          label={translate("web.all", "all")}
          onChange={handleChange}
        />
      </div> */}

      <span className="AuctionFilterSpan">
        {translate("web.numberOfHorses", "Number of horses")}
      </span>
      <div className="AuctionFilterNumberofhoursesContainer">
        <NetaqInput
          name={"numberOfHorsesFrom"}
          key={"numberOfHoursesfrom"}
          type={"number"}
          value={1}
          label={translate("web.from", "From")}
          onChange={handleChange}
        />
        <NetaqInput
          name={"numberOfHorsesTo"}
          key={"numberOfHoursesto"}
          type={"number"}
          value={50}
          label={translate("web.to", "To")}
          onChange={handleChange}
          minValue = {filterState.numberOfHorsesFrom}
        />
      </div>

      <span className="bold">{translate("web.date", "Date")}</span>
      <div className="AuctionFilterNumberofhoursesContainer">
        <NetaqInput
          name={"auctionStartDateFrom"}
          key={"auction-starting-date"}
          type={"date"}
          label={translate("web.from", "From")}
          onChange={handleChange}
          value={auctionStartFrom}
        />
        <NetaqInput
          name={"auctionStartDateTo"}
          key={"auction-end-date"}
          type={"date"}
          label={translate("web.to", "To")}
          onChange={handleChange}
          value={auctionStartTo}
          minDate={auctionStartFrom}
        />
      </div>
      <div className="AuctionFilterNumberofhoursesContainer mt-3" >
        <ActionButton key={"reset"} type={"reset"} />
        <ActionButton text={translate("web.search", "Search")} key={"apply"} type={"submit"} />
      </div>
    </form>
  );
};

export default AuctionFilter;
